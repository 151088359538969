export const ar = {
  "How Others Can Find You": "كيف يمكن للآخرين العثور عليك",
  "Schedules": "جداول",
  "Login": "تسجيل الدخول",
  "Start Your InstaPay Journey Today!": "ابدأ رحلتك مع InstaPay اليوم!",
  "Phone Number or Email": "رقم الهاتف أو البريد الإلكتروني",
  "Login Using Social Media": "تسجيل الدخول باستخدام وسائل التواصل الاجتماعي",
  "Continue": "استمر",
  "Don't Have an Account? Sign Up": "ليس لديك حساب؟ سجل الآن",
  "Sign Up": "تسجيل",
  "Edit": "تحرير",
  "Enter Your Password": "أدخل كلمة المرور الخاصة بك",
  "Forgot Password?": "نسيت كلمة المرور؟",
  "Account Type": "نوع الحساب",
  "Individual": "فردي",
  "Business": "تجاري",
  "Already Have an Account? Sign In": "هل لديك حساب بالفعل؟ تسجيل الدخول",
  "Sing In": "تسجيل الدخول",
  "Enter Your Personal Details": "أدخل تفاصيلك الشخصية",
  "Add Photo or Logo": "أضف صورة أو شعار",
  "Enter Your Name": "أدخل اسمك",
  "Back": "العودة",
  "Phone Number": "رقم الهاتف",
  "Email Address": "عنوان البريد الإلكتروني",
  "Create a Strong Password": "إنشاء كلمة مرور قوية",
  "Password Requirements": "متطلبات كلمة المرور",
  "Must be between 9 and 64 characters": "يجب أن تكون بين 9 و 64 حرفًا",
  "Include at least two of the following:": "تشمل على الأقل اثنين من التالي:",
  "Uppercase character": "حرف كبير",
  "Lowercase character": "حرف صغير",
  "Number": "رقم",
  "Special character": "حرف خاص",
  "Confirm Password": "تأكيد كلمة المرور",
  "Finalize Your Process": "استكمال العملية",
  "Referral Code (Optional)": "رمز الإحالة (اختياري)",
  "It will autofill if you're signing up with an invitation link.": "سيتم ملء الرمز تلقائيًا إذا كنت تقوم بالتسجيل باستخدام رابط دعوة.",
  "I agree with": "أوافق على",
  "Terms & Conditions": "الشروط والأحكام",
  "Privacy Policy": "سياسة الخصوصية",
  "Create Your Account": "إنشاء حسابك",
  "Enter Code": "أدخل الرمز",
  "A one-time verification code has been sent to your email address  and phone number.": "تم إرسال رمز التحقق لمرة واحدة إلى عنوان بريدك الإلكتروني ورقم هاتفك.",
  "Resend available in ": "إعادة الإرسال متاحة في",
  "seconds": "ثواني",
  "Verify": "تحقق",
  "Verification Code Verified": "تم التحقق من رمز التحقق",
  "Please continue to the next step.": "يرجى الاستمرار إلى الخطوة التالية.",
  "Finish": "إنهاء",
  "Finishing": "إنهاء العملية",
  "Company Name": "اسم الشركة",
  "Enter Your Business Details": "أدخل تفاصيل عملك",
  "Country ": "البلد",
  "You cannot change the country once your account is verified.": "لا يمكنك تغيير البلد بمجرد التحقق من حسابك.",
  "Learn more": "تعرف على المزيد",
  "Optional": "اختياري",
  "Continuing": "متابعة",
  "Didn't receive the verification code?": "لم تتلق الرمز؟",
  "Resend Now": "إعادة الإرسال الآن",
  "Search Here": "ابحث هنا",
  "Search for users, services, and more on InstaPay": "ابحث عن المستخدمين والخدمات والمزيد على InstaPay",
  "Search users here": "ابحث عن المستخدمين هنا",
  "Earn money with our affiliate program!": "اربح المال مع برنامج الإحالة الخاص بنا!",
  "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "أحصل على دخل سلبي من خلال مشاركة رمز الإحالة الخاص بـ InstaPay مع متابعيك. مقابل كل معاملة يقومون بها،\nستكسب عمولة، وبذلك تزيد من أرباحك من تأثيرك عبر الإنترنت.\n",
  "Learn More": "تعرف على المزيد",
  "Balance Amount": "مقدار الرصيد",
  "You spent less compared to last month.": "أنفقت أقل مقارنة بالشهر الماضي.",
  "Referral Rewards": "مكافآت الإحالة",
  "Bitcoin": "بيتكوين",
  "USDT": "USDT",
  "Ethereum": "إيثيريوم",
  "Quick Transfer to Your Most Recent Contacts:": "التحويل السريع إلى أحدث جهات الاتصال لديك:",
  "Number of Transactions": "عدد المعاملات",
  "Total Transaction Amount": "إجمالي مبلغ المعاملة",
  "Total Sent": "الإجمالي المرسل",
  "Total Received": "الإجمالي المستلم",
  "Add Funds": "إضافة الأموال",
  "Send Money": "إرسال الأموال",
  "Request Money": "طلب المال",
  "Send a Quote": "إرسال عرض سعر",
  "Pay With Crypto": "الدفع بالعملة المشفرة",
  "Payment Insights": "رؤى الدفع",
  "Recent Transactions ": "المعاملات الأخيرة",
  "Today": "اليوم",
  "My Wallets": "محافظي",
  "Balance": "الرصيد",
  "Wallet ID": "رقم المحفظة",
  "Monthly limit": "الحد الشهري",
  "Top Up": "إعادة الشحن",
  "Summary": "ملخص",
  "Settings": "الإعدادات",
  "More": "المزيد",
  "Upgrade to Business Account": "ترقية الحساب إلى حساب تجاري",
  "Conversion": "التحويل",
  "Enter Amount": "أدخل المبلغ",
  "Exchanged Amount": "المبلغ المتبادل",
  "Convert": "تحويل",
  "You Are Converting": "أنت تحول",
  "From": "من",
  "To": "إلى",
  "Exchange Rate": "سعر الصرف",
  "Fees": "الرسوم",
  "You Will Receive": "ستتلقى",
  "Slide To Confirm": "انزلق للتأكيد",
  "Verification Code Confirmed - You'll be redirected to the next step": "تم تأكيد رمز التحقق - سيتم تحويلك إلى الخطوة التالية",
  "Moving to next step in": "الانتقال إلى الخطوة التالية في",
  "Thanks for using InstaPay": "شكرًا لاستخدامك InstaPay",
  "Your funds have been credited to your wallet.": "تم إضافة الأموال إلى محفظتك.",
  "Dashboard": "لوحة التحكم",
  "Accounts": "الحسابات",
  "Transactions": "المعاملات",
  "Other Transactions": "المعاملات الأخرى",
  "Payments": "الدفعات",
  "Beneficiaries": "المستفيدين",
  "Referrals": "الإحالات",
  "Quotations": "العروض",
  "Pending": "الأمور المعلقة",
  "My QR Code Sticker": "ملصق الرمز الشريطي QR الخاص بي",
  "My Portfolio": "محفظتي",
  "My Payment Address": "عنوان الدفع الخاص بي",
  "Analytics": "التحليلات",
  "Profile": "الملف الشخصي",
  "Dark Mode": "الوضع الداكن",
  "Support": "الدعم",
  "Logout": "تسجيل الخروج",
  "Powered By": "مقدم من",
  "Kemit Kingdom SA": "Kemit Kingdom SA",
  "A Swiss Company": "شركة سويسرية للتكنولوجيا المالية",
  "MAIN MENU": "القائمة الرئيسية",
  "OTHERS": "آخرين",
  "Wallet": "Wallet",
  "Default": "افتراضي",
  "Breakdown": "تفصيل",
  "Credit": "ائتمان",
  "Debit": "دين",
  "Wallet Management": "إدارة المحفظة",
  "Top-up Your Wallet": "شحن محفظتك",
  "Download Statement": "تنزيل الكشف",
  "Block the Wallet": "حظر المحفظة",
  "Wallet Status": "حالة المحفظة",
  "-Select-File-Type-": "-اختر نوع الملف-",
  "Download Now": "تنزيل الآن",
  "Downloading": "جاري التنزيل",
  "All": "الكل",
  "Sent": "المرسل",
  "Received": "المستلم",
  "Requested": "المطلوب",
  "Quoted": "المقتبس",
  "Search Transactions": "بحث في المعاملات",
  "Date & Time": "التاريخ والوقت",
  "Type": "النوع",
  "Transaction ID": "رقم المعاملة",
  "Recipient": "المستلم",
  "Amount": "المبلغ",
  "Status": "الحالة",
  "Payment Type": "نوع الدفع",
  "Sent Payments": "المدفوعات المرسلة",
  "Received Payments": "المدفوعات المستلمة",
  "Date": "التاريخ",
  "Cycles/Time": "الدورات/الوقت",
  "Payment As": "الدفع ك",
  "Name": "الاسم",
  "Cancel": "إلغاء",
  "Subscriptions & Scheduled": "الاشتراكات والمقررة",
  "Select the option that matches your needs from the list below:": "اختر الخيار الذي يناسب احتياجاتك من القائمة أدناه:",
  "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "أرسل الأموال بسهولة إلى الأصدقاء، العائلة، أو الأعمال محلياً أو دولياً. اختر من بين خيارات التحويل المتعددة، بما في ذلك الإيداعات البنكية، المحافظ الإلكترونية، استلام النقد، بطاقات الدفع، والتحويلات من محفظة إلى محفظة. استمتع بالراحة والأمان مع InstaPay للتحويلات الفورية والسلسة.",
  "International Transfer": "التحويل الدولي\n",
  "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "أرسل الأموال عبر الحدود بسهولة. حول الأموال دولياً إلى الأصدقاء، العائلة، أو الأعمال في بضع نقرات فقط. اختر من مجموعة واسعة من قنوات الدفع بما في ذلك الحساب البنكي، الأموال المتنقلة/المحفظة الإلكترونية، موقع استلام النقد، أو بطاقة الدفع للمعاملات المريحة والآمن\n.",
  "Select the country where you want to send the money!": "اختر البلد الذي تريد إرسال الأموال إليه!",
  "Search Country": "ابحث عن البلد",
  "Trouble Sending Money?": "هل تواجه مشاكل في إرسال الأموال؟",
  "Confirm": "تأكيد",
  "Select Payout Channel": "اختر قناة الدفع",
  "Select your most convenient option to send the money.": "اختر الخيار الأكثر راحة لك لإرسال الأموال.",
  "Select The Bank": "اختر البنك",
  "Search Bank": "ابحث عن البنك",
  "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "أرسل الأموال مباشرةً إلى حساب مستفيد في البنك. استمتع بالراحة\nوالأمان في تحويل الأموال بسلاسة إلى أي بنك حول العال\n.",
  "Bank Transfer": "التحويل البنكي",
  "Enter the amount": "أدخل المبلغ",
  "Tax": "الضريبة",
  "Amount the recipient will receive": "المبلغ الذي سيتلقاه المستفيد",
  "Select Remarks / Reason": "اختر ملاحظات / سبب",
  "Comments": "تعليقات",
  "Attach Files": "إرفاق ملفات",
  "Groups": "المجموعات",
  "Manage Groups": "إدارة المجموعات",
  "Add New Group": "إضافة مجموعة جديدة",
  "Invite": "دعوة شخص",
  "Manage Beneficiaries": "إدارة المستفيدين",
  "Recipients List": "قائمة المستفيدين",
  "Search Contacts": "البحث عن جهات الاتصال",
  "Confirm Your Payment": "تأكيد الدفع الخاص بك",
  "Select Country": "اختر البلد",
  "Select the Recipient's Payment Channel": "اختر قناة الدفع الخاصة بالمستفيد",
  "Change Selection": "تغيير الاختيار",
  "You Are Transferring ": "أنت تحول",
  "Oops! Something Went Wrong.": "عذراً! حدث خطأ ما.",
  "We're Sorry About That. ": "نأسف لذلك.",
  "Go back to Payments Page.": "العودة إلى صفحة الدفع.",
  "Wallet to Wallet Transfer": "التحويل من محفظة إلى محفظة",
  "Request a customized price quote from service providers or vendors.": "اطلب تسعيرة مخصصة من مزودي الخدمات أو التجار.",
  "Enter the Wallet ID": "أدخل رقم المحفظة",
  "Enter the Wallet ID, Email, or Phone Number of the recipient.": "أدخل رقم المحفظة، البريد الإلكتروني، أو رقم الهاتف للمستفيد.",
  "Recipient not on InstaPay?": "هل المستفيد غير موجود في InstaPay؟ دعوتهم.",
  "Invite Them": "ادعوهم",
  "My Beneficiaries": "مستفيدي",
  "Enter the Amount You Wish to Transfer.": "أدخل المبلغ الذي ترغب في تحويله.",
  "Sending to ": "الإرسال إلى",
  "You Have Selected Wallet to Wallet Transfer": "لقد اخترت التحويل من محفظة إلى محفظة",
  "Authenticate Your Payment.": "اعتمد الدفع الخاص بك.",
  "You are transferring": "أنت تحول",
  "Total ": "المجموع",
  "Where Is My Payment?": "أين هو دفعي؟",
  "Share": "شارك",
  "Your Payment Is Confirmed": "تم تأكيد دفعك",
  "QR Code Payment": "دفع برمز الاستجابة السريعة",
  "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "انقل الأموال فوراً إلى مستخدمين آخرين في InstaPay بخدمتنا الآمنة من محفظة إلى محفظة.\nأرسل واستلم الأموال بدون أي متاعب وبسرع\n.",
  "Scan QR with Your Camera or Upload from Your Device.": "امسح رمز الاستجابة السريعة بالكاميرا أو حمّل من جهازك.",
  "Upload from Your Device.": "التحميل من جهازك.\n",
  "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "بدلاً من ذلك، استخدم عنوان الدفع الفريد من InstaPay (UPA)\n",
  "Enter InstaPay UPA": "أدخل عنوان UPA لـ InstaPay\n",
  "Recipient not on InstaPay? Invite Them": "هل المستفيد غير موجود في InstaPay؟ ادعوهم\n",
  "Verify QR Code": "تحقق من رمز الاستجابة السريعة",
  "Scan/Upload QR Code of the Recipient": "امسح/حمّل رمز الاستجابة السريعة للمستفيد",
  "Payment Confirmed": "تم تأكيد الدفع",
  "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "طلب تسعيرات مخصصة من مزودي الخدمات أو التجار للحصول على تقديرات تكلفة دقيقة للخدمات أو المنتجات التي تحتاجها.\"",
  "Choose The Beneficiary or Group": "اختر المستفيد أو المجموعة",
  "Enter the Amount You Wish to Request.": "أدخل المبلغ الذي ترغب في طلبه.",
  "You Are Requesting Money": "أنت تطلب المال",
  "You are requesting": "أنت تطلب",
  "As": "كما",
  "Conversion of Your Cryptocurrencies": "تحويل عملاتك الرقمية",
  "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "تجربة تحويل العملات الرقمية إلى العملات الورقية النقدية بأمان وكفاءة وتركيز على المستخدم في InstaPay.",
  "Choose the Cryptocurrency You Intend to Liquidate.": "اختر العملة الرقمية التي تنوي تصفيتها.",
  "Next": "التالي",
  "Enter the Crypto Wallet Address of the Recipient.": "أدخل عنوان محفظة العملات الرقمية للمستفيد.",
  "Minimum Transactional Value: $100.": "الحد الأدنى لقيمة المعاملة: 100 دولار.",
  "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "ملاحظة: أسعار العملات الرقمية تتغير بشكل متكرر. يرجى الرجوع إلى السعر النهائي في صفحة تأكيد الطلب.",
  "Select Network": "اختر الشبكة",
  "Send A Quote": "أرسل عرض سعر",
  "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "أرسل عرض سعر مفصل إلى عملائك أو زبائنك. قدم خدماتك ومنتجاتك وعروضك بطريقة احترافية مع عرض سعر شخصي.\n",
  "Choose the Beneficiary": "اختر المستفيد",
  "Allow Receiver to Bargain": "السماح للمستقبل بالمساومة",
  "Enter the Title for Your Quotation.": "أدخل العنوان لعرض السعر الخاص بك.",
  "Enter the Description for Your Quotation": "أدخل الوصف لعرض السعر الخاص بك",
  "You are Quoting": "أنت تقدم عرض سعر",
  "Title": "العنوان",
  "Description": "الوصف\n",
  "Attachments": "المرفقات",
  "Confirm Your Quotation": "تأكيد عرض السعر الخاص بك",
  "Verify The Quotation": "تحقق من عرض السعر",
  "Your quotation is confirmed": "تم تأكيد عرض السعر الخاص بك",
  "Request A Quote": "طلب عرض أسعار",
  "Coming Soon": "قريباً",
  "Add Beneficiary": "إضافة مستفيد",
  "Search for Beneficiaries": "البحث عن المستفيدين",
  "Connected Accounts": "الحسابات المتصلة",
  "Details": "التفاصيل",
  "First Name": "الاسم الأول",
  "Last Name": "الاسم الأخير",
  "Country": "البلد",
  "City": "المدينة",
  "Address Line": "عنوان السطر",
  "Mobile Number": "رقم الهاتف المحمول",
  "Relationship With Beneficiary": "العلاقة مع المستفيد",
  "Bank Account": "حساب مصرفي",
  "Mobile Money/Mobile Wallet": "المال المتنقل/محفظة المال المتنقل",
  "Payment Card": "بطاقة الدفع",
  "Crypto Wallet": "محفظة العملات الرقمية",
  "InstaPay Account": "حساب InstaPay",
  "Cash Pickup": "استلام نقدي",
  "IBAN / SWIFT Code": "رمز IBAN أو Swift/BIC",
  "Swift Code": "رمز Swift",
  "Account Number": "رقم الحساب",
  "Bank Name": "اسم البنك",
  "Branch Name": "اسم الفرع",
  "Branch Street": "شارع الفرع",
  "Province": "المحافظة",
  "Postal Code": "الرمز البريدي",
  "Mobile Money Provider": "مزود المال المتنقل",
  "Wallet Name": "اسم المحفظة",
  "Wallet Number": "رقم المحفظة",
  "Card Holder Name": "اسم حامل البطاقة",
  "Card Number": "رقم البطاقة",
  "Expiry Date": "تاريخ الانتهاء",
  "Crypto Currency": "عملة رقمية",
  "Wallet Address": "عنوان المحفظة",
  "Wallet Holder Name": "اسم حامل المحفظة",
  "Wallet Currency": "عملة المحفظة",
  "Select Document Type": "اختر نوع الوثيقة",
  "Enter Document Number": "أدخل رقم الوثيقة",
  "Add Individual Account": "إضافة حساب فردي",
  "Add Business Account": "إضافة حساب تجاري",
  "Company Address": "عنوان الشركة",
  "Company Email": "البريد الإلكتروني للشركة",
  "Company Phone No": "رقم هاتف الشركة",
  "Total Referrals": "إجمالي الإحالات",
  "My Earnings": "أرباحي",
  "Your Referral ID": "معرّف الإحالة الخاص بك",
  "Share Your Unique Referral Link": "شارك رابط الإحالة الفريد الخاص بك في InstaPay",
  "How do I Refer a Friend to InstaPay?": "كيف أحيل صديقًا إلى InstaPay؟",
  "Step 01": "الخطوة 01",
  "Share your InstaPay unique referral link with your friends.": "شارك رابط الإحالة الفريد الخاص بك في InstaPay مع أصدقائك.",
  "Step 02": "الخطوة 02",
  "Earn rewards when they transact $100 or more": "اربح مكافآت عندما يقومون بمعاملات بقيمة 100 دولار أو أكثر",
  "Step 03": "الخطوة 03",
  "Earn up to $0.06 for every transaction your followers make.": "اربح حتى 0.06 دولار لكل معاملة يقوم بها متابعوك.",
  "My Referrals": "إحالاتي",
  "Referral Complete": "إكمال الإحالة",
  "Received Quotations": "استلام العروض",
  "Sent Quotations": "إرسال العروض",
  "Search Pending Items": "البحث عن العناصر المعلقة",
  "Actions": "الإجراءات",
  "Received Requests": "استلام الطلبات",
  "Sent Requests": "إرسال الطلبات",
  "No Actions": "لا إجراءات",
  "You Requested": "لقد طلبت",
  "Via": "عبر",
  "How was your experience?": "كيف كانت تجربتك؟",
  "Leave a Review": "اترك تقييمًا",
  "Overall Ratings": "التقييمات العامة",
  "Visit Profile": "زيارة الملف الشخصي",
  "Ratings": "التقييمات",
  "Reviews": "المراجعات",
  "No reviews available": "لا توجد مراجعات متاحة",
  "Select Your Currency": "اختر عملتك",
  "Proceed": "تابع",
  "Commision": "العمولة",
  "Unlock Seamless Transactions with InstaPay QR Code Sticker": "افتح المعاملات السلسة مع ملصق QR Code الخاص بـ InstaPay\n",
  "Effortless and Swift:": "سهل وسريع:\n",
  "Instantly download your unique InstaPay QR code sticker.": "قم بتنزيل ملصق QR Code الفريد الخاص بـ InstaPay على الفور.\n",
  "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "تجربة الدفع بدون نقود وبدون متاعب، دون الحاجة إلى بنية تحتية معقدة مثل\nأجهزة الدف\n.",
  "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "تحصيل الدفعات بسرعة - لا مزيد من الإدخال الممل لأرقام بطاقات الائتمان أو الهواتف.",
  "Each payment includes customer information for your convenience.": "كل دفعة تتضمن معلومات العميل لراحتك.",
  "Economically Smart:": "ذكي اقتصادياً:\n",
  "Zero activation fees – start immediately without upfront costs.": "لا توجد رسوم تفعيل - ابدأ فورًا بدون تكاليف مسبقة.\n",
  "Forget about fixed monthly charges; pay as you transact.": "انسى الرسوم الشهرية الثابتة؛ ادفع كما تتعامل.\n",
  "Ditch the hardware; no terminal costs mean more savings for you.": "تخلص من الأجهزة؛ عدم وجود تكاليف للمحطات يعني توفير أكبر لك.\n",
  "Enjoy low transaction fees at just 0.75% per QR code payment.": "استمتع برسوم المعاملات المنخفضة بنسبة 0.75٪ لكل دفعة عبر QR Code.\n",
  "We don't impose minimum commissions; you save more with each transaction.": "لا نفرض عمولات دنيا؛ توفر أكثر مع كل معاملة.\n",
  "Experience the joy of instant payments, enhancing your cash flow.": "تجربة فرحة الدفعات الفورية، مما يعزز تدفقك النقدي.\n",
  "Make Every Transaction Count with InstaPay": "اجعل كل معاملة تحسب مع InstaPay\n",
  "Wallet QR": "محفظة QR\n",
  "Download QR": "تنزيل QR\n",
  "QR Code Status": "حالة QR Code\n",
  "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "شارك رابط QR Code أو رابط عنوان الدفع لتلقي الدفعات على الفور في محفظتك في InstaPay.\n",
  "QR Code Title": "عنوان QR Code\n",
  "View Transactions": "عرض المعاملات",
  "My QR Codes:": "رموز QR الخاصة بي:",
  "Load More": "تحميل المزيد\n",
  "Benefits Of Portfolio": "فوائد المحفظة",
  "Benefits:": "الفوائد:",
  "Enhanced Visibility:": "تعزيز الرؤية:\n",
  "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "ارفع ملفك الشخصي العام. تعمل محفظتك كعرض ديناميكي، يمكن الوصول إليه\nمباشرة من عنوان الدفع الخاص بك.\n",
  "Engage Your Audience:": "جذب جمهورك:",
  "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "تواصل مع الزوار من خلال رحلتك الإبداعية أو المهنية. دع أعمالك\nتتحدث عنك، وخلق تفاعل أعمق مع العملاء المحتملين أو الداعمين.\n",
  "Integrated Social Proof:": "إثبات اجتماعي متكامل:\n",
  "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "\"عرض تأثيرك على وسائل التواصل الاجتماعي. يمكن للزوار رؤية مدى تواصلك على وسائل التواصل الاجتماعي،\nمما يضيف المصداقية والسياق إلى محفظتك.\"\n",
  "Seamless Transactions:": "معاملات سلسة:\n",
  "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "مكن الزوار من طلب أو بدء الدفعات مباشرة من صفحتك العامة. لا تعرض محفظتك أعمالك فقط ولكنها تيسر أيضًا التفاعلات المالية السهلة.\n",
  "Personalized Storytelling:": "سرد شخصي:\n",
  "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "استخدم قسم 'عني' لمشاركة قصتك، مهمتك، أو رؤيتك، وخلق اتصال شخصي مع جمهورك.\n",
  "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "محفظتي' ليست مجرد معرض؛ إنها أداة لبناء علامتك التجارية، وجذب جمهورك، وتبسيط التفاعلات المالية الخاصة بك على\n",
  "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "عرض أعمالك وتوسيع نطاق تأثيرك. 'محفظتي' هي لوحتك الرقمية على\nInstaPay. قم بتحميل وعرض مجموعة متنوعة من المحتويات - من الصور المثيرة\nومقاطع الفيديو إلى ملفات PDF المعلوماتية. سواء كنت فنانًا، أو مستقلًا، أو\nرائد أعمال، تتيح لك هذه الميزة تقديم أعمالك أو مشاريعك بطريقة جذابة بصريً\n.",
  "Add/Manage Portfolio": "إضافة/إدارة المحفظة\n",
  "Add New": "إضافة جديد",
  "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "قم بزيادة أرباحك من وسائل التواصل الاجتماعي مع عنوان الدفع الخاص بـ InstaPay",
  "Make It Effortless for Your Audience to Support You:": "جعل الدعم من جمهورك سهلًا:",
  "Easily integrate your InstaPay Payment Address into your social media posts.": "دمج عنوان الدفع الخاص بـ InstaPay بسهولة في منشوراتك على وسائل التواصل الاجتماعي.",
  "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "انسخ والصق رابط عنوان الدفع الخاص بك في كل منشوراتك، وقصصك، أو بشكل بارز في ملفك الشخصي.\n",
  "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "مثالي للمؤثرين، والفنانين، ورجال الأعمال، أو أي شخص لديه مجتمع يتطلع لإظهار\nدعمهم.\n",
  "Instant Support, Endless Possibilities:": "دعم فوري، إمكانيات لا نهائية:\n",
  "Transform the way you monetize your social media content.": "حول طريقة تحقيق الأرباح من محتوى وسائل التواصل الاجتماعي الخاص بك.\n",
  "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "امكن متابعيك من إرسال الأموال لك على الفور بنقرة واحدة، مباشرة من شبكات التواصل الاجتماعي المفضلة لديهم.\n",
  "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "مثالي للدعم الفوري أثناء البث المباشر، كنظام للإكراميات للمحتوى الإبداعي الخاص بك، أو لتمويل مشروعك الكبير القادم.\n",
  "Where to Place Your InstaPay Payment Address for Maximum Impact:": "أين تضع عنوان الدفع الخاص بـ InstaPay لتحقيق أقصى تأثير:\n",
  "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "الملفات الشخصية على وسائل التواصل الاجتماعي: ثبته في قسم السيرة الذاتية أو الحول لتحقيق رؤية مستمرة.\n",
  "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "المنشورات والإعلانات: دمجه في منشوراتك الفردية، خاصةً تلك التي تبرز أعمالك أو نداءات الدعم المحددة.\n",
  "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "القصص والرولز: دمجه في قصصك التفاعلية أو رولزك الممتعة لالتقاط المساهمات الفورية.\n",
  "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "البث المباشر: شاركه خلال جلسات البث المباشر الخاصة بك، مما يسهل على المشاهدين المساهمة في الوقت الفعلي.\n",
  "Simple, Fast, and Secure:": "بسيط، سريع، وآمن:\n",
  "No complex setup – get started in moments.": "لا يوجد إعداد معقد - ابدأ في لحظات.\n",
  "Enjoy the security and speed of InstaPay's trusted payment processing.": "استمتع بالأمان والسرعة في معالجة الدفع الموثوق بها من InstaPay.\n",
  "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "تلقي الدفعات من أي شخص، في أي مكان، دون الحاجة إلى حساب InstaPay.\n",
  "Elevate Your Social Media Game with InstaPay Payment Address": "ارفع من مستوى لعبتك في وسائل التواصل الاجتماعي مع عنوان الدفع الخاص بـ InstaPay\n",
  "InstaPay VPA (Virtual Payment Address)": "عنوان الدفع الافتراضي VPA (InstaPay VPA)\n",
  "Payment Address Title": "عنوان عنوان الدفع",
  "Currency": "العملة",
  "Payment Address Description": "وصف عنوان الدفع",
  "Update": "تحديث",
  "Updating": "جاري التحديث",
  "Total Transaction": "إجمالي المعاملات",
  "Last 30 days": "آخر 30 يومًا",
  "Last 1 month": "آخر شهر",
  "Last 1 year": "آخر سنة",
  "Custom Date": "تاريخ مخصص",
  "Payment Methods": "طرق الدفع",
  "Payment Types": "أنواع الدفع",
  "Requested Amount": "المبلغ المطلوب",
  "Quoted Amount": "المبلغ المقترح",
  "About Me": "عني",
  "Followers": "المتابعون",
  "InstaPay ID": "معرف InstaPay",
  "Download QR Code": "تحميل رمز QR",
  "Initiate A Payment": "بدء الدفع",
  "Report this person": "تقرير عن هذا الشخص",
  "Add now": "أضف الآن",
  "Recent Reviews": "المراجعات الأخيرة",
  "Reviews As Seller": "المراجعات كبائع",
  "Reviews As Buyer": "المراجعات كمشتري",
  "Select Language": "اختر اللغة",
  "Profile Completion": "إكمال الملف الشخصي",
  "Profile Completed": "اكتمال الملف الشخصي",
  "Basic Info": "المعلومات الأساسية",
  "Username": "اسم المستخدم",
  "Gender": "الجنس",
  "Male": "ذكر",
  "Female": "أنثى",
  "-Select-Gender-": "-اختر الجنس-",
  "Date Of Birth": "تاريخ الميلاد",
  "Address": "العنوان",
  "Edit Profile": "تعديل الملف الشخصي",
  "Update Profile": "تحديث الملف الشخصي",
  "Updating Profile": "جاري تحديث الملف الشخصي",
  "Complete Now": "أكمل الآن",
  "Password": "كلمة المرور",
  "Change Password": "تغيير كلمة المرور",
  "New Password": "كلمة المرور الجديدة",
  "Re-enter New Password": "إعادة إدخال كلمة المرور الجديدة",
  "Please follow this guide for a strong password": "يرجى اتباع هذا الدليل للحصول على كلمة مرور قوية",
  "Include at least one special character.": "يجب أن تتضمن على الأقل حرفًا خاصًا واحدًا.",
  "Minimum of 8 characters.": "الحد الأدنى من 8 أحرف.",
  "Include at least one number": "يجب أن تتضمن على الأقل رقمًا واحدًا",
  "Change it often for enhanced security.": "غيرها بشكل متكرر لتعزيز الأمان.",
  "Security Questions": "أسئلة الأمان",
  "Security Question": "سؤال الأمان",
  "Select your question": "اختر سؤالك",
  "Answer To The Question": "الإجابة على السؤال",
  "Nominee": "المرشح",
  "Relationship with Nominee": "علاقتك بالمرشح",
  "Nominee Contact Number": "رقم الاتصال بالمرشح",
  "Nominee Address": "عنوان المرشح",
  "Delete Nominee": "حذف المرشح",
  "Update Nominee": "تحديث المرشح",
  "Details Updated Successfully!": "تم تحديث التفاصيل بنجاح!",
  "Success": "نجاح",
  "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "يمكنك اختيار مرشح حسب رغبتك، بشرط ألا يكون قاصرًا، من خلال منصة InstaPay. تعترف بأنه لا يتطلب توقيعًا مبتلاً لتعيين المرشح، وسيعتبر\n التسجيل عبر الإنترنت نهائيًا وملزمًا. في حالة وفاة مالك محفظة InstaPay، سيتم نقل الرصيد في الـWallet إلى المرشح المسجل. ستتم تبرئة KEMIT KINGDOM\n من جميع التزاماتها بعد تسليم المبلغ إلى المرشح. سيقدم المرشح جميع الوثائق المطلوبة من قبل InstaPay، بما في ذلك تلك المطلوبة للتعريف وإثبات الوفاة.\n",
  "Add Nominee": "إضافة مرشح",
  "Two Factor Authentication": "المصادقة الثنائية",
  "SMS Number": "رقم الرسائل النصية القصيرة",
  "Updated Successfully!": "تم التحديث بنجاح!",
  "Social Network Accounts": "حسابات الشبكات الاجتماعية",
  "Here, you can set up and manage your integration settings.": "هنا، يمكنك إعداد وإدارة إعدادات التكامل الخاصة بك.",
  "Social Network Account": "حساب الشبكة الاجتماعية",
  "Activate Account": "تفعيل الحساب",
  "Enter the code below in the InstaPay Chatbot.": "أدخل الرمز أدناه في دردشة InstaPay.",
  "The code will expire in ": "سينتهي صلاحية الرمز في",
  "Notifications": "الإشعارات",
  "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "قم بتخصيص كيفية استقبالك للإشعارات. تنطبق هذه الإعدادات على الأنشطة التي تراقبها.",
  "Activity": "النشاط",
  "Email": "البريد الإلكتروني",
  "Push": "الإشعارات الفورية",
  "SMS": "الرسائل النصية القصيرة",
  "Source": "مصدر",
  "Payment Requests": "طلبات الدفع ",
  "Bulk Payments": "دفعات جماعية",
  "Identity Verification": "التحقق من الهوية",
  "Verify Your Identity": "تحقق من هويتك",
  "Identity Verification Status:": "حالة التحقق من الهوية:",
  "Briefly describe yourself and explain your reasons for using InstaPay.": "صف نفسك باختصار واشرح أسباب استخدامك لـ InstaPay.",
  "Enter Your Message": "أدخل رسالتك",
  "Select Occupation": "اختر المهنة",
  "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "يجب أن يكون وثيقة هوية صادرة عن الحكومة مع صورة. يجب أن تكون جميع وثائق الهوية مكتوبة بالأبجدية اللاتينية. إذا لم يكن كذلك، فإن ترجمة معتمدة مطلوبة.\n",
  "Accepted Documents": "الوثائق المقبولة",
  "A national identity card": "بطاقة هوية وطنية",
  "A valid passport": "جواز سفر صالح",
  "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "تصاريح الإقامة الصالحة (مقبولة فقط إذا صدرت من دولة أوروبية أو في سويسرا)",
  "Biometric Facial Recognition: Comparing video with passport photograph": "التعرف البيومتري للوجه: مقارنة الفيديو بصورة جواز السفر",
  "Address Verification:": "التحقق من العنوان:",
  "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "قدم كشف حساب بنكي أو فاتورة مرافق (أقل من 3 أشهر) كدليل على العنوان",
  "Additional Documents:": "وثائق إضافية:",
  "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "تحتفظ مملكة كيميت بالحق في طلب أي وثائق إضافية (العناية الواجبة المعززة - EDD) أو معلومات أخرى ضرورية لإجراء عمليات التحقق الشاملة، وفقًا لالتزاماتها القانونية.\n",
  "Start Verification": "ابدأ التحقق",
  "Sessions": "الجلسات",
  "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "هذه قائمة بالأجهزة التي قامت بتسجيل الدخول إلى حسابك. أزل أيًا منها لا تعرفه.",
  "Your Withdrawal Options": "خيارات السحب الخاصة بك",
  "Choose your preferred method for withdrawing payments.": "اختر طريقتك المفضلة لسحب المدفوعات.",
  "No withdrawal options currently available in your country.": "لا توجد خيارات سحب متاحة حاليًا في بلدك.",
  "Download Your Data": "قم بتنزيل بياناتك",
  "Request a Copy of Your Data": "طلب نسخة من بياناتك",
  "Submit a request to download a copy of your data": "قدم طلبًا لتنزيل نسخة من بياناتك",
  "Which Data Should Be Included in the Download?": "ما البيانات التي يجب تضمينها في التنزيل؟",
  "Select all applicable options.": "اختر جميع الخيارات المناسبة.",
  "Personal Information": "المعلومات الشخصية",
  "This information is used to secure your account, tailor your experience, and contact you as necessary.": "تُستخدم هذه المعلومات لتأمين حسابك، وتخصيص تجربتك، والتواصل معك عند الضرورة.",
  "Name and Date Of Birth": "الاسم وتاريخ الميلاد",
  "Phone number": "رقم الهاتف",
  "Financial Information": "المعلومات المالية",
  "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "نستخدم هذه المعلومات لتسريع عملية الدفع وإرسال أو استلام الأموال ببضع نقرات فقط.",
  "Bank accounts": "حسابات بنكية",
  "Debit or credit cards": "بطاقات الخصم أو الائتمان",
  "Credit Information": "معلومات الائتمان",
  "Includes your credit application information": "تتضمن معلومات طلب الائتمان الخاص بك",
  "Other Information (not included in file)": "معلومات أخرى (غير مدرجة في الملف)",
  "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "تتضمن معلومات الجهاز، بيانات الاستخدام الفني، معلومات الموقع الجغرافي، تفضيلات التسويق، سجل الموافقة، والبيانات المستخدمة لخدمات أخرى مثل الائتمان، التحقق من الهوية، التواصل مع PayPal، ومعالجات الطرف الثالث.",
  "Choose file type:": "اختر نوع الملف:",
  "Select file type": "حدد نوع الملف",
  "PDF file can be easily opened on any computer": "يمكن فتح ملف PDF بسهولة على أي كمبيوتر",
  "Submit Request": "إرسال الطلب",
  "Delete Your Account": "حذف حسابك",
  "Ask us to delete your data and close this account": "اطلب منا حذف بياناتك وإغلاق هذا الحساب",
  "We use your data to provide services, so if we delete your data, we also need to close your account.": "نستخدم بياناتك لتقديم الخدمات، لذا إذا قمنا بحذف بياناتك، نحتاج أيضًا إلى إغلاق حسابك.\n",
  "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "إذا كان حسابك محدودًا، اذهب إلى مركز الحلول، واختر 'الذهاب إلى قيود الحساب'، ثم انقر على 'حل'.\n",
  "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "تأكد من تسوية أي مدفوعات معلقة. لن نتمكن من إغلاق حسابك حتى يتم ذلك.\n",
  "If you have a negative balance, click the Resolve link next to your balance.": "إذا كان لديك رصيد سلبي، انقر على رابط الحل بجوار رصيدك.\n",
  "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "بمجرد أن يصبح حسابك واضحًا، وإذا كنت مؤهلاً، فسنغلق حسابك ونحذف بياناتك. نحن بحاجة. للاحتفاظ ببعض بياناتك لأسباب قانونية وتنظيمية. لمعرفة المزيد، انظر لدينا",
  "privacy statement": "بيان الخصوصية",
  "We'll email you to let you know the status, and if there are any other steps you need to take.": "سنرسل لك بريدًا إلكترونيًا لإعلامك بالحالة، وإذا كانت هناك أي خطوات أخرى عليك اتخاذها.",
  "Have more questions about data deletion?": "هل أنت متأكد من أنك تريد إغلاق حسابك؟\n",
  "Contact us": "الاتصال بنا",
  "I understand that account deletion is permanent and can't be reversed.": "أفهم أن حذف الحساب نهائي ولا يمكن التراجع عنه.",
  "Are you sure you want to close your account?": "هل أنت متأكد من أنك تريد إغلاق حسابك؟",
  "Yes, Continue": "نعم، تابع",
  "Logging In": "تسجيل الدخول",
  "Complete your profile to streamline your transaction process.": "أكمل ملفك الشخصي لتبسيط عملية المعاملات الخاصة بك.",
  "Skip": "تخطي",
  "Complete Your Profile": "إكمال ملفك الشخصي",
  "API Key": "مفتاح API",
  "Get API Key": "احصل على مفتاح API",
  "Generating New API Key": "إنشاء مفتاح API جديد",
  "Generate API Key": "توليد مفتاح API",
  "Regenerate API Key": "إعادة توليد مفتاح API",
  "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "للاستفادة الكاملة من المعاملات السلسة لـ InstaPay، اتبع هذه الخطوات السريعة لتوليد مفتاح API\n الخاص بك. أولاً، سجل للحصول على حساب في [رابط التسجيل الخاص بك] وصول إلى لوحة معلومات المطور. انتقل إلى قسم 'مفاتيح API'، قم بتوليد مفتاح جديد، وتأكد من تخزينه بأمان.\n",
  "Delete API Key": "حذف مفتاح API\n",
  "Webhook URL": "عنوان URL لـ Webhook\n",
  "KYB Verification": "التحقق من KYB\n",
  "Additional Files": "ملفات إضافية\n",
  "Business Verification": "التحقق من الأعمال\n",
  "--Select-Your-Company-Type--": "--اختر-نوع-شركتك--\n",
  "Documents Required:": "الوثائق المطلوبة:\n",
  "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "مستخرج رسمي من سجل الشركات (وثيقة تسجيل/تأسيس الكيان)\n",
  "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "تعتبر هذه الوثيقة بمثابة 'بطاقة هوية' رسمية للشركة ويجب الحصول عليها من سجل الأعمال المحلي. تعكس الحالة الحالية للعمل (نشط أو مغلق).\n",
  "The document should be current, with an issuance date not exceeding 3 months.": "يجب أن تكون الوثيقة حديثة، بتاريخ إصدار لا يتجاوز 3 أشهر.\n",
  "The document must include the following details:": "يجب أن تتضمن الوثيقة التفاصيل التالية:\n",
  "Legal name of the company": "الاسم القانوني للشركة\n",
  "Full registered address": "العنوان الكامل المسجل\n",
  "Company registration number": "رقم تسجيل الشركة\n",
  "Date of incorporation": "تاريخ التأسيس\n",
  "Description of the company's activities/purpose": "وصف لأنشطة/غرض الشركة\n",
  "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "معلومات الممثل القانوني: الاسم الكامل، تاريخ ومكان الميلاد، العنوان السكني (الحد الأدنى: بلد الإقامة)، والجنسية\n",
  "If applicable, the document should also include:": "إذا كان ذلك مناسبًا، يجب أيضًا أن تتضمن الوثيقة:\n",
  "Brand or trading names": "أسماء العلامات التجارية أو الأسماء التجارية\n",
  "Value Added Tax (VAT) number": "رقم ضريبة القيمة المضافة (VAT)\n",
  "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "قائمة المالكين النهائيين المستفيدين (UBOs) - هذا إلزامي في بعض الدول، وإلا يمكن تقديمه كوثيقة منفصلة.\n",
  "Proof of Legal Representative's Authority": "إثبات سلطة الممثل القانوني\n",
  "This document validates an individual's authority to represent the company": "تُثبت هذه الوثيقة سلطة الفرد في تمثيل الشركة\n",
  "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "عادةً ما يُذكر الممثل القانوني في مستخرج سجل الشركة. ومع ذلك، إذا لم يكن الأمر كذلك، يجب تأمين وثيقة مثل توكيل أو تفويض بالصلاحيات.\n",
  "Power of Attorney/Delegation of Powers:": "توكيل/تفويض بالصلاحيات:\n",
  "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "يجب أن يكون الشخص المانح للسلطة مخولًا بشكل شرعي للقيام بذلك. يجب أن يُدرج هذا الشخص كممثل قانوني في مستخرج سجل الشركة.\n",
  "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "توثيق هيكل الملكية والمالكين النهائيين المستفيدين (UBOs)\n",
  "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "يجب تقديم دليل على إدراج الشركة في البورصة المعنية. يمكن أن يكون ذلك صفحة من موقع البورصة تعرض تفاصيل الشركة.\n",
  "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "إذا كان أقل من 75% من أسهم الشركة مملوكة للعامة، فإن جميع المساهمين الذين يملكون أو يسيطرون على أكثر من 25% من الأسهم سيخضعون لمتطلبات التحقق من المالك النهائي المستفيد (UBO).\n",
  "Collection of Identification Documents": "جمع وثائق الهوية\n",
  "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "يجب أن تكون وثيقة الهوية صادرة عن الحكومة وتحمل صورة الشخص. يجب أن تكون جميع وثائق الهوية مكتوبة بالأبجدية اللاتينية. إذا لم يكن كذلك، فإن ترجمة معتمدة مطلوبة.\n",
  "Accepted documents:": "الوثائق المقبولة:\n",
  "Valid National Identity Card": "بطاقة هوية وطنية صالحة\n",
  "Valid Passport": "جواز سفر صالح",
  "Valid Resident Permits - These will be accepted only if issued by a European country.": "تصاريح الإقامة الصالحة - ستُقبل فقط إذا كانت صادرة عن دولة أوروبية.",
  "Only legal representatives are subject to the identification document collection process.": "الممثلون القانونيون فقط هم من يخضعون لعملية جمع وثائق التعريف.\n",
  "Additional Requirement:": "متطلبات إضافية:",
  "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "في الحالات التي تتضمن توكيل أو تفويض بالصلاحيات، يجب على كلا الطرفين\n(الشخص المانح للسلطة والشخص المتلقي لها) تقديم نسخة من هويتهم.",
  "Additional Documents": "وثائق إضافية\n",
  "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "فاتورة خدمات (مثل الغاز، الكهرباء، الهاتف أو فاتورة الهاتف المحمول)؛ أو وثيقة صادرة عن إدارة حكومية\nتظهر عنوان واسم المستخدم النهائي (على سبيل المثال، شهادة إقامة)؛ أو",
  "Bank Statement; or": "كشف حساب بنكي؛ أو\n",
  "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "وثيقة صادرة عن إدارة حكومية تظهر عنوان واسم المستخدم النهائي\n(على سبيل المثال، شهادة إقامة).",
  "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "تحتفظ مملكة كيميت بالحق في طلب أي وثائق إضافية أو معلومات أخرى ضرورية لإجراء عمليات التحقق الشاملة، وفقًا لالتزاماتها القانونية.\n",
  "Note:": "ملاحظة:\n",
  "Partners": "الشركاء",
  "Add New Partner": "إضافة شريك جديد",
  "Designation": "المسمى الوظيفي",
  "Partner Name": "اسم الشريك",
  "Partner Email": "البريد الإلكتروني للشريك",
  "Partner Designation": "تعيين الشريك",
  "Add Now": "أضف الآن",
  "Adding": "إضافة",
  "Delete Partner": "حذف الشريك",
  "Delete": "حذف",
  "Deleting": "حذف",
  "Are you sure, you want to remove sxz as partner?": "هل أنت متأكد من أنك تريد إزالة sxz كشريك؟",
  "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "مستخرج سجل الكيان (وثيقة تسجيل/إنشاء الكيان)\n",
  "This document should be either:": "يجب أن تكون هذه الوثيقة إما:\n",
  "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "دليل على إنشاء الكيان، موضحًا تفاصيل الكيان، تاريخ الإنشاء، والغرض منه.\n",
  "A local registry extract:": "مستخرج من السجل المحلي:\n",
  "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "مطلوب وفقًا لتنظيمات الدولة، ويعمل كـ 'بطاقة هوية' حالية للكيان، مشيرًا إلى حالته\n(نشط أو مغلق).",
  "If a registry extract exists, it must not be older than 3 months.": "إذا كان هناك مستخرج سجل، يجب ألا يكون أقدم من 3 أشهر.\n",
  "Entity name": "اسم الكيان\n",
  "Registration number (if applicable, based on the country's requirements)": "رقم التسجيل (إذا كان مطلوبًا، بناءً على متطلبات الدولة)\n",
  "Date of creation": "تاريخ الإنشاء\n",
  "Description of the entity's activities or purpose": "وصف أنشطة أو غرض الكيان\n",
  "Trading names": "أسماء التجارة\n",
  "Articles of Association": "نظام الشركات\n",
  "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "يعمل نظام الشركات كوثيقة دستورية للكيان، موضحًا قواعد تشغيله.\n",
  "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "لا يوجد قيد زمني على هذه الوثيقة، مما يعني طلب النسخة الأحدث. عادةً، تتطلب هذه الأنواع من\nالكيانات عقد اجتماع سنوي واحد على الأقل قد يؤدي إلى تحديث نظام الشركات.\n",
  "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "أمثلة على التغييرات التي قد تحدث تشمل تحديثات اسم الكيان، عنوانه، غرضه، مجلس الإدارة،\nالأدوار، المسؤوليات، إلخ",
  "The document should contain information about:": "يجب أن تحتوي الوثيقة على معلومات حول:",
  "The entity itself, including its legal name, registered address, and purpose": "الكيان نفسه، بما في ذلك اسمه القانوني، عنوانه المسجل، وغرضه\n",
  "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "\nقواعده التشغيلية الداخلية، مثل عملية تعيين أعضاء مجلس الإدارة، أدوارهم وصلاحياتهم\nبين تفاصيل أخرى. هذه القائمة ليست شاملة.\n",
  "How the entity is funded": "كيفية تمويل الكيان\n",
  "Appointment/Removal/Changes on Board of Management Members:": "تعيين/إزالة/تغييرات في أعضاء مجلس الإدارة:",
  "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "عادةً ما تحدث هذه التحديثات بناءً على احتياجات الكيان أو كما هو محدد في نظام الشركات. يتم توثيق التغييرات\nمن خلال محاضر اجتماع مجلس الإدارة، والتي يتم تسجيلها واعتمادها لتعكس القائمة المحدثة\nللأعضاء، مثل الرئيس، نائب الرئيس، الأمين المالي، والأمين العا\n.",
  "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "ستكون محاضر أحدث اجتماع لمجلس الإدارة، بما في ذلك القائمة الحالية للأعضاء، مطلوبة بالإضافة إلى\nنظام الشركات، إذا كانت متوفرة.\n",
  "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "ملاحظة: إذا لم تتضمن النسخة الأخيرة من نظام الشركات القائمة الحالية لأعضاء مجلس الإدارة ولا\nتتوفر محاضر اجتماعات المجلس، فيجب تقديم هذه القائمة. يجب أن تكون مؤرخة وموقعة من قبل\nالممثل القانوني المعين مؤخرًا، ويمكن التحقق من تعيينه من خلال الوثائق ذات الصلة.\n",
  "This document demonstrates an individual's authority to represent the entity. This document could be:": "تُظهر هذه الوثيقة سلطة الفرد في تمثيل الكيان. يمكن أن تكون هذه الوثيقة:\n",
  "The latest Articles of Association": "أحدث نظام للشركات\n",
  "The current list of board management members (which may be included in the board management meeting minutes)": "القائمة الحالية لأعضاء مجلس الإدارة\n(والتي قد تكون مدرجة في محاضر اجتماع مجلس الإدارة)",
  "The document should specify:": "يجب أن تحدد الوثيقة:\n",
  "Full name of the individual(s)": "الاسم الكامل للفرد/الأفراد\n",
  "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "وظيفة الفرد/الأفراد. سيتم التحقق المتقاطع لهذه المعلومات مع نظام الشركات للتأكد من أن وظيفة الفرد تسمح له بربط الكيان. هذا مهم بشكل خاص عندما لا يكون مقدم الطلب هو الرئيس، ولهذه الوظائف سلطة تمثيل محدودة كما هو مذكور في نظام الشركات.\n",
  "Additional information about the legal representative that will be collected includes:": "المعلومات الإضافية التي سيتم جمعها عن الممثل القانوني تشمل:\n",
  "Date and place of birth": "تاريخ ومكان الميلاد",
  "Residential address (at least the country of residence should be stated)": "العنوان السكني (يجب ذكر البلد على الأقل)\n",
  "Nationality": "الجنسية",
  "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "هيكل الملكية والمالكون النهائيون المستفيدون (UBOs)\n",
  "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "عادةً، لا يكون لهذه الأنواع من الكيانات، نظرًا لهيكلها وغرضها، مالكون نهائيون مستفيدون. ومع ذلك، سيتم تحديد أعضاء مجلس الإدارة ومعاملتهم كذلك لأغراض الفحص، جمع الهوية والتحقق منها، إلخ.\n",
  "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "قد يكون لعدد قليل من الجمعيات مالكون نهائيون مستفيدون، وخاصة تلك التي تولد أرباحًا. في مثل هذه الحالات، سيتم ذكر هذا التفصيل في نظام الشركات. سيتم التعرف على هؤلاء الأفراد بسهولة ومعاملتهم على هذا النحو، إلى جانب أعضاء مجلس الإدارة.\n",
  "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "متطلب إضافي: في حالات تتضمن توكيل أو تفويض بالصلاحيات، يجب على كلا الطرفين (الشخص المانح للسلطة والشخص المتلقي لها) تقديم نسخة من وثائق هويتهم.\n",
  "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "مستخرج سجل التاجر الفردي (وثيقة تسجيل/إنشاء الكيان)\n",
  "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "تعتبر هذه الوثيقة بمثابة سجل محلي للتجار الأفراد، مطلوبة في الدول التي يكون فيها هذا التسجيل إلزاميًا. تُثبت أن الفرد يزاول نشاطًا مهنيًا كتاجر فردي. يختلف اسم هذه الوثيقة من دولة لأخرى.\n",
  "Where applicable, the document should not be older than 3 months.": "حيثما ينطبق، يجب ألا يكون عمر الوثيقة أكثر من 3 أشهر.",
  "The document must contain:": "يجب أن تحتوي الوثيقة على:",
  "Individual's name": "اسم الفرد",
  "Residential address": "العنوان السكني",
  "Registration number": "رقم التسجيل",
  "The sole trader is the only individual subject to the identification document collection process.": "التاجر الفردي هو الفرد الوحيد الخاضع لعملية جمع وثائق الهوية.",
  "No Transactions": "لا توجد معاملات",
  "No Pending Items": "لا توجد عناصر معلقة",
  "No Beneficiaries": "لا يوجد مستفيدون",
  "Send Money To": "إرسال الأموال إلى",
  "Confirmation": "التأكيد",
  "You are setting": "أنت تضبط",
  "as default.": "كافتراضي.",
  "Previously": "سابقًا",
  "was default QR.": "كان الافتراضي لرمز الاستجابة السريعة.",
  "Category": "الفئة",
  "-Select-From-The-List-": "-اختر-من-القائمة-",
  "Add a note": "أضف ملاحظة",
  "Categories Added Successfully!": "تمت إضافة الفئات بنجاح!",
  "Subscription starting date": "تاريخ بدء الاشتراك",
  "Until": "حتى",
  "Next Transaction": "المعاملة التالية",
  "Transaction Method": "طريقة المعاملة",
  "Accept the quotation": "قبول العرض",
  "Choose Currency": "اختر العملة",
  "Accept": "قبول",
  "Bargain the quotation": "مساومة العرض",
  "Enter Counter Amount:": "أدخل المبلغ المقابل:",
  "Bargain": "مساومة",
  "Decline the quotation": "إلغاء العرض",
  "Are you sure, you want to decline this quotation?": "هل أنت متأكد من أنك تريد رفض هذا العرض؟",
  "Decline": "رفض",
  "Personal Account": "الحساب الشخصي",
  "Jan": "ينا",
  "Feb": "فبراير",
  "Mar": "مارس",
  "Apr": "إبريل",
  "May": "مايو",
  "Jun": "يونيو",
  "Jul": "يوليو",
  "Aug": "أغسطس",
  "Sep": "سبتمبر",
  "Oct": "أكتوبر",
  "Nov": "نوفمبر",
  "Dec": "ديسمبر",
  "Today": "اليوم",
  "Spent In": "قضى في",
  "Revised Amount": "المبلغ المعدل",
  "You spent more comparing to last month": "أنت أنفقت أكثر مقارنة بالشهر الماضي",
  "Last": "الماضي",
  "days": "أيام",
  "Year": "سنوات",
  "Instant Mobile Top-up Across 150+ Countries": "إعادة شحن الهاتف الفورية في أكثر من 150 دولة",
  "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "اسعد أحباءك أو أعد شحن هاتفك الخاص، بغض النظر عن مكانك! تتيح لك خدمة الشحن الجوال من InstaPay إعادة شحن الهواتف الجوالة فورًا في أكثر من 150 دولة. مثالية للهدايا أو لضمان استمرار اتصالك، تم تصميم خدمتنا لتكون سهلة، سريعة وآمنة.",
  "How It Works - Step-by-Step Guide:": "كيفية العمل - دليل خطوة بخطوة:",
  "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "اختر 'شحن الجوال': ادخل مباشرةً إلى هذه الميزة في تطبيق InstaPay على منصات مثل WhatsApp، Twitter، Telegram، أو Instagram.",
  "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "اختر العملة والمبلغ: اختر من بين عملات متعددة (USD، GBP، EUR، إلخ) وحدد المبلغ الذي تريد شحنه.",
  "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "أدخل رقم الهاتف المحمول: اكتب الرقم الذي ترغب في شحنه—رقمك أو رقم أحد أحبائك. يضمن نظامنا عملية آمنة وسريعة.",
  "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "تأكيد ودفع: تحقق من تفاصيل الشحن، وقم بالتأكيد والدفع باستخدام طريقتك المفضلة. يتم معالجة المعاملة على الفور وبأمان.",
  "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "استلم تأكيدًا فوريًا: سواء أنت أو المستلم (إذا كان ذلك ينطبق) ستتلقى إشعارًا بالتأكيد فور نجاح الشحن.",
  "Benefits:": "المزايا:",
  "Global Reach: Effortlessly send airtime to over 150 countries.": "الوصول العالمي: أرسل رصيد الهاتف بسهولة إلى أكثر من 150 دولة.",
  "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "التأثير الفوري: شاهد الفوائد فورًا—مثالي للهدايا في اللحظة الأخيرة أو الاتصالات العاجلة.",
  "Complete Security: Enjoy peace of mind with our secure payment system.": "أمان كامل: استمتع براحة البال مع نظام الدفع الآمن لدينا.",
  "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "خيارات دفع متنوعة: اختر من بين بطاقات الائتمان، PayPal، أو محفظة InstaPay للدفع.",
  "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "هل أنت جاهز لنشر الفرحة أو البقاء على اتصال؟ قم بالشحن الآن واجعل يوم شخص ما—أو يومك!",
  "Pick a language": "اختر لغة",
  "French": "الفرنسية",
  "Spanish": "الإسبانية",
  "Portuguese": "البرتغالية",
  "German": "الألمانية",
  "Ukrainian": "الأوكرانية",
  "Italian": "الإيطالية",
  "Russian": "الروسية",
  "Arabic": "العربية",
  "Polish": "البولندية",
  "Dutch (Nederlands)": "الهولندية",
  "Yoruba": "اليوروبا",
  "Indonesian": "الإندونيسية",
  "Turkish": "التركية",
  "Swahili": "السواحيلية",
  "Hausa": "الهوسا",
  "Hindi": "الهندية",
  "Urdu": "الأردية",
  "Chinese": "الصينية",
  "Select Your Preferred Language": "حدد اللغة المفضلة لديك",
  "Please select the language you would \nlike to use for the eKYC process.": "يرجى تحديد اللغة التي ترغب في استخدامها لعملية eKYC.\n",
  "Select Your Nationality": "حدد جنسيتك",
  "Choose your nationality to see the \nsupported ID documents for  your country.": "اختر جنسيتك لرؤية المستندات المدعومة لبلدك.\n",
  "Grant permission to use the camera.": "منح الإذن لاستخدام الكاميرا.\n",
  "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "انقر أو اضغط على \"السماح\" عندما يُطلب منك منح الإذن للوصول إلى الكاميرا لالتقاط المستند.\n",
  "Upload Your Documents from \nGallery/Photos": "تحميل مستنداتك من المعرض/الصور",
  "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "إذا واجهت مشكلات مع كاميرتك أو إذا كان نظامك لا يحتوي على كاميرا، يمكنك تحميل صور المستند يدويًا من وحدة التخزين المحلية.",
  "Upload Your ID.": "حمّل هويتك.",
  "Make sure your ID is clear and not blurry to \nensure quick verification.": "تأكد من أن هويتك واضحة وغير ضبابية لضمان التحقق السريع.",
  "Valid Proof of Address": "إثبات عنوان صالح",
  "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. تأكد من أن إثبات العنوان الخاص بك حالي وأن تاريخ البيان هو في غضون الأشهر الثلاثة الماضية من تاريخ بدء التحقق من eKYC الخاص بك.\n2. تأكد من أن إثبات العنوان الخاص بك واضح وغير ضبابي لضمان التحقق السريع.\n\n",
  "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "إشعار هام\nيرجى مراجعة جميع خطوات هذا الدليل بعناية قبل متابعة التحقق من eKYC الخاص بك.\n\nتجنب الأخطاء الشائعة:\n\n* تأكد من أن جميع مستنداتك واضحة ومقروءة ومحدثة.\n* تحقق من أن المعلومات التي تقدمها تتطابق مع التفاصيل الموجودة في مستنداتك الرسمية.\n* اتبع التعليمات بدقة لتجنب التأخير أو الرفض.\n\nملاحظة:\n إذا فشل التحقق من eKYC الخاص بك وكنت بحاجة إلى تقديمه مرة أخرى، فسيتم فرض رسوم إضافية عليك لإعادة التقديم.\nباتباع هذه الإرشادات، يمكنك ضمان عملية تحقق eKYC سلسة وناجحة.\nلأي أسئلة أو مساعدة، يرجى الاتصال بفريق الدعم لدينا عل\n support@insta-pay.ch",
  "Personal Transactions": "المعاملات الشخصية",
  "Business Transactions": "المعاملات التجارية",
  "Shopping & Purchases": "التسوق والمشتريات",
  "Bills & Utilities": " الفواتير والخدمات",
  "Entertainment & Leisure": "الترفيه والترفيه",
  "Investments & Savings": "الاستثمارات والادخار",
  "Health & Wellness": "الصحة والعافية",
  "Transportation": "النقل",
  "Education": "التعليم",
  "Miscellaneous": "متنوع",
  "Family Support": "دعم الأسرة",
  "WALLET": "WALLET",
  "Add a currency": "إضافة عملة",
  "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "عند إضافة عملة، سيتم إضافة أي دفعات تتلقاها بهذه العملة إلى رصيد InstaPay الخاص بك. سيتم استخدام عملتك الرئيسية لإرسال أو طلب الدفعات ما لم يُحدد خلاف ذلك.",

  "balance amount ": "مبلغ الرصيد",
  "referral ": "الإحالة",
  "crypto wallet balance": "رصيد المحفظة المشفرة",
  "money in": "المال الوارد",
  "money out": "المال الصادر",
  "analytics": "تحليلات",
  "see more": "رؤية المزيد",
  "manage my Wallets and see the transaction details": "إدارة محفظاتي ورؤية تفاصيل معاملاتي",
  "set as default Wallet": "تعيين كمحفظة افتراضية",
  "add currency": "إضافة عملة",
  "convert funds ": "تحويل الأموال",
  "withdraw balance": "سحب الرصيد",
  "chart names": "أسماء المخططات",
  "withdraw balance from PKR wallet": "سحب الرصيد من محفظة PKR",
  "select a payout channel": "اختيار قناة الدفع",
  "you have entered an amount below the minimum payment range": "لقد أدخلت مبلغًا أقل من نطاق الدفع الأدنى",
  "change": "تغيير",
  "edit": "تحرير",
  "moving to next step in ...": "الانتقال إلى الخطوة التالية في ...",
  "you are withdrawing ...": "أنت تسحب ...",
  "where is my payment": "أين دفعي",
  "download": "تحميل",
  "IBAN / SWIFT Code": "IBAN أو رمز SWIFT/BIC",
  "swift code": "رمز SWIFT",
  "account number": "رقم الحساب",
  "branch street": "شارع الفرع",
  "city": "المدينة",
  "province": "المقاطعة",
  "postal code": "الرمز البريدي",
  "all payout channels": "جميع قنوات الدفع",
  "bussiness beneficiary": "المستفيد التجاري",
  "no quotation": "لا عرض أسعار",
  "no pending items": "لا توجد عناصر قيد الانتظار",
  "QR status": "حالة QR",
  "Portfolio": "محفظة",
  "edit whole portfolio page": "تحرير صفحة المحفظة بأكملها",
  "personal account": "الحساب الشخصي",
  "Chart names": "أسماء الرسوم البيانية",
  "instagram": "انستغرام",
  "facebook": "فيسبوك",
  "twitter": "تويتر",
  "more": "المزيد",
  "not connected": "غير متصل",
  "code": "رمز",
  "ratings": "تقييمات",
  "current password": "كلمة المرور الحالية",
  "include at least one uppercase and lowercase": "تضمين حرف كبير وحرف صغير على الأقل",
  "my social network accounts": "حسابات الشبكات الاجتماعية الخاصة بي",
  "SMS ": "رسائل قصيرة",
  "view identity verfication guide": "عرض دليل التحقق من الهوية",
  "boimetric face recognition": "التعرف على الوجه البيومتري",
  "please select a payer (withdrawl options)": "يرجى اختيار دافع (خيارات السحب)",
  "IBAN number ": "رقم IBAN",
  "account holder name": "اسم صاحب الحساب",
  "download data": "تحميل البيانات",
  "bank transfer": "تحويل بنكي",
  "mobile wallet": "محفظة الهاتف المحمول",
  "all bank names": "جميع أسماء البنوك",
  "pay via card": "الدفع عن طريق البطاقة",
  "you have entered an amount that is below the payer range": "لقد أدخلت مبلغًا أقل من نطاق الدافع",
  "trouble sending money": "مشكلة في إرسال المال",
  "please follow these example": "يرجى اتباع هذه الأمثلة",
  "wallet ID ": "معرف Wallet",
  "username": "اسم المستخدم",
  "email": "البريد الإلكتروني",
  "phone number": "رقم الهاتف",
  "no user found": "لم يتم العثور على مستخدم",
  "search": "بحث",
  "scan/upload QR code of the recipient": "امسح/حمّل رمز QR الخاص بالمستلم",
  "invite them": "ادعوهم",
  "trouble quoting money": "مشكلة في عرض السعر",
  "select a country": "اختر دولة",
  "airtime details": "تفاصيل الرصيد",
  "enter the phone number": "أدخل رقم الهاتف",
  "service type": "نوع الخدمة",
  "select a service": "اختر خدمة",
  "service list": "قائمة الخدمات",
  "amount entered is less than the ....": "المبلغ المُدخل أقل من ....",
  "total": "المجموع",
  "total credit the receiver gets": "إجمالي الرصيد الذي يحصل عليه المستلم",
  "take ": "خذ",
  "OTP has been verified , You'll be redirected......": "تم التحقق من OTP، ستتم إعادة توجيهك......",
  "Link Your Instagram Account to InstaPay": "رابط حساب Instagram الخاص بك بـ InstaPay",
  "Step 01 – From Instagram App": "الخطوة 01 – من تطبيق Instagram",
  "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "افتح تطبيق Instagram، ابحث عن صفحة الملف الشخصي لـ \"InstaPay\"، اختر \"رسالة\" لفتح مربع الدردشة، اكتب/قل \"مرحبًا\" وانقر على إرسال لبدء المحادثة مع الروبوت InstaPay.",
  "Step 02 – From InstaPay Instagram Profile Page ": "الخطوة 02 – من صفحة الملف الشخصي لـ InstaPay على Instagram",
  "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "سيعرض روبوت InstaPay زري إجراء: \"تسجيل\" و \"توصيل\". اختر \"توصيل\" وسيطالبك الروبوت بإدخال اسم المستخدم الخاص بك في InstaPay.",
  "Step 03 – From InstaPay Instagram Profile Page": "الخطوة 03 – من صفحة الملف الشخصي لـ InstaPay على Instagram",
  "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "سيطلب منك روبوت InstaPay بعد ذلك رمز التحقق من InstaPay. أدخل الرمز الذي حصلت عليه من إعدادات InstaPay تحت \"حسابات شبكاتي الاجتماعية\" وانقر على إرسال.",
  "Step 04 – From InstaPay Instagram Profile Page": "الخطوة 04 – من صفحة الملف الشخصي لـ InstaPay على Instagram",
  "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "ستتلقى رسالة تهنئة تؤكد أن حساب Instagram الخاص بك متزامن الآن مع InstaPay. انقر على \"القائمة الرئيسية\" لبدء استخدام خدمات InstaPay.",
  "Additional Tips": "نصائح إضافية",
  "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. أأمن حسابك: تأكد من أمان حساب Instagram الخاص بك عن طريق تمكين المصادقة الثنائية (2FA) لحماية خدمات InstaPay المرتبطة.",
  "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. اكتشف المزيد: بمجرد الارتباط، استكشف مجموعة واسعة من الخدمات التي يقدمها InstaPay، من المعاملات السلسة إلى السحوبات الفورية.",
  "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. ابق على اطلاع: تابع تحديثاتنا، حيث سنقوم قريبًا بدعم ربط حسابات وسائل التواصل الاجتماعي الأخرى لجعل تجربتك أكثر تكاملاً.",
  "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. المساعدة والدعم: إذا واجهت أي مشاكل أثناء عملية الربط، اتصل بفريق الدعم لدينا للحصول على المساعدة.",
  "Future Enhancements": "التحسينات المستقبلية",
  "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "في المستقبل القريب، سيسمح لك InstaPay بربط المزيد من حسابات وسائل التواصل الاجتماعي، مما يوفر لك مزيدًا من المرونة والراحة. تابعنا للحصول على تحديثات مثيرة!",
  "Personal Transactions": "المعاملات الشخصية",
  "Business Transactions": "المعاملات التجارية",
  "Shopping & Purchases": "التسوق والمشتريات",
  "Bills & Utilities": " الفواتير والخدمات",
  "Entertainment & Leisure": "الترفيه والترفيه",
  "Investments & Savings": "الاستثمارات والادخار",
  "Health & Wellness": "الصحة والعافية",
  "Transportation": "النقل",
  "Education": "التعليم",
  "Miscellaneous": "متنوع",
  "Family Support": "دعم الأسرة",
  "Login With Phone Number": null,
  "Login": "تسجيل الدخول",
  "Start Your InstaPay Journey Today!": "ابدأ رحلتك مع InstaPay اليوم!",
  "Enter your phone number": "أدخل رقم هاتفك",
  "Login with email": "تسجيل الدخول بالبريد الإلكتروني",
  "Don't have an account?": "ليس لديك حساب؟",
  "Sign Up": "اشترك",
  "Continue": "استمر",
  "We recommend you download and install the Google Authenticator app": "ننصحك بتحميل وتثبيت تطبيق Google Authenticator",
  "Two-factor authentication (2FA) is the best way to protect yourself online.": "المصادقة الثنائية (2FA) هي أفضل طريقة لحماية نفسك عبر الإنترنت.",
  "Strong security with Google Authenticator": "أمان قوي مع Google Authenticator",
  "Enter your password": "أدخل كلمة المرور الخاصة بك",
  "Web Mobile": null,
  "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "ننصحك بتحميل وتثبيت تطبيق Google Authenticator لضمان تلقيك رموز التحقق الخاصة بك على الفور، وتجنب أي تأخير أو مشاكل في الرسائل القصيرة.",
  "Web - Google Authenticator": null,
  "Google Authenticator": "Google Authenticator",
  "Two Factor Authentication Code": "رمز المصادقة الثنائية",
  "Problem with the token?": "هل هناك مشكلة في الرمز؟",
  "Verify using SMS": "استخدم SMS للتحقق",
  "Verify": "تحقق",
  "Web Mobile - Google Authenticator": null,
  "SMS - Web ": null,
  "SMS Verification": "التحقق عبر الرسائل القصيرة",
  "Enter the SMS code sent to +41********4053": "أدخل رمز الرسائل القصيرة المرسل إلى +41********4053",
  "Verify Using Authenticator App": "استخدم تطبيق Authenticator للتحقق",
  "Resend code in 120 seconds": "أعد إرسال الرمز في غضون 120 ثانية",
  "Verify ": "تحقق",
  "SMS - Web Mobile": null,
  "My own account": "حسابي الخاص",
  "Friends & Family": "أصدقاء وعائلة",
  "Aunt": "عمة",
  "Brother-in-law": "صهر",
  "Cousin": "ابن عم / ابن خال",
  "Daughter": "ابنة",
  "Father": "أب",
  "Father-in-law": "حمو",
  "Friend": "صديق",
  "Grandfather": "جد",
  "Grandmother": "جدة",
  "Husband": "زوج",
  "Mother": "أم",
  "Mother-in-law": "حماة",
  "Nephew": "ابن الأخ / ابن الأخت",
  "Niece": "ابنة الأخ / ابنة الأخت",
  "Self (i.e. the sender, himself)": "الذات (أي المرسل نفسه)",
  "Sister": "أخت",
  "Sister-in-law": "سلفة",
  "Son": "ابن",
  "Uncle": "عم / خال",
  "Wife": "زوجة",
  "Others not listed": "آخرون غير مذكورين",
  "Merchant": "تاجر",
  "No relationship": "لا علاقة",
  "Add Funds": "إضافة أموال",
  "Enter the Amount": "أدخل المبلغ:",
  "Fees": "الرسوم:",
  "You Will Get": "ستحصل على:",
  "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ يجب أن تكون قيمة المعاملة بين [Currency ISO code & Amount] - [Currency ISO code & Amount]",
  "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ مبلغ غير صالح: مستوى التحقق من الهوية الحالي الخاص بك يحد من المبلغ الأقصى الذي يمكنك إضافته. يرجى ترقية التحقق من هويتك للاستفادة من حدود أعلى.",
  "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ يتغير سعر العملة بشكل متكرر بناءً على ظروف السوق. يرجى الرجوع إلى السعر في صفحة تأكيد الطلب كعرضك النهائي.",
  "Next": "التالي",
  "Upgrade Identity Verification": "ترقية التحقق من الهوية",
  "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "اطلب المال من أي شخص، في أي مكان، بما في ذلك عبر منصات الشبكات الاجتماعية. مع InstaPay، تصبح طلبات الدفع الخاصة بك متاحة على الفور بمجرد قبولها ويتم إرسال الأموال إلى حسابات الاستلام الخاصة بك. بسط معاملاتك وأدر المدفوعات بسهولة وأمان.",
  "You’ve been logged out.": "لقد تم تسجيل خروجك.",
  "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "لأمانك، تم تسجيل خروجك بعد 15 دقيقة من عدم النشاط. يرجى تسجيل الدخول مرة أخرى إذا كنت ترغب في استخدام InstaPay.",
  "LOGIN AGAIN": "تسجيل الدخول مرة أخرى",
  "Brother": "أخ",
  "Current Password": "كلمة المرور الحالية",
  "The new password cannot be the same as the old one": "لا يمكن أن تكون كلمة المرور الجديدة مثل القديمة.",
  "Minimum password length: [X] characters ": "الحد الأدنى لطول كلمة المرور: [X] أحرف",
  "Include at least one uppercase and one lowercase letter.": "تضمين حرف واحد على الأقل كبير وصغير.",
  "Nominee Information": "معلومات المرشح",
  "Verification Code Preferences": "تفضيلات رمز التحقق",
  "How Others Can Find You": "كيف يمكن للآخرين العثور عليك",
  "My Social Network Accounts": "حسابات شبكاتي الاجتماعية",
  "Login Activity": "نشاط تسجيل الدخول",
  "Receiving Accounts": "حسابات الاستلام",
  "Change Your Password To Continue": "قم بتغيير كلمة المرور الخاصة بك للمتابعة",
  "Continue with": "تابع مع",
  "Back to Login": "العودة إلى تسجيل الدخول",
  "Send OTP": "إرسال OTP",
  "A Verification code has been sent to your email and number.": "تم إرسال رمز التحقق إلى بريدك الإلكتروني ورقمك.",
  "Resend available in": "إعادة الإرسال متاح في",
  "Create a strong Password": "أنشئ كلمة مرور قوية",
  "Password must:": "يجب أن تكون كلمة المرور:",
  "Be between 9 and 64 characters": "بين 9 و 64 حرفًا",
  "Include at least two of the following:": "تتضمن اثنين على الأقل مما يلي:",
  "Lowercase Character": "حرف صغير",
  "Special Character": "حرف خاص",
  "Password Updated Successfully!": "تم تحديث كلمة المرور بنجاح!",
  "Click below to login": "انقر أدناه لتسجيل الدخول",

  "Send Money": "إرسال المال",
  "Enter the amount": null,
  "Recent Transfer": "التحويلات الأخيرة",
  "See all": "رؤية الكل",
  "Send again": "إرسال مرة أخرى",
  "You Send": "أنت ترسل",
  "They Receive": "هم يستلمون",
  "Receive Method": "طريقة الاستلام",
  "Select method": "اختر الطريقة",
  "Next": "التالي",
  "Trouble sending money?": "هل تواجه مشكلة في إرسال الأموال؟",
  "Where do you want to send money ?": "أين تريد إرسال الأموال؟",
  "Search ": "بحث",
  "Confirm": "تأكيد",
  "Bank Deposit": "إيداع بنكي",
  "Cash Pick-up": "سحب نقدي",
  "Mobile Wallet": "Mobile Wallet",
  "Bank Card": "بطاقة مصرفية",
  "Crypto Wallet": "Wallet تشفير",
  "What is the receiver’s Mobile Wallet?": "ما هو Wallet المتنقل للمستلم؟",
  "Transfer fees": "رسوم التحويل",
  "Total to pay": "المبلغ الإجمالي",
  "Choose the beneficiary!": "اختر المستفيد!",
  "Country": "الدولة",
  "Receive method": "طريقة الاستلام",
  "You send": "أنت ترسل",
  "change": "تغيير",
  "edit": "إضافة جديد",
  "add new": "المستفيدون",
  "Beneficiaries": "إدارة المستفيدين",
  "Manage Beneficiaries": "بحث",
  "Search": "التالي",
  "CASH PICK-UP": "تحصيل نقدي",
  "Where will your receiver collect the money": "أين سيحصل مستلمك على الأموال؟",
  "Cash is available to collect within minutes from any branch of [name] ": "النقدية متاحة للتحصيل في غضون دقائق من أي فرع من [الاسم]",
  "BANK ACCOUNT": "الحساب البنكي",
  "Transfer to recipient’s bank account": "التحويل إلى حساب المستلم البنكي",
  "BANK CARD": "بطاقة بنكية",
  "Fast transfer directly to recipient’s bank card": "تحويل سريع مباشرة إلى بطاقة المستلم البنكية",
  "CRYPTO WALLET": "Wallet تشفير",
  "Fast transfer directly to a crypto wallet": "تحويل سريع مباشرة إلى Wallet تشفير",
  "Review transfer": "مراجعة التحويل",
  "Receiver's Details": "تفاصيل المستلم",
  "Edit": "تعديل",
  "Name": "الاسم",
  "Reason for sending": "سبب الإرسال",
  "Sending": "الإرسال",
  "Receiver gets": "المستلم يستلم",
  "Exchange rate": "سعر الصرف",
  "Estimated time": "الوقت المقدر",
  "Select Payment Type": "اختر نوع الدفع",
  "Credit cards": "بطاقات الائتمان",
  "Domestic cards": "البطاقات المحلية",
  "Mobile Wallets": "Wallet المتنقلة",
  "InstaPay Wallet": "Wallet InstaPay",
  "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
  "PayPal": "PayPal",
  "CREDIT CARD": "بطاقة الائتمان",
  "Credit Card": "بطاقة الائتمان",
  "Select card": "اختر البطاقة",
  "Card Number": "رقم البطاقة",
  "Enter the 16-digit card number on the card": "أدخل رقم البطاقة المكون من 16 رقمًا",
  "Expiry Date": "تاريخ الانتهاء",
  "Enter the expiration date of the card": "أدخل تاريخ انتهاء صلاحية البطاقة",
  "CVV Number": "رقم CVV",
  "Enter the 3 or 4 digit number on the card": "أدخل الرقم المكون من 3 أو 4 أرقام من البطاقة",
  "INSTAPAY WALLET": "Wallet InstaPay",
  "Select Wallet": "اختر Wallet",
  "My Wallet": "محفظتي",
  "Conversion": "التحويل",
  "Convert": "تحويل",
  "Your payment is confirmed": "تم تأكيد الدفع الخاص بك",
  "Thanks for using InstaPay": "شكرًا لاستخدامك InstaPay",
  "Status": "الحالة",
  "Share": "مشاركة",
  "Transfer created": "تم إنشاء التحويل",
  "Payment received": "تم استلام الدفع",
  "Payment processed": "تمت معالجة الدفع",
  "Transfer successful": "تم التحويل بنجاح",
  "It may take few hours for the funds to appear in [user name] account": "قد يستغرق بضع ساعات لظهور الأموال في حساب [اسم المستخدم]",
  "Transfer failed": "فشل التحويل",
  "Transaction Details": "تفاصيل المعاملة",
  "Need help?": "هل تحتاج إلى مساعدة؟",
  "Service Details": "تفاصيل الخدمة",
  "Funds Deposit Speed": "توفر الأموال",
  "Cut-off Time": "وقت القطع",
  "Bank holiday list": "قائمة العطلات المصرفية",
  "Threshold limits": "حدود المعاملات",
  "null": null,
  "Instant, Real Time": "فوري، في الوقت الحقيقي",
  "Same day, T+1": "نفس اليوم، T+1",
  "Non-instant, T+1": "غير فوري، T+1",
  "Instant Payment, Real Time": "دفع فوري، في الوقت الحقيقي",
  "Transaction processed before 3:00 PM Local time": "تمت معالجة المعاملة قبل الساعة 3:00 مساءً بالتوقيت المحلي",
  "Delivered the same day": "يتم التسليم في نفس اليوم",
  "Transactions processed post 3:00 PM": "المعاملات التي تتم معالجتها بعد الساعة 3:00 مساءً",
  "T+1 business days": "T+1 أيام عمل",
  "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "\"سيتم قيد الدفعات التي تتم في أيام الأسبوع قبل الساعة 3:00 مساءً في الحساب المصرفي المستلم بحلول منتصف الليل في نفس اليوم.\"",
  "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "سيتم قيد الدفعات التي تتم في أيام الأسبوع بعد الساعة 3:00 مساءً بحلول منتصف الليل في اليوم التالي.",
  "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "سيتم قيد الدفعات التي تتم في يوم السبت أو الأحد أو العطلات الرسمية في الحساب بحلول منتصف الليل في يوم العمل التالي",
  "24*7": "24*7",
  "09hrs - 18 hrs Local Time": "09:00 - 18:00 بالتوقيت المحلي",
  "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
  "Min per transaction limit:": "الحد الأدنى لكل معاملة:",
  "Maximum per transaction limit:": "الحد الأقصى لكل معاملة:",
  "Daily threshold limit:": "الحد اليومي:",
  "Weekly threshold limit:": "الحد الأسبوعي:",
  "Monthly threshold limit:": "الحد الشهري:",
  "Yearly threshold limit:": "الحد السنوي:",
  "P2P": "P2P",
  "P2B": "P2B",
  "B2P": "B2P",
  "B2B": "B2B",
  "WALLET TO WALLET": "WALLET TO WALLET",
  "Basic Account (Unverified)": "حساب أساسي (غير مُحقق)",
  "Identity Verified": "هوية مؤكدة",

  "Good Evening!": "مساء الخير!",
  "Total Balance": "الرصيد الإجمالي للحساب",
  "Quick Transfer": "تحويل الأموال السريع",
  "No transactions": "لم يتم العثور على أي معاملات",
  "Blocked balance": "الرصيد المحظور",
  "Available balance": "الرصيد المتاح",
  "Insufficient Balance in PKR": "رصيد غير كافٍ بالروبية الباكستانية (PKR)",
  "Chat with us": "تحدث معنا",
  "Add Currency": "إضافة عملة",
  "Education": "التعليم",
  "Divers": "منوعات",
  "Family support": "دعم الأسرة",
  "Others": "نفقات أخرى",
  "Transport": "النقل",
  "schedule payment": "الدفع المجدول",
  "subscription payment": "الدفع المتكرر",
  "No payment": "لم يتم العثور على أي دفعة",
  "No Beneficiaries": "لا يوجد مستفيدون متاحون",
  "Code Postal": "الرمز البريدي",
  "Additional Information": "معلومات إضافية",
  "Gender": "الجنس",
  "Date Of Birth": "تاريخ الميلاد",
  "Nationality": "الجنسية",
  "Occupation": "المهنة (جميع الخيارات أيضًا)",
  "ID Type": "نوع الهوية (جميع الخيارات أيضًا)",
  "ID Number": "رقم الهوية",
  "Select Beneficiary Payment Channels": "اختيار قنوات الدفع للمستفيد",
  "wallet crypto": "محفظة العملات الرقمية",
  "Add another wallet": "إضافة محفظة أخرى",
  "Enterprise": "الأعمال التجارية",
  "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "المستفيدون من الأعمال قادمون قريبًا! ستكون قادرًا على إجراء معاملات الأعمال عالميًا، وتوسيع نطاق وصولك عبر الحدود.",
  "Joined At": "انضم في",
  "Profile Link": "رابط الملف الشخصي",
  "comission": "العمولة",
  "my withdrawls": "سحوباتي",
  "My Referrals": "إحالاتي",
  "search quotation": "بحث عن عروض الأسعار",
  "no quotation": "لم يتم العثور على أي عروض أسعار",
  "actions": "الإجراءات",
  "search request": "طلب البحث",
  "no pending items ": "لم يتم العثور على أي عناصر معلقة",
  "Share your QR Code or pay link to instantly get paid in your insta wallet": "شارك رمز الاستجابة السريعة أو رابط الدفع الخاص بك لتلقي المدفوعات فورًا في محفظة InstaPay الخاصة بك",
  "Description": "الوصف",
  "save": "حفظ",
  "Share your Insta-Pay unique payment link": "شارك رابط الدفع الفريد الخاص بك عبر InstaPay",
  "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "قم برفع ملفك الشخصي العام. يعمل محفظتك كعرض ديناميكي، يمكن الوصول إليه مباشرة من عنوان الدفع الخاص بك",
  "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "تواصل مع جمهورك من خلال رحلتك المهنية أو الإبداعية. دع عملك يعرض نفسه ويجذب العملاء المحتملين أو الداعمين بشكل أعمق.\"",
  "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "اعرض تأثيرك على وسائل التواصل الاجتماعي. يمكن للزوار رؤية تأثيرك على وسائل التواصل الاجتماعي، مما يضيف مصداقية إلى محفظتك.",
  "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "تمكين الزوار من طلب أو بدء المدفوعات مباشرة من صفحتك العامة. محفظتك ليست فقط معرضًا ولكن أيضًا طريقة لتسهيل المعاملات المالية بسهولة.",
  "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "استخدم قسم \"عني\" لمشاركة قصتك، أو مهمتك، أو رؤيتك، وبناء اتصال شخصي مع جمهورك.",
  "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "\"محفظتي\" هي أكثر من مجرد معرض؛ إنها أداة لبناء علامتك التجارية، والتفاعل مع جمهورك، وتبسيط التفاعلات المالية على InstaPay.",
  "No Portfolio Items": "لم يتم العثور على عناصر في المحفظة",
  "Add New Portfolio Item": "إضافة عنصر محفظة جديد",
  "Title": "العنوان",
  "drag/drop or upload a file(photo/video/pdf)": "اسحب / افلت أو قم بتحميل ملف (صورة/فيديو/pdf)",
  "discard": "تجاهل",
  "save and post ": "حفظ ونشر",
  "My Payment Address": "عنوان الدفع الخاص بي",
  "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "مثالي للمؤثرين، والفنانين، ورواد الأعمال، أو أي شخص يريد دعم مجتمعه بسهولة.",
  "Personal Account": "حالة الحساب الشخصي",
  "spent in total": "الإجمالي الذي تم إنفاقه",
  "not connected": "غير متصل",
  "more": "المزيد",
  "less": "أقل",
  "profile reports": "تقارير الملف الشخصي",
  "All notifications are in English langauge": "جميع الإشعارات باللغة الإنجليزية",
  "timezone": "المنطقة الزمنية",
  "nominee information": "معلومات المرشح",
  "full name": "الاسم الكامل",
  "Relation with Nominee": "العلاقة بالمرشح",
  "source": "المصدر",
  "Google Authenticator": "مصادق جوجل",
  "notifications": "الإشعارات",
  "login": "تسجيل الدخول",
  "View Identity Verification Guide": "عرض دليل التحقق من الهوية",
  "verified": "تم التحقق",
  "select ": "اختيار",
  "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "يجب أن يكون المستند هو بطاقة تعريف حكومية مع صورة. يجب أن تكون جميع الوثائق المكتوبة بالأبجدية اللاتينية. إذا لم تكن كذلك، فإن ترجمة معتمدة مطلوبة.",
  "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "تحتفظ مملكة كيميت بالحق في طلب مستندات إضافية (العناية الواجبة المحسنة - EDD) أو أي معلومات مطلوبة لإجراء التحقق الكامل، بما يتماشى مع التزاماتها القانونية.",
  "sessions": "الجلسات",
  "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "افتح السحوبات الفورية: قم بإعداد حسابات الاستلام الخاصة بك الآن!",
  "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "إدارة الأماكن التي تريد تلقي المدفوعات منها بسهولة عن طريق إضافة تفاصيل حساباتك البنكية، أو المحافظ الإلكترونية، أو محافظ العملات الرقمية، أو بطاقات الدفع، أو نقاط الاستلام النقدي. اتبع الخطوات أدناه لضمان الوصول الفوري إلى أموالك عند الحاجة.",
  "Select the receiving channel below and setup the details.": "حدد القناة المستلمة أدناه وقم بإعداد التفاصيل.",
  "Please Select A Payer": "يرجى تحديد دافع",
  "Number": "رقم",
  "Account Holder Name": "اسم صاحب الحساب",
  "Province": "المقاطعة",
  "postal": "العنوان البريدي",
  "delete channel": "حذف القناة",
  "set as default": "تعيين كافتراضي",
  "edit": "تعديل",
  "all channels are in english language in recieving account page": "جميع القنوات باللغة الإنجليزية في صفحة حسابات الاستلام",
  "select file type": "اختر نوع الملف",
  "download data": "تحميل البيانات",
  "INTERNATIONAL TRANSFER": "التحويل الدولي",
  "enter the amount": "أدخل المبلغ",
  "they recieve ": "المستلم يتلقى",
  "trouble sending money": "مشكلة في إرسال الأموال؟",
  "select destination country": "اختر بلد الوجهة",
  "bank transfer": "التحويل البنكي",
  "recieving method": "طريقة الاستلام",
  "payer": "الدافع",
  "The fee and the received amount may vary depending on the chosen Payment Method": "الرسوم والمبلغ المستلم قد يختلفان بناءً على طريقة الدفع المختارة.",
  "search ": "بحث",
  "show more": "عرض المزيد",
  "select remark reasons": "اختر أسباب الملاحظة",
  "credit and debit card": "بطاقات الائتمان والخصم",
  "Transfer Fees": "رسوم التحويل",
  "Estimated Time": "الوقت المقدر:",
  "within minutes": "في غضون دقائق",
  "total ": "الإجمالي",
  "OTP verifications code": "رمز التحقق OTP",
  "verify using sms/email": "التحقق باستخدام الرسائل القصيرة / البريد الإلكتروني",
  "sms/email verification code": "رمز التحقق عبر الرسائل القصيرة / البريد الإلكتروني",
  "Verify Using Authenticator": "التحقق باستخدام المصادق",
  "WALLET TO WALLET TRANSFER": "تحويل من محفظة إلى محفظة",
  "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "أدخل معرف المحفظة، اسم المستخدم، البريد الإلكتروني، أو رقم هاتف المستلم.",
  "search": "بحث",
  "lookup": "البحث",
  "Recipient Not on InstaPay?": "المستلم غير موجود في InstaPay؟",
  "invite them": "قم بدعوته",
  "Please follow these examples": "يرجى اتباع هذه الأمثلة:",
  "Wallet ID: GY68J782": "معرف المحفظة: GY68J782",
  "InstaPay/Instagram Username": "اسم المستخدم على InstaPay/Instagram: instauser",
  "Email: user@email.com": "البريد الإلكتروني: user@email.com",
  "With Country code": "رقم الهاتف (مع رمز الدولة)",
  "invite friend": "دعوة صديق",
  "invite via phone number": "دعوة عبر رقم الهاتف",
  "invite via email address": "دعوة عبر عنوان البريد الإلكتروني",
  "next": "التالي",
  "back": "عودة",
  "Personalized Message": "رسالة شخصية",
  "available currency": "العملة المتاحة",
  "attach files": "إرفاق الملفات",
  "total amount": "المبلغ الإجمالي",
  "instant payment": "الدفع الفوري",
  "starting from": "ابتداءً من",
  "recurring cycle": "دورة متكررة",
  "until I stop": "حتى التوقف",
  "start from": "ابتداءً من",
  "cycle": "الدورة",
  "total": "الإجمالي",
  "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "قد يتلقى المستلم مبلغًا معدلًا قليلاً بسبب أسعار الصرف.",
  "schedule to": "مجدولة إلى",
  "schedule at": "مجدول في",
  "Scheduled Date": "التاريخ المجدول",
  "Scheduled time": "الوقت المجدول",
  "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "قد يتلقى المستلم مبلغًا معدلًا قليلاً بسبب أسعار الصرف.",
  "QR CODE PAYMENT ": "دفع باستخدام رمز QR",
  "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "بدلاً من ذلك، استخدم رمز QR الأبجدي الرقمي الخاص بـ InstaPay.",
  "PYAYMENT REQUEST": "طلب الدفع",
  "choose the beneficiary": "اختر المستفيد",
  "trouble requesting money": "مشكلة في طلب الأموال؟",
  "Standard Request": "الطلب القياسي",
  "Subscription Request": "الطلب المتكرر",
  "Schedule Request": "الطلب المجدول",
  "Security Request": "طلب تأمين",
  "Instalment Request": "خطة الأقساط",
  "Split Request": "تقسيم الدفع",
  "You recieved": "لقد تلقيت",
  "is this your location?": "هل هذا موقعك؟",
  "yes": "نعم",
  "Authenticator Code": "رمز المصادقة",
  "Enter a code shown in the app to make sure everything works fine.": "أدخل الرمز المعروض في التطبيق للتأكد من أن كل شيء صحيح.",
  "SEND A QUOTE": "إرسال عرض سعر",
  "Trouble Quoting Money?": "مشكلة في إرسال عرض السعر؟",
  "Please follow these examples:": "يرجى اتباع هذه الأمثلة:",
  "Full Name: Muhammad Imtiaz": "الاسم الكامل: محمد امتياز",
  "InstaPay/Intagram Username: instauser": "اسم المستخدم على InstaPay/Instagram: instauser",
  "Phone Number: 44795396600 (With Country Code)": "رقم الهاتف: 44795396600 (مع رمز الدولة)",
  "SEND TOP-UP AND E-SIM": "إرسال شحن رصيد وإي سيم",
  "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "ابق متصلاً عالميًا! خدمة الشحن الخاصة بـ InstaPay تتيح لك إعادة شحن الهواتف على الفور، شراء إي سيم، القسائم المميزة، بطاقات الهدايا، ودفع فواتير المرافق في أكثر من 150 دولة. مثالي للهدايا أو التحضير الشخصي، خدمتنا تركز على السهولة، السرعة، والأمان.",
  "MOBILE AIRTIME AND E-SIM": "وقت المحمول وإي سيم",
  "Select the country where you want to top-up a mobile phone!": "اختر البلد لشحن الهاتف المحمول!",
  "Other Operator": "مشغلون آخرون",
  "airtime": "شحن",
  "bundle": "الباقة",
  "data": "البيانات",
  "Enter custom amount": "أدخل مبلغ مخصص",
  "Credit & Debit Card": "بطاقة الائتمان أو الخصم",
  "Bank Transfer": "تحويل بنكي",
  "Mobile Wallet": "محفظة الموبايل",
  "InstaPay Wallet": "محفظة InstaPay",
  "PayPal": "باي بال",
  "add card payment": "إضافة بطاقة دفع",
  "save details to use next time.": "حفظ التفاصيل للاستخدام المستقبلي",
  "Recipient Mobile Number": "رقم هاتف المستلم المحمول",
  "Amount": "المبلغ",
  "Service": "الخدمة",
  "Destination": "الوجهة",
  "Provider": "المزود",
  "Airtime": "شحن",
  "Pakistan": "باكستان",
  "Telenor Pakistan": "تيلينور باكستان",
  "Within minutes": "في غضون دقائق",
  "top-up summary": "ملخص الشحن",
  "PKR is not supported by Paypal, USD will be used as the default currency.": "الروبية الباكستانية غير مدعومة من باي بال، سيتم استخدام الدولار الأمريكي كإعداد افتراضي.",
  "Amount in USD": "المبلغ بالدولار الأمريكي",
  "Send Top-up": "إرسال الشحن",
  "available sunservices": "الخدمات المتاحة",
  "login with email": "تسجيل الدخول بالبريد الإلكتروني",
  "Already Have an Account?": "لديك حساب بالفعل؟ تسجيل الدخول",
  "profile picture": "صورة الملف الشخصي",
  "add photo/logo": "إضافة صورة/شعار",
  "first name": "الاسم الأول",
  "enter your first name": "أدخل اسمك الأول",
  "last name": "اسم العائلة",
  "enter your last name": "أدخل اسمك العائلي",
  "email(optional)": "البريد الإلكتروني (اختياري)",
  "enter your email address": "أدخل عنوان بريدك الإلكتروني",
  "password must": "كلمة المرور يجب",
  "It will be autofill, if you're signing up with invitation link": "التعبئة التلقائية للتسجيل بناءً على الدعوة",
  "change number": "تغيير الرقم",
  "signup error": "خطأ في التسجيل",
  "Something went wrong while sending sms!": "حدث خطأ أثناء إرسال الرسالة القصيرة! يرجى المحاولة مرة أخرى لاحقًا.",
  "Errors": "حدثت أخطاء. يرجى الرجوع إلى القائمة التالية للحصول على التفاصيل.",
  "Generic error": "خطأ عام",
  "Trust Payments API requires the 'requests' library": "تتطلب واجهة Trust Payments API مكتبة \"requests\" لتعمل بشكل صحيح.",
  "Send error": "حدث خطأ أثناء عملية الإرسال. يرجى المحاولة مرة أخرى.",
  "Receive error": "حدث خطأ أثناء عملية الاستلام. يرجى المحاولة مرة أخرى.",
  "Invalid credentials provided": "بيانات الاعتماد غير صالحة. يرجى التحقق والمحاولة مرة أخرى.",
  "An issue occurred whilst trying to connect to Trust Payments servers": "حدثت مشكلة أثناء الاتصال بخوادم Trust Payments. يرجى التحقق من اتصالك والمحاولة مرة أخرى.",
  "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "خطأ غير متوقع أثناء الاتصال بخوادم Trust Payments. إذا استمرت هذه المشكلة، يرجى التواصل مع support@trustpayments.com.",
  "Unknown error. If this persists please contact Trust Payments": "خطأ غير معروف. يرجى التواصل مع دعم Trust Payments إذا استمر الأمر.",
  "Incorrect usage of the Trust Payments API": "استخدام غير صحيح لواجهة Trust Payments API. يرجى التحقق من الطلب والمحاولة مرة أخرى.",
  "Invalid card details": "تفاصيل البطاقة غير صالحة. يرجى التحقق وإدخال التفاصيل الصحيحة.",
  "Invalid date": "التاريخ المقدم غير صالح.",
  "Invalid date/time": "صيغة التاريخ/الوقت غير صالحة. يرجى تقديم الصيغة الصحيحة.",
  "Invalid details": "التفاصيل غير صالحة. يرجى التحقق وإدخال المعلومات الصحيحة.",
  "Card number does not match card type": "رقم البطاقة لا يتطابق مع نوع البطاقة المحدد.",
  "Malformed XML": "خطأ في تنسيق XML. يرجى التحقق وتصحيح الـ XML.",
  "XML does not match schema": "الـ XML لا يتطابق مع المخطط المتوقع.",
  "Invalid file format": "تنسيق الملف غير صالح. يرجى استخدام تنسيق مدعوم.",
  "Empty file contents": "محتوى الملف فارغ. يرجى تقديم محتوى صالح.",
  "Invalid file contents": "محتوى الملف غير صالح. يرجى التحقق من الملف والمحاولة مرة أخرى.",
  "Malformed JSON": "JSON غير صالح. يرجى تصحيح صيغة JSON.",
  "StApi Error": "خطأ StApi",
  "Invalid fields specified in request": "الحقول المحددة في الطلب غير صالحة. يرجى التحقق وتصحيحها.",
  "Missing parent": "معلومات الوالد مفقودة.",
  "Refund requires settled parent or parent that's due to settle today": "يتطلب استرداد المبلغ معاملة والد مستقرة أو مستحقة اليوم.",
  "Refund requires authorisation parent": "يتطلب استرداد المبلغ معاملة والد تفويض.",
  "Refund amount too great": "يتجاوز مبلغ الاسترداد الحد المسموح به.",
  "No acquirer specified": "لم يتم تقديم معلومات المستحوذ.",
  "Repeat amount too great": "المبلغ المتكرر يتجاوز الحد المسموح به.",
  "Split amount too great": "المبلغ المجزأ يتجاوز الحد المسموح به.",
  "Cannot refund a declined transaction": "لا يمكن استرداد معاملة مرفوضة.",
  "Refund requires a settled parent": "يتطلب استرداد المبلغ معاملة والد مستقرة.",
  "Reversal requires a cancelled auth parent": "يتطلب الإلغاء معاملة تفويض ملغاة.",
  "Cannot override amount in child transaction": "لا يمكن تجاوز المبلغ في معاملة فرعية.",
  "Cannot override currency in child transaction": "لا يمكن تجاوز العملة في معاملة فرعية.",
  "Subscription requires RECUR account": "يتطلب الاشتراك إعداد حساب متكرر.",
  "Subscription requires successful parent": "يتطلب الاشتراك معاملة والد ناجحة.",
  "Risk Decisions must have AUTH as parent": "يجب ربط قرارات المخاطر بمعاملة تفويض.",
  "Chargebacks must have AUTH/REFUND as parent": "يجب ربط المبالغ المرتجعة بمعاملة تفويض أو استرداد.",
  "Refund amount less than Minimum allowed": "مبلغ الاسترداد أقل من الحد الأدنى المسموح به.",
  "Refund requires paypaltransactionid": "يتطلب الاسترداد تقديم معرف معاملة PayPal.",
  "Invalid split transaction": "معاملة مجزأة غير صالحة.",
  "Cannot reverse AUTH processed more than 48 hours ago": "لا يمكن إلغاء التفويض بعد مرور أكثر من 48 ساعة.",
  "Reversal requires acquirerreferencedata": "يتطلب الإلغاء بيانات مرجعية للمستحوذ.",
  "Cannot reverse AUTH processed by a different acquirer": "لا يمكن إلغاء التفويض الذي تم معالجته بواسطة مستحوذ آخر.",
  "Payment type does not support repeats": "نوع الدفع لا يدعم المعاملات المتكررة.",
  "Reversal missing required data": "بيانات مطلوبة للإلغاء مفقودة.",
  "Missing token": "معلومات الرمز مفقودة.",
  "Subscription with an accountcheck parent not supported on current acquirer": "الاشتراك باستخدام تحقق من الحساب كوالد غير مدعوم من المستحوذ الحالي.",
  "Subscription cannot be used as a parent": "لا يمكن استخدام الاشتراك كمعاملة والد.",
  "Invalid parent": "نوع معاملة والد غير صالح.",
  "Payment type does not support refunds": "نوع الدفع لا يدعم الاستردادات.",
  "Invalid incremental transaction": "المعاملة الإضافية غير صالحة.",
  "Partial reversals not supported": "الإلغاءات الجزئية غير مدعومة.",
  "THREEDQUERY parent/child must have the same payment type as the child": "يجب أن يكون نوع الدفع بين المعاملات الأصلية والمعاملات الفرعية هو نفسه للاستعلامات الآمنة ثلاثية الأبعاد.",
  "Payment type does not support card scheme updates": "نوع الدفع لا يدعم تحديثات نظام البطاقات.",
  "Cannot reverse AUTH at this time, please try again": "لا يمكن إلغاء التفويض في هذا الوقت. يرجى المحاولة لاحقًا.",
  "Cannot determine token": "غير قادر على تحديد الرمز. يرجى التحقق والمحاولة مرة أخرى.",
  "Service Temporarily Disabled": "الخدمة معطلة مؤقتًا. يرجى المحاولة لاحقًا.",
  "Login first request": "يتطلب تسجيل الدخول قبل تقديم طلب.",
  "Invalid username/password": "اسم المستخدم أو كلمة المرور غير صالحة. يرجى المحاولة مرة أخرى.",
  "Invalid session": "الجلسة غير صالحة. يرجى تسجيل الدخول مرة أخرى.",
  "Session has expired": "انتهت صلاحية الجلسة. يرجى تسجيل الدخول مرة أخرى.",
  "Password expired": "انتهت صلاحية كلمة المرور. يرجى إعادة تعيين كلمة المرور الخاصة بك.",
  "Password has been previously used": "تم استخدام كلمة المرور من قبل. يرجى اختيار كلمة مرور جديدة.",
  "MyST user account has been locked": "تم قفل حساب مستخدم MyST. يرجى التواصل مع الدعم.",
  "New password does not match confirmed password": "لا تتطابق كلمة المرور الجديدة مع كلمة المرور المؤكدة.",
  "Incorrect current password": "كلمة المرور الحالية غير صحيحة. يرجى المحاولة مرة أخرى.",
  "Invalid selection": "تم إجراء اختيار غير صالح. يرجى اختيار خيار صالح.",
  "User already exists": "المستخدم موجود بالفعل. يرجى تسجيل الدخول أو إعادة تعيين كلمة المرور إذا لزم الأمر.",
  "No transaction found": "لم يتم العثور على معاملة.",
  "Invalid selected transactions": "المعاملات المختارة غير صالحة.",
  "Data supplied has not been saved": "لم يتم حفظ البيانات. يرجى المحاولة مرة أخرى.",
  "Invalid request type": "نوع الطلب غير صالح.",
  "Missing request type, at least one request type must be selected": "نوع الطلب مفقود. يجب اختيار واحد على الأقل.",
  "Invalid payment type": "نوع الدفع غير صالح.",
  "Missing payment type, at least one payment type must be selected": "نوع الدفع مفقود. يجب اختيار واحد على الأقل.",
  "Invalid error code": "رمز الخطأ غير صالح.",
  "Missing error code, at least one error code must be selected": "رمز الخطأ مفقود. يجب اختيار واحد على الأقل.",
  "Invalid filter description": "وصف الفلتر غير صالح.",
  "Invalid destination description": "وصف الوجهة غير صالح.",
  "Invalid notification type": "نوع الإشعار غير صالح.",
  "Invalid destination": "الوجهة غير صالحة.",
  "Invalid field selected": "الحقل المحدد غير صالح.",
  "Invalid email from address": "عنوان البريد الإلكتروني للمرسل غير صالح.",
  "Invalid email subject": "سطر موضوع البريد الإلكتروني غير صالح.",
  "Invalid email type": "نوع البريد الإلكتروني غير صالح.",
  "Unable to process request": "غير قادر على معالجة الطلب. يرجى المحاولة لاحقًا.",
  "No file selected for upload": "لم يتم تحديد ملف للتحميل.",
  "Invalid file size": "حجم الملف غير صالح. يرجى تقديم ملف بحجم مقبول.",
  "Invalid filename": "اسم الملف غير صالح.",
  "Invalid extension": "امتداد الملف غير صالح.",
  "User requires at least one site reference": "المستخدم يحتاج إلى مرجع واحد على الأقل للموقع.",
  "Only ST-level users can have '*' access": "فقط المستخدمون من المستوى ST يمكنهم الحصول على حقوق الوصول '*'.",
  "Request failed": "فشل الطلب. يرجى المحاولة لاحقًا.",
  "Invalid File Contents": "محتويات الملف غير صالحة.",
  "Maximum number of files uploaded": "تم تحميل الحد الأقصى لعدد الملفات. يرجى حذف بعض الملفات والمحاولة مرة أخرى.",
  "Insufficient gateway access privileges": "حقوق الوصول للبوابة غير كافية.",
  "Maximum file size limit reached": "تم الوصول إلى الحد الأقصى لحجم الملف.",
  "Username(s) must be a valid user(s)": "يجب أن يكون اسم المستخدم صالحًا.",
  "Site reference(s) must be a valid site(s)": "يجب أن تكون مراجع الموقع صالحة.",
  "Unable to send email, please verify the details and try again": "غير قادر على إرسال البريد الإلكتروني. يرجى التحقق من التفاصيل والمحاولة مرة أخرى.",
  "Negative already exists": "هناك قيمة سلبية موجودة بالفعل. يرجى التحقق وإجراء التصحيحات.",
  "Cannot delete a search owned by another user": "لا يمكن حذف بحث يملكه مستخدم آخر.",
  "Invalid search": "البحث غير صالح. يرجى المحاولة مرة أخرى بمعايير صالحة.",
  "Cannot delete the specified search, the search name cannot be found": "لا يمكن حذف البحث المحدد. لم يتم العثور على اسم البحث.",
  "Search parameter is too short": "معامل البحث قصير جدًا. يرجى إدخال مصطلحات بحث أكثر تحديدًا.",
  "Duplicate custom fields defined": "تم تعريف الحقول المخصصة المكررة. يرجى حل التكرارات.",
  "Cannot allocate selected users, insufficient privileges": "لا يمكن تخصيص المستخدمين المحددين. الامتيازات غير كافية.",
  "Allocated users have access to additional sites": "المستخدمون المخصصون لديهم حق الوصول إلى مواقع إضافية.",
  "Allocated users have access to additional users": "المستخدمون المخصصون لديهم حق الوصول إلى مستخدمين إضافيين.",
  "User with current role cannot be allocated users": "المستخدمون بالرتبة الحالية لا يمكنهم تخصيص مستخدمين آخرين.",
  "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "يتطلب هذا الموقع قبول ملفات تعريف الارتباط لتسجيل الدخول. يمكن قبول ملفات تعريف الارتباط بالنقر على 'أوافق' أدناه.",
  "User requires at least one site reference or site group": "المستخدم يحتاج إلى مرجع أو مجموعة مواقع واحدة على الأقل.",
  "Allocated users have access to additional site groups": "المستخدمون المخصصون لديهم حق الوصول إلى مجموعات مواقع إضافية.",
  "No statement found": "لم يتم العثور على بيان.",
  "Data supplied has not been updated in MobilePay 3rd-party service": "لم يتم تحديث البيانات الموردة في خدمة MobilePay التابعة لجهة خارجية.",
  "Bypass": "تجاوز",
  "Insufficient access privileges": "امتيازات الوصول غير كافية.",
  "Coding error": "تم اكتشاف خطأ في الكود.",
  "Insufficient privileges": "الامتيازات غير كافية لإتمام هذا الإجراء.",
  "Invalid request": "الطلب غير صالح. يرجى التحقق والمحاولة مرة أخرى.",
  "Invalid field": "تم إدخال حقل غير صالح. يرجى التحقق وتصحيح الإدخال.",
  "Unknown site": "الموقع غير معروف.",
  "Banned card": "البطاقة محظورة.",
  "XML element parse error": "خطأ في تحليل عنصر XML. يرجى التحقق من هيكل XML.",
  "Maestro must use SecureCode": "يجب استخدام SecureCode لمعاملات Maestro.",
  "Multiple email addresses must be separated with , or ;": "يجب فصل عناوين البريد الإلكتروني المتعددة بفواصل (,) أو بفواصل منقوطة (;).",
  "Invalid site reference for alias": "مرجع الموقع غير صالح للاسم المستعار.",
  "Invalid version number": "رقم الإصدار غير صالح. يرجى إدخال رقم إصدار صالح.",
  "Unknown user": "المستخدم غير معروف. يرجى التحقق والمحاولة مرة أخرى.",
  "Cannot determine account": "لا يمكن تحديد الحساب.",
  "JSON element parse error": "خطأ في تحليل عنصر JSON. يرجى التحقق من هيكل JSON.",
  "Wallet type configuration error": "خطأ في تكوين نوع المحفظة.",
  "Wallet type not supported on this request": "نوع المحفظة غير مدعوم لهذا الطلب.",
  "The card number you have provided is incorrect, please verify your details and try again": "رقم البطاقة المقدم غير صحيح. يرجى التحقق من التفاصيل والمحاولة مرة أخرى.",
  "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "رمز الأمان (CVV2) الذي قدمته غير صحيح. يرجى التحقق والمحاولة مرة أخرى.",
  "The expiry date you have provided is incorrect, please verify your details and try again": "تاريخ الانتهاء المقدم غير صحيح. يرجى التحقق من التفاصيل والمحاولة مرة أخرى.",
  "The expiry month you have provided is incorrect, please verify your details and try again": "شهر الانتهاء المقدم غير صحيح. يرجى التحقق والمحاولة مرة أخرى.",
  "The expiry year you have provided is incorrect, please verify your details and try again": "سنة الانتهاء المقدم غير صحيحة. يرجى التحقق والمحاولة مرة أخرى.",
  "Unable to process your payment due to connection errors - request id mismatch, please try again": "غير قادر على معالجة الدفع بسبب أخطاء الاتصال. عدم تطابق معرّف الطلب. يرجى المحاولة مرة أخرى.",
  "The issue number you have provided is incorrect, please verify your details and try again": "الرقم المقدم غير صحيح. يرجى التحقق والمحاولة مرة أخرى.",
  "The payment type you have provided is incorrect, please verify your details and try again": "نوع الدفع المقدم غير صحيح. يرجى التحقق والمحاولة مرة أخرى.",
  "Unable to process your payment, please contact the website": "غير قادر على معالجة الدفع. يرجى الاتصال بموقع الويب للحصول على الدعم.",
  "There are errors with these fields: {0}": "هناك أخطاء في هذه الحقول: {0}",
  "No account found": "لم يتم العثور على حساب.",
  "Refund cannot be processed": "لا يمكن معالجة الاسترداد.",
  "Transaction de-activated": "تم إلغاء المعاملة.",
  "Socket receive error": "خطأ في استقبال المقبس.",
  "Socket connection error": "خطأ في اتصال المقبس.",
  "Socket closed": "تم إغلاق المقبس.",
  "Invalid data received": "تم استلام بيانات غير صالحة.",
  "Invalid SQL": "استعلام SQL غير صالح.",
  "Timeout": "انتهاء المهلة.",
  "Invalid acquirer": "تفاصيل المشتري غير صالحة.",
  "Unable to connect to acquirer": "غير قادر على الاتصال بالمشتري.",
  "Invalid response from acquirer": "استجابة غير صالحة من المشتري.",
  "No available transport": "لا يوجد نقل متاح.",
  "File size too large": "حجم الملف كبير جدًا.",
  "Socket send error": "خطأ في إرسال المقبس.",
  "Communication error": "خطأ في الاتصال.",
  "Proxy error": "خطأ في الوكيل.",
  "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "غير قادر على معالجة الدفع بسبب أخطاء الاتصال. يرجى التحقق من التفاصيل والمحاولة مرة أخرى ({0}).",
  "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "غير قادر على معالجة الدفع بسبب أخطاء الاتصال (حالة الاستجابة HTTP {0}). يرجى التحقق من التفاصيل والمحاولة مرة أخرى ({1}).",
  "Wrong number of emails": "عدد غير صحيح من عناوين البريد الإلكتروني المقدمة.",
  "Bank System Error": "خطأ في نظام البنك.",
  "Wrong number of transactions": "عدد غير صحيح من المعاملات المقدمة.",
  "Invalid transport configuration": "تكوين النقل غير صالح.",
  "No valid updates specified": "لم يتم تحديد تحديثات صالحة.",
  "Transaction reference not found": "لم يتم العثور على مرجع المعاملة.",
  "Settlebaseamount too large": "المبلغ الأساسي للتسوية كبير جدًا.",
  "Transaction not updatable": "لا يمكن تحديث المعاملة.",
  "No searchable filter specified": "لم يتم تحديد فلتر قابل للبحث.",
  "Timeout Error": "خطأ في المهلة. يرجى المحاولة مرة أخرى.",
  "3-D Secure Transport Error": "خطأ في نقل 3-D Secure.",
  "Unauthenticated": "طلب غير مصدق.",
  "Site Suspended": "تم تعليق الموقع.",
  "No updates performed": "لم يتم تنفيذ أي تحديثات.",
  "Invalid Request": "طلب غير صالح.",
  "Invalid Response": "استجابة غير صالحة.",
  "Invalid Acquirer": "معلومات المشتري غير صالحة.",
  "Invalid account data": "بيانات الحساب غير صالحة.",
  "Missing": "المعلومات مفقودة.",
  "Payment Error": "خطأ في الدفع.",
  "Invalid acquirer for 3-D Secure": "المشتري غير صالح للـ 3-D Secure.",
  "Invalid payment type for 3-D Secure": "نوع الدفع غير صالح للـ 3-D Secure.",
  "Invalid updates specified": "تحديثات غير صالحة محددة.",
  "Manual investigation required": "يتطلب تحقيق يدوي.",
  "Invalid headers": "رؤوس غير صالحة.",
  "Max fraudscore exceeded": "تم تجاوز الحد الأقصى لنقاط الاحتيال.",
  "Invalid filters": "الفلاتر المقدمة غير صالحة.",
  "Merchant System Error": "خطأ في نظام التاجر.",
  "Your payment is being processed. Please wait...": "يتم معالجة دفعتك. يرجى الانتظار...",
  "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "لا يمكن تحديد كلا من 'وصف نوع الطلب' و 'وصف أنواع الطلبات' في طلب واحد.",
  "Acquirer missing original transaction data": "المشتري يفتقد بيانات المعاملة الأصلية.",
  "Insufficient funds": "الأموال غير كافية.",
  "Unable to process due to scheme restrictions": "غير قادر على المعالجة بسبب قيود النظام.",
  "Failed Screening": "فشل الفحص.",
  "Unable to process due to restrictions": "غير قادر على المعالجة بسبب القيود.",
  "Invalid process": "عملية غير صالحة.",
  "Risk Referral": "يتطلب إحالة المخاطر.",
  "Name Pick required": "الاسم مطلوب للاختيار.",
  "Address Pick required": "العنوان مطلوب للاختيار.",
  "IP not in range": "عنوان IP خارج النطاق.",
  "Invalid button configuration": "تكوين الزر غير صالح.",
  "Unrecognised response from acquirer": "استجابة غير معترف بها من المشتري.",
  "Decline": "رفض.",
  "Uncertain result": "نتيجة غير مؤكدة.",
  "Soft Decline": "رفض طفيف.",
  "Refer to Issuer": "يرجى الرجوع إلى الجهة المصدرة لمزيد من المعلومات.",
  "Request is queued please check the transaction later for the status": "الطلب في قائمة الانتظار. يرجى التحقق من حالة المعاملة لاحقًا.",
  "Generic Retry": "إعادة المحاولة العامة.",
  "Soft Decline retry": "إعادة المحاولة لرفض طفيف.",
  "There has been a problem with your payment, please verify your details and try again": "حدثت مشكلة في دفعتك. يرجى التحقق من التفاصيل والمحاولة مرة أخرى.",
  "Unknown error": "خطأ غير معروف.",

  "Login": "تسجيل الدخول",
  "Enter Your Email": "أدخل بريدك الإلكتروني",
  "Login With Phone Number": "تسجيل الدخول برقم الهاتف",
  "Verify Using Sms/Email": "التحقق باستخدام الرسائل القصيرة / البريد الإلكتروني",
  "Resend Code In 100 Seconds": "أعد إرسال الرمز خلال 100 ثانية",
  "Verify": "تحقق",
  "Verifying": "جارٍ التحقق",
  "Last Week": "الأسبوع الماضي",
  "Older Transaction": "معاملة أقدم",
  "Total": "الإجمالي",
  "Conversion Confirmed": "تم تأكيد التحويل",
  "User Account Limit": "حد حساب المستخدم",
  "Monthly Balance Limit": "حد الرصيد الشهري",
  "Total Limit": "الحد الإجمالي",
  "Used": "المستخدم",
  "Remaining": "المتبقي",
  "Allowed Minimum Topup Amount": "الحد الأدنى المسموح به لإعادة التعبئة",
  "Allowed Maximum Topup Amount": "الحد الأقصى المسموح به لإعادة التعبئة",
  "Incoming Limit": "حد الأموال الواردة",
  "Daily limit": "الحد اليومي",
  "Monthly limit": "الحد الشهري",
  "Yearly limit": "الحد السنوي",
  "Sending Limit": "حد الإرسال",
  "Allowed Number Of Transactions": "عدد المعاملات المسموح بها",
  "Total Amount": "المبلغ الإجمالي",
  "Amount To Send": "المبلغ المرسل",
  "Destination Amount": "المبلغ المستلم",
  "Note": "ملاحظة",
  "Revised Amount": "المبلغ المعدل",
  "Save": "حفظ",
  "Date": "التاريخ",
  "Action": "الإجراء",
  "Payment Scheduled At": "الدفعة مجدولة في",
  "Transaction Fee": "رسوم المعاملة",
  "Number Of Cycle": "عدد الدورات",
  "Subscription Starting Date": "تاريخ بدء الاشتراك",
  "Until": "حتى",
  "Next Transaction": "المعاملة التالية",
  "Pay Now": "ادفع الآن",
  "Transaction Method": "طريقة الدفع",
  "Mobile Money Provider": "مزود الأموال المتنقلة",
  "Wallet Name": "اسم المحفظة",
  "Wallet Number": "رقم المحفظة",
  "Crypto Currency": "العملة المشفرة",
  "Wallet Address": "عنوان المحفظة",
  "Select Document Type": "اختر نوع المستند",
  "Edit Document Number": "تحرير رقم المستند",
  "My Withdrawals": "سحبي",
  "Bargainable": "قابل للتفاوض",
  "Description": "الوصف",
  "Attached Files": "الملفات المرفقة",
  "Sent": "المرسلة",
  "Accepted": "مقبول",
  "Decline": "رفض",
  "Pending": "قيد الانتظار",
  "Overall Rating": "التقييم العام",
  "No Review": "لا توجد مراجعة",
  "0 Rating": "تقييم 0",
  "Transaction Map": "خريطة المعاملات",
  "Send Money": "إرسال المال",
  "Sending Method": "طريقة الإرسال",
  "Trouble Sending Money": "مشاكل في إرسال الأموال",
  "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "قد يختلف الرسم والمبلغ المستلم بناءً على طريقة الدفع المختارة.",
  "Select Method": "اختر الطريقة",
  "Completed": "مكتمل",
  "How Was Your Experience?": "كيف كانت تجربتك؟",
  "Leave A Review": "اترك مراجعة",
  "Review History": "تاريخ المراجعات",
  "Write A Review": "اكتب مراجعة",
  "Send And Post": "إرسال ونشر",
  "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out التحويل من التشفير إلى الفيات هو حل قوي وآمن يقوم بتحويل العملات الرقمية إلى العملات التقليدية بسلاسة. يلبي احتياجات المستخدمين المتنوعة من خلال توفير العديد من قنوات الدفع المخصصة لتناسب التفضيلات الفردية والمواقع",
  "Review Created": "تم إنشاء المراجعة",
  "Transactions": "المعاملات",
  "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "سحب/إفلات أو رفع ملف (صورة/فيديو/Pdf)",
  "Max Size 1GB": "الحد الأقصى للحجم 1GB",
  "Title Description": "وصف العنوان",
  "Add New": "إضافة جديد",
  "Discard": "تجاهل",
  "Save And Post": "حفظ ونشر",
  "Personal Account": "الحساب الشخصي",
  "Conversion": "التحويل",
  "Spent In Total": "إجمالي الإنفاق",
  "International": "دولي",
  "Recharge": "إعادة الشحن",
  "Top Up Wallet To Wallet": "شحن المحفظة إلى محفظة",
  "PayPal": "PayPal",
  "Instapay Wallet": "محفظة Instapay",
  "Not Connected": "غير متصل",
  "More": "المزيد",
  "Less": "أقل",
  "Profile Reports": "تقارير الملف الشخصي",
  "Timezone": "المنطقة الزمنية",
  "Cancel": "إلغاء",
  "Full Name": "الاسم الكامل",
  "Google Authenticator": "Google Authenticator",
  "Documents Supplémentaires": "مستندات إضافية",
  "Profile Complete": "الملف الشخصي مكتمل",
  "Verified": "تم التحقق",
  "Bank Account": "الحساب البنكي",
  "Mobile Wallet": "المحفظة المحمولة",
  "Cash Pickup": "استلام النقود",
  "Add": "إضافة",
  "Configured": "تم التكوين",
  "Email": "البريد الإلكتروني",
  "Download Data": "تنزيل البيانات",
  "Enter The Amount": "أدخل المبلغ",
  "They Receive": "هم يتلقون",
  "Where Do You Want To Send Money": "أين تريد إرسال الأموال",
  "Bank Transfer": "التحويل البنكي",
  "Receiving Method": "طريقة الاستلام",
  "Payer": "الدافع",
  "Minimum Amount": "المبلغ الأدنى",
  "Maximum Amount": "المبلغ الأقصى",
  "Search": "بحث",
  "Show More": "عرض المزيد",
  "Select Remark / Reasons (All)": "اختر الملاحظة / الأسباب (الجميع)",
  "Attach Files": "إرفاق الملفات",
  "Holiday List": "قائمة العطلات",
  "No Data To Show": "لا توجد بيانات لعرضها",
  "Threshold Limits": "حدود العتبة",
  "Amount Limits": "حدود المبلغ",
  "OTP Verification Code": "رمز التحقق OTP",
  "Verify Using Authenticator": "التحقق باستخدام Authenticator",
  "Sms/Email Verification Code": "رمز التحقق باستخدام الرسائل القصيرة / البريد الإلكتروني",
  "Invite Them": "دعهم",
  "Lookup": "البحث",
  "Invite Friend": "دعوة صديق",
  "Invite Via Phone Number": "دعوة عبر رقم الهاتف",
  "Invite Via Email Address": "دعوة عبر البريد الإلكتروني",
  "Next": "التالي",
  "Back": "العودة",
  "Invite Via Email": "دعوة عبر البريد الإلكتروني",
  "Available Currency": "العملة المتاحة",
  "Add Remarks And Comments": "إضافة ملاحظات وتعليقات",
  "Recipient Details": "تفاصيل المستلم",
  "Go Back": "العودة",
  "Trouble Requesting Money": "مشكلة في طلب المال",
  "Instant": "فوري",
  "Subscription": "الاشتراك",
  "Schedule": "الجدول الزمني",
  "Choose Request Type": "اختر نوع الطلب",
  "Mobile Airtime": "رصيد الهاتف المحمول",
  "Incorrect Phone Number": "رقم الهاتف غير صحيح",
  "Make Sure You Have Entered A Correct Phone Number.": "تأكد من إدخال رقم هاتف صحيح.",
  "Close": "إغلاق",
  "Operator": "المشغل",
  "Recipient Mobile Number": "رقم الهاتف المحمول للمستلم",
  "Amount": "المبلغ",
  "Service": "الخدمة",
  "Destination": "الوجهة",
  "Top Up Summary": "ملخص التعبئة",
  "Pakistan": "باكستان",
  "Daily Sending Limit Exceeded": "تم تجاوز الحد اليومي للإرسال",
  "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "أرسل عرض أسعار مفصل إلى عميلك أو زبونك. قدم خدماتك أو منتجاتك أو عروضك بشكل احترافي مع عرض أسعار مخصص.",
  "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "المدفوعات التي تتم في أيام العمل قبل الساعة 3:00 مساءً ستتم إضافتها إلى حساب البنك المستقبل قبل منتصف الليل من نفس اليوم.",
  "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "قم بإعداد حسابات الاستلام الخاصة بك في ثلاثة بلدان كحد أقصى وسحب الأموال على الفور من الطلبات المدفوعة المعتمدة إلى قنوات الدفع الخاصة بك.",
  "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "يحتفظ مملكة كيميت بالحق في طلب أي مستندات إضافية (العناية الواجبة المحسنة - EDD) أو مزيد من المعلومات اللازمة لإجراء التحقق الدقيق، وفقًا لالتزاماتها القانونية.",
  "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "يجب أن يكون مستند هوية صادر عن الحكومة مع صورة. يجب كتابة جميع مستندات الهوية بالأبجدية اللاتينية. إذا لم يكن الأمر كذلك، يتطلب الأمر ترجمة معتمدة.",
  "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "اعرض عملك، وزيّع نطاقك. \"محفظتي\" هي لوحتك الرقمية على Instapay. قم بتحميل وعرض مجموعة متنوعة من المحتوى - من الصور والفيديوهات الجذابة إلى ملفات PDF المعلوماتية. سواء كنت فنانًا أو مستقلًا أو رائد أعمال، يتيح لك هذا الميزة تقديم عملك أو مشاريعك بطريقة مرئية جذابة.",
  "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "شارك رمز الاستجابة السريعة (QR) أو رابط الدفع للحصول على أموال على الفور في محفظتك الرقمية Insta Wallet.",
  "Afghanistan": "أفغانستان",
  "Aland Islands": "جزر آلاند",
  "Albania": "ألبانيا",
  "Algeria": "الجزائر",
  "American Samoa": "ساموا الأمريكية",
  "Andorra": "أندورا",
  "Angola": "أنغولا",
  "Anguilla": "أنغيلا",
  "Antigua and Barbuda": "أنتيغوا وبربودا",
  "Argentina": "الأرجنتين",
  "Armenia": "أرمينيا",
  "Aruba": "أروبا",
  "Australia": "أستراليا",
  "Austria": "النمسا",
  "Azerbaijan": "أذربيجان",
  "Bahamas": "جزر البهاما",
  "Bahrain": "البحرين",
  "Bangladesh": "بنغلاديش",
  "Barbados": "باربادوس",
  "Belarus": "بيلاروسيا",
  "Belgium": "بلجيكا",
  "Belize": "بليز",
  "Benin": "بنين",
  "Bermuda": "برمودا",
  "Bhutan": "بوتان",
  "Bolivia (Plurinational State of)": "بوليفيا (دولة متعددة القوميات)",
  "Bonaire - Sint Eustatius and Saba": "بونير - سينت أوستاتيوس وصابا",
  "Bosnia and Herzegovina": "البوسنة والهرسك",
  "Botswana": "بوتسوانا",
  "Brazil": "البرازيل",
  "British Indian Ocean Territory": "إقليم المحيط الهندي البريطاني",
  "Brunei Darussalam": "بروناي دار السلام",
  "Bulgaria": "بلغاريا",
  "Burkina Faso": "بوركينا فاسو",
  "Burundi": "بوروندي",
  "Cabo Verde": "الرأس الأخضر",
  "Cambodia": "كمبوديا",
  "Cameroon": "الكاميرون",
  "Canada": "كندا",
  "Cayman Islands": "جزر كايمان",
  "Central African Republic": "جمهورية إفريقيا الوسطى",
  "Chad": "تشاد",
  "Chile": "تشيلي",
  "China": "الصين",
  "Christmas Island": "جزيرة الكريسماس",
  "Cocos (Keeling) Islands": "جزر كوكوس (كيلينغ)",
  "Colombia": "كولومبيا",
  "Comoros": "جزر القمر",
  "Congo": "جمهورية الكونغو",
  "Congo - Democratic Republic of the": "جمهورية الكونغو الديمقراطية",
  "Cook Islands": "جزر كوك",
  "Costa Rica": "كوستاريكا",
  "Cote d'Ivoire": "ساحل العاج",
  "Croatia": "كرواتيا",
  "Cuba": "كوبا",
  "Curacao": "كوراساو",
  "Cyprus": "قبرص",
  "Czech Republic": "جمهورية التشيك",
  "Denmark": "الدنمارك",
  "Djibouti": "جيبوتي",
  "Dominica": "دومينيكا",
  "Dominican Republic": "جمهورية الدومينيكان",
  "Ecuador": "الإكوادور",
  "Egypt": "مصر",
  "El Salvador": "السلفادور",
  "Equatorial Guinea": "غينيا الاستوائية",
  "Eritrea": "إريتريا",
  "Estonia": "إستونيا",
  "Eswatini": "إسواتيني",
  "Ethiopia": "إثيوبيا",
  "Falkland Islands (Malvinas)": "جزر فوكلاند (مالفيناس)",
  "Faroe Islands": "جزر فارو",
  "Fiji": "فيجي",
  "Finland": "فنلندا",
  "France": "فرنسا",
  "French Polynesia": "بولينيزيا الفرنسية",
  "French Southern Territories": "الأراضي الفرنسية الجنوبية والقطبية",
  "Gabon": "الغابون",
  "Gambia": "غامبيا",
  "Georgia": "جورجيا",
  "Germany": "ألمانيا",
  "Ghana": "غانا",
  "Gibraltar": "جبل طارق",
  "Greece": "اليونان",
  "Greenland": "غرينلاند",
  "Grenada": "غرينادا",
  "Guam": "غوام",
  "Guatemala": "غواتيمالا",
  "Guernsey": "غيرنسي",
  "Guinea (Conakry)": "غينيا (كوناكري)",
  "Guinea-Bissau": "غينيا بيساو",
  "Guyana": "غيانا",
  "Haiti": "هايتي",
  "Heard Island and McDonald Islands": "جزر هيرد وماكدونالد",
  "Holy See": "الكرسي الرسولي",
  "Honduras": "هندوراس",
  "Hong Kong": "هونغ كونغ",
  "Hungary": "هنغاريا",
  "Iceland": "آيسلندا",
  "India": "الهند",
  "Indonesia": "إندونيسيا",
  "Iran (Islamic Republic of)": "إيران (جمهورية إسلامية)",
  "Iraq": "العراق",
  "Ireland": "أيرلندا",
  "Isle of Man": "جزيرة مان",
  "Israel": "إسرائيل",
  "Italy": "إيطاليا",
  "Jamaica": "جامايكا",
  "Japan": "اليابان",
  "Jersey": "جيرسي",
  "Jordan": "الأردن",
  "Kazakhstan": "كازاخستان",
  "Kenya": "كينيا",
  "Kiribati": "كيريباتي",
  "Korea (Democratic People's Republic of)": "كوريا (جمهورية كوريا الديمقراطية الشعبية)",
  "Korea - Republic of": "كوريا - جمهورية كوريا",
  "Kuwait": "الكويت",
  "Kyrgyzstan": "قرغيزستان",
  "Lao People's Democratic Republic": "جمهورية لاوس الشعبية الديمقراطية",
  "Latvia": "لاتفيا",
  "Lebanon": "لبنان",
  "Lesotho": "ليسوتو",
  "Liberia": "ليبيريا",
  "Libya": "ليبيا",
  "Liechtenstein": "ليختنشتاين",
  "Lithuania": "ليتوانيا",
  "Luxembourg": "لوكسمبورغ",
  "Macao": "ماكاو",
  "Madagascar": "مدغشقر",
  "Malawi": "مالاوي",
  "Malaysia": "ماليزيا",
  "Maldives": "المالديف",
  "Mali": "مالي",
  "Malta": "مالطا",
  "Marshall Islands": "جزر مارشال",
  "Mauritania": "موريتانيا",
  "Mauritius": "موريشيوس",
  "Mexico": "المكسيك",
  "Micronesia (Federated States of)": "ميكرونيزيا (الدول الفيدرالية)",
  "Moldova - Republic of": "مولدوفا - جمهورية مولدوفا",
  "Monaco": "موناكو",
  "Mongolia": "منغوليا",
  "Montenegro": "مونتينيغرو",
  "Montserrat": "مونتسيرات",
  "Morocco": "المغرب",
  "Mozambique": "موزمبيق",
  "Myanmar": "ميانمار",
  "Namibia": "ناميبيا",
  "Nauru": "ناورو",
  "Nepal": "نيبال",
  "Netherlands": "هولندا",
  "New Caledonia": "كاليدونيا الجديدة",
  "New Zealand": "نيوزيلندا",
  "Nicaragua": "نيكاراغوا",
  "Niger": "النيجر",
  "Nigeria": "نيجيريا",
  "Niue": "نيوي",
  "Norfolk Island": "جزيرة نورفولك",
  "North Macedonia": "مقدونيا الشمالية",
  "Northern Mariana Islands": "جزر ماريانا الشمالية",
  "Norway": "النرويج",
  "Oman": "عمان",
  "Pakistan": "باكستان",
  "Palau": "بالاو",
  "Palestine - State of": "دولة فلسطين",
  "Panama": "بنما",
  "Papua New Guinea": "بابوا غينيا الجديدة",
  "Paraguay": "باراغواي",
  "Peru": "بيرو",
  "Philippines": "الفلبين",
  "Pitcairn": "جزر بيتكيرن",
  "Poland": "بولندا",
  "Portugal": "البرتغال",
  "Puerto Rico": "بورتو ريكو",
  "Qatar": "قطر",
  "Romania": "رومانيا",
  "Russian Federation": "الاتحاد الروسي",
  "Rwanda": "رواندا",
  "Saint Barth\u00e9lemy": "سانت بارتليمي",
  "Saint Helena - Ascension and Tristan da Cunha": "سانت هيلينا - أسيشن وترينيداد دا كونها",
  "Saint Kitts and Nevis": "سانت كيتس ونيفيس",
  "Saint Lucia": "سانت لوسيا",
  "Saint Martin (French part)": "سانت مارتن (الجزء الفرنسي)",
  "Saint Vincent and the Grenadines": "سانت فنسنت والغرينادين",
  "Samoa": "ساموا",
  "San Marino": "سان مارينو",
  "Sao Tome and Principe": "ساو تومي وبرينسيبي",
  "Senegal": "السنغال",
  "Serbia": "صربيا",
  "Seychelles": "سيشيل",
  "Sierra Leone": "سيراليون",
  "Singapore": "سنغافورة",
  "Sint Maarten (Dutch part)": "سينت مارتن (الجزء الهولندي)",
  "Slovakia": "سلوفاكيا",
  "Slovenia": "سلوفينيا",
  "Solomon Islands": "جزر سولومون",
  "Somalia": "الصومال",
  "South Africa": "جنوب أفريقيا",
  "South Georgia and the South Sandwich Islands": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  "South Sudan": "جنوب السودان",
  "Spain": "إسبانيا",
  "Sri Lanka": "سريلانكا",
  "Sudan": "السودان",
  "Suriname": "سورينام",
  "Svalbard and Jan Mayen": "سفالبارد وجزر جان ماين",
  "Sweden": "السويد",
  "Switzerland": "سويسرا",
  "Syrian Arab Republic": "الجمهورية العربية السورية",
  "Tajikistan": "طاجيكستان",
  "United Republic of Tanzania": "جمهورية تنزانيا المتحدة",
  "Thailand": "تايلاند",
  "Timor-Leste": "تيمور الشرقية",
  "Togo": "توجو",
  "Tokelau": "توكلاو",
  "Tonga": "تونغا",
  "Trinidad and Tobago": "ترينيداد وتوباغو",
  "Tunisia": "تونس",
  "Turkey": "تركيا",
  "Turkmenistan": "تركمانستان",
  "Turks and Caicos Islands": "جزر تركس وكايكوس",
  "Tuvalu": "توفالو",
  "Uganda": "أوغندا",
  "Ukraine": "أوكرانيا",
  "United Arab Emirates": "الإمارات العربية المتحدة",
  "United Kingdom of Great Britain and Northern Ireland": "المملكة المتحدة لبريطانيا العظمى وأيرلندا الشمالية",
  "United States of America": "الولايات المتحدة الأمريكية",
  "United States Minor Outlying Islands": "جزر الولايات المتحدة البعيدة",
  "Uruguay": "أوروغواي",
  "Uzbekistan": "أوزبكستان",
  "Vanuatu": "فانواتو",
  "Venezuela (Bolivarian Republic of)": "فنزويلا (جمهورية بوليفار)",
  "Viet Nam": "فيتنام",
  "Virgin Islands (British)": "جزر فرجين البريطانية",
  "Virgin Islands (U.S.)": "جزر فرجين الأمريكية",
  "Wallis and Futuna": "واليس وفوتونا",
  "Yemen": "اليمن",
  "Zambia": "زامبيا",
  "Zimbabwe": "زيمبابوي",
  "Taiwan": "تايوان",
  "Palestine": "فلسطين",
  "Western Sahara": "الصحراء الغربية",
  "Kosovo": "كوسوفو",
  "South Ossetia": "أوسيتيا الجنوبية",
  "Abkhazia": "أبخازيا",
  "Nagorno-Karabakh (Artsakh)": "قره باغ الجبلية (أرتساخ)",
  "Transnistria": "ترانسنيستريا",
  "Somaliland": "صوماليلاند",
  "Northern Cyprus": "قبرص الشمالية",
  "Saudi Arabia": "المملكة العربية السعودية",
  "Yugoslavia": "يوغوسلافيا",

  "Login": "تسجيل الدخول",
  "Enter Your Email": "أدخل بريدك الإلكتروني",
  "Login With Phone Number": "تسجيل الدخول برقم الهاتف",
  "Verify Using Sms/Email": "التحقق باستخدام الرسائل القصيرة / البريد الإلكتروني",
  "Resend Code In 100 Seconds": "أعد إرسال الرمز خلال 100 ثانية",
  "Verify": "تحقق",
  "Verifying": "جارٍ التحقق",
  "Last Week": "الأسبوع الماضي",
  "Older Transaction": "معاملة أقدم",
  "Total": "الإجمالي",
  "Conversion Confirmed": "تم تأكيد التحويل",
  "User Account Limit": "حد حساب المستخدم",
  "Monthly Balance Limit": "حد الرصيد الشهري",
  "Total Limit": "الحد الإجمالي",
  "Used": "المستخدم",
  "Remaining": "المتبقي",
  "Allowed Minimum Topup Amount": "الحد الأدنى المسموح به لإعادة التعبئة",
  "Allowed Maximum Topup Amount": "الحد الأقصى المسموح به لإعادة التعبئة",
  "Incoming Limit": "حد الأموال الواردة",
  "Daily Limit": "الحد اليومي",
  "Monthly Limit": "الحد الشهري",
  "Yearly Limit": "الحد السنوي",
  "Sending Limit": "حد الإرسال",
  "Allowed Number Of Transactions": "عدد المعاملات المسموح بها",
  "Total Amount": "المبلغ الإجمالي",
  "Amount To Send": "المبلغ المرسل",
  "Destination Amount": "المبلغ المستلم",
  "Note": "ملاحظة",
  "Revised Amount": "المبلغ المعدل",
  "Save": "حفظ",
  "Date": "التاريخ",
  "Action": "الإجراء",
  "Payment Scheduled At": "الدفعة مجدولة في",
  "Transaction Fee": "رسوم المعاملة",
  "Number Of Cycle": "عدد الدورات",
  "Subscription Starting Date": "تاريخ بدء الاشتراك",
  "Until": "حتى",
  "Next Transaction": "المعاملة التالية",
  "Pay Now": "ادفع الآن",
  "Transaction Method": "طريقة الدفع",
  "Mobile Money Provider": "مزود الأموال المتنقلة",
  "Wallet Name": "اسم المحفظة",
  "Wallet Number": "رقم المحفظة",
  "Crypto Currency": "العملة المشفرة",
  "Wallet Address": "عنوان المحفظة",
  "Select Document Type": "اختر نوع المستند",
  "Edit Document Number": "تحرير رقم المستند",
  "My Withdrawals": "سحبي",
  "Bargainable": "قابل للتفاوض",
  "Description": "الوصف",
  "Attached Files": "الملفات المرفقة",
  "Sent": "المرسلة",
  "Accepted": "مقبول",
  "Decline": "رفض",
  "Pending": "قيد الانتظار",
  "Overall Rating": "التقييم العام",
  "No Review": "لا توجد مراجعة",
  "0 Rating": "تقييم 0",
  "Transaction Map": "خريطة المعاملات",
  "Send Money": "إرسال المال",
  "Sending Method": "طريقة الإرسال",
  "Trouble Sending Money": "مشاكل في إرسال الأموال",
  "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "قد يختلف الرسم والمبلغ المستلم بناءً على طريقة الدفع المختارة.",
  "Select Method": "اختر الطريقة",
  "Completed": "مكتمل",
  "How Was Your Experience?": "كيف كانت تجربتك؟",
  "Leave A Review": "اترك مراجعة",
  "Review History": "تاريخ المراجعات",
  "Write A Review": "اكتب مراجعة",
  "Send And Post": "إرسال ونشر",
  "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out التحويل من التشفير إلى الفيات هو حل قوي وآمن يقوم بتحويل العملات الرقمية إلى العملات التقليدية بسلاسة. يلبي احتياجات المستخدمين المتنوعة من خلال توفير العديد من قنوات الدفع المخصصة لتناسب التفضيلات الفردية والمواقع",
  "Review Created": "تم إنشاء المراجعة",
  "Transactions": "المعاملات",
  "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "سحب/إفلات أو رفع ملف (صورة/فيديو/Pdf)",
  "Max Size 1GB": "الحد الأقصى للحجم 1GB",
  "Title Description": "وصف العنوان",
  "Add New": "إضافة جديد",
  "Discard": "تجاهل",
  "Save And Post": "حفظ ونشر",
  "Personal Account": "الحساب الشخصي",
  "Conversion": "التحويل",
  "Spent In Total": "إجمالي الإنفاق",
  "International": "دولي",
  "Recharge": "إعادة الشحن",
  "Top Up Wallet To Wallet": "شحن المحفظة إلى محفظة",
  "PayPal": "PayPal",
  "Instapay Wallet": "محفظة Instapay",
  "Not Connected": "غير متصل",
  "More": "المزيد",
  "Less": "أقل",
  "Profile Reports": "تقارير الملف الشخصي",
  "Timezone": "المنطقة الزمنية",
  "Cancel": "إلغاء",
  "Full Name": "الاسم الكامل",
  "Google Authenticator": "Google Authenticator",
  "Documents Supplémentaires": "مستندات إضافية",
  "Profile Complete": "الملف الشخصي مكتمل",
  "Verified": "تم التحقق",
  "Bank Account": "الحساب البنكي",
  "Mobile Wallet": "المحفظة المحمولة",
  "Cash Pickup": "استلام النقود",
  "Add": "إضافة",
  "Configured": "تم التكوين",
  "Email": "البريد الإلكتروني",
  "Download Data": "تنزيل البيانات",
  "Enter The Amount": "أدخل المبلغ",
  "They Receive": "هم يتلقون",
  "Where Do You Want To Send Money": "أين تريد إرسال الأموال",
  "Bank Transfer": "التحويل البنكي",
  "Receiving Method": "طريقة الاستلام",
  "Payer": "الدافع",
  "Minimum Amount": "المبلغ الأدنى",
  "Maximum Amount": "المبلغ الأقصى",
  "Search": "بحث",
  "Show More": "عرض المزيد",
  "Select Remark / Reasons (All)": "اختر الملاحظة / الأسباب (الجميع)",
  "Attach Files": "إرفاق الملفات",
  "Holiday List": "قائمة العطلات",
  "No Data To Show": "لا توجد بيانات لعرضها",
  "Threshold Limits": "حدود العتبة",
  "Amount Limits": "حدود المبلغ",
  "OTP Verification Code": "رمز التحقق OTP",
  "Verify Using Authenticator": "التحقق باستخدام Authenticator",
  "Sms/Email Verification Code": "رمز التحقق باستخدام الرسائل القصيرة / البريد الإلكتروني",
  "Invite Them": "دعهم",
  "Lookup": "البحث",
  "Invite Friend": "دعوة صديق",
  "Invite Via Phone Number": "دعوة عبر رقم الهاتف",
  "Invite Via Email Address": "دعوة عبر البريد الإلكتروني",
  "Next": "التالي",
  "Back": "العودة",
  "Invite Via Email": "دعوة عبر البريد الإلكتروني",
  "Available Currency": "العملة المتاحة",
  "Add Remarks And Comments": "إضافة ملاحظات وتعليقات",
  "Recipient Details": "تفاصيل المستلم",
  "Go Back": "العودة",
  "Trouble Requesting Money": "مشكلة في طلب المال",
  "Instant": "فوري",
  "Subscription": "الاشتراك",
  "Schedule": "الجدول الزمني",
  "Choose Request Type": "اختر نوع الطلب",
  "Mobile Airtime": "رصيد الهاتف المحمول",
  "Incorrect Phone Number": "رقم الهاتف غير صحيح",
  "Make Sure You Have Entered A Correct Phone Number.": "تأكد من إدخال رقم هاتف صحيح.",
  "Close": "إغلاق",
  "Operator": "المشغل",
  "Recipient Mobile Number": "رقم الهاتف المحمول للمستلم",
  "Amount": "المبلغ",
  "Service": "الخدمة",
  "Destination": "الوجهة",
  "Top Up Summary": "ملخص التعبئة",
  "Daily Sending Limit Exceeded": "تم تجاوز الحد اليومي للإرسال",
  "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "أرسل عرض أسعار مفصل إلى عميلك أو زبونك. قدم خدماتك أو منتجاتك أو عروضك بشكل احترافي مع عرض أسعار مخصص.",
  "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "المدفوعات التي تتم في أيام العمل قبل الساعة 3:00 مساءً ستتم إضافتها إلى حساب البنك المستقبل قبل منتصف الليل من نفس اليوم.",
  "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "قم بإعداد حسابات الاستلام الخاصة بك في ثلاثة بلدان كحد أقصى وسحب الأموال على الفور من الطلبات المدفوعة المعتمدة إلى قنوات الدفع الخاصة بك.",
  "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "يحتفظ مملكة كيميت بالحق في طلب أي مستندات إضافية (العناية الواجبة المحسنة - EDD) أو مزيد من المعلومات اللازمة لإجراء التحقق الدقيق، وفقًا لالتزاماتها القانونية.",
  "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "يجب أن يكون مستند هوية صادر عن الحكومة مع صورة. يجب كتابة جميع مستندات الهوية بالأبجدية اللاتينية. إذا لم يكن الأمر كذلك، يتطلب الأمر ترجمة معتمدة.",
  "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "اعرض عملك، وزيّع نطاقك. \"محفظتي\" هي لوحتك الرقمية على Instapay. قم بتحميل وعرض مجموعة متنوعة من المحتوى - من الصور والفيديوهات الجذابة إلى ملفات PDF المعلوماتية. سواء كنت فنانًا أو مستقلًا أو رائد أعمال، يتيح لك هذا الميزة تقديم عملك أو مشاريعك بطريقة مرئية جذابة.",
  "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "شارك رمز الاستجابة السريعة (QR) أو رابط الدفع للحصول على أموال على الفور في محفظتك الرقمية Insta Wallet.",
  "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "لديك 15 دقيقة لإتمام المعاملة.",
  "Time Remaining": "يرجى الإكمال ضمن الوقت المحدد لتجنب انتهاء صلاحية الرابط.",
  "Please Process The Payment Via Your Selected Payment Type": "يرجى معالجة الدفع باستخدام طريقة الدفع التي اخترتها",
  "Top-Up Wallet": "نوع الدفع",
  "Confirm Your Payment": "إعادة شحن المحفظة",
  "You Will Get": "أكد الدفع الخاص بك",
  "Amount To Pay": "ستحصل على",
  "Proceed To Payment": "المبلغ المطلوب دفعه",
  "Load/Deposit Money To The Wallet": "المتابعة إلى الدفع",
  "Withdraw": "إيداع الأموال في المحفظة",
  "Elevate Your Business With InstaPay": "سحب",
  "Higher Balances & Transaction Limits": "ارتقِ بأعمالك مع InstaPay",
  "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "أرصدة أعلى وحدود معاملات أكبر",
  "Full Feature Access": "افتح حرية إدارة أرصدة أكبر ومعالجة كميات أكبر من المعاملات مع الموثوقية التي تتوقعها من InstaPay.",
  "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "الوصول الكامل إلى الميزات",
  "Global Reach": "النطاق العالمي",
  "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "وسّع نطاق عملك بلا حدود. يربطك InstaPay بالعملاء والشركاء حول العالم، مما يجعل المعاملات العالمية أسهل من أي وقت مضى.",
  "Advanced Security": "الأمان المتقدم",
  "Business Registration Available Soon\n": "التسجيل التجاري سيكون متاحًا قريبًا.",
  "Amount After Fee": "المبلغ بعد الرسوم",
  "Allowed Maximum Amount Per Transaction": "الحد الأقصى المسموح به لكل معاملة",
  "Transfer": "تحويل",
  "Uncategorized": "غير مصنف",
  "Currency ISO Code & Amount": "رمز العملة ISO والمبلغ",
  "Choose The Top-Up Channel": "اختر قناة الشحن",
  "You Selected": "التي اخترتها",
  "Payout Channel": "قناة الدفع",
  "Your Details": "تفاصيلك",
  "Detailed Confirmed": "التفاصيل المؤكدة",
  "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "عزز إمكاناتك المالية بترقية حسابك إلى InstaPay Business. استمتع بكامل ميزات InstaPay المصممة خصيصًا لنمو الأعمال.",
  "Profile Picture": "صورة الملف الشخصي",
  "Add Photo Or Logo": "أضف صورة أو شعار",
  "First Name": "الاسم الأول",
  "Last Name": "الاسم الأخير",
  "Enter Your First Name": "أدخل اسمك الأول",
  "Enter Your Last Name": "أدخل اسمك الأخير",
  "Email (Optional)": "البريد الإلكتروني (اختياري)",
  "Password Must": "يجب أن تحتوي كلمة المرور",
  "It Will Be Autofill If You're Signing Up With Invitation Link": "سيتم ملؤه تلقائيًا إذا كنت تسجل باستخدام رابط دعوة.",
  "Change Number": "تغيير الرقم",
  "Search Country": "البحث عن بلد",
  "Select From The List": "اختر من القائمة",
  "Received Amount": "المبلغ المستلم",

  "null": null,
  "Processing": "معالجة",
  "Verify Using Sms/Email": "تحقق باستخدام الرسائل القصيرة/البريد الإلكتروني",
  "Bitcoin (BTC)": "بيتكوين (BTC)",
  "Tether (USDT)": "تيثر (USDT)",
  "Ethereum (ETH)": "إيثيريوم (ETH)",
  "Already Exist": "موجود بالفعل",
  "You Can Have Only 8 Wallets": "يمكنك أن تمتلك فقط 8 محافظ",
  "Save": "حفظ",
  "0 File": "0 ملف",
  "Search From The List": "ابحث من القائمة",
  "Amount To Send": "المبلغ المراد إرساله",
  "Wallet To Wallet": "من محفظة إلى محفظة",
  "TOP-UP WALLET ": "تعبئة المحفظة",
  "Enter The Amount": "أدخل المبلغ",
  "Load/Deposit Money To The Wallet": "أضف/قم بإيداع الأموال في المحفظة",
  "Choose The Top-Up Channel": "اختر قناة التعبئة",
  "Paypal": "بايبال",
  "You Will Get": "ستحصل على",
  "Amount To Pay": "المبلغ المطلوب دفعه",
  "Card Holder Name ": "اسم حامل البطاقة",
  "Card Number": "رقم البطاقة",
  "CVC": "CVC",
  "MM/YY": "شهر/سنة",
  "Your Details ": "تفاصيلك",
  "You Selected": "تم اختيارك",
  "Detailed Confirmed": "تأكيد مفصل",
  "Transaction Failed": "فشلت العملية",
  "Something Went Wrong. Try Again.": "حدث خطأ ما. حاول مرة أخرى.",
  "Convert Funds": "تحويل الأموال",
  "How Would You Like To Cashout?:": "كيف تريد السحب؟:",
  "Default Receiving Account": "حساب الاستلام الافتراضي",
  "Specify Receiving Account": "حدد حساب الاستلام",
  "Which Receiving Account Would You Want Use?:": "ما هو الحساب الذي تريد استخدامه؟:",
  "Pakistan": "باكستان",
  "Bank Mobile": "البنك المحمول",
  "PDF": "PDF",
  "CSV": "CSV",
  "None": "لا شيء",
  "Request": "طلب",
  "Subscription Payment": "دفع الاشتراك",
  "Is not supported by Paypal, USD will be used as the default currency.": "غير مدعوم بواسطة PayPal، سيتم استخدام الدولار الأمريكي كعملة افتراضية.",
  "Review Payment": "مراجعة الدفع",
  "Google Authenticator": "مصادّق جوجل",
  "Scheduled Has Been Cancelled": "تم إلغاء الجدولة",
  "Select Country First": "اختر الدولة أولاً",
  "Select Gender": "اختر الجنس",
  "Select Occupation": "اختر المهنة",
  "IBAN and SWIFT/BIC": "IBAN و SWIFT/BIC",
  "Mobile Money Provider": "مزود الأموال المتنقلة",
  "Wallet Name": "اسم المحفظة",
  "Wallet Number ": "رقم المحفظة",
  "Crypto Currency": "العملة الرقمية",
  "Wallet Address ": "عنوان المحفظة",
  "Select Document Type ": "اختر نوع الوثيقة",
  "Enter Document Number": "أدخل رقم الوثيقة",
  "Msisdn": "Msisdn",
  "Edit ": "تعديل",
  "My Withdrawls": "سحبي",
  "Declined": "مرفوض",
  "Accepted": "مقبول",
  "Overall Ratings": "التقييمات العامة",
  "0 Ratings": "0 تقييمات",
  "No Reviews": "لا توجد مراجعات",
  "Send Money": "إرسال الأموال",
  "Trouble Sending Money?": "هل تواجه مشكلة في إرسال الأموال؟",
  "Payment Request": "طلب الدفع",
  "How Was Your Experience?": "كيف كانت تجربتك؟",
  "Leave A Review": "اترك مراجعة",
  "Write Review Message": "اكتب رسالة مراجعة",
  "Send And Post": "إرسال ونشر",
  "You Rated": "قمت بالتقييم",
  "Review History": "سجل المراجعات",
  "Max Size 1GB": "الحجم الأقصى 1 جيجابايت",
  "Title": "العنوان",
  "Description": "الوصف",
  "Add New": "أضف جديد",
  "My Payment Address": "عنوان الدفع الخاص بي",
  "Personal Account": "الحساب الشخصي",
  "Updated Successfully": "تم التحديث بنجاح",
  "Spent In": "أنفق في",
  "TOP UP": "تعبئة",
  "Quotation": "عرض الأسعار",
  "Amount": "المبلغ",
  "Not Connected": "غير متصل",
  "More": "المزيد",
  "Less": "أقل",
  "Reports": "التقارير",
  "User Profile": "ملف المستخدم",
  "Cancel": "إلغاء",
  "Saving": "حفظ",
  "Confirm ": "تأكيد",
  "Verify ": "التحقق",
  "Delete Country": "حذف البلد",
  "Bank Account": "الحساب البنكي",
  "Cash Pickup": "استلام نقدي",
  "Mobile Wallet ": "محفظة الهاتف المحمول",
  "Delete Country Withdrawals": "حذف سحوبات البلد",
  "Yes , Delete It": "نعم، حذفه",
  "E-sim": "إي-شريحة",
  "Utilities": "خدمات",
  "Searching": "البحث",
  "Recipient Mobile Number": "رقم هاتف المستلم",
  "TOP-UP SUMMARY": "ملخص الشحن",
  "Trouble Requesting Money?": "مشكلة في طلب المال؟",
  "Choose The Beneficiary": "اختر المستفيد",
  "Enter The amount": "أدخل المبلغ",
  "You selected": "اخترت",
  "Timezone": "المنطقة الزمنية",
  "Starting From": "بدءًا من",
  "Recurring Cycle": "الدورة المتكررة",
  "Until I stop": "حتى أتوقف",
  "Schedule To": "جدولة إلى",
  "Schedule at": "الجدولة في",
  "Selected time must be at least 5 minutes from now.": "يجب أن يكون الوقت المحدد لا يقل عن 5 دقائق من الآن.",
  "Is this your Location?": "هل هذا هو موقعك؟",
  "Yes ": "نعم",
  "Select Remark Reason": "اختر سبب الملاحظة",
  "Attach File": "إرفاق ملف",
  "Invite via Phone Number": "دعوة عبر رقم الهاتف",
  "Invite Friend": "دعوة صديق",
  "Invite via Email Address": "دعوة عبر البريد الإلكتروني",
  "Invite via": "دعوة عبر",
  "Phone": "الهاتف:",
  "Message": "الرسالة:",
  "Send": "إرسال",
  "Invited Succesfully!": "تمت الدعوة بنجاح!",
  "Email": "البريد الإلكتروني",
  "Bank Transfer": "تحويل بنكي",
  "Mobile Wallet": "محفظة موبايل",
  "Select The Bank": "اختر البنك",
  "Select The Payer": "اختر الدافع",
  "Min Amount:": "الحد الأدنى للمبلغ:",
  "Max Amount": "الحد الأقصى للمبلغ",
  "Other Beneficiaries:": "المستفيدون الآخرون:",
  "Back ": "العودة",
  "Next": "التالي",
  "Lookup": "البحث",
  "Invite Them": "دعوة لهم",
  "Delete Channel ": "حذف القناة",
  "Set As Default": "تعيين كافتراضي",
  "See More": "عرض المزيد",
  "Session Details": "تفاصيل الجلسة",
  "Platform": "المنصة",
  "Windows": "ويندوز",
  "Browser Name": "اسم المتصفح",
  "Google Chrome": "جوجل كروم",
  "Browser Version": "إصدار المتصفح",
  "IP Address": "عنوان IP",
  "Karachi": "كراتشي",
  "State": "الولاية",
  "Sindh": "السند",
  "Active": "نشط",
  "This currency wallet is currently blocked/disabled and funds cannot be added.": "هذه المحفظة للعملات الرقمية محجوزة/معطلة حالياً ولا يمكن إضافة الأموال.",
  "This currency wallet is currently blocked/disabled and funds cannot be converted.": "هذه المحفظة للعملات الرقمية محجوزة/معطلة حالياً ولا يمكن تحويل الأموال.",
  "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "هذه المحفظة للعملات الرقمية محجوزة/معطلة حالياً ولا يمكنك سحب الرصيد.",
  " This currency wallet is currently blocked/disabled and you cannot download the statement.": "هذه المحفظة للعملات الرقمية محجوزة/معطلة حالياً ولا يمكنك تنزيل البيان.",
  "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "هذه المحفظة للعملات الرقمية حالياً تم تعيينها كافتراضية ولا يمكن حظرها. للمتابعة، يرجى تعيين محفظة عملات رقمية مختلفة كافتراضية أولاً.",
  "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "هذه المحفظة للعملات الرقمية حالياً تم تعيينها كافتراضية ولا يمكن تعطيلها. للمتابعة، يرجى تعيين محفظة عملات رقمية مختلفة كافتراضية أولاً.",
  "Are you sure, you want to delete the withdrawal details of this country?": "هل أنت متأكد أنك تريد حذف تفاصيل السحب لهذا البلد؟",
  "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "قم بإعداد حسابات الاستلام الخاصة بك في ما يصل إلى ثلاثة بلدان وسحب الأموال على الفور من الطلبات المدفوعة المعتمدة إلى قنوات الدفع الخاصة بك.",
  "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "لم يتم العثور على أي مستفيد من باكستان مع تفاصيل Allied Bank Limited",
  "Invitation Message Has Been Successfully Sent To": "تم إرسال رسالة الدعوة بنجاح إلى",
  "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "قد تختلف الرسوم والمبلغ المستلم حسب طريقة الدفع المختارة.",
  "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences، Mission Road، Moin Steel Market، Ranchore Line، Karachi District، Karachi Division، Sindh، 72500، باكستان",
  "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "شارك رمز الاستجابة السريعة أو رابط الدفع الخاص بك للحصول على المدفوعات فوراً في محفظتك على Insta.",
  "Drag/Drop Or Upload A File (Photo/Video/PDF)": "اسحب/افلت أو قم بتحميل ملف (صورة/فيديو/PDF)",
  "Login Again": "تسجيل الدخول مرة أخرى",
  "Unblock The Wallet": "إلغاء حظر المحفظة",
  "Invalid Amount": "مبلغ غير صالح",
  "Add Payment Card": "إضافة بطاقة الدفع",
  "Save Details To Use Next Time": "حفظ التفاصيل للاستخدام لاحقًا",
  "Paid": "مدفوع",
  "Started On": "بدأ في",
  "Num Of Cycles": "عدد الدورات",
  "Ends On": "ينتهي في",
  "Next Payment At": "الدفع التالي في",
  "Beneficiary": "المستفيد",
  "Recieved Quotation": "الاقتباس المستلم",
  "Sent Quotation": "الاقتباس المرسل",
  "Decline Offer": "رفض العرض",
  "Are You Sure That You Want To Decline The Offer?": "هل أنت متأكد أنك تريد رفض العرض؟",
  "Decline The Offer": "رفض العرض",
  "Bargain Offer": "عرض المساومة",
  "Enter Counter Amount": "إدخال مبلغ مضاد",
  "Custom Amount": "مبلغ مخصص",
  "Send The Offer": "إرسال العرض",
  "The Offer Is Sent": "العرض تم إرساله",
  "Countered Offer": "عرض مضاد",
  "Applied": "تم التطبيق",
  "Accept Quotation": "قبول الاقتباس",
  "Quotation Not Found Or Already Exist": "الاقتباس غير موجود أو موجود مسبقًا",
  "Recieved Request": "الطلب المستلم",
  "Sent Request": "الطلب المرسل",
  "Search Request": "البحث عن طلب",
  "Search Quotation": "البحث عن اقتباس",
  "QR Status": "حالة QR",
  "QR Code Description": "وصف رمز QR",
  "Download Your QR Code": "تحميل رمز QR الخاص بك",
  "Your QR Code": "رمز QR الخاص بك",
  "Your QR Code Poster": "ملصق رمز QR الخاص بك",
  "Download  ": "تحميل",
  "Select Relation": "تحديد العلاقة",
  "Nominee Permanent Address": "العنوان الدائم للمستفيد",
  "Enter Your Message": "أدخل رسالتك",
  "Select": "تحديد",
  "Select File Type": "تحديد نوع الملف",
  "Send TOP-UP And E-Sim": "إرسال شحن و E-sim",
  "Search Users": "البحث عن المستخدمين",
  "Select Payment Request Type": "تحديد نوع طلب الدفع",
  "Wallet To Wallet Transfer": "تحويل من محفظة إلى محفظة",
  "Holiday List": "قائمة العطلات",
  "No Data To Show": "لا توجد بيانات لعرضها",
  "They Recieve": "هم يتلقون",
  "You Will Be Logged Out In 60 Seconds": "سيتم تسجيل خروجك خلال 60 ثانية",
  "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "حرّك الماوس أو اضغط على أي مفتاح على لوحة المفاتيح لإعادة تعيين المؤقت إلى 15 دقيقة",
  "You've Been Logged Out": "تم تسجيل خروجك",
  "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "لأمانك، تم تسجيل خروجك بعد 15 دقيقة من عدم النشاط. يرجى تسجيل الدخول مرة أخرى إذا كنت ترغب في استخدام InstaPay.",
  "Cash": "نقدية",
  "Business": "تجارة",
  "Gift": "هدية",
  "Salary": "راتب",
  "Lottery": "يانصيب",
  "Savings": "المدخرات",
  "Other": "اخر",
  "Request Times Out. Please Try Again!": "انتهت مهلة الطلب. حاول مرة أخرى!",
  "Resend Code In 100 Seconds": "أعد إرسال الرمز خلال 100 ثانية",
  "Computer Service": "خدمة الحاسوب",
  "Family Support": "دعم الأسرة",
  "Education": "التعليم",
  "Gift And Other Donations": "الهدايا والتبرعات الأخرى",
  "Medical Treatment": "العلاج الطبي",
  "Maintenance Or Other Expenses": "الصيانة أو النفقات الأخرى",
  "Travel": "السفر",
  "Small Value Remittance": "التحويلات ذات القيمة الصغيرة",
  "Liberalized Remittance": "التحويلات الميسرة",
  "Construction Expenses": "نفقات البناء",
  "Hotel Accommodation": "الإقامة في الفندق",
  "Advertising And/Or Public Relations Related Expenses": "النفقات المتعلقة بالإعلانات و/أو العلاقات العامة",
  "Fees For Advisory Or Consulting Service": "رسوم الاستشارات أو الخدمات الاستشارية",
  "Business Related Insurance Payment": "دفع التأمين المتعلق بالأعمال",
  "Insurance Claims Payment": "دفع تعويضات التأمين",
  "Delivery Fees": "رسوم التوصيل",
  "Payments For Exported Goods": "المدفوعات للسلع المصدرة",
  "Payment For Services": "دفع مقابل الخدمات",
  "Payment Of Loans": "دفع القروض",
  "Office Expenses": "نفقات المكتب",
  "Residential Property Purchase": "شراء العقارات السكنية",
  "Property Rental Payment": "دفع إيجار العقار",
  "Royalty, Trademark, Patent And Copyright Fees": "الرسوم الخاصة بالحقوق الملكية والعلامات التجارية والبراءات وحقوق النشر",
  "Investment In Shares": "الاستثمار في الأسهم",
  "Fund Investment": "الاستثمار في الصناديق",
  "Tax Payment": "دفع الضرائب",
  "Transportation Fees": "رسوم النقل",
  "Utility Bills": "فواتير المرافق",
  "Personal Transfer": "التحويلات الشخصية",
  "Payment Of Salary": "دفع الرواتب",
  "Payment Of Rewards": "دفع المكافآت",
  "Payment Of Influencer": "دفع للمؤثرين",
  "Broker, Commitment, Guarantee And Other Fees": "السمسرة، الالتزام، الضمان والرسوم الأخرى",
  "Other Purposes": "أغراض أخرى",
  "Aunt": "عمة",
  "Brother": "أخ",
  "Brother-In-Law": "صهر",
  "Cousin": "ابن عم",
  "Daughter": "ابنة",
  "Father": "أب",
  "Father-In-Law": "حمو",
  "Friend": "صديق",
  "Grandfather": "جد",
  "Grandmother": "جدة",
  "Husband": "زوج",
  "Mother": "أم",
  "Mother-In-Law": "حماة",
  "Nephew": "ابن شقيق",
  "Niece": "ابنة شقيق",
  "Self (i.e. The Sender, Himself)": "المرسل (أي المرسل نفسه)",
  "Sister": "أخت",
  "Sister-In-Law": "شقيقة الزوج",
  "Son": "ابن",
  "Uncle": "عَم",
  "Wife": "زوجة",
  "Others Not Listed": "أخرى غير مذكورة",
  "Passport": "جواز السفر",
  "National Identification Card": "بطاقة الهوية الوطنية",
  "Driving License": "رخصة القيادة",
  "Social Security Card/Number": "بطاقة/رقم الضمان الاجتماعي",
  "Tax Payer Identification Card/Number": "بطاقة/رقم تعريف دافع الضرائب",
  "Senior Citizen Identification Card": "بطاقة تعريف المواطن المسن",
  "Birth Certificate": "شهادة الميلاد",
  "Village Elder Identification Card": "بطاقة تعريف شيخ القرية",
  "Permanent Residency Identification Card": "بطاقة تعريف الإقامة الدائمة",
  "Alien Registration Certificate/Card": "بطاقة/شهادة تسجيل الأجانب",
  "PAN Card": "بطاقة الرقم القومي (PAN)",
  "Voter’s Identification Card": "بطاقة تعريف الناخب",
  "Health Insurance Card/Number": "بطاقة/رقم التأمين الصحي",
  "Employer Identification Card": "بطاقة تعريف صاحب العمل",
  "Others Not Listed": "أخرى غير مدرجة",
  "Bank Account": "حساب بنكي",
  "Cash Pickup": "استلام نقدي",
  "Card": "بطاقة",
  "Arts & Entertainment": "الفنون والترفيه",
  "Banking & Finance": "البنوك والمالية",
  "Education & Research": "التعليم والبحث",
  "Engineering & Construction": "الهندسة والبناء",
  "Healthcare & Medicine": "الرعاية الصحية والطب",
  "Information Technology & Services": "تكنولوجيا المعلومات والخدمات",
  "Legal & Compliance": "القانونية والامتثال",
  "Manufacturing & Production": "التصنيع والإنتاج",
  "Marketing & Sales": "التسويق والمبيعات",
  "Non-profit & Charity": "غير ربحية وخيرية",
  "Real Estate & Property": "العقارات والممتلكات",
  "Retail & Wholesale": "البيع بالتجزئة والجملة",
  "Science & Pharmaceuticals": "العلوم والأدوية",
  "Transport & Logistics": "النقل والخدمات اللوجستية",
  "Travel & Tourism": "السفر والسياحة",
  "Unemployed": "عاطل عن العمل",
  "Other": "أخرى",
  "Cash": "نقد",
  "Business": "أعمال",
  "Gift": "هدية",
  "Salary": "راتب",
  "Lottery": "يانصيب",
  "Savings": "مدخرات",
  "Good Morning": "صباح الخير",
  "Good Evening": "مساء الخير",
  "Completed": "مكتمل",
  "Load/Deposit Money To The Wallet": "المتابعة إلى الدفع",
  "Enter The Amount": "أدخل المبلغ",
  "Choose The Top-Up Channel": "اختر قناة الشحن",
  "Good Night!": "تصبح على خير!",
  "You Will Get": "أكد الدفع الخاص بك",
  "Amount To Pay": "ستحصل على",
  "Top-Up Wallet": "نوع الدفع",
  "Card Holder Name ": "اسم حامل البطاقة",
  "Card Number": "رقم البطاقة",
  "CVC": "CVC",
  "MM/YY": "شهر/سنة",
  "Save Details To Use Next Time": "حفظ التفاصيل للاستخدام لاحقًا",
  "Select Or Add Payment Card": "اختر أو أضف بطاقة دفع",
  "Your Details ": "تفاصيلك",
  "You Selected": "تم اختيارك",
  "Detailed Confirmed": "تأكيد مفصل",
  "Transaction Failed": "فشلت العملية",
  "Something Went Wrong. Try Again.": "حدث خطأ ما. حاول مرة أخرى.",
  "PDF": "PDF",
  "CSV": "CSV",
  "Failed": "فشل",
  "Initiated": "بدأ",
  "Revise": "مراجعة",
  "Declined": "مرفوض",
  "Bargain-Accepted": "قبول الصفقة",
  "Accepted": "مقبول",
  "Decline The Offer": "رفض العرض",
  "Pending": "قيد الانتظار",
  "Cancelled": "تم الإلغاء",
  "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "أرسل عرض أسعار مفصل إلى عميلك أو زبونك. قدم خدماتك أو منتجاتك أو عروضك بشكل احترافي مع عرض أسعار مخصص.",
  "Wallet to wallet": "محفظة إلى محفظة",
  "Please follow these examples:": "يرجى اتباع هذه الأمثلة:",
  "👤 InstaPay/Instagram Username: instauser": "👤 اسم المستخدم في InstaPay/Instagram: instauser",
  "📧 Email: user@email.com": "📧 البريد الإلكتروني: user@email.com",
  "Full Name": "الاسم الكامل",
  "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "مرحبًا بك في InstaPay! للتأكد من أنه يمكنك سحب الأموال التي تتلقاها على الفور، ستحتاج إلى إعداد حسابات الاستلام الخاصة بك في الإعدادات. سيسمح لك ذلك بتحويل الأموال بسهولة إلى حساباتك المصرفية المفضلة أو المحافظ المحمولة أو المحافظ المشفرة أو مواقع استلام النقود.",
  "You Have No Beneficiary": "ليس لديك مستفيد",
  "Add Now": "أضف الآن",
  "Wallet-Status": "حالة المحفظة",
  "Select Payment Card": "اختر بطاقة الدفع",
  "Your funds have been credited to your wallet.": "تم إضافة أموالك إلى محفظتك.",
  "Thanks for using InstaPay": "شكرًا لاستخدام InstaPay",
  "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "احصل على أموالك فورًا: قم بإعداد حسابات الاستلام الآن!",
  "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "اتبع هذه الخطوات البسيطة لإعداد حسابات الاستلام الخاصة بك والتأكد من أنك دائمًا جاهز للوصول إلى أموالك:",
  "STEP 01": "الخطوة 01",
  "Go to Settings": "انتقل إلى الإعدادات",
  "Open your InstaPay app and navigate to the \"Settings\" section.": "افتح تطبيق InstaPay وانتقل إلى قسم \"الإعدادات\".",
  "STEP 02": "الخطوة 02",
  "Select Receiving Accounts": "اختر حسابات الاستلام",
  "Tap on \"Receiving Accounts\" to start adding your details.": "اضغط على \"حسابات الاستلام\" لبدء إضافة بياناتك.",
  "Start Adding Accounts": "ابدأ في إضافة الحسابات",
  "Phone Number: 44795396600 (With Country Code)": "رقم الهاتف: 44795396600 (مع رمز الدولة)",
  "Select Country First": "اختر الدولة أولاً",
  "First Name": "الاسم الأول",
  "Last Name": "الاسم الأخير",
  "Address Line": "سطر العنوان",
  "Email Address": "عنوان البريد الإلكتروني",
  "Select City": "اختر المدينة",
  "Select Gender": "اختر الجنس",
  "Select Occupation": "اختر المهنة",
  "Male": "ذكر",
  "Female": "أنثى",
  "Non-Binary": "غير ثنائي",
  "Prefer Not To Say'": "أفضل عدم القول",
  "Bitcoin (BTC)": "بيتكوين (BTC)",
  "Tether (USDT)": "تيثر (USDT)",
  "Ethereum (ETH)": "إيثيريوم (ETH)",
  "No User Found": "لم يتم العثور على مستخدم",
  "Email Address ": "عنوان البريد الإلكتروني",
  "Select Document": "اختر مستندًا",
  "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "اعرض عملك، وزيّع نطاقك. \"محفظتي\" هي لوحتك الرقمية على Instapay. قم بتحميل وعرض مجموعة متنوعة من المحتوى - من الصور والفيديوهات الجذابة إلى ملفات PDF المعلوماتية. سواء كنت فنانًا أو مستقلًا أو رائد أعمال، يتيح لك هذا الميزة تقديم عملك أو مشاريعك بطريقة مرئية جذابة.",
  "Add New": "إضافة جديد",
  "Money Out": "صرف الأموال",
  "Browsername": "اسم المتصفح",
  "Browserversion": "إصدار المتصفح",
  "Credit": "ائتمان",
  "Debit": "خصم",
  "Top Up": "إعادة شحن",
  "Wallet To Wallet": "محفظة إلى محفظة",
  "Accountant": "محاسب",
  "Architect": "مهندس معماري",
  "Artist": "فنان",
  "Banker": "مصرفي",
  "Business Owner": "صاحب عمل",
  "Consultant": "مستشار",
  "Doctor": "طبيب",
  "Engineer": "مهندس",
  "Farmer": "مزارع",
  "Government Employee": "موظف حكومي",
  "IT Professional": "محترف تكنولوجيا المعلومات",
  "Lawyer": "محام",
  "Nurse": "ممرضة",
  "Retailer": "بائع تجزئة",
  "Salesperson": "مندوب مبيعات",
  "Student": "طالب",
  "Teacher": "معلم",
  "Prefer Not to Say": "أفضل عدم القول",
  "Timezone": "المنطقة الزمنية",
  "Subscription Payment": "دفع الاشتراك",
  "Starting From": "بدءًا من",
  "Recurring Cycle": "الدورة المتكررة",
  "Until I stop": "حتى أتوقف",
  "Schedule To": "جدولة إلى",
  "Schedule at": "الجدولة في",
  "Selected time must be at least 5 minutes from now.": "يجب أن يكون الوقت المحدد لا يقل عن 5 دقائق من الآن.",
  "They Recieve": "هم يتلقون",
  "Attach Files": "إرفاق الملفات",
  "Wallet Selected": "تم اختيار المحفظة",
  "You Have Selected PKR Currency Wallet For This Transaction": "لقد اخترت محفظة عملة PKR لهذه العملية",
  "Instant": "فوري",
  "Subscription": "الاشتراك",
  "Schedule": "الجدول الزمني",
  "Sms/Email Verification Code": "رمز التحقق باستخدام الرسائل القصيرة / البريد الإلكتروني",
  "Confirm Your Password": "أكد كلمة المرور الخاصة بك",
  "This Security Feature Requires Password Confirmation!": "هذه الميزة الأمنية تتطلب تأكيد كلمة المرور!",
  "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "استخدم التطبيق لمسح رمز الاستجابة السريعة أو استخدم الرمز السري الخاص بك لتفعيل المصادقة الثنائية من جوجل",
  "OR": "أو",
  "Paste This Key In The Googe Authenticator App": "الصق هذا المفتاح في تطبيق Google Authenticator",
  "Show Secret Key": "عرض المفتاح السري",
  "Enter The Six-Digit Code From The Application": "أدخل الرمز المكون من ستة أرقام من التطبيق",
  "6 Digit Code": "رمز مكون من 6 أرقام",
  "Enable": "تفعيل",
  "Disable": "تعطيل",
  "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "بعد مسح صورة الباركود، سيعرض التطبيق رمزًا مكونًا من ستة أرقام يمكنك إدخاله أدناه",
  "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "تم الآن ربط المصادقة الثنائية من جوجل بحسابك على إنستاباي",
  "Min Amount:": "الحد الأدنى للمبلغ:",
  "Minimum Amount": "المبلغ الأدنى",
  "Maximum Amount": "المبلغ الأقصى",
  "Identity Verification Required": "مطلوب التحقق من الهوية",
  "Select Your Wallet Currency": "اختر عملة محفظتك",
  "Setup Authenticator": "إعداد المصادِق",
  "Note": "ملاحظة",
  "This Is A Test Notice": "هذه إشعار تجريبي",
  "Session Expired": "الجلسة منتهية",
  "You have been logged out for security reasons, please login again to continue.": "تم تسجيل خروجك لأسباب أمنية، يرجى تسجيل الدخول مرة أخرى للمتابعة.",
  "Redirecting You To Login Page In 5 Seconds": "سيتم تحويلك إلى صفحة تسجيل الدخول في 5 ثوانٍ",
  "Searching": "البحث",
  "Recipient Mobile Number": "رقم هاتف المستلم",
  "TOP-UP SUMMARY": "ملخص الشحن",
  "Amount": "المبلغ",
  "Something went wrong while getting countries.": "حدث خطأ أثناء الحصول على الدول.",
  "Select Destination Country": "اختر دولة المقصد.",
  "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR غير مدعوم من قبل PayPal، سيتم استخدام USD كعملة افتراضية.",
  "Select A Date": "اختر تاريخًا",
  "Select A Timezone": "اختر المنطقة الزمنية",
  "Proceed": "تابع",
  "Proceeding": "جارٍ المتابعة",
  "You Can Now Close This Webpage And Go Back To Instagram": "يمكنك الآن إغلاق هذه الصفحة والعودة إلى إنستغرام",
  "Select A Time": "اختر وقتًا",
  "Location Permission Denied": "تم رفض إذن الموقع",
  "Follow the below instructions to enable location services in your browser after denying permission previously:": "اتبع التعليمات أدناه لتمكين خدمات الموقع في متصفحك بعد رفض الإذن مسبقًا:",
  "1: Chrome Browser": "1: متصفح Chrome",
  "Open the Chrome app.": "افتح تطبيق Chrome.",
  "Tap the three-dot menu (top-right corner) and select Settings.": "اضغط على قائمة النقاط الثلاث (الزاوية العلوية اليمنى) واختر الإعدادات.",
  "Scroll down to Site Settings > Location.": "قم بالتمرير لأسفل إلى إعدادات الموقع > الموقع.",
  "Locate the website in the list or search for it.": "حدد موقع الموقع الإلكتروني في القائمة أو ابحث عنه.",
  "Tap the website and select Allow under Location Access.": "اضغط على الموقع الإلكتروني واختر السماح ضمن الوصول إلى الموقع.",
  "2: Firefox Browser": "2: متصفح Firefox",
  "Open the Firefox app.": "افتح تطبيق Firefox.",
  "Tap the three-line menu (bottom-right corner) and go to Settings.": "اضغط على قائمة الخطوط الثلاثة (الزاوية السفلية اليمنى) وانتقل إلى الإعدادات.",
  "Select Privacy & Security > Site Permissions > Location.": "اختر الخصوصية والأمان > أذونات الموقع > الموقع.",
  "Find the website in the list and set the permission to Allow.": "ابحث عن الموقع الإلكتروني في القائمة وقم بتعيين الإذن إلى السماح.",
  "3: Samsung Internet Browser": "3: متصفح الإنترنت من سامسونج",
  "Open the Samsung Internet app.": "افتح تطبيق Samsung Internet.",
  "Tap the three-line menu (bottom-right corner) and select Settings.": "اضغط على قائمة الخطوط الثلاثة (الزاوية السفلية اليمنى) واختر الإعدادات.",
  "Go to Sites and Downloads > Site Permissions > Location.": "انتقل إلى المواقع والتنزيلات > أذونات الموقع > الموقع.",
  "Find the website and change the permission to Allow.": "ابحث عن الموقع الإلكتروني وقم بتغيير الإذن إلى السماح.",
  "4: Safari Browser": "4: متصفح Safari",
  "Open the Settings app.": "افتح تطبيق الإعدادات.",
  "Scroll down and select Safari.": "قم بالتمرير لأسفل وحدد Safari.",
  "Tap Location and set it to Ask Next Time or Allow While Using the App.": "اضغط على الموقع وقم بتعيينه إلى اسأل في المرة القادمة أو السماح أثناء استخدام التطبيق.",
  "Revisit the website and when prompt, allow location access": "أعد زيارة الموقع وعندما يُطلب منك، اسمح بالوصول إلى الموقع.",
  "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "نصيحة: إذا لم يظهر الموقع في إعدادات المتصفح، قم بمسح ذاكرة التخزين المؤقت أو السجل في المتصفح وحاول مرة أخرى.",
  "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "لإرسال طلب دفع، يرجى مشاركة موقعك—هذا يساعدنا في تعزيز الأمان وبناء الثقة لتجربة أكثر سلاسة!",
  "Decline": "رفض",
  "Share Location": "مشاركة الموقع",
  "Already Exist": "موجود بالفعل",
  "Withdraw": "سحب",
  "Seller Left A reply": "ترك البائع ردًا",
  "Check Now": "تحقق الآن",
  "You Left A Review": "لقد تركت مراجعة",
  "View Larger Map": "عرض خريطة أكبر",
  "English (UK)": "الإنجليزية (المملكة المتحدة)",
  "QR Code Title": "عنوان رمز الاستجابة السريعة",
  "Notice!": "تنبيه!",
  "By proceeding with this transaction, I hereby confirm that I am acting independently and of my own free will. I affirm that I have not been influenced, coached, coerced, or persuaded by any third party to carry out this transaction for investment purposes or any other reason.": "بالمتابعة في هذه المعاملة، أؤكد بموجبه أنني أتصرف بشكل مستقل وبإرادتي الحرة. أؤكد أنني لم أتأثر أو أُوجَّه أو أُجبرت أو أُقنِع من قبل أي طرف ثالث لتنفيذ هذه المعاملة لأغراض الاستثمار أو لأي سبب آخر.",
  "I understand that this confirmation is required to ensure compliance and to safeguard my account and transactions.": "أنا أفهم أن هذا التأكيد مطلوب لضمان الامتثال وحماية حسابي ومعاملاتي.",
  "Confirm and Proceed": "تأكيد والمتابعة",
  "Blocked Wallet": "المحفظة المحظورة",
  "Nominee's Full Name": "الاسم الكامل للمرشح",
  "Insufficient Balance": "رصيد غير كافٍ",
  "Upload Photo": "تحميل الصورة",
   "Security Alert": "تنبيه أمني",
  "Please remember to add your security questions for smooth experience. You will not be able to access the features of InstaPay unless You add the security questions.": "يرجى تذكر إضافة أسئلة الأمان الخاصة بك لتجربة سلسة. لن تتمكن من الوصول إلى ميزات InstaPay ما لم تضف أسئلة الأمان.",
  "Setup Security Questions": "إعداد أسئلة الأمان",
  "Geo Fencing": "التسييج الجغرافي",
  "Manage Payment & Quotation Access": "إدارة الوصول إلى الدفع وعروض الأسعار",
  "Control countries from where you can receive Payment Requests and Quotations. Toggle on/off access for specific countries.": "تحكم في البلدان التي يمكنك تلقي طلبات الدفع وعروض الأسعار منها. قم بتشغيل/إيقاف الوصول إلى بلدان معينة.",
  "Search Countries ": "البحث عن البلدان",
  "Filter": "تصفية",
  "Payment Requests": "طلبات الدفع",
  "#No": "#رقم",
  "Country": "البلد",
  "Account’s Country": "بلد الحساب",
  "Current Location": "الموقع الحالي",
  "Save Changes": "حفظ التغييرات",
  "Asia": "آسيا",
  "Europe": "أوروبا",
  "Africa": "إفريقيا",
  "Oceania": "أوقيانوسيا",
  "Americas": "الأمريكتان",
  "Polar": "القطب",
  "Finger Biometric": "البصمة الحيوية",
  "Confirm": "تأكيد",
  "Resend available in 77 seconds": "إعادة الإرسال متاحة خلال 77 ثانية",
  "Invalid OTP!": "رمز التحقق غير صالح!",
  "Finger Biometric Updated": "تم تحديث البصمة الحيوية",
  "Your finger biometric has been disabled successfully!": "تم تعطيل بصمتك الحيوية بنجاح!",
  "Your finger biometric has been enabled successfully!": "تم تفعيل بصمتك الحيوية بنجاح!",
  "Afghanistan": "أفغانستان",
  "Armenia": "أرمينيا",
  "Azerbaijan": "أذربيجان",
  "Bahrain": "البحرين",
  "Bangladesh": "بنغلاديش",
  "Bhutan": "بوتان",
  "Brunei": "بروناي",
  "Cambodia": "كمبوديا",
  "China": "الصين",
  "East Timor": "تيمور الشرقية",
  "Georgia": "جورجيا",
  "Hong Kong S.A.R.": "هونغ كونغ منطقة إدارية خاصة",
  "India": "الهند",
  "Indonesia": "إندونيسيا",
  "Iran": "إيران",
  "Iraq": "العراق",
  "Israel": "إسرائيل",
  "Japan": "اليابان",
  "Jordan": "الأردن",
  "Kazakhstan": "كازاخستان",
  "Kuwait": "الكويت",
  "Kyrgyzstan": "قيرغيزستان",
  "Laos": "لاوس",
  "Lebanon": "لبنان",
  "Macau S.A.R.": "ماكاو منطقة إدارية خاصة",
  "Malaysia": "ماليزيا",
  "Maldives": "المالديف",
  "Mongolia": "منغوليا",
  "Myanmar": "ميانمار",
  "Nepal": "نيبال",
  "North Korea": "كوريا الشمالية",
  "Oman": "عمان",
  "Palestinian Territory Occupied": "الأراضي الفلسطينية المحتلة",
  "Philippines": "الفلبين",
  "Qatar": "قطر",
  "Saudi Arabia": "المملكة العربية السعودية",
  "Singapore": "سنغافورة",
  "South Korea": "كوريا الجنوبية",
  "Sri Lanka": "سريلانكا",
  "Syria": "سوريا",
  "Taiwan": "تايوان",
  "Tajikistan": "طاجيكستان",
  "Thailand": "تايلاند",
  "Turkey": "تركيا",
  "Turkmenistan": "تركمانستان",
  "United Arab Emirates": "الإمارات العربية المتحدة",
  "Uzbekistan": "أوزبكستان",
  "Vietnam": "فيتنام",
  "Yemen": "اليمن",
  "Åland Islands": "جزر آلاند",
  "Albania": "ألبانيا",
  "Andorra": "أندورا",
  "Austria": "النمسا",
  "Belarus": "بيلاروسيا",
  "Belgium": "بلجيكا",
  "Bosnia and Herzegovina": "البوسنة والهرسك",
  "Bulgaria": "بلغاريا",
  "Croatia": "كرواتيا",
  "Cyprus": "قبرص",
  "Czech Republic": "جمهورية التشيك",
  "Denmark": "الدنمارك",
  "Estonia": "إستونيا",
  "Faroe Islands": "جزر فارو",
  "Finland": "فنلندا",
  "France": "فرنسا",
  "Germany": "ألمانيا",
  "Gibraltar": "جبل طارق",
  "Greece": "اليونان",
  "Guernsey and Alderney": "غيرنزي وألدرني",
  "Hungary": "المجر",
  "Iceland": "أيسلندا",
  "Ireland": "أيرلندا",
  "Italy": "إيطاليا",
  "Jersey": "جيرسي",
  "Kosovo": "كوسوفو",
  "Latvia": "لاتفيا",
  "Liechtenstein": "ليختنشتاين",
  "Lithuania": "ليتوانيا",
  "Luxembourg": "لوكسمبورغ",
  "Malta": "مالطا",
  "Isle of Man": "جزيرة مان",
  "Moldova": "مولدوفا",
  "Monaco": "موناكو",
  "Montenegro": "الجبل الأسود",
  "Netherlands": "هولندا",
  "North Macedonia": "مقدونيا الشمالية",
  "Norway": "النرويج",
  "Poland": "بولندا",
  "Portugal": "البرتغال",
  "Romania": "رومانيا",
  "Russia": "روسيا",
  "San Marino": "سان مارينو",
  "Serbia": "صربيا",
  "Slovakia": "سلوفاكيا",
  "Slovenia": "سلوفينيا",
  "Spain": "إسبانيا",
  "Svalbard and Jan Mayen Islands": "جزر سفالبارد ويان ماين",
  "Sweden": "السويد",
  "Switzerland": "سويسرا",
  "Ukraine": "أوكرانيا",
  "United Kingdom": "المملكة المتحدة",
  "Vatican City": "مدينة الفاتيكان",
  "Algeria": "الجزائر",
  "Angola": "أنغولا",
  "Benin": "بنين",
  "Botswana": "بوتسوانا",
  "British Indian Ocean Territory": "إقليم المحيط الهندي البريطاني",
  "Burkina Faso": "بوركينا فاسو",
  "Burundi": "بوروندي",
  "Cameroon": "الكاميرون",
  "Cape Verde": "الرأس الأخضر",
  "Central African Republic": "جمهورية أفريقيا الوسطى",
  "Chad": "تشاد",
  "Comoros": "جزر القمر",
  "Congo": "الكونغو",
  "Côte d'Ivoire (Ivory Coast)": "ساحل العاج",
  "Democratic Republic of the Congo": "جمهورية الكونغو الديمقراطية",
  "Djibouti": "جيبوتي",
  "Egypt": "مصر",
  "Equatorial Guinea": "غينيا الاستوائية",
  "Eritrea": "إريتريا",
  "Ethiopia": "إثيوبيا",
  "French Southern Territories": "الأقاليم الجنوبية الفرنسية",
  "Gabon": "الغابون",
  "Gambia": "غامبيا",
  "Ghana": "غانا",
  "Guinea": "غينيا",
  "Guinea-Bissau": "غينيا بيساو",
  "Kenya": "كينيا",
  "Lesotho": "ليسوتو",
  "Liberia": "ليبيريا",
  "Libya": "ليبيا",
  "Madagascar": "مدغشقر",
  "Malawi": "مالاوي",
  "Mali": "مالي",
  "Mauritania": "موريتانيا",
  "Mauritius": "موريشيوس",
  "Mayotte": "مايوت",
  "Morocco": "المغرب",
  "Mozambique": "موزمبيق",
  "Namibia": "ناميبيا",
  "Niger": "النيجر",
  "Nigeria": "نيجيريا",
  "Réunion": "ريونيون",
  "Rwanda": "رواندا",
  "Saint Helena": "سانت هيلينا",
  "São Tomé and Príncipe": "ساو تومي وبرينسيبي",
  "Senegal": "السنغال",
  "Seychelles": "سيشيل",
  "Sierra Leone": "سيراليون",
  "Somalia": "الصومال",
  "South Africa": "جنوب أفريقيا",
  "South Sudan": "جنوب السودان",
  "Sudan": "السودان",
  "Swaziland (Eswatini)": "سوازيلاند (إسواتيني)",
  "Tanzania": "تنزانيا",
  "Togo": "توغو",
  "Tunisia": "تونس",
  "Uganda": "أوغندا",
  "Western Sahara": "الصحراء الغربية",
  "Zambia": "زامبيا",
  "Zimbabwe": "زيمبابوي",
  "American Samoa": "ساموا الأمريكية",
  "Australia": "أستراليا",
  "Christmas Island": "جزيرة الكريسماس",
  "Cocos (Keeling) Islands": "جزر كوكوس (كيلينغ)",
  "Cook Islands": "جزر كوك",
  "Fiji": "فيجي",
  "French Polynesia": "بولينيزيا الفرنسية",
  "Guam": "غوام",
  "Kiribati": "كيريباتي",
  "Marshall Islands": "جزر مارشال",
  "Micronesia": "ميكرونيزيا",
  "Nauru": "ناورو",
  "New Caledonia": "كاليدونيا الجديدة",
  "New Zealand": "نيوزيلندا",
  "Niue": "نييوي",
  "Norfolk Island": "جزيرة نورفولك",
  "Northern Mariana Islands": "جزر ماريانا الشمالية",
  "Palau": "بالاو",
  "Papua New Guinea": "بابوا غينيا الجديدة",
  "Pitcairn Islands": "جزر بيتكيرن",
  "Samoa": "ساموا",
  "Solomon Islands": "جزر سليمان",
  "Tokelau": "توكيلاو",
  "Tonga": "تونغا",
  "Tuvalu": "توفالو",
  "Vanuatu": "فانواتو",
  "Wallis and Futuna": "واليس وفوتونا",
  "North & South America": "أمريكا الشمالية والجنوبية",
  "Anguilla": "أنغيلا",
  "Antigua and Barbuda": "أنتيغوا وباربودا",
  "Argentina": "الأرجنتين",
  "Aruba": "أروبا",
  "Barbados": "بربادوس",
  "Belize": "بليز",
  "Bermuda": "برمودا",
  "Bolivia": "بوليفيا",
  "Bonaire, Sint Eustatius, and Saba": "بونير، سانت يوستاتيوس وسابا",
  "Brazil": "البرازيل",
  "Canada": "كندا",
  "Cayman Islands": "جزر كايمان",
  "Chile": "تشيلي",
  "Colombia": "كولومبيا",
  "Costa Rica": "كوستاريكا",
  "Cuba": "كوبا",
  "Curaçao": "كوراساو",
  "Dominica": "دومينيكا",
  "Dominican Republic": "جمهورية الدومينيكان",
  "Ecuador": "الإكوادور",
  "El Salvador": "السلفادور",
  "Falkland Islands": "جزر فوكلاند",
  "French Guiana": "غويانا الفرنسية",
  "Greenland": "جرينلاند",
  "Grenada": "غرينادا",
  "Guadeloupe": "جوادلوب",
  "Guatemala": "غواتيمالا",
  "Guyana": "غيانا",
  "Haiti": "هايتي",
  "Honduras": "هندوراس",
  "Jamaica": "جامايكا",
  "Martinique": "مارتينيك",
  "Mexico": "المكسيك",
  "Montserrat": "مونتسيرات",
  "Nicaragua": "نيكاراغوا",
  "Panama": "بنما",
  "Paraguay": "باراغواي",
  "Peru": "بيرو",
  "Puerto Rico": "بورتوريكو",
  "Saint Kitts and Nevis": "سانت كيتس ونيفيس",
  "Saint Lucia": "سانت لوسيا",
  "Saint Pierre and Miquelon": "سان بيير وميكلون",
  "Saint Vincent and the Grenadines": "سانت فنسنت وجزر غرينادين",
  "Saint Barthélemy": "سانت بارتيليمي",
  "Saint Martin (French part)": "سان مارتن (الجزء الفرنسي)",
  "Sint Maarten (Dutch part)": "سينت مارتن (الجزء الهولندي)",
  "South Georgia and the South Sandwich Islands": "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  "Suriname": "سورينام",
  "The Bahamas": "جزر البهاما",
  "Trinidad and Tobago": "ترينيداد وتوباغو",
  "Turks and Caicos Islands": "جزر تركس وكايكوس",
  "United States": "الولايات المتحدة",
  "United States Minor Outlying Islands": "الجزر الصغيرة النائية التابعة للولايات المتحدة",
  "Uruguay": "أوروغواي",
  "Venezuela": "فنزويلا",
  "British Virgin Islands": "جزر العذراء البريطانية",
  "U.S. Virgin Islands": "جزر العذراء الأمريكية",
  "Antarctica": "القارة القطبية الجنوبية",
  "Your Preferences Updated Successfully": "تم تحديث تفضيلاتك بنجاح"
}