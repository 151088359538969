export const ur = {
    "Login": "لاگ ان",
    "Start Your InstaPay Journey Today!": "آپ کا انسٹا پے سفر آج ہی شروع کریں!",
    "Phone Number or Email": "فون نمبر یا ای میل",
    "Login Using Social Media": "سوشل میڈیا کا استعمال کرکے لاگ ان کریں",
    "Continue": "جاری رہے",
    "Don't Have an Account? Sign Up": "اکاؤنٹ نہیں ہے؟ سائن اپ کریں",
    "Sign Up": "سائن اپ",
    "Edit": "ترتیب دیں",
    "Enter Your Password": "اپنا پاس ورڈ درج کریں",
    "Forgot Password?": "پاس ورڈ بھول گئے ہیں؟",
    "Account Type": "اکاؤنٹ کی قسم",
    "Individual": "فرد",
    "Business": "کاروبار",
    "Already Have an Account? Sign In": "پہلے ہی اکاؤنٹ ہے؟ سائن ان",
    "Sing In": "سائن ان",
    "Enter Your Personal Details": "اپنی ذاتی تفصیلات درج کریں",
    "Add Photo or Logo": "تصویر یا لوگو شامل کریں",
    "Enter Your Name": "اپنا نام درج کریں",
    "Back": "واپس",
    "Phone Number": "فون نمبر",
    "Email Address": "ای میل ایڈریس",
    "Create a Strong Password": "ایک مضبوط پاس ورڈ بنائیں",
    "Password Requirements": "پاس ورڈ کی ضروریات",
    "Must be between 9 and 64 characters": "9 اور 64 حروف کے درمیان ہونا چاہئے",
    "Include at least two of the following:": "کم سے کم دو میں سے ایک شامل ہونا چاہئے:",
    "Uppercase character": "اپر کیس حرف",
    "Lowercase character": "لو کیس حرف",
    "Number": "نمبر",
    "Special character": "خصوصی حرف",
    "Confirm Password": "پاس ورڈ کی تصدیق کریں",
    "Finalize Your Process": "اپنے عمل کو مکمل کریں",
    "Referral Code (Optional)": "ریفرل کوڈ (اختیاری)",
    "It will autofill if you're signing up with an invitation link.": "اگر آپ دعوتی لنک کے ساتھ سائن اپ کر رہے ہیں تو یہ خود بھرے گا۔",
    "I agree with": "میں اتفاق ہوں",
    "Terms & Conditions": "ضوابط اور شرائط",
    "Privacy Policy": "رازداری کی پالیسی",
    "Create Your Account": "اپنا اکاؤنٹ بنائیں",
    "Enter Code": "کوڈ درج کریں",
    "A one-time verification code has been sent to your email address and phone number.": "ایک مرتبہ کی تصدیقی کوڈ آپکی ای میل ایڈریس اور فون نمبر پر بھیجا گیا ہے۔",
    "Resend available in ": "دوبارہ بھیجیں میں دستیاب ہے ",
    "seconds": "سیکنڈز",
    "Verify": "تصدیق کریں",
    "Verification Code Verified": "تصدیقی کوڈ کی تصدیق ہوگئی ہے",
    "Please continue to the next step.": "براہ کرم اگلے مرحلے پر جاری رہیں۔",
    "Finish": "ختم کریں",
    "Finishing": "ختم ہو رہا ہے",
    "Company Name": "کمپنی کا نام",
    "Enter Your Business Details": "اپنی کاروبار کی تفصیلات درج کریں",
    "Country ": "ملک ",
    "You cannot change the country once your account is verified.": "آپ اپنے اکاؤنٹ کی تصدیق ہونے کے بعد ملک نہیں بدل سکتے۔",
    "Learn more": "مزید جانیں",
    "Optional": "اختیاری",
    "Continuing": "جاری رہے ہیں",
    "Didn't receive the verification code?": "تصدیقی کوڈ حاصل نہ ہوا؟",
    "Resend Now": "اب دوبارہ بھیجیں",
    "Search Here": "یہاں تلاش کریں",
    "Search for users, services, and more on InstaPay": "InstaPay پر صارفین، خدمات اور مزید کے لئے تلاش کریں",
    "Search users here": "یہاں صارفین تلاش کریں",
    "Earn money with our affiliate program!": "ہمارے ایفلییٹ پروگرام کے ساتھ پیسے کمائیں!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "اپنے انسٹا پے ایفلییٹ کوڈ کو اپنے فالورز کے ساتھ شیئر کرکے باہری آمدنی پیدا کریں۔ جو ہر ٹرانزیکشن وہ کریں، \nآپ کمیشن کماتے ہیں، جس سے آپ اپنے آن لائن اثر سے منافع حاصل کرتے ہیں۔",
    "Learn More": "مزید جانیں",
    "Balance Amount": "بیلنس رقم",
    "You spent less compared to last month.": "آپ نے پچھلے مہینے کی موازنہ میں کم خرچ کیا ہے۔",
    "Referral Rewards": "ریفرل ریوارڈز",
    "Bitcoin": "بٹ کوائن",
    "USDT": "USDT",
    "Ethereum": "ایتھیریم",
    "Quick Transfer to Your Most Recent Contacts:": "آپ کے تازہ ترین رابطے کو فوراً منتقل کریں:",
    "Number of Transactions": "ٹرانزیکشن کی تعداد",
    "Total Transaction Amount": "کل ٹرانزیکشن رقم",
    "Total Sent": "کل بھیجا گیا",
    "Total Received": "کل حاصل ہوا",
    "Add Funds": "فنڈز شامل کریں",
    "Send Money": "پیسے بھیجیں",
    "Request Money": "پیسے کی درخواست کریں",
    "Send a Quote": "ایک کوٹ بھیجیں",
    "Pay With Crypto": "کرپٹو سے ادا کریں",
    "Payment Insights": "ادائیگی کی راہنمائی",
    "Recent Transactions ": "حال ہی کی ٹرانزیکشنز ",
    "Today": "آج",
    "My Wallets": "میرے والٹس",
    "Balance": "بیلنس",
    "Wallet ID": "والٹ آئی ڈی",
    "Monthly limit": "ماہانہ حد",
    "Top Up": "مزید ڈالیں",
    "Summary": "خلاصہ",
    "Settings": "سیٹنگز",
    "More": "مزید",
    "Upgrade to Business Account": "بزنس اکاؤنٹ پر اپگریڈ کریں",
    "Conversion": "تبادلہ",
    "Enter Amount": "رقم درج کریں",
    "Exchanged Amount": "تبادلہ ہونے والی رقم",
    "Convert": "تبادلہ کریں",
    "You Are Converting": "آپ تبادلہ کر رہے ہیں",
    "From": "سے",
    "To": "کو",
    "Exchange Rate": "تبادلہ کرنے کی شرح",
    "Fees": "فیس",
    "You Will Receive": "آپ کو ملے گا",
    "Slide To Confirm": "تصدیق کے لئے ہلکان دیں",
    "Verification Code Confirmed - You'll be redirected to the next step": "تصدیقی کوڈ کی تصدیق ہو گئی ہے - آپ کو اگلے مرحلے پر ریڈائریکٹ کیا جائے گا",
    "Moving to next step in": "اگلے مرحلے میں چلا جا رہا ہے",
    "Thanks for using InstaPay": "استا پے استعمال کرنے کا شکریہ",
    "Your funds have been credited to your wallet.": "آپ کے فنڈ آپ کے والٹ میں شامل ہو گئے ہیں۔",
    "Dashboard": "ڈیش بورڈ",
    "Accounts": "اکاؤنٹس",
    "Transactions": "ٹرانزیکشنز",
    "Other Transactions": "دیگر ٹرانزیکشنز",
    "Payments": "ادائیگیاں",
    "Beneficiaries": "فائدہ اُٹھانے والے",
    "Referrals": "ریفرلز",
    "Quotations": "حوالے",
    "Pending": "زیر التواء",
    "My QR Code Sticker": "میرا QR کوڈ اسٹیکر",
    "My Portfolio": "میرا پورٹفولیو",
    "My Payment Address": "میرا ادائیگی کا پتہ",
    "Analytics": "تجزیے",
    "Profile": "پروفائل",
    "Dark Mode": "ڈارک موڈ",
    "Support": "حمایت",
    "Logout": "لاگ آؤٹ",
    "Powered By": "چلایا گیا بذریعہ",
    "Kemit Kingdom SA": "کیمٹ کنگڈم ایس اے",
    "A Swiss Company": "ایک سوئس فن ٹیک کمپنی",
    "MAIN MENU": "مین مینو",
    "OTHERS": "دیگر",
    "Wallet": "والٹ",
    "Default": "پہلے سے طے شدہ",
    "Breakdown": "تفصیل",
    "Credit": "کریڈٹ",
    "Debit": "ڈیبٹ",
    "Wallet Management": "والٹ کا منظر نامہ",
    "Top-up Your Wallet": "اپنی والٹ میں ٹاپ اپ کریں",
    "Download Statement": "سٹیٹمنٹ ڈاؤن لوڈ کریں",
    "Block the Wallet": "والٹ کو بلاک کریں",
    "Wallet Status": "والٹ کی حالت",
    "-Select-File-Type-": "-فائل کی قسم کا انتخاب کریں-",
    "Download Now": "اب ڈاؤن لوڈ کریں",
    "Downloading": "ڈاؤن لوڈ ہو رہا ہے",
    "All": "سب",
    "Sent": "بھیجا گیا",
    "Received": "مل گیا",
    "Requested": "درخواست کی گئی",
    "Quoted": "حوالہ دیا گیا",
    "Search Transactions": "ٹرانزیکشن تلاش کریں",
    "Date & Time": "تاریخ اور وقت",
    "Type": "قسم",
    "Transaction ID": "ٹرانزیکشن آئی ڈی",
    "Recipient": "موصول ہونے والا",
    "Amount": "رقم",
    "Status": "حالت",
    "Payment Type": "ادائیگی کی قسم",
    "Sent Payments": "بھیجی گئی ادائیگیاں",
    "Received Payments": "مل گئی ادائیگیاں",
    "Date": "تاریخ",
    "Cycles/Time": "چکر/وقت",
    "Payment As": "ادائیگی کی طرح",
    "Name": "نام",
    "Cancel": "منسوخ کریں",
    "Subscriptions & Scheduled": "سبسکرپشنز اور شیڈولڈ",
    "Select the option that matches your needs from the list below:": "نیچے دی گئی فہرست میں اپنی ضروریات کے مطابق ایک آپشن منتخب کریں:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "آسانی سے دوستوں، خاندان یا کاروباروں کو ملک یا بین الاقوامی طور پر پیسہ بھیجیں۔ بینک ڈپوزٹ، موبائل والٹ، نقدی اٹھانا، ادائیگی کارڈز اور والٹ سے والٹ ٹرانسفر جیسے متعدد اہلکاروں میں سے ایک کا انتخاب کریں۔ InstaPay کی سےملسل اور فوری پیسے کی منتقلیوں کے لئے آسانی اور حفاظت کا لطف اٹھائیں۔",
    "International Transfer": "بین الاقوامی ٹرانسفر",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "آسانی سے حدود کو چھوڑتے ہوئے پیسے بھیجیں۔ دوستوں، خاندان یا کاروباروں کو صرف چند کلکس میں بین الاقوامی فنڈ ٹرانسفر کریں۔ بینک اکاؤنٹ، موبائل مونی/موبائل والٹ، نقدی اٹھانے کے مقامات یا ادائیگی کارڈ کا انتخاب کریں \nآسان اور محفوظ ٹرانزیکشنز کے لئے۔",
    "Select the country where you want to send the money!": "وہ ملک منتخب کریں جہاں آپ پیسے بھیجنا چاہتے ہیں!",
    "Search Country": "ملک تلاش کریں",
    "Trouble Sending Money?": "پیسے بھیجنے میں مشکل ہے؟",
    "Confirm": "تصدیق کریں",
    "Select Payout Channel": "پی آؤٹ چینل منتخب کریں",
    "Select your most convenient option to send the money.": "پیسے بھیجنے کے لئے اپنے سب سے موزوں اختیار کو منتخب کریں۔",
    "Select The Bank": "بینک منتخب کریں",
    "Search Bank": "بینک تلاش کریں",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "صارف کے بینک اکاؤنٹ میں سیدھے پیسے بھیجیں۔ دنیا بھر میں کسی بھی بینک کو فنڈ کو بے رکاوٹ ٹرانسفر کرنے کا آسان اور محفوظ طریقہ اٹھائیں۔",
    "Bank Transfer": "بینک ٹرانسفر",
    "Enter the amount": "رقم درج کریں",
    "Tax": "ٹیکس",
    "Amount the recipient will receive": "وصول کرنے والے کو ملنے والی رقم",
    "Select Remarks / Reason": "ریمارکس / وجہ منتخب کریں",
    "Comments": "تبصرے",
    "Attach Files": "فائلیں منسلک کریں",
    "Groups": "گروہ",
    "Manage Groups": "گروہ منظم کریں",
    "Add New Group": "نیا گروہ شامل کریں",
    "Invite a Person": "ایک شخص کو دعوت دیں",
    "Manage Beneficiaries": "فائدہ اُٹھانے والوں کا منظم کریں",
    "Recipients List": "وصول ہونے والوں کی فہرست",
    "Search Contacts": "رابطے تلاش کریں",
    "Confirm Your Payment": "اپنی ادائیگی کی تصدیق کریں",
    "Select Country": "ملک منتخب کریں",
    "Select the Recipient's Payment Channel": "وصول ہونے والے کا ادائیگی چینل منتخب کریں",
    "Change Selection": "تبدیلی کا انتخاب",
    "You Are Transferring ": "آپ بھیج رہے ہیں ",
    "Oops! Something Went Wrong.": "اوہ! کچھ غلط ہو گیا ہے۔",
    "We're Sorry About That. ": "ہم اس پر معافی چاہتے ہیں۔ ",
    "Go back to Payments Page.": "ادائیگیوں کی پیج پر واپس جائیں۔",
    "Wallet to Wallet Transfer": "والٹ سے والٹ ٹرانسفر",
    "Request a customized price quote from service providers or vendors.": "خدمات فراہم کنندگان یا فروخت کنندگان سے ایک تخصیص شدہ قیمت کا حوالہ درخواست کریں۔",
    "Enter the Wallet ID": "والٹ آئی ڈی درج کریں",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "وصول کنندہ کی والٹ آئی ڈی، ای میل یا فون نمبر درج کریں۔",
    "Is the Recipient Not on InstaPay? Invite Them.": "کیا وصول کنندہ انسٹاپے پر نہیں ہے؟ انہیں دعوت دیں۔",
    "Invite Them": "انہیں دعوت دیں",
    "My Beneficiaries": "میرے فائدہ اُٹھانے والے",
    "Enter the Amount You Wish to Transfer.": "وہ رقم درج کریں جو آپ منتقل کرنا چاہتے ہیں۔",
    "Sending to ": "بھیج رہے ہیں ",
    "You Have Selected Wallet to Wallet Transfer": "آپ نے والٹ سے والٹ ٹرانسفر منتخب کیا ہے",
    "Authenticate Your Payment.": "اپنی ادائیگی کی تصدیق کریں۔",
    "You are transferring": "آپ بھیج رہے ہیں",
    "Total ": "کل ",
    "Where Is My Payment?": "میری ادائیگی کہاں ہے؟",
    "Share": "شیئر کریں",
    "Your Payment Is Confirmed": "آپکی ادائیگی کی تصدیق ہو گئی ہے",
    "QR Code Payment": "QR کوڈ ادائیگی",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "فوراً پیسہ دوسرے انسٹاپے صارفوں کو ہمارے محفوظ والٹ سے والٹ سروس کے ذریعے منتقل کریں۔ چند سیکنڈوں کے اندر فنڈ کیلئے پریشانی مفت بھیجیں اور وصول کریں۔",
    "Scan QR with Your Camera or Upload from Your Device.": "اپنی کیمرے سے QR کوڈ سکین کریں یا اپنے ڈوائس سے اپ لوڈ کریں۔",
    "Upload from Your Device.": "اپنے ڈوائس سے اپ لوڈ کریں۔",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "یا تو، انسٹاپے یونیک ادائیگی پتہ (UPA) استعمال کریں",
    "Enter InstaPay UPA": "انسٹاپے یونیک ادائیگی پتہ (UPA) درج کریں",
    "Recipient not on InstaPay? Invite Them": "انسٹاپے پر وصول کنندہ نہیں ہے؟ انہیں دعوت دیں",
    "Verify QR Code": "QR کوڈ کی تصدیق کریں",
    "Scan/Upload QR Code of the Recipient": "وصول کنندہ کا QR کوڈ اسکین/اپ لوڈ کریں",
    "Payment Confirmed": "ادائیگی کی تصدیق ہو گئی ہے",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "خدمات فراہم کنندگان یا فروخت کنندگان سے اپنی ضروریات کے لئے درست لاگت کی تخمینیں حاصل کرنے کے لئے تخصیص شدہ قیمت کے حوالے کا درخواست کریں۔",
    "Choose The Beneficiary or Group": "فائدہ اُٹھانے والا یا گروہ منتخب کریں",
    "Enter the Amount You Wish to Request.": "وہ رقم درج کریں جو آپ درخواست کرنا چاہتے ہیں۔",
    "You Are Requesting Money": "آپ پیسے کی درخواست کر رہے ہیں",
    "You are requesting": "آپ درخواست کر رہے ہیں",
    "As": "کے طور پر",
    "Conversion of Your Cryptocurrencies": "آپ کی کرپٹوکرنسیوں کا تبادلہ",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "ہمارے صارف مدد گار، محفوظ اور موثر کیش آؤٹ کرپٹو سے فائیٹ کانورژن کا تجربہ کریں۔",
    "Choose the Cryptocurrency You Intend to Liquidate.": "وہ کرپٹوکرنسی منتخب کریں جسے آپ بحرانی کا ارادہ رکھتے ہیں۔",
    "Next": "اگلا",
    "Enter the Crypto Wallet Address of the Recipient.": "وصول کنندہ کا کرپٹو والٹ ایڈریس درج کریں۔",
    "Minimum Transactional Value: $100.": "کم سے کم لین دین کی قدر: $100۔",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "نوٹ: کرپٹو کی قیمتیں بار بار تبدیل ہوتی ہیں۔ آخری حوالے کو تصدیق کے صفحے پر رجوع کریں۔",
    "Select Network": "نیٹ ورک منتخب کریں",
    "Send A Quote": "ایک قیمت کا حوالہ بھیجیں",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "اپنے گاہکوں یا صارفین کو تفصیلی قیمت کا حوالہ بھیجیں۔ اپنی خدمات، مصنوعات یا پیشکشوں کو ایک شخصیت سے بھیجی گئی قیمت کے ساتھ پیش کریں۔",
    "Choose the Beneficiary": "فائدہ اُٹھانے والا منتخب کریں",
    "Allow Receiver to Bargain": "وصول کنندہ کو مہلت دیں",
    "Enter the Title for Your Quotation.": "اپنے حوالے کے لئے عنوان درج کریں۔",
    "Enter the Description for Your Quotation": "اپنے حوالے کے لئے تفصیل درج کریں",
    "You are Quoting": "آپ قیمت لگا رہے ہیں",
    "Title": "عنوان",
    "Description": "تفصیل",
    "Attachments": "منسلکہ",
    "Confirm Your Quotation": "اپنے حوالے کی تصدیق کریں",
    "Verify The Quotation": "حوالے کی تصدیق کریں",
    "Your quotation is confirmed": "آپ کا حوالہ تصدیق ہوا ہے",
    "Request A Quote": "ایک قیمت کی درخواست کریں",
    "Coming Soon": "جلد ہی آ رہا ہے",
    "Add Beneficiary": "فائدہ اُٹھانے والا شامل کریں",
    "Search for Beneficiaries": "فائدہ اُٹھانے والوں کی تلاش کریں",
    "Connected Accounts": "منسلک ہونے والے حسابات",
    "Details": "تفصیلات",
    "First Name": "پہلا نام",
    "Last Name": "آخری نام",
    "Country": "ملک",
    "City": "شہر",
    "Address Line": "پتہ لائن",
    "Mobile Number": "موبائل نمبر",
    "Relationship With Beneficiary": "فائدہ اُٹھانے والے کے ساتھ تعلق",
    "Bank Account": "بینک اکاؤنٹ",
    "Mobile Money/Mobile Wallet": "موبائل منی / موبائل والٹ",
    "Payment Card": "ادائیگی کارڈ",
    "Crypto Wallet": "کرپٹو والٹ",
    "InstaPay Account": "انسٹاپے اکاؤنٹ",
    "Cash Pickup": "کیش پک اپ",
    "IBAN / SWIFT Code": "IBAN یا سوفٹ / بی آئی سی کوڈ",
    "Swift Code": "سوفٹ کوڈ",
    "Account Number": "اکاؤنٹ نمبر",
    "Bank Name": "بینک کا نام",
    "Branch Name": "برانچ کا نام",
    "Branch Street": "برانچ کی سڑک",
    "Province": "صوبہ",
    "Postal Code": "پوسٹل کوڈ",
    "Mobile Money Provider": "موبائل منی فراہم کنندہ",
    "Wallet Name": "والٹ کا نام",
    "Wallet Number": "والٹ نمبر",
    "Card Holder Name": "کارڈ ہولڈر کا نام",
    "Card Number": "کارڈ نمبر",
    "Expiry Date": "انقضاء کی تاریخ",
    "Crypto Currency": "کرپٹو کرنسی",
    "Wallet Address": "والٹ کا پتہ",
    "Wallet Holder Name": "والٹ ہولڈر کا نام",
    "Wallet Currency": "والٹ کرنسی",
    "Select Document Type": "دستاویز کی قسم منتخب کریں",
    "Enter Document Number": "دستاویز نمبر درج کریں",
    "Add Individual Account": "انفرادی اکاؤنٹ شامل کریں",
    "Add Business Account": "بزنس اکاؤنٹ شامل کریں",
    "Company Address": "کمپنی کا پتہ",
    "Company Email": "کمپنی ای میل",
    "Company Phone No": "کمپنی فون نمبر",
    "Total Referrals": "کل ریفرلز",
    "My Earnings": "میری کمائی",
    "Your Referral ID": "آپ کا ریفرل آئی ڈی",
    "Share Your Unique Referral Link": "اپنا یونیک ریفرل لنک شیئر کریں",
    "How do I Refer a Friend to InstaPay?": "میں اپنے دوست کو انسٹاپے پر کس طرح ریفر کروں؟",
    "Step 01": "مرحلہ 01",
    "Share your InstaPay unique referral link with your friends.": "اپنا انسٹاپے یونیک ریفرل لنک اپنے دوستوں کے ساتھ شیئر کری",
    "Step 02": "مرحلہ 02",
    "Earn rewards when they transact $100 or more": "جب وہ 100 ڈالر یا اس سے زیادہ کی ٹرانزیکشن کریں تو انعام حاصل کریں",
    "Step 03": "مرحلہ 03",
    "Earn up to $0.06 for every transaction your followers make.": "اپنے ہر فالوور کی ہر ٹرانزیکشن کے لئے 0.06 ڈالر تک کمائیں",
    "My Referrals": "میرے ریفرلز",
    "Referral Complete": "ریفرل مکمل",
    "Received Quotations": "پذیرفتہ حوالے",
    "Sent Quotations": "بھیجے گئے حوالے",
    "Search Pending Items": "انتظار میں چیزوں کی تلاش",
    "Actions": "اعمال",
    "Received Requests": "پذیرفتہ درخواستیں",
    "Sent Requests": "بھیجی گئی درخواستیں",
    "No Actions": "کوئی اعمال نہیں",
    "You Requested": "آپ نے درخواست دی ہے",
    "Via": "زریعہ",
    "How was your experience?": "آپ کے تجربات کیسے تھے؟",
    "Leave a Review": "ایک جائزہ چھوڑیں",
    "Overall Ratings": "کل ریٹنگز",
    "Visit Profile": "پروفائل دیکھیں",
    "Ratings": "ریٹنگز",
    "Reviews": "جائزے",
    "No reviews available": "کوئی جائزے دستیاب نہیں ہیں",
    "Select Your Currency": "اپنی کرنسی منتخب کریں",
    "Proceed": "آگے بڑھیں",
    "Commision": "کمیشن",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "انسٹاپے QR کوڈ اسٹیکر کے ساتھ بے رکاوٹ ٹرانزیکشن کو کھولیں",
    "Effortless and Swift:": "کسی مشکل کی آڑ میں اور تیزی سے:",
    "Instantly download your unique InstaPay QR code sticker.": "فوراً اپنا یونیک انسٹاپے QR کوڈ اسٹیکر ڈاؤن لوڈ کریں۔",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "پیمنٹ ٹرمینلز جیسی مشکل زیرِ بنیاد چیزوں کی ضرورت کے بغیر مشکل سے اور کیش لیس ڈراپسٹس کا تجربہ کریں۔",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "فوراً ادائیگی جمع کریں - کریڈٹ کارڈ یا فون نمبرز کی مزید مشکل درخواست نہ ہو۔",
    "Each payment includes customer information for your convenience.": "ہر ادائیگی میں آپ کی سہولت کے لئے صارف کی معلومات شامل ہوتی ہے۔",
    "Economically Smart:": "اقتصادی طور پر ہوشیار:",
    "Zero activation fees – start immediately without upfront costs.": "صفر چالن فیس - پہلے ہی شروع ہوں بغیر اگلے خرچ کے۔",
    "Forget about fixed monthly charges; pay as you transact.": "مستقر ماہانہ چارجز کو بھول جائیں؛ جو ٹرانزیکٹ کرتے ہیں ویسا ہی ادا کریں۔",
    "Ditch the hardware; no terminal costs mean more savings for you.": "ہارڈوئیر چھوڑ دیں؛ بغیر ٹرمینل کے خرچے آپ کے لئے مزید بچت ہوتی ہے۔",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "صرف 0.75٪ فی QR کوڈ ادائیگی پر کم ٹرانزیکشن فیس کا لطف اٹھائیں۔",
    "We don't impose minimum commissions; you save more with each transaction.": "ہم نے منہم کمیشن لگانا بند کر دیا ہے؛ ہر ٹرانزیکشن کے ساتھ آپ مزید بچت کرتے ہیں۔",
    "Experience the joy of instant payments, enhancing your cash flow.": "فوری ادائیگیوں کی خوشی کا تجربہ کریں، اپنی ہوائی رقم میں اضافہ ہوگا۔",
    "Make Every Transaction Count with InstaPay": "انسٹاپے کے ساتھ ہر ٹرانزیکشن کو حساب کتنا بنائیں",
    "Wallet QR": "والٹ QR",
    "Download QR": "QR ڈاؤن لوڈ کریں",
    "QR Code Status": "QR کوڈ کی حالت",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "اپنا QR کوڈ یا ادائیگی کا پتہ لنک فوراً اپنے انسٹاپے والٹ میں ادائیگی حاصل کرنے کے لئے شیئر کریں۔",
    "QR Code Title": "QR کوڈ ٹائٹل",
    "View Transactions": "ٹرانزیکشنز دیکھیں",
    "My QR Codes:": "میرے QR کوڈز:",
    "Load More": "مزید لوڈ کریں",
    "Benefits Of Portfolio": "پورٹفولیو کے فوائد",
    "Benefits:": "فوائد:",
    "Enhanced Visibility:": "بڑھی ہوئی دکھائیں:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "اپنے عوامی پروفائل کو بلند کریں۔ آپ کا پورٹفولیو ، آپ کے ادائیگی پتے سے سیدھے دستیاب ہوکر ایک متحرک شوکیس کی طرح ہوتا ہے۔",
    "Engage Your Audience:": "اپنے حضور کو مشغول کریں:",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "اپنے تخلیقاتی یا پیشہ ورانہ سفر کے ذریعے دورہ ہونے والوں سے جڑیں۔ اپنے کام کو آپ کے لیے بات کرنے دیں ، ممکنہ گاہک یا حمایت کنندگان کے ساتھ گہرائی سے مشغولیت پیدا ہوتی ہے۔",
    "Integrated Social Proof:": "متضمن سوشل ثابتی:",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "اپنے سوشل میڈیا کے اثرات کو ظاہر کریں۔ دورہ ہونے والے آپ کے سوشل میڈیا کے رسائی کو دیکھ سکتے ہیں ، جو آپ کے پورٹفولیو میں اعتبار اور سیاق و سباق شامل کرتا ہے۔",
    "Seamless Transactions:": "بے رکاوٹ لین دین:",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "دورہ ہونے والوں کو چاہتی ہیں یا ادائیگیاں صرف آپ کے عوامی صفحے سے ہی فراہم ہوتی ہیں۔ آپ کا پورٹفولیو صرف آپ کے کام کو ظاہر کرتا ہے بلکہ آسان مالی تعاملات ممکن بناتا ہے۔",
    "Personalized Storytelling:": "شخصیات سے بھرا ہوا کہانی گوئی:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": " 'میرے بارے میں' حصے کا استعمال آپ کی کہانی ، منصوبہ یا خواب شیئر کرنے کے لئے کریں ، جو آپ کے حاضرین کے ساتھ ایک شخصی تعلق پیدا کرتا ہے۔",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "'میرا پورٹفولیو' صرف ایک گیلری سے زیادہ ہے؛ یہ آپ کی برانڈ بنانے ، حاضرین کو مشغول کرنے اور آپ کے مالی تعاملات کو انسٹاپے پر سیدھا کرنے کے لئے ایک ٹول ہے۔",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "اپنے کام کو ظاہر کریں ، اپنے رسائی کو بڑھائیں۔ 'میرا پورٹفولیو' انسٹاپے پر آپ کا ڈیجیٹل کینوس ہے۔ دلچسپ تصاویر اور ویڈیوز سے لے کر معلوماتی پی ڈی ایف تک ، آپ مختلف قسم کے مواد کو اپلوڈ اور ظاہر کر سکتے ہیں۔ چاہے آپ کالا ہیں ، فری لانسر ہیں یا کاروباری ہیں ، یہ خصوصیت آپ کو اپنے کام یا منصوبوں کو ایک دکھائی گئی طریقے سے پیش کرنے کی اجازت دیتی ہے۔",
    "Add/Manage Portfolio": "پورٹفولیو شامل / منظم کریں",
    "Add New": "نیا شامل کریں",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "اپنے انسٹاپے ادائیگی پتے کے ساتھ اپنے سوشل میڈیا کمائیوں کو زیادہ سے زیادہ بنائیں",
    "Make It Effortless for Your Audience to Support You:": "اپنے حاضرین کو آپ کی حمایت کرنے کے لئے اسے بے جدوجہ بنائیں:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "آپ کے انسٹاپے ادائیگی پتے کو آپ کے سوشل میڈیا پوسٹس میں آسانی سے شامل کریں۔",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "آپ کے ہر پوسٹ یا کہانی میں اپنے ادائیگی پتے کا لنک آسانی سے کاپی اور پیسٹ کریں یا اسے آپ کے پروفائل پر اہم مقام پر رکھیں۔",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "انفلوئنسرز ، ہنرمندانہ ، کاروباری یا جو بھی ایسا کوئی ہے جس کے پاس ایک جماعت ہے جو اپنی حمایت ظاہر کرنے کے لئے بے صبر ہے۔",
    "Instant Support, Endless Possibilities:": "فوری حمایت ، بے حد ممکنات:",
    "Transform the way you monetize your social media content.": "اپنے سوشل میڈیا مواد کو منافع حاصل کرنے کے طریقے کو تبدیل کریں۔",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "اپنے پیروکاروں کو بصورت فوراً ایک کلک کے ذریعے آپ کو فنڈ بھیجنے کی اجازت دیں ، سیدھے ان کے مقامی سوشل نیٹ ورکس سے۔",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "لاعلی زندگی کے دوران فوری حمایت کے لئے مثالی ہے ، آپ کے تخلیقی مواد کے لئے ٹپنگ سسٹم کے طور پر یا آپ کے اگلے بڑے منصوبے کو مالی مدد کے لئے۔",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "اپنے انسٹاپے ادائیگی پتے کو زیادہ سے زیادہ اثرات کے لئے کہاں رکھیں:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "سوشل میڈیا پروفائلز: اسے اپنی بائیو یا معلومات حصے میں پن کریں تاکہ یہ مسلسل نمائش ہو۔",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "پوسٹ اور اعلانات: اسے اپنے انفرادی پوسٹس میں شامل کریں ، خصوصاً ان میں سے جو آپ کے کام یا خصوصی حمایت کے لئے ہیں۔",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "کہانیاں اور ریلز: اسے آپ کی متعامل کہانیوں یا دلفریب ریلز میں ملا لیں تاکہ فوری شراکتوں کو پکڑ سکیں۔",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "لاعلی سٹریمز: اسے اپنی لائیو سیشن کے دوران شیئر کریں ، تاکہ دیکھنے والوں کو فوراً مشارکت کرنا آسان ہو۔",
    "Simple, Fast, and Secure:": "سادہ ، تیز اور محفوظ:",
    "No complex setup – get started in moments.": "کوئی پیچیدہ سیٹ اپ نہیں - لمحوں میں شروع ہو جائیں۔",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "انسٹاپے کے اعتماد کرنے والے ادائیگی پروسیسنگ کی حفاظت اور رفتار کا لطف اٹھائیں۔",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "کسی سے بھی کہیں بھی ادائیگی حاصل کریں ، انہیں انسٹاپے اکاؤنٹ کی ضرورت ہوتی ہے۔",
    "Elevate Your Social Media Game with InstaPay Payment Address": "انسٹاپے ادائیگی پتے کے ساتھ اپنے سوشل میڈیا گیم کو بلند کریں",
    "InstaPay VPA (Virtual Payment Address)": "انسٹاپے وی پی اے (واچوئل ادائیگی پتہ)",
    "Payment Address Title": "ادائیگی پتہ ڈائٹل",
    "Currency": "کرنسی",
    "Payment Address Description": "ادائیگی پتہ تفصیل",
    "Update": "اپ ڈیٹ",
    "Updating": "اپ ڈیٹ ہو رہا ہے",
    "Total Transaction": "کل لین دین",
    "Last 30 days": "آخری 30 دن",
    "Last 1 month": "آخری 1 مہینہ",
    "Last 1 year": "آخری 1 سال",
    "Custom Date": "اپنی تاریخ",
    "Payment Methods": "ادائیگی کے طریقے",
    "Payment Types": "ادائیگی کی قسمیں",
    "Requested Amount": "درخواست کردہ رقم",
    "Quoted Amount": "ہوشیار رقم",
    "About Me": "میرے بارے میں",
    "Followers": "پیروکار",
    "InstaPay ID": "انسٹاپے آئی ڈی",
    "Download QR Code": "QR کوڈ ڈاؤن لوڈ کریں",
    "Initiate A Payment": "ایک ادائیگی شروع کریں",
    "Report this person": "اس شخص کی رپورٹ کریں",
    "Add now": "اب شامل کریں",
    "Recent Reviews": "حال ہی میں دی گئی تجویزات",
    "Reviews As Seller": "فروخت کنندہ کی حیثیت میں تجویزات",
    "Reviews As Buyer": "خریدار کی حیثیت میں تجویزات",
    "Select Language": "زبان منتخب کریں",
    "Profile Completion": "پروفائل مکملی",
    "Profile Completed": "پروفائل مکمل ہوگئی",
    "Basic Info": "بنیادی معلومات",
    "Username": "صارف کا نام",
    "Gender": "جنس",
    "Male": "مرد",
    "Female": "عورت",
    "-Select-Gender-": "-جنس منتخب کریں-",
    "Date Of Birth": "پیدائش کی تاریخ",
    "Address": "پتہ",
    "Edit Profile": "پروفائل میں ترتیب دیں",
    "Update Profile": "پروفائل کو اپ ڈیٹ کریں",
    "Updating Profile": "پروفائل کو اپ ڈیٹ کر رہا ہے",
    "Complete Now": "اب مکمل کریں",
    "Password": "پاس ورڈ",
    "Change Password": "پاس ورڈ تبدیل کریں",
    "New Password": "نیا پاس ورڈ",
    "Re-enter New Password": "نیا پاس ورڈ دوبارہ درج کریں",
    "Please follow this guide for a strong password": "ایک مضبوط پاس ورڈ کے لئے براہ کرم یہ ہدایتوں کا پیروی کریں",
    "Include at least one special character.": "کم سے کم ایک خاص حرف شامل کریں۔",
    "Minimum of 8 characters.": "کم سے کم 8 حروف ہوں۔",
    "Include at least one number": "کم سے کم ایک نمبر شامل کریں",
    "Change it often for enhanced security.": "اسے مضبوط سیکیورٹی کے لئے بار بار تبدیل کریں۔",
    "Security Questions": "سیکیورٹی سوالات",
    "Security Question": "سیکیورٹی سوال",
    "Select your question": "اپنا سوال منتخب کریں",
    "Answer To The Question": "سوال کا جواب",
    "Nominee": "نامزد",
    "Relationship with Nominee": "نامزد کے ساتھ تعلق",
    "Nominee Contact Number": "نامزد کا رابطہ نمبر",
    "Nominee Address": "نامزد کا پتہ",
    "Delete Nominee": "نامزد ہٹائیں",
    "Update Nominee": "نامزد کو اپ ڈیٹ کریں",
    "Details Updated Successfully!": "تفصیلات کامیابی سے اپ ڈیٹ ہوگئی ہیں!",
    "Success": "کامیابی",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "آپ اپنی مرضی کے مطابق نامزد کا انتخاب کر سکتے ہیں، مگر انہیں بالغ نہیں ہونا چاہئے، انسٹاپے پلیٹفارم کے ذریعے۔ آپ تسلیم کرتے ہیں کہ نامزد مقرر کرنے کے لئے کوئی گیلا دستخط ضروری نہیں ہے، اور آن لائن رجسٹریشن کو آخری اور بانڈنگ ہونے والا سمجھا جائے گا۔ اگر انسٹاپے والٹ ہولڈر کی موت ہوتی ہے تو والٹ میں موجود رقم رجسٹرڈ نامزد کو منتقل ہوگی۔ کیمٹ کنگڈم نامزد کو رقم سومچنے پر اپنی تمام ذمہ داریوں سے چھٹکارا حاصل کرے گا۔ نامزد انسٹاپے کی ضروری دستاویزات فراہم کرے گا، جو شناخت اور موت کا ثبوت شامل ہو سکتی ہیں۔",
    "Add Nominee": "نامزد شامل کریں",
    "Two Factor Authentication": "دو فیکٹر انتظام",
    "SMS Number": "ایس ایم ایس نمبر",
    "Updated Successfully!": "بہترین طریقے سے اپ ڈیٹ ہوگیا!",
    "Social Network Accounts": "سوشل نیٹ ورک اکاؤنٹس",
    "Here, you can set up and manage your integration settings.": "یہاں، آپ اپنی تشکیلات کی ترتیب دینے اور انٹیگریشن کی ترتیب دینے کے لئے استعمال کرسکتے ہیں۔",
    "Social Network Account": "سوشل نیٹ ورک اکاؤنٹ",
    "Activate Account": "اکاؤنٹ چالو کریں",
    "Enter the code below in the InstaPay Chatbot.": "نیچے دیے گئے کوڈ کو انسٹاپے چیٹ بوٹ میں درج کریں۔",
    "The code will expire in ": "کوڈ اس وقت ختم ہوجائے گا ",
    "Notifications": "اطلاعات",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "اطلاعات کو کس طرح سے حاصل کرنا چاہتے ہیں اسے اپنی مرضی کے مطابق ترتیب دیں۔ یہ ترتیبات آپ کی نگرانی کردہ فعالیتوں پر لاگو ہوں گی۔",
    "Activity": "فعالیت",
    "Email": "ای میل",
    "Push": "پش",
    "SMS": "ایس ایم ایس",
    "Source": "ماخذ",
    "Payment Requests": "ادائیگی درخواستیں ",
    "Bulk Payments": "بلک پیمنٹس ",
    "Identity Verification": "شناخت کی تصدیق",
    "Verify Your Identity": "اپنی شناخت کی تصدیق کریں",
    "Identity Verification Status:": "شناخت کی تصدیق کی حالت:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "اپنے بارے میں مختصراً بیان کریں اور اپنے انسٹاپے کا استعمال کرنے کے لئے اپنے وجوہات بیان کریں۔",
    "Enter Your Message": "اپنا پیغام درج کریں",
    "Select Occupation": "پیشہ منتخب کریں",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "یہ حکومت کے ذریعے جاری کردہ شناختی دستاویز ہونی چاہئے جس میں تصویر ہو۔ تمام شناختی دستاویز لاطینی الفبا میں لکھے جانے چاہئے۔ اگر نہیں تو ایک سرٹیفائیڈ ترجمہ درکار ہے۔",
    "Accepted Documents": "قبول شدہ دستاویزات",
    "A national identity card": "قومی شناختی کارڈ",
    "A valid passport": "ایک درست پاسپورٹ",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "درست رہائی کی اجازت (صرف اس صورت میں قبول ہوگی اگر یہ یورپی ملک یا سوئٹزرلینڈ کے ذریعے جاری ہو)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "بائیومیٹرک چہرے کی شناخت: ویڈیو کو پاسپورٹ فوٹو کے ساتھ موازنہ کرنا",
    "Address Verification:": "پتہ کی تصدیق:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "ایک بینک کا اسٹیٹمنٹ یا یوٹلٹی بل (تین مہینے سے کم پرانا) فراہم کریں جو پتہ ثابت کرنے کے لئے ہو",
    "Additional Documents:": "اضافی دستاویزات:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "کیمٹ کنگڈم کو ہق ہے کہ وہ مضاف دستاویزات (ترقی یافتہ ڈو ڈلیجنس - EDD) یا مزید معلومات کی درخواست کرے جو کہ کسی بھی اہم چیکس کے لئے ضروری ہوں، اس کے قانونی التزامات کے مطابق۔",
    "Start Verification": "تصدیق شروع کریں",
    "Sessions": "سیشنز",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "یہ ایک ڈوائسز کی فہرست ہے جو آپ کے اکاؤنٹ میں لاگ ان ہوئی ہیں۔ جو بھی آپ نہیں پہچانتے ہیں، انہیں ہٹا دیں۔",
    "Your Withdrawal Options": "آپ کے وڈراول اختیارات",
    "Choose your preferred method for withdrawing payments.": "اپنے مستقبل کی ادائیگیوں کے لئے اپنا مرضی کا طریقہ منتخب کریں۔",
    "No withdrawal options currently available in your country.": "آپ کے ملک میں فی الحال کوئی وڈراول اختیارات دستیاب نہیں ہیں۔",
    "Download Your Data": "اپنے ڈیٹا کو ڈاؤن لوڈ کریں",
    "Request a Copy of Your Data": "اپنے ڈیٹا کا ایک کاپی حاصل کریں",
    "Submit a request to download a copy of your data": "اپنے ڈیٹا کا ایک کاپی ڈاؤن لوڈ کرنے کی درخواست دیں",
    "Which Data Should Be Included in the Download?": "ڈاؤن لوڈ میں کون سا ڈیٹا شامل ہونا چاہئے؟",
    "Select all applicable options.": "تمام مطابق اختیارات کو منتخب کریں۔",
    "Personal Information": "ذاتی معلومات",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "یہ معلومات آپ کے اکاؤنٹ کو محفوظ بنانے، آپ کے تجربے کو مطابق بنانے اور ضرورت کے مطابق آپ سے رابطہ کرنے کے لئے استعمال ہوتی ہے۔",
    "Name and Date Of Birth": "نام اور تاریخ پیدائش",
    "Phone number": "فون نمبر",
    "Financial Information": "مالی معلومات",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "ہم یہ معلومات تیز چیک آؤٹ کو ممکن بنانے اور صرف چند کلکس میں پیسے بھیجنے یا حاصل کرنے کے لئے استعمال کرتے ہیں۔",
    "Bank accounts": "بینک اکاؤنٹس",
    "Debit or credit cards": "ڈیبٹ یا کریڈٹ کارڈز",
    "Credit Information": "کریڈٹ معلومات",
    "Includes your credit application information": "آپ کی کریڈٹ درخواست کی معلومات شامل ہے",
    "Other Information (not included in file)": "دوسری معلومات (فائل میں شامل نہیں ہے)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "آلہ کی معلومات، ٹیکنیکل استعمال کی ڈیٹا، جغرافیائی مقامات کی معلومات، مارکیٹنگ کی ترجیحات، رضامندی کی تاریخ، اور کریڈٹ، شناخت کی تصدیق، پے پال کے ساتھ رابطہ، اور تیسرے حزب کے پروسیسرز جیسی دیگر خدمات کے لئے استعمال ہونے والی ڈیٹا شامل ہے۔",
    "Choose file type:": "فائل کی قسم منتخب کریں:",
    "Select file type": "فائل کی قسم منتخب کریں",
    "PDF file can be easily opened on any computer": "PDF فائل کو کسی بھی کمپیوٹر پر آسانی سے کھولا جا سکتا ہے",
    "Submit Request": "درخواست دیں",
    "Delete Your Account": "اپنا اکاؤنٹ حذف کریں",
    "Ask us to delete your data and close this account": "ہمیں اپنے ڈیٹا کو حذف کرنے اور یہ اکاؤنٹ بند کرنے کی درخواست کریں",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "ہم آپ کی معلومات کو خدمات فراہم کرنے کے لئے استعمال کرتے ہیں، لہذا اگر ہم آپ کی معلومات کو حذف کریں، تو ہمیں آپ کا اکاؤنٹ بھی بند کرنا ہوگا۔",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "اگر آپ کا اکاؤنٹ محدود ہے، تو ریزولیوشن سینٹر پر جائیں، 'Go to Account Limitations' منتخب کریں، پھر 'Resolve' پر کلک کریں۔",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "یہ یہد رہے کہ کوئی باقی ہوئی ادائیگیاں صاف ہوں۔ ہم آپ کا اکاؤنٹ بند کرنے میں کامیاب ہونے تک ہم آپ کے اکاؤنٹ کو بند نہیں کر سکیں گے۔",
    "If you have a negative balance, click the Resolve link next to your balance.": "اگر آپ کا بینک بیلنس منفی ہے، تو اپنے بیلنس کے بلکے کے پاس والے Resolve لنک پر کلک کریں۔",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need to retain some of your data for legal and regulatory reasons. \nTo learn more, see our ": "ایک بار جب آپ کا اکاؤنٹ صاف ہو جائے اور آپ واجب شرائط پر پوری ہوں، ہم آپ کا اکاؤنٹ بند کریں گے اور آپ کی معلومات حذف کریں گے۔ ہمیں قانونی اور ریگولیٹری وجوہات کے لئے آپ کی کچھ معلومات کو محفوظ رکھنا ہے۔ \nمزید جاننے کے لئے ہمارے ",
    "privacy statement": "رازداری بیان دیکھیں",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "ہم آپ کو ای میل کریں گے تاکہ آپ کو حالت کا علم ہو اور اگر کوئی اور کدام قدم آپ کو اٹھانا ہو تو۔",
    "Have more questions about data deletion?": "ڈیٹا حذف کے بارے میں مزید سوالات ہیں؟",
    "Contact us": "ہم سے رابطہ کریں",
    "I understand that account deletion is permanent and can't be reversed.": "مجھے معلوم ہے کہ اکاؤنٹ کا حذف دائمی ہے اور اسے الٹا نہیں کیا جا سکتا۔",
    "Are you sure you want to close your account?": "کیا آپ واقعی اپنا اکاؤنٹ بند کرنا چاہتے ہیں؟",
    "Yes, Continue": "جی ہاں، جاری رہے",
    "Logging In": "لاگ ان",
    "Complete your profile to streamline your transaction process.": "اپنی پروفائل مکمل کریں تاکہ آپ کے لین دین کا عمل آسان ہو جائے۔",
    "Skip": "چھوڑیں",
    "Complete Your Profile": "اپنی پروفائل مکمل کریں",
    "API Key": "API کلید",
    "Get API Key": "API کلید حاصل کریں",
    "Generating New API Key": "نیا API کلید بنا رہا ہے",
    "Generate API Key": "API کلید بنائیں",
    "Regenerate API Key": "API کلید دوبارہ بنائیں",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "InstaPay کی بے رکاوٹ لین دین کی مکمل صلاحیت حاصل کرنے کے لئے، ان چھوٹے قدموں کو اٹھائیں تاکہ آپ اپنی API کلید بنا سکیں۔ پہلے، \n[your registration URL] پر ایک اکاؤنٹ بنائیں اور ڈویلپر ڈیش بورڈ تک رسائی حاصل کریں۔ 'API Keys' سیکشن پر جائیں، ایک نیا کلید بنائیں اور اس کو محفوظ طور پر سٹور کریں۔",
    "Delete API Key": "API کلید حذف کریں",
    "Webhook URL": "ویب ہک یو آر ایل",
    "KYB Verification": "KYB تصدیق",
    "Additional Files": "اضافی فائلیں",
    "Business Verification": "بزنس کی تصدیق",
    "--Select-Your-Company-Type--": "--اپنی کمپنی کی قسم منتخب کریں--",
    "Documents Required:": "دستاویزات کی ضرورت ہے:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "آفیشل کمپنی رجسٹری ایکسٹریکٹ (انٹٹی کا رجسٹریشن/انکارپوریشن ڈاکیومنٹ)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "یہ دستاویز کمپنی کی آفیشل 'شناختی کارڈ' کے طور پر کام کرتا ہے اور اسے مقامی کاروبار رجسٹری سے حاصل کیا جانا چاہئے۔ یہ کمپنی کے فعلی حالت کو مظاہر کرتا ہے \n(فعال یا بند).",
    "The document should be current, with an issuance date not exceeding 3 months.": "دستاویز فعلی ہونی چاہئے، جس کی جاری کرنے کی تاریخ 3 مہینے سے زیادہ نہ ہو۔",
    "The document must include the following details:": "دستاویز میں مندرجہ ذیل تفصیلات ہونی چاہئیں:",
    "Legal name of the company": "کمپنی کا قانونی نام",
    "Full registered address": "پوری رجسٹرڈ ایڈریس",
    "Company registration number": "کمپنی رجسٹریشن نمبر",
    "Date of incorporation": "شاملہ کی تاریخ",
    "Description of the company's activities/purpose": "کمپنی کی فعالیتوں/مقصد کا تفصیلات",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "قانونی نمائندہ کی معلومات: پورا نام، پیدائش کی تاریخ اور جگہ، رہائشی پتہ (کم سے کم ضرورت: رہائش کے ملک)، اور قومیت",
    "If applicable, the document should also include:": "اگر لاگو ہوتا ہے تو، دستاویز میں درج ذیل بھی ہونا چاہئے:",
    "Brand or trading names": "برانڈ یا ٹریڈنگ نام",
    "Value Added Tax (VAT) number": "ویلیو ایڈڈ ٹیکس (وی ٹی) نمبر",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "اولٹیمیٹ بینفیشل آونرز (یو بی اوز) کی فہرست - یہ کچھ ملکوں میں لازمی ہے، بصورت دیگر ورنہ، یہ الگ ڈاکیومنٹ کے طور پر فراہم کیا جا سکتا ہے۔",
    "Proof of Legal Representative's Authority": "قانونی نمائندہ کی اختیار کی پروف",
    "This document validates an individual's authority to represent the company": "یہ دستاویز ایک افراد کی کمپنی کو نمائندگی کرنے کا اختیار کو توثیق دیتا ہے",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "عام طور پر، کمپنی کی رجسٹری ایکسٹریکٹ میں قانونی نمائندہ کا نام ہوتا ہے۔ ہاں، اگر یہ معاملہ یوں نہ ہو تو، ایک وکالتنامہ یا اختیارات کی مختاری کی طرح کا کاغذ حاصل کرنا لازمی ہے۔",
    "Power of Attorney/Delegation of Powers:": "وکالتنامہ/اختیارات کی مختاری:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "اختیار دینے والے فرد کو قانونی طور پر اس حق کے حاصل ہونا چاہئے۔ یہ فرد کمپنی کی رجسٹری ایکسٹریکٹ میں ایک قانونی نمائندہ کے طور پر درج ہونا چاہئے۔",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "ملکیتی ڈھانچہ اور اولٹیمیٹ بینفیشل آونر (یو بی او) ڈوکیومنٹیشن",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "کمپنی کی موزوں اسٹاک ایکسچینج پر درج ہونے کا ثبوت فراہم کیا جانا چاہئے۔ یہ ہو سکتا ہے کہ یہ ایک صفحہ ہو جو اسٹاک ایکسچینج ویب سائٹ سے کمپنی کی تفصیلات کو دکھاتا ہے۔",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "اگر کمپنی کے شیئرز کم سے کم 75٪ عوامی ملکت میں ہوں، تو تمام شیئر ہولڈرز جو شیئر کا 25٪ سے زیادہ ملکت یا انہیں کنٹرول کرتے ہیں، وہ سب اولٹیمیٹ بینفیشل آونر (یو بی او) تصدیق کی ضروریات کے تحت ہوں گے۔",
    "Collection of Identification Documents": "شناختی دستاویزات کی جمع ومعلول",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "شناختی دستاویزات حکومت کی جانب سے جاری ہونی چاہئیں، جو ایک فرد کی تصویر کے ساتھ ہو۔ تمام شناختی دستاویزات کو لاطینی حروف میں لکھا گیا ہونا چاہئے۔ اگر نہیں تو، ایک سند ہونا چاہئے۔",
    "Accepted documents:": "قبول کردہ دستاویزات:",
    "Valid National Identity Card": "جاری قومی شناختی کارڈ",
    "Valid Passport": "جاری پاسپورٹ",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "جاری رہائشی اجازتیں - یہ صرف اور صرف یورپی ملک سے جاری ہونے پر قبول کی جائیں گی۔",
    "Only legal representatives are subject to the identification document collection process.": "شناختی دستاویزات کی جمع ومعلول کے عمل کے لئے صرف قانونی نمائندگان متعین ہیں۔",
    "Additional Requirement:": "اضافی ضرورت:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "وکالتنامہ یا اختیارات کی مختاری کے معاملات میں، دونوں طرفیں (اختیار دینے والے فرد اور اسے حاصل کرنے والے فرد) کو اپنے شناختی کارڈ کی کاپی فراہم کرنی چاہئیے۔",
    "Additional Documents": "اضافی دستاویزات",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "یوٹیلٹی بل (جیسے گیس، بجلی، ٹیلیفون یا موبائل فون بل)؛ یا حکومتی دیپارٹمنٹ کے ذریعے جاری ہونے والا کاغذ جو انڈ یوزر کا پتہ اور نام دکھاتا ہے (مثال کے طور پر، ریزڈنشل سرٹیفیکیٹ)؛ یا",
    "Bank Statement; or": "بینک اسٹیٹمنٹ؛ یا",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "ایک حکومتی دیپارٹمنٹ کے ذریعے جاری ہونے والا کاغذ جو انڈ یوزر کا پتہ اور نام دکھاتا ہے (مثال کے طور پر، ریزڈنشل سرٹیفیکیٹ)۔",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "کیمٹ کنگڈم کو شدت سے تصدیقات کرنے کے لئے کسی بھی اضافی دستاویزات یا مزید معلومات کی درخواست کرنے کا حق ہے، جو اس کے قانونی الزامات کے مطابق ہوتا ہے۔",
    "Note:": "نوٹ:",
    "Partners": "شراکت دار",
    "Add New Partner": "نیا شراکت دار شامل کریں",
    "Designation": "عہد",
    "Partner Name": "شراکت دار کا نام",
    "Partner Email": "شراکت دار کا ای میل",
    "Partner Designation": "شراکت دار کا عہد",
    "Add Now": "اب شامل کریں",
    "Adding": "شامل ہو رہا ہے",
    "Delete Partner": "شراکت دار کو حذف کریں",
    "Delete": "حذف کریں",
    "Deleting": "حذف ہو رہا ہے",
    "Are you sure, you want to remove sxz as partner?": "کیا آپ یقینی ہیں کہ آپ sxz کو شراکت دار کے طور پر ہٹانا چاہتے ہیں؟",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "انٹٹی کا رجسٹری ایکسٹریکٹ (انٹٹی کا رجسٹریشن/تخلیق ڈاکیومنٹ)",
    "This document should be either:": "یہ دستاویز یا تو یہ ہونا چاہئے:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "انٹٹی کی تخلیق کا ثبوت فراہم کرنے والا، انٹٹی کی تفصیلات، تخلیق کی تاریخ، اور مقصد فراہم کرنے والا۔",
    "A local registry extract:": "ایک مقامی رجسٹری ایکسٹریکٹ:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "ملک کے ضوابط کے مطابق ضروری ہے، جو انٹٹی کے لئے موجودہ 'شناختی کارڈ' کے طور پر خدمت کرتا ہے، جس میں اس کی حیثیت ظاہر ہے (فعال یا بند ہے)۔",
    "If a registry extract exists, it must not be older than 3 months.": "اگر ایک رجسٹری ایکسٹریکٹ موجود ہوتی ہے، تو یہ 3 مہینے سے زیادہ پرانی نہ ہونی چاہئے۔",
    "Entity name": "انٹٹی کا نام",
    "Registration number (if applicable, based on the country's requirements)": "رجسٹریشن نمبر (اگر لاگو ہو، ملک کی ضروریات کے مطابق)",
    "Date of creation": "تخلیق کی تاریخ",
    "Description of the entity's activities or purpose": "انٹٹی کی انشطاک یا مقصد کی تفصیل",
    "Trading names": "تجارتی نام",
    "Articles of Association": "میثاقیہ",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "الیگز آف ایسوسی ایشن انٹٹی کے دستوری ڈاکیومنٹ کے طور پر خدمت کرتے ہیں، جو اس کے عملی قوانین کو آؤٹ لائن کرتے ہیں۔",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "اس دستاویز پر وقت کی محدودیت نہیں ہے، یعنی تازہ ترین ورژن درخواست کی جاتی ہے۔ عام طور پر، ان اقسام کی انٹٹیز کو کم سے کم ایک سالانہ میٹنگ کی ضرورت ہوتی ہے جو ممکن ہے کہ آؤٹ لائن کے آؤٹ لائن کرنے میں موثر ہو۔ آؤٹ لائن کرنے میں موثر ہو۔",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "یہاں تبدیلیوں کے مثالیں شامل ہیں جو ہوسکتی ہیں مثل انٹٹی کے نام، پتہ، مقصد، مینجمنٹ بورڈ، کردار، ذمہ داریاں، وغیرہ کو اپ ڈیٹ کرنا۔",
    "The document should contain information about:": "اس دستاویز میں معلومات ہونی چاہئے:",
    "The entity itself, including its legal name, registered address, and purpose": "خود انٹٹی، جس میں اس کا قانونی نام، رجسٹرڈ پتہ، اور مقصد شامل ہے",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "اس کے اندرونی عملی قوانین، جیسے کہ مینجمنٹ بورڈ ممبرز کی تعیناتی پروسیس، ان کے کردار اور اختیارات، اور دیگر تفصیلات شامل ہیں۔ یہ فہرست مکمل نہیں ہے۔",
    "How the entity is funded": "کس طرح انٹٹی کو فنڈ کیا گیا ہے",
    "Appointment/Removal/Changes on Board of Management Members:": "مینجمنٹ بورڈ ممبرز کی تعیناتی/نکالنا/تبدیلیاں پر:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "عام طور پر یہ تازہ ترین اپ ڈیٹس انٹٹی کی ضروریات یا الیگز آف ایسوسی ایشن میں ہوتی ہیں۔ تبدیلیاں مینجمنٹ بورڈ کی میٹنگ کی منٹس کے ذریعے دستاویز ہوتی ہیں، جو کہ رجسٹرڈ اور تصدیق کی گئی ہیں تاکہ ان ممبرز کی تازہ ترین لسٹ، جیسے کہ صدر، وائس صدر، خزانہ دار، اور جنرل سیکرٹری، کو ظاہر کریں۔",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "آخری بورڈ کے انتظاماتی اجلاس کے منٹس، مشاہدے کرنے والے اراکین کی فہرست کے ساتھ، آرٹیکلز آف ایسوسی ایشن، اگر دستیاب ہوں، کے علاوہ ضروری ہوں گے۔",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "نوٹ: اگر بورڈ کے انتظاماتی اجلاس کے ممبرز کی فہرست آخری آرٹیکلز آف ایسوسی ایشن میں شامل نہیں ہے اور کوئی بورڈ کی میٹنگ کے منٹس دستیاب نہیں ہیں، تو یہ فہرست فراہم کی جانی چاہئے۔ اسے تاریخ دینا چاہئے اور جس کو حال ہی میں تعین کیا گیا ہے، اس کی تصدیق متعلقہ دستاویزات کے ذریعے کی جا سکتی ہے۔",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "یہ دستاویز کسی کی صلاحیت کو ظاہر کرتی ہے کہ وہ اینٹٹی کو نمائندگی کر سکتا ہے۔ یہ دستاویز یہ ہو سکتی ہے:",
    "The latest Articles of Association": "آخری آرٹیکلز آف ایسوسی ایشن",
    "The current list of board management members (which may be included in the board management meeting minutes)": "بورڈ کے انتظاماتی اجلاس کے منٹس میں شامل ہونے والے موجودہ بورڈ کے انتظاماتی اجلاس کے ممبرز کی فہرست",
    "The document should specify:": "اس دستاویز میں درج کیا جانا چاہئے:",
    "Full name of the individual(s)": "فرد یا فردوں کا مکمل نام",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "فرد یا فردوں کا کردار۔ یہ معلومات آرٹیکلز آف ایسوسی ایشن کے ساتھ دوبارہ تصدیق ہوگی تاکہ یہ یہ سنجیدہ ہو سکے کہ فرد کا کردار اینٹٹی کو باندھنے کی اجازت دیتا ہے۔ \nیہ خصوصی طور پر اہم ہے جب درخواست دہندہ صدر نہ ہو، اور دوسرے کردار میں آرٹیکلز آف ایسوسی ایشن میں ذکر ہونے والی نمائندگی کی اختیارات محدود ہوں۔",
    "Additional information about the legal representative that will be collected includes:": "قانونی نمائندہ کے بارے میں مزید معلومات جو جمع ہو گیں، شامل ہیں:",
    "Date and place of birth": "پیدائش کی تاریخ اور جگہ",
    "Residential address (at least the country of residence should be stated)": "رہائشی پتہ (کم سے کم رہائش کے ملک کا ذکر کرنا ضروری ہے)",
    "Nationality": "قومیت",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "ملکیتی ڈھانچہ اور انتہائی فائدہ مند مالکان (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "عام طور پر، ان اقسام کی اینٹیٹیز، اپنی ڈھانچہ اور مقصد کی بنا پر، فائدہ مند مالکان نہیں ہوتیں۔ ہاں، بورڈ کے ممبرز کو صارف کے طور پر شناخت کیا جائے گا اور اس کیلئے فلٹر کیا جائے گا، آئی ڈی جمع اور تصدیق وغیرہ۔",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "چند تعداد کی ایسوسی ایشنز ممکن ہے کہ فائدہ مند مالکان ہوں، خصوصاً جو منافع پیدا کر رہی ہوں۔ اس طرح کے مواقع میں، یہ تفصیل آرٹیکلز آف ایسوسی ایشن میں ذکر کی جائے گی۔ یہ افراد آسانی سے شناخت کیے جائیں گے اور ان کو بورڈ کے انتظاماتی اجلاس کے ممبرز کے طور پر اس طرح کے لئے علاج کیا جائے گا۔",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "اضافی ضرورت: وکالت یا اختیارات کی تفویض میں شامل معاملات میں، دونوں طرفین (اختیار دینے والا فرد اور اختیار حاصل کرنے والا) کو اپنے شناختی دستاویز کا ایک کاپی فراہم کرنا چاہئے۔",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "سول ٹریڈر کا رجسٹری نمائش (اینٹٹی کا رجسٹریشن / تخلیقی دستاویز)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "یہ دستاویز سول ٹریڈرز کے لئے ایک مقامی رجسٹری کے طور پر خدمت کرتا ہے، جو ملکوں میں لازمی ہے جہاں ایسی رجسٹریشن ہوتی ہے۔ یہ یہ تصدیق کرتا ہے کہ فرد ایک سول ٹریڈر کے طور پر پیشہ ورانہ میں مصروف ہے۔ یہ دستاویز کا نام ملک سے ملک مختلف ہوتا ہے۔",
    "Where applicable, the document should not be older than 3 months.": "جہاں لاگو ہوتا ہے، دستاویز تین مہینے سے زیادہ پرانا نہیں ہونا چاہئے۔",
    "The document must contain:": "اس دستاویز میں ہونا چاہئے:",
    "Individual's name": "فرد کا نام",
    "Residential address": "رہائشی پتہ",
    "Registration number": "رجسٹریشن نمبر",
    "The sole trader is the only individual subject to the identification document collection process.": "سول ٹریڈر وہ واحد فرد ہے جو شناختی دستاویز جمع کرنے کے عمل کا مضمون ہے۔",
    "No Transactions": "کوئی لین دین نہیں",
    "No Pending Items": "کوئی معلق اشیاء نہیں",
    "No Beneficiaries": "کوئی فائدہ مند افراد نہیں",
    "Send Money To": "پیسے بھیجیں",
    "Confirmation": "تصدیق",
    "You are setting": "آپ ترتیب دے رہے ہیں",
    "as default.": "کو دیفالٹ بنانا۔",
    "Previously": "پہلے",
    "was default QR.": "دیفالٹ QR تھا۔",
    "Category": "زمرہ",
    "-Select-From-The-List-": "-لسٹ سے منتخب کریں-",
    "Add a note": "نوٹ شامل کریں",
    "Categories Added Successfully!": "زمرے کامیابی سے شامل ہو گئے ہیں!",
    "Subscription starting date": "سبسکرپشن شروع ہونے کی تاریخ",
    "Until": "تک",
    "Next Transaction": "اگلا لین دین",
    "Transaction Method": "لین دین کا طریقہ",
    "Accept the quotation": "قیمت کو قبول کریں",
    "Choose Currency": "کرنسی منتخب کریں",
    "Accept": "قبول کریں",
    "Bargain the quotation": "قیمت میں مکمل کریں",
    "Enter Counter Amount:": "کاؤنٹر مقدار داخل کریں:",
    "Bargain": "مکمل کریں",
    "Decline the quotation": "قیمت کو منسوخ کریں",
    "Are you sure, you want to decline this quotation?": "کیا آپ یقینی ہیں کہ آپ یہ قیمت کو رد کرنا چاہتے ہیں؟",
    "Decline": "رد کریں",
    "Personal Account": "ذاتی اکاؤنٹ",
    "Schedules": "نظام الاوقات",
    "Jan": "جنوری",
    "Feb": "فروری",
    "Mar": "مارچ",
    "Apr": "اپریل",
    "May": "مئی",
    "Jun": "جون",
    "Jul": "جولائی",
    "Aug": "اگست",
    "Sep": "ستمبر",
    "Oct": "اکتوبر",
    "Nov": "نومبر",
    "Dec": "دسمبر",
    "Today": "آج",
    "Spent In": "میں خرچ ہوا",
    "Revised Amount": "دوبارہ جمع",
    "You spent more comparing to last month": "آپ نے پچھلے مہینے کی موازنہ میں زیادہ خرچ کیا",
    "Last": "پچھلا",
    "days": "دن",
    "Year": "سال",
    "Instant Mobile Top-up Across 150+ Countries": "150 سے زائد ممالک میں فوری موبائل ٹاپ اپ",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "اپنے پیاروں کو خوش کریں یا اپنا فون ریچارج کریں، آپ جہاں بھی ہوں! InstaPay کی موبائل ایئر ٹائم سروس آپ کو 150 سے زائد ممالک میں فوری طور پر موبائل فونز کو ٹاپ اپ کرنے کی سہولت دیتی ہے۔ تحائف کے لیے بہترین یا یقینی بنانے کے لیے کہ آپ ہمیشہ جڑے رہیں، ہماری سروس آسانی، رفتار، اور حفاظت کے لئے ڈیزائن کی گئی ہے۔",
    "How It Works - Step-by-Step Guide:": "یہ کیسے کام کرتا ہے - مرحلہ وار گائیڈ:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "موبائل ٹاپ اپ' منتخب کریں: WhatsApp، Twitter، Telegram، یا Instagram جیسے پلیٹ فارمز پر InstaPay ایپ کے اندر براہ راست اس فیچر تک رسائی حاصل کریں۔",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "کرنسی اور رقم منتخب کریں: مختلف کرنسیوں میں سے انتخاب کریں (USD، GBP، EUR، وغیرہ) اور مقرر کریں کہ آپ کتنا ٹاپ اپ کرنا چاہتے ہیں۔",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "موبائل نمبر درج کریں: وہ نمبر ٹائپ کریں جسے آپ ریچارج کرنا چاہتے ہیں—آپ کا یا آپ کے پیارے کا۔ ہمارا نظام ایک محفوظ اور تیز رفتار عمل کو یقینی بناتا ہے۔",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "تصدیق کریں اور ادائیگی کریں: ٹاپ اپ کی تفصیلات کی دوبارہ جانچ پڑتال کریں، تصدیق کریں اور اپنی پسندیدہ طریقہ سے ادائیگی کریں۔ لین دین فوری طور پر اور محفوظ طریقے سے عمل میں لایا جاتا ہے۔",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "فوری تصدیق حاصل کریں: آپ اور وصول کنندہ (اگر لاگو ہو) ٹاپ اپ کامیاب ہونے پر تصدیقی نوٹیفکیشن وصول کریں گے۔",
    "Benefits:": "فوائد:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "عالمی پہنچ: 150 سے زائد ممالک میں بغیر کسی دشواری کے ایئر ٹائم بھیجیں۔",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "فوری اثر: فوائد کو فوراً دیکھیں—آخری لمحے کے تحائف یا فوری مواصلات کے لئے بہترین۔",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "مکمل حفاظت: ہمارے محفوظ ادائیگی نظام کے ساتھ سکون کی زندگی گزاریں۔",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "وسیع ادائیگی کے اختیارات: ادائیگیوں کے لئے کریڈٹ کارڈز، پے پال، یا آپ کے InstaPay والیٹ میں سے انتخاب کریں۔",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "خوشی پھیلانے یا جڑے رہنے کے لئے تیار ہیں؟ ابھی ٹاپ اپ کریں اور کسی کا دن بنائیں—یا اپنا خود کا!",
    "Pick a language": "زبان منتخب کریں",
    "French": "فرانسیسی",
    "Spanish": "ہسپانوی",
    "Portuguese": "پرتگالی",
    "German": "جرمن",
    "Ukrainian": "یوکرینی",
    "Italian": "اطالوی",
    "Russian": "روسی",
    "Arabic": "عربی",
    "Polish": "پولش",
    "Dutch (Nederlands)": "ڈچ",
    "Yoruba": "یوروبا",
    "Indonesian": "انڈونیشیائی",
    "Turkish": "ترکی",
    "Swahili": "سواحلی",
    "Hausa": "ہاؤسا",
    "Hindi": "ہندی",
    "Urdu": "اردو",
    "Chinese": "چینی",
    "Select Your Preferred Language": "اپنی پسندیدہ زبان منتخب کریں\n",
    "Please select the language you would \nlike to use for the eKYC process.": "براہ کرم eKYC عمل کے لیے استعمال کرنے کے لیے زبان کا انتخاب کریں۔\n\n",
    "Select Your Nationality": "اپنی قومیت منتخب کریں\n",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "اپنے ملک کے لیے معاون دستاویزات دیکھنے کے لیے اپنی قومیت منتخب کریں۔\n\n",
    "Grant permission to use the camera.": "کیمرہ استعمال کرنے کی اجازت دیں۔\n",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "دستاویز کو قبضہ کرنے کے لیے کیمرے تک رسائی کی اجازت دینے کے لیے جب پوچھا جائے تو \"اجازت دیں\" پر ٹیپ یا کلک کریں۔\n\n",
    "Upload Your Documents from \nGallery/Photos": "گیلری/تصاویر سے اپنے دستاویزات اپ لوڈ کریں\n",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "اگر آپ کو اپنے کیمرے کے ساتھ مسائل درپیش ہیں یا اگر آپ کے سسٹم میں کوئی کیمرہ نہیں ہے، تو آپ اپنے مقامی ذخیرے سے دستاویزات کی تصاویر دستی طور پر اپ لوڈ کر سکتے ہیں۔\n\n",
    "Upload Your ID.": "اپنا شناختی کارڈ اپلوڈ کریں۔",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "یقینی بنائیں کہ آپ کا شناختی کارڈ صاف اور غیر دھندلا ہے تاکہ تیزی سے تصدیق کی جا سکے۔",
    "Valid Proof of Address": "درست پتے کا ثبوت",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. یقینی بنائیں کہ آپ کا پتے کا ثبوت موجودہ ہے اور آپ کے eKYC تصدیق کے شروع ہونے کی تاریخ سے پچھلے تین مہینوں کے اندر ہے۔\n\n2. یقینی بنائیں کہ آپ کا پتے کا ثبوت صاف اور غیر دھندلا ہے تاکہ تیزی سے تصدیق کی جا سکے۔\n",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "اہم اطلاع\n\nبراہ کرم اپنی eKYC تصدیق شروع کرنے سے پہلے اس گائیڈ کے تمام مراحل کو غور سے پڑھیں۔\n\nعام غلطیوں سے بچیں:\n\nیقینی بنائیں کہ آپ کے تمام دستاویزات واضح، قابل پڑھائی اور موجودہ ہیں۔\nاس بات کو یقینی بنائیں کہ فراہم کردہ معلومات آپ کے سرکاری دستاویزات میں دی گئی معلومات سے مطابقت رکھتی ہو۔\nتاخیر یا انکار سے بچنے کے لیے ہدایات کو غور سے پڑھیں۔\n\nنوٹ:\nاگر آپ کی eKYC تصدیق ناکام ہو جاتی ہے اور آپ کو اسے دوبارہ جمع کروانا پڑتا ہے، تو دوبارہ جمع کروانے کے لیے اضافی فیس وصول کی جائے گی۔\n\nان ہدایات پر عمل کرتے ہوئے، آپ ایک ہموار اور کامیاب eKYC تصدیق کو یقینی بنا سکتے ہیں۔\n\nکسی بھی سوالات یا مدد کے لیے، براہ کرم ہماری سپورٹ ٹیم سے support@insta-pay.ch پر رابطہ کریں۔",
    "Personal Transactions": "ذاتی لین دین",
    "Business Transactions": "کاروباری لین دین",
    "Shopping & Purchases": "خریداری اور خریداری",
    "Bills & Utilities": "بلز اور خدمات",
    "Entertainment & Leisure": "تفریح اور آرام",
    "Investments & Savings": "سرمایہ کاری اور بچت",
    "Health & Wellness": "صحت اور تندرستی",
    "Transportation": "قل و حمل",
    "Education": "تعلیم",
    "Miscellaneous": "متفرق",
    "Family Support": "خاندانی معاونت",
    "WALLET": "WALLET",
    "Add a currency": "ایک کرنسی شامل کریں",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "جب آپ ایک کرنسی شامل کرتے ہیں، تو اس کرنسی میں آپ کو موصول ہونے والی ادائیگیوں کو آپ کے InstaPay بیلنس میں کریڈٹ کیا جائے گا۔ جب تک کہ دوسری صورت میں مخصوص نہ ہو، ادائیگی بھیجنے یا درخواست کرنے کے لیے آپ کی بنیادی کرنسی استعمال کی جائے گی۔\r\n",


    "balance amount ": "بیلنس رقم",
    "referral ": "حوالہ",
    "crypto wallet balance": "کریپٹو والٹ بیلنس",
    "money in": "پیسہ اندر",
    "money out": "پیسہ باہر",
    "analytics": "تجزیہ",
    "see more": "مزید دیکھیں",
    "manage my Wallets and see the transaction details": "بطور ڈیفالٹ Wallet سیٹ کریں",
    "set as default Wallet": "بطور ڈیفالٹ Wallet سیٹ کریں",
    "add currency": "کرنسی شامل کریں",
    "convert funds ": "فنڈز تبدیل کریں",
    "withdraw balance": "بیلنس نکالیں",
    "chart names": "چارٹ نام",
    "withdraw balance from PKR wallet": "PKR والے بٹوے سے بیلنس نکالیں",
    "select a payout channel": "ایک ادائیگی چینل کا انتخاب کریں",
    "you have entered an amount below the minimum payment range": "آپ نے کم از کم ادائیگی کی حد سے نیچے کی رقم داخل کی ہے",
    "change": "تبدیل کریں",
    "edit": "ترمیم کریں",
    "moving to next step in ...": "اگلے مرحلے میں جا رہے ہیں ...",
    "you are withdrawing ...": "آپ نکال رہے ہیں ...",
    "where is my payment": "میری ادائیگی کہاں ہے",
    "download": "ڈاؤن لوڈ کریں",
    "IBAN / SWIFT Code": "IBAN یا SWIFT/BIC کوڈ",
    "swift code": "SWIFT کوڈ",
    "account number": "اکاؤنٹ نمبر",
    "branch street": "برانچ کی گلی",
    "city": "شہر",
    "province": "صوبہ",
    "postal code": "پوسٹل کوڈ",
    "all payout channels": "تمام ادائیگی چینلز",
    "bussiness beneficiary": "کاروباری مستفید",
    "no quotation": "کوئی کوٹیشن نہیں",
    "no pending items": "کوئی زیر التواء آئٹمز نہیں",
    "QR status": "کیو آر کی حیثیت",
    "Portfolio": "پورٹ فولیو",
    "edit whole portfolio page": "پورے پورٹ فولیو صفحے میں ترمیم کریں",
    "personal account": "ذاتی اکاؤنٹ",
    "Chart names": "چارٹ کے نام",
    "instagram": "انسٹاگرام",
    "facebook": "فیس بک",
    "twitter": "ٹویٹر",
    "more": "مزید",
    "not connected": "منسلک نہیں ہے",
    "code": "کوڈ",
    "ratings": "درجہ بندیاں",
    "current password": "موجودہ پاس ورڈ",
    "include at least one uppercase and lowercase": "کم از کم ایک بڑے اور چھوٹے حروف شامل کریں",
    "my social network accounts": "میرے سوشل نیٹ ورک اکاؤنٹس",
    "SMS ": "ایس ایم ایس",
    "view identity verfication guide": "شناخت کی تصدیق کے گائیڈ کو دیکھیں",
    "boimetric face recognition": "بایومیٹرک چہرے کی شناخت",
    "please select a payer (withdrawl options)": "براہ کرم ایک ادائیگی کنندہ منتخب کریں (واپسی کے اختیارات)",
    "IBAN number ": "آئی بی اے این نمبر",
    "account holder name": "اکاؤنٹ ہولڈر کا نام",
    "download data": "ڈیٹا ڈاؤن لوڈ کریں",
    "bank transfer": "بینک ٹرانسفر",
    "mobile wallet": "موبائل والیٹ",
    "all bank names": "تمام بینک کے نام",
    "pay via card": "کارڈ کے ذریعے ادائیگی کریں",
    "you have entered an amount that is below the payer range": "آپ نے جو رقم درج کی ہے وہ ادائیگی کرنے والے کی حد سے کم ہے",
    "trouble sending money": "پیسہ بھیجنے میں دشواری",
    "please follow these example": "براہ کرم ان مثالوں پر عمل کریں",
    "wallet ID ": "پرس کی شناخت",
    "username": "صارف کا نام",
    "email": "ای میل",
    "phone number": "فون نمبر",
    "no user found": "کوئی صارف نہیں ملا",
    "search": "تلاش کریں",
    "scan/upload QR code of the recipient": "وصول کنندہ کا کیو آر کوڈ اسکین/اپلوڈ کریں",
    "invite them": "انہیں مدعو کریں",
    "trouble quoting money": "پیسے کا اقتباس دینے میں مسئلہ",
    "select a country": "ملک منتخب کریں",
    "airtime details": "ایئرٹائم کی تفصیلات",
    "enter the phone number": "فون نمبر درج کریں",
    "service type": "خدمت کی قسم",
    "select a service": "ایک خدمت منتخب کریں",
    "service list": "خدمت کی فہرست",
    "amount entered is less than the ....": "درج کردہ رقم .... سے کم ہے",
    "total": "کل",
    "total credit the receiver gets": "وصول کنندہ کو مجموعی طور پر ملتا ہے",
    "take ": "لینا",
    "OTP has been verified , You'll be redirected......": "OTP کی تصدیق ہوگئی ہے، آپ کو ری ڈائریکٹ کر دیا جائے گا......",
    "Link Your Instagram Account to InstaPay": "اپنے Instagram اکاؤنٹ کو InstaPay سے لنک کریں",
    "Step 01 – From Instagram App": "مرحلہ 01 – Instagram ایپ سے",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Instagram ایپ کھولیں، \"InstaPay\" پروفائل صفحہ تلاش کریں، چیٹ باکس کھولنے کے لیے \"پیغام\" منتخب کریں، \"ہیلو\" لکھیں/کہیں اور InstaPay چیٹ بوٹ کے ساتھ بات چیت شروع کرنے کے لیے بھیجیں پر کلک کریں۔",
    "Step 02 – From InstaPay Instagram Profile Page ": "مرحلہ 02 – InstaPay Instagram پروفائل صفحہ سے",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "InstaPay چیٹ بوٹ دو ایکشن بٹن دکھائے گا: \"رجسٹر کریں\" اور \"کنیکٹ کریں\"۔ \"کنیکٹ کریں\" کو منتخب کریں اور چیٹ بوٹ آپ سے اپنا InstaPay صارف نام درج کرنے کا کہے گا۔",
    "Step 03 – From InstaPay Instagram Profile Page": "مرحلہ 03 – InstaPay Instagram پروفائل صفحہ سے",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "InstaPay چیٹ بوٹ پھر InstaPay تصدیقی کوڈ طلب کرے گا۔ اپنے InstaPay سیٹنگز کے تحت \"میرے سوشل نیٹ ورک اکاؤنٹس\" سے حاصل کردہ کوڈ درج کریں اور بھیجیں پر کلک کریں۔",
    "Step 04 – From InstaPay Instagram Profile Page": "مرحلہ 04 – InstaPay Instagram پروفائل صفحہ سے",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "آپ کو مبارکباد کا پیغام موصول ہوگا جس میں تصدیق ہوگی کہ آپ کا Instagram اکاؤنٹ اب InstaPay کے ساتھ ہم آہنگ ہو گیا ہے۔ InstaPay خدمات کا استعمال شروع کرنے کے لیے \"مین مینو\" پر کلک کریں۔",
    "Additional Tips": "اضافی نکات",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. اپنے اکاؤنٹ کو محفوظ بنائیں: اپنے منسلک InstaPay خدمات کی حفاظت کے لیے دو عنصر کی توثیق (2FA) کو فعال کر کے یہ یقینی بنائیں کہ آپ کا Instagram اکاؤنٹ محفوظ ہے۔",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. مزید دریافت کریں: ایک بار منسلک ہونے کے بعد، InstaPay کی پیش کردہ وسیع رینج کی خدمات کا جائزہ لیں، بغیر کسی رکاوٹ کے لین دین سے لے کر فوری نکالنے تک۔",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. اپ ڈیٹ رہیں: ہمارے اپ ڈیٹس پر نظر رکھیں، کیونکہ ہم جلد ہی آپ کے تجربے کو مزید مربوط بنانے کے لیے دوسرے سوشل نیٹ ورک اکاؤنٹس کو لنک کرنے کی حمایت کریں گے۔",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. مدد اور معاونت: اگر آپ کو لنک کرنے کے عمل کے دوران کوئی مسئلہ درپیش ہے تو مدد کے لیے ہماری سپورٹ ٹیم سے رابطہ کریں۔",
    "Future Enhancements": "مستقبل میں بہتری",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "قریب مستقبل میں، InstaPay آپ کو مزید سوشل نیٹ ورک اکاؤنٹس کو لنک کرنے کی اجازت دے گا، جس سے آپ کو مزید لچک اور سہولت فراہم ہوگی۔ دلچسپ اپ ڈیٹس کے لیے ہمارے ساتھ رہیں!",
    "Personal Transactions": "ذاتی لین دین",
    "Business Transactions": "کاروباری لین دین",
    "Shopping & Purchases": "خریداری اور خریداری",
    "Bills & Utilities": "بلز اور خدمات",
    "Entertainment & Leisure": "تفریح اور آرام",
    "Investments & Savings": "سرمایہ کاری اور بچت",
    "Health & Wellness": "صحت اور تندرستی",
    "Transportation": "قل و حمل",
    "Education": "تعلیم",
    "Miscellaneous": "متفرق",
    "Family Support": "خاندانی معاونت",
    "Login With Phone Number": null,
    "Start Your InstaPay Journey Today!": "آج ہی اپنی InstaPay سفر شروع کریں!",
    "Enter your phone number": "اپنا فون نمبر درج کریں",
    "Login with email": "ای میل کے ساتھ لاگ ان کریں",
    "Don't have an account?": "اکاؤنٹ نہیں ہے؟",
    "Sign Up": "سائن اپ کریں",
    "Continue": "جاری رکھیں",
    "We recommend you download and install the Google Authenticator app": "ہم آپ کو Google Authenticator ایپ ڈاؤن لوڈ اور انسٹال کرنے کی تجویز کرتے ہیں",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "دو عنصر کی تصدیق (2FA) آن لائن خود کو محفوظ رکھنے کا بہترین طریقہ ہے۔",
    "Strong security with Google Authenticator": "Google Authenticator کے ساتھ مضبوط سیکیورٹی",
    "Enter your password": "اپنا پاس ورڈ درج کریں",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "ہم آپ کو یہ یقینی بنانے کے لیے Google Authenticator ایپ ڈاؤن لوڈ اور انسٹال کرنے کی تجویز کرتے ہیں کہ آپ کو اپنے تصدیقی کوڈز فوری طور پر مل جائیں، کسی بھی SMS تاخیر یا مسئلے سے بچیں۔",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "دو عنصر کی تصدیقی کوڈ",
    "Problem with the token?": "ٹوکن میں مسئلہ؟",
    "Verify using SMS": "تصدیق کے لیے SMS استعمال کریں",
    "Verify": "تصدیق کریں",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "SMS تصدیق",
    "Enter the SMS code sent to +41********4053": "+41********4053 پر بھیجا گیا SMS کوڈ درج کریں",
    "Verify Using Authenticator App": "تصدیق کے لیے Authenticator ایپ استعمال کریں",
    "Resend code in 120 seconds": "120 سیکنڈ میں کوڈ دوبارہ بھیجیں",
    "Verify ": "تصدیق کریں",
    "SMS - Web Mobile": null,
    "My own account": "میرا اپنا کھاتہ",
    "Friends & Family": "دوست اور خاندان",
    "Aunt": "پھوپھی / خالہ",
    "Brother-in-law": "بہنوئی",
    "Cousin": "کزن",
    "Daughter": "بیٹی",
    "Father": "والد",
    "Father-in-law": "سسر",
    "Friend": "دوست",
    "Grandfather": "دادا",
    "Grandmother": "دادی",
    "Husband": "شوہر",
    "Mother": "والدہ",
    "Mother-in-law": "ساس",
    "Nephew": "بھتیجا",
    "Niece": "بھتیجی",
    "Self (i.e. the sender, himself)": "خود (یعنی بھیجنے والا)",
    "Sister": "بہن",
    "Sister-in-law": "نند",
    "Son": "بیٹا",
    "Uncle": "چچا / ماموں",
    "Wife": "بیوی",
    "Others not listed": "دیگر جو درج نہیں",
    "Merchant": "تاجر",
    "No relationship": "کوئی رشتہ نہیں",
    "Add Funds": "فنڈز شامل کریں",
    "Enter the Amount": "رقم درج کریں:",
    "Fees": "فیس:",
    "You Will Get": "آپ کو ملے گا:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ لین دین کی قیمت [Currency ISO code & Amount] - [Currency ISO code & Amount] کے درمیان ہونی چاہیے",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ غیر موزوں رقم: آپ کی موجودہ شناخت کی تصدیق کی سطح اس زیادہ سے زیادہ رقم کو محدود کرتی ہے جو آپ شامل کر سکتے ہیں۔ اعلی حدود سے فائدہ اٹھانے کے لیے براہ کرم اپنی شناخت کی تصدیق کو اپ گریڈ کریں۔",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ کرنسی کی قیمت اکثر مارکیٹ کے حالات کی بنیاد پر تبدیل ہوتی رہتی ہے۔ براہ کرم اپنے حتمی کوٹیشن کے طور پر تصدیق کے آرڈر کے صفحے پر قیمت دیکھیں۔",
    "Next": "اگلا",
    "Upgrade Identity Verification": "شناخت کی تصدیق کو اپ گریڈ کریں",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "کسی سے بھی، کہیں سے بھی پیسے کی درخواست کریں، بشمول سوشل نیٹ ورکنگ پلیٹ فارمز۔ InstaPay کے ساتھ، آپ کی ادائیگی کی درخواستیں منظور ہونے پر فوری طور پر دستیاب ہو جاتی ہیں اور فنڈز آپ کے وصولی اکاؤنٹس میں بھیج دیے جاتے ہیں۔ اپنے لین دین کو آسان بنائیں اور ادائیگیوں کا آسانی اور حفاظت کے ساتھ انتظام کریں۔",
    "You’ve been logged out.": "آپ کو لاگ آؤٹ کر دیا گیا ہے۔",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "آپ کی سیکیورٹی کے لیے، 15 منٹ کی غیرفعالیت کے بعد آپ کو لاگ آؤٹ کر دیا گیا ہے۔ براہ کرم InstaPay استعمال کرنے کے لیے دوبارہ لاگ ان کریں۔",
    "LOGIN AGAIN": "دوبارہ لاگ ان کریں",
    "Brother": "بھائی",
    "Current Password": "موجودہ پاس ورڈ",
    "The new password cannot be the same as the old one": "نیا پاس ورڈ پرانے جیسا نہیں ہو سکتا۔",
    "Minimum password length: [X] characters ": "کم از کم پاس ورڈ کی لمبائی: [X] حروف",
    "Include at least one uppercase and one lowercase letter.": "کم از کم ایک بڑے اور ایک چھوٹے حرف کا اندراج کریں۔",
    "Nominee Information": "نامزد کی معلومات",
    "Verification Code Preferences": "تصدیقی کوڈ کی ترجیحات",
    "How Others Can Find You": "دوسرے آپ کو کیسے تلاش کر سکتے ہیں",
    "My Social Network Accounts": "میرے سوشل نیٹ ورک اکاؤنٹس",
    "Login Activity": "لاگ ان سرگرمی",
    "Receiving Accounts": "وصولی کے اکاؤنٹس",
    "Change Your Password To Continue": "جاری رکھنے کے لیے اپنا پاس ورڈ تبدیل کریں",
    "Continue with": "کے ساتھ جاری رکھیں",
    "Back to Login": "واپس لاگ ان کریں",
    "Send OTP": "OTP بھیجیں",
    "A Verification code has been sent to your email and number.": "تصدیقی کوڈ آپ کے ای میل اور نمبر پر بھیج دیا گیا ہے۔",
    "Resend available in": "دوبارہ بھیجنے کے لیے دستیاب",
    "Create a strong Password": "ایک مضبوط پاس ورڈ بنائیں",
    "Password must:": "پاس ورڈ ہونا چاہیے:",
    "Be between 9 and 64 characters": "9 سے 64 حروف کے درمیان ہو",
    "Include at least two of the following:": "کم از کم درج ذیل میں سے دو شامل ہوں:",
    "Lowercase Character": "چھوٹا حرف",
    "Special Character": "خصوصی حرف",
    "Password Updated Successfully!": "پاس ورڈ کامیابی کے ساتھ اپ ڈیٹ ہو گیا!",
    "Click below to login": "لاگ ان کرنے کے لیے نیچے کلک کریں",

    "Send Money": "رقم بھیجیں",
    "Enter the amount": null,
    "Recent Transfer": "حالیہ منتقلی",
    "See all": "سب دیکھیں",
    "Send again": "دوبارہ بھیجیں",
    "You Send": "آپ بھیجتے ہیں",
    "They Receive": "وہ وصول کرتے ہیں",
    "Receive Method": "وصول کرنے کا طریقہ",
    "Select method": "طریقہ منتخب کریں",
    "Next": "اگلا",
    "Trouble sending money?": "رقم بھیجنے میں مشکل؟",
    "Where do you want to send money ?": "آپ رقم کہاں بھیجنا چاہتے ہیں؟",
    "Search ": "تلاش کریں",
    "Confirm": "تصدیق کریں",
    "Bank Deposit": "بینک ڈپازٹ",
    "Cash Pick-up": "نقدی وصولی",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "بینک کارڈ",
    "Crypto Wallet": "کرپٹو Wallet",
    "What is the receiver’s Mobile Wallet?": "وصول کنندہ کا موبائل Wallet کیا ہے؟",
    "Transfer fees": "منتقلی کی فیس",
    "Total to pay": "کل ادا کرنا",
    "Choose the beneficiary!": "وصول کنندہ کا انتخاب کریں!",
    "Country": "ملک",
    "Receive method": "وصول کرنے کا طریقہ",
    "You send": "آپ بھیجتے ہیں",
    "change": "تبدیل کریں",
    "edit": "نیا شامل کریں",
    "add new": "وصول کنندہ",
    "Beneficiaries": "وصول کنندگان کا انتظام کریں",
    "Manage Beneficiaries": "تلاش کریں",
    "Search": "اگلا",
    "CASH PICK-UP": "نقد رقم اٹھانا",
    "Where will your receiver collect the money": "آپ کا وصول کنندہ کہاں رقم اکٹھا کرے گا",
    "Cash is available to collect within minutes from any branch of [name] ": "رقم کسی بھی [نام] شاخ سے منٹوں میں وصول کی جا سکتی ہے",
    "BANK ACCOUNT": "بینک اکاؤنٹ",
    "Transfer to recipient’s bank account": "وصول کنندہ کے بینک اکاؤنٹ میں منتقل کریں",
    "BANK CARD": "بینک کارڈ",
    "Fast transfer directly to recipient’s bank card": "وصول کنندہ کے بینک کارڈ میں براہ راست فوری منتقلی",
    "CRYPTO WALLET": "کرپٹو Wallet",
    "Fast transfer directly to a crypto wallet": "کرپٹو Wallet میں براہ راست فوری منتقلی",
    "Review transfer": "منتقلی کا جائزہ لیں",
    "Receiver's Details": "وصول کنندہ کی تفصیلات",
    "Edit": "ترمیم کریں",
    "Name": "نام",
    "Reason for sending": "بھیجنے کی وجہ",
    "Sending": "بھیجنا",
    "Receiver gets": "وصول کنندہ وصول کرتا ہے",
    "Exchange rate": "زر مبادلہ کی شرح",
    "Estimated time": "متوقع وقت",
    "Select Payment Type": "ادائیگی کی قسم منتخب کریں",
    "Credit cards": "کریڈٹ کارڈز",
    "Domestic cards": "مقامی کارڈز",
    "Mobile Wallets": "موبائل Wallet",
    "InstaPay Wallet": "InstaPay Wallet",
    "Crypto (USDT, ETH, BTC)": "کرپٹو (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "کریڈٹ کارڈ",
    "Credit Card": "کریڈٹ کارڈ",
    "Select card": "کارڈ منتخب کریں",
    "Card Number": "کارڈ نمبر",
    "Enter the 16-digit card number on the card": "کارڈ پر 16 ہندسوں کا کارڈ نمبر درج کریں",
    "Expiry Date": "خاتمے کی تاریخ",
    "Enter the expiration date of the card": "کارڈ کی خاتمے کی تاریخ درج کریں",
    "CVV Number": "CVV نمبر",
    "Enter the 3 or 4 digit number on the card": "کارڈ پر 3 یا 4 ہندسوں کا نمبر درج کریں",
    "INSTAPAY WALLET": "INSTAPAY Wallet",
    "Select Wallet": "Wallet منتخب کریں",
    "My Wallet": "میرا Wallet",
    "Conversion": "تبدیلی",
    "Convert": "تبدیل کریں",
    "Your payment is confirmed": "آپ کی ادائیگی کی تصدیق ہو گئی ہے",
    "Thanks for using InstaPay": "InstaPay استعمال کرنے کا شکریہ",
    "Status": "حالت",
    "Share": "شیئر کریں",
    "Transfer created": "منتقلی بنائی گئی",
    "Payment received": "ادائیگی وصول کی گئی",
    "Payment processed": "ادائیگی کی گئی",
    "Transfer successful": "منتقلی کامیاب",
    "It may take few hours for the funds to appear in [user name] account": "[صارف کا نام] اکاؤنٹ میں رقم ظاہر ہونے میں چند گھنٹے لگ سکتے ہیں",
    "Transfer failed": "منتقلی ناکام",
    "Transaction Details": "لین دین کی تفصیلات",
    "Need help?": "مدد چاہیے؟",
    "Service Details": "سروس کی تفصیلات",
    "Funds Deposit Speed": "فنڈز کی دستیابی",
    "Cut-off Time": "کٹ آف ٹائم",
    "Bank holiday list": "بینک چھٹیوں کی فہرست",
    "Threshold limits": "لین دین کی حدیں",
    "null": null,
    "Instant, Real Time": "فوری، حقیقی وقت میں",
    "Same day, T+1": "اسی دن، T+1",
    "Non-instant, T+1": "فوری نہیں، T+1",
    "Instant Payment, Real Time": "فوری ادائیگی، حقیقی وقت میں",
    "Transaction processed before 3:00 PM Local time": "ٹرانزیکشن کو مقامی وقت کے مطابق 3:00 PM سے پہلے پروسیس کیا گیا",
    "Delivered the same day": "اسی دن پہنچایا گیا",
    "Transactions processed post 3:00 PM": "3:00 PM کے بعد پروسیس کی گئی ٹرانزیکشنز",
    "T+1 business days": "T+1 کاروباری دن",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "\"ہفتے کے دنوں میں 3:00PM سے پہلے کی گئی ادائیگیاں اسی دن کی آدھی رات تک وصول کنندہ کے بینک اکاؤنٹ میں جمع کر دی جائیں گی۔\"",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "ہفتے کے دنوں میں 3:00PM کے بعد کی گئی ادائیگیاں اگلے دن کی آدھی رات تک جمع کر دی جائیں گی۔",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "ہفتہ، اتوار یا عوامی تعطیل پر کی گئی ادائیگیاں اگلے کاروباری دن کی آدھی رات تک اکاؤنٹ میں جمع کر دی جائیں گی۔",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 مقامی وقت",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "فی ٹرانزیکشن کم از کم حد:",
    "Maximum per transaction limit:": "فی ٹرانزیکشن زیادہ سے زیادہ حد:",
    "Daily threshold limit:": "روزانہ کی حد:",
    "Weekly threshold limit:": "ہفتہ وار حد:",
    "Monthly threshold limit:": "ماہانہ حد:",
    "Yearly threshold limit:": "سالانہ حد:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "بنیادی اکاؤنٹ (غیر تصدیق شدہ)",
    "Identity Verified": "شناخت کی تصدیق شدہ",

    "Good Evening!": "شام بخیر!",
    "Total Balance": "کل اکاؤنٹ بیلنس",
    "Quick Transfer": "جلدی فنڈز کی منتقلی",
    "No transactions": "کوئی ٹرانزیکشن نہیں ملی",
    "Blocked balance": "بلاک شدہ بیلنس",
    "Available balance": "دستیاب بیلنس",
    "Insufficient Balance in PKR": "PKR میں ناکافی بیلنس",
    "Chat with us": "ہم سے چیٹ کریں",
    "Add Currency": "کرنسی شامل کریں",
    "Education": "تعلیم",
    "Divers": "مختلف",
    "Family support": "خاندانی مدد",
    "Others": "دیگر اخراجات",
    "Transport": "ٹرانسپورٹ",
    "schedule payment": "طے شدہ ادائیگی",
    "subscription payment": "بار بار کی جانے والی ادائیگی",
    "No payment": "کوئی ادائیگی نہیں ملی",
    "No Beneficiaries": "کوئی مستحق دستیاب نہیں",
    "Code Postal": "پوسٹل کوڈ",
    "Additional Information": "اضافی معلومات",
    "Gender": "جنس",
    "Date Of Birth": "تاریخ پیدائش",
    "Nationality": "قومیت",
    "Occupation": "پیشہ (تمام اختیارات بھی)",
    "ID Type": "آئی ڈی کی قسم (تمام اختیارات بھی)",
    "ID Number": "شناختی نمبر",
    "Select Beneficiary Payment Channels": "مستحق کی ادائیگی کے چینلز منتخب کریں",
    "wallet crypto": "کرپٹو والیٹ",
    "Add another wallet": "ایک اور والیٹ شامل کریں",
    "Enterprise": "کاروبار",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "\"کاروباری مستحقین جلد آ رہے ہیں! آپ دنیا بھر میں کاروباری ٹرانزیکشنز کر سکیں گے، اپنی پہنچ کو سرحدوں سے آگے بڑھائیں۔",
    "Joined At": "شمولیت کی تاریخ",
    "Profile Link": "پروفائل لنک",
    "comission": "کمیشن",
    "my withdrawls": "میرے نکلوائے گئے فنڈز",
    "My Referrals": "میرے ریفرلز",
    "search quotation": "اقتباس تلاش کریں",
    "no quotation": "کوئی اقتباس نہیں ملا",
    "actions": "عمل",
    "search request": "تلاش کی درخواست",
    "no pending items ": "کوئی زیر التوا آئٹم نہیں ملا",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "اپنا QR کوڈ یا پے لنک شیئر کریں تاکہ آپ کے انسٹا پے والیٹ میں فوری ادائیگیاں ہو سکیں",
    "Description": "تفصیل",
    "save": "محفوظ کریں",
    "Share your Insta-Pay unique payment link": "اپنا منفرد InstaPay ادائیگی لنک شیئر کریں",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "اپنی عوامی پروفائل کو بلند کریں۔ آپ کا پورٹ فولیو ایک متحرک نمائش کے طور پر کام کرتا ہے، جو براہ راست آپ کے ادائیگی کے ایڈریس سے قابل رسائی ہے",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "\"اپنے سامعین کے ساتھ اپنے پیشہ ورانہ یا تخلیقی سفر کے ذریعے جڑیں۔ اپنے کام کو خود کو دکھانے دیں اور ممکنہ کلائنٹس یا حامیوں کو گہرائی سے متوجہ کریں۔",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "اپنی سوشل میڈیا پہنچ کو دکھائیں۔ وزیٹرز آپ کے سوشل میڈیا اثرات کو دیکھ سکتے ہیں، جو آپ کے پورٹ فولیو کو معتبر بناتا ہے۔",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "وزیٹرز کو آپ کے عوامی صفحہ سے براہ راست ادائیگیوں کی درخواست یا آغاز کی اجازت دیں۔ آپ کا پورٹ فولیو نہ صرف ایک نمائش ہے بلکہ آسان مالی ٹرانزیکشنز کی سہولت بھی ہے۔",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "\"میرے بارے میں\" سیکشن کو استعمال کریں تاکہ آپ اپنی کہانی، مشن، یا وژن شیئر کر سکیں، اور اپنے سامعین کے ساتھ ذاتی تعلق بنائیں۔",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "\"میرا پورٹ فولیو\" صرف ایک گیلری نہیں ہے؛ یہ آپ کے برانڈ کی تعمیر، آپ کے سامعین کے ساتھ رابطہ کرنے، اور InstaPay پر مالی تعاملات کو آسان بنانے کا ایک ذریعہ ہے۔",
    "No Portfolio Items": "کوئی پورٹ فولیو آئٹمز نہیں ملے",
    "Add New Portfolio Item": "نیا پورٹ فولیو آئٹم شامل کریں",
    "Title": "عنوان",
    "drag/drop or upload a file(photo/video/pdf)": "فائل ڈریگ/ڈراپ کریں یا اپ لوڈ کریں (تصویر/ویڈیو/pdf)",
    "discard": "خارج کریں",
    "save and post ": "محفوظ کریں اور پوسٹ کریں",
    "My Payment Address": "میرا ادائیگی پتہ",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "متاثرین، فنکاروں، کاروباری افراد یا کسی کے لئے بہترین ہے جو اپنی کمیونٹی سے آسانی سے تعاون حاصل کرنا چاہتا ہے۔",
    "Personal Account": "ذاتی اکاؤنٹ کی حیثیت",
    "spent in total": "کل خرچ",
    "not connected": "متصل نہیں",
    "more": "زیادہ",
    "less": "کم",
    "profile reports": "پروفائل رپورٹس",
    "All notifications are in English langauge": "تمام اطلاعات انگریزی میں ہیں",
    "timezone": "ٹائم زون",
    "nominee information": "نامزد کردہ کی معلومات",
    "full name": "مکمل نام",
    "Relation with Nominee": "نامزد کردہ کے ساتھ تعلق",
    "source": "ماخذ",
    "Google Authenticator": "گوگل آتھینٹی کیٹر",
    "notifications": "اطلاعات",
    "login": "لاگ ان کریں",
    "View Identity Verification Guide": "شناخت کی تصدیق گائیڈ دیکھیں",
    "verified": "تصدیق شدہ",
    "select ": "منتخب کریں",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "یہ ایک سرکاری جاری کردہ شناختی دستاویز ہونی چاہیے جس پر تصویر ہو۔ تمام شناختی دستاویزات لاطینی حروف تہجی میں ہونی چاہئیں۔ بصورت دیگر، ایک تصدیق شدہ ترجمہ کی ضرورت ہوگی۔",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "کیمٹ کنگڈم قانونی ذمہ داریوں کے تحت مکمل تصدیق کے لیے اضافی دستاویزات (Enhanced Due Diligence - EDD) یا کوئی بھی ضروری معلومات طلب کرنے کا حق محفوظ رکھتا ہے۔",
    "sessions": "سیشنز",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "فوری نکاسی کو ان لاک کریں: اپنے وصول کرنے والے اکاؤنٹس ابھی سیٹ اپ کریں!",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "آسانی سے ادائیگی کی وصولی کا انتظام کریں، اپنے بینک اکاؤنٹس، موبائل والٹس، کرپٹو والٹس، پیمنٹ کارڈز، یا نقدی وصولی کے مقامات کی تفصیلات شامل کر کے۔ نیچے دیے گئے اقدامات پر عمل کریں تاکہ جب بھی ضرورت ہو آپ کو اپنے فنڈز تک فوری رسائی مل سکے۔\n",
    "Select the receiving channel below and setup the details.": "نیچے دیے گئے وصولی کے چینل کو منتخب کریں اور تفصیلات مرتب کریں۔",
    "Please Select A Payer": "براہ کرم ایک ادائیگی کرنے والا منتخب کریں۔\n",
    "Number": "نمبر",
    "Account Holder Name": "اکاؤنٹ ہولڈر کا نام",
    "Province": "صوبہ",
    "postal": "پوسٹل پتہ",
    "delete channel": "چینل کو حذف کریں",
    "set as default": "ڈیفالٹ کے طور پر سیٹ کریں",
    "edit": "ترمیم کریں",
    "all channels are in english language in recieving account page": "تمام چینلز وصولی کے اکاؤنٹس کے صفحے پر انگریزی میں ہیں۔",
    "select file type": "فائل کی قسم منتخب کریں",
    "download data": "ڈیٹا ڈاؤن لوڈ کریں",
    "INTERNATIONAL TRANSFER": "بین الاقوامی منتقلی",
    "enter the amount": "رقم درج کریں",
    "they recieve ": "وصول کنندہ وصول کرتا ہے",
    "trouble sending money": "رقم بھیجنے میں دشواری؟",
    "select destination country": "منزل کا ملک منتخب کریں",
    "bank transfer": "بینک ٹرانسفر",
    "recieving method": "وصول کرنے کا طریقہ",
    "payer": "اداکار",
    "The fee and the received amount may vary depending on the chosen Payment Method": "فیس اور وصول کی جانے والی رقم منتخب کردہ ادائیگی کے طریقہ کار کے مطابق مختلف ہو سکتی ہے۔",
    "search ": "تلاش کریں",
    "show more": "مزید دکھائیں",
    "select remark reasons": "وجہ کے لیے منتخب کریں",
    "credit and debit card": "کریڈٹ اور ڈیبٹ کارڈز",
    "Transfer Fees": "ٹرانسفر فیس",
    "Estimated Time": "متوقع وقت:",
    "within minutes": "چند منٹوں میں",
    "total ": "کل",
    "OTP verifications code": "OTP تصدیقی کوڈ",
    "verify using sms/email": "ایس ایم ایس/ای میل کے ذریعے تصدیق کریں",
    "sms/email verification code": "ایس ایم ایس/ای میل تصدیقی کوڈ",
    "Verify Using Authenticator": "آتھنٹی کیٹر کے ذریعے تصدیق کریں",
    "WALLET TO WALLET TRANSFER": "والٹ ٹو والٹ ٹرانسفر",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "وصول کنندہ کا والٹ آئی ڈی، یوزرنیم، ای میل یا فون نمبر درج کریں",
    "search": "تلاش کریں",
    "lookup": "تلاش کریں",
    "Recipient Not on InstaPay?": "وصول کنندہ InstaPay پر نہیں؟",
    "invite them": "انہیں مدعو کریں",
    "Please follow these examples": "براہ کرم ان مثالوں کی پیروی کریں:",
    "Wallet ID: GY68J782": "والٹ آئی ڈی: GY68J782",
    "InstaPay/Instagram Username": "InstaPay/Instagram یوزرنیم: instauser",
    "Email: user@email.com": "ای میل: user@email.com",
    "With Country code": "فون نمبر (ملک کا کوڈ کے ساتھ)",
    "invite friend": "دوست کو مدعو کریں",
    "invite via phone number": "فون نمبر کے ذریعے مدعو کریں",
    "invite via email address": "ای میل ایڈریس کے ذریعے مدعو کریں",
    "next": "اگلا",
    "back": "واپس",
    "Personalized Message": "ذاتی پیغام",
    "available currency": "دستیاب کرنسی",
    "attach files": "فائلز منسلک کریں",
    "total amount": "کل رقم",
    "instant payment": "فوری ادائیگی",
    "starting from": "شروع ہونے سے",
    "recurring cycle": "بار بار کی جانے والا چکر",
    "until I stop": "جب تک روکا نہ جائے",
    "start from": "شروع ہونے سے",
    "cycle": "چکر",
    "total": "کل",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "وصول کنندہ کو تبادلہ نرخوں کی وجہ سے تھوڑی سی ایڈجسٹ رقم موصول ہو سکتی ہے",
    "schedule to": "طے شدہ",
    "schedule at": "طے شدہ وقت",
    "Scheduled Date": "طے شدہ تاریخ",
    "Scheduled time": "طے شدہ وقت",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "وصول کنندہ کو تبادلہ نرخوں کی وجہ سے تھوڑی سی ایڈجسٹ رقم موصول ہو سکتی ہے",
    "QR CODE PAYMENT ": "کیو آر کوڈ کی ادائیگی",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "متبادل طور پر، InstaPay الفا نمریک کیو آر کوڈ استعمال کریں",
    "PYAYMENT REQUEST": "ادائیگی کی درخواست",
    "choose the beneficiary": "مستفید کنندہ کا انتخاب کریں",
    "trouble requesting money": "رقم طلب کرنے میں مشکل؟",
    "Standard Request": "معیاری درخواست",
    "Subscription Request": "بار بار کی جانے والی درخواست",
    "Schedule Request": "طے شدہ درخواست",
    "Security Request": "سیکورٹی ڈپازٹ درخواست",
    "Instalment Request": "قسط بندی منصوبہ",
    "Split Request": "تقسیم شدہ ادائیگی",
    "You recieved": "آپ کو موصول ہوا",
    "is this your location?": "کیا یہ آپ کا مقام ہے؟",
    "yes": "ہاں",
    "Authenticator Code": "آتھنٹی کیٹر کوڈ",
    "Enter a code shown in the app to make sure everything works fine.": "یقینی بنانے کے لیے ایپ میں دکھایا گیا کوڈ درج کریں کہ سب کچھ درست ہے",
    "SEND A QUOTE": "ایک قیمت بھیجیں",
    "Trouble Quoting Money?": "قیمت بھیجنے میں دشواری؟",
    "Please follow these examples:": "براہ کرم ان مثالوں کی پیروی کریں:",
    "Full Name: Muhammad Imtiaz": "پورا نام: محمد امتیاز",
    "InstaPay/Intagram Username: instauser": "InstaPay/Instagram صارف نام: instauser",
    "Phone Number: 44795396600 (With Country Code)": "فون نمبر: 44795396600 (ملک کوڈ کے ساتھ)",
    "SEND TOP-UP AND E-SIM": "ٹاپ اپ اور ای-سم بھیجیں",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "دنیا بھر میں جڑے رہیں! InstaPay کی ٹاپ اپ سروس آپ کو فونز کو فوری طور پر ریچارج کرنے، ای سم خریدنے، برانڈڈ واؤچرز، گفٹ کارڈز خریدنے اور 150 سے زائد ممالک میں یوٹیلیٹی بلز ادا کرنے کی اجازت دیتی ہے۔ تحائف کے لیے مثالی یا خود کو تیار رکھنے کے لیے، ہماری سروس آسانی، تیزی اور سیکیورٹی کو ترجیح دیتی ہے۔",
    "MOBILE AIRTIME AND E-SIM": "موبائل ایئرٹائم اور ای-سم",
    "Select the country where you want to top-up a mobile phone!": "موبائل ٹاپ اپ کے لیے ملک منتخب کریں!",
    "Other Operator": "دیگر آپریٹرز",
    "airtime": "ایئرٹائم",
    "bundle": "بنڈل",
    "data": "ڈیٹا",
    "Enter custom amount": "اپنی مرضی کی رقم درج کریں",
    "Credit & Debit Card": "کریڈٹ اور ڈیبٹ کارڈ",
    "Bank Transfer": "بینک ٹرانسفر",
    "Mobile Wallet": "موبائل والٹ",
    "InstaPay Wallet": "InstaPay والٹ",
    "PayPal": "پے پال",
    "add card payment": "کارڈ کی ادائیگی شامل کریں",
    "save details to use next time.": "مستقبل میں استعمال کے لیے تفصیلات محفوظ کریں",
    "Recipient Mobile Number": "وصول کنندہ کا موبائل نمبر",
    "Amount": "رقم",
    "Service": "سروس",
    "Destination": "منزل",
    "Provider": "فراہم کنندہ",
    "Airtime": "ایئرٹائم",
    "Pakistan": "پاکستان",
    "Telenor Pakistan": "ٹیلی نار پاکستان",
    "Within minutes": "چند منٹوں میں",
    "top-up summary": "ٹاپ اپ خلاصہ",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "پے پال PKR کی حمایت نہیں کرتا، USD کو بطور ڈیفالٹ استعمال کیا جائے گا۔",
    "Amount in USD": "اماؤنٹ ان USD",
    "Send Top-up": "ٹاپ اپ بھیجیں",
    "available sunservices": "دستیاب سروسز",
    "login with email": "ای میل کے ساتھ لاگ ان کریں",
    "Already Have an Account?": "کیا آپ کے پاس پہلے سے اکاؤنٹ ہے؟ لاگ ان کریں",
    "profile picture": "پروفائل تصویر",
    "add photo/logo": "تصویر/لوگو شامل کریں",
    "first name": "پہلا نام",
    "enter your first name": "اپنا پہلا نام درج کریں",
    "last name": "آخری نام",
    "enter your last name": "اپنا آخری نام درج کریں",
    "email(optional)": "ای میل (اختیاری)",
    "enter your email address": "اپنا ای میل پتہ درج کریں",
    "password must": "پاس ورڈ ضروری",
    "It will be autofill, if you're signing up with invitation link": "دعوتی سائن اپ کے لیے خودکار پر کریں",
    "change number": "نمبر تبدیل کریں",
    "signup error": "سائن اپ کی غلطی",
    "Something went wrong while sending sms!": "SMS بھیجنے میں کچھ غلط ہوا! براہ کرم بعد میں دوبارہ کوشش کریں۔",
    "Errors": "غلطیاں پیش آئیں۔ براہ کرم تفصیلات کے لیے درج ذیل فہرست کا حوالہ دیں۔",
    "Generic error": "جنرل ایرر",
    "Trust Payments API requires the 'requests' library": "ٹرسٹ پیمنٹس API کو صحیح طریقے سے کام کرنے کے لیے 'requests' لائبریری کی ضرورت ہے۔",
    "Send error": "بھیجنے کے عمل کے دوران خرابی۔ براہ کرم دوبارہ کوشش کریں۔",
    "Receive error": "وصولی کے عمل کے دوران خرابی۔ براہ کرم دوبارہ کوشش کریں۔",
    "Invalid credentials provided": "غلط اسناد۔ براہ کرم تصدیق کریں اور دوبارہ کوشش کریں۔",
    "An issue occurred whilst trying to connect to Trust Payments servers": "ٹرسٹ پیمنٹس سرورز سے منسلک ہونے میں ایک مسئلہ پیش آیا۔ براہ کرم اپنا کنکشن چیک کریں اور دوبارہ کوشش کریں۔",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "ٹرسٹ پیمنٹس سرورز سے غیر متوقع خرابی۔ اگر یہ مسئلہ برقرار رہتا ہے، تو support@trustpayments.com سے رابطہ کریں۔",
    "Unknown error. If this persists please contact Trust Payments": "نامعلوم غلطی۔ براہ کرم ٹرسٹ پیمنٹس سپورٹ سے رابطہ کریں اگر مسئلہ جاری رہے۔",
    "Incorrect usage of the Trust Payments API": "ٹرسٹ پیمنٹس API کا غلط استعمال۔ براہ کرم درخواست کی تصدیق کریں اور دوبارہ کوشش کریں۔",
    "Invalid card details": "غلط کارڈ کی تفصیلات۔ براہ کرم تصدیق کریں اور درست تفصیلات درج کریں۔",
    "Invalid date": "غلط تاریخ فراہم کی گئی۔",
    "Invalid date/time": "غلط تاریخ/وقت کا فارمیٹ۔ براہ کرم صحیح فارمیٹ فراہم کریں۔",
    "Invalid details": "غلط تفصیلات۔ براہ کرم تصدیق کریں اور درست معلومات درج کریں۔",
    "Card number does not match card type": "کارڈ نمبر مخصوص کارڈ کی قسم سے میل نہیں کھاتا۔",
    "Malformed XML": "XML فارمیٹنگ کی خرابی۔ براہ کرم XML کی تصدیق کریں اور درست کریں۔",
    "XML does not match schema": "XML متوقع سکیمہ سے میل نہیں کھاتا۔",
    "Invalid file format": "غلط فائل فارمیٹ۔ براہ کرم معاون فارمیٹ استعمال کریں۔",
    "Empty file contents": "خالی فائل مواد۔ براہ کرم درست مواد فراہم کریں۔",
    "Invalid file contents": "غلط فائل مواد۔ براہ کرم فائل کی تصدیق کریں اور دوبارہ کوشش کریں۔",
    "Malformed JSON": "خراب شدہ JSON۔ براہ کرم JSON فارمیٹ درست کریں۔",
    "StApi Error": "StApi غلطی",
    "Invalid fields specified in request": "درخواست میں غلط فیلڈز مخصوص کی گئیں۔ براہ کرم تصدیق کریں اور درست کریں۔",
    "Missing parent": "والدین کی معلومات غائب ہیں۔",
    "Refund requires settled parent or parent that's due to settle today": "ریفنڈ کے لئے ایک مستحکم والدین کا لین دین یا ایک جو آج تصفیہ کے لئے واجب ہے، ضروری ہے۔",
    "Refund requires authorisation parent": "ریفنڈ کے لئے والدین کے اجازت کے لین دین کی ضرورت ہوتی ہے۔",
    "Refund amount too great": "ریفنڈ کی رقم قابل اجازت حد سے زیادہ ہے۔",
    "No acquirer specified": "کوئی خریدار کی معلومات فراہم نہیں کی گئیں۔",
    "Repeat amount too great": "مکرر رقم قابل اجازت حد سے زیادہ ہے۔",
    "Split amount too great": "تقسیم کی گئی رقم قابل اجازت حد سے زیادہ ہے۔",
    "Cannot refund a declined transaction": "منسوخ کردہ لین دین کی واپسی نہیں کی جا سکتی۔",
    "Refund requires a settled parent": "ریفنڈ کے لئے مستحکم والدین کے لین دین کی ضرورت ہوتی ہے۔",
    "Reversal requires a cancelled auth parent": "واپسی کے لئے منسوخ شدہ اجازت کے لین دین کی ضرورت ہوتی ہے۔",
    "Cannot override amount in child transaction": "بچے کے لین دین میں رقم کو اوور رائڈ نہیں کیا جا سکتا۔",
    "Cannot override currency in child transaction": "بچے کے لین دین میں کرنسی کو اوور رائڈ نہیں کیا جا سکتا۔",
    "Subscription requires RECUR account": "سبسکرپشن کے لئے مکرر اکاؤنٹ کی سیٹ اپ کی ضرورت ہوتی ہے۔",
    "Subscription requires successful parent": "سبسکرپشن کے لئے کامیاب والدین کے لین دین کی ضرورت ہوتی ہے۔",
    "Risk Decisions must have AUTH as parent": "رسک کے فیصلے کو اجازت کے لین دین سے منسلک کیا جانا چاہئے۔",
    "Chargebacks must have AUTH/REFUND as parent": "چارجبیکس کو اجازت یا ریفنڈ کے لین دین سے منسلک کیا جانا چاہئے۔",
    "Refund amount less than Minimum allowed": "ریفنڈ کی رقم کم از کم مطلوبہ سے کم ہے۔",
    "Refund requires paypaltransactionid": "ریفنڈ کے لئے پے پال ٹرانزیکشن ID کی ضرورت ہوتی ہے۔",
    "Invalid split transaction": "غلط تقسیم شدہ لین دین۔",
    "Cannot reverse AUTH processed more than 48 hours ago": "48 گھنٹے سے زیادہ عرصہ پہلے کی اجازت واپس نہیں کی جا سکتی۔",
    "Reversal requires acquirerreferencedata": "واپسی کے لئے خریدار کے حوالہ ڈیٹا کی ضرورت ہوتی ہے۔",
    "Cannot reverse AUTH processed by a different acquirer": "ایک مختلف خریدار کی طرف سے پراسیس کی گئی اجازت واپس نہیں کی جا سکتی۔",
    "Payment type does not support repeats": "ادائیگی کی قسم مکرر لین دین کی حمایت نہیں کرتی۔",
    "Reversal missing required data": "واپسی کے لئے مطلوبہ ڈیٹا غائب ہے۔",
    "Missing token": "ٹوکین کی معلومات غائب ہیں۔",
    "Subscription with an accountcheck parent not supported on current acquirer": "موجودہ خریدار کی طرف سے معاون نہ ہونے والے اکاؤنٹ چیک والدین کے ساتھ سبسکرپشن۔",
    "Subscription cannot be used as a parent": "سبسکرپشن والدین کے لین دین کے طور پر استعمال نہیں کی جا سکتی۔",
    "Invalid parent": "غلط والدین کے لین دین کی قسم۔",
    "Payment type does not support refunds": "ادائیگی کی قسم ریفنڈ کی حمایت نہیں کرتی۔",
    "Invalid incremental transaction": "غلط اضافی لین دین۔",
    "Partial reversals not supported": "جزوی واپسی کی حمایت نہیں کی جاتی۔",
    "THREEDQUERY parent/child must have the same payment type as the child": "3D محفوظ سوالات کے لئے والدین اور بچے کے لین دین میں ایک جیسی ادائیگی کی قسم ہونی چاہیے۔",
    "Payment type does not support card scheme updates": "ادائیگی کی قسم کارڈ سکیم کی تازہ کاریوں کی حمایت نہیں کرتی۔",
    "Cannot reverse AUTH at this time, please try again": "اس وقت اجازت واپس نہیں کی جا سکتی۔ براہ کرم بعد میں دوبارہ کوشش کریں۔",
    "Cannot determine token": "ٹوکین کی تصدیق نہیں ہو سکی۔ براہ کرم دوبارہ کوشش کریں۔",
    "Service Temporarily Disabled": "سروس عارضی طور پر غیر فعال ہے۔ براہ کرم بعد میں دوبارہ کوشش کریں۔",
    "Login first request": "درخواست کرنے سے پہلے لاگ ان ضروری ہے۔",
    "Invalid username/password": "غلط صارف نام یا پاس ورڈ۔ براہ کرم دوبارہ کوشش کریں۔",
    "Invalid session": "غلط سیشن۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "Session has expired": "سیشن کی میعاد ختم ہوگئی ہے۔ براہ کرم دوبارہ لاگ ان کریں۔",
    "Password expired": "پاس ورڈ کی میعاد ختم ہوگئی ہے۔ براہ کرم اپنا پاس ورڈ دوبارہ سیٹ کریں۔",
    "Password has been previously used": "پاس ورڈ پہلے ہی استعمال کیا جا چکا ہے۔ براہ کرم نیا پاس ورڈ منتخب کریں۔",
    "MyST user account has been locked": "MyST صارف اکاؤنٹ لاک ہو چکا ہے۔ براہ کرم سپورٹ سے رابطہ کریں۔",
    "New password does not match confirmed password": "نیا پاس ورڈ تصدیق شدہ پاس ورڈ سے میل نہیں کھاتا۔",
    "Incorrect current password": "غلط موجودہ پاس ورڈ۔ براہ کرم دوبارہ کوشش کریں۔",
    "Invalid selection": "غلط انتخاب کیا گیا ہے۔ براہ کرم درست آپشن منتخب کریں۔",
    "User already exists": "صارف پہلے سے موجود ہے۔ براہ کرم لاگ ان کریں یا ضرورت ہو تو پاس ورڈ ری سیٹ کریں۔",
    "No transaction found": "کوئی لین دین نہیں ملا۔",
    "Invalid selected transactions": "منتخب کردہ لین دین غلط ہیں۔",
    "Data supplied has not been saved": "ڈیٹا محفوظ نہیں کیا جا سکا۔ براہ کرم دوبارہ کوشش کریں۔",
    "Invalid request type": "غلط درخواست کی قسم۔",
    "Missing request type, at least one request type must be selected": "درخواست کی قسم غائب ہے۔ کم از کم ایک منتخب ہونا چاہئے۔",
    "Invalid payment type": "غلط ادائیگی کی قسم۔",
    "Missing payment type, at least one payment type must be selected": "ادائیگی کی قسم غائب ہے۔ کم از کم ایک منتخب ہونا چاہئے۔",
    "Invalid error code": "غلط ایرر کوڈ۔",
    "Missing error code, at least one error code must be selected": "ایرر کوڈ غائب ہے۔ کم از کم ایک منتخب ہونا چاہئے۔",
    "Invalid filter description": "غلط فلٹر وضاحت۔",
    "Invalid destination description": "غلط منزل کی وضاحت۔",
    "Invalid notification type": "غلط نوٹیفکیشن کی قسم۔",
    "Invalid destination": "غلط منزل۔",
    "Invalid field selected": "غلط فیلڈ منتخب کیا گیا۔",
    "Invalid email from address": "بھیجنے والے کے لیے غلط ای میل پتہ۔",
    "Invalid email subject": "غلط ای میل سبجیکٹ لائن۔",
    "Invalid email type": "غلط ای میل کی قسم۔",
    "Unable to process request": "درخواست پراسیس نہیں کی جا سکی۔ براہ کرم بعد میں دوبارہ کوشش کریں۔",
    "No file selected for upload": "اپ لوڈ کرنے کے لیے کوئی فائل منتخب نہیں کی گئی۔",
    "Invalid file size": "غلط فائل سائز۔ براہ کرم قابل قبول سائز کی فائل فراہم کریں۔",
    "Invalid filename": "غلط فائل نام۔",
    "Invalid extension": "غلط فائل توسیع۔",
    "User requires at least one site reference": "صارف کو کم از کم ایک سائٹ حوالہ درکار ہے۔",
    "Only ST-level users can have '*' access": "صرف ST سطح کے صارفین کو '*' تک رسائی کے حقوق ہو سکتے ہیں۔",
    "Request failed": "درخواست ناکام ہو گئی۔ براہ کرم بعد میں دوبارہ کوشش کریں۔",
    "Invalid File Contents": "غلط فائل مواد۔",
    "Maximum number of files uploaded": "زیادہ سے زیادہ فائلوں کی تعداد اپ لوڈ ہو چکی ہے۔ براہ کرم کچھ فائلیں حذف کریں اور دوبارہ کوشش کریں۔",
    "Insufficient gateway access privileges": "گیٹ وے تک رسائی کے حقوق ناکافی ہیں۔",
    "Maximum file size limit reached": "زیادہ سے زیادہ فائل سائز کی حد تک پہنچ گئی ہے۔",
    "Username(s) must be a valid user(s)": "صارف نام (صارفین) درست ہونے چاہئیں۔",
    "Site reference(s) must be a valid site(s)": "سائٹ کا حوالہ (حوالہ جات) درست سائٹس ہونے چاہئیں۔",
    "Unable to send email, please verify the details and try again": "ای میل بھیجنے میں ناکامی۔ براہ کرم تفصیلات کی تصدیق کریں اور دوبارہ کوشش کریں۔",
    "Negative already exists": "ایک منفی قدر پہلے سے موجود ہے۔ براہ کرم تصدیق کریں اور اصلاحات کریں۔",
    "Cannot delete a search owned by another user": "کسی دوسرے صارف کی ملکیت تلاش کو حذف نہیں کیا جا سکتا۔",
    "Invalid search": "غلط تلاش۔ براہ کرم درست معیار کے ساتھ دوبارہ کوشش کریں۔",
    "Cannot delete the specified search, the search name cannot be found": "مخصوص تلاش کو حذف نہیں کیا جا سکتا۔ تلاش کا نام نہیں مل سکا۔",
    "Search parameter is too short": "تلاش کا پیرامیٹر بہت چھوٹا ہے۔ براہ کرم مزید مخصوص تلاش کی شرائط درج کریں۔",
    "Duplicate custom fields defined": "ڈپلیکیٹ کسٹم فیلڈز متعین کیے گئے۔ براہ کرم نقل کو حل کریں۔",
    "Cannot allocate selected users, insufficient privileges": "منتخب کردہ صارفین کو مختص نہیں کیا جا سکتا۔ مراعات ناکافی ہیں۔",
    "Allocated users have access to additional sites": "مختص کردہ صارفین کو اضافی سائٹس تک رسائی حاصل ہے۔",
    "Allocated users have access to additional users": "مختص کردہ صارفین کو اضافی صارفین تک رسائی حاصل ہے۔",
    "User with current role cannot be allocated users": "موجودہ کردار کے حامل صارفین دوسرے صارفین کو مختص نہیں کر سکتے۔",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "اس سائٹ کو سائن ان کرنے کے لیے آپ کے براؤزر کو کوکیز قبول کرنے کی ضرورت ہے۔ کوکیز کو 'I accept' پر کلک کرکے قبول کیا جا سکتا ہے۔",
    "User requires at least one site reference or site group": "صارف کو کم از کم ایک سائٹ حوالہ یا سائٹ گروپ درکار ہے۔",
    "Allocated users have access to additional site groups": "مختص کردہ صارفین کو اضافی سائٹ گروپوں تک رسائی حاصل ہے۔",
    "No statement found": "کوئی بیان نہیں ملا۔",
    "Data supplied has not been updated in MobilePay 3rd-party service": "سپلائی کردہ ڈیٹا کو موبائل پے تھرڈ پارٹی سروس میں اپ ڈیٹ نہیں کیا گیا۔",
    "Bypass": "بائی پاس",
    "Insufficient access privileges": "رسائی کی مراعات ناکافی ہیں۔",
    "Coding error": "کوڈنگ کی غلطی کا پتہ چلا۔",
    "Insufficient privileges": "اس عمل کو مکمل کرنے کے لئے مراعات ناکافی ہیں۔",
    "Invalid request": "غلط درخواست۔ براہ کرم تصدیق کریں اور دوبارہ کوشش کریں۔",
    "Invalid field": "غلط فیلڈ درج کیا گیا۔ براہ کرم تصدیق کریں اور درست کریں۔",
    "Unknown site": "نامعلوم سائٹ۔",
    "Banned card": "کارڈ بینڈ ہے۔",
    "XML element parse error": "XML عنصر کی پارسنگ کی خرابی۔ براہ کرم XML ساخت کی تصدیق کریں۔",
    "Maestro must use SecureCode": "میسڑو لین دین کے لئے SecureCode کی تصدیق لازمی ہے۔",
    "Multiple email addresses must be separated with , or ;": "متعدد ای میل ایڈریسز کو کومہ (,) یا سیمی کولن (;) سے الگ کرنا ہوگا۔",
    "Invalid site reference for alias": "عرف کے لئے غلط سائٹ حوالہ۔",
    "Invalid version number": "غلط ورژن نمبر۔ براہ کرم درست ورژن نمبر درج کریں۔",
    "Unknown user": "نامعلوم صارف۔ براہ کرم تصدیق کریں اور دوبارہ کوشش کریں۔",
    "Cannot determine account": "اکاؤنٹ کی تصدیق نہیں ہو سکی۔",
    "JSON element parse error": "JSON عنصر کی پارسنگ کی خرابی۔ براہ کرم JSON ساخت کی تصدیق کریں۔",
    "Wallet type configuration error": "والیٹ کی قسم کی تشکیل کی خرابی۔",
    "Wallet type not supported on this request": "اس درخواست کے لئے والیٹ کی قسم کی حمایت نہیں ہے۔",
    "The card number you have provided is incorrect, please verify your details and try again": "فراہم کردہ کارڈ نمبر غلط ہے۔ براہ کرم اپنی تفصیلات کی تصدیق کریں اور دوبارہ کوشش کریں۔",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "فراہم کردہ سیکیورٹی کوڈ (CVV2) غلط ہے۔ براہ کرم تصدیق کریں اور دوبارہ کوشش کریں۔",
    "The expiry date you have provided is incorrect, please verify your details and try again": "فراہم کردہ اختتام کی تاریخ غلط ہے۔ براہ کرم اپنی تفصیلات کی تصدیق کریں اور دوبارہ کوشش کریں۔",
    "The expiry month you have provided is incorrect, please verify your details and try again": "فراہم کردہ اختتام کا مہینہ غلط ہے۔ براہ کرم تصدیق کریں اور دوبارہ کوشش کریں۔",
    "The expiry year you have provided is incorrect, please verify your details and try again": "فراہم کردہ اختتام کا سال غلط ہے۔ براہ کرم تصدیق کریں اور دوبارہ کوشش کریں۔",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "کنکشن کی غلطیوں کی وجہ سے آپ کی ادائیگی پراسیس نہیں ہو سکی۔ درخواست ID کا مماثلت نہیں ہو سکا۔ براہ کرم دوبارہ کوشش کریں۔",
    "The issue number you have provided is incorrect, please verify your details and try again": "فراہم کردہ مسئلے کا نمبر غلط ہے۔ براہ کرم تصدیق کریں اور دوبارہ کوشش کریں۔",
    "The payment type you have provided is incorrect, please verify your details and try again": "فراہم کردہ ادائیگی کی قسم غلط ہے۔ براہ کرم تصدیق کریں اور دوبارہ کوشش کریں۔",
    "Unable to process your payment, please contact the website": "آپ کی ادائیگی پراسیس نہیں ہو سکی۔ براہ کرم سپورٹ کے لئے ویب سائٹ سے رابطہ کریں۔",
    "There are errors with these fields: {0}": "ان فیلڈز میں غلطیاں ہیں: {0}",
    "No account found": "کوئی اکاؤنٹ نہیں ملا۔",
    "Refund cannot be processed": "ریفنڈ پراسیس نہیں کی جا سکتی۔",
    "Transaction de-activated": "لین دین غیر فعال ہو گیا ہے۔",
    "Socket receive error": "ساکٹ وصولی کی غلطی۔",
    "Socket connection error": "ساکٹ کنکشن کی غلطی۔",
    "Socket closed": "ساکٹ بند ہو گیا ہے۔",
    "Invalid data received": "غلط ڈیٹا موصول ہوا۔",
    "Invalid SQL": "غلط SQL سوال۔",
    "Timeout": "وقت کی حد ختم ہو گئی۔",
    "Invalid acquirer": "غلط خریدار کی تفصیلات۔",
    "Unable to connect to acquirer": "خریدار سے کنکشن نہیں ہو سکا۔",
    "Invalid response from acquirer": "خریدار کی طرف سے غلط جواب۔",
    "No available transport": "کوئی دستیاب ٹرانسپورٹ نہیں ملا۔",
    "File size too large": "فائل کا سائز بہت بڑا ہے۔",
    "Socket send error": "ساکٹ بھیجنے کی غلطی۔",
    "Communication error": "مواصلاتی غلطی۔",
    "Proxy error": "پراکسی غلطی۔",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "کنکشن کی غلطیوں کی وجہ سے آپ کی ادائیگی پراسیس نہیں ہو سکی۔ براہ کرم اپنی تفصیلات کی تصدیق کریں اور دوبارہ کوشش کریں ({0})۔",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "کنکشن کی غلطیوں کی وجہ سے آپ کی ادائیگی پراسیس نہیں ہو سکی (HTTP ردعمل کی حیثیت {0})۔ براہ کرم اپنی تفصیلات کی تصدیق کریں اور دوبارہ کوشش کریں ({1})۔",
    "Wrong number of emails": "غلط ای میل ایڈریسز کی تعداد فراہم کی گئی ہے۔",
    "Bank System Error": "بینک سسٹم کی خرابی۔",
    "Wrong number of transactions": "غلط لین دین کی تعداد فراہم کی گئی ہے۔",
    "Invalid transport configuration": "غلط ٹرانسپورٹ کنفیگریشن۔",
    "No valid updates specified": "کوئی درست اپڈیٹس فراہم نہیں کی گئی ہیں۔",
    "Transaction reference not found": "لین دین کا حوالہ نہیں ملا۔",
    "Settlebaseamount too large": "سیٹل کی بنیادی رقم بہت زیادہ ہے۔",
    "Transaction not updatable": "لین دین اپڈیٹ نہیں ہو سکتی۔",
    "No searchable filter specified": "کوئی تلاش کرنے والا فلٹر فراہم نہیں کیا گیا۔",
    "Timeout Error": "ٹائم آؤٹ کی غلطی۔ براہ کرم دوبارہ کوشش کریں۔",
    "3-D Secure Transport Error": "3-D سیکیور ٹرانسپورٹ کی خرابی۔",
    "Unauthenticated": "غیر تصدیق شدہ درخواست۔",
    "Site Suspended": "سائٹ معطل ہے۔",
    "No updates performed": "کوئی اپڈیٹس نہیں کی گئیں۔",
    "Invalid Request": "غلط درخواست۔",
    "Invalid Response": "غلط جواب۔",
    "Invalid Acquirer": "غلط خریدار کی معلومات۔",
    "Invalid account data": "غلط اکاؤنٹ ڈیٹا۔",
    "Missing": "معلومات غائب ہیں۔",
    "Payment Error": "ادائیگی کی خرابی۔",
    "Invalid acquirer for 3-D Secure": "3-D سیکیور کے لئے خریدار غلط ہے۔",
    "Invalid payment type for 3-D Secure": "3-D سیکیور کے لئے غلط ادائیگی کی قسم۔",
    "Invalid updates specified": "غلط اپڈیٹس فراہم کی گئیں۔",
    "Manual investigation required": "دستی جانچ کی ضرورت ہے۔",
    "Invalid headers": "غلط ہیڈرز۔",
    "Max fraudscore exceeded": "زیادہ سے زیادہ فراڈ اسکور تجاوز کر گیا۔",
    "Invalid filters": "غلط فلٹرز فراہم کیے گئے۔",
    "Merchant System Error": "تاجر نظام کی خرابی۔",
    "Your payment is being processed. Please wait...": "آپ کی ادائیگی پر کام کیا جا رہا ہے۔ براہ کرم انتظار کریں...",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "ایک ہی درخواست میں 'درخواست کی قسم کی وضاحت' اور 'درخواست کی قسم کی وضاحتیں' فراہم نہیں کی جا سکتیں۔",
    "Acquirer missing original transaction data": "خریدار کی اصل لین دین کی معلومات غائب ہیں۔",
    "Insufficient funds": "فنڈز ناکافی ہیں۔",
    "Unable to process due to scheme restrictions": "اسکیم پابندیوں کی وجہ سے پراسیس نہیں ہو سکی۔",
    "Failed Screening": "اسکریننگ ناکام ہو گئی۔",
    "Unable to process due to restrictions": "پابندیوں کی وجہ سے پراسیس نہیں ہو سکی۔",
    "Invalid process": "غلط عمل۔",
    "Risk Referral": "خطرہ حوالہ کی ضرورت ہے۔",
    "Name Pick required": "انتخاب کے لئے نام ضروری ہے۔",
    "Address Pick required": "انتخاب کے لئے پتہ ضروری ہے۔",
    "IP not in range": "IP پتہ دائرے میں نہیں ہے۔",
    "Invalid button configuration": "غلط بٹن کنفیگریشن۔",
    "Unrecognised response from acquirer": "خریدار کی طرف سے پہچان نہ آنے والا جواب۔",
    "Decline": "مسترد۔",
    "Uncertain result": "غیر یقینی نتیجہ۔",
    "Soft Decline": "نرم مسترد۔",
    "Refer to Issuer": "مزید معلومات کے لئے جاری کرنے والے سے رجوع کریں۔",
    "Request is queued please check the transaction later for the status": "درخواست قطار میں ہے۔ براہ کرم بعد میں لین دین کی حیثیت چیک کریں۔",
    "Generic Retry": "عام دوبارہ کوشش۔",
    "Soft Decline retry": "نرم مسترد دوبارہ کوشش۔",
    "There has been a problem with your payment, please verify your details and try again": "آپ کی ادائیگی میں ایک مسئلہ ہوا ہے۔ براہ کرم اپنی تفصیلات کی تصدیق کریں اور دوبارہ کوشش کریں۔",
    "Unknown error": "نامعلوم خرابی۔",

    "Login": "لاگ ان کریں",
    "Enter Your Email": "اپنا ای میل درج کریں",
    "Login With Phone Number": "فون نمبر سے لاگ ان کریں",
    "Verify Using Sms/Email": "SMS/ای میل کے ذریعے تصدیق کریں",
    "Resend Code In 100 Seconds": "100 سیکنڈ میں کوڈ دوبارہ بھیجیں",
    "Verify": "تصدیق کریں",
    "Verifying": "تصدیق ہو رہی ہے",
    "Last Week": "پچھلے ہفتے",
    "Older Transaction": "پرانا لین دین",
    "Total": "کل",
    "Conversion Confirmed": "منتقلی کی تصدیق ہوگئی",
    "User Account Limit": "صارف اکاؤنٹ کی حد",
    "Monthly Balance Limit": "ماہانہ بیلنس کی حد",
    "Total Limit": "کل حد",
    "Used": "استعمال شدہ",
    "Remaining": "باقی",
    "Allowed Minimum Topup Amount": "کم از کم ٹاپ اپ کی اجازت شدہ رقم",
    "Allowed Maximum Topup Amount": "زیادہ سے زیادہ ٹاپ اپ کی اجازت شدہ رقم",
    "Incoming Limit": "آنے والی حد",
    "Daily limit": "روزانہ کی حد",
    "Monthly limit": "ماہانہ حد",
    "Yearly limit": "سالانہ حد",
    "Sending Limit": "بھیجنے کی حد",
    "Allowed Number Of Transactions": "اجازت شدہ ٹرانزیکشنز کی تعداد",
    "Total Amount": "کل رقم",
    "Amount To Send": "بھیجنے کے لئے رقم",
    "Destination Amount": "منزل رقم",
    "Note": "نوٹ",
    "Revised Amount": "ترمیم شدہ رقم",
    "Save": "محفوظ کریں",
    "Date": "تاریخ",
    "Action": "کارروائی",
    "Payment Scheduled At": "شیڈولڈ ادائیگی",
    "Transaction Fee": "ٹرانزیکشن فیس",
    "Number Of Cycle": "سائیکل کی تعداد",
    "Subscription Starting Date": "سبسکرپشن کا آغاز کی تاریخ",
    "Until": "تک",
    "Next Transaction": "اگلا ٹرانزیکشن",
    "Pay Now": "ابھی ادائیگی کریں",
    "Transaction Method": "ٹرانزیکشن کا طریقہ",
    "Mobile Money Provider": "موبائل پیسہ فراہم کرنے والا",
    "Wallet Name": "والٹ کا نام",
    "Wallet Number": "والٹ نمبر",
    "Crypto Currency": "کرپٹو کرنسی",
    "Wallet Address": "والٹ پتہ",
    "Select Document Type": "دستاویز کی قسم منتخب کریں",
    "Edit Document Number": "دستاویز نمبر میں ترمیم کریں",
    "My Withdrawals": "میری واپسی",
    "Bargainable": "سودے بازی کے قابل",
    "Description": "تفصیل",
    "Attached Files": "منسلک فائلیں",
    "Sent": "بھیجا گیا",
    "Accepted": "قبول کیا گیا",
    "Decline": "مسترد",
    "Pending": "زیر التواء",
    "Overall Rating": "مجموعی درجہ بندی",
    "No Review": "کوئی جائزہ نہیں",
    "0 Rating": "0 درجہ بندی",
    "Transaction Map": "لین دین کا نقشہ",
    "Send Money": "پیسے بھیجیں",
    "Sending Method": "بھیجنے کا طریقہ",
    "Trouble Sending Money": "پیسے بھیجنے میں مشکل",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "فیس اور وصول شدہ رقم منتخب کردہ ادائیگی کے طریقے کے مطابق مختلف ہو سکتی ہے۔",
    "Select Method": "طریقہ منتخب کریں",
    "Completed": "مکمل",
    "How Was Your Experience?": "آپ کا تجربہ کیسا تھا؟",
    "Leave A Review": "ایک جائزہ چھوڑیں",
    "Review History": "جائزہ کی تاریخ",
    "Write A Review": "ایک جائزہ لکھیں",
    "Send And Post": "بھیجیں اور پوسٹ کریں",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out کرپٹو سے فیاٹ میں تبدیلی ایک مضبوط اور محفوظ حل ہے جو کرپٹو کرنسیوں کو فیاٹ کرنسیوں میں بغیر کسی رکاوٹ کے تبدیل کرتا ہے۔ یہ صارفین کی مختلف ضروریات کو پورا کرتا ہے، انفرادی ترجیحات اور مقامات کے مطابق ادائیگی کے متعدد چینلز فراہم کرتا ہے۔",
    "Review Created": "جائزہ تخلیق کیا گیا",
    "Transactions": "لین دین",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "فائل کو کھینچیں/ڈراپ کریں یا اپ لوڈ کریں (تصویر/ویڈیو/PDF)",
    "Max Size 1GB": "زیادہ سے زیادہ سائز 1GB",
    "Title Description": "عنوان تفصیل",
    "Add New": "نیا شامل کریں",
    "Discard": "خارج کریں",
    "Save And Post": "محفوظ کریں اور پوسٹ کریں",
    "Personal Account": "ذاتی اکاؤنٹ",
    "Conversion": "تبادلہ",
    "Spent In Total": "کل خرچ",
    "International": "بین الاقوامی",
    "Recharge": "ریچارج",
    "Top Up Wallet To Wallet": "والیٹ سے والیٹ ٹاپ اپ کریں",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay والیٹ",
    "Not Connected": "منسلک نہیں",
    "More": "مزید",
    "Less": "کم",
    "Profile Reports": "پروفائل رپورٹس",
    "Timezone": "ٹائم زون",
    "Cancel": "منسوخ کریں",
    "Full Name": "مکمل نام",
    "Google Authenticator": "Google آتھینٹیکیٹر",
    "Documents Supplémentaires": "اضافی دستاویزات",
    "Profile Complete": "پروفائل مکمل",
    "Verified": "تصدیق شدہ",
    "Bank Account": "بینک اکاؤنٹ",
    "Mobile Wallet": "موبائل والیٹ",
    "Cash Pickup": "نقد وصولی",
    "Add": "شامل کریں",
    "Configured": "ترتیب دیا گیا",
    "Email": "ای میل",
    "Download Data": "ڈیٹا ڈاؤن لوڈ کریں",
    "Enter The Amount": "رقم درج کریں",
    "They Receive": "وہ وصول کرتے ہیں",
    "Where Do You Want To Send Money": "آپ پیسے کہاں بھیجنا چاہتے ہیں؟",
    "Bank Transfer": "بینک منتقلی",
    "Receiving Method": "وصولی کا طریقہ",
    "Payer": "ادائیگی کرنے والا",
    "Minimum Amount": "کم از کم رقم",
    "Maximum Amount": "زیادہ سے زیادہ رقم",
    "Search": "تلاش کریں",
    "Show More": "مزید دکھائیں",
    "Select Remark / Reasons (All)": "تبصرہ / وجہ منتخب کریں (سب)",
    "Attach Files": "فائلیں منسلک کریں",
    "Holiday List": "تعطیلات کی فہرست",
    "No Data To Show": "دکھانے کے لیے کوئی ڈیٹا نہیں",
    "Threshold Limits": "حد کی حدود",
    "Amount Limits": "رقم کی حدود",
    "OTP Verification Code": "OTP تصدیق کوڈ",
    "Verify Using Authenticator": "آتھینٹیکیٹر کے ذریعے تصدیق کریں",
    "Sms/Email Verification Code": "SMS/ای میل تصدیق کوڈ",
    "Invite Them": "انہیں مدعو کریں",
    "Lookup": "تلاش کریں",
    "Invite Friend": "دوست کو مدعو کریں",
    "Invite Via Phone Number": "فون نمبر کے ذریعے مدعو کریں",
    "Invite Via Email Address": "ای میل ایڈریس کے ذریعے مدعو کریں",
    "Next": "اگلا",
    "Back": "واپس",
    "Invite Via Email": "ای میل کے ذریعے مدعو کریں",
    "Available Currency": "دستیاب کرنسی",
    "Add Remarks And Comments": "تبصرے اور کمنٹس شامل کریں",
    "Recipient Details": "وصول کنندہ کی تفصیلات",
    "Go Back": "واپس جائیں",
    "Trouble Requesting Money": "پیسوں کی درخواست میں مسئلہ",
    "Instant": "فوری",
    "Subscription": "سبسکرپشن",
    "Schedule": "شیڈول",
    "Choose Request Type": "درخواست کی قسم منتخب کریں",
    "Mobile Airtime": "موبائل ایئر ٹائم",
    "Incorrect Phone Number": "غلط فون نمبر",
    "Make Sure You Have Entered A Correct Phone Number.": "یہ یقینی بنائیں کہ آپ نے صحیح فون نمبر درج کیا ہو۔",
    "Close": "بند کریں",
    "Operator": "آپریٹر",
    "Recipient Mobile Number": "وصول کنندہ کا موبائل نمبر",
    "Amount": "رقم",
    "Service": "سروس",
    "Destination": "منزل",
    "Top Up Summary": "ٹاپ اپ کا خلاصہ",
    "Pakistan": "پاکستان",
    "Daily Sending Limit Exceeded": "روزانہ پیسوں کی حد تجاوز کر گئی",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "اپنے گاہک یا صارف کو ایک تفصیلی قیمت کا حوالہ بھیجیں۔ اپنے خدمات، مصنوعات یا پیشکشوں کو ایک ذاتی قیمت کے ساتھ پیشہ ورانہ طور پر پیش کریں۔",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "ہفتہ کے دن 3:00 PM سے پہلے کیے جانے والے ادائیگیاں وصول کنندہ کے بینک اکاؤنٹ میں اسی دن آدھی رات تک کریڈٹ کر دی جائیں گی۔",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "اپنے وصولی اکاؤنٹس کو تین ممالک میں تک سیٹ کریں اور منظور شدہ ادائیگی کی درخواستوں سے فوراً فنڈز اپنے فعال ادائیگی چینلز میں نکالیں۔",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "کمیٹ کنگڈم کو اپنے قانونی ذمہ داریوں کے مطابق مکمل تصدیق کرنے کے لیے کسی بھی اضافی دستاویزات (بہتر اہلیت کی جانچ - EDD) یا اضافی معلومات کی درخواست کرنے کا حق حاصل ہے۔",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "یہ ایک حکومت کی طرف سے جاری کردہ شناختی دستاویز ہونی چاہیے جس میں تصویر ہو۔ تمام شناختی دستاویزات کو لاطینی حروف تہجی میں لکھا جانا چاہیے۔ اگر نہیں، تو ایک تصدیق شدہ ترجمہ ضروری ہے۔",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "اپنے کام کو پیش کریں، اپنی پہنچ کو بڑھائیں۔ 'میرا پورٹ فولیو' آپ کا ڈیجیٹل کینوس ہے Instapay پر۔ دلکش تصاویر اور ویڈیوز سے لے کر معلوماتی PDFs تک مختلف قسم کے مواد کو اپ لوڈ اور دکھائیں۔ چاہے آپ ایک آرٹسٹ ہوں، فری لانسر ہوں یا کاروباری ہوں، یہ خصوصیت آپ کو اپنے کام یا منصوبوں کو بصری طور پر دلکش طریقے سے پیش کرنے کی اجازت دیتی ہے۔",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "اپنا QR کوڈ یا ادائیگی لنک شیئر کریں تاکہ آپ فوری طور پر اپنی Insta والٹ میں ادائیگی حاصل کر سکیں۔",
    "Afghanistan": "افغانستان",
    "Aland Islands": "جزائر آلاند",
    "Albania": "البانیہ",
    "Algeria": "الجزائر",
    "American Samoa": "امریکی ساموا",
    "Andorra": "انڈورا",
    "Angola": "انگولا",
    "Anguilla": "انگوئیلا",
    "Antigua and Barbuda": "انٹیگوا اور باربوڈا",
    "Argentina": "ارجنٹینا",
    "Armenia": "آرمینیا",
    "Aruba": "اروبا",
    "Australia": "آسٹریلیا",
    "Austria": "آسٹریا",
    "Azerbaijan": "آذربائیجان",
    "Bahamas": "بہاماس",
    "Bahrain": "بحرین",
    "Bangladesh": "بنگلہ دیش",
    "Barbados": "بارباڈوس",
    "Belarus": "بیلاروس",
    "Belgium": "بیلجیم",
    "Belize": "بیلیز",
    "Benin": "بینن",
    "Bermuda": "برمودا",
    "Bhutan": "بھوٹان",
    "Bolivia (Plurinational State of)": "بولیویا",
    "Bonaire - Sint Eustatius and Saba": "کیریبین نیدرلینڈز",
    "Bosnia and Herzegovina": "بوسنیا اور ہرزیگووینا",
    "Botswana": "بوتسوانا",
    "Brazil": "برازیل",
    "British Indian Ocean Territory": "برطانوی بحر ہند خطہ",
    "Brunei Darussalam": "برونائی دارالسلام",
    "Bulgaria": "بلغاریہ",
    "Burkina Faso": "برکینا فاسو",
    "Burundi": "برونڈی",
    "Cabo Verde": "کابو ورڈی",
    "Cambodia": "کمبوڈیا",
    "Cameroon": "کیمرون",
    "Canada": "کینیڈا",
    "Cayman Islands": "کیمین آئی لینڈز",
    "Central African Republic": "وسطی افریقی جمہوریہ",
    "Chad": "چاد",
    "Chile": "چلی",
    "China": "چین",
    "Christmas Island": "کرسمس آئی لینڈ",
    "Cocos (Keeling) Islands": "کوکوس (کِیلنگ) آئی لینڈز",
    "Colombia": "کولمبیا",
    "Comoros": "کوموروس",
    "Congo": "کانگو",
    "Congo - Democratic Republic of the": "کانگو - جمہوری جمہوریہ",
    "Cook Islands": "کک آئی لینڈز",
    "Costa Rica": "کوسٹا ریکا",
    "Cote d'Ivoire": "کوت ڈی آئیوئر",
    "Croatia": "کروشیا",
    "Cuba": "کوبا",
    "Curacao": "کورا کاو",
    "Cyprus": "قبرص",
    "Czech Republic": "چیک جمہوریہ",
    "Denmark": "ڈنمارک",
    "Djibouti": "جیبوتی",
    "Dominica": "ڈومینیکا",
    "Dominican Republic": "ڈومینیکن جمہوریہ",
    "Ecuador": "ایکواڈور",
    "Egypt": "مصر",
    "El Salvador": "ایس Salvador",
    "Equatorial Guinea": "استوائی گنی",
    "Eritrea": "اریٹیا",
    "Estonia": "ایسٹونیا",
    "Eswatini": "ایسواتینی",
    "Ethiopia": "ایتھوپیا",
    "Falkland Islands (Malvinas)": "فاکلینڈ جزائر (مالوینا)",
    "Faroe Islands": "فارو جزائر",
    "Fiji": "فجی",
    "Finland": "فن لینڈ",
    "France": "فرانس",
    "French Polynesia": "فرانسیسی پولینییشیا",
    "French Southern Territories": "فرانسیسی جنوبی علاقوں",
    "Gabon": "گابن",
    "Gambia": "گیمبیا",
    "Georgia": "جارجیا",
    "Germany": "جرمنی",
    "Ghana": "گھانا",
    "Gibraltar": "جبرالٹر",
    "Greece": "یونان",
    "Greenland": "گرین لینڈ",
    "Grenada": "گریناڈا",
    "Guam": "گوام",
    "Guatemala": "گواتیمالا",
    "Guernsey": "گیرنسی",
    "Guinea (Conakry)": "گنی (کوناکری)",
    "Guinea-Bissau": "گنی-بساو",
    "Guyana": "گائنا",
    "Haiti": "ہیتی",
    "Heard Island and McDonald Islands": "ہیرڈ جزیرہ اور میکڈونلڈ جزائر",
    "Holy See": "ہولی سی",
    "Honduras": "ہونڈوراس",
    "Hong Kong": "ہانگ کانگ",
    "Hungary": "ہنگری",
    "Iceland": "آئس لینڈ",
    "India": "بھارت",
    "Indonesia": "انڈونیشیا",
    "Iran (Islamic Republic of)": "ایران (اسلامی جمہوریہ)",
    "Iraq": "عراق",
    "Ireland": "آئرلینڈ",
    "Isle of Man": "آئل آف مین",
    "Israel": "اسرائیل",
    "Italy": "اٹلی",
    "Jamaica": "جمیکا",
    "Japan": "جاپان",
    "Jersey": "جرسی",
    "Jordan": "اردن",
    "Kazakhstan": "قازقستان",
    "Kenya": "کینیا",
    "Kiribati": "کیرِباتی",
    "Korea (Democratic People's Republic of)": "شمالی کوریا (ڈیموکریٹک پیپلز ریپبلک)",
    "Korea - Republic of": "جنوبی کوریا (جمہوریہ)",
    "Kuwait": "کویت",
    "Kyrgyzstan": "کرغزستان",
    "Lao People's Democratic Republic": "لاؤس (پیپلز ڈیموکریٹک ریپبلک)",
    "Latvia": "لٹویا",
    "Lebanon": "لبنان",
    "Lesotho": "لیسوتھو",
    "Liberia": "لائبیریا",
    "Libya": "لیبیا",
    "Liechtenstein": "لِشٹنسٹائن",
    "Lithuania": "لتھوانیا",
    "Luxembourg": "لکسمبرگ",
    "Macao": "مکاو",
    "Madagascar": "مڈغاسکر",
    "Malawi": "ملاوی",
    "Malaysia": "ملیشیا",
    "Maldives": "مالدیپ",
    "Mali": "مالی",
    "Malta": "مالٹا",
    "Marshall Islands": "مارشل جزائر",
    "Mauritania": "موریطانیہ",
    "Mauritius": "ماریشس",
    "Mexico": "میکسیکو",
    "Micronesia (Federated States of)": "مائیکرونیشیا (وفاقی ریاستیں)",
    "Moldova - Republic of": "مقداشی (ریپبلک آف)",
    "Monaco": "مونا کو",
    "Mongolia": "منگولیا",
    "Montenegro": "مونٹینیگرو",
    "Montserrat": "مونٹسرات",
    "Morocco": "مراکش",
    "Mozambique": "موزمبیق",
    "Myanmar": "میانمار",
    "Namibia": "نامیبیا",
    "Nauru": "نائرو",
    "Nepal": "نیپال",
    "Netherlands": "نیدرلینڈز",
    "New Caledonia": "نیو کیلیڈونیا",
    "New Zealand": "نیوزی لینڈ",
    "Nicaragua": "نیکاراگوا",
    "Niger": "نائیجر",
    "Nigeria": "نائجیریا",
    "Niue": "نیوے",
    "Norfolk Island": "نورفولک جزیرہ",
    "North Macedonia": "شمالی مقدونیہ",
    "Northern Mariana Islands": "شمالی ماریانا جزائر",
    "Norway": "ناروے",
    "Oman": "عمان",
    "Pakistan": "پاکستان",
    "Palau": "پالاؤ",
    "Palestine - State of": "فلسطین - ریاست",
    "Panama": "پاناما",
    "Papua New Guinea": "پاپوا نیوگنی",
    "Paraguay": "پیراگوئے",
    "Peru": "پیرو",
    "Philippines": "فلپائن",
    "Pitcairn": "پٹکیرن جزائر",
    "Poland": "پولینڈ",
    "Portugal": "پرتگال",
    "Puerto Rico": "پورٹو ریکو",
    "Qatar": "قطر",
    "Romania": "رومانی",
    "Russian Federation": "روسیہ",
    "Rwanda": "روانڈا",
    "Saint Barth\u00e9lemy": "سینٹ بارتھیلمی",
    "Saint Helena - Ascension and Tristan da Cunha": "سینٹ ہیلینا",
    "Saint Kitts and Nevis": "سینٹ کٹس اور نیوس",
    "Saint Lucia": "سینٹ لوسیا",
    "Saint Martin (French part)": "Saint Martin (Franse deel)",
    "Saint Vincent and the Grenadines": "سینٹ ونسنٹ اور گریناڈینز",
    "Samoa": "ساموا",
    "San Marino": "سان مارینو",
    "Sao Tome and Principe": "ساؤ ٹومے اور پرنسپی",
    "Senegal": "سینیگال",
    "Serbia": "صربیا",
    "Seychelles": "سیچلز",
    "Sierra Leone": "سیرالیون",
    "Singapore": "سنگاپور",
    "Sint Maarten (Dutch part)": "سینٹ مارٹن",
    "Slovakia": "سلوواکیا",
    "Slovenia": "سلووینیا",
    "Solomon Islands": "سلیمان جزائر",
    "Somalia": "صومالیہ",
    "South Africa": "جنوبی افریقہ",
    "South Georgia and the South Sandwich Islands": "جنوبی جارجیا اور جنوبی سینڈوچ جزائر",
    "South Sudan": "جنوبی سوڈان",
    "Spain": "اسپین",
    "Sri Lanka": "سری لنکا",
    "Sudan": "سوڈان",
    "Suriname": "سرینام",
    "Svalbard and Jan Mayen": "سوالبارڈ اور جان میئن",
    "Sweden": "سویڈن",
    "Switzerland": "سوئٹزرلینڈ",
    "Syrian Arab Republic": "شام",
    "Tajikistan": "تاجکستان",
    "United Republic of Tanzania": "تنزانیہ",
    "Thailand": "تھائی لینڈ",
    "Timor-Leste": "مشرقی تیمور",
    "Togo": "ٹوگو",
    "Tokelau": "ٹوکیلو",
    "Tonga": "ٹونگا",
    "Trinidad and Tobago": "ترینیداد اور ٹوباگو",
    "Tunisia": "تیونس",
    "Turkey": "ترکی",
    "Turkmenistan": "ترکمانستان",
    "Turks and Caicos Islands": "ترکس اور کیکوس جزائر",
    "Tuvalu": "تووالو",
    "Uganda": "یوگنڈا",
    "Ukraine": "یوکرین",
    "United Arab Emirates": "متحدہ عرب امارات",
    "United Kingdom of Great Britain and Northern Ireland": "برطانیہ",
    "United States of America": "ریاستہائے متحدہ",
    "United States Minor Outlying Islands": "ریاستہائے متحدہ کے چھوٹے بیرونی جزائر",
    "Uruguay": "یوراگوئے",
    "Uzbekistan": "ازبکستان",
    "Vanuatu": "وانواتو",
    "Venezuela (Bolivarian Republic of)": "وینزویلا (بولیویرین جمہوریہ)",
    "Viet Nam": "ویتنام",
    "Virgin Islands (British)": "برطانوی ورجن جزائر",
    "Virgin Islands (U.S.)": "امریکی ورجن جزائر",
    "Wallis and Futuna": "ویلس اور فتونہ",
    "Yemen": "یمن",
    "Zambia": "زامبیا",
    "Zimbabwe": "زمبابوے",
    "Taiwan": "تائیوان",
    "Palestine": "فلسطین",
    "Western Sahara": "مغربی صحارا",
    "Kosovo": "کوسوو",
    "South Ossetia": "جنوبی اوسیتیا",
    "Abkhazia": "ابخازیا",
    "Nagorno-Karabakh (Artsakh)": "ناگورنو کاراباخ (آرتساخ)",
    "Transnistria": "ٹرانسنسٹریا",
    "Somaliland": "صومالی لینڈ",
    "Northern Cyprus": "شمالی قبرص",
    "Saudi Arabia": "سعودی عرب",
    "Yugoslavia": "یوگوسلاویہ",

    "Login": "لاگ ان کریں",
    "Enter Your Email": "اپنا ای میل درج کریں",
    "Login With Phone Number": "فون نمبر سے لاگ ان کریں",
    "Verify Using Sms/Email": "SMS/ای میل کے ذریعے تصدیق کریں",
    "Resend Code In 100 Seconds": "100 سیکنڈ میں کوڈ دوبارہ بھیجیں",
    "Verify": "تصدیق کریں",
    "Verifying": "تصدیق ہو رہی ہے",
    "Last Week": "پچھلے ہفتے",
    "Older Transaction": "پرانا لین دین",
    "Total": "کل",
    "Conversion Confirmed": "منتقلی کی تصدیق ہوگئی",
    "User Account Limit": "صارف اکاؤنٹ کی حد",
    "Monthly Balance Limit": "ماہانہ بیلنس کی حد",
    "Total Limit": "کل حد",
    "Used": "استعمال شدہ",
    "Remaining": "باقی",
    "Allowed Minimum Topup Amount": "کم از کم ٹاپ اپ کی اجازت شدہ رقم",
    "Allowed Maximum Topup Amount": "زیادہ سے زیادہ ٹاپ اپ کی اجازت شدہ رقم",
    "Incoming Limit": "آنے والی حد",
    "Daily Limit": "روزانہ کی حد",
    "Monthly Limit": "ماہانہ حد",
    "Yearly Limit": "سالانہ حد",
    "Sending Limit": "بھیجنے کی حد",
    "Allowed Number Of Transactions": "اجازت شدہ ٹرانزیکشنز کی تعداد",
    "Total Amount": "کل رقم",
    "Amount To Send": "بھیجنے کے لئے رقم",
    "Destination Amount": "منزل رقم",
    "Note": "نوٹ",
    "Revised Amount": "ترمیم شدہ رقم",
    "Save": "محفوظ کریں",
    "Date": "تاریخ",
    "Action": "کارروائی",
    "Payment Scheduled At": "شیڈولڈ ادائیگی",
    "Transaction Fee": "ٹرانزیکشن فیس",
    "Number Of Cycle": "سائیکل کی تعداد",
    "Subscription Starting Date": "سبسکرپشن کا آغاز کی تاریخ",
    "Until": "تک",
    "Next Transaction": "اگلا ٹرانزیکشن",
    "Pay Now": "ابھی ادائیگی کریں",
    "Transaction Method": "ٹرانزیکشن کا طریقہ",
    "Mobile Money Provider": "موبائل پیسہ فراہم کرنے والا",
    "Wallet Name": "والٹ کا نام",
    "Wallet Number": "والٹ نمبر",
    "Crypto Currency": "کرپٹو کرنسی",
    "Wallet Address": "والٹ پتہ",
    "Select Document Type": "دستاویز کی قسم منتخب کریں",
    "Edit Document Number": "دستاویز نمبر میں ترمیم کریں",
    "My Withdrawals": "میری واپسی",
    "Bargainable": "سودے بازی کے قابل",
    "Description": "تفصیل",
    "Attached Files": "منسلک فائلیں",
    "Sent": "بھیجا گیا",
    "Accepted": "قبول کیا گیا",
    "Decline": "مسترد",
    "Pending": "زیر التواء",
    "Overall Rating": "مجموعی درجہ بندی",
    "No Review": "کوئی جائزہ نہیں",
    "0 Rating": "0 درجہ بندی",
    "Transaction Map": "لین دین کا نقشہ",
    "Send Money": "پیسے بھیجیں",
    "Sending Method": "بھیجنے کا طریقہ",
    "Trouble Sending Money": "پیسے بھیجنے میں مشکل",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "فیس اور وصول شدہ رقم منتخب کردہ ادائیگی کے طریقے کے مطابق مختلف ہو سکتی ہے۔",
    "Select Method": "طریقہ منتخب کریں",
    "Completed": "مکمل",
    "How Was Your Experience?": "آپ کا تجربہ کیسا تھا؟",
    "Leave A Review": "ایک جائزہ چھوڑیں",
    "Review History": "جائزہ کی تاریخ",
    "Write A Review": "ایک جائزہ لکھیں",
    "Send And Post": "بھیجیں اور پوسٹ کریں",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out کرپٹو سے فیاٹ میں تبدیلی ایک مضبوط اور محفوظ حل ہے جو کرپٹو کرنسیوں کو فیاٹ کرنسیوں میں بغیر کسی رکاوٹ کے تبدیل کرتا ہے۔ یہ صارفین کی مختلف ضروریات کو پورا کرتا ہے، انفرادی ترجیحات اور مقامات کے مطابق ادائیگی کے متعدد چینلز فراہم کرتا ہے۔",
    "Review Created": "جائزہ تخلیق کیا گیا",
    "Transactions": "لین دین",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "فائل کو کھینچیں/ڈراپ کریں یا اپ لوڈ کریں (تصویر/ویڈیو/PDF)",
    "Max Size 1GB": "زیادہ سے زیادہ سائز 1GB",
    "Title Description": "عنوان تفصیل",
    "Add New": "نیا شامل کریں",
    "Discard": "خارج کریں",
    "Save And Post": "محفوظ کریں اور پوسٹ کریں",
    "Personal Account": "ذاتی اکاؤنٹ",
    "Conversion": "تبادلہ",
    "Spent In Total": "کل خرچ",
    "International": "بین الاقوامی",
    "Recharge": "ریچارج",
    "Top Up Wallet To Wallet": "والیٹ سے والیٹ ٹاپ اپ کریں",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay والیٹ",
    "Not Connected": "منسلک نہیں",
    "More": "مزید",
    "Less": "کم",
    "Profile Reports": "پروفائل رپورٹس",
    "Timezone": "ٹائم زون",
    "Cancel": "منسوخ کریں",
    "Full Name": "مکمل نام",
    "Google Authenticator": "Google آتھینٹیکیٹر",
    "Documents Supplémentaires": "اضافی دستاویزات",
    "Profile Complete": "پروفائل مکمل",
    "Verified": "تصدیق شدہ",
    "Bank Account": "بینک اکاؤنٹ",
    "Mobile Wallet": "موبائل والیٹ",
    "Cash Pickup": "نقد وصولی",
    "Add": "شامل کریں",
    "Configured": "ترتیب دیا گیا",
    "Email": "ای میل",
    "Download Data": "ڈیٹا ڈاؤن لوڈ کریں",
    "Enter The Amount": "رقم درج کریں",
    "They Receive": "وہ وصول کرتے ہیں",
    "Where Do You Want To Send Money": "آپ پیسے کہاں بھیجنا چاہتے ہیں؟",
    "Bank Transfer": "بینک منتقلی",
    "Receiving Method": "وصولی کا طریقہ",
    "Payer": "ادائیگی کرنے والا",
    "Minimum Amount": "کم از کم رقم",
    "Maximum Amount": "زیادہ سے زیادہ رقم",
    "Search": "تلاش کریں",
    "Show More": "مزید دکھائیں",
    "Select Remark / Reasons (All)": "تبصرہ / وجہ منتخب کریں (سب)",
    "Attach Files": "فائلیں منسلک کریں",
    "Holiday List": "تعطیلات کی فہرست",
    "No Data To Show": "دکھانے کے لیے کوئی ڈیٹا نہیں",
    "Threshold Limits": "حد کی حدود",
    "Amount Limits": "رقم کی حدود",
    "OTP Verification Code": "OTP تصدیق کوڈ",
    "Verify Using Authenticator": "آتھینٹیکیٹر کے ذریعے تصدیق کریں",
    "Sms/Email Verification Code": "SMS/ای میل تصدیق کوڈ",
    "Invite Them": "انہیں مدعو کریں",
    "Lookup": "تلاش کریں",
    "Invite Friend": "دوست کو مدعو کریں",
    "Invite Via Phone Number": "فون نمبر کے ذریعے مدعو کریں",
    "Invite Via Email Address": "ای میل ایڈریس کے ذریعے مدعو کریں",
    "Next": "اگلا",
    "Back": "واپس",
    "Invite Via Email": "ای میل کے ذریعے مدعو کریں",
    "Available Currency": "دستیاب کرنسی",
    "Add Remarks And Comments": "تبصرے اور کمنٹس شامل کریں",
    "Recipient Details": "وصول کنندہ کی تفصیلات",
    "Go Back": "واپس جائیں",
    "Trouble Requesting Money": "پیسوں کی درخواست میں مسئلہ",
    "Instant": "فوری",
    "Subscription": "سبسکرپشن",
    "Schedule": "شیڈول",
    "Choose Request Type": "درخواست کی قسم منتخب کریں",
    "Mobile Airtime": "موبائل ایئر ٹائم",
    "Incorrect Phone Number": "غلط فون نمبر",
    "Make Sure You Have Entered A Correct Phone Number.": "یہ یقینی بنائیں کہ آپ نے صحیح فون نمبر درج کیا ہو۔",
    "Close": "بند کریں",
    "Operator": "آپریٹر",
    "Recipient Mobile Number": "وصول کنندہ کا موبائل نمبر",
    "Amount": "رقم",
    "Service": "سروس",
    "Destination": "منزل",
    "Top Up Summary": "ٹاپ اپ کا خلاصہ",
    "Daily Sending Limit Exceeded": "روزانہ پیسوں کی حد تجاوز کر گئی",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "اپنے گاہک یا صارف کو ایک تفصیلی قیمت کا حوالہ بھیجیں۔ اپنے خدمات، مصنوعات یا پیشکشوں کو ایک ذاتی قیمت کے ساتھ پیشہ ورانہ طور پر پیش کریں۔",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "ہفتہ کے دن 3:00 PM سے پہلے کیے جانے والے ادائیگیاں وصول کنندہ کے بینک اکاؤنٹ میں اسی دن آدھی رات تک کریڈٹ کر دی جائیں گی۔",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "اپنے وصولی اکاؤنٹس کو تین ممالک میں تک سیٹ کریں اور منظور شدہ ادائیگی کی درخواستوں سے فوراً فنڈز اپنے فعال ادائیگی چینلز میں نکالیں۔",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "کمیٹ کنگڈم کو اپنے قانونی ذمہ داریوں کے مطابق مکمل تصدیق کرنے کے لیے کسی بھی اضافی دستاویزات (بہتر اہلیت کی جانچ - EDD) یا اضافی معلومات کی درخواست کرنے کا حق حاصل ہے۔",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "یہ ایک حکومت کی طرف سے جاری کردہ شناختی دستاویز ہونی چاہیے جس میں تصویر ہو۔ تمام شناختی دستاویزات کو لاطینی حروف تہجی میں لکھا جانا چاہیے۔ اگر نہیں، تو ایک تصدیق شدہ ترجمہ ضروری ہے۔",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "اپنے کام کو پیش کریں، اپنی پہنچ کو بڑھائیں۔ 'میرا پورٹ فولیو' آپ کا ڈیجیٹل کینوس ہے Instapay پر۔ دلکش تصاویر اور ویڈیوز سے لے کر معلوماتی PDFs تک مختلف قسم کے مواد کو اپ لوڈ اور دکھائیں۔ چاہے آپ ایک آرٹسٹ ہوں، فری لانسر ہوں یا کاروباری ہوں، یہ خصوصیت آپ کو اپنے کام یا منصوبوں کو بصری طور پر دلکش طریقے سے پیش کرنے کی اجازت دیتی ہے۔",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "اپنا QR کوڈ یا ادائیگی لنک شیئر کریں تاکہ آپ فوری طور پر اپنی Insta والٹ میں ادائیگی حاصل کر سکیں۔",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "آپ کے پاس لین دین مکمل کرنے کے لیے 15 منٹ ہیں۔",
    "Time Remaining": "براہ کرم وقت کی حد کے اندر مکمل کریں تاکہ یو آر ایل کی میعاد ختم ہونے سے بچا جا سکے۔",
    "Please Process The Payment Via Your Selected Payment Type": "براہ کرم اپنے منتخب کردہ ادائیگی کے طریقے کے ذریعے ادائیگی کا عمل مکمل کریں۔",
    "Top-Up Wallet": "ادائیگی کا طریقہ",
    "Confirm Your Payment": "والیٹ کو ری چارج کریں",
    "You Will Get": "اپنی ادائیگی کی تصدیق کریں",
    "Amount To Pay": "آپ کو ملے گا",
    "Proceed To Payment": "ادائیگی کے لیے رقم",
    "Load/Deposit Money To The Wallet": "ادائیگی کے لیے آگے بڑھیں",
    "Withdraw": "والیٹ میں رقم لوڈ/جمع کریں",
    "Elevate Your Business With InstaPay": "نکالنا",
    "Higher Balances & Transaction Limits": "InstaPay کے ساتھ اپنے کاروبار کو ترقی دیں",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "زیادہ بیلنس اور لین دین کی حدیں",
    "Full Feature Access": "زیادہ بیلنسز کا انتظام کرنے اور بڑی ٹرانزیکشنز کو پروسیس کرنے کی آزادی حاصل کریں، InstaPay کی توقع کردہ بھروسے کے ساتھ۔",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "مکمل فیچر رسائی",
    "Global Reach": "عالمی رسائی",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "اپنے کاروبار کو بغیر سرحدوں کے وسعت دیں۔ InstaPay آپ کو دنیا بھر کے صارفین اور شراکت داروں سے جوڑتا ہے، عالمی لین دین کو پہلے سے کہیں زیادہ آسان بنا دیتا ہے۔",
    "Advanced Security": "اعلیٰ سیکیورٹی",
    "Business Registration Available Soon\n": "کاروباری رجسٹریشن جلد دستیاب ہوگی۔",
    "Amount After Fee": "فیس کے بعد رقم",
    "Allowed Maximum Amount Per Transaction": "ہر لین دین کے لیے زیادہ سے زیادہ اجازت شدہ رقم",
    "Transfer": "منتقلی",
    "Uncategorized": "غیر درجہ بند",
    "Currency ISO Code & Amount": "کرنسی ISO کوڈ اور رقم",
    "Choose The Top-Up Channel": "ٹاپ اپ چینل کا انتخاب کریں",
    "You Selected": "جو آپ نے منتخب کیا",
    "Payout Channel": "ادائیگی کا چینل",
    "Your Details": "آپ کی تفصیلات",
    "Detailed Confirmed": "تفصیلات کی تصدیق ہوگئی",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "اپنی مالی صلاحیت کو بڑھانے کے لیے InstaPay بزنس اکاؤنٹ میں اپ گریڈ کریں۔ انسٹا پے کی مکمل طاقت کا تجربہ کریں، جو خاص طور پر کاروبار کی ترقی کے لیے تیار کردہ خصوصیات کے ساتھ۔",
    "Profile Picture": "پروفائل تصویر",
    "Add Photo Or Logo": "تصویر یا لوگو شامل کریں",
    "First Name": "پہلا نام",
    "Last Name": "آخری نام",
    "Enter Your First Name": "اپنا پہلا نام درج کریں",
    "Enter Your Last Name": "اپنا آخری نام درج کریں",
    "Email (Optional)": "ای میل (اختیاری)",
    "Password Must": "پاس ورڈ لازمی ہے",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "اگر آپ دعوتی لنک کے ذریعے سائن اپ کر رہے ہیں تو یہ خودکار طور پر بھرا جائے گا۔",
    "Change Number": "نمبر تبدیل کریں",
    "Search Country": "ملک تلاش کریں",
    "Select From The List": "فہرست سے منتخب کریں",
    "Received Amount": "موصول شدہ رقم",

    "null": null,
    "Processing": "پراسیسنگ",
    "Verify Using Sms/Email": "ایس ایم ایس/ای میل کے ذریعے تصدیق کریں",
    "Bitcoin (BTC)": "بٹ کوائن (BTC)",
    "Tether (USDT)": "ٹیتر (USDT)",
    "Ethereum (ETH)": "ایتھیریم (ETH)",
    "Already Exist": "پہلے سے موجود ہے",
    "You Can Have Only 8 Wallets": "آپ کے پاس صرف 8 بٹوے ہو سکتے ہیں",
    "Save": "محفوظ کریں",
    "0 File": "0 فائل",
    "Search From The List": "فہرست سے تلاش کریں",
    "Amount To Send": "بھیجنے کی رقم",
    "Wallet To Wallet": "والٹ سے والٹ",
    "TOP-UP WALLET ": "بٹوے کو بھرنا",
    "Enter The Amount": "رقم درج کریں",
    "Load/Deposit Money To The Wallet": "بٹوے میں رقم لوڈ/جمع کریں",
    "Choose The Top-Up Channel": "ٹاپ اپ چینل منتخب کریں",
    "Paypal": "Paypal",
    "You Will Get": "آپ کو ملے گا",
    "Amount To Pay": "ادا کی جانے والی رقم",
    "Card Holder Name ": "کارڈ ہولڈر کا نام",
    "Card Number": "کارڈ نمبر",
    "CVC": "سی وی سی",
    "MM/YY": "ایم ایم/سال",
    "Your Details ": "آپ کی تفصیلات",
    "You Selected": "آپ نے منتخب کیا",
    "Detailed Confirmed": "تفصیلی تصدیق شدہ",
    "Transaction Failed": "ٹرانزیکشن ناکام ہوگیا",
    "Something Went Wrong. Try Again.": "کچھ غلط ہو گیا۔ دوبارہ کوشش کریں۔",
    "Convert Funds": "فنڈز کو تبدیل کریں",
    "How Would You Like To Cashout?:": "آپ کیش آؤٹ کیسے کرنا چاہیں گے؟:",
    "Default Receiving Account": "ڈیفالٹ وصول کرنے والا اکاؤنٹ",
    "Specify Receiving Account": "وصول کنندہ اکاؤنٹ کی وضاحت کریں",
    "Which Receiving Account Would You Want Use?:": "آپ کون سا وصول کنندہ اکاؤنٹ استعمال کرنا چاہیں گے؟:",
    "Pakistan": "پاکستان",
    "Bank Mobile": "بینک موبائل",
    "PDF": "پی ڈی ایف",
    "CSV": "سی ایس وی",
    "None": "کوئی نہیں",
    "Request": "درخواست کریں",
    "Subscription Payment": "سبسکرپشن ادائیگی",
    "Is not supported by Paypal, USD will be used as the default currency.": "پے پال کے ذریعے سپورٹ نہیں کیا جاتا، USD کو ڈیفالٹ کرنسی کے طور پر استعمال کیا جائے گا۔",
    "Review Payment": "ادائیگی کا جائزہ لیں",
    "Google Authenticator": "گوگل توثیق کنندہ",
    "Scheduled Has Been Cancelled": "شیڈول منسوخ کر دیا گیا",
    "Select Country First": "پہلے ملک منتخب کریں",
    "Select Gender": "جنس منتخب کریں",
    "Select Occupation": "پیشہ منتخب کریں",
    "IBAN and SWIFT/BIC": "آئی بی اے این اور سوئفٹ/بی آئی سی",
    "Mobile Money Provider": "موبائل منی فراہم کنندہ",
    "Wallet Name": "والٹ کا نام",
    "Wallet Number ": "والٹ نمبر",
    "Crypto Currency": "کرپٹو کرنسی",
    "Wallet Address ": "والٹ ایڈریس",
    "Select Document Type ": "دستاویز کی قسم منتخب کریں",
    "Enter Document Number": "دستاویز نمبر درج کریں",
    "Msisdn": "ایم ایس آئی ایس ڈی این",
    "Edit ": "ترمیم کریں",
    "My Withdrawls": "میری واپسی",
    "Declined": "مسترد کر دیا گیا",
    "Accepted": "قبول کر لیا گیا",
    "Overall Ratings": "مجموعی درجہ بندی",
    "0 Ratings": "0 درجہ بندی",
    "No Reviews": "کوئی جائزہ نہیں",
    "Send Money": "پیسے بھیجیں",
    "Trouble Sending Money?": "پیسے بھیجنے میں مسئلہ؟",
    "Payment Request": "ادائیگی کی درخواست",
    "How Was Your Experience?": "آپ کا تجربہ کیسا رہا؟",
    "Leave A Review": "جائزہ چھوڑیں",
    "Write Review Message": "جائزہ پیغام لکھیں",
    "Send And Post": "بھیجیں اور پوسٹ کریں",
    "You Rated": "آپ نے درجہ دیا",
    "Review History": "جائزہ تاریخ",
    "Max Size 1GB": "زیادہ سے زیادہ سائز 1GB",
    "Title": "عنوان",
    "Description": "تفصیل",
    "Add New": "نیا شامل کریں",
    "My Payment Address": "میرا ادائیگی پتہ",
    "Personal Account": "ذاتی اکاؤنٹ",
    "Updated Successfully": "کامیابی سے اپ ڈیٹ کیا گیا",
    "Spent In": "خرچ کیا",
    "TOP UP": "دوبارہ بھرنا",
    "Quotation": "حوالہ",
    "Amount": "رقم",
    "Not Connected": "جڑا نہیں ہے",
    "More": "مزید",
    "Less": "کم",
    "Reports": "رپورٹس",
    "User Profile": "صارف پروفائل",
    "Cancel": "منسوخ کریں",
    "Saving": "بچت",
    "Confirm ": "تصدیق کریں",
    "Verify ": "تصدیق کریں",
    "Delete Country": "ملک حذف کریں",
    "Bank Account": "بینک اکاؤنٹ",
    "Cash Pickup": "کیش پک اپ",
    "Mobile Wallet ": "موبائل والٹ",
    "Delete Country Withdrawals": "ملک نکالنے کو حذف کریں",
    "Yes , Delete It": "ہاں، اسے حذف کریں",
    "E-sim": "ای-سم",
    "Utilities": "افادیت",
    "Searching": "تلاش کر رہے ہیں",
    "Recipient Mobile Number": "وصول کنندہ کا موبائل نمبر",
    "TOP-UP SUMMARY": "ٹاپ اپ کا خلاصہ",
    "Trouble Requesting Money?": "پیسے بھیجنے میں پریشانی؟",
    "Choose The Beneficiary": "وصول کنندہ منتخب کریں",
    "Enter The amount": "رقم درج کریں",
    "You selected": "آپ نے منتخب کیا",
    "Timezone": "وقت کا علاقہ",
    "Starting From": "شروع سے",
    "Recurring Cycle": "تکراری سائیکل",
    "Until I stop": "جب تک میں روک نہ دوں",
    "Schedule To": "شیڈول کریں",
    "Schedule at": "شیڈول پر",
    "Selected time must be at least 5 minutes from now.": "منتخب کردہ وقت کم از کم 5 منٹ بعد کا ہونا چاہئے۔",
    "Is this your Location?": "کیا یہ آپ کا مقام ہے؟",
    "Yes ": "ہاں",
    "Select Remark Reason": "تبصرہ وجہ منتخب کریں",
    "Attach File": "فائل منسلک کریں",
    "Invite via Phone Number": "فون نمبر کے ذریعے مدعو کریں",
    "Invite Friend": "دوست کو مدعو کریں",
    "Invite via Email Address": "ای میل ایڈریس کے ذریعے مدعو کریں",
    "Invite via": "کے ذریعے مدعو کریں",
    "Phone": "فون:",
    "Message": "پیغام:",
    "Send": "بھیجیں",
    "Invited Succesfully!": "کامیابی سے مدعو کیا!",
    "Email": "ای میل",
    "Bank Transfer": "بینک ٹرانسفر",
    "Mobile Wallet": "موبائل والیٹ",
    "Select The Bank": "بینک منتخب کریں",
    "Select The Payer": "ادائیگی کرنے والے کو منتخب کریں",
    "Min Amount:": "کم سے کم رقم:",
    "Max Amount": "زیادہ سے زیادہ رقم",
    "Other Beneficiaries:": "دیگر مستفید کنندگان:",
    "Back ": "واپس",
    "Next": "اگلا",
    "Lookup": "تلاش کریں",
    "Invite Them": "انہیں مدعو کریں",
    "Delete Channel ": "چینل حذف کریں",
    "Set As Default": "ڈیفالٹ کے طور پر سیٹ کریں",
    "See More": "مزید دیکھیں",
    "Session Details": "سیشن کی تفصیلات",
    "Platform": "پلیٹ فارم",
    "Windows": "Windows",
    "Browser Name": "براؤزر کا نام",
    "Google Chrome": "Google Chrome",
    "Browser Version": "براؤزر کا ورژن",
    "IP Address": "آئی پی پتہ",
    "Karachi": "کراچی",
    "State": "صوبہ",
    "Sindh": "سندھ",
    "Active": "فعال",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "یہ کرنسی والیٹ اس وقت بلاک/غیر فعال ہے اور فنڈز شامل نہیں کیے جا سکتے۔",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "یہ کرنسی والیٹ اس وقت بلاک/غیر فعال ہے اور فنڈز کو تبدیل نہیں کیا جا سکتا۔",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "یہ کرنسی والیٹ اس وقت بلاک/غیر فعال ہے اور آپ بیلنس نکال نہیں سکتے۔",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "یہ کرنسی والیٹ اس وقت بلاک/غیر فعال ہے اور آپ بیان ڈاؤن لوڈ نہیں کر سکتے۔",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "یہ کرنسی والیٹ اس وقت آپ کی ڈیفالٹ سیٹ کی گئی ہے اور اسے بلاک نہیں کیا جا سکتا۔ آگے بڑھنے کے لیے، براہ کرم پہلے دوسرے کرنسی والیٹ کو ڈیفالٹ کے طور پر سیٹ کریں۔",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "یہ کرنسی والیٹ اس وقت آپ کی ڈیفالٹ سیٹ کی گئی ہے اور اسے غیر فعال نہیں کیا جا سکتا۔ آگے بڑھنے کے لیے، براہ کرم پہلے دوسرے کرنسی والیٹ کو ڈیفالٹ کے طور پر سیٹ کریں۔",
    "Are you sure, you want to delete the withdrawal details of this country?": "کیا آپ کو یقین ہے کہ آپ اس ملک کے واپسی کی تفصیلات حذف کرنا چاہتے ہیں؟",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "اپنے وصولی اکاؤنٹس تین ممالک میں سیٹ کریں اور منظور شدہ ادائیگی درخواستوں سے اپنے فعال ادائیگی چینلز پر فوری فنڈز نکالیں۔",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "پاکستان سے کوئی فائدہ حاصل کرنے والا نہیں ملا جس کے پاس Allied Bank Limited کی تفصیلات ہوں۔",
    "Invitation Message Has Been Successfully Sent To": "دعوت کا پیغام کامیابی سے بھیجا گیا",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "فیس اور وصول شدہ رقم منتخب ادائیگی کے طریقے پر منحصر ہو کر مختلف ہو سکتی ہے۔",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences، Mission Road، Moin Steel Market، Ranchore Line، کراچی ڈسٹرکٹ، کراچی ڈویژن، سندھ، 72500، پاکستان",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "اپنا QR کوڈ یا ادائیگی کا لنک شیئر کریں تاکہ آپ کو فوراً اپنے Insta والیٹ میں ادائیگی ہو سکے۔",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "فائل کو کھینچیں/ڈراپ کریں یا اپلوڈ کریں (تصویر/ویڈیو/PDF)",
    "Login Again": "دوبارہ لاگ ان کریں",
    "Unblock The Wallet": "والٹ کو ان بلاک کریں",
    "Invalid Amount": "غلط رقم",
    "Add Payment Card": "ادائیگی کارڈ شامل کریں",
    "Save Details To Use Next Time": "اگلی بار استعمال کے لیے تفصیلات محفوظ کریں",
    "Paid": "ادا کیا گیا",
    "Started On": "شروع کیا",
    "Num Of Cycles": "چکروں کی تعداد",
    "Ends On": "اختتام پر",
    "Next Payment At": "اگلی ادائیگی",
    "Beneficiary": "فائدہ اٹھانے والا",
    "Recieved Quotation": "موصول شدہ اقتباس",
    "Sent Quotation": "بھیجا گیا اقتباس",
    "Decline Offer": "پیشکش مسترد کریں",
    "Are You Sure That You Want To Decline The Offer?": "کیا آپ کو یقین ہے کہ آپ پیشکش مسترد کرنا چاہتے ہیں؟",
    "Decline The Offer": "پیشکش مسترد کریں",
    "Bargain Offer": "سودے بازی کی پیشکش",
    "Enter Counter Amount": "کاؤنٹر رقم درج کریں",
    "Custom Amount": "اپنی رقم",
    "Send The Offer": "پیشکش بھیجیں",
    "The Offer Is Sent": "پیشکش بھیج دی گئی",
    "Countered Offer": "کاؤنٹر پیشکش",
    "Applied": "درخواست کی",
    "Accept Quotation": "اقتباس قبول کریں",
    "Quotation Not Found Or Already Exist": "اقتباس نہیں ملا یا پہلے ہی موجود ہے",
    "Recieved Request": "موصول شدہ درخواست",
    "Sent Request": "بھیجی گئی درخواست",
    "Search Request": "درخواست تلاش کریں",
    "Search Quotation": "اقتباس تلاش کریں",
    "QR Status": "QR کی حیثیت",
    "QR Code Description": "QR کوڈ کی تفصیل",
    "Download Your QR Code": "اپنا QR کوڈ ڈاؤن لوڈ کریں",
    "Your QR Code": "آپ کا QR کوڈ",
    "Your QR Code Poster": "آپ کا QR کوڈ پوسٹر",
    "Download  ": "ڈاؤن لوڈ کریں",
    "Select Relation": "تعلق منتخب کریں",
    "Nominee Permanent Address": "نامزد کا مستقل پتہ",
    "Enter Your Message": "اپنا پیغام درج کریں",
    "Select": "منتخب کریں",
    "Select File Type": "فائل کی قسم منتخب کریں",
    "Send TOP-UP And E-Sim": "ٹاپ اپ اور ای سیم بھیجیں",
    "Search Users": "صارف تلاش کریں",
    "Select Payment Request Type": "ادائیگی کی درخواست کی قسم منتخب کریں",
    "Wallet To Wallet Transfer": "والیٹ سے والیٹ منتقلی",
    "Holiday List": "تعطیلات کی فہرست",
    "No Data To Show": "دکھانے کے لیے کوئی ڈیٹا نہیں",
    "They Recieve": "وہ وصول کرتے ہیں",
    "You Will Be Logged Out In 60 Seconds": "آپ 60 سیکنڈ میں لاگ آؤٹ ہو جائیں گے",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "ٹائمر کو 15 منٹ پر دوبارہ سیٹ کرنے کے لیے اپنا ماؤس ہلائیں یا کی بورڈ پر کوئی بھی بٹن دبائیں",
    "You've Been Logged Out": "آپ لاگ آؤٹ ہو چکے ہیں",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "آپ کی سیکیورٹی کے لیے، آپ کو 15 منٹ کی غیر فعالیت کے بعد لاگ آؤٹ کر دیا گیا ہے۔ براہ کرم دوبارہ لاگ ان کریں اگر آپ InstaPay استعمال کرنا چاہتے ہیں۔",
    "Cash": "نقد",
    "Business": "کاروبار",
    "Gift": "تحفہ",
    "Salary": "تنخواہ",
    "Lottery": "لاٹری",
    "Savings": "بچت",
    "Other": "دیگر",
    "Select Source Of Fund": "فنڈ کا ذریعہ منتخب کریں",
    "Select your source of funds": "اپنا فنڈ کا ذریعہ منتخب کریں",
    "Request Times Out. Please Try Again!": "درخواست کا وقت ختم ہوگیا۔ براہ کرم دوبارہ کوشش کریں!",
    "Resend Code In 100 Seconds": "100 سیکنڈ میں کوڈ دوبارہ بھیجیں",
    "Computer Service": "کمپیوٹر سروس",
    "Family Support": "خاندان کی حمایت",
    "Education": "تعلیم",
    "Gift And Other Donations": "تحفے اور دیگر عطیات",
    "Medical Treatment": "طبی علاج",
    "Maintenance Or Other Expenses": "نگہداشت یا دیگر اخراجات",
    "Travel": "سفر",
    "Small Value Remittance": "چھوٹے مالیت کی رقم کی ترسیل",
    "Liberalized Remittance": "لبرلائزڈ ترسیل",
    "Construction Expenses": "تعمیراتی اخراجات",
    "Hotel Accommodation": "ہوٹل کا قیام",
    "Advertising And/Or Public Relations Related Expenses": "تشہیر اور/یا عوامی تعلقات سے متعلق اخراجات",
    "Fees For Advisory Or Consulting Service": "مشورے یا مشاورتی خدمات کے لئے فیس",
    "Business Related Insurance Payment": "کاروباری متعلقہ انشورنس کی ادائیگی",
    "Insurance Claims Payment": "انشورنس کلیمز کی ادائیگی",
    "Delivery Fees": "ترسیل کی فیس",
    "Payments For Exported Goods": "برآمد شدہ سامان کے لیے ادائیگیاں",
    "Payment For Services": "خدمات کے لئے ادائیگیاں",
    "Payment Of Loans": "قرضوں کی ادائیگی",
    "Office Expenses": "دفتر کے اخراجات",
    "Residential Property Purchase": "رہائشی جائیداد کی خریداری",
    "Property Rental Payment": "جائیداد کرایہ کی ادائیگی",
    "Royalty, Trademark, Patent And Copyright Fees": "رائلٹی، ٹریڈ مارک، پیٹنٹ اور کاپی رائٹ فیس",
    "Investment In Shares": "شیئرز میں سرمایہ کاری",
    "Fund Investment": "فنڈ کی سرمایہ کاری",
    "Tax Payment": "ٹیکس کی ادائیگی",
    "Transportation Fees": "نقل و حمل کی فیس",
    "Utility Bills": "یوٹیلیٹی بل",
    "Personal Transfer": "ذاتی منتقلی",
    "Payment Of Salary": "تنخواہ کی ادائیگی",
    "Payment Of Rewards": "انعامات کی ادائیگی",
    "Payment Of Influencer": "مؤثر شخص کو ادائیگی",
    "Broker, Commitment, Guarantee And Other Fees": "بروکر، عہد، ضمانت اور دیگر فیسیں",
    "Other Purposes": "دیگر مقاصد",
    "Aunt": "خالہ",
    "Brother": "بھائی",
    "Brother-In-Law": "سالہ",
    "Cousin": "کزن",
    "Daughter": "بیٹی",
    "Father": "والد",
    "Father-In-Law": "سسر",
    "Friend": "دوست",
    "Grandfather": "دادا",
    "Grandmother": "دادی",
    "Husband": "شوہر",
    "Mother": "ماں",
    "Mother-In-Law": "ساس",
    "Nephew": "بھتیجا",
    "Niece": "بھتیجی",
    "Self (i.e. The Sender, Himself)": "خود (یعنی بھیجنے والا خود)",
    "Sister": "بہن",
    "Sister-In-Law": "نند",
    "Son": "بیٹا",
    "Uncle": "چچا",
    "Wife": "بیوی",
    "Others Not Listed": "دیگر جو درج نہیں ہیں",
    "Passport": "پاسپورٹ",
    "National Identification Card": "قومی شناختی کارڈ",
    "Driving License": "ڈرائیونگ لائسنس",
    "Social Security Card/Number": "سوشل سیکیورٹی کارڈ/نمبر",
    "Tax Payer Identification Card/Number": "ٹیکس دہندہ شناختی کارڈ/نمبر",
    "Senior Citizen Identification Card": "سینئر سٹیزن شناختی کارڈ",
    "Birth Certificate": "ولادت کا سرٹیفیکیٹ",
    "Village Elder Identification Card": "گاؤں بزرگوں کی شناختی کارڈ",
    "Permanent Residency Identification Card": "مستقل رہائش شناختی کارڈ",
    "Alien Registration Certificate/Card": "غیر ملکی رجسٹریشن سرٹیفکیٹ/کارڈ",
    "PAN Card": "پین کارڈ",
    "Voter’s Identification Card": "ووٹر شناختی کارڈ",
    "Health Insurance Card/Number": "ہیلتھ انشورنس کارڈ/نمبر",
    "Employer Identification Card": "آجر شناختی کارڈ",
    "Others Not Listed": "دیگر غیر فہرست شدہ",
    "Bank Account": "بینک اکاؤنٹ",
    "Cash Pickup": "نقد رقم کی وصولی",
    "Card": "کارڈ",
    "Arts & Entertainment": "آرٹس اور تفریح",
    "Banking & Finance": "بینکنگ اور فنانس",
    "Education & Research": "تعلیم اور تحقیق",
    "Engineering & Construction": "انجینئرنگ اور تعمیرات",
    "Healthcare & Medicine": "صحت کی دیکھ بھال اور طب",
    "Information Technology & Services": "انفارمیشن ٹیکنالوجی اور خدمات",
    "Legal & Compliance": "قانونی اور تعمیل",
    "Manufacturing & Production": "مینوفیکچرنگ اور پیداوار",
    "Marketing & Sales": "مارکیٹنگ اور سیلز",
    "Non-profit & Charity": "غیر منافع بخش اور خیرات",
    "Real Estate & Property": "رئیل اسٹیٹ اور جائیداد",
    "Retail & Wholesale": "خوردہ اور تھوک",
    "Science & Pharmaceuticals": "سائنس اور فارماسیوٹیکلز",
    "Transport & Logistics": "نقل و حمل اور لاجسٹک",
    "Travel & Tourism": "سفر اور سیاحت",
    "Unemployed": "بے روزگار",
    "Other": "دیگر",
    "Cash": "نقد رقم",
    "Business": "کاروبار",
    "Gift": "تحفہ",
    "Salary": "تنخواہ",
    "Lottery": "لاٹری",
    "Savings": "بچت",
    "Good Morning": "صبح بخیر",
    "Good Evening": "شام بخیر",
    "Completed": "مکمل",
    "Load/Deposit Money To The Wallet": "ادائیگی کے لیے آگے بڑھیں",
    "Enter The Amount": "رقم درج کریں",
    "Choose The Top-Up Channel": "ٹاپ اپ چینل کا انتخاب کریں",
    "Good Night!": "شب بخیر!",
    "You Will Get": "اپنی ادائیگی کی تصدیق کریں",
    "Amount To Pay": "آپ کو ملے گا",
    "Top-Up Wallet": "ادائیگی کا طریقہ",
    "Card Holder Name ": "کارڈ ہولڈر کا نام",
    "Card Number": "کارڈ نمبر",
    "CVC": "سی وی سی",
    "MM/YY": "ایم ایم/سال",
    "Save Details To Use Next Time": "اگلی بار استعمال کے لیے تفصیلات محفوظ کریں",
    "Select Or Add Payment Card": "ادائیگی کا کارڈ منتخب کریں یا شامل کریں",
    "Your Details ": "آپ کی تفصیلات",
    "You Selected": "آپ نے منتخب کیا",
    "Detailed Confirmed": "تفصیلی تصدیق شدہ",
    "Transaction Failed": "ٹرانزیکشن ناکام ہوگیا",
    "Something Went Wrong. Try Again.": "کچھ غلط ہو گیا۔ دوبارہ کوشش کریں۔",
    "PDF": "پی ڈی ایف",
    "CSV": "سی ایس وی",
    "Failed": "ناکام",
    "Initiated": "آغاز کیا",
    "Revise": "نظر ثانی کریں",
    "Declined": "مسترد",
    "Bargain-Accepted": "مذاکرات-قبول",
    "Accepted": "قبول",
    "Decline The Offer": "آفر مسترد کریں",
    "Pending": "زیر التواء",
    "Cancelled": "منسوخ",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "اپنے گاہک یا صارف کو ایک تفصیلی قیمت کا حوالہ بھیجیں۔ اپنے خدمات، مصنوعات یا پیشکشوں کو ایک ذاتی قیمت کے ساتھ پیشہ ورانہ طور پر پیش کریں۔",
    "Wallet to wallet": "والیٹ سے والیٹ",
    "Please follow these examples:": "براہ کرم ان مثالوں کی پیروی کریں:",
    "👤 InstaPay/Instagram Username: instauser": "👤 InstaPay/Instagram یوزر نیم: instauser",
    "📧 Email: user@email.com": "📧 ای میل: user@email.com",
    "Full Name": "مکمل نام",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "InstaPay میں خوش آمدید! اس بات کو یقینی بنانے کے لیے کہ آپ وصول کردہ رقم کو فوراً نکال سکتے ہیں، آپ کو ترتیبات میں اپنے وصولی والے اکاؤنٹس کو سیٹ اپ کرنا ہوگا۔ اس سے آپ اپنی پسندیدہ بینک اکاؤنٹس، موبائل والیٹس، کرپٹو والیٹس، یا نقد رقم اٹھانے کے مقامات پر بآسانی رقم منتقل کر سکیں گے۔",
    "You Have No Beneficiary": "آپ کے پاس کوئی مستفید نہیں ہے",
    "Add Now": "ابھی شامل کریں",
    "Wallet-Status": "والٹ کی حیثیت",
    "Select Payment Card": "ادائیگی کارڈ منتخب کریں",
    "Your funds have been credited to your wallet.": "آپ کے فنڈز آپ کے والٹ میں جمع کر دیے گئے ہیں۔",
    "Thanks for using InstaPay": "InstaPay استعمال کرنے کا شکریہ",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "فوراً ادائیگی حاصل کریں: اپنے وصولی اکاؤنٹس ابھی سیٹ کریں!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "اپنے وصولی کے اکاؤنٹس کو سیٹ اپ کرنے اور یہ یقینی بنانے کے لیے ان آسان مراحل پر عمل کریں کہ آپ ہمیشہ اپنے فنڈز تک رسائی کے لیے تیار ہیں:",
    "STEP 01": "مرحلہ 01",
    "Go to Settings": "سیٹنگز پر جائیں",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "اپنا InstaPay ایپ کھولیں اور \"سیٹنگز\" سیکشن پر جائیں۔",
    "STEP 02": "مرحلہ 02",
    "Select Receiving Accounts": "وصولی کے اکاؤنٹس منتخب کریں",
    "Tap on \"Receiving Accounts\" to start adding your details.": "اپنی تفصیلات شامل کرنے کے لیے \"وصولی کے اکاؤنٹس\" پر ٹیپ کریں۔",
    "Start Adding Accounts": "اکاؤنٹس شامل کرنا شروع کریں",
    "Phone Number: 44795396600 (With Country Code)": "فون نمبر: 44795396600 (ملک کوڈ کے ساتھ)",
    "Select Country First": "پہلے ملک منتخب کریں",
    "First Name": "پہلا نام",
    "Last Name": "آخری نام",
    "Address Line": "پتہ لائن",
    "Email Address": "ای میل پتہ",
    "Select City": "شہر منتخب کریں",
    "Select Gender": "جنس منتخب کریں",
    "Select Occupation": "پیشہ منتخب کریں",
    "Male": "مرد",
    "Female": "عورت",
    "Non-Binary": "غیر بائنری",
    "Prefer Not To Say'": "نہیں کہنا چاہتا",
    "Bitcoin (BTC)": "بٹ کوائن (BTC)",
    "Tether (USDT)": "ٹیتر (USDT)",
    "Ethereum (ETH)": "ایتھیریم (ETH)",
    "No User Found": "کوئی صارف نہیں ملا",
    "Email Address ": "ای میل ایڈریس",
    "Select Document": "دستاویز منتخب کریں",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "اپنے کام کو پیش کریں، اپنی پہنچ کو بڑھائیں۔ 'میرا پورٹ فولیو' آپ کا ڈیجیٹل کینوس ہے Instapay پر۔ دلکش تصاویر اور ویڈیوز سے لے کر معلوماتی PDFs تک مختلف قسم کے مواد کو اپ لوڈ اور دکھائیں۔ چاہے آپ ایک آرٹسٹ ہوں، فری لانسر ہوں یا کاروباری ہوں، یہ خصوصیت آپ کو اپنے کام یا منصوبوں کو بصری طور پر دلکش طریقے سے پیش کرنے کی اجازت دیتی ہے۔",
    "Add New": "نیا شامل کریں",
    "Money Out": "رقم نکالیں",
    "Browsername": "براؤزر کا نام",
    "Browserversion": "براؤزر کا ورژن",
    "Credit": "کریڈٹ",
    "Debit": "ڈیبٹ",
    "Top Up": "چارج کریں",
    "Wallet To Wallet": "والٹ سے والٹ",
    "Accountant": "محاسب",
    "Architect": "معمار",
    "Artist": "فنکار",
    "Banker": "بینکار",
    "Business Owner": "کاروباری مالک",
    "Consultant": "مشیر",
    "Doctor": "ڈاکٹر",
    "Engineer": "انجینئر",
    "Farmer": "کسان",
    "Government Employee": "سرکاری ملازم",
    "IT Professional": "آئی ٹی پیشہ ور",
    "Lawyer": "وکیل",
    "Nurse": "نرس",
    "Retailer": "پرچون فروش",
    "Salesperson": "سیلز پرسن",
    "Student": "طالب علم",
    "Teacher": "استاد",
    "Prefer Not to Say": "کہنے سے گریز کریں",
    "Timezone": "وقت کا علاقہ",
    "Subscription Payment": "سبسکرپشن ادائیگی",
    "Starting From": "شروع سے",
    "Recurring Cycle": "تکراری سائیکل",
    "Until I stop": "جب تک میں روک نہ دوں",
    "Schedule To": "شیڈول کریں",
    "Schedule at": "شیڈول پر",
    "Selected time must be at least 5 minutes from now.": "منتخب کردہ وقت کم از کم 5 منٹ بعد کا ہونا چاہئے۔",
    "They Recieve": "وہ وصول کرتے ہیں",
    "Attach Files": "فائلیں منسلک کریں",
    "Wallet Selected": "والیٹ منتخب",
    "You Have Selected PKR Currency Wallet For This Transaction": "آپ نے اس لین دین کے لیے PKR کرنسی والیٹ منتخب کیا ہے",
    "Instant": "فوری",
    "Subscription": "سبسکرپشن",
    "Schedule": "شیڈول",
    "Sms/Email Verification Code": "SMS/ای میل تصدیق کوڈ",
    "Confirm Your Password": "اپنے پاس ورڈ کی تصدیق کریں",
    "This Security Feature Requires Password Confirmation!": "اس حفاظتی خصوصیت کے لیے پاس ورڈ کی تصدیق کی ضرورت ہے!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "QR کوڈ کو اسکین کرنے کے لیے ایپلیکیشن کا استعمال کریں یا Google دو فیکٹر کی تصدیق کو فعال کرنے کے لیے اپنے خفیہ کوڈ کا استعمال کریں",
    "OR": "یا",
    "Paste This Key In The Googe Authenticator App": "اس کلید کو Google Authenticator ایپ میں پیسٹ کریں",
    "Show Secret Key": "خفیہ کلید دکھائیں",
    "Enter The Six-Digit Code From The Application": "ایپلیکیشن سے چھ ہندسوں کا کوڈ درج کریں",
    "6 Digit Code": "6 ہندسوں کا کوڈ",
    "Enable": "فعال کریں",
    "Disable": "غیر فعال کریں",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "بار کوڈ کی تصویر اسکین کرنے کے بعد، ایپ ایک چھ ہندسوں کا کوڈ دکھائے گی جسے آپ نیچے درج کر سکتے ہیں",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "Google دو فیکٹر کی تصدیق اب آپ کے Instapay اکاؤنٹ سے منسلک ہو گئی ہے",
    "Min Amount:": "کم سے کم رقم:",
    "Minimum Amount": "کم از کم رقم",
    "Maximum Amount": "زیادہ سے زیادہ رقم",
    "Identity Verification Required": "شناخت کی تصدیق کی ضرورت ہے",
    "Select Your Wallet Currency": "اپنے والیٹ کرنسی کا انتخاب کریں",
    "Setup Authenticator": "مستند کار سیٹ اپ کریں",
    "Note": "نوٹ",
    "This Is A Test Notice": "یہ ایک ٹیسٹ نوٹس ہے",
    "Session Expired": "سیشن ختم ہو گیا",
    "You have been logged out for security reasons, please login again to continue.": "آپ کو سیکیورٹی وجوہات کی بنا پر لاگ آؤٹ کر دیا گیا ہے، براہ کرم جاری رکھنے کے لیے دوبارہ لاگ ان کریں۔",
    "Redirecting You To Login Page In 5 Seconds": "آپ کو 5 سیکنڈ میں لاگ ان صفحے پر منتقل کیا جا رہا ہے",
    "Searching": "تلاش کر رہے ہیں",
    "Recipient Mobile Number": "وصول کنندہ کا موبائل نمبر",
    "TOP-UP SUMMARY": "ٹاپ اپ کا خلاصہ",
    "Amount": "رقم",
    "Something went wrong while getting countries.": "ممالک حاصل کرتے وقت کچھ غلط ہو گیا۔",
    "Select Destination Country": "منزل کا ملک منتخب کریں۔",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR کو Paypal کے ذریعہ تعاون نہیں کیا جاتا ہے، USD کو ڈیفالٹ کرنسی کے طور پر استعمال کیا جائے گا۔",
    "Select A Date": "ایک تاریخ منتخب کریں",
    "Select A Timezone": "ایک ٹائم زون منتخب کریں",
    "Proceed": "جاری رکھیں",
    "Proceeding": "جاری ہے",
    "You Can Now Close This Webpage And Go Back To Instagram": "آپ اب اس ویب صفحہ کو بند کر سکتے ہیں اور انسٹاگرام پر واپس جا سکتے ہیں",
    "Select A Time": "ایک وقت منتخب کریں",
    "Location Permission Denied": "مقام کی اجازت مسترد کر دی گئی",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "پہلے اجازت کو مسترد کرنے کے بعد اپنے براؤزر میں مقام خدمات کو فعال کرنے کے لیے نیچے دی گئی ہدایات پر عمل کریں:",
    "1: Chrome Browser": "1: کروم براؤزر",
    "Open the Chrome app.": "کروم ایپ کھولیں۔",
    "Tap the three-dot menu (top-right corner) and select Settings.": "اوپر دائیں کونے میں تین نقطوں والے مینو پر ٹیپ کریں اور سیٹنگز منتخب کریں۔",
    "Scroll down to Site Settings > Location.": "سائٹ سیٹنگز > مقام تک نیچے سکرول کریں۔",
    "Locate the website in the list or search for it.": "فہرست میں ویب سائٹ تلاش کریں یا اسے تلاش کریں۔",
    "Tap the website and select Allow under Location Access.": "ویب سائٹ پر ٹیپ کریں اور مقام تک رسائی کے تحت اجازت دیں کو منتخب کریں۔",
    "2: Firefox Browser": "2: فائر فاکس براؤزر",
    "Open the Firefox app.": "فائر فاکس ایپ کھولیں۔",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "نیچے دائیں کونے میں تین لائنوں والے مینو پر ٹیپ کریں اور سیٹنگز پر جائیں۔",
    "Select Privacy & Security > Site Permissions > Location.": "پرائیویسی اور سیکیورٹی > سائٹ کی اجازتیں > مقام کو منتخب کریں۔",
    "Find the website in the list and set the permission to Allow.": "فہرست میں ویب سائٹ تلاش کریں اور اجازت کو اجازت دیں پر سیٹ کریں۔",
    "3: Samsung Internet Browser": "3: سام سنگ انٹرنیٹ براؤزر",
    "Open the Samsung Internet app.": "سام سنگ انٹرنیٹ ایپ کھولیں۔",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "نیچے دائیں کونے میں تین لائنوں والے مینو پر ٹیپ کریں اور سیٹنگز منتخب کریں۔",
    "Go to Sites and Downloads > Site Permissions > Location.": "سائٹس اور ڈاؤن لوڈز > سائٹ کی اجازتیں > مقام پر جائیں۔",
    "Find the website and change the permission to Allow.": "ویب سائٹ تلاش کریں اور اجازت کو اجازت دیں میں تبدیل کریں۔",
    "4: Safari Browser": "4: سفاری براؤزر",
    "Open the Settings app.": "سیٹنگز ایپ کھولیں۔",
    "Scroll down and select Safari.": "نیچے سکرول کریں اور سفاری منتخب کریں۔",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "مقام پر ٹیپ کریں اور اسے اگلی بار پوچھیں یا ایپ استعمال کرتے ہوئے اجازت دیں پر سیٹ کریں۔",
    "Revisit the website and when prompt, allow location access": "ویب سائٹ پر دوبارہ جائیں اور جب اشارہ کیا جائے، تو مقام تک رسائی کی اجازت دیں۔",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "مشورہ: اگر ویب سائٹ براؤزر کی سیٹنگز میں ظاہر نہیں ہوتی، تو اپنے براؤزر کا کیش یا تاریخ صاف کریں اور دوبارہ کوشش کریں۔",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "ادائیگی کی درخواست بھیجنے کے لیے، براہ کرم اپنا مقام شیئر کریں—یہ ہمیں سیکیورٹی کو بڑھانے اور ہموار تجربے کے لیے اعتماد پیدا کرنے میں مدد دیتا ہے!",
    "Decline": "مسترد کریں",
    "Share Location": "مقام شیئر کریں",
      "Already Exist": "پہلے سے موجود ہے",
  "Withdraw": "نکالنا",
  "Seller Left A reply": "بیچنے والے نے جواب چھوڑا",
  "Check Now": "ابھی چیک کریں",
  "You Left A Review": "آپ نے جائزہ چھوڑا",
  "View Larger Map": "بڑا نقشہ دیکھیں",
  "English (UK)": "انگریزی (برطانیہ)",
  "QR Code Title": "کیو آر کوڈ کا عنوان",
  "Notice!": "نوٹس!",
  "By proceeding with this transaction, I hereby confirm that I am acting independently and of my own free will. I affirm that I have not been influenced, coached, coerced, or persuaded by any third party to carry out this transaction for investment purposes or any other reason.": "اس لین دین کو جاری رکھتے ہوئے، میں اس بات کی تصدیق کرتا ہوں کہ میں آزادانہ طور پر اور اپنی مرضی سے کام کر رہا ہوں۔ میں تصدیق کرتا ہوں کہ مجھے کسی تیسرے فریق نے سرمایہ کاری کے مقصد یا کسی اور وجہ سے اس لین دین کو انجام دینے کے لیے متاثر، سکھایا، مجبور یا قائل نہیں کیا ہے۔",
  "I understand that this confirmation is required to ensure compliance and to safeguard my account and transactions.": "میں سمجھتا ہوں کہ اس تصدیق کی ضرورت تعمیل کو یقینی بنانے اور میرے اکاؤنٹ اور لین دین کی حفاظت کے لیے ہے۔",
  "Confirm and Proceed": "تصدیق کریں اور جاری رکھیں",
  "Blocked Wallet": "مسدود شدہ بٹوا",
  "Nominee's Full Name": "نامزد شخص کا مکمل نام",
  "Insufficient Balance": "کافی بیلنس نہیں",
  "Upload Photo": "تصویر اپ لوڈ کریں",
  "Security Alert": "سیکیورٹی الرٹ",
  "Please remember to add your security questions for smooth experience. You will not be able to access the features of InstaPay unless You add the security questions.": "براہ کرم ہموار تجربے کے لیے اپنے سیکیورٹی سوالات شامل کرنا یاد رکھیں۔ جب تک آپ سیکیورٹی سوالات شامل نہیں کریں گے، آپ InstaPay کی خصوصیات تک رسائی حاصل نہیں کر سکیں گے۔",
  "Setup Security Questions": "سیکیورٹی سوالات سیٹ کریں",
  "Geo Fencing": "جغرافیائی باڑ بندی",
  "Manage Payment & Quotation Access": "ادائیگی اور قیمت کی رسائی کا نظم کریں",
  "Control countries from where you can receive Payment Requests and Quotations. Toggle on/off access for specific countries.": "ان ممالک کو کنٹرول کریں جہاں سے آپ ادائیگی کی درخواستیں اور قیمتیں وصول کرسکتے ہیں۔ مخصوص ممالک کے لیے رسائی آن/آف کریں۔",
  "Search Countries ": "ممالک تلاش کریں",
  "Filter": "فلٹر",
  "Payment Requests": "ادائیگی کی درخواستیں",
  "#No": "#نمبر",
  "Country": "ملک",
  "Account’s Country": "اکاؤنٹ کا ملک",
  "Current Location": "موجودہ مقام",
  "Save Changes": "تبدیلیاں محفوظ کریں",
  "Asia": "ایشیا",
  "Europe": "یورپ",
  "Africa": "افریقہ",
  "Oceania": "اوشیانا",
  "Americas": "امریکہ",
  "Polar": "قطبی",
  "Finger Biometric": "فنگر بایومیٹرک",
  "Confirm": "تصدیق کریں",
  "Resend available in 77 seconds": "دوبارہ بھیجنا 77 سیکنڈ میں دستیاب ہوگا",
  "Invalid OTP!": "غلط OTP!",
  "Finger Biometric Updated": "فنگر بایومیٹرک اپڈیٹ ہو گیا",
  "Your finger biometric has been disabled successfully!": "آپ کا فنگر بایومیٹرک کامیابی کے ساتھ غیر فعال کر دیا گیا ہے!",
  "Your finger biometric has been enabled successfully!": "آپ کا فنگر بایومیٹرک کامیابی کے ساتھ فعال کر دیا گیا ہے!",
  "Afghanistan": "افغانستان",
  "Armenia": "آرمینیا",
  "Azerbaijan": "آذربائیجان",
  "Bahrain": "بحرین",
  "Bangladesh": "بنگلہ دیش",
  "Bhutan": "بھوٹان",
  "Brunei": "برونائی",
  "Cambodia": "کمبوڈیا",
  "China": "چین",
  "East Timor": "مشرقی تیمور",
  "Georgia": "جارجیا",
  "Hong Kong S.A.R.": "ہانگ کانگ خصوصی انتظامی علاقہ",
  "India": "بھارت",
  "Indonesia": "انڈونیشیا",
  "Iran": "ایران",
  "Iraq": "عراق",
  "Israel": "اسرائیل",
  "Japan": "جاپان",
  "Jordan": "اردن",
  "Kazakhstan": "قازقستان",
  "Kuwait": "کویت",
  "Kyrgyzstan": "کرغزستان",
  "Laos": "لاؤس",
  "Lebanon": "لبنان",
  "Macau S.A.R.": "مکاؤ خصوصی انتظامی علاقہ",
  "Malaysia": "ملائیشیا",
  "Maldives": "مالدیپ",
  "Mongolia": "منگولیا",
  "Myanmar": "میانمار",
  "Nepal": "نیپال",
  "North Korea": "شمالی کوریا",
  "Oman": "عمان",
  "Palestinian Territory Occupied": "فلسطینی مقبوضہ علاقہ",
  "Philippines": "فلپائن",
  "Qatar": "قطر",
  "Saudi Arabia": "سعودی عرب",
  "Singapore": "سنگاپور",
  "South Korea": "جنوبی کوریا",
  "Sri Lanka": "سری لنکا",
  "Syria": "شام",
  "Taiwan": "تائیوان",
  "Tajikistan": "تاجکستان",
  "Thailand": "تھائی لینڈ",
  "Turkey": "ترکی",
  "Turkmenistan": "ترکمانستان",
  "United Arab Emirates": "متحدہ عرب امارات",
  "Uzbekistan": "ازبکستان",
  "Vietnam": "ویتنام",
  "Yemen": "یمن",
  "Åland Islands": "آلینڈ جزائر",
  "Albania": "البانیا",
  "Andorra": "انڈورا",
  "Austria": "آسٹریا",
  "Belarus": "بیلاروس",
  "Belgium": "بیلجیم",
  "Bosnia and Herzegovina": "بوسنیا اور ہرزیگووینا",
  "Bulgaria": "بلغاریہ",
  "Croatia": "کروشیا",
  "Cyprus": "قبرص",
  "Czech Republic": "چیک جمہوریہ",
  "Denmark": "ڈنمارک",
  "Estonia": "ایسٹونیا",
  "Faroe Islands": "فیرو جزائر",
  "Finland": "فن لینڈ",
  "France": "فرانس",
  "Germany": "جرمنی",
  "Gibraltar": "جبرالٹر",
  "Greece": "یونان",
  "Guernsey and Alderney": "گرنزی اور ایلڈرنی",
  "Hungary": "ہنگری",
  "Iceland": "آئس لینڈ",
  "Ireland": "آئرلینڈ",
  "Italy": "اٹلی",
  "Jersey": "جرسی",
  "Kosovo": "کوسوو",
  "Latvia": "لٹویا",
  "Liechtenstein": "لچٹنسٹائن",
  "Lithuania": "لتھوانیا",
  "Luxembourg": "لکسمبرگ",
  "Malta": "مالٹا",
  "Isle of Man": "آئل آف مین",
  "Moldova": "مالدووا",
  "Monaco": "موناکو",
  "Montenegro": "مونٹی نیگرو",
  "Netherlands": "نیدرلینڈ",
  "North Macedonia": "شمالی مقدونیہ",
  "Norway": "ناروے",
  "Poland": "پولینڈ",
  "Portugal": "پرتگال",
  "Romania": "رومانیہ",
  "Russia": "روس",
  "San Marino": "سان مارینو",
  "Serbia": "سربیا",
  "Slovakia": "سلوواکیا",
  "Slovenia": "سلووینیا",
  "Spain": "اسپین",
  "Svalbard and Jan Mayen Islands": "سوالبارڈ اور جان ماین جزائر",
  "Sweden": "سویڈن",
  "Switzerland": "سوئٹزرلینڈ",
  "Ukraine": "یوکرین",
  "United Kingdom": "متحدہ سلطنت",
  "Vatican City": "ویٹیکن سٹی",
  "Algeria": "الجیریا",
  "Angola": "انگولا",
  "Benin": "بینن",
  "Botswana": "بوٹسوانا",
  "British Indian Ocean Territory": "برٹش انڈین اوشین ٹیریٹری",
  "Burkina Faso": "برکینا فاسو",
  "Burundi": "برونڈی",
  "Cameroon": "کیمرون",
  "Cape Verde": "کیپ وردے",
  "Central African Republic": "وسطی افریقی جمہوریہ",
  "Chad": "چاڈ",
  "Comoros": "کوموروس",
  "Congo": "کانگو",
  "Côte d'Ivoire (Ivory Coast)": "کوٹ ڈی آئیوری (آئیوری کوسٹ)",
  "Democratic Republic of the Congo": "جمہوری جمہوریہ کانگو",
  "Djibouti": "جبوتی",
  "Egypt": "مصر",
  "Equatorial Guinea": "استوائی گنی",
  "Eritrea": "اریٹریا",
  "Ethiopia": "ایتھوپیا",
  "French Southern Territories": "فرانسیسی جنوبی علاقے",
  "Gabon": "گیبون",
  "Gambia": "گیمبیا",
  "Ghana": "گھانا",
  "Guinea": "گنی",
  "Guinea-Bissau": "گنی بساؤ",
  "Kenya": "کینیا",
  "Lesotho": "لیسوتھو",
  "Liberia": "لائبیریا",
  "Libya": "لیبیا",
  "Madagascar": "مڈغاسکر",
  "Malawi": "ملاوی",
  "Mali": "مالی",
  "Mauritania": "موریطانیہ",
  "Mauritius": "ماریشس",
  "Mayotte": "مایوٹ",
  "Morocco": "مراکش",
  "Mozambique": "موزمبیق",
  "Namibia": "نامیبیا",
  "Niger": "نائجر",
  "Nigeria": "نائیجیریا",
  "Réunion": "ری یونین",
  "Rwanda": "روانڈا",
  "Saint Helena": "سینٹ ہیلینا",
  "São Tomé and Príncipe": "ساؤ ٹومے اور پرنسپے",
  "Senegal": "سینیگال",
  "Seychelles": "سیشلز",
  "Sierra Leone": "سیرالیون",
  "Somalia": "صومالیہ",
  "South Africa": "جنوبی افریقہ",
  "South Sudan": "جنوبی سوڈان",
  "Sudan": "سوڈان",
  "Swaziland (Eswatini)": "ایسواتینی",
  "Tanzania": "تنزانیہ",
  "Togo": "ٹوگو",
  "Tunisia": "تیونس",
  "Uganda": "یوگنڈا",
  "Western Sahara": "مغربی صحارا",
  "Zambia": "زیمبیا",
  "Zimbabwe": "زمبابوے",
  "American Samoa": "امریکی ساموا",
  "Australia": "آسٹریلیا",
  "Christmas Island": "کرسمس آئی لینڈ",
  "Cocos (Keeling) Islands": "کوکوس (کیلنگ) جزائر",
  "Cook Islands": "کک جزائر",
  "Fiji": "فجی",
  "French Polynesia": "فرانسیسی پولینیشیا",
  "Guam": "گوام",
  "Kiribati": "کیریباتی",
  "Marshall Islands": "مارشل جزائر",
  "Micronesia": "مائیکرونیشیا",
  "Nauru": "ناورو",
  "New Caledonia": "نیو کیلیڈونیا",
  "New Zealand": "نیوزی لینڈ",
  "Niue": "نیوے",
  "Norfolk Island": "نورفوک جزیرہ",
  "Northern Mariana Islands": "شمالی ماریانا جزائر",
  "Palau": "پلاؤ",
  "Papua New Guinea": "پاپوا نیو گنی",
  "Pitcairn Islands": "پٹکیرن جزائر",
  "Samoa": "ساموآ",
  "Solomon Islands": "سولومن جزائر",
  "Tokelau": "ٹوکیلاو",
  "Tonga": "ٹونگا",
  "Tuvalu": "تووالو",
  "Vanuatu": "وانوآتو",
  "Wallis and Futuna": "والس اور فوتونا",
  "North & South America": "شمالی اور جنوبی امریکہ",
  "Anguilla": "انگویلا",
  "Antigua and Barbuda": "اینٹیگوا اور باربوڈا",
  "Argentina": "ارجنٹینا",
  "Aruba": "اروبا",
  "Barbados": "بارباڈوس",
  "Belize": "بیلیز",
  "Bermuda": "برمودا",
  "Bolivia": "بولیویا",
  "Bonaire, Sint Eustatius, and Saba": "بونائیر، سینٹ یوسٹیٹیئس، اور صبا",
  "Brazil": "برازیل",
  "Canada": "کینیڈا",
  "Cayman Islands": "کیمین جزائر",
  "Chile": "چلی",
  "Colombia": "کولمبیا",
  "Costa Rica": "کوسٹا ریکا",
  "Cuba": "کیوبا",
  "Curaçao": "کیوراساؤ",
  "Dominica": "ڈومینیکا",
  "Dominican Republic": "ڈومینیکن جمہوریہ",
  "Ecuador": "ایکواڈور",
  "El Salvador": "ایل سلواڈور",
  "Falkland Islands": "فاک لینڈ جزائر",
  "French Guiana": "فرانسیسی گیانا",
  "Greenland": "گرین لینڈ",
  "Grenada": "گریناڈا",
  "Guadeloupe": "گواڈیلوپ",
  "Guatemala": "گوئٹے مالا",
  "Guyana": "گیانا",
  "Haiti": "ہیٹی",
  "Honduras": "ہونڈوراس",
  "Jamaica": "جمیکا",
  "Martinique": "مارٹینک",
  "Mexico": "میکسیکو",
  "Montserrat": "مونٹسیراٹ",
  "Nicaragua": "نکاراگوا",
  "Panama": "پانامہ",
  "Paraguay": "پیراگوئے",
  "Peru": "پیرو",
  "Puerto Rico": "پورٹو ریکو",
  "Saint Kitts and Nevis": "سینٹ کٹس اور نیوس",
  "Saint Lucia": "سینٹ لوشیا",
  "Saint Pierre and Miquelon": "سینٹ پیئر اور میکلون",
  "Saint Vincent and the Grenadines": "سینٹ ونسنٹ اور گریناڈائنز",
  "Saint Barthélemy": "سینٹ بارتھیلمی",
  "Saint Martin (French part)": "سینٹ مارٹن (فرانسیسی حصہ)",
  "Sint Maarten (Dutch part)": "سنت مارٹن (ڈچ حصہ)",
  "South Georgia and the South Sandwich Islands": "جنوبی جارجیا اور جنوبی سینڈوچ جزائر",
  "Suriname": "سورینام",
  "The Bahamas": "بہاماس",
  "Trinidad and Tobago": "ٹرینیڈاڈ اور ٹوباگو",
  "Turks and Caicos Islands": "ترکس اور کیکوس جزائر",
  "United States": "ریاستہائے متحدہ",
  "United States Minor Outlying Islands": "ریاستہائے متحدہ کے چھوٹے بیرونی جزائر",
  "Uruguay": "یوروگوئے",
  "Venezuela": "وینزویلا",
  "British Virgin Islands": "برطانوی ورجن جزائر",
  "U.S. Virgin Islands": "امریکی ورجن جزائر",
  "Antarctica": "انٹارکٹیکا",
  "Your Preferences Updated Successfully": "آپ کی ترجیحات کامیابی کے ساتھ اپ ڈیٹ ہوگئی ہیں"
}