export const id = {
     "How Others Can Find You": "Bagaimana Orang Lain Bisa Menemukan Anda",
     "Schedules": "Jadwal",
     "Login": "Masuk",
     "Start Your InstaPay Journey Today!": "Mulai Perjalanan InstaPay Anda Hari Ini!",
     "Phone Number or Email": "Nomor Telepon atau Email",
     "Login Using Social Media": "Masuk dengan Media Sosial",
     "Continue": "Lanjutkan",
     "Don't Have an Account? Sign Up": "Belum Punya Akun? Daftar",
     "Sign Up": "Daftar",
     "Edit": "Edit",
     "Enter Your Password": "Masukkan Kata Sandi Anda",
     "Forgot Password?": "Lupa Kata Sandi?",
     "Account Type": "Jenis Akun",
     "Individual": "Individu",
     "Business": "Bisnis",
     "Already Have an Account? Sign In": "Sudah Punya Akun? Masuk",
     "Sing In": "Masuk",
     "Enter Your Personal Details": "Masukkan Detail Pribadi Anda",
     "Add Photo or Logo": "Tambahkan Foto atau Logo",
     "Enter Your Name": "Masukkan Nama Anda",
     "Back": "Kembali",
     "Phone Number": "Nomor Telepon",
     "Email Address": "Alamat Email",
     "Create a Strong Password": "Buat Kata Sandi Kuat",
     "Password Requirements": "Persyaratan Kata Sandi",
     "Must be between 9 and 64 characters": "Harus terdiri dari 9 hingga 64 karakter",
     "Include at least two of the following:": "Harus mengandung setidaknya dua dari yang berikut:",
     "Uppercase character": "Huruf besar",
     "Lowercase character": "Huruf kecil",
     "Number": "Angka",
     "Special character": "Karakter khusus",
     "Confirm Password": "Konfirmasi Kata Sandi",
     "Finalize Your Process": "Selesaikan Proses Anda",
     "Referral Code (Optional)": "Kode Referral (Opsional)",
     "It will autofill if you're signing up with an invitation link.": "Akan diisi otomatis jika Anda mendaftar dengan tautan undangan.",
     "I agree with": "Saya setuju dengan",
     "Terms & Conditions": "Ketentuan dan Syarat",
     "Privacy Policy": "Kebijakan Privasi",
     "Create Your Account": "Buat Akun Anda",
     "Enter Code": "Masukkan Kode",
     "A one-time verification code has been sent to your email address  and phone number.": "Kode verifikasi satu kali telah dikirimkan ke alamat email dan nomor telepon Anda.",
     "Resend available in ": "Kirim Ulang tersedia dalam",
     "seconds": "detik",
     "Verify": "Verifikasi",
     "Verification Code Verified": "Kode Verifikasi Terverifikasi",
     "Please continue to the next step.": "Silakan lanjut ke langkah berikutnya.",
     "Finish": "Selesai",
     "Finishing": "Menyelesaikan",
     "Company Name": "Nama Perusahaan",
     "Enter Your Business Details": "Masukkan Detail Bisnis Anda",
     "Country ": "Negara",
     "You cannot change the country once your account is verified.": "Anda tidak dapat mengubah negara setelah akun Anda diverifikasi.",
     "Learn more": "Pelajari lebih lanjut",
     "Optional": "Opsional",
     "Continuing": "Lanjutkan",
     "Didn't receive the verification code?": "Tidak menerima kode verifikasi?",
     "Resend Now": "Kirim Ulang Sekarang",
     "Search Here": "Cari Di Sini",
     "Search for users, services, and more on InstaPay": "Cari pengguna, layanan, dan lebih banyak lagi di InstaPay",
     "Search users here": "Cari pengguna di sini",
     "Earn money with our affiliate program!": "Dapatkan uang dengan program afiliasi kami!",
     "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Hasilkan penghasilan pasif dengan membagikan kode afiliasi InstaPay Anda kepada pengikut Anda. Untuk setiap transaksi yang mereka lakukan, Anda akan \nmendapatkan komisi, sehingga memaksimalkan keuntungan dari pengaruh online Anda.",
     "Learn More": "Pelajari Lebih Lanjut",
     "Balance Amount": "Jumlah Saldo",
     "You spent less compared to last month.": "Anda mengeluarkan lebih sedikit dibandingkan dengan bulan lalu.",
     "Referral Rewards": "Hadiah Referral",
     "Bitcoin": "Bitcoin",
     "USDT": "USDT",
     "Ethereum": "Ethereum",
     "Quick Transfer to Your Most Recent Contacts:": "Transfer Cepat ke Kontak Terbaru Anda:",
     "Number of Transactions": "Jumlah Transaksi",
     "Total Transaction Amount": "Jumlah Total Transaksi",
     "Total Sent": "Total Dikirim",
     "Total Received": "Total Diterima",
     "Add Funds": "Tambah Dana",
     "Send Money": "Kirim Uang",
     "Request Money": "Minta Uang",
     "Send a Quote": "Kirim Kutipan",
     "Pay With Crypto": "Bayar dengan Kripto",
     "Payment Insights": "Wawasan Pembayaran",
     "Recent Transactions ": "Transaksi Terbaru",
     "Today": "Hari Ini",
     "My Wallets": "Dompet Saya",
     "Balance": "Saldo",
     "Wallet ID": "ID Dompet",
     "Monthly limit": "Batas Bulanan",
     "Top Up": "Isi Ulang",
     "Summary": "Ringkasan",
     "Settings": "Pengaturan",
     "More": "Lainnya",
     "Upgrade to Business Account": "Upgrade ke Akun Bisnis",
     "Conversion": "Konversi",
     "Enter Amount": "Masukkan Jumlah",
     "Exchanged Amount": "Jumlah yang Dikonversi",
     "Convert": "Konversi",
     "You Are Converting": "Anda Mengonversi",
     "From": "Dari",
     "To": "Ke",
     "Exchange Rate": "Kurs Tukar",
     "Fees": "Biaya",
     "You Will Receive": "Anda Akan Menerima",
     "Slide To Confirm": "Geser Untuk Konfirmasi",
     "Verification Code Confirmed - You'll be redirected to the next step": "Kode Verifikasi Dikonfirmasi - Anda akan diarahkan ke langkah berikutnya",
     "Moving to next step in": "Pindah ke langkah berikutnya dalam",
     "Thanks for using InstaPay": "Terima kasih telah menggunakan InstaPay",
     "Your funds have been credited to your wallet.": "Dana Anda telah dikreditkan ke dompet Anda.",
     "Dashboard": "Dasbor",
     "Accounts": "Akun",
     "Transactions": "Transaksi",
     "Other Transactions": "Transaksi Lainnya",
     "Payments": "Pembayaran",
     "Beneficiaries": "Penerima Manfaat",
     "Referrals": "Rujukan",
     "Quotations": "Kutipan",
     "Pending": "Menunggu",
     "My QR Code Sticker": "Stiker Kode QR Saya",
     "My Portfolio": "Portofolio Saya",
     "My Payment Address": "Alamat Pembayaran Saya",
     "Analytics": "Analitika",
     "Profile": "Profil",
     "Dark Mode": "Mode Gelap",
     "Support": "Dukungan",
     "Logout": "Keluar",
     "Powered By": "Diberdayakan Oleh",
     "Kemit Kingdom SA": "Kemit Kingdom SA",
     "A Swiss Company": "Sebuah Perusahaan Fintech Swiss",
     "MAIN MENU": "MENU UTAMA",
     "OTHERS": "LAINNYA",
     "Wallet": "Wallet (Dompet)",
     "Default": "Default (Bawaan)",
     "Breakdown": "Pembagian (Pemecahan)",
     "Credit": "Kredit (Kartu Kredit)",
     "Debit": "Debit (Kartu Debit)",
     "Wallet Management": "Manajemen Wallet (Dompet)",
     "Top-up Your Wallet": "Isi Ulang Wallet Anda",
     "Download Statement": "Unduh Laporan (Pernyataan)",
     "Block the Wallet": "Blokir Wallet (Dompet)",
     "Wallet Status": "Status Wallet (Dompet)",
     "-Select-File-Type-": "-Pilih-Jenis-Berkas-",
     "Download Now": "Unduh Sekarang",
     "Downloading": "Sedang Mengunduh",
     "All": "Semua",
     "Sent": "Dikirim",
     "Received": "Diterima",
     "Requested": "Diminta",
     "Quoted": "Dikutip",
     "Search Transactions": "Cari Transaksi",
     "Date & Time": "Tanggal & Waktu",
     "Type": "Jenis",
     "Transaction ID": "ID Transaksi",
     "Recipient": "Penerima",
     "Amount": "Jumlah",
     "Status": "Status",
     "Payment Type": "Jenis Pembayaran",
     "Sent Payments": "Pembayaran Dikirim",
     "Received Payments": "Pembayaran Diterima",
     "Date": "Tanggal",
     "Cycles/Time": "Siklus/Waktu",
     "Payment As": "Pembayaran Sebagai",
     "Name": "Nama",
     "Cancel": "Batalkan",
     "Subscriptions & Scheduled": "Langganan & Jadwal",
     "Select the option that matches your needs from the list below:": "Pilih opsi yang sesuai dengan kebutuhan Anda dari daftar di bawah ini:",
     "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Mudah mengirim uang kepada teman, keluarga, atau bisnis secara lokal maupun internasional. Pilih dari berbagai opsi transfer, termasuk deposito bank, \ndompet mobile, penarikan tunai, kartu pembayaran, dan transfer antar dompet. Nikmati kenyamanan dan keamanan InstaPay untuk transfer uang yang \nlancar dan instan.",
     "International Transfer": "Transfer Internasional",
     "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Kirim uang melintasi batas negara dengan mudah. Transfer dana secara internasional ke teman, keluarga, atau bisnis hanya dalam beberapa klik. Pilih dari\nberbagai saluran pembayaran termasuk rekening bank, uang mobile/dompet mobile, lokasi penarikan tunai, atau kartu pembayaran\nuntuk transaksi yang nyaman dan aman.",
     "Select the country where you want to send the money!": "Pilih negara tempat Anda ingin mengirim uang!",
     "Search Country": "Cari Negara",
     "Trouble Sending Money?": "Masalah dalam Mengirim Uang?",
     "Confirm": "Konfirmasi",
     "Select Payout Channel": "Pilih Saluran Pencairan",
     "Select your most convenient option to send the money.": "Pilih opsi yang paling nyaman bagi Anda untuk mengirim uang.",
     "Select The Bank": "Pilih Bank",
     "Search Bank": "Cari Bank",
     "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Kirim uang langsung ke rekening bank penerima. Nikmati kenyamanan dan keamanan dalam mentransfer dana dengan lancar ke bank mana pun di seluruh dunia.",
     "Bank Transfer": "Transfer Bank",
     "Enter the amount": "Masukkan jumlah",
     "Tax": "Pajak",
     "Amount the recipient will receive": "Jumlah yang akan diterima oleh penerima",
     "Select Remarks / Reason": "Pilih Catatan / Alasan",
     "Comments": "Komentar",
     "Attach Files": "Lampirkan Berkas",
     "Groups": "Grup",
     "Manage Groups": "Kelola Grup",
     "Add New Group": "Tambahkan Grup Baru",
     "Invite": "Undang Seseorang",
     "Manage Beneficiaries": "Kelola Penerima",
     "Recipients List": "Daftar Penerima",
     "Search Contacts": "Cari Kontak",
     "Confirm Your Payment": "Konfirmasi Pembayaran Anda",
     "Select Country": "Pilih Negara",
     "Select the Recipient's Payment Channel": "Pilih Saluran Pembayaran Penerima",
     "Change Selection": "Ubah Pilihan",
     "You Are Transferring ": "Anda Sedang Mentransfer",
     "Oops! Something Went Wrong.": "Oops! Ada Masalah.",
     "We're Sorry About That. ": "Maaf atas itu.",
     "Go back to Payments Page.": "Kembali ke Halaman Pembayaran.",
     "Wallet to Wallet Transfer": "Transfer Dompet ke Dompet",
     "Request a customized price quote from service providers or vendors.": "Minta penawaran harga yang disesuaikan dari penyedia layanan atau vendor.",
     "Enter the Wallet ID": "Masukkan ID Dompet",
     "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Masukkan ID Dompet, Email, atau Nomor Telepon penerima.",
     "Recipient not on InstaPay?": "Penerima Tidak Ada di InstaPay? Undang Mereka.",
     "Invite Them": "Undang Mereka",
     "My Beneficiaries": "Penerima Manfaat Saya",
     "Enter the Amount You Wish to Transfer.": "Masukkan Jumlah yang Ingin Anda Transfer.",
     "Sending to ": "Mengirim ke",
     "You Have Selected Wallet to Wallet Transfer": "Anda Telah Memilih Transfer Dompet ke Dompet",
     "Authenticate Your Payment.": "Autentikasi Pembayaran Anda.",
     "You are transferring": "Anda sedang mentransfer",
     "Total ": "Total",
     "Where Is My Payment?": "Di Mana Pembayaran Saya?",
     "Share": "Bagikan",
     "Your Payment Is Confirmed": "Pembayaran Anda Telah Dikonfirmasi",
     "QR Code Payment": "Pembayaran dengan Kode QR",
     "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Transfer uang secara instan kepada pengguna InstaPay lainnya dengan layanan dompet-ke-dompet yang aman kami. Kirim dan terima dana tanpa masalah dalam hitungan detik.",
     "Scan QR with Your Camera or Upload from Your Device.": "Pindai Kode QR dengan Kamera Anda atau Unggah dari Perangkat Anda.",
     "Upload from Your Device.": "Unggah dari Perangkat Anda.",
     "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Atau, Gunakan Alamat Pembayaran Unik InstaPay (UPA)",
     "Enter InstaPay UPA": "Masukkan InstaPay UPA",
     "Recipient not on InstaPay? Invite Them": "Penerima tidak ada di InstaPay? Undang Mereka",
     "Verify QR Code": "Verifikasi Kode QR",
     "Scan/Upload QR Code of the Recipient": "Pindai/Unggah Kode QR Penerima",
     "Payment Confirmed": "Pembayaran Dikonfirmasi",
     "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Minta penawaran harga yang disesuaikan dari penyedia layanan atau vendor untuk menerima perkiraan biaya yang akurat untuk layanan atau produk yang Anda butuhkan.",
     "Choose The Beneficiary or Group": "Pilih Penerima Manfaat atau Grup",
     "Enter the Amount You Wish to Request.": "Masukkan Jumlah yang Ingin Anda Minta.",
     "You Are Requesting Money": "Anda Sedang Meminta Uang",
     "You are requesting": "Anda sedang meminta",
     "As": "Sebagai",
     "Conversion of Your Cryptocurrencies": "Konversi Kripto Anda",
     "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Alami konversi Kripto ke Fiat yang berorientasi pada pengguna, aman, dan efisien.",
     "Choose the Cryptocurrency You Intend to Liquidate.": "Pilih Kripto yang Akan Anda Likuidasi.",
     "Next": "Selanjutnya",
     "Enter the Crypto Wallet Address of the Recipient.": "Masukkan Alamat Dompet Kripto Penerima.",
     "Minimum Transactional Value: $100.": "Nilai Transaksi Minimum: $100.",
     "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Catatan: Harga Kripto sering berubah. Lihat kutipan akhir di halaman pesanan konfirmasi.",
     "Select Network": "Pilih Jaringan",
     "Send A Quote": "Kirim Kutipan",
     "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Kirim kutipan harga yang detail kepada klien atau pelanggan Anda. Sajikan layanan, produk, atau penawaran Anda secara profesional dengan kutipan yang dipersonalisasi.",
     "Choose the Beneficiary": "Pilih Penerima Manfaat",
     "Allow Receiver to Bargain": "Izinkan Penerima untuk Tawar-menawar",
     "Enter the Title for Your Quotation.": "Masukkan Judul Kutipan Anda.",
     "Enter the Description for Your Quotation": "Masukkan Deskripsi Kutipan Anda",
     "You are Quoting": "Anda Sedang Mengutip",
     "Title": "Judul",
     "Description": "Deskripsi",
     "Attachments": "Lampiran",
     "Confirm Your Quotation": "Konfirmasi Kutipan Anda",
     "Verify The Quotation": "Verifikasi Kutipan",
     "Your quotation is confirmed": "Kutipan Anda telah dikonfirmasi",
     "Request A Quote": "Minta Penawaran",
     "Coming Soon": "Segera Hadir",
     "Add Beneficiary": "Tambah Penerima Manfaat",
     "Search for Beneficiaries": "Cari Penerima Manfaat",
     "Connected Accounts": "Akun Terhubung",
     "Details": "Detail",
     "First Name": "Nama Depan",
     "Last Name": "Nama Belakang",
     "Country": "Negara",
     "City": "Kota",
     "Address Line": "Alamat",
     "Mobile Number": "Nomor Telepon Seluler",
     "Relationship With Beneficiary": "Hubungan dengan Penerima Manfaat",
     "Bank Account": "Rekening Bank",
     "Mobile Money/Mobile Wallet": "Uang Mobile/Dompet Mobile",
     "Payment Card": "Kartu Pembayaran",
     "Crypto Wallet": "Dompet Kripto",
     "InstaPay Account": "Akun InstaPay",
     "Cash Pickup": "Penarikan Tunai",
     "IBAN / SWIFT Code": "IBAN atau Kode Swift/BIC",
     "Swift Code": "Kode Swift",
     "Account Number": "Nomor Rekening",
     "Bank Name": "Nama Bank",
     "Branch Name": "Nama Cabang",
     "Branch Street": "Alamat Cabang",
     "Province": "Provinsi",
     "Postal Code": "Kode Pos",
     "Mobile Money Provider": "Penyedia Uang Mobile",
     "Wallet Name": "Nama Dompet",
     "Wallet Number": "Nomor Dompet",
     "Card Holder Name": "Nama Pemegang Kartu",
     "Card Number": "Nomor Kartu",
     "Expiry Date": "Tanggal Kadaluarsa",
     "Crypto Currency": "Mata Uang Kripto",
     "Wallet Address": "Alamat Dompet",
     "Wallet Holder Name": "Nama Pemegang Dompet",
     "Wallet Currency": "Mata Uang Dompet",
     "Select Document Type": "Pilih Jenis Dokumen",
     "Enter Document Number": "Masukkan Nomor Dokumen",
     "Add Individual Account": "Tambahkan Akun Individu",
     "Add Business Account": "Tambahkan Akun Bisnis",
     "Company Address": "Alamat Perusahaan",
     "Company Email": "Alamat Email Perusahaan",
     "Company Phone No": "Nomor Telepon Perusahaan",
     "Total Referrals": "Total Referensi",
     "My Earnings": "Pendapatan Saya",
     "Your Referral ID": "ID Referensi Anda",
     "Share Your Unique Referral Link": "Bagikan Tautan Referensi Unik Anda",
     "How do I Refer a Friend to InstaPay?": "Bagaimana Cara Mengajak Teman ke InstaPay?",
     "Step 01": "Langkah 01",
     "Share your InstaPay unique referral link with your friends.": "Bagikan tautan referensi unik InstaPay Anda kepada teman-teman Anda.",
     "Step 02": "Langkah 02",
     "Earn rewards when they transact $100 or more": "Dapatkan hadiah saat mereka bertransaksi sebesar $100 atau lebih.",
     "Step 03": "Langkah 03",
     "Earn up to $0.06 for every transaction your followers make.": "Dapatkan hingga $0.06 untuk setiap transaksi yang dilakukan pengikut Anda.",
     "My Referrals": "Referensi Saya",
     "Referral Complete": "Referensi Selesai",
     "Received Quotations": "Penawaran Diterima",
     "Sent Quotations": "Penawaran Dikirim",
     "Search Pending Items": "Cari Item yang Tertunda",
     "Actions": "Tindakan",
     "Received Requests": "Permintaan Diterima",
     "Sent Requests": "Permintaan Dikirim",
     "No Actions": "Tidak Ada Tindakan",
     "You Requested": "Anda Meminta",
     "Via": "Melalui",
     "How was your experience?": "Bagaimana pengalaman Anda?",
     "Leave a Review": "Tinggalkan Ulasan",
     "Overall Ratings": "Peringkat Keseluruhan",
     "Visit Profile": "Kunjungi Profil",
     "Ratings": "Peringkat",
     "Reviews": "Ulasan",
     "No reviews available": "Tidak ada ulasan yang tersedia",
     "Select Your Currency": "Pilih Mata Uang Anda",
     "Proceed": "Lanjutkan",
     "Commision": "Komisi",
     "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Buka Transaksi Lancar dengan Stiker Kode QR InstaPay",
     "Effortless and Swift:": "Mudah dan Cepat:",
     "Instantly download your unique InstaPay QR code sticker.": "Unduh segera stiker kode QR InstaPay unik Anda.",
     "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Nikmati pembayaran tanpa uang tunai tanpa perlu infrastruktur rumit seperti terminal pembayaran.",
     "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Kumpulkan pembayaran dengan cepat - tidak lagi perlu memasukkan nomor kartu kredit atau telepon secara manual.",
     "Each payment includes customer information for your convenience.": "Setiap pembayaran mencakup informasi pelanggan untuk kenyamanan Anda.",
     "Economically Smart:": "Ekonomis Cerdas:",
     "Zero activation fees – start immediately without upfront costs.": "Biaya aktivasi nol - mulailah segera tanpa biaya awal.",
     "Forget about fixed monthly charges; pay as you transact.": "Lupakan biaya bulanan tetap; bayar sesuai dengan transaksi Anda.",
     "Ditch the hardware; no terminal costs mean more savings for you.": "Tinggalkan perangkat keras; tidak ada biaya terminal berarti lebih banyak penghematan bagi Anda.",
     "Enjoy low transaction fees at just 0.75% per QR code payment.": "Nikmati biaya transaksi rendah hanya 0.75% per pembayaran kode QR.",
     "We don't impose minimum commissions; you save more with each transaction.": "Kami tidak memberlakukan komisi minimum; Anda menghemat lebih banyak dengan setiap transaksi.",
     "Experience the joy of instant payments, enhancing your cash flow.": "Nikmati kegembiraan pembayaran instan, meningkatkan aliran kas Anda.",
     "Make Every Transaction Count with InstaPay": "Buat Setiap Transaksi Berarti dengan InstaPay",
     "Wallet QR": "Dompet QR",
     "Download QR": "Unduh QR",
     "QR Code Status": "Status Kode QR",
     "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Bagikan kode QR atau tautan alamat pembayaran Anda untuk segera menerima pembayaran dalam dompet InstaPay Anda.",
     "QR Code Title": "Judul Kode QR",
     "View Transactions": "Lihat Transaksi",
     "My QR Codes:": "Kode QR Saya:",
     "Load More": "Muat Lebih Banyak",
     "Benefits Of Portfolio": "Manfaat Portofolio",
     "Benefits:": "Manfaat:",
     "Enhanced Visibility:": "Visibilitas Meningkat:",
     "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Tingkatkan profil publik Anda. Portofolio Anda berfungsi sebagai pameran dinamis, dapat diakses langsung dari Alamat Pembayaran Anda.",
     "Engage Your Audience:": "Engage Audience Anda:",
     "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Terhubung dengan pengunjung melalui perjalanan kreatif atau profesional Anda. Biarkan karya Anda berbicara untuk Anda, menciptakan keterlibatan yang lebih dalam \ndengan calon klien atau pendukung.",
     "Integrated Social Proof:": "Bukti Sosial Terintegrasi:",
     "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Tampilkan pengaruh media sosial Anda. Pengunjung dapat melihat jangkauan media sosial Anda, menambah kredibilitas dan konteks pada portofolio Anda.",
     "Seamless Transactions:": "Transaksi Lancar:",
     "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Mengaktifkan pengunjung untuk meminta atau memulai pembayaran langsung dari halaman publik Anda. Portofolio Anda tidak hanya memamerkan karya Anda tetapi juga \nmemfasilitasi interaksi keuangan yang mudah.",
     "Personalized Storytelling:": "Penceritaan yang Dipersonalisasi:",
     "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Gunakan bagian 'Tentang Saya' untuk berbagi cerita, misi, atau visi Anda, menciptakan koneksi pribadi dengan audiens Anda.",
     "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "My Portfolio' lebih dari sekadar galeri; itu adalah alat untuk membangun merek Anda, terlibat dengan audiens Anda, dan menyederhanakan interaksi keuangan Anda di InstaPay.",
     "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Tampilkan Karya Anda, Perbesar Jangkauan Anda. 'My Portfolio' adalah kanvas digital Anda di InstaPay. Unggah dan tampilkan berbagai konten - mulai dari gambar dan video yang \nmenarik hingga PDF informatif. Baik Anda seorang seniman, freelancer, atau pengusaha, fitur ini memungkinkan Anda untuk menyajikan karya atau proyek Anda dengan cara yang \nmenarik secara visual.",
     "Add/Manage Portfolio": "Tambahkan/Kelola Portofolio",
     "Add New": "Tambahkan Baru",
     "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maksimalkan Pendapatan Media Sosial Anda dengan Alamat Pembayaran InstaPay Anda",
     "Make It Effortless for Your Audience to Support You:": "Buatnya Mudah bagi Audiens Anda untuk Mendukung Anda:",
     "Easily integrate your InstaPay Payment Address into your social media posts.": "Integrasikan dengan mudah Alamat Pembayaran InstaPay Anda ke dalam postingan media sosial Anda.",
     "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Salin dan tempel tautan alamat pembayaran Anda ke setiap postingan, cerita, atau secara menonjol di profil Anda.",
     "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideal untuk pengaruh, seniman, pengusaha, atau siapa pun dengan komunitas yang ingin menunjukkan dukungannya.",
     "Instant Support, Endless Possibilities:": "Dukungan Instan, Kemungkinan Tak Terbatas:",
     "Transform the way you monetize your social media content.": "Ubah cara Anda mendapatkan uang dari konten media sosial Anda.",
     "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Izinkan pengikut Anda untuk mengirimkan dana kepada Anda secara instan dengan sekali klik, langsung dari jaringan sosial favorit mereka.",
     "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideal untuk dukungan waktu nyata selama siaran langsung, sebagai sistem tip untuk konten kreatif Anda, atau untuk mendanai proyek besar berikutnya Anda.",
     "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Tempatkan Alamat Pembayaran InstaPay Anda untuk Dampak Maksimum:",
     "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Profil Media Sosial: Tempelkan di bio atau bagian tentang Anda untuk visibilitas konstan.",
     "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Posting & Pengumuman: Gabungkan ke dalam postingan individual, khususnya yang menyoroti karya Anda atau panggilan dukungan tertentu.",
     "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Cerita & Reels: Integrasikan ke dalam cerita interaktif atau reel yang menarik untuk menangkap kontribusi instan.",
     "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Siaran Langsung: Bagikan selama sesi LANGSUNG Anda, sehingga mudah bagi pemirsa untuk berkontribusi secara waktu nyata.",
     "Simple, Fast, and Secure:": "Mudah, Cepat, dan Aman:",
     "No complex setup – get started in moments.": "Tidak ada pengaturan rumit - mulailah dalam sekejap.",
     "Enjoy the security and speed of InstaPay's trusted payment processing.": "Nikmati keamanan dan kecepatan pemrosesan pembayaran tepercaya InstaPay.",
     "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Terima pembayaran dari siapa saja, di mana saja, tanpa mereka perlu memiliki akun InstaPay.",
     "Elevate Your Social Media Game with InstaPay Payment Address": "Tingkatkan Permainan Media Sosial Anda dengan Alamat Pembayaran InstaPay",
     "InstaPay VPA (Virtual Payment Address)": "Alamat Pembayaran Virtual InstaPay (VPA)",
     "Payment Address Title": "Judul Alamat Pembayaran",
     "Currency": "Mata Uang",
     "Payment Address Description": "Deskripsi Alamat Pembayaran",
     "Update": "Perbarui",
     "Updating": "Memperbarui",
     "Total Transaction": "Total Transaksi",
     "Last 30 days": "30 Hari Terakhir",
     "Last 1 month": "1 Bulan Terakhir",
     "Last 1 year": "Terakhir 1 tahun",
     "Custom Date": "Tanggal Kustom",
     "Payment Methods": "Metode Pembayaran",
     "Payment Types": "Jenis Pembayaran",
     "Requested Amount": "Jumlah Diminta",
     "Quoted Amount": "Jumlah Ditawarkan",
     "About Me": "Tentang Saya",
     "Followers": "Pengikut",
     "InstaPay ID": "ID InstaPay",
     "Download QR Code": "Unduh Kode QR",
     "Initiate A Payment": "Mulai Pembayaran",
     "Report this person": "Laporkan orang ini",
     "Add now": "Tambahkan sekarang",
     "Recent Reviews": "Ulasan Terbaru",
     "Reviews As Seller": "Ulasan Sebagai Penjual",
     "Reviews As Buyer": "Ulasan Sebagai Pembeli",
     "Select Language": "Pilih Bahasa",
     "Profile Completion": "Pengisian Profil",
     "Profile Completed": "Profil Terisi",
     "Basic Info": "Info Dasar",
     "Username": "Nama Pengguna",
     "Gender": "Jenis Kelamin",
     "Male": "Laki-laki",
     "Female": "Perempuan",
     "-Select-Gender-": "-Pilih Jenis Kelamin-",
     "Date Of Birth": "Tanggal Lahir",
     "Address": "Alamat",
     "Edit Profile": "Edit Profil",
     "Update Profile": "Perbarui Profil",
     "Updating Profile": "Memperbarui Profil",
     "Complete Now": "Selesaikan Sekarang",
     "Password": "Kata Sandi",
     "Change Password": "Ganti Kata Sandi",
     "New Password": "Kata Sandi Baru",
     "Re-enter New Password": "Masukkan Ulang Kata Sandi Baru",
     "Please follow this guide for a strong password": "Silakan ikuti panduan ini untuk kata sandi yang kuat",
     "Include at least one special character.": "Sertakan setidaknya satu karakter khusus.",
     "Minimum of 8 characters.": "Minimal 8 karakter.",
     "Include at least one number": "Sertakan setidaknya satu angka.",
     "Change it often for enhanced security.": "Ganti secara teratur untuk keamanan yang ditingkatkan.",
     "Security Questions": "Pertanyaan Keamanan",
     "Security Question": "Pertanyaan Keamanan",
     "Select your question": "Pilih pertanyaan Anda",
     "Answer To The Question": "Jawaban untuk Pertanyaan",
     "Nominee": "Nominasi",
     "Relationship with Nominee": "Hubungan dengan Nominasi",
     "Nominee Contact Number": "Nomor Kontak Nominasi",
     "Nominee Address": "Alamat Nominasi",
     "Delete Nominee": "Hapus Nominasi",
     "Update Nominee": "Perbarui Nominasi",
     "Details Updated Successfully!": "Detail Diperbarui dengan Sukses!",
     "Success": "Sukses",
     "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "Anda dapat memilih Nominasi pilihan Anda, asalkan mereka bukan anak di bawah umur, melalui platform InstaPay itu sendiri. Anda mengakui bahwa tidak diperlukan tanda tangan \nbasah untuk menunjuk Nominasi, dan pendaftaran online akan dianggap final dan mengikat. Dalam kejadian kematian pemegang Dompet InstaPay, saldo dalam Dompet akan \nditransfer kepada Nominasi yang terdaftar. KEMIT KINGDOM akan dibebaskan dari semua kewajibannya setelah penyerahan jumlah kepada Nominasi. Nominasi akan menyediakan \ndokumen yang diperlukan oleh InstaPay, termasuk dokumen identifikasi dan bukti kematian.\"",
     "Add Nominee": "Tambahkan Nominasi",
     "Two Factor Authentication": "Autentikasi Dua Faktor",
     "SMS Number": "Nomor SMS",
     "Updated Successfully!": "Diperbarui dengan Sukses!",
     "Social Network Accounts": "Akun Jejaring Sosial",
     "Here, you can set up and manage your integration settings.": "Di sini, Anda dapat mengatur dan mengelola pengaturan integrasi Anda.",
     "Social Network Account": "Akun Jejaring Sosial",
     "Activate Account": "Aktifkan Akun",
     "Enter the code below in the InstaPay Chatbot.": "Masukkan kode di bawah ini ke InstaPay Chatbot.",
     "The code will expire in ": "Kode akan kedaluwarsa dalam",
     "Notifications": "Notifikasi",
     "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Sesuaikan bagaimana Anda menerima notifikasi. Pengaturan ini berlaku untuk aktivitas yang Anda pantau.",
     "Activity": "Aktivitas",
     "Email": "Email",
     "Push": "Push",
     "SMS": "SMS",
     "Source": "Sumber",
     "Payment Requests": "Permintaan Pembayaran",
     "Bulk Payments": "Pembayaran Massal",
     "Identity Verification": "Verifikasi Identitas",
     "Verify Your Identity": "Verifikasi Identitas Anda",
     "Identity Verification Status:": "Status Verifikasi Identitas:",
     "Briefly describe yourself and explain your reasons for using InstaPay.": "Jelaskan secara singkat tentang diri Anda dan jelaskan alasan Anda menggunakan InstaPay.",
     "Enter Your Message": "Masukkan Pesan Anda",
     "Select Occupation": "Pilih Pekerjaan",
     "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Itu harus menjadi dokumen identifikasi yang dikeluarkan pemerintah dengan foto. Semua dokumen identifikasi harus ditulis dalam alfabet Latin. Jika tidak, diperlukan terjemahan bersertifikat.",
     "Accepted Documents": "Dokumen yang Diterima",
     "A national identity card": "Kartu identitas nasional",
     "A valid passport": "Paspor yang sah",
     "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Izin tinggal yang sah (hanya diterima jika dikeluarkan oleh negara Eropa atau di Swiss)",
     "Biometric Facial Recognition: Comparing video with passport photograph": "Pengenalan Wajah Biometrik: Membandingkan video dengan foto paspor",
     "Address Verification:": "Verifikasi Alamat:",
     "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Sediakan rekening bank atau tagihan utilitas (kurang dari 3 bulan) sebagai bukti alamat",
     "Additional Documents:": "Dokumen Tambahan:",
     "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom berhak untuk meminta dokumen tambahan (Enhanced Due Diligence - EDD) atau informasi lebih lanjut yang diperlukan untuk melakukan verifikasi yang cermat, \nsesuai dengan kewajiban hukumnya.",
     "Start Verification": "Mulai Verifikasi",
     "Sessions": "Sesi-sesi",
     "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Ini adalah daftar perangkat yang telah masuk ke akun Anda. Hapus yang tidak Anda kenali.",
     "Your Withdrawal Options": "Pilihan Penarikan Anda",
     "Choose your preferred method for withdrawing payments.": "Pilih metode yang Anda sukai untuk menarik pembayaran.",
     "No withdrawal options currently available in your country.": "Saat ini tidak ada pilihan penarikan yang tersedia di negara Anda.",
     "Download Your Data": "Unduh Data Anda",
     "Request a Copy of Your Data": "Minta Salinan Data Anda",
     "Submit a request to download a copy of your data": "Ajukan permintaan untuk mengunduh salinan data Anda",
     "Which Data Should Be Included in the Download?": "Data Mana yang Harus Dimasukkan dalam Unduhan?",
     "Select all applicable options.": "Pilih semua opsi yang berlaku.",
     "Personal Information": "Informasi Pribadi",
     "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Informasi ini digunakan untuk mengamankan akun Anda, menyesuaikan pengalaman Anda, dan menghubungi Anda jika diperlukan.",
     "Name and Date Of Birth": "Nama dan tanggal lahir",
     "Phone number": "Nomor telepon",
     "Financial Information": "Informasi Keuangan",
     "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Kami menggunakan informasi ini untuk memungkinkan proses checkout yang lebih cepat dan untuk mengirim atau menerima uang dalam beberapa klik saja.",
     "Bank accounts": "Rekening bank",
     "Debit or credit cards": "Kartu debit atau kredit",
     "Credit Information": "nformasi Kredit",
     "Includes your credit application information": "Termasuk informasi aplikasi kredit Anda",
     "Other Information (not included in file)": "Informasi Lainnya (tidak termasuk dalam file)",
     "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Termasuk info perangkat, data penggunaan teknis, informasi geolokasi, preferensi pemasaran, riwayat persetujuan, dan data yang digunakan untuk layanan lain seperti kredit, verifikasi identitas, \nkomunikasi dengan PayPal, dan prosesor pihak ketiga.",
     "Choose file type:": "Pilih jenis file:",
     "Select file type": "Pilih jenis file",
     "PDF file can be easily opened on any computer": "File PDF dapat dibuka dengan mudah di komputer apa pun",
     "Submit Request": "Kirim Permintaan",
     "Delete Your Account": "Hapus Akun Anda",
     "Ask us to delete your data and close this account": "Minta kami menghapus data Anda dan menutup akun ini",
     "We use your data to provide services, so if we delete your data, we also need to close your account.": "Kami menggunakan data Anda untuk memberikan layanan, jadi jika kami menghapus data Anda, kami juga perlu menutup akun Anda.",
     "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Jika akun Anda terbatas, pergi ke Pusat Resolusi, pilih 'Pergi ke Pembatasan Akun', lalu klik 'Selesaikan'.",
     "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Pastikan semua pembayaran tertunda sudah diselesaikan. Kami tidak akan dapat menutup akun Anda sampai itu terjadi.",
     "If you have a negative balance, click the Resolve link next to your balance.": "Jika Anda memiliki saldo negatif, klik tautan Selesaikan di sebelah saldo Anda.",
     "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Saat akun Anda jelas, dan jika Anda memenuhi syarat, kami akan menutup akun Anda dan menghapus data Anda. Kami perlu menyimpan beberapa data Anda untuk alasan hukum dan regulasi. \nUntuk informasi lebih lanjut, lihat ",
     "privacy statement": "pernyataan privasi",
     "We'll email you to let you know the status, and if there are any other steps you need to take.": "Kami akan mengirim email kepada Anda untuk memberi tahu statusnya, dan jika ada langkah lain yang perlu Anda ambil.",
     "Have more questions about data deletion?": "Punya pertanyaan lebih lanjut tentang penghapusan data?",
     "Contact us": "Hubungi kami",
     "I understand that account deletion is permanent and can't be reversed.": "Saya mengerti bahwa penghapusan akun bersifat permanen dan tidak dapat dibalikkan.",
     "Are you sure you want to close your account?": "Apakah Anda yakin ingin menutup akun Anda?",
     "Yes, Continue": "Ya, Lanjutkan",
     "Logging In": "Masuk",
     "Complete your profile to streamline your transaction process.": "Lengkapi profil Anda untuk menyederhanakan proses transaksi Anda.",
     "Skip": "Lewati",
     "Complete Your Profile": "Lengkapi Profil Anda",
     "API Key": "Kunci API",
     "Get API Key": "Dapatkan Kunci API",
     "Generating New API Key": "Menghasilkan Kunci API Baru",
     "Generate API Key": "Hasilkan Kunci API",
     "Regenerate API Key": "Hasilkan Ulang Kunci API",
     "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Untuk memanfaatkan potensi penuh transaksi tanpa batas InstaPay, ikuti langkah-langkah cepat ini untuk menghasilkan kunci API Anda. Pertama, daftar akun di [URL pendaftaran Anda] \ndan akses Dasbor Pengembang. Beralih ke bagian 'Kunci API', hasilkan kunci baru, dan pastikan penyimpanannya aman.",
     "Delete API Key": "Hapus Kunci API",
     "Webhook URL": "URL Webhook",
     "KYB Verification": "Verifikasi KYB",
     "Additional Files": "File Tambahan",
     "Business Verification": "Verifikasi Bisnis",
     "--Select-Your-Company-Type--": "--Pilih-Jenis-Perusahaan-Anda--",
     "Documents Required:": "Dokumen yang Diperlukan:",
     "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Ekstrak Resmi Pendaftaran Perusahaan (Dokumen Pendaftaran/Inkorporasi Entitas)",
     "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Dokumen ini berfungsi sebagai 'kartu identitas' resmi perusahaan dan harus diperoleh dari registrasi bisnis lokal. Ini mencerminkan status saat ini bisnis tersebut (aktif atau ditutup).",
     "The document should be current, with an issuance date not exceeding 3 months.": "Dokumen tersebut harus berlaku, dengan tanggal penerbitan tidak lebih dari 3 bulan.",
     "The document must include the following details:": "Dokumen harus mencakup detail berikut:",
     "Legal name of the company": "Nama resmi perusahaan",
     "Full registered address": "Alamat terdaftar lengkap",
     "Company registration number": "Nomor registrasi perusahaan",
     "Date of incorporation": "Tanggal inkorporasi",
     "Description of the company's activities/purpose": "Deskripsi aktivitas/tujuan perusahaan",
     "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Informasi perwakilan hukum: nama lengkap, tanggal dan tempat lahir, alamat tempat tinggal (persyaratan minimum: negara tempat tinggal), dan kewarganegaraan",
     "If applicable, the document should also include:": "Jika berlaku, dokumen harus juga mencakup:",
     "Brand or trading names": "Merek atau nama perdagangan",
     "Value Added Tax (VAT) number": "Nomor Pajak Pertambahan Nilai (PPN)",
     "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Daftar Pemilik Manfaat Utama (UBO) - ini wajib di beberapa negara, jika tidak, dapat diberikan sebagai dokumen terpisah.",
     "Proof of Legal Representative's Authority": "Bukti Kewenangan Perwakilan Hukum",
     "This document validates an individual's authority to represent the company": "Dokumen ini memvalidasi otoritas seseorang untuk mewakili perusahaan",
     "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Dalam umumnya, perwakilan hukum dinamai dalam ekstrak registrasi perusahaan. Namun, jika hal ini tidak terjadi, dokumen seperti Surat Kuasa atau Delegasi Wewenang harus diperoleh.",
     "Power of Attorney/Delegation of Powers:": "Surat Kuasa/Delegasi Wewenang:",
     "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Individu yang memberikan wewenang harus memiliki hak yang sah untuk melakukannya. Individu ini harus terdaftar sebagai perwakilan hukum dalam ekstrak registrasi perusahaan.",
     "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Struktur Kepemilikan dan Dokumentasi Pemilik Manfaat Utama (UBO)",
     "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "ukti perusahaan terdaftar di bursa saham yang relevan harus disediakan. Ini bisa berupa halaman dari situs web bursa saham yang menampilkan detail perusahaan.",
     "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "ika kurang dari 75% saham perusahaan dimiliki oleh publik, semua pemegang saham yang memiliki atau mengendalikan lebih dari 25% saham akan tunduk pada persyaratan verifikasi Pemilik \nManfaat Utama (UBO).",
     "Collection of Identification Documents": "Pengumpulan Dokumen Identifikasi",
     "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Dokumen identifikasi harus dikeluarkan oleh pemerintah, memuat foto individu. Semua dokumen identifikasi harus ditulis dalam alfabet Latin. Jika tidak, diperlukan terjemahan bersertifikat.",
     "Accepted documents:": "Dokumen yang diterima:",
     "Valid National Identity Card": "Kartu Identitas Nasional yang Valid",
     "Valid Passport": "Paspor yang Valid",
     "Valid Resident Permits - These will be accepted only if issued by a European country.": "Izin Tinggal yang Valid - Ini hanya akan diterima jika diterbitkan oleh negara Eropa.",
     "Only legal representatives are subject to the identification document collection process.": "Hanya perwakilan hukum yang terlibat dalam proses pengumpulan dokumen identifikasi.",
     "Additional Requirement:": "Persyaratan Tambahan:",
     "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "Dalam kasus Surat Kuasa atau Delegasi Wewenang, kedua pihak (individu yang memberikan wewenang dan yang menerimanya) harus menyediakan salinan KTP mereka.",
     "Additional Documents": "Dokumen Tambahan",
     "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Tagihan Utilitas (seperti tagihan gas, listrik, telepon, atau ponsel); atau dokumen yang dikeluarkan oleh departemen pemerintah yang menunjukkan alamat dan nama Pengguna Akhir \n(mis., Sertifikat Tempat Tinggal); atau",
     "Bank Statement; or": "Rekening Bank; atau\n",
     "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "dokumen yang dikeluarkan oleh departemen pemerintah yang menunjukkan alamat dan nama Pengguna Akhir (mis., Sertifikat Tempat Tinggal).",
     "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom berhak untuk meminta dokumen tambahan atau informasi lebih lanjut yang diperlukan untuk melakukan verifikasi yang teliti, sesuai dengan kewajiban hukumnya.",
     "Note:": "Catatan:",
     "Partners": "Mitra",
     "Add New Partner": "Tambahkan Mitra Baru",
     "Designation": "Penunjukan",
     "Partner Name": "Nama Mitra",
     "Partner Email": "Email Mitra",
     "Partner Designation": "Penunjukan Mitra",
     "Add Now": "Tambahkan Sekarang",
     "Adding": "Menambahkan",
     "Delete Partner": "Hapus Mitra",
     "Delete": "Hapus",
     "Deleting": "Menghapus",
     "Are you sure, you want to remove sxz as partner?": "Apakah Anda yakin ingin menghapus sxz sebagai mitra?",
     "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Ekstrak Registrasi Entitas (Dokumen Pembuatan Entitas)",
     "This document should be either:": "Dokumen ini harus berupa salah satu dari berikut:",
     "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Bukti pembuatan entitas, menyediakan detail entitas, tanggal pembuatan, dan tujuan entitas tersebut.",
     "A local registry extract:": "Ekstrak registrasi lokal:",
     "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Diperlukan sesuai dengan regulasi negara, berfungsi sebagai 'kartu identitas' saat ini untuk entitas, menunjukkan statusnya (aktif atau ditutup).",
     "If a registry extract exists, it must not be older than 3 months.": "Jika ekstrak registrasi sudah ada, dokumen tersebut tidak boleh lebih lama dari 3 bulan.",
     "Entity name": "Nama Entitas",
     "Registration number (if applicable, based on the country's requirements)": "Nomor registrasi (jika berlaku, berdasarkan persyaratan negara)",
     "Date of creation": "Tanggal pembuatan",
     "Description of the entity's activities or purpose": "Deskripsi kegiatan atau tujuan entitas",
     "Trading names": "Nama perdagangan",
     "Articles of Association": "Anggaran Dasar",
     "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Anggaran Dasar berfungsi sebagai dokumen konstitusi entitas, menguraikan aturan operasionalnya.",
     "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Tidak ada batasan waktu pada dokumen ini, yang berarti versi terbaru yang diminta. Biasanya, jenis entitas ini memerlukan setidaknya satu pertemuan tahunan yang dapat menghasilkan \nversi terbaru dari Anggaran Dasar.",
     "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Contoh perubahan yang dapat terjadi termasuk pembaruan nama entitas, alamat, tujuan, dewan manajemen, peran, tanggung jawab, dll.",
     "The document should contain information about:": "Dokumen harus berisi informasi tentang:",
     "The entity itself, including its legal name, registered address, and purpose": "Entitas itu sendiri, termasuk nama hukum, alamat terdaftar, dan tujuannya",
     "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Aturan operasional internalnya, seperti proses pengangkatan anggota dewan manajemen, peran dan kekuasaan mereka, di antara detail lainnya. Daftar ini tidak lengkap.",
     "How the entity is funded": "Bagaimana entitas didanai",
     "Appointment/Removal/Changes on Board of Management Members:": "Pengangkatan/Penghapusan/Perubahan pada Anggota Dewan Manajemen:",
     "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Pembaruan ini biasanya terjadi berdasarkan kebutuhan entitas atau seperti yang ditetapkan dalam Anggaran Dasar. Perubahan didokumentasikan melalui notulen rapat dewan manajemen, \nyang didaftarkan dan disertifikasi untuk mencerminkan daftar anggota yang diperbarui, seperti presiden, wakil presiden, bendahara, dan sekretaris jenderal.",
     "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "Notulen rapat dewan manajemen terbaru, termasuk daftar anggota saat ini, akan diperlukan sebagai tambahan dari Anggaran Dasar, jika tersedia.",
     "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Catatan: Jika daftar terbaru anggota dewan manajemen tidak termasuk dalam Anggaran Dasar terbaru dan tidak ada notulen rapat dewan yang tersedia, daftar ini harus disediakan. \nDaftar tersebut harus bertanggal dan ditandatangani oleh perwakilan hukum yang paling baru diangkat, yang pengangkatannya dapat diverifikasi melalui dokumentasi terkait.",
     "This document demonstrates an individual's authority to represent the entity. This document could be:": "Dokumen ini menunjukkan kewenangan seseorang untuk mewakili entitas. Dokumen ini bisa berupa:",
     "The latest Articles of Association": "Anggaran Dasar terbaru",
     "The current list of board management members (which may be included in the board management meeting minutes)": "Daftar terbaru anggota dewan manajemen (yang mungkin termasuk dalam notulen rapat dewan manajemen)",
     "The document should specify:": "Dokumen harus menentukan:",
     "Full name of the individual(s)": "Nama lengkap individu",
     "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Fungsi individu. Informasi ini akan diverifikasi silang dengan Anggaran Dasar untuk memastikan bahwa fungsi individu memungkinkan mereka mengikat entitas. Ini sangat penting ketika \npemohon bukan presiden, dan fungsi lain memiliki kewenangan perwakilan terbatas seperti yang dinyatakan dalam Anggaran Dasar.",
     "Additional information about the legal representative that will be collected includes:": "Informasi tambahan tentang perwakilan hukum yang akan dikumpulkan meliputi:",
     "Date and place of birth": "Tanggal dan tempat lahir",
     "Residential address (at least the country of residence should be stated)": "Alamat tempat tinggal (setidaknya negara tempat tinggal harus dinyatakan)",
     "Nationality": "Kewarganegaraan",
     "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Struktur Kepemilikan & Pemilik Manfaat Akhir (UBOs)",
     "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Umumnya, entitas semacam ini, mengingat struktur dan tujuannya, tidak memiliki pemilik manfaat. Namun, anggota dewan akan diidentifikasi dan diperlakukan sebagai pemilik manfaat untuk\ntujuan pemeriksaan, pengumpulan dan verifikasi ID, dll.",
     "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Sejumlah kecil asosiasi mungkin memiliki pemilik manfaat, terutama yang menghasilkan keuntungan. Dalam kasus seperti ini, detail ini akan disebutkan dalam Anggaran Dasar. Individu-individu \nini akan mudah diidentifikasi dan diperlakukan sebagai pemilik manfaat, bersama dengan anggota dewan manajemen.",
     "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Persyaratan Tambahan: Dalam kasus yang melibatkan Surat Kuasa atau Delegasi Wewenang, kedua belah pihak (yang memberikan wewenang dan yang menerimanya) harus menyediakan \nsalinan dokumen identifikasi mereka.",
     "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Ekstrak Registrasi Pedagang Tunggal (Dokumen Registrasi/Pembuatan Entitas)",
     "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Dokumen ini berfungsi sebagai registri lokal untuk pedagang tunggal, yang diperlukan di negara-negara di mana pendaftaran semacam ini adalah wajib. Dokumen ini memverifikasi bahwa \nindividu tersebut terlibat dalam aktivitas profesional sebagai pedagang tunggal. Nama dokumen ini berbeda-beda dari negara ke negara.",
     "Where applicable, the document should not be older than 3 months.": "Dimana berlaku, dokumen tidak boleh lebih dari 3 bulan.",
     "The document must contain:": "Dokumen harus berisi:",
     "Individual's name": "Nama individu",
     "Residential address": "Alamat tempat tinggal",
     "Registration number": "Nomor registrasi",
     "The sole trader is the only individual subject to the identification document collection process.": "Pemilik tunggal adalah satu-satunya individu yang terlibat dalam proses pengumpulan dokumen identifikasi.",
     "No Transactions": "Tidak Ada Transaksi",
     "No Pending Items": "Tidak Ada Item Tertunda",
     "No Beneficiaries": "Tidak Ada Penerima Manfaat",
     "Send Money To": "Kirim Uang Ke",
     "Confirmation": "Konfirmasi",
     "You are setting": "Anda sedang mengatur",
     "as default.": "sebagai bawaan.",
     "Previously": "Sebelumnya",
     "was default QR.": "adalah QR bawaan.",
     "Category": "Kategori",
     "-Select-From-The-List-": "-Pilih-Dari-Daftar-",
     "Add a note": "Tambahkan catatan",
     "Categories Added Successfully!": "Kategori Berhasil Ditambahkan!",
     "Subscription starting date": "Tanggal mulai berlangganan",
     "Until": "Hingga",
     "Next Transaction": "Transaksi Berikutnya",
     "Transaction Method": "Metode Transaksi",
     "Accept the quotation": "Terima kutipan",
     "Choose Currency": "Pilih Mata Uang",
     "Accept": "Terima",
     "Bargain the quotation": "Tawar kutipan",
     "Enter Counter Amount:": "Masukkan Jumlah Tawaran:",
     "Bargain": "Tawar",
     "Decline the quotation": "Batalkan kutipan",
     "Are you sure, you want to decline this quotation?": "Anda yakin ingin menolak kutipan ini?",
     "Decline": "Tolak",
     "Personal Account": "Akun Pribadi",
     "Jan": "Jan",
     "Feb": "Feb",
     "Mar": "Mar",
     "Apr": "Apr",
     "May": "Mei",
     "Jun": "Jun",
     "Jul": "Jul",
     "Aug": "Agu",
     "Sep": "Sep",
     "Oct": "Okt",
     "Nov": "Nov",
     "Dec": "Des",
     "Today": "Hari ini",
     "Spent In": "Dihabiskan di",
     "Revised Amount": "Jumlah yang Direvisi",
     "You spent more comparing to last month": "Anda menghabiskan lebih banyak dibanding bulan lalu",
     "Last": "Terakhir",
     "days": "Hari",
     "Year": "Tahun",
     "Instant Mobile Top-up Across 150+ Countries": "Isi Ulang Seluler Instan di Lebih dari 150 Negara",
     "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Manjakan orang terkasih atau isi ulang ponsel Anda sendiri, di mana pun Anda berada! Layanan Isi Ulang Seluler InstaPay memungkinkan Anda mengisi ulang ponsel secara instan di lebih dari 150 negara. Sempurna untuk hadiah atau memastikan Anda selalu terhubung, layanan kami dirancang untuk kemudahan, kecepatan, dan keamanan.",
     "How It Works - Step-by-Step Guide:": "Cara Kerja - Panduan Langkah demi Langkah:",
     "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Pilih 'Isi Ulang Seluler': Akses fitur ini langsung dalam aplikasi InstaPay di platform seperti WhatsApp, Twitter, Telegram, atau Instagram.",
     "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Pilih Mata Uang dan Jumlah: Pilih dari beberapa mata uang (USD, GBP, EUR, dll.) dan tentukan berapa banyak yang ingin Anda isi ulang.",
     "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Masukkan Nomor Ponsel: Ketik nomor yang ingin Anda isi ulang—milik Anda atau orang terkasih. Sistem kami memastikan proses yang aman dan cepat.",
     "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Konfirmasi dan Bayar: Periksa kembali detail isi ulang, konfirmasi, dan lakukan pembayaran dengan metode pilihan Anda. Transaksi diproses secara instan dan aman.",
     "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Terima Konfirmasi Instan: Anda dan penerima (jika berlaku) akan menerima notifikasi konfirmasi setelah isi ulang berhasil.",
     "Benefits:": "Keuntungan:",
     "Global Reach: Effortlessly send airtime to over 150 countries.": "Jangkauan Global: Kirim pulsa dengan mudah ke lebih dari 150 negara.",
     "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Dampak Instan: Lihat manfaatnya segera—sangat cocok untuk hadiah mendadak atau komunikasi mendesak.",
     "Complete Security: Enjoy peace of mind with our secure payment system.": "Keamanan Lengkap: Nikmati ketenangan pikiran dengan sistem pembayaran kami yang aman.",
     "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Opsi Pembayaran Serbaguna: Pilih dari kartu kredit, PayPal, atau dompet InstaPay untuk pembayaran.",
     "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Siap menyebarkan kegembiraan atau tetap terhubung? Isi ulang sekarang dan buat hari seseorang—atau hari Anda sendiri!",
     "Pick a language": "Pilih bahasa",
     "French": "Prancis",
     "Spanish": "Spanyol",
     "Portuguese": "Portugis",
     "German": "Jerman",
     "Ukrainian": "Ukraina",
     "Italian": "Italia",
     "Russian": "Rusia",
     "Arabic": "Arab",
     "Polish": "Polandia",
     "Dutch (Nederlands)": "Belanda",
     "Yoruba": "Yoruba",
     "Indonesian": "Indonesia",
     "Turkish": "Turki",
     "Swahili": "Swahili",
     "Hausa": "Hausa",
     "Hindi": "Hindi",
     "Urdu": "Urdu",
     "Chinese": "Cina",
     "Select Your Preferred Language": "Pilih Bahasa yang Anda Pilih",
     "Please select the language you would \nlike to use for the eKYC process.": "Silakan pilih bahasa yang ingin Anda gunakan untuk proses eKYC.",
     "Select Your Nationality": "Pilih Kewarganegaraan Anda",
     "Choose your nationality to see the \nsupported ID documents for  your country.": "Pilih kewarganegaraan Anda untuk melihat dokumen ID yang didukung untuk negara Anda.",
     "Grant permission to use the camera.": "Izinkan penggunaan kamera.",
     "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Ketuk atau klik \"Izinkan\" saat diminta untuk memberikan izin akses kamera untuk menangkap dokumen.",
     "Upload Your Documents from \nGallery/Photos": "Unggah Dokumen Anda dari Galeri/Foto",
     "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Jika Anda mengalami masalah dengan kamera atau jika sistem Anda tidak memiliki satu, Anda dapat mengunggah foto dokumen secara manual dari penyimpanan lokal Anda.",
     "Upload Your ID.": "Unggah ID Anda.",
     "Make sure your ID is clear and not blurry to \nensure quick verification.": "Pastikan ID Anda jelas dan tidak buram untuk memastikan verifikasi cepat.",
     "Valid Proof of Address": "Bukti Alamat yang Sah",
     "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Pastikan bahwa bukti alamat Anda terkini dan tanggal pernyataan adalah dalam tiga bulan terakhir dari tanggal mulai verifikasi eKYC Anda.\n\n2. Pastikan bukti alamat Anda jelas dan tidak buram untuk memastikan verifikasi cepat.",
    "Itant Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Pemberitahuan Penting\n\nHarap baca dengan cermat semua langkah dalam panduan ini sebelum melanjutkan dengan verifikasi eKYC Anda.\n\nHindari kesalahan umum:\n\n* Pastikan semua dokumen Anda jelas, terbaca, dan terbaru.\n* Periksa apakah informasi yang Anda berikan sesuai dengan data pada dokumen resmi Anda.\n* Ikuti petunjuk dengan seksama untuk menghindari keterlambatan atau penolakan.\n\nCatatan: \nJika verifikasi eKYC Anda gagal dan Anda perlu mengirimkannya kembali, biaya tambahan akan dikenakan untuk proses pengiriman ulang.\n\nDengan mengikuti pedoman ini, Anda dapat memastikan verifikasi eKYC yang lancar dan sukses.\n\nUntuk pertanyaan atau bantuan, silakan hubungi tim dukungan kami di support@insta-pay.ch",
    "Transactions": "Transaksi Pribadi",
     "Business Transactions": "Transaksi Bisnis",
    "urchases": "Belanja & Pembelian",
     "Bills & Utilities": "Tagihan & Utilitas",
     "Entertainment & Leisure": "Hiburan & Rekreasi",
     "Investments & Savings": "Investasi & Tabungan",
     "Health & Wellness": "Kesehatan & Kesejahteraan",
     "Transportation": "Transportasi",
     "Education": "Pendidikan",
     "Miscellaneous": "Lain-lain",
     "Family Support": "Dukungan keluarga",
     "WALLET": "WALLET",
     "Add a currency": "Tambah mata uang",
     "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Saat Anda menambahkan mata uang, pembayaran apa pun yang Anda terima dalam mata uang tersebut akan dikreditkan ke saldo InstaPay Anda. Mata uang utama Anda akan digunakan untuk mengirim atau meminta pembayaran kecuali ditentukan lain.",

     "balance amount ": "jumlah saldo",
    "referral ": "referensi",
     "crypto wallet balance": "saldo dompet crypto",
     "money in": "uang masuk",
     "money out": "uang keluar",
     "analytics": "analitik",
     "see more": "lihat lebih banyak",
     "manage my Wallets and see the transaction details": "kelola Wallets saya dan lihat rincian transaksi saya",
     "set as default Wallet": "tetapkan sebagai Wallet default",
     "add currency": "tambahkan mata uang",
     "convert funds ": "konversi dana",
     "withdraw balance": "tarik saldo",
     "chart names": "nama grafik",
     "withdraw balance from PKR wallet": "tarik saldo dari dompet PKR",
     "select a payout channel": "pilih saluran pembayaran",
     "you have entered an amount below the minimum payment range": "Anda telah memasukkan jumlah di bawah kisaran pembayaran minimum",
     "change": "ganti",
     "edit": "sunting",
     "moving to next step in ...": "melanjutkan ke langkah berikutnya dalam ...",
     "you are withdrawing ...": "Anda menarik ...",
     "where is my payment": "di mana pembayaran saya",
     "download": "unduh",
     "IBAN / SWIFT Code": "IBAN atau kode  SWIFT/BIC",  
     "swift code": "kode SWIFT",
                     "account number": "nomor rekening",
     "street": "jalan cabang",
     "city": "kota", 
        "province": "provinsi",
     "postal code": "kode pos",
     "all payout channels": "semua saluran pembayaran",
     "bussiness beneficiary": "penerima bisnis",
     "no quotation": "tidak ada kutipan",
     "no pending items": "tidak ada item yang tertunda",
     "QR status": "Status QR",
     "Portfolio": "Portofolio",
     "edit whole portfolio page": "sunting seluruh halaman portofolio",
     "personal account": "akun pribadi",
     "Chart names": "Nama grafik",
     "instagram": "instagram",
     "facebook": "facebook",
     "twitter": "twitter",
     "more": "lainnya",
     "not connected": "tidak terhubung",
     "code": "kode",
     "ratings": "penilaian",
     "current password": "kata sandi saat ini",
     "include at least one uppercase and lowercase": "termasuk setidaknya satu huruf besar dan kecil",
     "my social network accounts": "akun jejaring sosial saya",
     "SMS ": "SMS",
     "view identity verfication guide": "lihat panduan verifikasi identitas",
     "boimetric face recognition": "pengenalan wajah biometrik",
     "please select a payer (withdrawl options)": "silakan pilih pembayar (opsi penarikan)",
     "IBAN number ": "nomor IBAN",
     "account holder name": "nama pemegang akun",
     "download data": "unduh data",
     "bank transfer": "transfer bank",
     "mobile wallet": "dompet seluler",
     "all bank names": "semua nama bank",
     "pay via card": "bayar dengan kartu",
     "you have entered an amount that is below the payer range": "Anda telah memasukkan jumlah yang berada di bawah kisaran pembayar",
     "trouble sending money": "masalah mengirim uang",
     "please follow these example": "silakan ikuti contoh ini",
     "wallet ID ": "ID Wallet",
     "username": "nama pengguna",
     "email": "email",
     "phone number": "nomor telepon",
     "no user found": "tidak ditemukan pengguna",
     "search": "pencarian",
     "scan/upload QR code of the recipient": "pindai/unggah kode QR penerima",
     "invite them": "undang mereka",
     "trouble quoting money": "masalah dalam mengutip uang",
     "select a country": "pilih negara",
     "airtime details": "detail pulsa",
     "enter the phone number": "masukkan nomor telepon",
     "service type": "jenis layanan",
     "select a service": "pilih layanan",
     "service list": "daftar layanan",
     "amount entered is less than the ....": "jumlah yang dimasukkan kurang dari ....",
     "total": "total",
     "total credit the receiver gets": "total kredit yang diterima penerima",
     "take ": "ambil",
     "OTP has been verified , You'll be redirected......": "OTP telah diverifikasi, Anda akan diarahkan......",
     "Link Your Instagram Account to InstaPay": "Hubungkan akun Instagram Anda ke InstaPay",
     "Step 01 – From Instagram App": "Langkah 01 – Dari aplikasi Instagram",
     "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Buka aplikasi Instagram, cari halaman profil \"InstaPay\", pilih \"Pesan\" untuk membuka kotak obrolan, ketik/ucapkan \"Hai\" dan klik kirim untuk memulai percakapan dengan chatbot InstaPay.",
     "Step 02 – From InstaPay Instagram Profile Page ": "Langkah 02 – Dari halaman profil Instagram InstaPay",
     "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Chatbot InstaPay akan menampilkan dua tombol tindakan: \"Daftar\" dan \"Hubungkan\". Pilih \"Hubungkan\", dan chatbot akan meminta Anda memasukkan nama pengguna InstaPay Anda.",
     "Step 03 – From InstaPay Instagram Profile Page": "Langkah 03 – Dari halaman profil Instagram InstaPay",
     "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Chatbot InstaPay kemudian akan meminta kode verifikasi InstaPay. Masukkan kode yang Anda peroleh dari pengaturan InstaPay di bawah \"Akun Jejaring Sosial Saya\" dan klik kirim.",
     "Step 04 – From InstaPay Instagram Profile Page": "Langkah 04 – Dari halaman profil Instagram InstaPay",
     "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Anda akan menerima pesan ucapan selamat yang mengonfirmasi bahwa akun Instagram Anda sekarang disinkronkan dengan InstaPay. Klik \"Menu Utama\" untuk mulai menggunakan layanan InstaPay.",
     "Additional Tips": "Tips tambahan",
     "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Amankan akun Anda: Pastikan akun Instagram Anda aman dengan mengaktifkan otentikasi dua faktor (2FA) untuk melindungi layanan InstaPay Anda yang tertaut.",
     "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Jelajahi lebih lanjut: Setelah ditautkan, jelajahi berbagai layanan yang ditawarkan InstaPay, mulai dari transaksi yang mulus hingga penarikan instan.",
     "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Tetap diperbarui: Pantau pembaruan kami, karena kami akan segera mendukung penautan akun jejaring sosial lainnya untuk membuat pengalaman Anda lebih terintegrasi.",
     "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Bantuan & Dukungan: Jika Anda mengalami masalah selama proses penautan, hubungi tim dukungan kami untuk mendapatkan bantuan.",
     "Future Enhancements": "Peningkatan masa depan",
     "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Dalam waktu dekat, InstaPay akan memungkinkan Anda untuk menautkan lebih banyak akun jejaring sosial, memberi Anda lebih banyak fleksibilitas dan kenyamanan. Nantikan pembaruan menarik!",
     "Personal Transactions": "Transaksi Pribadi",
     "Business Transactions": "Transaksi Bisnis",
     "Shopping & Purchases": "Belanja & Pembelian",
     "Bills & Utilities": "Tagihan & Utilitas",
     "Entertainment & Leisure": "Hiburan & Rekreasi",
     "Investments & Savings": "Investasi & Tabungan",
     "Health & Wellness": "Kesehatan & Kesejahteraan",
     "Transportation": "Transportasi",
     "Education": "Pendidikan",
     "Miscellaneous": "Lain-lain",
     "Family Support": "Dukungan keluarga",
     "Login With Phone Number": null,
     "Login": "Login",
     "Start Your InstaPay Journey Today!": "Mulailah Perjalanan InstaPay Anda Hari Ini!",
     "Enter your phone number": "Masukkan nomor telepon Anda",
     "Login with email": "Masuk dengan email",
     "Don't have an account?": "Tidak punya akun?",
     "Sign Up": "Daftar",
     "Continue": "Lanjutkan",
     "We recommend you download and install the Google Authenticator app": "Kami merekomendasikan Anda mengunduh dan menginstal aplikasi Google Authenticator",
     "Two-factor authentication (2FA) is the best way to protect yourself online.": "Otentikasi dua faktor (2FA) adalah cara terbaik untuk melindungi diri Anda secara online.",
     "Strong security with Google Authenticator": "Keamanan kuat dengan Google Authenticator",
     "Enter your password": "Masukkan kata sandi Anda",
     "Web Mobile": null,
     "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Kami merekomendasikan Anda mengunduh dan menginstal aplikasi Google Authenticator untuk memastikan Anda menerima kode verifikasi Anda secara instan, menghindari keterlambatan atau masalah dengan SMS.",
     "Web - Google Authenticator": null,
     "Google Authenticator": "Google Authenticator",
     "Two Factor Authentication Code": "Kode Otentikasi Dua Faktor",
     "Problem with the token?": "Masalah dengan token?",
     "Verify using SMS": "Gunakan SMS untuk memverifikasi",
     "Verify": "Verifikasi",
     "Web Mobile - Google Authenticator": null,
     "SMS - Web ": null,
     "SMS Verification": "Verifikasi SMS",
     "Enter the SMS code sent to +41********4053": "Masukkan kode SMS yang dikirim ke +41********4053",
     "Verify Using Authenticator App": "Gunakan aplikasi Authenticator untuk memverifikasi",
     "Resend code in 120 seconds": "Kirim ulang kode dalam 120 detik",
     "Verify ": "Verifikasi",
     "SMS - Web Mobile": null,
     "My own account": "Akun saya sendiri",
     "Friends & Family": "Teman & Keluarga",
     "Aunt": "Bibi",
     "Brother-in-law": "Ipar laki-laki",
     "Cousin": "Sepupu",
     "Daughter": "Anak perempuan",
     "Father": "Ayah",
     "Father-in-law": "Ayah mertua",
     "Friend": "Teman",
     "Grandfather": "Kakek",
     "Grandmother": "Nenek",
     "Husband": "Suami",
     "Mother": "Ibu",
     "Mother-in-law": "Ibu mertua",
     "Nephew": "Keponakan laki-laki",
     "Niece": "Keponakan perempuan",
     "Self (i.e. the sender, himself)": "Diri sendiri (yaitu pengirim)",
     "Sister": "Saudara perempuan",
     "Sister-in-law": "Ipar perempuan",
     "Son": "Anak laki-laki",
     "Uncle": "Paman",
     "Wife": "Istri",
     "Others not listed": "Lainnya yang tidak tercantum",
     "Merchant": "Pedagang",
     "No relationship": "Tidak ada hubungan",
     "Add Funds": "Tambah Dana",
     "Enter the Amount": "Masukkan jumlah:",
     "Fees": "Biaya:",
     "You Will Get": "Anda akan menerima:",
     "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Nilai transaksi harus antara [Currency ISO code & Amount] - [Currency ISO code & Amount]",
     "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Jumlah Tidak Valid: Tingkat verifikasi identitas Anda saat ini membatasi jumlah maksimum yang dapat Anda tambahkan. Harap tingkatkan verifikasi identitas Anda untuk mendapatkan batas yang lebih tinggi.",
     "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Harga mata uang sering berubah berdasarkan kondisi pasar. Silakan merujuk ke harga pada halaman konfirmasi pesanan sebagai penawaran akhir Anda.",
     "Next": "Berikutnya",
     "Upgrade Identity Verification": "Tingkatkan Verifikasi Identitas",
     "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Meminta uang dari siapa saja, di mana saja, termasuk melalui platform jejaring sosial. Dengan InstaPay, permintaan pembayaran Anda dapat diakses secara instan setelah diterima dan dana dikirim ke Akun Penerimaan Anda. Sederhanakan transaksi Anda dan kelola pembayaran dengan mudah dan aman.",
     "You’ve been logged out.": "Anda telah keluar.",
     "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Untuk keamanan Anda, Anda telah keluar setelah 15 menit tidak aktif. Silakan masuk lagi jika Anda ingin menggunakan InstaPay.",
     "LOGIN AGAIN": "MASUK LAGI",
     "Brother": "Saudara laki-laki",
     "Current Password": "Kata sandi saat ini",
     "The new password cannot be the same as the old one": "Kata sandi baru tidak boleh sama dengan yang lama.",
     "Minimum password length: [X] characters ": "Panjang kata sandi minimal: [X] karakter",
     "Include at least one uppercase and one lowercase letter.": "Sertakan setidaknya satu huruf kapital dan satu huruf kecil.",
     "Nominee Information": "Informasi Penerima",
     "Verification Code Preferences": "Preferensi Kode Verifikasi",
     "How Others Can Find You": "Bagaimana Orang Lain Dapat Menemukan Anda",
     "My Social Network Accounts": "Akun Jejaring Sosial Saya",
     "Login Activity": "Aktivitas Masuk",
     "Receiving Accounts": "Akun Penerimaan",
     "Change Your Password To Continue": "Ubah Kata Sandi Anda Untuk Melanjutkan",
     "Continue with": "Lanjutkan dengan",
     "Back to Login": "Kembali ke Login",
     "Send OTP": "Kirim OTP",
     "A Verification code has been sent to your email and number.": "Kode verifikasi telah dikirim ke email dan nomor Anda.",
     "Resend available in": "Kirim ulang tersedia dalam",
     "Create a strong Password": "Buat Kata Sandi yang Kuat",
     "Password must:": "Kata Sandi harus:",
     "Be between 9 and 64 characters": "Antara 9 dan 64 karakter",
     "Include at least two of the following:": "Sertakan setidaknya dua dari yang berikut:",
     "Lowercase Character": "Karakter Huruf Kecil",
     "Special Character": "Karakter Khusus",
     "Password Updated Successfully!": "Kata Sandi Berhasil Diperbarui!",
     "Click below to login": "Klik di bawah untuk login",
       "Send Money": "Kirim Uang",
     "Recent Transfer": "Transfer Terbaru",
     "See all": "Lihat semua",
     "Send again": "Kirim lagi",
     "You Send": "Anda Kirim",
     "They Receive": "Mereka Terima",
     "Receive Method": "Metode Penerimaan",
     "Select method": "Pilih metode",
     "Next": "Berikutnya",
     "Trouble sending money?": "Masalah mengirim uang?",
     "Where do you want to send money ?": "Di mana Anda ingin mengirim uang?",
     "Search ": "Cari",
     "Confirm": "Konfirmasi",
     "Bank Deposit": "Setoran Bank",
     "Cash Pick-up": "Pengambilan Tunai",
     "Mobile Wallet": "Mobile Wallet",
     "Bank Card": "Kartu Bank",
     "Crypto Wallet": "Wallet Kripto",
     "What is the receiver’s Mobile Wallet?": "Apa Wallet seluler penerima?",
     "Transfer fees": "Biaya transfer",
     "Total to pay": "Total yang harus dibayar",
     "Choose the beneficiary!": "Pilih penerima!",
     "Country": "Negara",
     "Receive method": "Metode penerimaan",
     "You send": "Anda kirim",
     "change": "edit",
     "edit": "tambahkan baru",
     "add new": "Penerima",
     "Beneficiaries": "Kelola Penerima",
     "Manage Beneficiaries": "Cari",
     "Search": "Berikutnya",
     "CASH PICK-UP": "PENGAMBILAN TUNAI",
     "Where will your receiver collect the money": "Di mana penerima Anda akan mengambil uangnya?",
     "Cash is available to collect within minutes from any branch of [name] ": "Uang tunai tersedia untuk diambil dalam beberapa menit dari cabang [nama] mana pun",
     "BANK ACCOUNT": "REKENING BANK",
     "Transfer to recipient’s bank account": "Transfer ke rekening bank penerima",
     "BANK CARD": "KARTU BANK",
     "Fast transfer directly to recipient’s bank card": "Transfer cepat langsung ke kartu bank penerima",
     "CRYPTO WALLET": "WALLET KRIPTO",
     "Fast transfer directly to a crypto wallet": "Transfer cepat langsung ke Wallet kripto",
     "Review transfer": "Tinjau transfer",
     "Receiver's Details": "Detail penerima",
     "Edit": "Edit",
     "Name": "Nama",
     "Reason for sending": "Alasan mengirim",
     "Sending": "Mengirim",
     "Receiver gets": "Penerima menerima",
     "Exchange rate": "Kurs",
     "Estimated time": "Perkiraan waktu",
     "Select Payment Type": "Pilih Jenis Pembayaran",
     "Credit cards": "Kartu kredit",
     "Domestic cards": "Kartu Domestik",
     "Mobile Wallets": "Wallet Seluler",
     "InstaPay Wallet": "Wallet InstaPay",
     "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
     "PayPal": "PayPal",
     "CREDIT CARD": "KARTU KREDIT",
     "Credit Card": "Kartu Kredit",
     "Select card": "Pilih kartu",
     "Card Number": "Nomor kartu",
     "Enter the 16-digit card number on the card": "Masukkan nomor kartu 16 digit",
     "Expiry Date": "Tanggal Kedaluwarsa",
     "Enter the expiration date of the card": "Masukkan tanggal kedaluwarsa kartu",
     "CVV Number": "Nomor CVV",
     "Enter the 3 or 4 digit number on the card": "Masukkan nomor 3 atau 4 digit pada kartu",
     "INSTAPAY WALLET": "WALLET INSTAPAY",
     "Select Wallet": "Pilih Wallet",
     "My Wallet": "Wallet saya",
     "Conversion": "Konversi",
     "Convert": "Konversi",
     "Your payment is confirmed": "Pembayaran Anda dikonfirmasi",
     "Thanks for using InstaPay": "Terima kasih telah menggunakan InstaPay",
     "Status": "Status",
     "Share": "Bagikan",
     "Transfer created": "Transfer dibuat",
     "Payment received": "Pembayaran diterima",
     "Payment processed": "Pembayaran diproses",
     "Transfer successful": "Transfer berhasil",
     "It may take few hours for the funds to appear in [user name] account": "Mungkin perlu beberapa jam agar dana muncul di akun [nama pengguna]",
     "Transfer failed": "Transfer gagal",
     "Transaction Details": "Detail Transaksi",
     "Need help?": "Butuh bantuan?",
     "Service Details": "Rincian Layanan",
     "Funds Deposit Speed": "Ketersediaan Dana",
     "Cut-off Time": "Batas Waktu",
     "Bank holiday list": "Daftar Libur Bank",
     "Threshold limits": "Batas Transaksi",
     "null": null,
     "Instant, Real Time": "Instan, waktu nyata",
     "Same day, T+1": "Hari yang sama, T+1",
     "Non-instant, T+1": "Tidak instan, T+1",
     "Instant Payment, Real Time": "Pembayaran instan, waktu nyata",
     "Transaction processed before 3:00 PM Local time": "Transaksi diproses sebelum pukul 15:00 waktu setempat",
     "Delivered the same day": "Dikirim pada hari yang sama",
     "Transactions processed post 3:00 PM": "Transaksi diproses setelah pukul 15:00",
     "T+1 business days": "T+1 hari kerja",
     "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Pembayaran yang dilakukan pada hari kerja sebelum pukul 15:00 akan dikreditkan ke rekening bank penerima pada tengah malam hari yang sama.",
     "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Pembayaran yang dilakukan pada hari kerja setelah pukul 15:00 akan dikreditkan pada tengah malam hari berikutnya.",
     "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Pembayaran yang dilakukan pada hari Sabtu, Minggu, atau hari libur nasional akan dikreditkan ke rekening pada tengah malam hari kerja berikutnya.",
     "24*7": "24*7",
     "09hrs - 18 hrs Local Time": "09:00 - 18:00 Waktu Setempat",
     "https:www.officeholidays.com/countries ": "https:www.officeholidays.com/countries",
     "Min per transaction limit:": "Batas minimum per transaksi:",
     "Maximum per transaction limit:": "Batas maksimum per transaksi:",
     "Daily threshold limit:": "Batas harian:",
     "Weekly threshold limit:": "Batas mingguan:",
     "Monthly threshold limit:": "Batas bulanan:",
     "Yearly threshold limit:": "Batas tahunan:",
     "P2P": "P2P",
     "P2B": "P2B",
     "B2P": "B2P",
     "B2B": "B2B",
     "WALLET TO WALLET": "WALLET TO WALLET",
     "Basic Account (Unverified)": "Akun Dasar (Tidak Terverifikasi)",
     "Identity Verified": "Identitas Terverifikasi",
     "Total Balance": "Saldo Rekening Total",
     "Quick Transfer": "Transfer Dana Cepat",
     "No transactions": "Tidak ada transaksi yang ditemukan",
     "Blocked balance": "Saldo diblokir",
     "Available balance":  "Saldo tersedia",
     "Insufficient Balanc in PKR": "Saldo tidak cukup dalam PKR",
     "Chat with us": "Chat dengan kami",
     "Add Currency": "Tambah Mata Uang",
     "Education": "Pendidikan",
     "Divers": "Lainnya",
     "Family support": "Dukungan keluarga",
     "Others": "Pengeluaran Lainnya",
     "Transport": "Transportasi",
     "schedule payment": "Pembayaran Terjadwal",
     "subscription payment": "Pembayaran Berulang",
     "No payment": "Tidak ada pembayaran yang ditemukan",
     "No Beneficiaries": "Tidak ada Penerima Manfaat yang Tersedia",
     "Code Postal": "Kode Pos",
     "Additional Information": "Informasi Tambahan",
     "Gender": "Jenis Kelamin",
     "Date Of Birth": "Tanggal Lahir",
     "Nationality": "Kebangsaan",
     "Occupation": "Pekerjaan (termasuk semua opsi)",
     "ID Type": "Jenis ID (termasuk semua opsi)",
     "ID Number": "Nomor ID",
     "Select Beneficiary Payment Channels": "Pilih Saluran Pembayaran Penerima Manfaat",
     "wallet crypto": "Dompet Kripto",
     "Add another wallet": "Tambah dompet lain",
      "Enterprise": "Bisnis",
     "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "Penerima manfaat bisnis akan segera hadir! Anda akan dapat melakukan transaksi bisnis secara global, memperluas jangkauan Anda melewati batas.",
     "Joined At": "Bergabung pada",
     "Profile Link": "Tautan Profil",
     "comission": "Komisi",
     "my withdrawls": "Penarikan Saya",
     "My Referrals": "Rujukan Saya",
     "search quotation": "Cari Kutipan",
     "no quotation": "Tidak ada kutipan yang ditemukan",
     "actions": "Tindakan",
     "search request": "Permintaan Pencarian",
     "no pending items ": "Tidak ada item yang menunggu ditemukan",
     "Share your QR Code or pay link to instantly get paid in your insta wallet": "Bagikan Kode QR atau tautan pembayaran Anda untuk segera menerima pembayaran ke dompet InstaPay Anda",
     "Description": "Deskripsi",
     "save": "Simpan",
     "Share your Insta-Pay unique payment link": "Bagikan tautan pembayaran InstaPay unik Anda",
     "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Tingkatkan profil publik Anda. Portofolio Anda berfungsi sebagai pameran dinamis, dapat diakses langsung dari Alamat Pembayaran Anda",
     "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Terhubung dengan audiens Anda melalui perjalanan profesional atau kreatif Anda. Biarkan karya Anda berbicara dan libatkan klien atau pendukung potensial lebih dalam.",
     "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Tampilkan jangkauan media sosial Anda. Pengunjung dapat melihat dampak media sosial Anda, menambah kredibilitas pada portofolio Anda.",
     "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Biarkan pengunjung meminta atau memulai pembayaran langsung dari halaman publik Anda. Portofolio Anda bukan hanya pameran, tetapi juga cara memudahkan transaksi keuangan.",
     "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Gunakan bagian \"Tentang Saya\" untuk berbagi cerita Anda, misi Anda, atau visi Anda, membangun koneksi pribadi dengan audiens Anda.",
     "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Portofolio Saya\" lebih dari sekadar galeri; ini adalah alat untuk membangun merek Anda, melibatkan audiens Anda, dan menyederhanakan interaksi keuangan di InstaPay.",
     "No Portfolio Items": "Tidak Ada Item Portofolio Ditemukan",
     "Add New Portfolio Item": "Tambahkan Item Portofolio Baru",
     "Title": "Judul",
     "drag/drop or upload a file(photo/video/pdf)": "Seret/Lepaskan atau unggah file (foto/video/pdf)",
     "discard": "Hapus",
     "save and post ": "Simpan dan Posting",
     "My Payment Address": "Alamat Pembayaran Saya",
     "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "Sangat cocok untuk influencer, seniman, wirausahawan, atau siapa saja yang ingin komunitasnya dengan mudah menunjukkan dukungan.",
     "Personal Account": "Status Akun Pribadi",
     "spent in total": "Total Dihabiskan",
     "not connected": "Tidak Terhubung",
     "more": "Lebih Banyak",
     "less": "Lebih Sedikit",
     "profile reports": "Laporan Profil",
     "All notifications are in English langauge": "Semua notifikasi dalam bahasa Inggris",
     "timezone": "Zona Waktu",
     "nominee information": "Informasi Calon Penerima",
     "full name": "Nama Lengkap",
     "Relation with Nominee": "Hubungan dengan Calon Penerima",
     "source": "Sumber",
     "Google Authenticator": "Google Authenticator",
     "notifications": "Notifikasi",
     "login": "Masuk",
     "View Identity Verification Guide": "Lihat Panduan Verifikasi Identitas",
     "verified": "Terverifikasi",
     "select ": "Pilih",
     "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "Ini harus merupakan dokumen ID yang dikeluarkan oleh pemerintah dengan foto. Semua identifikasi harus dalam alfabet Latin. Jika tidak, terjemahan bersertifikat diperlukan.",
     "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom berhak meminta dokumen tambahan (Enhanced Due Diligence - EDD) atau informasi lain yang diperlukan untuk melakukan verifikasi penuh, sesuai dengan kewajiban hukumnya.",
     "sessions": "Sesi",
     "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Aktifkan Penarikan Instan: Atur Akun Penerimaan Anda Sekarang!",
     "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "Kelola dengan mudah di mana Anda ingin menerima pembayaran dengan menambahkan detail rekening bank, dompet seluler, dompet kripto, kartu pembayaran, atau titik penjemputan tunai. Ikuti langkah-langkah di bawah ini untuk memastikan akses instan ke dana Anda kapan pun diperlukan.",
     "Select the receiving channel below and setup the details.": "Pilih saluran penerimaan di bawah ini dan atur detailnya.",
     "Please Select A Payer": "Silakan Pilih Pembayar",
     "Number": "Nomor",
     "Account Holder Name": "Nama Pemegang Rekening",
     "Province": "Provinsi",
     "postal": "Alamat Pos",
     "delete channel": "Hapus Saluran",
     "set as default": "Tetapkan Sebagai Default",
     "edit": "Edit",
     "all channels are in english language in recieving account page": "Semua saluran dalam bahasa Inggris pada halaman akun penerimaan",
     "select file type": "Pilih Jenis File",
     "download data": "Unduh Data",
     "INTERNATIONAL TRANSFER": "TRANSFER INTERNASIONAL",
     "enter the amount": "Masukkan Jumlah",
     "they recieve ": "Penerima Menerima",
     "trouble sending money": "Masalah Mengirim Uang?",
     "select destination country": "Pilih Negara Tujuan",
     "bank transfer": "Transfer Bank",
     "recieving method": "Metode Penerimaan",
     "payer": "Pembayar",
     "The fee and the received amount may vary depending on the chosen Payment Method": "Biaya dan jumlah yang diterima dapat bervariasi berdasarkan metode pembayaran yang dipilih.",
     "search ": "Cari",
     "show more": "Tampilkan Lebih Banyak",
     "select remark reasons": "Pilih Alasan Catatan",
     "credit and debit card": "Kartu Kredit dan Debit",
     "Transfer Fees": "Biaya Transfer",
     "Estimated Time": "Estimasi Waktu:",
     "within minutes": "Dalam Hitungan Menit",
     "total ": "Total",
     "OTP verifications code": "Kode Verifikasi OTP",
     "verify using sms/email": "Verifikasi Menggunakan SMS/Email",
     "sms/email verification code": "Kode Verifikasi SMS/Email",
     "Verify Using Authenticator": "Verifikasi Menggunakan Authenticator",
     "WALLET TO WALLET TRANSFER": "TRANSFER DOMPET KE DOMPET",
     "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Masukkan ID Dompet, Nama Pengguna, Email, atau Nomor Telepon penerima.",
     "search": "Cari",
     "lookup": "Lihat",
     "Recipient Not on InstaPay?": "Penerima Tidak Ada di InstaPay?",
     "invite them": "Undang mereka",
     "Please follow these examples": "Silakan ikuti contoh berikut:",
     "Wallet ID: GY68J782": "ID Dompet: GY68J782",
     "InstaPay/Instagram Username": "Nama Pengguna InstaPay/Instagram: instauser",
     "Email: user@email.com": "Email: user@email.com",
     "With Country code": "Nomor Telepon (dengan Kode Negara)",
     "invite friend": "Undang Teman",
     "invite via phone number": "Undang melalui Nomor Telepon",
     "invite via email address": "Undang melalui Alamat Email",
     "next": "Lanjut",
     "back": "Kembali",
     "Personalized Message": "Pesan Pribadi",
     "available currency": "Mata Uang Tersedia",
     "attach files": "Lampirkan Berkas",
     "total amount": "Jumlah Total",
     "instant payment": "Pembayaran Instan",
     "starting from": "Mulai Dari",
     "recurring cycle": "Siklus Berulang",
     "until I stop": "Sampai Dihentikan",
     "start from": "Mulai Dari",
     "cycle": "Siklus",
     "total": "Total",
     "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "Penerima mungkin menerima jumlah yang sedikit disesuaikan karena kurs pertukaran.",
     "schedule to": "Dijadwalkan Untuk",
     "schedule at": "Dijadwalkan Pada",
     "Scheduled Date": "Tanggal Dijadwalkan",
     "Scheduled time": "Waktu Dijadwalkan",
     "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "Penerima mungkin menerima jumlah yang sedikit disesuaikan karena kurs pertukaran.",
     "QR CODE PAYMENT ": "PEMBAYARAN KODE QR",
     "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "Sebagai alternatif, gunakan Kode QR Alfanumerik InstaPay.",
     "PYAYMENT REQUEST": "PERMINTAAN PEMBAYARAN",
     "choose the beneficiary": "Pilih Penerima",
     "trouble requesting money": "Masalah Meminta Uang?",
     "Standard Request": "Permintaan Standar",
     "Subscription Request": "Permintaan Berulang",
     "Schedule Request": "Permintaan Terjadwal",
     "Security Request": "Permintaan Deposit Keamanan",
     "Instalment Request": "Rencana Angsuran",
     "Split Request": "Pembayaran Terpisah",
     "You recieved": "Anda menerima",
     "is this your location?": "Apakah ini lokasi Anda?",
     "yes": "Ya",
     "Authenticator Code": "Kode Authenticator",
     "Enter a code shown in the app to make sure everything works fine.": "Masukkan kode yang ditampilkan di aplikasi untuk memastikan semuanya benar.",
     "SEND A QUOTE": "KIRIM PENAWARAN",
     "Trouble Quoting Money?": "Masalah Mengirim Penawaran?",
     "Please follow these examples:": "Silakan ikuti contoh berikut:",
     "Full Name: Muhammad Imtiaz": "Nama Lengkap: Muhammad Imtiaz",
     "InstaPay/Intagram Username: instauser": "Nama Pengguna InstaPay/Instagram: instauser",
     "Phone Number: 44795396600 (With Country Code)": "Nomor Telepon: 44795396600 (dengan Kode Negara)",
     "SEND TOP-UP AND E-SIM": "KIRIM ISI ULANG DAN E-SIM",
     "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "Tetap terhubung di seluruh dunia! Layanan Isi Ulang InstaPay memungkinkan Anda mengisi ulang ponsel secara instan, membeli eSIM, voucher bermerek, kartu hadiah, dan membayar tagihan utilitas di lebih dari 150 negara. Ideal untuk hadiah atau memastikan diri Anda siap, layanan kami mengutamakan kemudahan, kecepatan, dan keamanan.",
     "MOBILE AIRTIME AND E-SIM": "PULSA SELULER DAN E-SIM",
     "Select the country where you want to top-up a mobile phone!": "Pilih negara untuk isi ulang ponsel!",
     "Other Operator": "Operator Lain",
     "airtime": "Pulsa",
     "bundle": "Paket",
     "data": "Data",
     "Enter custom amount": "Masukkan Jumlah Khusus",
     "Credit & Debit Card": "Kartu Kredit & Debit",
     "Bank Transfer": "Transfer Bank",
     "Mobile Wallet": "Dompet Seluler",
     "InstaPay Wallet": "Dompet InstaPay",
     "PayPal": "PayPal",
     "add card payment": "Tambahkan Pembayaran Kartu",
     "save details to use next time.": "Simpan Detail untuk Penggunaan di Masa Depan",
     "Recipient Mobile Number": "Nomor Telepon Penerima",
     "Amount": "Jumlah",
     "Service": "Layanan",
     "Destination": "Tujuan",
     "Provider": "Penyedia",
     "Airtime": "Pulsa",
     "Pakistan": "Pakistan",
     "Telenor Pakistan": "Telenor Pakistan",
     "Within minutes": "Dalam Hitungan Menit",
     "top-up summary": "Ringkasan Isi Ulang",
     "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR tidak didukung oleh PayPal, USD akan digunakan sebagai default.",
     "Amount in USD": "Jumlah dalam USD",
     "Send Top-up": "Kirim Isi Ulang",
     "available sunservices": "Layanan Matahari yang Tersedia",
     "login with email": "Masuk Dengan Email",
     "Already Have an Account?": "Sudah Punya Akun? Masuk",
     "profile picture": "Foto Profil",
     "add photo/logo": "Tambah Foto/Logo",
     "first name": "Nama Depan",
     "enter your first name": "Masukkan Nama Depan Anda",
     "last name": "Nama Belakang",
     "enter your last name": "Masukkan Nama Belakang Anda",
     "email(optional)": "Email (Opsional)",
     "enter your email address": "Masukkan Alamat Email Anda",
     "password must": "Kata Sandi Wajib",
     "It will be autofill, if you're signing up with invitation link": "Isi Otomatis untuk Pendaftaran Undangan",
     "change number": "Ubah Nomor",
     "signup error": "Kesalahan Pendaftaran",
     "Something went wrong while sending sms!": "Terjadi kesalahan saat mengirim SMS! Silakan coba lagi nanti.",
     "Errors": "Terjadi kesalahan. Silakan lihat daftar berikut untuk detailnya.",
     "Generic error": "Kesalahan Umum",
     "Trust Payments API requires the 'requests' library": "API Trust Payments memerlukan perpustakaan 'requests' agar berfungsi dengan baik.",
     "Send error": "Kesalahan saat proses pengiriman. Silakan coba lagi.",
     "Receive error": "Kesalahan saat proses penerimaan. Silakan coba lagi.",
     "Invalid credentials provided": "Kredensial tidak valid. Silakan periksa dan coba lagi.",
     "An issue occurred whilst trying to connect to Trust Payments servers": "Terjadi masalah saat menghubungkan ke server Trust Payments. Silakan periksa koneksi Anda dan coba lagi.",
     "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "Kesalahan tak terduga saat menghubungkan ke server Trust Payments. Jika masalah ini terus terjadi, hubungi support@trustpayments.com.",
     "Unknown error. If this persists please contact Trust Payments": "Kesalahan tidak diketahui. Silakan hubungi dukungan Trust Payments jika masalah berlanjut.",
     "Incorrect usage of the Trust Payments API": "Penggunaan API Trust Payments tidak benar. Silakan periksa permintaan dan coba lagi.",
     "Invalid card details": "Detail kartu tidak valid. Silakan periksa dan masukkan detail yang benar.",
     "Invalid date": "Tanggal tidak valid yang diberikan.",
     "Invalid date/time": "Format tanggal/waktu tidak valid. Silakan berikan format yang benar.",
     "Invalid details": "Detail tidak valid. Silakan periksa dan masukkan informasi yang benar.",
     "Card number does not match card type": "Nomor kartu tidak cocok dengan jenis kartu yang ditentukan.",
     "Malformed XML": "Kesalahan pemformatan XML. Silakan periksa dan koreksi XML.",
     "XML does not match schema": "XML tidak sesuai dengan skema yang diharapkan.",
     "Invalid file format": "Format file tidak valid. Silakan gunakan format yang didukung.",
     "Empty file contents": "Isi file kosong. Silakan berikan isi yang valid.",
     "Invalid file contents": "Isi file tidak valid. Silakan periksa file dan coba lagi.",
     "Malformed JSON": "JSON tidak sesuai. Silakan koreksi format JSON.",
     "StApi Error": "Kesalahan StApi",
     "Invalid fields specified in request": "Kolom tidak valid yang ditentukan dalam permintaan. Silakan periksa dan koreksi.",
     "Missing parent": "Informasi induk hilang.",
     "Refund requires settled parent or parent that's due to settle today": "Pengembalian dana memerlukan transaksi induk yang sudah diselesaikan atau yang jatuh tempo hari ini.",
     "Refund requires authorisation parent": "Pengembalian dana memerlukan transaksi otorisasi induk.",
     "Refund amount too great": "Jumlah pengembalian dana melebihi batas yang diizinkan.",
     "No acquirer specified": "Tidak ada informasi akuisisi yang diberikan.",
     "Repeat amount too great": "Jumlah pengulangan melebihi batas yang diizinkan.",
     "Split amount too great": "Jumlah pembagian melebihi batas yang diizinkan.",
     "Cannot refund a declined transaction": "Tidak dapat mengembalikan transaksi yang ditolak.",
     "Refund requires a settled parent": "Pengembalian dana memerlukan transaksi induk yang sudah diselesaikan.",
     "Reversal requires a cancelled auth parent": "Pembatalan memerlukan transaksi otorisasi yang dibatalkan.",
     "Cannot override amount in child transaction": "Tidak dapat mengganti jumlah dalam transaksi anak.",
     "Cannot override currency in child transaction": "Tidak dapat mengganti mata uang dalam transaksi anak.",
     "Subscription requires RECUR account": "Berlangganan memerlukan pengaturan akun berulang.",
     "Subscription requires successful parent": "Berlangganan memerlukan transaksi induk yang berhasil.",
     "Risk Decisions must have AUTH as parent": "Keputusan risiko harus terkait dengan transaksi otorisasi.",
     "Chargebacks must have AUTH/REFUND as parent": "Chargeback harus ditautkan ke transaksi otorisasi atau pengembalian dana.",
     "Refund amount less than Minimum allowed": "Jumlah pengembalian dana kurang dari minimum yang diizinkan.",
     "Refund requires paypaltransactionid": "Pengembalian dana memerlukan ID transaksi PayPal.",
     "Invalid split transaction": "Transaksi pembagian tidak valid.",
     "Cannot reverse AUTH processed more than 48 hours ago": "Tidak dapat membatalkan otorisasi yang diproses lebih dari 48 jam yang lalu.",
     "Reversal requires acquirerreferencedata": "Pembatalan memerlukan data referensi akuisisi.",
     "Cannot reverse AUTH processed by a different acquirer": "Tidak dapat membatalkan otorisasi yang diproses oleh akuisisi yang berbeda.",
     "Payment type does not support repeats": "Jenis pembayaran tidak mendukung transaksi berulang.",
     "Reversal missing required data": "Pembatalan tidak memiliki data yang diperlukan.",
     "Missing token": "Informasi token hilang.",
     "Subscription with an accountcheck parent not supported on current acquirer": "Berlangganan dengan cek akun induk tidak didukung oleh akuisisi saat ini.",
     "Subscription cannot be used as a parent": "Berlangganan tidak dapat digunakan sebagai transaksi induk.",
     "Invalid parent": "Jenis transaksi induk tidak valid.",
     "Payment type does not support refunds": "Jenis pembayaran tidak mendukung pengembalian dana.",
     "Invalid incremental transaction": "Transaksi bertahap tidak valid.",
     "Partial reversals not supported": "Pembalikan parsial tidak didukung.",
     "THREEDQUERY parent/child must have the same payment type as the child": "Transaksi induk dan anak harus memiliki jenis pembayaran yang sama untuk permintaan keamanan 3D.",
     "Payment type does not support card scheme updates": "Jenis pembayaran tidak mendukung pembaruan skema kartu.",
     "Cannot reverse AUTH at this time, please try again": "Tidak dapat membatalkan otorisasi saat ini. Silakan coba lagi nanti.",
     "Cannot determine token": "Tidak dapat menentukan token. Silakan periksa dan coba lagi.",
     "Service Temporarily Disabled": "Layanan sementara tidak tersedia. Silakan coba lagi nanti.",
     "Login first request": "Masuk diperlukan sebelum membuat permintaan.",
     "Invalid username/password": "Nama pengguna atau kata sandi tidak valid. Silakan coba lagi.",
     "Invalid session": "Sesi tidak valid. Silakan masuk lagi.",
     "Session has expired": "Sesi berakhir. Silakan masuk lagi.",
     "Password expired": "Kata sandi berakhir. Silakan atur ulang kata sandi Anda.",
     "Password has been previously used": "Kata sandi telah digunakan sebelumnya. Silakan pilih kata sandi baru.",
     "MyST user account has been locked": "Akun pengguna MyST terkunci. Silakan hubungi dukungan.",
     "New password does not match confirmed password": "Kata sandi baru tidak cocok dengan kata sandi yang dikonfirmasi.",
     "Incorrect current password": "Kata sandi saat ini salah. Silakan coba lagi.",
     "Invalid selection": "Pilihan tidak valid dibuat. Silakan pilih opsi yang valid.",
     "User already exists": "Pengguna sudah ada. Silakan masuk atau atur ulang kata sandi jika diperlukan.",
     "No transaction found": "Tidak ada transaksi ditemukan.",
     "Invalid selected transactions": "Transaksi yang dipilih tidak valid.",
     "Data supplied has not been saved": "Data belum disimpan. Silakan coba lagi.",
     "Invalid request type": "Jenis permintaan tidak valid.",
     "Missing request type, at least one request type must be selected": "Jenis permintaan hilang. Setidaknya satu harus dipilih.",
     "Invalid payment type": "Jenis pembayaran tidak valid.",
     "Missing payment type, at least one payment type must be selected": "Jenis pembayaran hilang. Setidaknya satu harus dipilih.",
     "Invalid error code": "Kode kesalahan tidak valid.",
     "Missing error code, at least one error code must be selected": "Kode kesalahan hilang. Setidaknya satu harus dipilih.",
     "Invalid filter description": "Deskripsi filter tidak valid.",
     "Invalid destination description": "Deskripsi tujuan tidak valid.",
     "Invalid notification type": "Jenis notifikasi tidak valid.",
     "Invalid destination": "Tujuan tidak valid.",
     "Invalid field selected": "Kolom yang dipilih tidak valid.",
     "Invalid email from address": "Alamat email pengirim tidak valid.",
     "Invalid email subject": "Judul email tidak valid.",
     "Invalid email type": "Jenis email tidak valid.",
     "Unable to process request": "Tidak dapat memproses permintaan. Silakan coba lagi nanti.",
     "No file selected for upload": "Tidak ada file yang dipilih untuk diunggah.",
     "Invalid file size": "Ukuran file tidak valid. Silakan berikan file dengan ukuran yang dapat diterima.",
     "Invalid filename": "Nama file tidak valid.",
     "Invalid extension": "Ekstensi file tidak valid.",
     "User requires at least one site reference": "Pengguna memerlukan setidaknya satu referensi situs.",
     "Only ST-level users can have '*' access": "Hanya pengguna level ST yang dapat memiliki hak akses '*'.",
     "Request failed": "Permintaan gagal. Silakan coba lagi nanti.",
     "Invalid File Contents": "Isi file tidak valid.",
     "Maximum number of files uploaded": "Jumlah maksimum file yang diunggah tercapai. Silakan hapus beberapa file dan coba lagi.",
     "Insufficient gateway access privileges": "Hak akses gateway tidak mencukupi.",
     "Maximum file size limit reached": "Batas ukuran file maksimum tercapai.",
     "Username(s) must be a valid user(s)": "Username harus merupakan pengguna yang valid.",
     "Site reference(s) must be a valid site(s)": "Referensi situs harus merupakan situs yang valid.",
     "Unable to send email, please verify the details and try again": "Tidak dapat mengirim email. Silakan verifikasi detailnya dan coba lagi.",
     "Negative already exists": "Nilai negatif sudah ada. Silakan verifikasi dan lakukan koreksi.",
     "Cannot delete a search owned by another user": "Tidak dapat menghapus pencarian yang dimiliki pengguna lain.",
     "Invalid search": "Pencarian tidak valid. Silakan coba lagi dengan kriteria yang valid.",
     "Cannot delete the specified search, the search name cannot be found": "Tidak dapat menghapus pencarian yang ditentukan. Nama pencarian tidak ditemukan.",
     "Search parameter is too short": "Parameter pencarian terlalu pendek. Silakan masukkan istilah pencarian yang lebih spesifik.",
     "Duplicate custom fields defined": "Bidang kustom duplikat telah ditentukan. Silakan selesaikan duplikatnya.",
     "Cannot allocate selected users, insufficient privileges": "Tidak dapat mengalokasikan pengguna yang dipilih. Hak istimewa tidak mencukupi.",
     "Allocated users have access to additional sites": "Pengguna yang dialokasikan memiliki akses ke situs tambahan.",
     "Allocated users have access to additional users": "Pengguna yang dialokasikan memiliki akses ke pengguna tambahan.",
     "User with current role cannot be allocated users": "Pengguna dengan peran saat ini tidak dapat mengalokasikan pengguna lain.",
     "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Situs ini memerlukan browser Anda menerima cookie untuk masuk. Cookie dapat diterima dengan mengklik 'Saya setuju' di bawah ini.",
     "User requires at least one site reference or site group": "Pengguna memerlukan setidaknya satu referensi situs atau grup situs.",
     "Allocated users have access to additional site groups": "Pengguna yang dialokasikan memiliki akses ke grup situs tambahan.",
     "No statement found": "Tidak ada pernyataan yang ditemukan.",
     "Data supplied has not been updated in MobilePay 3rd-party service": "Data yang disediakan belum diperbarui dalam layanan pihak ketiga MobilePay.",
     "Bypass": "Bypass",
     "Insufficient access privileges": "Hak akses tidak mencukupi.",
     "Coding error": "Kesalahan pengkodean terdeteksi.",
     "Insufficient privileges": "Hak istimewa tidak mencukupi untuk menyelesaikan tindakan ini.",
     "Invalid request": "Permintaan tidak valid. Silakan verifikasi dan coba lagi.",
     "Invalid field": "Bidang yang dimasukkan tidak valid. Silakan verifikasi dan koreksi entri.",
     "Unknown site": "Situs tidak dikenal.",
     "Banned card": "Kartu diblokir.",
     "XML element parse error": "Kesalahan parsing elemen XML. Silakan verifikasi struktur XML.",
     "Maestro must use SecureCode": "Transaksi Maestro harus menggunakan SecureCode untuk validasi.",
     "Multiple email addresses must be separated with , or ;": "Beberapa alamat email harus dipisahkan dengan koma (,) atau titik koma (;).",
     "Invalid site reference for alias": "Referensi situs untuk alias tidak valid.",
     "Invalid version number": "Nomor versi tidak valid. Silakan masukkan nomor versi yang valid.",
     "Unknown user": "Pengguna tidak dikenal. Silakan verifikasi dan coba lagi.",
     "Cannot determine account": "Tidak dapat menentukan akun.",
     "JSON element parse error": "Kesalahan parsing elemen JSON. Silakan verifikasi struktur JSON.",
     "Wallet type configuration error": "Kesalahan konfigurasi jenis dompet.",
     "Wallet type not supported on this request": "Jenis dompet tidak didukung untuk permintaan ini.",
     "The card number you have provided is incorrect, please verify your details and try again": "Nomor kartu yang diberikan salah. Silakan verifikasi detail Anda dan coba lagi.",
     "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "Kode keamanan (CVV2) yang Anda berikan salah. Silakan verifikasi dan coba lagi.",
     "The expiry date you have provided is incorrect, please verify your details and try again": "Tanggal kedaluwarsa yang diberikan salah. Silakan verifikasi detail Anda dan coba lagi.",
     "The expiry month you have provided is incorrect, please verify your details and try again": "Bulan kedaluwarsa yang diberikan salah. Silakan verifikasi dan coba lagi.",
     "The expiry year you have provided is incorrect, please verify your details and try again": "Tahun kedaluwarsa yang diberikan salah. Silakan verifikasi dan coba lagi.",
     "Unable to process your payment due to connection errors - request id mismatch, please try again": "Tidak dapat memproses pembayaran Anda karena kesalahan koneksi. ID permintaan tidak cocok. Silakan coba lagi.",
     "The issue number you have provided is incorrect, please verify your details and try again": "Nomor masalah yang diberikan salah. Silakan verifikasi dan coba lagi.",
     "The payment type you have provided is incorrect, please verify your details and try again": "Jenis pembayaran yang diberikan salah. Silakan verifikasi dan coba lagi.",
     "Unable to process your payment, please contact the website": "Tidak dapat memproses pembayaran Anda. Silakan hubungi situs web untuk dukungan.",
     "There are errors with these fields: {0}": "Ada kesalahan pada bidang berikut: {0}",
     "No account found": "Tidak ada akun yang ditemukan.",
     "Refund cannot be processed": "Pengembalian dana tidak dapat diproses.",
     "Transaction de-activated": "Transaksi dinonaktifkan.",
     "Socket receive error": "Kesalahan penerimaan soket.",
     "Socket connection error": "Kesalahan koneksi soket.",
     "Socket closed": "Soket ditutup.",
     "Invalid data received": "Data yang diterima tidak valid.",
     "Invalid SQL": "Kueri SQL tidak valid.",
     "Timeout": "Batas waktu terjadi.",
     "Invalid acquirer": "Detail akuisisi tidak valid.",
     "Unable to connect to acquirer": "Tidak dapat terhubung ke akuisisi.",
     "Invalid response from acquirer": "Respon dari akuisisi tidak valid.",
     "No available transport": "Tidak ada transportasi yang tersedia.",
     "File size too large": "Ukuran file terlalu besar.",
     "Socket send error": "Kesalahan pengiriman soket.",
     "Communication error": "Kesalahan komunikasi.",
     "Proxy error": "Kesalahan proxy.",
     "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "Tidak dapat memproses pembayaran Anda karena kesalahan koneksi. Silakan verifikasi detail Anda dan coba lagi ({0}).",
     "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "Tidak dapat memproses pembayaran Anda karena kesalahan koneksi (status respons HTTP {0}). Silakan verifikasi detail Anda dan coba lagi ({1}).",
     "Wrong number of emails": "Jumlah alamat email yang diberikan salah.",
     "Bank System Error": "Kesalahan sistem bank.",
     "Wrong number of transactions": "Jumlah transaksi yang diberikan salah.",
     "Invalid transport configuration": "Konfigurasi transportasi tidak valid.",
     "No valid updates specified": "Tidak ada pembaruan yang valid yang ditentukan.",
     "Transaction reference not found": "Referensi transaksi tidak ditemukan.",
     "Settlebaseamount too large": "Jumlah penyelesaian dasar terlalu besar.",
     "Transaction not updatable": "Transaksi tidak dapat diperbarui.",
     "No searchable filter specified": "Tidak ada filter yang dapat dicari yang ditentukan.",
     "Timeout Error": "Kesalahan batas waktu. Silakan coba lagi.",
     "3-D Secure Transport Error": "Kesalahan transportasi 3-D Secure.",
     "Unauthenticated": "Permintaan tidak terotentikasi.",
     "Site Suspended": "Situs ditangguhkan.",
     "No updates performed": "Tidak ada pembaruan yang dilakukan.",
     "Invalid Request": "Permintaan tidak valid.",
     "Invalid Response": "Respons tidak valid.",
     "Invalid Acquirer": "Informasi akuisisi tidak valid.",
     "Invalid account data": "Data akun tidak valid.",
     "Missing": "Informasi yang hilang.",
     "Payment Error": "Kesalahan pembayaran.",
     "Invalid acquirer for 3-D Secure": "Akuisisi tidak valid untuk 3-D Secure.",
     "Invalid payment type for 3-D Secure": "Jenis pembayaran tidak valid untuk 3-D Secure.",
     "Invalid updates specified": "Pembaruan tidak valid yang ditentukan.",
     "Manual investigation required": "Penyelidikan manual diperlukan.",
     "Invalid headers": "Header tidak valid.",
     "Max fraudscore exceeded": "Skor penipuan maksimum terlampaui.",
     "Invalid filters": "Filter yang diberikan tidak valid.",
     "Merchant System Error": "Kesalahan sistem pedagang.",
     "Your payment is being processed. Please wait...": "Pembayaran Anda sedang diproses. Silakan tunggu...",
     "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "Tidak dapat menentukan 'deskripsi jenis permintaan' dan 'deskripsi jenis permintaan' dalam satu permintaan.",
     "Acquirer missing original transaction data": "Akuisisi kehilangan data transaksi asli.",
     "Insufficient funds": "Dana tidak mencukupi.",
     "Unable to process due to scheme restrictions": "Tidak dapat diproses karena pembatasan skema.",
     "Failed Screening": "Gagal penyaringan.",
     "Unable to process due to restrictions": "Tidak dapat diproses karena pembatasan.",
     "Invalid process": "Proses tidak valid.",
     "Risk Referral": "Diperlukan rujukan risiko.",
     "Name Pick required": "Nama diperlukan untuk pemilihan.",
     "Address Pick required": "Alamat diperlukan untuk pemilihan.",
     "IP not in range": "Alamat IP tidak dalam jangkauan.",
     "Invalid button configuration": "Konfigurasi tombol tidak valid.",
     "Unrecognised response from acquirer": "Respon tidak dikenali dari akuisisi.",
     "Decline": "Ditolak.",
     "Uncertain result": "Hasil tidak pasti.",
     "Soft Decline": "Penolakan lembut.",
     "Refer to Issuer": "Hubungi penerbit untuk informasi lebih lanjut.",
     "Request is queued please check the transaction later for the status": "Permintaan sedang dalam antrean. Silakan periksa status transaksi nanti.",
     "Generic Retry": "Ulangi secara umum.",
     "Soft Decline retry": "Ulangi penolakan lembut.",
     "There has been a problem with your payment, please verify your details and try again": "Terjadi masalah dengan pembayaran Anda. Silakan verifikasi detail Anda dan coba lagi.",
     "Unknown error": "Kesalahan tidak dikenal.",

    "Afghanistan": "Afganistan",
    "Aland Islands": "Kepulauan Åland",
    "Albania": "Albania",
    "Algeria": "Aljazair",
    "American Samoa": "Samoa Amerika",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antigua and Barbuda": "Antigua dan Barbuda",
    "Argentina": "Argentina",
    "Armenia": "Armenia",
    "Aruba": "Aruba",
    "Australia": "Australia",
    "Austria": "Austria",
    "Azerbaijan": "Azerbaijan",
    "Bahamas": "Bahama",
    "Bahrain": "Bahrain",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Belarusia",
    "Belgium": "Belgia",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Bhutan",
    "Bolivia (Plurinational State of)": "Bolivia (Negara Plurinasi)",
    "Bonaire - Sint Eustatius and Saba": "Bonaire - Sint Eustatius dan Saba",
    "Bosnia and Herzegovina": "Bosnia dan Herzegovina",
    "Botswana": "Botswana",
    "Brazil": "Brasil",
    "British Indian Ocean Territory": "Wilayah Samudra Hindia Britania",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgaria",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Tanjung Verde",
    "Cambodia": "Kamboja",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cayman Islands": "Kepulauan Cayman",
    "Central African Republic": "Republik Afrika Tengah",
    "Chad": "Chad",
    "Chile": "Cile",
    "China": "Tiongkok",
    "Christmas Island": "Pulau Natal",
    "Cocos (Keeling) Islands": "Kepulauan Cocos (Keeling)",
    "Colombia": "Kolombia",
    "Comoros": "Komoro",
    "Congo": "Republik Kongo",
    "Congo - Democratic Republic of the": "Kongo - Republik Demokratik",
    "Cook Islands": "Kepulauan Cook",
    "Costa Rica": "Kosta Rika",
    "Cote d'Ivoire": "Pantai Gading",
    "Croatia": "Kroasia",
    "Cuba": "Kuba",
    "Curacao": "Kuraçao",
    "Cyprus": "Siprus",
    "Czech Republic": "Republik Ceko",
    "Denmark": "Denmark",
    "Djibouti": "Djibouti",
    "Dominica": "Dominika",
    "Dominican Republic": "Republik Dominika",
    "Ecuador": "Ekuador",
    "Egypt": "Mesir",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Guinea Khatulistiwa",
    "Eritrea": "Eritrea",
    "Estonia": "Estonia",
    "Eswatini": "Eswatini",
    "Ethiopia": "Etiopia",
    "Falkland Islands (Malvinas)": "Kepulauan Falkland (Malvinas)",
    "Faroe Islands": "Kepulauan Faroe",
    "Fiji": "Fiji",
    "Finland": "Finlandia",
    "France": "Prancis",
    "French Polynesia": "Polinesia Prancis",
    "French Southern Territories": "Wilayah Selatan Prancis",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgia",
    "Germany": "Jerman",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Yunani",
    "Greenland": "Greenlandia",
    "Grenada": "Grenada",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea (Conakry)": "Guinea (Conakry)",
    "Guinea-Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Heard Island and McDonald Islands": "Pulau Heard dan Kepulauan McDonald",
    "Holy See": "Takhta Suci",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hungaria",
    "Iceland": "Islandia",
    "India": "India",
    "Indonesia": "Indonesia",
    "Iran (Islamic Republic of)": "Iran (Republik Islam)",
    "Iraq": "Irak",
    "Ireland": "Irlandia",
    "Isle of Man": "Isle of Man",
    "Israel": "Israel",
    "Italy": "Italia",
    "Jamaica": "Jamaika",
    "Japan": "Jepang",
    "Jersey": "Jersey",
    "Jordan": "Yordania",
    "Kazakhstan": "Kazakhstan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Korea (Democratic People's Republic of)": "Korea (Republik Rakyat Demokratik)",
    "Korea - Republic of": "Korea - Republik Korea",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Kirgistan",
    "Lao People's Democratic Republic": "Republik Demokratik Rakyat Laos",
    "Latvia": "Latvia",
    "Lebanon": "Lebanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libya",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Lituania",
    "Luxembourg": "Luxembourg",
    "Macao": "Macao",
    "Madagascar": "Madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Malaysia",
    "Maldives": "Maladewa",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Kepulauan Marshall",
    "Mauritania": "Mauritania",
    "Mauritius": "Mauritius",
    "Mexico": "Meksiko",
    "Micronesia (Federated States of)": "Mikronesia (Negara Federasi)",
    "Moldova - Republic of": "Moldova - Republik",
    "Monaco": "Monako",
    "Mongolia": "Mongolia",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Morocco": "Maroko",
    "Mozambique": "Mozambik",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Belanda",
    "New Caledonia": "Kaledonia Baru",
    "New Zealand": "Selandia Baru",
    "Nicaragua": "Nikaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Pulau Norfolk",
    "North Macedonia": "Makedonia Utara",
    "Northern Mariana Islands": "Kepulauan Mariana Utara",
    "Norway": "Norwegia",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestine - State of": "Palestina - Negara",
    "Panama": "Panama",
    "Papua New Guinea": "Papua Nugini",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Filipina",
    "Pitcairn": "Pitcairn",
    "Poland": "Polandia",
    "Portugal": "Portugal",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Qatar",
    "Romania": "Rumania",
    "Russian Federation": "Federasi Rusia",
    "Rwanda": "Rwanda",
    "Saint Barth\u00e9lemy": "Saint Barthélemy",
    "Saint Helena - Ascension and Tristan da Cunha": "Saint Helena - Ascension dan Tristan da Cunha",
    "Saint Kitts and Nevis": "Saint Kitts dan Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Martin (French part)": "Saint Martin (bagian Prancis)",
    "Saint Vincent and the Grenadines": "Saint Vincent dan Grenadine",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "Sao Tome dan Principe",
    "Senegal": "Senegal",
    "Serbia": "Serbia",
    "Seychelles": "Seychelles",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapura",
    "Sint Maarten (Dutch part)": "Sint Maarten (bagian Belanda)",
    "Slovakia": "Slovakia",
    "Slovenia": "Slovenia",
    "Solomon Islands": "Kepulauan Solomon",
    "Somalia": "Somalia",
    "South Africa": "Afrika Selatan",
    "South Georgia and the South Sandwich Islands": "Georgias Selatan dan Kepulauan Sandwich Selatan",
    "South Sudan": "Sudan Selatan",
    "Spain": "Spanyol",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard dan Jan Mayen",
    "Sweden": "Swedia",
    "Switzerland": "Swiss",
    "Syrian Arab Republic": "Republik Arab Suriah",
    "Tajikistan": "Tajikistan",
    "United Republic of Tanzania": "Republik Bersatu Tanzania",
    "Thailand": "Thailand",
    "Timor-Leste": "Timor-Leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad dan Tobago",
    "Tunisia": "Tunisia",
    "Turkey": "Turki",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Kepulauan Turks dan Caicos",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraina",
    "United Arab Emirates": "Uni Emirat Arab",
    "United Kingdom of Great Britain and Northern Ireland": "Kerajaan Inggris Raya dan Irlandia Utara",
    "United States of America": "Amerika Serikat",
    "United States Minor Outlying Islands": "Kepulauan Minor Amerika Serikat",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Uzbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Venezuela (Republik Bolivarian)",
    "Viet Nam": "Viet Nam",
    "Virgin Islands (British)": "Kepulauan Virgin (Inggris)",
    "Virgin Islands (U.S.)": "Kepulauan Virgin (AS)",
    "Wallis and Futuna": "Wallis dan Futuna",
    "Yemen": "Yaman",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe",
    "Taiwan": "Taiwan",
    "Palestine": "Palestina",
    "Western Sahara": "Sahara Barat",
    "Kosovo": "Kosovo",
    "South Ossetia": "Ossetia Selatan",
    "Abkhazia": "Abkhazia",
    "Nagorno-Karabakh (Artsakh)": "Nagorno-Karabakh (Artsakh)",
    "Transnistria": "Transnistria",
    "Somaliland": "Somaliland",
    "Northern Cyprus": "Siprus Utara",
    "Saudi Arabia": "Arab Saudi",
    "Yugoslavia": "Yugoslavia",


    "Enter Your Email": "Masukkan email Anda",
    "Login With Phone Number": "Masuk dengan nomor telepon Anda",
    "Verify Using Sms/Email": "Verifikasi Menggunakan Sms/Email",
    "Resend Code In 100 Seconds": "Kirim ulang kode dalam 100 detik",
    "Verify": "Verifikasi",
    "Verifying": "Sedang memverifikasi",
    "Last Week": "Minggu lalu",
    "Older Transaction": "Transaksi lama",
    "Total": "Total",
    "Conversion Confirmed": "Konversi dikonfirmasi",
    "User Account Limit": "Batas akun pengguna",
    "Monthly Balance Limit": "Batas saldo bulanan",
    "Total Limit": "Batas total",
    "Used": "Digunakan",
    "Remaining": "Tersisa",
    "Allowed Minimum Topup Amount": "Jumlah isi ulang minimum yang diizinkan",
    "Allowed Maximum Topup Amount": "Jumlah isi ulang maksimum yang diizinkan",
    "Incoming Limit": "Batas masuk",
    "Daily Limit": "Batas harian",
    "Monthly Limit": "Batas bulanan",
    "Yearly Limit": "Batas tahunan",
    "Sending Limit": "Batas pengiriman",
    "Allowed Number Of Transactions": "Jumlah transaksi yang diizinkan",
    "Total Amount": "Jumlah Total",
    "Amount To Send": "Jumlah yang akan dikirim",
    "Destination Amount": "Jumlah tujuan",
    "Note": "Catatan",
    "Revised Amount": "Jumlah yang direvisi",
    "Save": "Simpan",
    "Date": "Tanggal",
    "Action": "Tindakan",
    "Payment Scheduled At": "Pembayaran dijadwalkan pada",
    "Transaction Fee": "Biaya transaksi",
    "Number Of Cycle": "Jumlah siklus",
    "Subscription Starting Date": "Tanggal mulai berlangganan",
    "Until": "Hingga",
    "Next Transaction": "Transaksi berikutnya",
    "Pay Now": "Bayar sekarang",
    "Transaction Method": "Metode pembayaran",
    "Mobile Money Provider": "Penyedia uang seluler",
    "Wallet Name": "Nama dompet",
    "Wallet Number": "Nomor dompet",
    "Crypto Currency": "Mata uang kripto",
    "Wallet Address": "Alamat dompet",
    "Select Document Type": "Pilih jenis dokumen",
    "Edit Document Number": "Edit nomor dokumen",
    "My Withdrawals": "Penarikan saya",
    "Bargainable": "Bisa dinegosiasikan",
    "Description": "Deskripsi",
    "Attached Files": "Berkas terlampir",
    "Sent": "Terkirim",
    "Accepted": "Diterima",
    "Decline": "Tolak",
    "Pending": "Menunggu",
    "Overall Rating": "Rating keseluruhan",
    "No Review": "Tidak ada ulasan",
    "0 Rating": "Rating 0",
    "Transaction Map": "Peta transaksi",
    "Send Money": "Kirim uang",
    "Sending Method": "Metode pengiriman",
    "Trouble Sending Money": "Masalah Mengirim Uang",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Biaya dan jumlah yang diterima dapat bervariasi tergantung pada metode pembayaran yang dipilih.",
    "Select Method": "Pilih Metode",
    "Completed": "Selesai",
    "How Was Your Experience?": "Bagaimana pengalaman Anda?",
    "Leave A Review": "Tinggalkan ulasan",
    "Review History": "Riwayat ulasan",
    "Write A Review": "Tulis ulasan",
    "Send And Post": "Kirim dan Pos",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Konversi Crypto ke Fiat adalah solusi yang kuat dan aman yang dengan mulus mengubah cryptocurrency ke mata uang fiat. Ini memenuhi berbagai kebutuhan pengguna, menawarkan banyak saluran pembayaran yang disesuaikan dengan preferensi individu dan lokasi.\"",
    "Review Created": "Tinjauan Dibuat",
    "Transactions": "Transaksi",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Seret/Jatuhkan atau Unggah File (Foto/Video/Pdf)",
    "Max Size 1GB": "Ukuran Maksimal 1GB",
    "Title Description": "Deskripsi Judul",
    "Add New": "Tambah Baru",
    "Discard": "Buang",
    "Save And Post": "Simpan dan Pos",
    "Personal Account": "Akun Pribadi",
    "Conversion": "Konversi",
    "Spent In Total": "Total Pengeluaran",
    "International": "Internasional",
    "Recharge": "Isi Ulang",
    "Top Up Wallet To Wallet": "Top Up Wallet ke Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Tidak Terhubung",
    "More": "Lebih Banyak",
    "Less": "Lebih Sedikit",
    "Profile Reports": "Laporan Profil",
    "Timezone": "Zona Waktu",
    "Cancel": "Batalkan",
    "Full Name": "Nama Lengkap",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Dokumen Tambahan",
    "Profile Complete": "Profil Lengkap",
    "Verified": "Tervalidasi",
    "Bank Account": "Rekening Bank",
    "Mobile Wallet": "Dompet Seluler",
    "Cash Pickup": "Pengambilan Tunai",
    "Add": "Tambah",
    "Configured": "Dikendalikan",
    "Email": "Email",
    "Download Data": "Unduh Data",
    "Enter The Amount": "Masukkan Jumlah",
    "They Receive": "Mereka Menerima",
    "Where Do You Want To Send Money": "Di Mana Anda Ingin Mengirim Uang",
    "Bank Transfer": "Transfer Bank",
    "Receiving Method": "Metode Penerimaan",
    "Payer": "Pembayar",
    "Minimum Amount": "Jumlah Minimum",
    "Maximum Amount": "Jumlah Maksimal",
    "Search": "Cari",
    "Show More": "Tampilkan Lebih Banyak",
    "Select Remark / Reasons (All)": "Pilih Catatan / Alasan (Semua)",
    "Attach Files": "Lampirkan Berkas",
    "Holiday List": "Daftar Liburan",
    "No Data To Show": "Tidak Ada Data untuk Ditampilkan",
    "Threshold Limits": "Batas Ambang",
    "Amount Limits": "Batas Jumlah",
    "OTP Verification Code": "Kode Verifikasi OTP",
    "Verify Using Authenticator": "Verifikasi Menggunakan Authenticator",
    "Sms/Email Verification Code": "Kode Verifikasi Sms/Email",
    "Invite Them": "Undang Mereka",
    "Lookup": "Cari",
    "Invite Friend": "Undang Teman",
    "Invite Via Phone Number": "Undang Melalui Nomor Telepon",
    "Invite Via Email Address": "Undang Melalui Alamat Email",
    "Next": "Berikutnya",
    "Back": "Kembali",
    "Invite Via Email": "Undang Melalui Email",
    "Available Currency": "Mata Uang Tersedia",
    "Add Remarks And Comments": "Tambahkan Catatan dan Komentar",
    "Recipient Details": "Detail Penerima",
    "Go Back": "Kembali",
    "Trouble Requesting Money": "Masalah Meminta Uang",
    "Instant": "Instan",
    "Subscription": "Langganan",
    "Schedule": "Jadwal",
    "Choose Request Type": "Pilih Jenis Permintaan",
    "Mobile Airtime": "Pulsa Mobile",
    "Incorrect Phone Number": "Nomor Telepon Salah",
    "Make Sure You Have Entered A Correct Phone Number.": "Pastikan Anda Memasukkan Nomor Telepon yang Benar.",
    "Close": "Tutup",
    "Operator": "Operator",
    "Recipient Mobile Number": "Nomor Ponsel Penerima",
    "Amount": "Jumlah",
    "Service": "Layanan",
    "Destination": "Tujuan",
    "Top Up Summary": "Ringkasan Top Up",
    "Daily Sending Limit Exceeded": "Batas Pengiriman Harian Terlampaui",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Kirimkan penawaran harga rinci kepada klien atau pelanggan Anda. Sajikan layanan, produk, atau penawaran Anda secara profesional dengan penawaran yang dipersonalisasi.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Pembayaran yang dilakukan pada hari kerja sebelum pukul 15:00 akan dikreditkan ke rekening bank penerima sebelum tengah malam pada hari yang sama.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Atur akun penerimaan Anda di hingga tiga negara dan segera tarik dana dari permintaan pembayaran yang disetujui ke saluran pembayaran yang diaktifkan.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom Memiliki Hak Untuk Meminta Dokumen Tambahan (Due Diligence yang Ditingkatkan - EDD) Atau Informasi Lain Yang Diperlukan Untuk Melakukan Verifikasi Menyeluruh, Sesuai Dengan Kewajiban Hukum Mereka.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Harus Merupakan Dokumen Identitas Yang Dikeluarkan Pemerintah Dengan Foto. Semua Dokumen Identitas Harus Ditulis Dalam Alfabet Latin. Jika Tidak, Terjemahan Tersertifikasi Diperlukan.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Tampilkan Karya Anda, Perluas Jangkauan Anda. 'Portofolio Saya' Adalah Kanvas Digital Anda Di Instapay. Unggah Dan Tampilkan Beragam Konten - Dari Gambar Menarik Dan Video Hingga PDF Informatif. Apakah Anda Seorang Seniman, Pekerja Lepas, Atau Pengusaha, Fitur Ini Memungkinkan Anda Menampilkan Karya Atau Proyek Anda Dengan Cara Yang Menarik Secara Visual.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Bagikan Kode QR Anda Atau Tautan Pembayaran Untuk Langsung Dibayar Di Dompet Insta Anda.",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "Anda memiliki 15 menit untuk menyelesaikan transaksi.",
    "Time Remaining": "Harap selesaikan dalam batas waktu untuk menghindari kadaluwarsa URL.",
    "Please Process The Payment Via Your Selected Payment Type": "Harap proses pembayaran melalui jenis pembayaran yang Anda pilih.",
    "Top-Up Wallet": "Jenis pembayaran",
    "Confirm Your Payment": "ISI ULANG DOMPET",
    "You Will Get": "Konfirmasi pembayaran Anda",
    "Amount To Pay": "Anda akan menerima",
    "Proceed To Payment": "Jumlah yang harus dibayar",
    "Load/Deposit Money To The Wallet": "Lanjutkan ke pembayaran",
    "Withdraw": "Muat/menyetor uang ke dompet",
    "Elevate Your Business With InstaPay": "Tarik",
    "Higher Balances & Transaction Limits": "Tingkatkan bisnis Anda dengan InstaPay",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Saldo lebih tinggi & batas transaksi lebih besar",
    "Full Feature Access": "Buka kebebasan untuk mengelola saldo lebih besar dan memproses volume transaksi lebih tinggi dengan keandalan yang Anda harapkan dari InstaPay.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Akses fitur penuh",
    "Global Reach": "Jangkauan Global",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "Perluas bisnis Anda tanpa batas. InstaPay menghubungkan Anda dengan pelanggan dan mitra di seluruh dunia, membuat transaksi global lebih mudah dari sebelumnya.",
    "Advanced Security": "Keamanan Lanjutan",
    "Business Registration Available Soon\n": "Pendaftaran bisnis akan segera tersedia.",
    "Amount After Fee": "Jumlah Setelah Biaya",
    "Allowed Maximum Amount Per Transaction": "Jumlah Maksimum yang Diizinkan Per Transaksi",
    "Transfer": "Transfer",
    "Uncategorized": "Tidak Dikategorikan",
    "Currency ISO Code & Amount": "Kode ISO Mata Uang & Jumlah",
    "Choose The Top-Up Channel": "Pilih saluran isi ulang",
    "You Selected": "yang Anda pilih",
    "Payout Channel": "saluran pembayaran",
    "Your Details": "rincian Anda",
    "Detailed Confirmed": "rincian dikonfirmasi",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Tingkatkan potensi keuangan Anda dengan meningkatkan ke Akun Bisnis InstaPay. Rasakan kekuatan penuh InstaPay dengan fitur-fitur yang ditingkatkan yang dirancang khusus untuk pertumbuhan bisnis.",
    "Profile Picture": "Foto profil",
    "Add Photo Or Logo": "Tambahkan Foto atau Logo",
    "First Name": "Nama depan",
    "Last Name": "Nama belakang",
    "Enter Your First Name": "Masukkan nama depan Anda",
    "Enter Your Last Name": "Masukkan nama belakang Anda",
    "Email (Optional)": "Email (opsional)",
    "Password Must": "Kata sandi harus",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Akan terisi otomatis jika Anda mendaftar melalui tautan undangan.",
    "Change Number": "Ubah nomor",
    "Search Country": "Cari negara",
    "Select From The List": "Pilih dari daftar",
    "Received Amount": "Jumlah yang diterima",


    "Processing": "Memproses",
    "Verify Using Sms/Email": "Verifikasi menggunakan SMS/Email",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Sudah ada",
    "You Can Have Only 8 Wallets": "Anda hanya dapat memiliki 8 dompet",
    "Save": "Simpan",
    "0 File": "0 file",
    "Search From The List": "Cari dari daftar",
    "Amount To Send": "Jumlah untuk dikirim",
    "Wallet To Wallet": "Wallet ke Wallet",
    "TOP-UP WALLET ": "Isi Saldo Dompet",
    "Enter The Amount": "Masukkan jumlah",
    "Load/Deposit Money To The Wallet": "Muatkan/Depositkan uang ke dompet",
    "Choose The Top-Up Channel": "Pilih saluran isi saldo",
    "Paypal": "Paypal",
    "You Will Get": "Anda akan mendapatkan",
    "Amount To Pay": "Jumlah yang harus dibayar",
    "Card Holder Name ": "Nama pemegang kartu",
    "Card Number": "Nomor kartu",
    "CVC": "CVC",
    "MM/YY": "MM/YY",
    "Your Details ": "Detail Anda",
    "You Selected": "Anda memilih",
    "Detailed Confirmed": "Detail dikonfirmasi",
    "Transaction Failed": "Transaksi gagal",
    "Something Went Wrong. Try Again.": "Ada yang salah. Coba lagi.",
    "Convert Funds": "Konversi dana",
    "How Would You Like To Cashout?:": "Bagaimana Anda ingin menarik dana?:",
    "Default Receiving Account": "Akun Penerima Default",
    "Specify Receiving Account": "Tentukan Akun Penerima",
    "Which Receiving Account Would You Want Use?:": "Akun penerima mana yang ingin Anda gunakan?:",
    "Pakistan": "Pakistan",
    "Bank Mobile": "Bank Seluler",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Tidak ada",
    "Request": "Permintaan",
    "Subscription Payment": "Pembayaran Langganan",
    "Is not supported by Paypal, USD will be used as the default currency.": "Tidak didukung oleh PayPal, USD akan digunakan sebagai mata uang default.",
    "Review Payment": "Tinjau Pembayaran",
    "Google Authenticator": "google authenticator",
    "Scheduled Has Been Cancelled": "Jadwal telah dibatalkan",
    "Select Country First": "Pilih negara terlebih dahulu",
    "Select Gender": "Pilih jenis kelamin",
    "Select Occupation": "Pilih pekerjaan",
    "IBAN and SWIFT/BIC": "IBAN dan SWIFT/BIC",
    "Mobile Money Provider": "Penyedia Uang Seluler",
    "Wallet Name": "Nama Dompet",
    "Wallet Number ": "Nomor Dompet",
    "Crypto Currency": "Mata Uang Kripto",
    "Wallet Address ": "Alamat Dompet",
    "Select Document Type ": "Pilih Jenis Dokumen",
    "Enter Document Number": "Masukkan Nomor Dokumen",
    "Msisdn": "Msisdn",
    "Edit ": "Edit",
    "My Withdrawls": "Penarikan saya",
    "Declined": "Ditolak",
    "Accepted": "Diterima",
    "Overall Ratings": "Penilaian Keseluruhan",
    "0 Ratings": "0 penilaian",
    "No Reviews": "Tidak Ada Ulasan",
    "Send Money": "Kirim Uang",
    "Trouble Sending Money?": "Masalah Mengirim Uang?",
    "Payment Request": "Permintaan Pembayaran",
    "How Was Your Experience?": "Bagaimana pengalaman Anda?",
    "Leave A Review": "Tinggalkan ulasan",
    "Write Review Message": "Tulis pesan ulasan",
    "Send And Post": "Kirim dan posting",
    "You Rated": "Anda memberi peringkat",
    "Review History": "Riwayat ulasan",
    "Max Size 1GB": "Ukuran maksimum 1GB",
    "Title": "Judul",
    "Description": "Deskripsi",
    "Add New": "Tambah baru",
    "My Payment Address": "Alamat pembayaran saya",
    "Personal Account": "Akun Pribadi",
    "Updated Successfully": "Berhasil diperbarui",
    "Spent In": "Dihabiskan di",
    "TOP UP": "Isi ulang",
    "Quotation": "Penawaran",
    "Amount": "Jumlah",
    "Not Connected": "Tidak terhubung",
    "More": "Lebih banyak",
    "Less": "Lebih sedikit",
    "Reports": "Laporan",
    "User Profile": "Profil pengguna",
    "Cancel": "Batalkan",
    "Saving": "Menyimpan",
    "Confirm ": "konfirmasi",
    "Verify ": "Verifikasi",
    "Delete Country": "Hapus negara",
    "Bank Account": "Rekening bank",
    "Cash Pickup": "Pengambilan Uang Tunai",
    "Mobile Wallet ": "Dompet seluler",
    "Delete Country Withdrawals": "Hapus Penarikan Negara",
    "Yes , Delete It": "Ya, Hapus",
    "E-sim": "E-sim",
    "Utilities": "Utilitas",
    "Searching": "Mencari",
    "Recipient Mobile Number": "Nomor ponsel penerima",
    "TOP-UP SUMMARY": "Ringkasan Isi Ulang",
    "Trouble Requesting Money?": "Masalah Mengirim Uang?",
    "Choose The Beneficiary": "Pilih Penerima",
    "Enter The amount": "Masukkan jumlah",
    "You selected": "Anda memilih",
    "Timezone": "Zona Waktu",
    "Starting From": "Mulai dari",
    "Recurring Cycle": "Siklus Berulang",
    "Until I stop": "Sampai saya berhenti",
    "Schedule To": "Jadwalkan ke",
    "Schedule at": "Jadwalkan pada",
    "Selected time must be at least 5 minutes from now.": "Waktu yang dipilih harus setidaknya 5 menit dari sekarang.",
    "Is this your Location?": "Apakah ini lokasi Anda?",
    "Yes ": "Ya",
    "Select Remark Reason": "Pilih alasan komentar",
    "Attach File": "Lampirkan file",
    "Invite via Phone Number": "Undang via Nomor Telepon",
    "Invite Friend": "Undang Teman",
    "Invite via Email Address": "Undang via Alamat Email",
    "Invite via": "Undang via",
    "Phone": "Telepon:",
    "Message": "Pesan:",
    "Send": "Kirim",
    "Invited Succesfully!": "Diundang dengan Sukses!",
    "Email": "Email",
    "Bank Transfer": "Transfer Bank",
    "Mobile Wallet": "Dompet Seluler",
    "Select The Bank": "Pilih Bank",
    "Select The Payer": "Pilih Pembayar",
    "Min Amount:": "Jumlah Minimal:",
    "Max Amount": "Jumlah Maksimal",
    "Other Beneficiaries:": "Penerima Lain:",
    "Back ": "Kembali",
    "Next": "Berikutnya",
    "Lookup": "Cari",
    "Invite Them": "Undang mereka",
    "Delete Channel ": "Hapus saluran",
    "Set As Default": "Atur sebagai default",
    "See More": "Lihat lebih banyak",
    "Session Details": "Detail sesi",
    "Platform": "Platform",
    "Windows": "Windows",
    "Browser Name": "Nama Browser",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Versi Browser",
    "IP Address": "Alamat IP",
    "Karachi": "Karachi",
    "State": "Negara Bagian",
    "Sindh": "Sindh",
    "Active": "Aktif",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Dompet mata uang ini saat ini diblokir/dinonaktifkan dan dana tidak dapat ditambahkan.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Dompet mata uang ini saat ini diblokir/dinonaktifkan dan dana tidak dapat dikonversi.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Dompet mata uang ini saat ini diblokir/dinonaktifkan dan Anda tidak dapat menarik saldo.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Dompet mata uang ini saat ini diblokir/dinonaktifkan dan Anda tidak dapat mengunduh pernyataan.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Dompet mata uang ini saat ini disetel sebagai default Anda dan tidak dapat diblokir. Untuk melanjutkan, harap setel dompet mata uang lain sebagai default terlebih dahulu.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Dompet mata uang ini saat ini disetel sebagai default Anda dan tidak dapat dinonaktifkan. Untuk melanjutkan, harap setel dompet mata uang lain sebagai default terlebih dahulu.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Apakah Anda yakin ingin menghapus rincian penarikan untuk negara ini?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Atur akun penerimaan Anda di hingga tiga negara dan tarik dana secara instan dari permintaan pembayaran yang disetujui ke saluran pembayaran yang diaktifkan.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Tidak ditemukan penerima dari Pakistan dengan detail Allied Bank Limited",
    "Invitation Message Has Been Successfully Sent To": "Pesan undangan berhasil dikirim ke",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Biaya dan jumlah yang diterima dapat bervariasi tergantung pada metode pembayaran yang dipilih.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "Bagikan Kode QR atau tautan pembayaran Anda untuk segera dibayar di dompet Insta Anda.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Seret/Jatuhkan atau Unggah file (Foto/Video/PDF)",
    "Login Again": "masuk lagi",
    "Unblock The Wallet": "buka blokir dompet",
    "Invalid Amount": "jumlah tidak valid",
    "Add Payment Card": "tambahkan kartu pembayaran",
    "Save Details To Use Next Time": "simpan detail untuk digunakan nanti",
    "Paid": "dibayar",
    "Started On": "dimulai pada",
    "Num Of Cycles": "jumlah siklus",
    "Ends On": "berakhir pada",
    "Next Payment At": "pembayaran berikutnya pada",
    "Beneficiary": "penerima",
    "Recieved Quotation": "kutipan diterima",
    "Sent Quotation": "kutipan dikirim",
    "Decline Offer": "tolak tawaran",
    "Are You Sure That You Want To Decline The Offer?": "apakah Anda yakin ingin menolak tawaran ini?",
    "Decline The Offer": "tolak tawaran",
    "Bargain Offer": "tawaran tawar-menawar",
    "Enter Counter Amount": "masukkan jumlah penawaran balik",
    "Custom Amount": "jumlah kustom",
    "Send The Offer": "kirim tawaran",
    "The Offer Is Sent": "tawaran telah dikirim",
    "Countered Offer": "tawaran balasan",
    "Applied": "diterapkan",
    "Accept Quotation": "terima kutipan",
    "Quotation Not Found Or Already Exist": "Kutipan tidak ditemukan atau sudah ada",
    "Recieved Request": "permintaan diterima",
    "Sent Request": "permintaan dikirim",
    "Search Request": "cari permintaan",
    "Search Quotation": "cari kutipan",
    "QR Status": "status QR",
    "QR Code Description": "deskripsi kode QR",
    "Download Your QR Code": "Unduh Kode QR Anda",
    "Your QR Code": "kode QR Anda",
    "Your QR Code Poster": "poster kode QR Anda",
    "Download  ": "unduh",
    "Select Relation": "pilih hubungan",
    "Nominee Permanent Address": "alamat permanen penerima",
    "Enter Your Message": "masukkan pesan Anda",
    "Select": "pilih",
    "Select File Type": "pilih jenis file",
    "Send TOP-UP And E-Sim": "Kirim isi ulang dan E-sim",
    "Search Users": "cari pengguna",
    "Select Payment Request Type": "pilih jenis permintaan pembayaran",
    "Wallet To Wallet Transfer": "transfer dompet ke dompet",
    "Holiday List": "daftar hari libur",
    "No Data To Show": "tidak ada data untuk ditampilkan",
    "They Recieve": "mereka menerima",
    "You Will Be Logged Out In 60 Seconds": "Anda akan keluar dalam 60 detik",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Gerakkan mouse Anda atau tekan sembarang tombol di keyboard Anda untuk mengatur ulang timer ke 15 menit",
    "You've Been Logged Out": "Anda telah keluar",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Untuk keamanan Anda, Anda telah keluar setelah 15 menit tidak aktif. Silakan login kembali jika Anda ingin menggunakan InstaPay.",
    "Cash": "Tunai",
    "Business": "Bisnis",
    "Gift": "Hadiah",
    "Salary": "Gaji",
    "Lottery": "Lotere",
    "Savings": "Tabungan",
    "Other": "Lainnya",
    "Select Source Of Fund": "Pilih sumber dana",
    "Select your source of funds": "Pilih sumber dana Anda",
    "Request Times Out. Please Try Again!": "Permintaan habis waktu. Silakan coba lagi!",
    "Resend Code In 100 Seconds": "Kirim ulang kode dalam 100 detik",
    "Computer Service": "Layanan komputer",
    "Family Support": "Dukungan keluarga",
    "Education": "Pendidikan",
    "Gift And Other Donations": "Hadiah dan sumbangan lainnya",
    "Medical Treatment": "Perawatan medis",
    "Maintenance Or Other Expenses": "Pemeliharaan atau pengeluaran lainnya",
    "Travel": "Perjalanan",
    "Small Value Remittance": "Pengiriman nilai kecil",
    "Liberalized Remittance": "Pengiriman liberalisasi",
    "Construction Expenses": "Biaya konstruksi",
    "Hotel Accommodation": "Akomodasi hotel",
    "Advertising And/Or Public Relations Related Expenses": "Biaya iklan dan/atau hubungan masyarakat",
    "Fees For Advisory Or Consulting Service": "Biaya untuk layanan konsultasi atau penasihat",
    "Business Related Insurance Payment": "Pembayaran asuransi terkait bisnis",
    "Insurance Claims Payment": "Pembayaran klaim asuransi",
    "Delivery Fees": "Biaya pengiriman",
    "Payments For Exported Goods": "Pembayaran untuk barang ekspor",
    "Payment For Services": "Pembayaran untuk layanan",
    "Payment Of Loans": "Pembayaran pinjaman",
    "Office Expenses": "Biaya kantor",
    "Residential Property Purchase": "Pembelian properti hunian",
    "Property Rental Payment": "Pembayaran sewa properti",
    "Royalty, Trademark, Patent And Copyright Fees": "Biaya royalti, merek dagang, paten, dan hak cipta",
    "Investment In Shares": "Investasi saham",
    "Fund Investment": "Investasi dana",
    "Tax Payment": "Pembayaran pajak",
    "Transportation Fees": "Biaya transportasi",
    "Utility Bills": "Tagihan utilitas",
    "Personal Transfer": "Pengiriman pribadi",
    "Payment Of Salary": "Pembayaran gaji",
    "Payment Of Rewards": "Pembayaran hadiah",
    "Payment Of Influencer": "Pembayaran untuk influencer",
    "Broker, Commitment, Guarantee And Other Fees": "Biaya broker, komitmen, jaminan, dan biaya lainnya",
    "Other Purposes": "Tujuan lain",
    "Aunt": "Bibi",
    "Brother": "Saudara laki-laki",
    "Brother-In-Law": "Saudara ipar",
    "Cousin": "Sepupu",
    "Daughter": "Anak perempuan",
    "Father": "Ayah",
    "Father-In-Law": "Mertua",
    "Friend": "Teman",
    "Grandfather": "Kakek",
    "Grandmother": "Nenek",
    "Husband": "Suami",
    "Mother": "Ibu",
    "Mother-In-Law": "Mertua",
    "Nephew": "Keponakan laki-laki",
    "Niece": "Keponakan perempuan",
    "Self (i.e. The Sender, Himself)": "Diri sendiri (yaitu pengirim itu sendiri)",
    "Sister": "Saudara perempuan",
    "Sister-In-Law": "Mertua perempuan",
    "Son": "Anak laki-laki",
    "Uncle": "Paman",
    "Wife": "Istri",
    "Others Not Listed": "Lainnya yang tidak terdaftar",
    "Passport": "Paspor",
    "National Identification Card": "Kartu Identitas Nasional",
    "Driving License": "SIM",
    "Social Security Card/Number": "Kartu/Nomor Jaminan Sosial",
    "Tax Payer Identification Card/Number": "Kartu/Nomor Identifikasi Pembayar Pajak",
    "Senior Citizen Identification Card": "Kartu Identifikasi Warga Senior",
    "Birth Certificate": "Akta Kelahiran",
    "Village Elder Identification Card": "Kartu Identifikasi Kepala Desa",
    "Permanent Residency Identification Card": "Kartu Identifikasi Tempat Tinggal Tetap",
    "Alien Registration Certificate/Card": "Kartu/Sertifikat Pendaftaran WNA",
    "PAN Card": "Kartu PAN",
    "Voter’s Identification Card": "Kartu Identifikasi Pemilih",
    "Health Insurance Card/Number": "Kartu/Nomor Asuransi Kesehatan",
    "Employer Identification Card": "Kartu Identifikasi Pemberi Kerja",
    "Others Not Listed": "Lainnya yang tidak tercantum",
    "Bank Account": "Rekening Bank",
    "Cash Pickup": "Pengambilan Uang",
    "Card": "Kartu",
    "Arts & Entertainment": "Seni & Hiburan",
    "Banking & Finance": "Perbankan & Keuangan",
    "Education & Research": "Pendidikan & Penelitian",
    "Engineering & Construction": "Rekayasa & Konstruksi",
    "Healthcare & Medicine": "Kesehatan & Pengobatan",
    "Information Technology & Services": "Teknologi Informasi & Layanan",
    "Legal & Compliance": "Hukum & Kepatuhan",
    "Manufacturing & Production": "Manufaktur & Produksi",
    "Marketing & Sales": "Pemasaran & Penjualan",
    "Non-profit & Charity": "Lembaga Nirlaba & Amal",
    "Real Estate & Property": "Properti & Real Estat",
    "Retail & Wholesale": "Ritel & Grosir",
    "Science & Pharmaceuticals": "Ilmu Pengetahuan & Farmasi",
    "Transport & Logistics": "Transportasi & Logistik",
    "Travel & Tourism": "Perjalanan & Pariwisata",
    "Unemployed": "Pengangguran",
    "Other": "Lainnya",
    "Cash": "Uang Tunai",
    "Business": "Bisnis",
    "Gift": "Hadiah",
    "Salary": "Gaji",
    "Lottery": "Lotere",
    "Savings": "Tabungan",
    "Good Morning": "Selamat pagi",
    "Good Evening": "Selamat malam",
    "Completed": "Selesai",
    "Load/Deposit Money To The Wallet": "Lanjutkan ke pembayaran",
    "Enter The Amount": "Masukkan jumlah",
    "Choose The Top-Up Channel": "Pilih saluran isi ulang",
    "Good Night!": "Selamat malam!",
    "You Will Get": "Konfirmasi pembayaran Anda",
    "Amount To Pay": "Anda akan menerima",
    "Top-Up Wallet": "Jenis pembayaran",
    "Card Holder Name ": "Nama pemegang kartu",
    "Card Number": "Nomor kartu",
    "CVC": "CVC",
    "MM/YY": "MM/YY",
    "Save Details To Use Next Time": "simpan detail untuk digunakan nanti",
    "Select Or Add Payment Card": "Pilih atau Tambahkan Kartu Pembayaran",
    "Your Details ": "Detail Anda",
    "You Selected": "Anda memilih",
    "Detailed Confirmed": "Detail dikonfirmasi",
    "Transaction Failed": "Transaksi gagal",
    "Something Went Wrong. Try Again.": "Ada yang salah. Coba lagi.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Gagal",
    "Initiated": "Dimulai",
    "Revise": "Tinjau",
    "Declined": "Ditolak",
    "Bargain-Accepted": "Tawar-menawar Diterima",
    "Accepted": "Diterima",
    "Decline The Offer": "Tolak Tawaran",
    "Pending": "Tertunda",
    "Cancelled": "Dibatalkan",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Kirimkan penawaran harga rinci kepada klien atau pelanggan Anda. Sajikan layanan, produk, atau penawaran Anda secara profesional dengan penawaran yang dipersonalisasi.",
    "Wallet to wallet": "Dompet ke dompet",
    "Please follow these examples:": "Harap ikuti contoh berikut:",
    "👤 InstaPay/Instagram Username: instauser": "👤 Nama pengguna InstaPay/Instagram: instauser",
    "📧 Email: user@email.com": "📧 Email: user@email.com",
    "Full Name": "Nama Lengkap",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "Selamat datang di InstaPay! Untuk memastikan Anda dapat mencairkan dana yang diterima secara instan, Anda perlu mengatur akun penerimaan di pengaturan. Ini akan memungkinkan Anda mentransfer uang dengan lancar ke rekening bank, dompet seluler, dompet kripto, atau lokasi pengambilan uang tunai pilihan Anda.",
    "You Have No Beneficiary": "Anda tidak memiliki penerima",
    "Add Now": "Tambahkan sekarang",
    "Wallet-Status": "Status dompet",
    "Select Payment Card": "Pilih kartu pembayaran",
    "Your funds have been credited to your wallet.": "Dana Anda telah dikreditkan ke dompet Anda.",
    "Thanks for using InstaPay": "Terima kasih telah menggunakan InstaPay",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Dapatkan pembayaran instan: Siapkan akun penerimaan Anda sekarang!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Ikuti langkah-langkah sederhana ini untuk mengatur akun penerimaan Anda dan pastikan Anda selalu siap mengakses dana Anda:",
    "STEP 01": "LANGKAH 01",
    "Go to Settings": "Buka Pengaturan",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "Buka aplikasi InstaPay Anda dan navigasikan ke bagian \"Pengaturan\".",
    "STEP 02": "LANGKAH 02",
    "Select Receiving Accounts": "Pilih Akun Penerimaan",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Ketuk \"Akun Penerimaan\" untuk mulai menambahkan detail Anda.",
    "Start Adding Accounts": "Mulai Menambahkan Akun",
    "Phone Number: 44795396600 (With Country Code)": "Nomor Telepon: 44795396600 (Dengan Kode Negara)",
    "Select Country First": "Pilih negara terlebih dahulu",
    "First Name": "Nama depan",
    "Last Name": "Nama belakang",
    "Address Line": "Baris Alamat",
    "Email Address": "Alamat Email",
    "Select City": "Pilih Kota",
    "Select Gender": "Pilih jenis kelamin",
    "Select Occupation": "Pilih pekerjaan",
    "Male": "Laki-laki",
    "Female": "Perempuan",
    "Non-Binary": "Non-Biner",
    "Prefer Not To Say'": "Lebih baik tidak mengatakan",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Pengguna tidak ditemukan",
    "Email Address ": "Alamat Email",
    "Select Document": "Pilih Dokumen",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Tampilkan Karya Anda, Perluas Jangkauan Anda. 'Portofolio Saya' Adalah Kanvas Digital Anda Di Instapay. Unggah Dan Tampilkan Beragam Konten - Dari Gambar Menarik Dan Video Hingga PDF Informatif. Apakah Anda Seorang Seniman, Pekerja Lepas, Atau Pengusaha, Fitur Ini Memungkinkan Anda Menampilkan Karya Atau Proyek Anda Dengan Cara Yang Menarik Secara Visual.",
    "Add New": "Tambahkan Baru",
    "Money Out": "Uang Keluar",
    "Browsername": "Nama Peramban",
    "Browserversion": "Versi Peramban",
    "Credit": "Kredit",
    "Debit": "Debit",
    "Top Up": "Isi Ulang",
    "Wallet To Wallet": "Dompet ke Dompet",
    "Accountant": "Akuntan",
    "Architect": "Arsitek",
    "Artist": "Seniman",
    "Banker": "Bankir",
    "Business Owner": "Pemilik Bisnis",
    "Consultant": "Konsultan",
    "Doctor": "Dokter",
    "Engineer": "Insinyur",
    "Farmer": "Petani",
    "Government Employee": "Pegawai Pemerintah",
    "IT Professional": "Profesional TI",
    "Lawyer": "Pengacara",
    "Nurse": "Perawat",
    "Retailer": "Pengecer",
    "Salesperson": "Penjual",
    "Student": "Mahasiswa",
    "Teacher": "Guru",
    "Prefer Not to Say": "Lebih suka tidak mengatakan",
    "Timezone": "Zona Waktu",
    "Subscription Payment": "Pembayaran Langganan",
    "Starting From": "Mulai dari",
    "Recurring Cycle": "Siklus Berulang",
    "Until I stop": "Sampai saya berhenti",
    "Schedule To": "Jadwalkan ke",
    "Schedule at": "Jadwalkan pada",
    "Selected time must be at least 5 minutes from now.": "Waktu yang dipilih harus setidaknya 5 menit dari sekarang.",
    "They Recieve": "mereka menerima",
    "Attach Files": "Lampirkan Berkas",
    "Wallet Selected": "Dompet Dipilih",
    "You Have Selected PKR Currency Wallet For This Transaction": "Anda telah memilih dompet mata uang PKR untuk transaksi ini",
    "Instant": "Instan",
    "Subscription": "Langganan",
    "Schedule": "Jadwal",
    "Sms/Email Verification Code": "Kode Verifikasi Sms/Email",
    "Confirm Your Password": "Konfirmasi Kata Sandi Anda",
    "This Security Feature Requires Password Confirmation!": "Fitur keamanan ini memerlukan konfirmasi kata sandi!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Gunakan aplikasi untuk memindai kode QR atau gunakan kode rahasia Anda untuk mengaktifkan Autentikasi Dua Faktor Google",
    "OR": "ATAU",
    "Paste This Key In The Googe Authenticator App": "Tempelkan kunci ini di aplikasi Google Authenticator",
    "Show Secret Key": "Tampilkan Kunci Rahasia",
    "Enter The Six-Digit Code From The Application": "Masukkan kode enam digit dari aplikasi",
    "6 Digit Code": "Kode 6 Digit",
    "Enable": "Aktifkan",
    "Disable": "Nonaktifkan",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Setelah memindai gambar barcode, aplikasi akan menampilkan kode enam digit yang dapat Anda masukkan di bawah ini",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "Autentikasi Dua Faktor Google sekarang terhubung ke akun Instapay Anda",
    "Min Amount:": "Jumlah Minimal:",
    "Minimum Amount": "Jumlah Minimum",
    "Maximum Amount": "Jumlah Maksimal",
    "Identity Verification Required": "Verifikasi identitas diperlukan",
    "Select Your Wallet Currency": "Pilih mata uang dompet Anda",
    "Setup Authenticator": "Setel autentikator",
    "Note": "Catatan",
    "This Is A Test Notice": "Ini adalah pemberitahuan uji coba",
    "Session Expired": "Sesi kedaluwarsa",
    "You have been logged out for security reasons, please login again to continue.": "Anda telah keluar karena alasan keamanan, silakan masuk lagi untuk melanjutkan.",
    "Redirecting You To Login Page In 5 Seconds": "Mengarahkan Anda ke halaman login dalam 5 detik",
    "Searching": "Mencari",
    "Recipient Mobile Number": "Nomor ponsel penerima",
    "TOP-UP SUMMARY": "Ringkasan Isi Ulang",
    "Amount": "Jumlah",
    "Something went wrong while getting countries.": "Terjadi kesalahan saat mengambil negara.",
    "Select Destination Country": "Pilih Negara Tujuan.",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR tidak didukung oleh Paypal, USD akan digunakan sebagai mata uang default.",
    "Select A Date": "Pilih Tanggal",
    "Select A Timezone": "Pilih Zona Waktu",
    "Proceed": "Lanjutkan",
    "Proceeding": "Sedang Berlangsung",
    "You Can Now Close This Webpage And Go Back To Instagram": "Anda sekarang dapat menutup halaman web ini dan kembali ke Instagram",
    "Select A Time": "Pilih Waktu",
    "Location Permission Denied": "Izin lokasi ditolak",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Ikuti instruksi di bawah ini untuk mengaktifkan layanan lokasi di browser Anda setelah menolak izin sebelumnya:",
    "1: Chrome Browser": "1: Peramban Chrome",
    "Open the Chrome app.": "Buka aplikasi Chrome.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Ketuk menu tiga titik (sudut kanan atas) dan pilih Pengaturan.",
    "Scroll down to Site Settings > Location.": "Gulir ke bawah ke Pengaturan Situs > Lokasi.",
    "Locate the website in the list or search for it.": "Temukan situs web di daftar atau cari.",
    "Tap the website and select Allow under Location Access.": "Ketuk situs web dan pilih Izinkan di bawah Akses Lokasi.",
    "2: Firefox Browser": "2: Peramban Firefox",
    "Open the Firefox app.": "Buka aplikasi Firefox.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Ketuk menu tiga garis (sudut kanan bawah) dan buka Pengaturan.",
    "Select Privacy & Security > Site Permissions > Location.": "Pilih Privasi & Keamanan > Izin Situs > Lokasi.",
    "Find the website in the list and set the permission to Allow.": "Temukan situs web di daftar dan atur izinnya ke Izinkan.",
    "3: Samsung Internet Browser": "3: Peramban Samsung Internet",
    "Open the Samsung Internet app.": "Buka aplikasi Samsung Internet.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Ketuk menu tiga garis (sudut kanan bawah) dan pilih Pengaturan.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Masuk ke Situs dan Unduhan > Izin Situs > Lokasi.",
    "Find the website and change the permission to Allow.": "Temukan situs web dan ubah izinnya menjadi Izinkan.",
    "4: Safari Browser": "4: Peramban Safari",
    "Open the Settings app.": "Buka aplikasi Pengaturan.",
    "Scroll down and select Safari.": "Gulir ke bawah dan pilih Safari.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Ketuk Lokasi dan atur ke Tanyakan Lain Kali atau Izinkan Saat Menggunakan Aplikasi.",
    "Revisit the website and when prompt, allow location access": "Kunjungi kembali situs web dan, saat diminta, izinkan akses lokasi.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "Tip: Jika situs web tidak muncul dalam pengaturan peramban, bersihkan cache atau riwayat peramban Anda dan coba lagi.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Untuk mengirim permintaan pembayaran, silakan bagikan lokasi Anda—ini membantu kami meningkatkan keamanan dan membangun kepercayaan untuk pengalaman yang lebih lancar!",
    "Decline": "Tolak",
    "Share Location": "Bagikan lokasi",
      "Already Exist": "Sudah Ada",
  "Withdraw": "Tarik",
    "Seller Left A reply": "Penjual meninggalkan balasan",
  "Check Now": "Periksa sekarang",
  "You Left A Review": "Anda meninggalkan ulasan",
  "View Larger Map": "Lihat Peta Lebih Besar",
  "English (UK)": "Bahasa Inggris (Inggris)",
  "QR Code Title": "Judul Kode QR",
  "Notice!": "Perhatian!",
  "By proceeding with this transaction, I hereby confirm that I am acting independently and of my own free will. I affirm that I have not been influenced, coached, coerced, or persuaded by any third party to carry out this transaction for investment purposes or any other reason.": "Dengan melanjutkan transaksi ini, saya dengan ini mengonfirmasi bahwa saya bertindak secara mandiri dan atas kehendak saya sendiri. Saya menegaskan bahwa saya tidak dipengaruhi, dibimbing, dipaksa, atau dibujuk oleh pihak ketiga untuk melakukan transaksi ini untuk tujuan investasi atau alasan lainnya.",
  "I understand that this confirmation is required to ensure compliance and to safeguard my account and transactions.": "Saya memahami bahwa konfirmasi ini diperlukan untuk memastikan kepatuhan dan melindungi akun serta transaksi saya.",
  "Confirm and Proceed": "Konfirmasi dan Lanjutkan",
  "Blocked Wallet": "Dompet Diblokir",
  "Nominee's Full Name": "Nama Lengkap Nominee",
  "Insufficient Balance": "Saldo Tidak Cukup",
  "Upload Photo": "Unggah Foto",
   "Security Alert": "Peringatan Keamanan",
  "Please remember to add your security questions for smooth experience. You will not be able to access the features of InstaPay unless You add the security questions.": "Harap ingat untuk menambahkan pertanyaan keamanan Anda untuk pengalaman yang lancar. Anda tidak akan dapat mengakses fitur InstaPay kecuali Anda menambahkan pertanyaan keamanan.",
  "Setup Security Questions": "Atur Pertanyaan Keamanan",
   "Geo Fencing": "Pembatasan Wilayah",
  "Manage Payment & Quotation Access": "Kelola Akses Pembayaran & Penawaran",
  "Control countries from where you can receive Payment Requests and Quotations. Toggle on/off access for specific countries.": "Kontrol negara dari mana Anda dapat menerima Permintaan Pembayaran dan Penawaran. Aktifkan/nonaktifkan akses untuk negara tertentu.",
  "Search Countries ": "Cari Negara",
  "Filter": "Filter",
  "Payment Requests": "Permintaan Pembayaran",
  "#No": "#No",
  "Country": "Negara",
  "Account’s Country": "Negara Akun",
  "Current Location": "Lokasi Saat Ini",
  "Save Changes": "Simpan Perubahan",
  "Asia": "Asia",
  "Europe": "Eropa",
  "Africa": "Afrika",
  "Oceania": "Oseania",
  "Americas": "Amerika",
  "Polar": "Kutub",
  "Finger Biometric": "Biometrik Jari",
  "Confirm": "Konfirmasi",
  "Resend available in 77 seconds": "Kirim ulang tersedia dalam 77 detik",
  "Invalid OTP!": "OTP tidak valid!",
  "Finger Biometric Updated": "Biometrik jari diperbarui",
  "Your finger biometric has been disabled successfully!": "Biometrik jari Anda telah berhasil dinonaktifkan!",
  "Your finger biometric has been enabled successfully!": "Biometrik jari Anda telah berhasil diaktifkan!",
  "Afghanistan": "Afghanistan",
  "Armenia": "Armenia",
  "Azerbaijan": "Azerbaijan",
  "Bahrain": "Bahrain",
  "Bangladesh": "Bangladesh",
  "Bhutan": "Bhutan",
  "Brunei": "Brunei",
  "Cambodia": "Kamboja",
  "China": "China",
  "East Timor": "Timor Leste",
  "Georgia": "Georgia",
  "Hong Kong S.A.R.": "Hong Kong S.A.R.",
  "India": "India",
  "Indonesia": "Indonesia",
  "Iran": "Iran",
  "Iraq": "Irak",
  "Israel": "Israel",
  "Japan": "Jepang",
  "Jordan": "Yordania",
  "Kazakhstan": "Kazakhstan",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kirgizstan",
  "Laos": "Laos",
  "Lebanon": "Lebanon",
  "Macau S.A.R.": "Makau S.A.R.",
  "Malaysia": "Malaysia",
  "Maldives": "Maladewa",
  "Mongolia": "Mongolia",
  "Myanmar": "Myanmar",
  "Nepal": "Nepal",
  "North Korea": "Korea Utara",
  "Oman": "Oman",
  "Palestinian Territory Occupied": "Wilayah Palestina yang Diduduki",
  "Philippines": "Filipina",
  "Qatar": "Qatar",
  "Saudi Arabia": "Arab Saudi",
  "Singapore": "Singapura",
  "South Korea": "Korea Selatan",
  "Sri Lanka": "Sri Lanka",
  "Syria": "Suriah",
  "Taiwan": "Taiwan",
  "Tajikistan": "Tajikistan",
  "Thailand": "Thailand",
  "Turkey": "Turki",
  "Turkmenistan": "Turkmenistan",
  "United Arab Emirates": "Uni Emirat Arab",
  "Uzbekistan": "Uzbekistan",
  "Vietnam": "Vietnam",
  "Yemen": "Yaman",
  "Åland Islands": "Kepulauan Åland",
  "Albania": "Albania",
  "Andorra": "Andorra",
  "Austria": "Austria",
  "Belarus": "Belarusia",
  "Belgium": "Belgia",
  "Bosnia and Herzegovina": "Bosnia dan Herzegovina",
  "Bulgaria": "Bulgaria",
  "Croatia": "Kroasia",
  "Cyprus": "Siprus",
  "Czech Republic": "Republik Ceko",
  "Denmark": "Denmark",
  "Estonia": "Estonia",
  "Faroe Islands": "Kepulauan Faroe",
  "Finland": "Finlandia",
  "France": "Prancis",
  "Germany": "Jerman",
  "Gibraltar": "Gibraltar",
  "Greece": "Yunani",
  "Guernsey and Alderney": "Guernsey dan Alderney",
  "Hungary": "Hongaria",
  "Iceland": "Islandia",
  "Ireland": "Irlandia",
  "Italy": "Italia",
  "Jersey": "Jersey",
  "Kosovo": "Kosovo",
  "Latvia": "Latvia",
  "Liechtenstein": "Liechtenstein",
  "Lithuania": "Lituania",
  "Luxembourg": "Luksemburg",
  "Malta": "Malta",
  "Isle of Man": "Pulau Man",
  "Moldova": "Moldova",
  "Monaco": "Monako",
  "Montenegro": "Montenegro",
  "Netherlands": "Belanda",
  "North Macedonia": "Makedonia Utara",
  "Norway": "Norwegia",
  "Poland": "Polandia",
  "Portugal": "Portugal",
  "Romania": "Rumania",
  "Russia": "Rusia",
  "San Marino": "San Marino",
  "Serbia": "Serbia",
  "Slovakia": "Slovakia",
  "Slovenia": "Slovenia",
  "Spain": "Spanyol",
  "Svalbard and Jan Mayen Islands": "Kepulauan Svalbard dan Jan Mayen",
  "Sweden": "Swedia",
  "Switzerland": "Swiss",
  "Ukraine": "Ukraina",
  "United Kingdom": "Britania Raya",
  "Vatican City": "Kota Vatikan",
  "Algeria": "Aljazair",
  "Angola": "Angola",
  "Benin": "Benin",
  "Botswana": "Botswana",
  "British Indian Ocean Territory": "Wilayah Samudra Hindia Britania",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cameroon": "Kamerun",
  "Cape Verde": "Tanjung Verde",
  "Central African Republic": "Republik Afrika Tengah",
  "Chad": "Chad",
  "Comoros": "Komoro",
  "Congo": "Kongo",
  "Côte d'Ivoire (Ivory Coast)": "Pantai Gading",
  "Democratic Republic of the Congo": "Republik Demokratik Kongo",
  "Djibouti": "Djibouti",
  "Egypt": "Mesir",
  "Equatorial Guinea": "Guinea Khatulistiwa",
  "Eritrea": "Eritrea",
  "Ethiopia": "Ethiopia",
  "French Southern Territories": "Wilayah Selatan Perancis",
  "Gabon": "Gabon",
  "Gambia": "Gambia",
  "Ghana": "Ghana",
  "Guinea": "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  "Kenya": "Kenya",
  "Lesotho": "Lesotho",
  "Liberia": "Liberia",
  "Libya": "Libya",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Mali": "Mali",
  "Mauritania": "Mauritania",
  "Mauritius": "Mauritius",
  "Mayotte": "Mayotte",
  "Morocco": "Maroko",
  "Mozambique": "Mozambik",
  "Namibia": "Namibia",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Réunion": "Réunion",
  "Rwanda": "Rwanda",
  "Saint Helena": "Saint Helena",
  "São Tomé and Príncipe": "São Tomé dan Príncipe",
  "Senegal": "Senegal",
  "Seychelles": "Seychelles",
  "Sierra Leone": "Sierra Leone",
  "Somalia": "Somalia",
  "South Africa": "Afrika Selatan",
  "South Sudan": "Sudan Selatan",
  "Sudan": "Sudan",
  "Swaziland (Eswatini)": "Eswatini",
  "Tanzania": "Tanzania",
  "Togo": "Togo",
  "Tunisia": "Tunisia",
  "Uganda": "Uganda",
  "Western Sahara": "Sahara Barat",
  "Zambia": "Zambia",
  "Zimbabwe": "Zimbabwe",
  "American Samoa": "Samoa Amerika",
  "Australia": "Australia",
  "Christmas Island": "Pulau Natal",
  "Cocos (Keeling) Islands": "Kepulauan Cocos (Keeling)",
  "Cook Islands": "Kepulauan Cook",
  "Fiji": "Fiji",
  "French Polynesia": "Polinesia Prancis",
  "Guam": "Guam",
  "Kiribati": "Kiribati",
  "Marshall Islands": "Kepulauan Marshall",
  "Micronesia": "Mikronesia",
  "Nauru": "Nauru",
  "New Caledonia": "Kaledonia Baru",
  "New Zealand": "Selandia Baru",
  "Niue": "Niue",
  "Norfolk Island": "Pulau Norfolk",
  "Northern Mariana Islands": "Kepulauan Mariana Utara",
  "Palau": "Palau",
  "Papua New Guinea": "Papua Nugini",
  "Pitcairn Islands": "Kepulauan Pitcairn",
  "Samoa": "Samoa",
  "Solomon Islands": "Kepulauan Solomon",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Tuvalu": "Tuvalu",
  "Vanuatu": "Vanuatu",
  "Wallis and Futuna": "Wallis dan Futuna",
  "North & South America": "Amerika Utara & Selatan",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua dan Barbuda",
  "Argentina": "Argentina",
  "Aruba": "Aruba",
  "Barbados": "Barbados",
  "Belize": "Belize",
  "Bermuda": "Bermuda",
  "Bolivia": "Bolivia",
  "Bonaire, Sint Eustatius, and Saba": "Bonaire, Sint Eustatius, dan Saba",
  "Brazil": "Brasil",
  "Canada": "Kanada",
  "Cayman Islands": "Kepulauan Cayman",
  "Chile": "Chili",
  "Colombia": "Kolombia",
  "Costa Rica": "Kosta Rika",
  "Cuba": "Kuba",
  "Curaçao": "Curaçao",
  "Dominica": "Dominika",
  "Dominican Republic": "Republik Dominika",
  "Ecuador": "Ekuador",
  "El Salvador": "El Salvador",
  "Falkland Islands": "Kepulauan Falkland",
  "French Guiana": "Guyana Prancis",
  "Greenland": "Greenland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guatemala": "Guatemala",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Honduras": "Honduras",
  "Jamaica": "Jamaika",
  "Martinique": "Martinik",
  "Mexico": "Meksiko",
  "Montserrat": "Montserrat",
  "Nicaragua": "Nikaragua",
  "Panama": "Panama",
  "Paraguay": "Paraguay",
  "Peru": "Peru",
  "Puerto Rico": "Puerto Riko",
  "Saint Kitts and Nevis": "Saint Kitts dan Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint Pierre dan Miquelon",
  "Saint Vincent and the Grenadines": "Saint Vincent dan Grenadines",
  "Saint Barthélemy": "Saint Barthélemy",
  "Saint Martin (French part)": "Saint Martin (bagian Prancis)",
  "Sint Maarten (Dutch part)": "Sint Maarten (bagian Belanda)",
  "South Georgia and the South Sandwich Islands": "Georgia Selatan dan Kepulauan Sandwich Selatan",
  "Suriname": "Suriname",
  "The Bahamas": "Bahama",
  "Trinidad and Tobago": "Trinidad dan Tobago",
  "Turks and Caicos Islands": "Kepulauan Turks dan Caicos",
  "United States": "Amerika Serikat",
  "United States Minor Outlying Islands": "Pulau-Pulau Terluar Kecil Amerika Serikat",
  "Uruguay": "Uruguay",
  "Venezuela": "Venezuela",
  "British Virgin Islands": "Kepulauan Virgin Britania Raya",
  "U.S. Virgin Islands": "Kepulauan Virgin AS",
  "Antarctica": "Antartika",
  "Your Preferences Updated Successfully": "Preferensi Anda berhasil diperbarui"
}