export const nl = {
    "How Others Can Find You": "Hoe anderen jou kunnen vinden",
    "Schedules": "Schema's",
    "Login": "Inloggen",
    "Start Your InstaPay Journey Today!": "Begin Vandaag met Uw InstaPay Reis!",
    "Phone Number or Email": "Telefoonnummer of Email",
    "Login Using Social Media": "Inloggen Met Sociale Media",
    "Continue": "Doorgaan",
    "Don't Have an Account? Sign Up": "Heeft U Nog Geen Account? Meld U Aan",
    "Sign Up": "Aanmelden",
    "Edit": "Bewerken",
    "Enter Your Password": "Voer Uw Wachtwoord In",
    "Forgot Password?": "Wachtwoord Vergeten?",
    "Account Type": "Accounttype",
    "Individual": "Individueel",
    "Business": "Zakelijk",
    "Already Have an Account? Sign In": "Heeft U Al Een Account? Meld U Aan",
    "Sing In": "Aanmelden",
    "Enter Your Personal Details": "Voer Uw Persoonlijke Gegevens In",
    "Add Photo or Logo": "Voeg Foto of Logo Toe",
    "Enter Your Name": "Voer Uw Naam In",
    "Back": "Terug",
    "Phone Number": "Telefoonnummer",
    "Email Address": "E-mailadres",
    "Create a Strong Password": "Maak Een Sterk Wachtwoord",
    "Password Requirements": "Wachtwoordvereisten",
    "Must be between 9 and 64 characters": "Moet tussen de 9 en 64 tekens zijn",
    "Include at least two of the following:": "Bevat ten minste twee van de volgende:",
    "Uppercase character": "Hoofdletter",
    "Lowercase character": "Kleine letter",
    "Number": "Nummer",
    "Special character": "Speciaal teken",
    "Confirm Password": "Bevestig Wachtwoord",
    "Finalize Your Process": "Rond Uw Proces Af",
    "Referral Code (Optional)": "Verwijzingscode (Optioneel)",
    "It will autofill if you're signing up with an invitation link.": "Dit wordt automatisch ingevuld als u zich aanmeldt met een uitnodigingslink.",
    "I agree with": "Ik ga akkoord met",
    "Terms & Conditions": "Algemene Voorwaarden",
    "Privacy Policy": "Privacybeleid",
    "Create Your Account": "Maak Uw Account Aan",
    "Enter Code": "Voer Code In",
    "A one-time verification code has been sent to your email address  and phone number.": "Een eenmalige verificatiecode is verzonden naar uw emailadres en telefoonnummer.",
    "Resend available in ": "Opnieuw verzenden beschikbaar in",
    "seconds": "seconden",
    "Verify": "Verifiëren",
    "Verification Code Verified": "Verificatiecode Geverifieerd",
    "Please continue to the next step.": "Ga door naar de volgende stap.",
    "Finish": "Voltooien",
    "Finishing": "Afronden",
    "Company Name": "Bedrijfsnaam",
    "Enter Your Business Details": "Voer Uw Bedrijfsgegevens In",
    "Country ": "Land",
    "You cannot change the country once your account is verified.": "U kunt het land niet veranderen zodra uw account is geverifieerd.",
    "Learn more": "Meer leren",
    "Optional": "Optioneel",
    "Continuing": "Doorgaan",
    "Didn't receive the verification code?": "Heeft u de verificatiecode niet ontvangen?",
    "Resend Now": "Nu Opnieuw Verzenden",
    "Search Here": "Zoek Hier",
    "Search for users, services, and more on InstaPay": "Zoek naar gebruikers, diensten en meer op InstaPay",
    "Search users here": "Zoek gebruikers hier",
    "Earn money with our affiliate program!": "Verdien geld met ons affiliate programma!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Genereer passief inkomen door uw InstaPay affiliate code te delen met uw volgers. Voor elke transactie die zij maken,\nverdient u een commissie, waardoor u de winsten van uw online invloed maximaliseert.",
    "Learn More": "Meer leren",
    "Balance Amount": "Balansbedrag",
    "You spent less compared to last month.": "U heeft minder uitgegeven vergeleken met vorige maand.",
    "Referral Rewards": "Verwijzingsbeloningen",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Snelle Overdracht naar Uw Meest Recente Contacten:",
    "Number of Transactions": "Aantal Transacties",
    "Total Transaction Amount": "Totaal Transactiebedrag",
    "Total Sent": "Totaal Verzonden",
    "Total Received": "Totaal Ontvangen",
    "Add Funds": "Fondsen Toevoegen",
    "Send Money": "Geld Verzenden",
    "Request Money": "Geld Aanvragen",
    "Send a Quote": "Een Offerte Verzenden",
    "Pay With Crypto": "Betalen Met Crypto",
    "Payment Insights": "Inzichten in Betalingen",
    "Recent Transactions ": "Recente Transacties",
    "Today": "Vandaag",
    "My Wallets": "Mijn Wallets",
    "Balance": "Saldo",
    "Wallet ID": "Wallet ID",
    "Monthly limit": "Maandelijkse Limiet",
    "Top Up": "Opwaarderen",
    "Summary": "Overzicht",
    "Settings": "Instellingen",
    "More": "Meer",
    "Upgrade to Business Account": "Upgrade naar Zakelijk Account",
    "Conversion": "Conversie",
    "Enter Amount": "Voer Bedrag In",
    "Exchanged Amount": "Omgeruild Bedrag",
    "Convert": "Converteren",
    "You Are Converting": "U Converteert",
    "From": "Van",
    "To": "Naar",
    "Exchange Rate": "Wisselkoers",
    "Fees": "Tarieven",
    "You Will Receive": "U Ontvangt",
    "Slide To Confirm": "Schuiven Om Te Bevestigen",
    "Verification Code Confirmed - You'll be redirected to the next step": "Verificatiecode Bevestigd - U wordt doorverwezen naar de volgende stap",
    "Moving to next step in": "Naar de volgende stap in",
    "Thanks for using InstaPay": "Bedankt voor het gebruiken van InstaPay",
    "Your funds have been credited to your wallet.": "Uw fondsen zijn bijgeschreven op uw wallet.",
    "Dashboard": "Dashboard",
    "Accounts": "Accounts",
    "Transactions": "Transacties",
    "Other Transactions": "Andere Transacties",
    "Payments": "Betalingen",
    "Beneficiaries": "Begunstigden",
    "Referrals": "Verwijzingen",
    "Quotations": "Offertes",
    "Pending": "In Behandeling",
    "My QR Code Sticker": "Mijn QR-Code Sticker",
    "My Portfolio": "Mijn Portfolio",
    "My Payment Address": "Mijn Betaaladres",
    "Analytics": "Analytics",
    "Profile": "Profiel",
    "Dark Mode": "Donkere Modus",
    "Support": "Ondersteuning",
    "Logout": "Uitloggen",
    "Powered By": "Aangedreven door",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Een Zwitsers Fintech Bedrijf",
    "MAIN MENU": "HOOFDMENU",
    "OTHERS": "ANDEREN",
    "Wallet": "Wallet",
    "Default": "Standaard",
    "Breakdown": "Uitsplitsing",
    "Credit": "Credit",
    "Debit": "Debet",
    "Wallet Management": "Walletbeheer",
    "Top-up Your Wallet": "Uw Wallet Opwaarderen",
    "Download Statement": "Rekeningoverzicht Downloaden",
    "Block the Wallet": "De Wallet Blokkeren",
    "Wallet Status": "Wallet Status",
    "-Select-File-Type-": "-Selecteer-Bestandstype-",
    "Download Now": "Nu Downloaden",
    "Downloading": "Downloaden",
    "All": "Alles",
    "Sent": "Verzonden",
    "Received": "Ontvangen",
    "Requested": "Aangevraagd",
    "Quoted": "Geoffreerd",
    "Search Transactions": "Zoek Transacties",
    "Date & Time": "Datum & Tijd",
    "Type": "Type",
    "Transaction ID": "Transactie-ID",
    "Recipient": "Ontvanger",
    "Amount": "Bedrag",
    "Status": "Status",
    "Payment Type": "Betaaltype",
    "Sent Payments": "Verzonden Betalingen",
    "Received Payments": "Ontvangen Betalingen",
    "Date": "Datum",
    "Cycles/Time": "Cycli/Tijd",
    "Payment As": "Betalen Als",
    "Name": "Naam",
    "Cancel": "Annuleren",
    "Subscriptions & Scheduled": "Abonnementen & Gepland",
    "Select the option that matches your needs from the list below:": "Selecteer de optie die past bij uw behoeften uit de lijst hieronder:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Eenvoudig geld verzenden naar vrienden, familie of bedrijven, zowel lokaal als internationaal. Kies uit meerdere overdrachtsopties, \nwaaronder bankstortingen, mobiele wallets, contant geld ophalen, betaalkaarten en wallet-to-wallet overdrachten. Geniet van het gemak\nen de veiligheid van InstaPay voor naadloze en onmiddellijke geldoverdrachten.",
    "International Transfer": "Internationale Overboeking\n",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Verzend geld moeiteloos over de grenzen. Transfer fondsen internationaal naar vrienden, familie of bedrijven met slechts een paar klikken. \nKies uit een breed scala aan betaalkanalen, waaronder bankrekening, mobiel geld/mobiele wallet, contante ophaallocatie of betaalkaart\nvoor handige en veilige transacties.",
    "Select the country where you want to send the money!": "Selecteer het land waar je het geld naartoe wilt sturen!",
    "Search Country": "Zoek Land",
    "Trouble Sending Money?": "Problemen met geld verzenden?",
    "Confirm": "Bevestigen",
    "Select Payout Channel": "Selecteer Uitbetalingskanaal",
    "Select your most convenient option to send the money.": "Kies uw meest handige optie om het geld te verzenden.",
    "Select The Bank": "Selecteer De Bank",
    "Search Bank": "Zoek Bank",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Stuur geld direct naar de bankrekening van een ontvanger. Geniet van het gemak en de veiligheid van het naadloos overmaken van fondsen \nnaar elke bank wereldwijd.\"",
    "Bank Transfer": "Bankoverschrijving",
    "Enter the amount": "Voer het bedrag in",
    "Tax": "Belasting",
    "Amount the recipient will receive": "Bedrag dat de ontvanger zal ontvangen",
    "Select Remarks / Reason": "Selecteer Opmerkingen / Reden",
    "Comments": "Opmerkingen",
    "Attach Files": "Bestanden Toevoegen",
    "Groups": "Groepen",
    "Manage Groups": "Beheer Groepen",
    "Add New Group": "Nieuwe Groep Toevoegen",
    "Invite": "Nodig Iemand Uit",
    "Manage Beneficiaries": "Beheer Begunstigden",
    "Recipients List": "Ontvangerslijst",
    "Search Contacts": "Zoek Contacten",
    "Confirm Your Payment": "Bevestig Uw Betaling",
    "Select Country": "Selecteer Land",
    "Select the Recipient's Payment Channel": "Selecteer Betaalkanaal van de Ontvanger",
    "Change Selection": "Selectie Wijzigen",
    "You Are Transferring ": "U Maakt Over",
    "Oops! Something Went Wrong.": "Oeps! Er Ging Iets Mis.",
    "We're Sorry About That. ": "Het spijt ons.",
    "Go back to Payments Page.": "Ga terug naar de Betalingspagina.",
    "Wallet to Wallet Transfer": "Wallet naar Wallet Overboeking",
    "Request a customized price quote from service providers or vendors.": "Vraag een aangepaste prijsofferte aan bij dienstverleners of leveranciers.",
    "Enter the Wallet ID": "Voer de Wallet-ID in",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Voer de Wallet-ID, e-mail of telefoonnummer van de ontvanger in.",
    "Recipient not on InstaPay?": "Is de Ontvanger Niet op InstaPay? Nodig Ze Uit.",
    "Invite Them": "Nodig Ze Uit",
    "My Beneficiaries": "Mijn Begunstigden",
    "Enter the Amount You Wish to Transfer.": "Voer het bedrag in dat u wilt overmaken.",
    "Sending to ": "Verzenden naar",
    "You Have Selected Wallet to Wallet Transfer": "U Heeft Gekozen voor Wallet naar Wallet Overboeking",
    "Authenticate Your Payment.": "Authenticeer Uw Betaling.",
    "You are transferring": "U maakt over",
    "Total ": "Totaal",
    "Where Is My Payment?": "Waar Is Mijn Betaling?",
    "Share": "Delen",
    "Your Payment Is Confirmed": "Uw Betaling Is Bevestigd",
    "QR Code Payment": "QR-Code Betaling",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Zet direct geld over naar andere InstaPay-gebruikers met onze veilige wallet-naar-wallet service. Verzend en ontvang fondsen probleemloos \nbinnen enkele seconden.",
    "Scan QR with Your Camera or Upload from Your Device.": "Scan QR met uw camera of upload van uw apparaat.",
    "Upload from Your Device.": "Upload van uw apparaat.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Gebruik anders het unieke betalingsadres van InstaPay (UPA)",
    "Enter InstaPay UPA": "Voer InstaPay UPA in",
    "Recipient not on InstaPay? Invite Them": "Ontvanger niet op InstaPay? Nodig Ze Uit",
    "Verify QR Code": "Controleer QR-Code",
    "Scan/Upload QR Code of the Recipient": "Scan/Upload",
    "Payment Confirmed": "Geld Verzenden\n",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Vraag aangepaste prijsoffertes aan bij dienstverleners of leveranciers om nauwkeurige kostenramingen te ontvangen voor de diensten of \nproducten die u nodig heeft.",
    "Choose The Beneficiary or Group": "Kies De Begunstigde of Groep",
    "Enter the Amount You Wish to Request.": "Voer Het Bedrag In Dat U Wilt Aanvragen.",
    "You Are Requesting Money": "U Vraagt Geld Aan",
    "You are requesting": "U vraagt aan",
    "As": "Als",
    "Conversion of Your Cryptocurrencies": "Conversie Van Uw Cryptocurrencies",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Ervaar onze gebruikersgerichte, veilige en efficiënte Cash-Out Crypto naar Fiat Conversie.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Kies De Cryptocurrency Die U Wilt Liquideren.",
    "Next": "Volgende",
    "Enter the Crypto Wallet Address of the Recipient.": "Voer Het Crypto Wallet Adres van de Ontvanger In.",
    "Minimum Transactional Value: $100.": "Minimale Transactiewaarde: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Let op: Crypto prijzen veranderen vaak. Raadpleeg de definitieve offerte op de bevestigingspagina.",
    "Select Network": "Selecteer Netwerk",
    "Send A Quote": "Stuur Een Offerte",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Stuur een gedetailleerde prijsofferte naar uw klanten of opdrachtgevers. Presenteer uw diensten, producten,\nof aanbiedingen professioneel met een gepersonaliseerde offerte.",
    "Choose the Beneficiary": "Kies De Begunstigde",
    "Allow Receiver to Bargain": "Sta Ontvanger Toe Om Te Onderhandelen",
    "Enter the Title for Your Quotation.": "Voer De Titel Voor Uw Offerte In.",
    "Enter the Description for Your Quotation": "Voer De Beschrijving Voor Uw Offerte In",
    "You are Quoting": "U Offreert",
    "Title": "Titel",
    "Description": "Beschrijving",
    "Attachments": "Bijlagen",
    "Confirm Your Quotation": "Bevestig Uw Offerte",
    "Verify The Quotation": "Verifieer De Offerte",
    "Your quotation is confirmed": "Uw offerte is bevestigd",
    "Request A Quote": "Offerte Aanvragen",
    "Coming Soon": "Binnenkort Beschikbaar",
    "Add Beneficiary": "Voeg Begunstigde Toe",
    "Search for Beneficiaries": "Zoek naar Begunstigden",
    "Connected Accounts": "Verbonden Accounts",
    "Details": "Details",
    "First Name": "Voornaam",
    "Last Name": "Achternaam",
    "Country": "Land",
    "City": "Stad",
    "Address Line": "Adresregel",
    "Mobile Number": "Mobiel Nummer",
    "Relationship With Beneficiary": "Relatie Met Begunstigde",
    "Bank Account": "Bankrekening",
    "Mobile Money/Mobile Wallet": "Mobiel Geld/Mobiele Wallet",
    "Payment Card": "Betaalkaart",
    "Crypto Wallet": "Crypto Wallet",
    "InstaPay Account": "InstaPay Account",
    "Cash Pickup": "Contant Ophalen",
    "IBAN / SWIFT Code": "IBAN Of Swift/BIC-code",
    "Swift Code": "Swift-Code",
    "Account Number": "Rekeningnummer",
    "Bank Name": "Banknaam",
    "Branch Name": "Naam Van De Vestiging",
    "Branch Street": "Straat Van De Vestiging",
    "Province": "Provincie",
    "Postal Code": "Postcode",
    "Mobile Money Provider": "Mobiel Geld Aanbieder",
    "Wallet Name": "Wallet Naam",
    "Wallet Number": "Wallet Nummer",
    "Card Holder Name": "Naam Kaarthouder",
    "Card Number": "Kaartnummer",
    "Expiry Date": "Vervaldatum",
    "Crypto Currency": "Cryptovaluta",
    "Wallet Address": "Wallet Adres",
    "Wallet Holder Name": "Naam Wallet Houder",
    "Wallet Currency": "Wallet Valuta",
    "Select Document Type": "Selecteer Documenttype",
    "Enter Document Number": "Voer Documentnummer In",
    "Add Individual Account": "Voeg Individuele Rekening Toe",
    "Add Business Account": "Voeg Zakelijke Rekening Toe",
    "Company Address": "Bedrijfsadres",
    "Company Email": "Bedrijfsemail",
    "Company Phone No": "Bedrijfstelefoonnummer",
    "Total Referrals": "Totaal Verwijzingen",
    "My Earnings": "Mijn Verdiensten",
    "Your Referral ID": "Uw Referral ID",
    "Share Your Unique Referral Link": "Deel Uw Unieke Referral Link",
    "How do I Refer a Friend to InstaPay?": "Hoe verwijs ik een vriend naar InstaPay?",
    "Step 01": "Stap 01",
    "Share your InstaPay unique referral link with your friends.": "Deel uw unieke InstaPay referral link met uw vrienden.",
    "Step 02": "Stap 02",
    "Earn rewards when they transact $100 or more": "Verdien beloningen wanneer zij transacties doen van $100 of meer",
    "Step 03": "Stap 03",
    "Earn up to $0.06 for every transaction your followers make.": "Verdien tot $0.06 voor elke transactie die uw volgers maken.",
    "My Referrals": "Mijn Verwijzingen",
    "Referral Complete": "Referral Voltooid",
    "Received Quotations": "Ontvangen Offertes",
    "Sent Quotations": "Verzonden Offertes",
    "Search Pending Items": "Zoek naar Openstaande Items",
    "Actions": "Acties",
    "Received Requests": "Ontvangen Verzoeken",
    "Sent Requests": "Verzonden Verzoeken",
    "No Actions": "Geen Acties",
    "You Requested": "U Heeft Aangevraagd",
    "Via": "Via",
    "How was your experience?": "Hoe was uw ervaring?",
    "Leave a Review": "Laat een Beoordeling Achter",
    "Overall Ratings": "Algemene Beoordelingen",
    "Visit Profile": "Bezoek Profiel",
    "Ratings": "Beoordelingen",
    "Reviews": "Recensies",
    "No reviews available": "Geen recensies beschikbaar",
    "Select Your Currency": "Selecteer Uw Valuta",
    "Proceed": "Ga Verder",
    "Commision": "Commissie",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Ontgrendel Naadloze Transacties met InstaPay QR Code Sticker",
    "Effortless and Swift:": "Moeiteloos en Snel:",
    "Instantly download your unique InstaPay QR code sticker.": "Download direct uw unieke InstaPay QR code sticker.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Ervaar probleemloze, cashloze betalingen zonder complexe infrastructuur zoals betaalterminals.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Verzamel betalingen snel – geen vervelend invoeren van creditcard- of telefoonnummers meer.",
    "Each payment includes customer information for your convenience.": "Elke betaling bevat klantinformatie voor uw gemak.",
    "Economically Smart:": "Economisch Slim:",
    "Zero activation fees – start immediately without upfront costs.": "Geen activeringskosten – start onmiddellijk zonder voorafgaande kosten.",
    "Forget about fixed monthly charges; pay as you transact.": "Vergeet vaste maandelijkse kosten; betaal als u transacties doet.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Laat de hardware los; geen terminalkosten betekenen meer besparingen voor u.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Geniet van lage transactiekosten van slechts 0,75% per QR-code betaling.",
    "We don't impose minimum commissions; you save more with each transaction.": "We leggen geen minimale commissies op; u bespaart meer bij elke transactie.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Ervaar de vreugde van onmiddellijke betalingen, en verhoog uw cashflow.",
    "Make Every Transaction Count with InstaPay": "Maak Elke Transactie Belangrijk met InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Download QR",
    "QR Code Status": "QR Code Status",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Deel uw QR-code of betalingsadreslink om direct betalingen in uw InstaPay wallet te ontvangen.",
    "QR Code Title": "QR Code Titel",
    "View Transactions": "Bekijk Transacties",
    "My QR Codes:": "Mijn QR Codes:",
    "Load More": "Meer Laden",
    "Benefits Of Portfolio": "Voordelen van Portfolio",
    "Benefits:": "Voordelen:",
    "Enhanced Visibility:": "Verbeterde Zichtbaarheid:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Verhoog uw openbare profiel. Uw portfolio fungeert als een dynamische showcase, direct toegankelijk vanaf uw betalingsadres.",
    "Engage Your Audience:": "Betrek Uw Publiek:\n",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Verbind met bezoekers via uw creatieve of professionele reis. Laat uw werk\nvoor u spreken, en creëer een diepere betrokkenheid met potentiële klanten of ondersteuners.",
    "Integrated Social Proof:": "Geïntegreerd Sociaal Bewijs:\n",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Toon uw sociale media-invloed. Bezoekers kunnen uw sociale media-bereik zien, waardoor uw portfolio geloofwaardigheid en context krijgt.",
    "Seamless Transactions:": "Naadloze Transacties:\n",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Sta bezoekers toe om betalingen rechtstreeks vanaf uw openbare pagina aan te vragen of te initiëren. Uw portfolio toont niet alleen uw werk, \nmaar faciliteert ook gemakkelijke financiële interacties.",
    "Personalized Storytelling:": "Gepersonaliseerd Verhaal Vertellen:\n",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Gebruik de 'Over Mij'-sectie om uw verhaal, missie of visie te delen, en creëer een\npersoonlijke connectie met uw publiek.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Mijn Portfolio' is meer dan alleen een galerij; het is een hulpmiddel om uw merk te bouwen, uw publiek te betrekken en uw financiële interacties \nop InstaPay te stroomlijnen.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Toon Uw Werk, Vergroot Uw Bereik. 'Mijn Portfolio' is uw digitale canvas op InstaPay. Upload en toon een divers scala aan inhoud - van \nboeiende afbeeldingen en video's tot informatieve PDF's. Of u nu een artiest, freelancer of ondernemer bent, deze functie stelt u in staat om \nuw werk of projecten op een visueel boeiende manier te presenteren.",
    "Add/Manage Portfolio": "Portfolio Toevoegen/Beheren",
    "Add New": "Nieuw Toevoegen",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maximaliseer Uw Sociale Media-Inkomsten met Uw InstaPay Betaaladres",
    "Make It Effortless for Your Audience to Support You:": "Maak het moeiteloos voor uw publiek om u te ondersteunen:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Integreer gemakkelijk uw InstaPay Betaaladres in uw sociale media-posts.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Kopieer en plak eenvoudig uw betaaladreslink in elk van uw posts, verhalen of prominent op uw profiel.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideaal voor influencers, artiesten, ondernemers of iedereen met een gemeenschap die graag hun steun wil tonen.",
    "Instant Support, Endless Possibilities:": "Onmiddellijke Ondersteuning, Eindeloze Mogelijkheden:",
    "Transform the way you monetize your social media content.": "Transformeer de manier waarop u uw sociale media-inhoud monetariseert.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Sta uw volgers toe u direct geld te sturen met slechts één klik, rechtstreeks vanaf hun favoriete sociale netwerken.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideaal voor realtime ondersteuning tijdens live streams, als een fooiensysteem voor uw creatieve inhoud, of voor de financiering \nvan uw volgende grote project.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Waar u uw InstaPay betaaladres voor maximale impact plaatst:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Sociale Media Profielen: Zet het vast in uw bio of over-sectie voor constante zichtbaarheid.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Berichten & Aankondigingen: Integreer het in uw individuele berichten, vooral diegene die uw werk of specifieke oproepen voor \nondersteuning benadrukken.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Verhalen & Reels: Integreer het in uw interactieve verhalen of boeiende reels om directe bijdragen vast te leggen.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Live Streams: Deel het tijdens uw LIVE-sessies, waardoor het gemakkelijk is voor kijkers om in realtime bij te dragen.",
    "Simple, Fast, and Secure:": "Eenvoudig, Snel en Veilig:",
    "No complex setup – get started in moments.": "Geen complexe installatie - begin in een oogwenk.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Geniet van de veiligheid en snelheid van InstaPay's vertrouwde betaalverwerking.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Ontvang betalingen van iedereen, overal, zonder dat ze een InstaPay-account nodig hebben.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Verhoog uw sociale media-spel met InstaPay Betaaladres",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Virtueel Betaaladres)",
    "Payment Address Title": "Titel Betaaladres",
    "Currency": "Valuta",
    "Payment Address Description": "Beschrijving Betaaladres",
    "Update": "Update",
    "Updating": "Bijwerken",
    "Total Transaction": "Totaal Transactie",
    "Last 30 days": "Laatste 30 dagen",
    "Last 1 month": "Laatste 1 maand",
    "Last 1 year": "Laatste 1 jaar",
    "Custom Date": "Aangepaste Datum",
    "Payment Methods": "Betaalmethoden",
    "Payment Types": "Betaaltypes",
    "Requested Amount": "Gevraagd Bedrag",
    "Quoted Amount": "Geoffreerd Bedrag",
    "About Me": "Over Mij",
    "Followers": "Volgers",
    "InstaPay ID": "InstaPay ID",
    "Download QR Code": "QR Code Downloaden",
    "Initiate A Payment": "Een Betaling Initiëren",
    "Report this person": "Deze persoon Rapporteren",
    "Add now": "Nu Toevoegen",
    "Recent Reviews": "Recente Beoordelingen",
    "Reviews As Seller": "Beoordelingen Als Verkoper",
    "Reviews As Buyer": "Beoordelingen Als Koper",
    "Select Language": "Taal Selecteren",
    "Profile Completion": "Profiel Afronden",
    "Profile Completed": "Profiel Voltooid",
    "Basic Info": "Basisinformatie",
    "Username": "Gebruikersnaam",
    "Gender": "Geslacht",
    "Male": "Man",
    "Female": "Vrouw",
    "-Select-Gender-": "-Geslacht Selecteren-",
    "Date Of Birth": "Geboortedatum",
    "Address": "Adres",
    "Edit Profile": "Profiel Bewerken",
    "Update Profile": "Profiel Bijwerken",
    "Updating Profile": "Profiel wordt Bijgewerkt",
    "Complete Now": "Nu Voltooien",
    "Password": "Wachtwoord",
    "Change Password": "Wachtwoord Wijzigen",
    "New Password": "Nieuw Wachtwoord",
    "Re-enter New Password": "Nieuw Wachtwoord Herhalen",
    "Please follow this guide for a strong password": "Volg deze gids voor een sterk wachtwoord",
    "Include at least one special character.": "Minstens één speciaal teken bevatten.",
    "Minimum of 8 characters.": "Minimaal 8 tekens.",
    "Include at least one number": "Minstens één nummer bevatten",
    "Change it often for enhanced security.": "Verander het regelmatig voor verhoogde veiligheid.",
    "Security Questions": "Beveiligingsvragen",
    "Security Question": "Beveiligingsvraag",
    "Select your question": "Selecteer uw vraag",
    "Answer To The Question": "Antwoord op de Vraag",
    "Nominee": "Genomineerde",
    "Relationship with Nominee": "Relatie met de Genomineerde",
    "Nominee Contact Number": "Contactnummer Genomineerde",
    "Nominee Address": "Adres Genomineerde",
    "Delete Nominee": "Genomineerde Verwijderen",
    "Update Nominee": "Genomineerde Bijwerken",
    "Details Updated Successfully!": "Details Succesvol Bijgewerkt!",
    "Success": "Succes",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "U kunt een begunstigde van uw keuze aanwijzen, mits deze geen minderjarige is, via het InstaPay platform zelf. U erkent dat geen natte \nhandtekening vereist is om de begunstigde aan te wijzen, en de online registratie wordt beschouwd als definitief en bindend. In het geval \nvan overlijden van een InstaPay Wallet houder, zal het saldo in de Wallet worden overgedragen aan de geregistreerde begunstigde. \nKEMIT KINGDOM zal worden ontslagen van alle verplichtingen bij de overhandiging van het bedrag aan de begunstigde. De begunstigde \nzal alle door InstaPay vereiste documenten verstrekken, inclusief die voor identificatie en bewijs van overlijden.",
    "Add Nominee": "Voeg Genomineerde Toe",
    "Two Factor Authentication": "Tweefactorauthenticatie",
    "SMS Number": "SMS Nummer",
    "Updated Successfully!": "Succesvol Bijgewerkt!",
    "Social Network Accounts": "Sociale Netwerk Accounts",
    "Here, you can set up and manage your integration settings.": "Hier kunt u uw integratie-instellingen opzetten en beheren.",
    "Social Network Account": "Sociaal Netwerk Account",
    "Activate Account": "Activeer Account",
    "Enter the code below in the InstaPay Chatbot.": "Voer de onderstaande code in in de InstaPay Chatbot.",
    "The code will expire in ": "De code verloopt over",
    "Notifications": "Meldingen",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Pas aan hoe u meldingen ontvangt. Deze instellingen zijn van toepassing op de activiteiten die u monitort.\n",
    "Activity": "Activiteit",
    "Email": "E-mail",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Bron",
    "Payment Requests": "Betalingsverzoeken",
    "Bulk Payments": "Bulkbetalingen",
    "Identity Verification": "Identiteitsverificatie",
    "Verify Your Identity": "Verifieer Uw Identiteit",
    "Identity Verification Status:": "Status Identiteitsverificatie:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Beschrijf uzelf kort en leg uit waarom u InstaPay gebruikt.",
    "Enter Your Message": "Voer Uw Bericht in",
    "Select Occupation": "Selecteer Beroep",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Het moet een door de overheid uitgegeven identificatiedocument met foto zijn. Alle identificatiedocumenten moeten in het Latijnse \nalfabet geschreven zijn. Zo niet, dan is een gecertificeerde vertaling vereist.",
    "Accepted Documents": "Geaccepteerde Documenten",
    "A national identity card": "Een nationale identiteitskaart",
    "A valid passport": "Een geldig paspoort",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Geldige verblijfsvergunningen (alleen geaccepteerd indien uitgegeven door een Europees land of in Zwitserland)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometrische Gezichtsherkenning: Vergelijken van video met paspoortfoto",
    "Address Verification:": "Adresverificatie:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Geef een bankafschrift of nutsrekening (niet ouder dan 3 maanden) als bewijs van adres\n",
    "Additional Documents:": "Aanvullende Documenten:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom behoudt zich het recht voor om eventuele aanvullende documenten (Enhanced Due Diligence - EDD) of verdere informatie \ndie nodig is voor grondige verificaties op te vragen, in overeenstemming met de wettelijke verplichtingen.",
    "Start Verification": "Start Verificatie",
    "Sessions": "Sessies",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Dit is een lijst van apparaten die op uw account hebben ingelogd. Verwijder alle apparaten die u niet herkent.",
    "Your Withdrawal Options": "Uw Opnameopties",
    "Choose your preferred method for withdrawing payments.": "Kies uw voorkeursmethode om betalingen op te nemen.",
    "No withdrawal options currently available in your country.": "Er zijn momenteel geen opnameopties beschikbaar in uw land.",
    "Download Your Data": "Download Uw Gegevens",
    "Request a Copy of Your Data": "Vraag een Kopie van Uw Gegevens aan",
    "Submit a request to download a copy of your data": "Dien een verzoek in om een kopie van uw gegevens te downloaden",
    "Which Data Should Be Included in the Download?": "Welke Gegevens Moeten Worden Opgenomen in de Download?",
    "Select all applicable options.": "Selecteer alle toepasselijke opties.",
    "Personal Information": "Persoonlijke Informatie",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Deze informatie wordt gebruikt om uw account te beveiligen, uw ervaring aan te passen en contact met u op te nemen indien nodig.",
    "Name and Date Of Birth": "Naam en geboortedatum",
    "Phone number": "Telefoonnummer",
    "Financial Information": "Financiële Informatie",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "We gebruiken deze informatie om sneller af te rekenen en om in slechts een paar klikken geld te verzenden of te ontvangen.",
    "Bank accounts": "Bankrekeningen",
    "Debit or credit cards": "Debet- of creditcards",
    "Credit Information": "Kredietinformatie",
    "Includes your credit application information": "Bevat uw kredietaanvraaginformatie",
    "Other Information (not included in file)": "Overige Informatie (niet opgenomen in bestand)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Bevat apparaatinformatie, technische gebruiksgegevens, geolocatie-informatie, marketingvoorkeuren, toestemmingsgeschiedenis en gegevens \ndie worden gebruikt voor andere diensten zoals krediet, identiteitsverificatie, communicatie met PayPal en derde partij verwerkers.",
    "Choose file type:": "Kies bestandstype:",
    "Select file type": "Selecteer bestandstype",
    "PDF file can be easily opened on any computer": "Een PDF-bestand kan eenvoudig worden geopend op elke computer",
    "Submit Request": "Dien Verzoek In",
    "Delete Your Account": "Verwijder Uw Account",
    "Ask us to delete your data and close this account": "Vraag ons om uw gegevens te verwijderen en dit account te sluiten",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "We gebruiken uw gegevens om diensten te leveren, dus als we uw gegevens verwijderen, moeten we ook uw account sluiten.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Als uw account beperkt is, ga dan naar het Resolution Center, selecteer 'Ga naar Accountbeperkingen' en klik op 'Oplossen'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Zorg ervoor dat eventuele uitstaande betalingen zijn verrekend. We kunnen uw account niet sluiten totdat dit gebeurd is.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Als u een negatief saldo heeft, klik dan op de oploslink naast uw saldo.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Zodra uw account in orde is, en als u in aanmerking komt, sluiten we uw account en verwijderen we uw gegevens. We moeten sommige van \nuw gegevens om juridische en regelgevende redenen bewaren. Om meer te weten te komen, zie onze ",
    "privacy statement": "Privacyverklaring",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "We sturen u een e-mail om u op de hoogte te stellen van de status en of er nog andere stappen zijn die u moet nemen.",
    "Have more questions about data deletion?": "Heeft u meer vragen over gegevensverwijdering?",
    "Contact us": "Neem contact met ons op",
    "I understand that account deletion is permanent and can't be reversed.": "Ik begrijp dat accountverwijdering permanent is en niet kan worden teruggedraaid.",
    "Are you sure you want to close your account?": "Weet u zeker dat u uw account wilt sluiten?",
    "Yes, Continue": "Ja, Doorgaan",
    "Logging In": "Inloggen",
    "Complete your profile to streamline your transaction process.": "Voltooi uw profiel om uw transactieproces te stroomlijnen.",
    "Skip": "Overslaan",
    "Complete Your Profile": "Voltooi Uw Profiel",
    "API Key": "API-sleutel",
    "Get API Key": "Verkrijg API-sleutel",
    "Generating New API Key": "Nieuwe API-sleutel genereren",
    "Generate API Key": "Genereer API-sleutel",
    "Regenerate API Key": "Regenereer API-sleutel",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Om het volledige potentieel van de naadloze transacties van InstaPay te benutten, volg deze snelle stappen om uw API-sleutel te genereren. \nMeld u eerst aan voor een account op [uw registratie-URL] en ga naar het Ontwikkelaarsdashboard. Navigeer naar de sectie 'API-sleutels', \ngenereer een nieuwe sleutel en zorg voor een veilige opslag.",
    "Delete API Key": "Verwijder API-sleutel",
    "Webhook URL": "Webhook-URL",
    "KYB Verification": "KYB-verificatie",
    "Additional Files": "Aanvullende Bestanden",
    "Business Verification": "Bedrijfsverificatie",
    "--Select-Your-Company-Type--": "--Selecteer-Uw-Bedrijfstype--",
    "Documents Required:": "Vereiste Documenten:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Officieel Uittreksel van het Bedrijfsregister (Registratie-/Oprichtingsdocument van de Entiteit)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Dit document dient als het officiële 'identiteitsbewijs' van het bedrijf en moet worden verkregen uit het lokale bedrijfsregister.\nHet weerspiegelt de huidige status van het bedrijf (actief of gesloten).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Het document moet actueel zijn, met een uitgiftedatum die niet ouder is dan 3 maanden.",
    "The document must include the following details:": "Het document moet de volgende details bevatten:",
    "Legal name of the company": "Juridische naam van het bedrijf",
    "Full registered address": "Volledig geregistreerd adres",
    "Company registration number": "Bedrijfsregistratienummer",
    "Date of incorporation": "Datum van oprichting",
    "Description of the company's activities/purpose": "Beschrijving van de activiteiten/doel van het bedrijf",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Informatie over de wettelijke vertegenwoordiger: volledige naam, geboortedatum en plaats, woonadres (minimale vereiste: land van verblijf), \nen nationaliteit",
    "If applicable, the document should also include:": "Indien van toepassing, moet het document ook bevatten:",
    "Brand or trading names": "Merk of handelsnamen",
    "Value Added Tax (VAT) number": "BTW-nummer (Belasting Toegevoegde Waarde)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Lijst van Uiteindelijke Begunstigden (UBO's) - dit is verplicht in sommige landen, anders kan het als een apart document worden verstrekt.",
    "Proof of Legal Representative's Authority": "Proof of Legal Representative's Authority",
    "This document validates an individual's authority to represent the company": "Dit document valideert de bevoegdheid van een individu om het bedrijf te vertegenwoordigen",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Typisch wordt de wettelijke vertegenwoordiger genoemd in het uittreksel uit het bedrijfsregister. Als dit niet het geval is, moet een document \nzoals een Volmacht of Delegatie van Bevoegdheden worden verkregen.",
    "Power of Attorney/Delegation of Powers:": "Volmacht/Delegatie van Bevoegdheden:\n",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "De persoon die de bevoegdheid verleent, moet hiertoe legitiem gerechtigd zijn. Deze persoon moet worden vermeld als een wettelijke \nvertegenwoordiger in het uittreksel uit het bedrijfsregister.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Documentatie van Eigendomsstructuur en Uiteindelijke Begunstigden (UBO's)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Bewijs van notering van het bedrijf op de relevante effectenbeurs moet worden verstrekt. Dit kan een pagina van de website van de \neffectenbeurs zijn waarop de gegevens van het bedrijf worden weergegeven.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "Als minder dan 75% van de aandelen van het bedrijf openbaar in bezit is, zullen alle aandeelhouders die meer dan 25% van de aandelen \nbezitten of beheersen, onderworpen zijn aan verificatie-eisen van de Uiteindelijke Begunstigde Eigenaar (UBO).",
    "Collection of Identification Documents": "Collection of Identification Documents\n",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written \nin the Latin alphabet. If not, a certified translation is required.",
    "Accepted documents:": "Geaccepteerde documenten:",
    "Valid National Identity Card": "Geldige nationale identiteitskaart",
    "Valid Passport": "Geldig paspoort",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Geldige verblijfsvergunningen - Deze worden alleen geaccepteerd indien uitgegeven door een Europees land.",
    "Only legal representatives are subject to the identification document collection process.": "Only legal representatives are subject to the identification document collection process.\n",
    "Additional Requirement:": "Aanvullende Vereiste:\n",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "In gevallen met een volmacht of delegatie van bevoegdheden, moeten beide partijen\n(de persoon die de autoriteit verleent en degene die deze ontvangt) een kopie van hun ID verstrekken.",
    "Additional Documents": "Aanvullende Documenten\n",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Nutsrekening (zoals gas, elektriciteit, telefoon of mobiele telefoon rekening); of een document uitgegeven door een overheidsinstantie \ndat het adres en de naam van de eindgebruiker toont (bijvoorbeeld een woonplaatscertificaat); of",
    "Bank Statement; or": "Bankafschrift; of\n",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "een document uitgegeven door een overheidsinstantie dat het adres en de naam van de eindgebruiker toont \n(bijvoorbeeld een woonplaatscertificaat).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom behoudt zich het recht voor om eventuele aanvullende documenten of verdere informatie die nodig is voor het uitvoeren \nvan grondige verificaties aan te vragen, in overeenstemming met zijn wettelijke verplichtingen.",
    "Note:": "Opmerking:",
    "Partners": "Partners",
    "Add New Partner": "Nieuwe Partner Toevoegen",
    "Designation": "Functie",
    "Partner Name": "Partner Naam",
    "Partner Email": "Partner E-mail",
    "Partner Designation": "Partner Functie",
    "Add Now": "Nu Toevoegen",
    "Adding": "Toevoegen",
    "Delete Partner": "Partner Verwijderen",
    "Delete": "Verwijderen",
    "Deleting": "Verwijdering",
    "Are you sure, you want to remove sxz as partner?": "Bent u zeker dat u sxz als partner wilt verwijderen?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Uittreksel uit het Handelsregister (Registratie-/Oprichtingsdocument van de Entiteit)",
    "This document should be either:": "Dit document kan een van de volgende zijn:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Een bewijs van oprichting van de entiteit, met details, oprichtingsdatum en doel van de entiteit.",
    "A local registry extract:": "Een uittreksel uit het lokale register:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Vereist volgens de regelgeving van het land, fungeert als de huidige 'identiteitskaart' van de entiteit, waarin de status (actief of gesloten) \nwordt aangegeven.",
    "If a registry extract exists, it must not be older than 3 months.": "Indien er een uittreksel uit het register bestaat, mag dit niet ouder zijn dan 3 maanden.",
    "Entity name": "Naam van de entiteit",
    "Registration number (if applicable, based on the country's requirements)": "Registratienummer (indien van toepassing, gebaseerd op de eisen van het land)",
    "Date of creation": "Datum van oprichting",
    "Description of the entity's activities or purpose": "Beschrijving van de activiteiten of het doel van de entiteit",
    "Trading names": "Handelsnamen",
    "Articles of Association": "Statuten",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "De Statuten dienen als het grondwettelijk document van de entiteit, waarin de operationele regels worden uiteengezet.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at \nleast one annual meeting that may result in an updated version of the Articles of Association.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.",
    "The document should contain information about:": "The document should contain information about:\n",
    "The entity itself, including its legal name, registered address, and purpose": "The entity itself, including its legal name, registered address, and purpose\n",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. \nThis list is not exhaustive.",
    "How the entity is funded": "How the entity is funded\n",
    "Appointment/Removal/Changes on Board of Management Members:": "Appointment/Removal/Changes on Board of Management Members:\n",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through \nboard of management meeting minutes, which are registered and certified to reflect the updated list of members, such as the president, \nvice-president, treasurer, and general secretary.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, \nif available.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting \nminutes available, this list must be provided. It should be dated and signed by the most recently appointed legal representative, \nwhose appointment can be verified through related documentation.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "This document demonstrates an individual's authority to represent the entity. This document could be:",
    "The latest Articles of Association": "The latest Articles of Association",
    "The current list of board management members (which may be included in the board management meeting minutes)": "The current list of board management members (which may be included in the board management meeting minutes)",
    "The document should specify:": "The document should specify:",
    "Full name of the individual(s)": "Full name of the individual(s)",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits \nthem to bind the entity. This is particularly important when the applicant is not the president, and other functions have limited representation \nauthority as stated in the Articles of Association.",
    "Additional information about the legal representative that will be collected includes:": "Additional information about the legal representative that will be collected includes:",
    "Date and place of birth": "Geboortedatum en -plaats",
    "Residential address (at least the country of residence should be stated)": "Residential address (at least the country of residence should be stated)",
    "Nationality": "Nationaliteit",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Ownership Structure & Ultimate Beneficial Owners (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified \nand treated as such for screening purposes, ID collection and verification, etc.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be \nmentioned in the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Sole Trader's Registry Extract (Entity's Registration/Creation Document)\n",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. It verifies that the individual \nis engaged in a professional activity as a sole trader. The name of this document varies from country to country.",
    "Where applicable, the document should not be older than 3 months.": "Waar van toepassing, mag het document niet ouder zijn dan 3 maanden.",
    "The document must contain:": "Het document moet bevatten:",
    "Individual's name": "Naam van de persoon",
    "Residential address": "Woonadres",
    "Registration number": "Registratienummer",
    "The sole trader is the only individual subject to the identification document collection process.": "De eenmanszaak is de enige persoon onderworpen aan het verzamelproces van identiteitsdocumenten.",
    "No Transactions": "Geen Transacties",
    "No Pending Items": "Geen Lopende Zaken",
    "No Beneficiaries": "Geen Begunstigden",
    "Send Money To": "Geld Versturen Naar",
    "Confirmation": "Bevestiging",
    "You are setting": "U stelt in",
    "as default.": "als standaard.",
    "Previously": "Eerder",
    "was default QR.": "was standaard QR.",
    "Category": "Categorie",
    "-Select-From-The-List-": "-Selecteer-Van-De-Lijst-",
    "Add a note": "Voeg een notitie toe",
    "Categories Added Successfully!": "Categorieën Succesvol Toegevoegd!",
    "Subscription starting date": "Startdatum abonnement",
    "Until": "Tot",
    "Next Transaction": "Volgende Transactie",
    "Transaction Method": "Transactiemethode",
    "Accept the quotation": "Accepteer de offerte",
    "Choose Currency": "Kies Valuta",
    "Accept": "Accepteren",
    "Bargain the quotation": "Onderhandel over de offerte",
    "Enter Counter Amount:": "Voer Tegenbedrag in:",
    "Bargain": "Onderhandelen",
    "Decline the quotation": "Annuleer de offerte",
    "Are you sure, you want to decline this quotation?": "Weet u zeker dat u deze offerte wilt afwijzen?",
    "Decline": "Afwijzen",
    "Personal Account": "Persoonlijke Rekening",
    "Jan": "jan",
    "Feb": "feb",
    "Mar": "mrt",
    "Apr": "apr",
    "May": "mei",
    "Jun": "jun",
    "Jul": "jul",
    "Aug": "aug",
    "Sep": "sep",
    "Oct": "okt",
    "Nov": "nov",
    "Dec": "dec",
    "Today": "Vandaag",
    "Spent In": "Besteed in",
    "Revised Amount": "Herzien bedrag",
    "You spent more comparing to last month": "Je hebt meer uitgegeven in vergelijking met vorige maand",
    "Last": "Laatste",
    "days": "Dagen",
    "Year": "Jaren",
    "Pick a language": "Kies een taal",
    "French": "Frans",
    "Spanish": "Spaans",
    "Portuguese": "Portugees",
    "German": "Duits",
    "Ukrainian": "Oekraïens",
    "Italian": "Italiaans",
    "Russian": "Russisch",
    "Arabic": "Arabisch",
    "Polish": "Pools",
    "Dutch (Nederlands)": "Nederlands",
    "Yoruba": "Yoruba",
    "Indonesian": "Indonesisch",
    "Turkish": "Turks",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chinees",
    "Select Your Preferred Language": "Selecteer uw voorkeurstaal",
    "Please select the language you would \nlike to use for the eKYC process.": "Selecteer de taal die u wilt gebruiken voor het eKYC-proces.",
    "Select Your Nationality": "Selecteer uw nationaliteit",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Kies uw nationaliteit om de ondersteunde ID-documenten voor uw land te zien.",
    "Grant permission to use the camera.": "Geef toestemming om de camera te gebruiken.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Tik of klik op \"Toestaan\" wanneer daarom wordt gevraagd om toestemming voor cameratoegang om het document vast te leggen.",
    "Upload Your Documents from \nGallery/Photos": "Upload uw documenten vanuit de galerij/foto's",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Als u problemen ondervindt met uw camera of als uw systeem er geen heeft, kunt u handmatig foto's van het document uploaden vanuit uw lokale opslag.",
    "Upload Your ID.": "Upload uw ID.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Zorg ervoor dat uw ID duidelijk en niet wazig is om een snelle verificatie te garanderen.",
    "Valid Proof of Address": "Geldig Bewijs van Adres",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Zorg ervoor dat uw bewijs van adres actueel is en dat de datum van het afschrift binnen de laatste drie maanden vanaf de startdatum van uw eKYC-verificatie ligt.\n\n2. Zorg ervoor dat uw bewijs van adres duidelijk en niet wazig is om een snelle verificatie te garanderen.\n",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Belangrijke mededeling\n\nLees alle stappen in deze handleiding zorgvuldig door voordat u doorgaat met uw eKYC-verificatie.\n\nVermijd veelvoorkomende fouten:\n\n* Zorg ervoor dat al uw documenten duidelijk, leesbaar en actueel zijn.\n* Controleer of de door u verstrekte informatie overeenkomt met de gegevens op uw officiële documenten.\n* Volg de instructies nauwkeurig op om vertragingen of afwijzingen te voorkomen.\n\nOpmerking: \nAls uw eKYC-verificatie mislukt en u deze opnieuw moet indienen, worden er extra kosten in rekening gebracht voor het herindienen.\n\nDoor deze richtlijnen te volgen, kunt u zorgen voor een soepele en succesvolle eKYC-verificatie.\n\nVoor vragen of ondersteuning kunt u contact opnemen met ons ondersteuningsteam via support@insta-pay.ch",
    "Personal Transactions": "Persoonlijke transacties",
    "Business Transactions": "Zakelijke transacties",
    "Shopping & Purchases": "Winkelen en aankopen",
    "Bills & Utilities": "Rekeningen en nutsvoorzieningen",
    "Entertainment & Leisure": "Vermaak en Vrije tijd",
    "Investments & Savings": "Investeringen en Besparingen",
    "Health & Wellness": "Gezondheid en Welzijn",
    "Transportation": "Vervoer",
    "Education": "Onderwijs",
    "Miscellaneous": "Diversen",
    "Family Support": "Gezinsondersteuning",
    "WALLET": "WALLET",
    "Add a currency": "Voeg een valuta toe",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Wanneer je een valuta toevoegt, worden alle betalingen die je in die valuta ontvangt bijgeschreven op je InstaPay-saldo. Je primaire valuta wordt gebruikt voor het verzenden of aanvragen van betalingen, tenzij anders aangegeven.",

    "balance amount ": "saldo bedrag",
    "referral ": "verwijzing",
    "crypto wallet balance": "saldo crypto-portemonnee",
    "money in": "geld in",
    "money out": "geld uit",
    "analytics": "analyse",
    "see more": "meer zien",
    "manage my Wallets and see the transaction details": "beheer mijn Wallets en bekijk de details van mijn transacties",
    "set as default Wallet": "als standaard Wallet instellen",
    "add currency": "valuta toevoegen",
    "convert funds ": "fondsen omzetten",
    "withdraw balance": "saldo opnemen",
    "chart names": "grafieknamen",
    "withdraw balance from PKR wallet": "saldo opnemen van PKR-portemonnee",
    "select a payout channel": "kies een uitbetalingskanaal",
    "you have entered an amount below the minimum payment range": "je hebt een bedrag ingevoerd dat lager is dan het minimale betalingsbedrag",
    "change": "veranderen",
    "edit": "bewerken",
    "moving to next step in ...": "naar de volgende stap in ...",
    "you are withdrawing ...": "je neemt op ...",
    "where is my payment": "waar is mijn betaling",
    "download": "downloaden",
    "IBAN / SWIFT Code": "IBAN lub kod SWIFT/BIC",
    "swift code": "kod SWIFT",
    "account number": "numer konta",
    "branch street": "straat van het filiaal",
    "city": "miasto",
    "province": "województwo",
    "postal code": "kod pocztowy",
    "all payout channels": "wszystkie kanały wypłat",
    "bussiness beneficiary": "beneficjent biznesowy",
    "no quotation": "geen offerte",
    "no pending items": "geen openstaande items",
    "QR status": "QR-status",
    "Portfolio": "Portfolio",
    "edit whole portfolio page": "bewerk de hele portfoliopagina",
    "personal account": "persoonlijk account",
    "Chart names": "Grafieknamen",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "meer",
    "not connected": "niet verbonden",
    "code": "code",
    "ratings": "beoordelingen",
    "current password": "huidig wachtwoord",
    "include at least one uppercase and lowercase": "bevat ten minste één hoofdletter en kleine letter",
    "my social network accounts": "mijn sociale netwerkaccounts",
    "SMS ": "SMS",
    "view identity verfication guide": "bekijk de identiteitsverificatiegids",
    "boimetric face recognition": "biometrische gezichtsherkenning",
    "please select a payer (withdrawl options)": "selecteer een betaler (opnamemogelijkheden)",
    "IBAN number ": "IBAN-nummer",
    "account holder name": "naam rekeninghouder",
    "download data": "gegevens downloaden",
    "bank transfer": "bankoverschrijving",
    "mobile wallet": "mobiele portemonnee",
    "all bank names": "alle banknamen",
    "pay via card": "betaal met kaart",
    "you have entered an amount that is below the payer range": "u heeft een bedrag ingevoerd dat lager is dan het betalerbereik",
    "trouble sending money": "probleem met geld versturen",
    "please follow these example": "volg deze voorbeelden",
    "wallet ID ": "Wallet-ID",
    "username": "gebruikersnaam",
    "email": "e-mail",
    "phone number": "telefoonnummer",
    "no user found": "geen gebruiker gevonden",
    "search": "zoeken",
    "scan/upload QR code of the recipient": "scan/upload de QR-code van de ontvanger",
    "invite them": "nodig hen uit",
    "trouble quoting money": "probleem met het quoteren van geld",
    "select a country": "selecteer een land",
    "airtime details": "details van het beltegoed",
    "enter the phone number": "voer het telefoonnummer in",
    "service type": "soort dienst",
    "select a service": "selecteer een dienst",
    "service list": "servicelijst",
    "amount entered is less than the ....": "het ingevoerde bedrag is minder dan ....",
    "total": "totaal",
    "total credit the receiver gets": "Totaal krediet dat de ontvanger krijgt",
    "take ": "nemen",
    "OTP has been verified , You'll be redirected......": "OTP is geverifieerd, je wordt doorgestuurd......",
    "Link Your Instagram Account to InstaPay": "Koppel je Instagram-account aan InstaPa",
    "Step 01 – From Instagram App": "Stap 01 – Vanuit de Instagram-app",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Open de Instagram-app, zoek naar de \"InstaPay\" profielpagina, selecteer \"Bericht\" om het chatvenster te openen, typ/zeg \"Hallo\" en klik op verzenden om het gesprek met de InstaPay-chatbot te starten.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Stap 02 – Vanuit de InstaPay Instagram-profielpagina",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "De InstaPay-chatbot toont twee actieborden: \"Registreren\" en \"Verbinden\". Selecteer \"Verbinden\" en de chatbot vraagt je om je InstaPay-gebruikersnaam in te voeren.",
    "Step 03 – From InstaPay Instagram Profile Page": "Stap 03 – Vanuit de InstaPay Instagram-profielpagina",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "De InstaPay-chatbot zal dan om de InstaPay-verificatiecode vragen. Voer de code in die je hebt verkregen in de InstaPay-instellingen onder \"Mijn sociale netwerken\" en klik op verzenden.",
    "Step 04 – From InstaPay Instagram Profile Page": "Stap 04 – Vanuit de InstaPay Instagram-profielpagina",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Je ontvangt een felicitatiebericht waarin wordt bevestigd dat je Instagram-account nu is gesynchroniseerd met InstaPay. Klik op \"Hoofdmenu\" om de InstaPay-diensten te gebruiken.",
    "Additional Tips": "Aanvullende tips",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Beveilig uw account: Zorg ervoor dat uw Instagram-account beveiligd is door tweefactorauthenticatie (2FA) in te schakelen om uw gekoppelde InstaPay-diensten te beschermen.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Verken meer: Verken, zodra u bent verbonden, het brede scala aan diensten dat InstaPay biedt, van naadloze transacties tot directe opnames.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Blijf op de hoogte: Houd onze updates in de gaten, want binnenkort ondersteunen we het koppelen van andere sociale netwerkaccounts om uw ervaring nog meer geïntegreerd te maken.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Hulp en ondersteuning: Als u problemen ondervindt tijdens het koppelingsproces, neem dan contact op met ons ondersteuningsteam voor hulp.",
    "Future Enhancements": "Toekomstige verbeteringen",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "In de nabije toekomst stelt InstaPay u in staat om meer sociale netwerkaccounts te koppelen, waardoor u nog meer flexibiliteit en gemak krijgt. Blijf op de hoogte van spannende updates!",
    "Personal Transactions": "Persoonlijke transacties",
    "Business Transactions": "Zakelijke transacties",
    "Shopping & Purchases": "Winkelen en aankopen",
    "Bills & Utilities": "Rekeningen en nutsvoorzieningen",
    "Entertainment & Leisure": "Vermaak en Vrije tijd",
    "Investments & Savings": "Investeringen en Besparingen",
    "Health & Wellness": "Gezondheid en Welzijn",
    "Transportation": "Vervoer",
    "Education": "Onderwijs",
    "Miscellaneous": "Diversen",
    "Family Support": "Gezinsondersteuning",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Begin vandaag nog aan je InstaPay-reis!",
    "Enter your phone number": "Voer je telefoonnummer in",
    "Login with email": "Inloggen met e-mail",
    "Don't have an account?": "Geen account?",
    "Sign Up": "Meld je aan",
    "Continue": "Doorgaan",
    "We recommend you download and install the Google Authenticator app": "We raden je aan de Google Authenticator-app te downloaden en te installeren",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Twee-factor-authenticatie (2FA) is de beste manier om jezelf online te beschermen.",
    "Strong security with Google Authenticator": "Sterke beveiliging met Google Authenticator",
    "Enter your password": "Voer je wachtwoord in",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "We raden je aan de Google Authenticator-app te downloaden en te installeren om ervoor te zorgen dat je verificatiecodes direct ontvangt, zonder vertragingen of problemen met SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Twee-factor-authenticatiecode",
    "Problem with the token?": "Probleem met het token?",
    "Verify using SMS": "Verifieer via SMS",
    "Verify": "Verifiëren",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "SMS-verificatie",
    "Enter the SMS code sent to +41********4053": "Voer de SMS-code in die is verzonden naar +41********4053",
    "Verify Using Authenticator App": "Verifieer met de Authenticator-app",
    "Resend code in 120 seconds": "Stuur de code opnieuw in 120 seconden",
    "Verify ": "Verifiëren",
    "SMS - Web Mobile": null,
    "My own account": "Mijn eigen account",
    "Friends & Family": "Vrienden & Familie",
    "Aunt": "Tante",
    "Brother-in-law": "Zwager",
    "Cousin": "Neef",
    "Daughter": "Dochter",
    "Father": "Vader",
    "Father-in-law": "Schoonvader",
    "Friend": "Vriend",
    "Grandfather": "Grootvader",
    "Grandmother": "Grootmoeder",
    "Husband": "Echtgenoot",
    "Mother": "Moeder",
    "Mother-in-law": "Schoonmoeder",
    "Nephew": "Neefje",
    "Niece": "Nichtje",
    "Self (i.e. the sender, himself)": "Zelf (d.w.z. de afzender zelf)",
    "Sister": "Zus",
    "Sister-in-law": "Schoonzus",
    "Son": "Zoon",
    "Uncle": "Oom",
    "Wife": "Echtgenote",
    "Others not listed": "Anderen niet vermeld",
    "Merchant": "Handelaar",
    "No relationship": "Geen relatie",
    "Add Funds": "Geld toevoegen",
    "Enter the Amount": "Voer het bedrag in:",
    "Fees": "Kosten:",
    "You Will Get": "Je ontvangt:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ De transactie waarde moet tussen [Currency ISO code & Amount] - [Currency ISO code & Amount] liggen",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Ongeldig bedrag: Uw huidige identiteitsverificatieniveau beperkt het maximale bedrag dat u kunt toevoegen. Gelieve uw identiteitsverificatie te upgraden om te profiteren van hogere limieten.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ De prijs van de valuta verandert vaak op basis van marktomstandigheden. Raadpleeg de prijs op de bevestigingspagina van de bestelling als uw definitieve offerte.",
    "Next": "Volgende",
    "Upgrade Identity Verification": "Identiteitsverificatie upgraden",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Vraag geld aan van iedereen, overal, inclusief via sociale netwerken. Met InstaPay zijn uw betalingsverzoeken direct toegankelijk zodra ze zijn geaccepteerd en worden de fondsen naar uw ontvangende accounts gestuurd. Vereenvoudig uw transacties en beheer betalingen met gemak en veiligheid.",
    "You’ve been logged out.": "Je bent uitgelogd.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Voor je veiligheid ben je uitgelogd na 15 minuten inactiviteit. Log opnieuw in als je InstaPay wilt gebruiken.",
    "LOGIN AGAIN": "OPNIEUW INLOGGEN",
    "Brother": "Broer",
    "Current Password": "Huidig wachtwoord",
    "The new password cannot be the same as the old one": "Het nieuwe wachtwoord mag niet hetzelfde zijn als het oude.",
    "Minimum password length: [X] characters ": "Minimale wachtwoordlengte: [X] tekens",
    "Include at least one uppercase and one lowercase letter.": "Inclusief minstens één hoofdletter en één kleine letter.",
    "Nominee Information": "Informatie over genomineerde",
    "Verification Code Preferences": "Voorkeuren voor verificatiecode",
    "How Others Can Find You": "Hoe anderen je kunnen vinden",
    "My Social Network Accounts": "Mijn sociale netwerkaccounts",
    "Login Activity": "Inlogactiviteit",
    "Receiving Accounts": "Ontvangstrekeningen",
    "Change Your Password To Continue": "Wijzig uw wachtwoord om door te gaan",
    "Continue with": "Doorgaan met",
    "Back to Login": "Terug naar inloggen",
    "Send OTP": "OTP verzenden",
    "A Verification code has been sent to your email and number.": "Een verificatiecode is naar uw e-mail en nummer verzonden.",
    "Resend available in": "Opnieuw verzenden beschikbaar over",
    "Create a strong Password": "Maak een sterk wachtwoord aan",
    "Password must:": "Wachtwoord moet:",
    "Be between 9 and 64 characters": "Tussen 9 en 64 tekens lang zijn",
    "Include at least two of the following:": "Minstens twee van de volgende bevatten:",
    "Lowercase Character": "Kleine letter",
    "Special Character": "Speciaal teken",
    "Password Updated Successfully!": "Wachtwoord succesvol bijgewerkt!",
    "Click below to login": "Klik hieronder om in te loggen",

    "Send Money": "Geld verzenden",
    "Enter the amount": null,
    "Recent Transfer": "Recente overboeking",
    "See all": "Alles zien",
    "Send again": "Opnieuw verzenden",
    "You Send": "U verzendt",
    "They Receive": "Zij ontvangen",
    "Receive Method": "Ontvangstmethode",
    "Select method": "Selecteer methode",
    "Next": "Volgende",
    "Trouble sending money?": "Problemen met het verzenden van geld?",
    "Where do you want to send money ?": "Waar wilt u geld naar toe verzenden?",
    "Search ": "Zoeken",
    "Confirm": "Bevestigen",
    "Bank Deposit": "Bankstorting",
    "Cash Pick-up": "Contant Ophalen",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Bankpas",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Wat is het Wallet mobiel van de ontvanger?",
    "Transfer fees": "Overboekingskosten",
    "Total to pay": "Totaal te betalen",
    "Choose the beneficiary!": "Kies de begunstigde!",
    "Country": "Land",
    "Receive method": "Ontvangstmethode",
    "You send": "U verzendt",
    "change": "wijzigen",
    "edit": "nieuwe toevoegen",
    "add new": "Begunstigden",
    "Beneficiaries": "Beheer begunstigden",
    "Manage Beneficiaries": "Zoeken",
    "Search": "Volgende",
    "CASH PICK-UP": "CONTANT AFHALEN",
    "Where will your receiver collect the money": "Waar zal uw ontvanger het geld afhalen?",
    "Cash is available to collect within minutes from any branch of [name] ": "Contant geld is binnen enkele minuten beschikbaar bij elk filiaal van [naam]",
    "BANK ACCOUNT": "BANKREKENING",
    "Transfer to recipient’s bank account": "Overboeking naar de bankrekening van de ontvanger",
    "BANK CARD": "BANKKAART",
    "Fast transfer directly to recipient’s bank card": "Snelle overboeking direct naar de bankkaart van de ontvanger",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Snelle overboeking direct naar een Wallet crypto",
    "Review transfer": "Overboeking controleren",
    "Receiver's Details": "Gegevens van de ontvanger",
    "Edit": "Bewerken",
    "Name": "Naam",
    "Reason for sending": "Reden voor verzending",
    "Sending": "Verzenden",
    "Receiver gets": "Ontvanger krijgt",
    "Exchange rate": "Wisselkoers",
    "Estimated time": "Geschatte tijd",
    "Select Payment Type": "Selecteer betaalmethode",
    "Credit cards": "Creditcards",
    "Domestic cards": "Binnenlandse kaarten",
    "Mobile Wallets": "Wallets mobiel",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "CREDITCARD",
    "Credit Card": "Creditcard",
    "Select card": "Selecteer kaart",
    "Card Number": "Kaartnummer",
    "Enter the 16-digit card number on the card": "Voer het 16-cijferige kaartnummer in",
    "Expiry Date": "Vervaldatum",
    "Enter the expiration date of the card": "Voer de vervaldatum van de kaart in",
    "CVV Number": "CVV-nummer",
    "Enter the 3 or 4 digit number on the card": "Voer het 3- of 4-cijferige nummer van de kaart in",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Wallet selecteren",
    "My Wallet": "Mijn Wallet",
    "Conversion": "Conversie",
    "Convert": "Converteren",
    "Your payment is confirmed": "Uw betaling is bevestigd",
    "Thanks for using InstaPay": "Bedankt voor het gebruik van InstaPay",
    "Status": "Status",
    "Share": "Delen",
    "Transfer created": "Overboeking aangemaakt",
    "Payment received": "Betaling ontvangen",
    "Payment processed": "Betaling verwerkt",
    "Transfer successful": "Overboeking succesvol",
    "It may take few hours for the funds to appear in [user name] account": "Het kan enkele uren duren voordat de fondsen op het account van [gebruikersnaam] verschijnen",
    "Transfer failed": "Overboeking mislukt",
    "Transaction Details": "Transactiedetails",
    "Need help?": "Hulp nodig?",
    "Service Details": "Service Details",
    "Funds Deposit Speed": "Beschikbaarheid van Fondsen",
    "Cut-off Time": "Sluitingstijd",
    "Bank holiday list": "Lijst van Bankfeestdagen",
    "Threshold limits": "Transactielimieten",
    "null": null,
    "Instant, Real Time": "Instant, real-time",
    "Same day, T+1": "Zelfde dag, T+1",
    "Non-instant, T+1": "Niet instant, T+1",
    "Instant Payment, Real Time": "Directe betaling, real-time",
    "Transaction processed before 3:00 PM Local time": "Transactie verwerkt vóór 15:00 lokale tijd",
    "Delivered the same day": "Geleverd dezelfde dag",
    "Transactions processed post 3:00 PM": "Transacties verwerkt na 15:00",
    "T+1 business days": "T+1 werkdagen",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Betalingen die op werkdagen vóór 15:00 uur worden gedaan, worden voor middernacht van dezelfde dag bijgeschreven op de ontvangende bankrekening.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Betalingen die op werkdagen na 15:00 uur worden gedaan, worden voor middernacht de volgende dag bijgeschreven.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Betalingen die op zaterdag, zondag of feestdagen worden gedaan, worden voor middernacht van de eerstvolgende werkdag bijgeschreven.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 lokale tijd",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Minimale limiet per transactie:",
    "Maximum per transaction limit:": "Maximale limiet per transactie:",
    "Daily threshold limit:": "Dagelijkse limiet:",
    "Weekly threshold limit:": "Wekelijkse limiet:",
    "Monthly threshold limit:": "Maandelijkse limiet:",
    "Yearly threshold limit:": "Jaarlijkse limiet:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Basisaccount (Niet geverifieerd)",
    "Identity Verified": "Identiteit geverifieerd",

    "Good Evening!": "Goedenavond!",
    "Total Balance": "Totale Rekeningbalans",
    "Quick Transfer": "Snelle Geldoverschrijving",
    "No transactions": "Geen transacties gevonden",
    "Blocked balance": "Geblokkeerd saldo",
    "Available balance": "Beschikbaar saldo",
    "Insufficient Balance in PKR": "Onvoldoende Saldo in PKR",
    "Chat with us": "Chat met ons",
    "Add Currency": "Valuta Toevoegen",
    "Education": "Onderwijs",
    "Divers": "Diversen",
    "Family support": "Familie ondersteuning",
    "Others": "Andere uitgaven",
    "Transport": "Vervoer",
    "schedule payment": "Geplande Betaling",
    "subscription payment": "Terugkerende Betaling",
    "No payment": "Geen betalingen gevonden",
    "No Beneficiaries": "Geen begunstigden beschikbaar",
    "Code Postal": "Postcode",
    "Additional Information": "Aanvullende Informatie",
    "Gender": "Geslacht",
    "Date Of Birth": "Geboortedatum",
    "Nationality": "Nationaliteit",
    "Occupation": "Beroep (alle opties ook)",
    "ID Type": "ID Type (alle opties ook)",
    "ID Number": "ID Nummer",
    "Select Beneficiary Payment Channels": "Selecteer Betalingskanalen voor Begunstigde",
    "wallet crypto": "Crypto Wallet",
    "Add another wallet": "Nog een wallet toevoegen",
    "Enterprise": "Zakelijk",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "\"Zakelijke begunstigden komen binnenkort! U zult in staat zijn zakelijke transacties wereldwijd uit te voeren, waardoor u uw bereik over grenzen heen uitbreidt.\"",
    "Joined At": "Toegevoegd op",
    "Profile Link": "Profiel link",
    "comission": "Commissie",
    "my withdrawls": "Mijn Opnames",
    "My Referrals": "Mijn Verwijzingen",
    "search quotation": "Zoek Citaten",
    "no quotation": "Geen citaten gevonden",
    "actions": "Acties",
    "search request": "Zoek verzoek",
    "no pending items ": "Geen openstaande items gevonden",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "Deel uw QR-code of betaal link om direct betalingen te ontvangen in uw InstaPay-portemonnee",
    "Description": "Beschrijving",
    "save": "Opslaan",
    "Share your Insta-Pay unique payment link": "Deel uw unieke InstaPay-betaallink",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Verhoog uw openbare profiel. Uw portfolio fungeert als een dynamische vitrine, direct toegankelijk vanaf uw Betalingsadres",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "\"Verbind met uw publiek door middel van uw professionele of creatieve reis. Laat uw werk zichzelf tonen en betrek potentiële klanten of supporters dieper.\"",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "\"Toon uw sociale media bereik. Bezoekers kunnen uw impact op sociale media zien, wat geloofwaardigheid toevoegt aan uw portfolio.\"",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "\"Sta bezoekers toe om betalingen aan te vragen of te initiëren direct vanaf uw openbare pagina. Uw portfolio is niet alleen een vitrine, maar ook een manier om eenvoudige financiële transacties mogelijk te maken.\"",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Gebruik de sectie \"Over Mij\" om uw verhaal, missie of visie te delen, en zo een persoonlijke band met uw publiek op te bouwen.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "\"Mijn Portfolio\" is meer dan alleen een galerie; het is een hulpmiddel voor het opbouwen van uw merk, het betrekken van uw publiek en het vereenvoudigen van financiële interacties op InstaPay.",
    "No Portfolio Items": "Geen Portfolio Items Gevonden",
    "Add New Portfolio Item": "Nieuw Portfolio Item Toevoegen",
    "Title": "Titel",
    "drag/drop or upload a file(photo/video/pdf)": "Sleep/Upload een bestand (foto/video/pdf)",
    "discard": "Verwijderen",
    "save and post ": "Opslaan en Plaatsen",
    "My Payment Address": "Mijn Betalingsadres",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "Perfect voor influencers, artiesten, ondernemers, of iedereen die zijn gemeenschap eenvoudig wil laten ondersteunen.",
    "Personal Account": "Status van Persoonlijke Rekening",
    "spent in total": "Totaal Besteed",
    "not connected": "Niet Verbonden",
    "more": "Meer",
    "less": "Minder",
    "profile reports": "Profielrapporten",
    "All notifications are in English langauge": "Alle meldingen zijn in het Engels",
    "timezone": "Tijdzone",
    "nominee information": "Informatie over Gerechtigde",
    "full name": "Volledige Naam",
    "Relation with Nominee": "Relatie met Gerechtigde",
    "source": "Bron",
    "Google Authenticator": "Google Authenticator",
    "notifications": "Meldingen",
    "login": "Inloggen",
    "View Identity Verification Guide": "Bekijk Gids Identiteitsverificatie",
    "verified": "Geverifieerd",
    "select ": "Selecteren",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "Het moet een door de overheid uitgegeven identiteitsdocument met een foto zijn. Alle identificatie moet in het Latijns alfabet zijn. Anders is een gecertificeerde vertaling vereist.",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom behoudt zich het recht voor om aanvullende documenten (Enhanced Due Diligence - EDD) of enige informatie te vragen die nodig is voor volledige verificatie, in overeenstemming met zijn wettelijke verplichtingen.",
    "sessions": "Sessies",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Ontgrendel Directe Opnames: Stel Nu Uw Ontvangende Rekeningen In!",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "Beheer eenvoudig waar u betalingen wilt ontvangen door de gegevens van uw bankrekeningen, mobiele wallets, crypto wallets, betaalkaarten of afhaalpunten voor contant geld toe te voegen. Volg de onderstaande stappen om te zorgen voor directe toegang tot uw geld wanneer dat nodig is.",
    "Select the receiving channel below and setup the details.": "Selecteer het onderstaande ontvangkanaal en stel de gegevens in.",
    "Please Select A Payer": "Selecteer een Betaler",
    "Number": "Nummer",
    "Account Holder Name": "Naam Rekeninghouder",
    "Province": "Provincie",
    "postal": "Postadres",
    "delete channel": "Kanaal Verwijderen",
    "set as default": "Instellen als Standaard",
    "edit": "Bewerken",
    "all channels are in english language in recieving account page": "Alle kanalen zijn in het Engels op de pagina voor ontvangende rekeningen",
    "select file type": "Selecteer Bestandstype",
    "download data": "Gegevens Downloaden",
    "INTERNATIONAL TRANSFER": "INTERNATIONALE OVERDRACHT",
    "enter the amount": "Voer het Bedrag in",
    "they recieve ": "Ontvanger Ontvangt",
    "trouble sending money": "Problemen met Geld Overmaken?",
    "select destination country": "Selecteer Bestemmingsland",
    "bank transfer": "Bankoverschrijving",
    "recieving method": "Ontvangstwijze",
    "payer": "Betaler",
    "The fee and the received amount may vary depending on the chosen Payment Method": "Kosten en het ontvangen bedrag kunnen variëren afhankelijk van de gekozen betaalmethode.",
    "search ": "Zoeken",
    "show more": "Meer Weergeven",
    "select remark reasons": "Selecteer Redenen voor Opmerking",
    "credit and debit card": "Krediet- en Debetkaarten",
    "Transfer Fees": "Overboekingskosten",
    "Estimated Time": "Geschatte Tijd:",
    "within minutes": "Binnen Minuten",
    "total ": "Totaal",
    "OTP verifications code": "OTP Verificatiecode",
    "verify using sms/email": "Verifieer met SMS/E-mail",
    "sms/email verification code": "SMS/E-mail Verificatiecode",
    "Verify Using Authenticator": "Verifieer met Authenticator",
    "WALLET TO WALLET TRANSFER": "WALLET NAAR WALLET OVERDRACHT",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Voer het Wallet ID, Gebruikersnaam, E-mailadres of Telefoonnummer van de ontvanger in.",
    "search": "Zoeken",
    "lookup": "Zoeken",
    "Recipient Not on InstaPay?": "Ontvanger Niet op InstaPay?",
    "invite them": "Nodig hen uit",
    "Please follow these examples": "Volg de onderstaande voorbeelden:",
    "Wallet ID: GY68J782": "Wallet ID: GY68J782",
    "InstaPay/Instagram Username": "InstaPay/Instagram Gebruikersnaam: instauser",
    "Email: user@email.com": "E-mail: user@email.com",
    "With Country code": "Telefoonnummer (met Landcode)",
    "invite friend": "Vriend Uitnodigen",
    "invite via phone number": "Uitnodigen via Telefoonnummer",
    "invite via email address": "Uitnodigen via E-mailadres",
    "next": "Volgende",
    "back": "Terug",
    "Personalized Message": "Gepersonaliseerd Bericht",
    "available currency": "Beschikbare Valuta",
    "attach files": "Bestanden Toevoegen",
    "total amount": "Totaal Bedrag",
    "instant payment": "Directe Betaling",
    "starting from": "Vanaf",
    "recurring cycle": "Terugkerende Cyclus",
    "until I stop": "Tot Gestopt",
    "start from": "Vanaf",
    "cycle": "Cyclus",
    "total": "Totaal",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "De ontvanger kan een licht aangepast bedrag ontvangen door wisselkoersen.",
    "schedule to": "Gepland Naar",
    "schedule at": "Gepland Op",
    "Scheduled Date": "Geplande Datum",
    "Scheduled time": "Geplande Tijd",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "De ontvanger kan een licht aangepast bedrag ontvangen door wisselkoersen.",
    "QR CODE PAYMENT ": "QR CODE BETALING",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "Gebruik als alternatief de alfanumerieke QR-code van InstaPay.",
    "PYAYMENT REQUEST": "BETALINGSVERZOEK",
    "choose the beneficiary": "Kies de Begunstigde",
    "trouble requesting money": "Problemen met Geld Aanvragen?",
    "Standard Request": "Standaard Verzoek",
    "Subscription Request": "Terugkerend Verzoek",
    "Schedule Request": "Gepland Verzoek",
    "Security Request": "Verzoek Om Waarborgsom",
    "Instalment Request": "Termijnplan",
    "Split Request": "Gesplitste Betaling",
    "You recieved": "U heeft ontvangen",
    "is this your location?": "Is dit uw locatie?",
    "yes": "Ja",
    "Authenticator Code": "Authenticator Code",
    "Enter a code shown in the app to make sure everything works fine.": "Voer de code in die in de app wordt weergegeven om te bevestigen dat alles correct is.",
    "SEND A QUOTE": "EEN OFFERTE VERSTUREN",
    "Trouble Quoting Money?": "Problemen met het Verzenden van een Offerte?",
    "Please follow these examples:": "Volg deze voorbeelden:",
    "Full Name: Muhammad Imtiaz": "Volledige Naam: Muhammad Imtiaz",
    "InstaPay/Intagram Username: instauser": "InstaPay/Instagram Gebruikersnaam: instauser",
    "Phone Number: 44795396600 (With Country Code)": "Telefoonnummer: 44795396600 (Met Landcode)",
    "SEND TOP-UP AND E-SIM": "TOP-UP EN E-SIM VERZENDEN",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "Blijf wereldwijd verbonden! De Top-Up service van InstaPay stelt u in staat om telefoons direct op te waarderen, eSIM's, merkbonnen, cadeaubonnen te kopen en rekeningen te betalen in meer dan 150 landen. Ideaal voor geschenken of om voorbereid te blijven, onze service geeft prioriteit aan gemak, snelheid en veiligheid.",
    "MOBILE AIRTIME AND E-SIM": "MOBIEL BELTEGOED EN E-SIM",
    "Select the country where you want to top-up a mobile phone!": "Selecteer het land voor mobiel opwaarderen!",
    "Other Operator": "Andere Operators",
    "airtime": "Beltegoed",
    "bundle": "Bundel",
    "data": "Data",
    "Enter custom amount": "Voer een Aangepast Bedrag in",
    "Credit & Debit Card": "Credit & Debit Card",
    "Bank Transfer": "Bankoverschrijving",
    "Mobile Wallet": "Mobiele Wallet",
    "InstaPay Wallet": "InstaPay Wallet",
    "PayPal": "PayPal",
    "add card payment": "Kaartbetaling Toevoegen",
    "save details to use next time.": "Sla Gegevens op voor Toekomstig Gebruik",
    "Recipient Mobile Number": "Mobiel Nummer van de Ontvanger",
    "Amount": "Bedrag",
    "Service": "Service",
    "Destination": "Bestemming",
    "Provider": "Provider",
    "Airtime": "Beltegoed",
    "Pakistan": "Pakistan",
    "Telenor Pakistan": "Telenor Pakistan",
    "Within minutes": "Binnen Minuten",
    "top-up summary": "Top-Up Samenvatting",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR wordt niet ondersteund door PayPal, USD zal standaard worden gebruikt.",
    "Amount in USD": "Bedrag in USD",
    "Send Top-up": "Verzend Top-Up",
    "available sunservices": "Beschikbare Sunservices",
    "login with email": "Inloggen met E-mail",
    "Already Have an Account?": "Al een Account? Log In",
    "profile picture": "Profielfoto",
    "add photo/logo": "Foto/Logo Toevoegen",
    "first name": "Voornaam",
    "enter your first name": "Voer uw Voornaam in",
    "last name": "Achternaam",
    "enter your last name": "Voer uw Achternaam in",
    "email(optional)": "E-mail (Optioneel)",
    "enter your email address": "Voer uw E-mailadres in",
    "password must": "Wachtwoord Moet",
    "It will be autofill, if you're signing up with invitation link": "Automatisch Invullen voor Uitnodigingsaanmelding",
    "change number": "Nummer Wijzigen",
    "signup error": "Fout bij Aanmelden",
    "Something went wrong while sending sms!": "Er is iets misgegaan tijdens het verzenden van de SMS! Probeer het later opnieuw.",
    "Errors": "Er zijn fouten opgetreden. Raadpleeg de volgende lijst voor details.",
    "Generic error": "Algemene Fout",
    "Trust Payments API requires the 'requests' library": "Trust Payments API vereist de 'requests' bibliotheek om correct te functioneren.",
    "Send error": "Fout tijdens verzendproces. Probeer opnieuw.",
    "Receive error": "Fout tijdens ontvangstproces. Probeer opnieuw.",
    "Invalid credentials provided": "Ongeldige inloggegevens. Controleer en probeer opnieuw.",
    "An issue occurred whilst trying to connect to Trust Payments servers": "Er is een probleem opgetreden bij het verbinden met de Trust Payments-servers. Controleer uw verbinding en probeer opnieuw.",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "Onverwachte fout bij het verbinden met de Trust Payments-servers. Als dit probleem aanhoudt, neem contact op met support@trustpayments.com.",
    "Unknown error. If this persists please contact Trust Payments": "Onbekende fout. Neem contact op met Trust Payments ondersteuning als het probleem aanhoudt.",
    "Incorrect usage of the Trust Payments API": "Onjuist gebruik van Trust Payments API. Controleer het verzoek en probeer opnieuw.",
    "Invalid card details": "Ongeldige kaartgegevens. Controleer en voer de juiste gegevens in.",
    "Invalid date": "Ongeldige datum opgegeven.",
    "Invalid date/time": "Ongeldig datum/tijdformaat. Geef het juiste formaat op.",
    "Invalid details": "Ongeldige gegevens. Controleer en voer de juiste informatie in.",
    "Card number does not match card type": "Kaartnummer komt niet overeen met het opgegeven kaarttype.",
    "Malformed XML": "XML formatteringsfout. Controleer en corrigeer de XML.",
    "XML does not match schema": "XML komt niet overeen met het verwachte schema.",
    "Invalid file format": "Ongeldig bestandsformaat. Gebruik een ondersteund formaat.",
    "Empty file contents": "Lege bestandsinhoud. Geef geldige inhoud op.",
    "Invalid file contents": "Ongeldige bestandsinhoud. Controleer het bestand en probeer opnieuw.",
    "Malformed JSON": "Onjuiste JSON. Corrigeer het JSON-formaat.",
    "StApi Error": "StApi Fout",
    "Invalid fields specified in request": "Ongeldige velden opgegeven in het verzoek. Controleer en corrigeer ze.",
    "Missing parent": "Ontbrekende ouderinformatie.",
    "Refund requires settled parent or parent that's due to settle today": "Terugbetaling vereist een afgeronde oudertransactie of een die vandaag moet worden afgerekend.",
    "Refund requires authorisation parent": "Terugbetaling vereist een autorisatie oudertransactie.",
    "Refund amount too great": "Terugbetalingsbedrag overschrijdt de toegestane limiet.",
    "No acquirer specified": "Geen acquirer-informatie verstrekt.",
    "Repeat amount too great": "Herhaalbedrag overschrijdt de toegestane limiet.",
    "Split amount too great": "Gesplitst bedrag overschrijdt de toegestane limiet.",
    "Cannot refund a declined transaction": "Kan geen afgewezen transactie terugbetalen.",
    "Refund requires a settled parent": "Terugbetaling vereist een afgeronde oudertransactie.",
    "Reversal requires a cancelled auth parent": "Terugboeking vereist een geannuleerde autorisatie-transactie.",
    "Cannot override amount in child transaction": "Kan bedrag in een kindtransactie niet overschrijven.",
    "Cannot override currency in child transaction": "Kan valuta in een kindtransactie niet overschrijven.",
    "Subscription requires RECUR account": "Abonnement vereist een opgezette terugkerende account.",
    "Subscription requires successful parent": "Abonnement vereist een succesvolle oudertransactie.",
    "Risk Decisions must have AUTH as parent": "Risicobeslissingen moeten gekoppeld zijn aan een autorisatie-transactie.",
    "Chargebacks must have AUTH/REFUND as parent": "Terugboekingen moeten gekoppeld zijn aan een autorisatie- of terugbetalingstransactie.",
    "Refund amount less than Minimum allowed": "Het terugbetalingsbedrag is lager dan het toegestane minimum.",
    "Refund requires paypaltransactionid": "Terugbetaling vereist een PayPal-transactie-ID.",
    "Invalid split transaction": "Ongeldige gesplitste transactie.",
    "Cannot reverse AUTH processed more than 48 hours ago": "Kan geen autorisatie terugdraaien die meer dan 48 uur geleden is verwerkt.",
    "Reversal requires acquirerreferencedata": "Terugdraaiing vereist acquirer-referentiegegevens.",
    "Cannot reverse AUTH processed by a different acquirer": "Kan geen autorisatie terugdraaien die door een andere acquirer is verwerkt.",
    "Payment type does not support repeats": "Betaaltype ondersteunt geen herhaalde transacties.",
    "Reversal missing required data": "Terugdraaiing mist vereiste gegevens.",
    "Missing token": "Ontbrekende tokeninformatie.",
    "Subscription with an accountcheck parent not supported on current acquirer": "Abonnement met een accountcontrole ouder niet ondersteund door de huidige acquirer.",
    "Subscription cannot be used as a parent": "Abonnement kan niet worden gebruikt als een oudertransactie.",
    "Invalid parent": "Ongeldig type oudertransactie.",
    "Payment type does not support refunds": "Betaaltype ondersteunt geen terugbetalingen.",
    "Invalid incremental transaction": "Ongeldige incrementele transactie.",
    "Partial reversals not supported": "Gedeeltelijke terugboekingen worden niet ondersteund.",
    "THREEDQUERY parent/child must have the same payment type as the child": "Ouder- en kindtransacties moeten hetzelfde betaaltype hebben voor 3D Secure-query's.",
    "Payment type does not support card scheme updates": "Betaaltype ondersteunt geen kaartenschema-updates.",
    "Cannot reverse AUTH at this time, please try again": "Kan de autorisatie op dit moment niet terugdraaien. Probeer het later opnieuw.",
    "Cannot determine token": "Niet in staat om de token te bepalen. Controleer en probeer het opnieuw.",
    "Service Temporarily Disabled": "Dienst is tijdelijk uitgeschakeld. Probeer het later opnieuw.",
    "Login first request": "Inloggen vereist voordat u een verzoek kunt doen.",
    "Invalid username/password": "Ongeldige gebruikersnaam of wachtwoord. Probeer het opnieuw.",
    "Invalid session": "Ongeldige sessie. Log opnieuw in.",
    "Session has expired": "Sessie verlopen. Log opnieuw in.",
    "Password expired": "Wachtwoord verlopen. Stel uw wachtwoord opnieuw in.",
    "Password has been previously used": "Het wachtwoord is eerder gebruikt. Kies een nieuw wachtwoord.",
    "MyST user account has been locked": "MyST-gebruikersaccount is vergrendeld. Neem contact op met de ondersteuning.",
    "New password does not match confirmed password": "Nieuw wachtwoord komt niet overeen met het bevestigde wachtwoord.",
    "Incorrect current password": "Onjuist huidig wachtwoord. Probeer het opnieuw.",
    "Invalid selection": "Ongeldige selectie gemaakt. Kies een geldige optie.",
    "User already exists": "Gebruiker bestaat al. Log in of reset het wachtwoord indien nodig.",
    "No transaction found": "Geen transactie gevonden.",
    "Invalid selected transactions": "Ongeldige geselecteerde transacties.",
    "Data supplied has not been saved": "Gegevens zijn niet opgeslagen. Probeer het opnieuw.",
    "Invalid request type": "Ongeldig type verzoek.",
    "Missing request type, at least one request type must be selected": "Ontbrekend type verzoek. Er moet minimaal één worden geselecteerd.",
    "Invalid payment type": "Ongeldig betaaltype.",
    "Missing payment type, at least one payment type must be selected": "Ontbrekend betaaltype. Er moet minimaal één worden geselecteerd.",
    "Invalid error code": "Ongeldige foutcode.",
    "Missing error code, at least one error code must be selected": "Ontbrekende foutcode. Er moet minimaal één worden geselecteerd.",
    "Invalid filter description": "Ongeldige filterbeschrijving.",
    "Invalid destination description": "Ongeldige bestemmingsbeschrijving.",
    "Invalid notification type": "Ongeldig type melding.",
    "Invalid destination": "Ongeldige bestemming.",
    "Invalid field selected": "Ongeldig veld geselecteerd.",
    "Invalid email from address": "Ongeldig e-mailadres voor de afzender.",
    "Invalid email subject": "Ongeldige e-mail onderwerpregel.",
    "Invalid email type": "Ongeldig e-mailtype.",
    "Unable to process request": "Kan het verzoek niet verwerken. Probeer het later opnieuw.",
    "No file selected for upload": "Geen bestand geselecteerd voor uploaden.",
    "Invalid file size": "Ongeldige bestandsgrootte. Geef een bestand van acceptabele grootte op.",
    "Invalid filename": "Ongeldige bestandsnaam.",
    "Invalid extension": "Ongeldige bestandsextensie.",
    "User requires at least one site reference": "Gebruiker vereist ten minste één siteverwijzing.",
    "Only ST-level users can have '*' access": "Alleen ST-niveau gebruikers kunnen '*' toegangsrechten hebben.",
    "Request failed": "Verzoek mislukt. Probeer het later opnieuw.",
    "Invalid File Contents": "Ongeldige bestandsinhoud.",
    "Maximum number of files uploaded": "Maximaal aantal geüploade bestanden bereikt. Verwijder enkele bestanden en probeer opnieuw.",
    "Insufficient gateway access privileges": "Onvoldoende toegangsprivileges voor gateway.",
    "Maximum file size limit reached": "Maximale limiet voor bestandsgrootte bereikt.",
    "Username(s) must be a valid user(s)": "Gebruikersnaam/namen moeten geldige gebruikers zijn.",
    "Site reference(s) must be a valid site(s)": "Siteverwijzingen moeten geldige sites zijn.",
    "Unable to send email, please verify the details and try again": "E-mail kan niet verzonden worden. Controleer de gegevens en probeer het opnieuw.",
    "Negative already exists": "Er bestaat al een negatieve waarde. Controleer en maak correcties.",
    "Cannot delete a search owned by another user": "Kan geen zoekopdracht verwijderen die eigendom is van een andere gebruiker.",
    "Invalid search": "Ongeldige zoekopdracht. Probeer het opnieuw met geldige criteria.",
    "Cannot delete the specified search, the search name cannot be found": "Kan de opgegeven zoekopdracht niet verwijderen. De zoeknaam kan niet worden gevonden.",
    "Search parameter is too short": "Zoekparameter is te kort. Voer specifiekere zoektermen in.",
    "Duplicate custom fields defined": "Gedefinieerde aangepaste velden zijn dubbel. Los de duplicaten op.",
    "Cannot allocate selected users, insufficient privileges": "Kan geselecteerde gebruikers niet toewijzen. Onvoldoende privileges.",
    "Allocated users have access to additional sites": "Toegewezen gebruikers hebben toegang tot extra sites.",
    "Allocated users have access to additional users": "Toegewezen gebruikers hebben toegang tot extra gebruikers.",
    "User with current role cannot be allocated users": "Gebruikers met de huidige rol kunnen geen andere gebruikers toewijzen.",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Deze site vereist dat uw browser cookies accepteert om in te loggen. Cookies kunnen worden geaccepteerd door hieronder op 'Ik accepteer' te klikken.",
    "User requires at least one site reference or site group": "Gebruiker vereist ten minste één siteverwijzing of sitegroep.",
    "Allocated users have access to additional site groups": "Toegewezen gebruikers hebben toegang tot extra sitegroepen.",
    "No statement found": "Geen verklaring gevonden.",
    "Data supplied has not been updated in MobilePay 3rd-party service": "Gegevens die zijn verstrekt, zijn niet bijgewerkt in de MobilePay-dienst van derden.",
    "Bypass": "Omzeilen",
    "Insufficient access privileges": "Onvoldoende toegangsprivileges.",
    "Coding error": "Codering fout gedetecteerd.",
    "Insufficient privileges": "Onvoldoende privileges om deze actie te voltooien.",
    "Invalid request": "Ongeldig verzoek. Controleer en probeer opnieuw.",
    "Invalid field": "Ongeldig veld ingevoerd. Controleer en corrigeer de invoer.",
    "Unknown site": "Onbekende site.",
    "Banned card": "Geblokkeerde kaart.",
    "XML element parse error": "XML-element verwerkingsfout. Controleer de XML-structuur.",
    "Maestro must use SecureCode": "Maestro-transacties moeten SecureCode gebruiken voor validatie.",
    "Multiple email addresses must be separated with , or ;": "Meerdere e-mailadressen moeten worden gescheiden met komma's (,) of puntkomma's (;).",
    "Invalid site reference for alias": "Ongeldige siteverwijzing voor alias.",
    "Invalid version number": "Ongeldig versienummer. Voer een geldig versienummer in.",
    "Unknown user": "Onbekende gebruiker. Controleer en probeer opnieuw.",
    "Cannot determine account": "Kan het account niet bepalen.",
    "JSON element parse error": "JSON-element verwerkingsfout. Controleer de JSON-structuur.",
    "Wallet type configuration error": "Configuratiefout voor portemonneetype.",
    "Wallet type not supported on this request": "Portemonneetype wordt niet ondersteund voor dit verzoek.",
    "The card number you have provided is incorrect, please verify your details and try again": "Het opgegeven kaartnummer is onjuist. Controleer uw gegevens en probeer het opnieuw.",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "De beveiligingscode (CVV2) die u hebt opgegeven is onjuist. Controleer en probeer opnieuw.",
    "The expiry date you have provided is incorrect, please verify your details and try again": "De verstrekte vervaldatum is onjuist. Controleer uw gegevens en probeer het opnieuw.",
    "The expiry month you have provided is incorrect, please verify your details and try again": "De verstrekte vervalmaand is onjuist. Controleer en probeer opnieuw.",
    "The expiry year you have provided is incorrect, please verify your details and try again": "Het verstrekte vervaljaar is onjuist. Controleer en probeer het opnieuw.",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "Kan uw betaling niet verwerken vanwege verbindingsfouten. Verzoek-ID komt niet overeen. Probeer het opnieuw.",
    "The issue number you have provided is incorrect, please verify your details and try again": "Het opgegeven nummer van de kwestie is onjuist. Controleer en probeer opnieuw.",
    "The payment type you have provided is incorrect, please verify your details and try again": "Het opgegeven betalingstype is onjuist. Controleer en probeer het opnieuw.",
    "Unable to process your payment, please contact the website": "Kan uw betaling niet verwerken. Neem contact op met de website voor ondersteuning.",
    "There are errors with these fields: {0}": "Er zijn fouten met deze velden: {0}",
    "No account found": "Geen account gevonden.",
    "Refund cannot be processed": "Terugbetaling kan niet worden verwerkt.",
    "Transaction de-activated": "Transactie gedeactiveerd.",
    "Socket receive error": "Socket ontvangfout.",
    "Socket connection error": "Socket verbindingsfout.",
    "Socket closed": "Socket gesloten.",
    "Invalid data received": "Ongeldige gegevens ontvangen.",
    "Invalid SQL": "Ongeldige SQL-query.",
    "Timeout": "Time-out opgetreden.",
    "Invalid acquirer": "Ongeldige acquirer gegevens.",
    "Unable to connect to acquirer": "Kan geen verbinding maken met de acquirer.",
    "Invalid response from acquirer": "Ongeldig antwoord van de acquirer.",
    "No available transport": "Geen beschikbaar transport gevonden.",
    "File size too large": "Bestandsgrootte te groot.",
    "Socket send error": "Socket zendfout.",
    "Communication error": "Communicatiefout.",
    "Proxy error": "Proxy fout.",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "Kan uw betaling niet verwerken vanwege verbindingsfouten. Controleer uw gegevens en probeer het opnieuw ({0}).",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "Kan uw betaling niet verwerken vanwege verbindingsfouten (HTTP response status {0}). Controleer uw gegevens en probeer het opnieuw ({1}).",
    "Wrong number of emails": "Onjuist aantal opgegeven e-mailadressen.",
    "Bank System Error": "Bank systeemfout.",
    "Wrong number of transactions": "Onjuist aantal transacties opgegeven.",
    "Invalid transport configuration": "Ongeldige transportconfiguratie.",
    "No valid updates specified": "Geen geldige updates opgegeven.",
    "Transaction reference not found": "Transactieverwijzing niet gevonden.",
    "Settlebaseamount too large": "Basiskredietbedrag is te groot.",
    "Transaction not updatable": "Transactie niet bij te werken.",
    "No searchable filter specified": "Geen doorzoekbaar filter opgegeven.",
    "Timeout Error": "Time-out fout. Probeer het opnieuw.",
    "3-D Secure Transport Error": "3-D Secure transportfout.",
    "Unauthenticated": "Niet-geauthenticeerd verzoek.",
    "Site Suspended": "Site opgeschort.",
    "No updates performed": "Geen updates uitgevoerd.",
    "Invalid Request": "Ongeldig verzoek.",
    "Invalid Response": "Ongeldig antwoord.",
    "Invalid Acquirer": "Ongeldige acquirer informatie.",
    "Invalid account data": "Ongeldige accountgegevens.",
    "Missing": "Ontbrekende informatie.",
    "Payment Error": "Betalingsfout.",
    "Invalid acquirer for 3-D Secure": "Ongeldige acquirer voor 3-D Secure.",
    "Invalid payment type for 3-D Secure": "Ongeldig betalingstype voor 3-D Secure.",
    "Invalid updates specified": "Ongeldige updates opgegeven.",
    "Manual investigation required": "Handmatig onderzoek vereist.",
    "Invalid headers": "Ongeldige headers.",
    "Max fraudscore exceeded": "Maximale fraude score overschreden.",
    "Invalid filters": "Ongeldige filters opgegeven.",
    "Merchant System Error": "Fout in handelaarssysteem.",
    "Your payment is being processed. Please wait...": "Uw betaling wordt verwerkt. Even geduld...",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "Kan niet zowel 'verzoek type beschrijving' als 'verzoek type beschrijvingen' in één verzoek opgeven.",
    "Acquirer missing original transaction data": "Acquirer mist originele transactiedata.",
    "Insufficient funds": "Onvoldoende saldo.",
    "Unable to process due to scheme restrictions": "Kan niet verwerken vanwege regelingbeperkingen.",
    "Failed Screening": "Gefaalde screening.",
    "Unable to process due to restrictions": "Kan niet verwerken vanwege beperkingen.",
    "Invalid process": "Ongeldig proces.",
    "Risk Referral": "Risicobeoordeling vereist.",
    "Name Pick required": "Naam is vereist voor selectie.",
    "Address Pick required": "Adres is vereist voor selectie.",
    "IP not in range": "IP-adres valt niet binnen het bereik.",
    "Invalid button configuration": "Ongeldige knopconfiguratie.",
    "Unrecognised response from acquirer": "Onherkenbaar antwoord van acquirer.",
    "Decline": "Afwijzing.",
    "Uncertain result": "Onzeker resultaat.",
    "Soft Decline": "Zachte afwijzing.",
    "Refer to Issuer": "Raadpleeg de verstrekker voor meer informatie.",
    "Request is queued please check the transaction later for the status": "Verzoek staat in de wachtrij. Controleer de transactiestatus later.",
    "Generic Retry": "Algemene herhaling.",
    "Soft Decline retry": "Zachte afwijzing opnieuw proberen.",
    "There has been a problem with your payment, please verify your details and try again": "Er is een probleem met uw betaling. Controleer uw gegevens en probeer het opnieuw.",
    "Unknown error": "Onbekende fout.",

    "Login": "Inloggen",
    "Enter Your Email": "Voer je e-mailadres in",
    "Login With Phone Number": "Inloggen met telefoonnummer",
    "Verify Using Sms/Email": "Verifiëren Via Sms/Email",
    "Resend Code In 100 Seconds": "Code opnieuw verzenden in 100 seconden",
    "Verify": "Verifiëren",
    "Verifying": "Verifiëren bezig",
    "Last Week": "Vorige week",
    "Older Transaction": "Oudere transactie",
    "Total": "Totaal",
    "Conversion Confirmed": "Conversie bevestigd",
    "User Account Limit": "Gebruikersaccountlimiet",
    "Monthly Balance Limit": "Maandelijkse saldolimiet",
    "Total Limit": "Totale limiet",
    "Used": "Gebruikt",
    "Remaining": "Overig",
    "Allowed Minimum Topup Amount": "Toegestane minimale opwaardeerbedrag",
    "Allowed Maximum Topup Amount": "Toegestane maximale opwaardeerbedrag",
    "Incoming Limit": "Inkomende limiet",
    "Daily limit": "Daglimiet",
    "Monthly limit": "Maandlimiet",
    "Yearly limit": "Jaarlimiet",
    "Sending Limit": "Verzendlimiet",
    "Allowed Number Of Transactions": "Toegestaan aantal transacties",
    "Total Amount": "Totaal Bedrag",
    "Amount To Send": "Te verzenden bedrag",
    "Destination Amount": "Bestemmingsbedrag",
    "Note": "Opmerking",
    "Revised Amount": "Herzien bedrag",
    "Save": "Opslaan",
    "Date": "Datum",
    "Action": "Actie",
    "Payment Scheduled At": "Betaling gepland op",
    "Transaction Fee": "Transactiekosten",
    "Number Of Cycle": "Aantal cycli",
    "Subscription Starting Date": "Begindatum van abonnement",
    "Until": "Tot",
    "Next Transaction": "Volgende transactie",
    "Pay Now": "Betaal nu",
    "Transaction Method": "Betaalmethode",
    "Mobile Money Provider": "Mobiele geldprovider",
    "Wallet Name": "Portemonneenaam",
    "Wallet Number": "Portemonneenummer",
    "Crypto Currency": "Cryptovaluta",
    "Wallet Address": "Portemonnee-adres",
    "Select Document Type": "Selecteer documenttype",
    "Edit Document Number": "Bewerk documentnummer",
    "My Withdrawals": "Mijn opnames",
    "Bargainable": "Onderhandelbaar",
    "Description": "Beschrijving",
    "Attached Files": "Aangehechte bestanden",
    "Sent": "Verzonden",
    "Accepted": "Geaccepteerd",
    "Decline": "Weigeren",
    "Pending": "In behandeling",
    "Overall Rating": "Algemene beoordeling",
    "No Review": "Geen beoordeling",
    "0 Rating": "0 beoordeling",
    "Transaction Map": "Transactiemap",
    "Send Money": "Geld sturen",
    "Sending Method": "Verzenden methode",
    "Trouble Sending Money": "Problemen met Geld Verzenden",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "De kosten en het ontvangen bedrag kunnen variëren afhankelijk van de gekozen betaalmethode.",
    "Select Method": "Selecteer Methode",
    "Completed": "Voltooid",
    "How Was Your Experience?": "Hoe was je ervaring?",
    "Leave A Review": "Laat een beoordeling achter",
    "Review History": "Beoordelingsgeschiedenis",
    "Write A Review": "Schrijf een beoordeling",
    "Send And Post": "Verzenden en Plaatsen",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Crypto naar Fiat-conversie is een robuuste en veilige oplossing die naadloos cryptocurrencies naar fiat-valuta's converteert. Het voldoet aan de diverse behoeften van gebruikers door een overvloed aan uitbetalingskanalen aan te bieden die zijn afgestemd op individuele voorkeuren en locaties.\"",
    "Review Created": "Review Aangemaakt",
    "Transactions": "Transacties",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Sleep/Drop of Upload een bestand (Foto/Video/Pdf)",
    "Max Size 1GB": "Max Size 1GB",
    "Title Description": "Titel Beschrijving",
    "Add New": "Voeg Nieuw Toe",
    "Discard": "Weggooien",
    "Save And Post": "Opslaan en Plaatsen",
    "Personal Account": "Persoonlijk Account",
    "Conversion": "Conversie",
    "Spent In Total": "Totaal Besteed",
    "International": "Internationaal",
    "Recharge": "Opladen",
    "Top Up Wallet To Wallet": "Top Up Wallet naar Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Niet Verbonden",
    "More": "Meer",
    "Less": "Minder",
    "Profile Reports": "Profiel Rapporten",
    "Timezone": "Tijdzone",
    "Cancel": "Annuleren",
    "Full Name": "Volledige Naam",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Aanvullende Documenten",
    "Profile Complete": "Profiel Voltooid",
    "Verified": "Geverifieerd",
    "Bank Account": "Bank Rekening",
    "Mobile Wallet": "Mobiele Wallet",
    "Cash Pickup": "Contante Ophaling",
    "Add": "Toevoegen",
    "Configured": "Geconfigureerd",
    "Email": "E-mail",
    "Download Data": "Download Gegevens",
    "Enter The Amount": "Voer Het Bedrag In",
    "They Receive": "Ze Ontvangen",
    "Where Do You Want To Send Money": "Waar Wil Je Geld Versturen",
    "Bank Transfer": "Bankoverschrijving",
    "Receiving Method": "Ontvangstmethode",
    "Payer": "Betaler",
    "Minimum Amount": "Minimale Bedrag",
    "Maximum Amount": "Maximale Bedrag",
    "Search": "Zoek",
    "Show More": "Toon Meer",
    "Select Remark / Reasons (All)": "Selecteer Opmerking / Redenen (Alles)",
    "Attach Files": "Voeg Bestanden Toe",
    "Holiday List": "Vakantie Lijst",
    "No Data To Show": "Geen Gegevens Om Weer Te Geven",
    "Threshold Limits": "Drempel Limieten",
    "Amount Limits": "Bedrag Limieten",
    "OTP Verification Code": "OTP Verificatiecode",
    "Verify Using Authenticator": "Verifiëren Via Authenticator",
    "Sms/Email Verification Code": "Sms/Email Verificatiecode",
    "Invite Them": "Nodig Ze Uit",
    "Lookup": "Zoek Op",
    "Invite Friend": "Nodig Vriend Uit",
    "Invite Via Phone Number": "Nodig Uit Via Telefoonnummer",
    "Invite Via Email Address": "Nodig Uit Via E-mailadres",
    "Next": "Volgende",
    "Back": "Terug",
    "Invite Via Email": "Nodig Uit Via E-mail",
    "Available Currency": "Beschikbare Valuta",
    "Add Remarks And Comments": "Voeg Opmerkingen En Reacties Toe",
    "Recipient Details": "Ontvanger Details",
    "Go Back": "Teruggaan",
    "Trouble Requesting Money": "Probleem Met Geld Aanvragen",
    "Instant": "Direct",
    "Subscription": "Abonnement",
    "Schedule": "Schema",
    "Choose Request Type": "Kies Aanvraag Type",
    "Mobile Airtime": "Mobiele Beltegoed",
    "Incorrect Phone Number": "Onjuist Telefoonnummer",
    "Make Sure You Have Entered A Correct Phone Number.": "Zorg ervoor dat u het juiste telefoonnummer heeft ingevoerd.",
    "Close": "Sluiten",
    "Operator": "Operator",
    "Recipient Mobile Number": "Ontvanger Telefoonnummer",
    "Amount": "Bedrag",
    "Service": "Dienst",
    "Destination": "Bestemming",
    "Top Up Summary": "Top Up Samenvatting",
    "Pakistan": "Pakistan",
    "Daily Sending Limit Exceeded": "Dagelijkse Limiet Overschreden",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Stuur een gedetailleerde prijsopgave naar uw klant of koper. Presenteer uw service, producten of aanbiedingen professioneel met een gepersonaliseerde offerte.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Betalingen die op werkdagen voor 15:00 uur worden gedaan, worden vóór middernacht van dezelfde dag bijgeschreven op de ontvangende bankrekening.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Stel uw ontvangende rekeningen in tot drie landen in en haal onmiddellijk geld op van goedgekeurde betalingsverzoeken naar uw geactiveerde uitbetalingskanalen.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom behoudt zich het recht voor om aanvullende documenten (Enhanced Due Diligence - EDD) of verdere informatie op te vragen die nodig zijn voor grondige verificaties, in overeenstemming met zijn wettelijke verplichtingen.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Het moet een door de overheid uitgegeven identiteitsbewijs zijn met een foto. Alle identiteitsdocumenten moeten in het Latijnse alfabet zijn geschreven. Zo niet, dan is een gecertificeerde vertaling vereist.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Laat je werk zien, vergroot je bereik. 'Mijn Portfolio' is je digitale canvas op Instapay. Upload en toon een breed scala aan inhoud - van boeiende afbeeldingen en video's tot informatieve PDF's. Of je nu een kunstenaar, freelancer of ondernemer bent, deze functie stelt je in staat om je werk of projecten visueel aantrekkelijk te presenteren.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Deel je QR-code of betaallink om direct betaald te worden in je Insta Wallet.",
    "Afghanistan": "Afghanistan",
    "Aland Islands": "Ålandeilanden",
    "Albania": "Albanië",
    "Algeria": "Algerije",
    "American Samoa": "Amerikaans-Samoa",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antigua and Barbuda": "Antigua en Barbuda",
    "Argentina": "Argentinië",
    "Armenia": "Armenië",
    "Aruba": "Aruba",
    "Australia": "Australië",
    "Austria": "Oostenrijk",
    "Azerbaijan": "Azerbeidzjan",
    "Bahamas": "Bahama's",
    "Bahrain": "Bahrein",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Wit-Rusland",
    "Belgium": "België",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Bhutan",
    "Bolivia (Plurinational State of)": "Bolivië",
    "Bonaire - Sint Eustatius and Saba": "Bonaire - Sint Eustatius en Saba",
    "Bosnia and Herzegovina": "Bosnië en Herzegovina",
    "Botswana": "Botswana",
    "Brazil": "Brazilië",
    "British Indian Ocean Territory": "Britse Gebieden in de Indische Oceaan",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgarije",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Kaapverdië",
    "Cambodia": "Cambodja",
    "Cameroon": "Kameroen",
    "Canada": "Canada",
    "Cayman Islands": "Kaaimaneilanden",
    "Central African Republic": "Centraal-Afrikaanse Republiek",
    "Chad": "Tsjaad",
    "Chile": "Chili",
    "China": "China",
    "Christmas Island": "Kerstmis-eiland",
    "Cocos (Keeling) Islands": "Cocos (Keeling) eilanden",
    "Colombia": "Colombia",
    "Comoros": "Comoren",
    "Congo": "Congo",
    "Congo - Democratic Republic of the": "Congo - Democratische Republiek van de",
    "Cook Islands": "Cookeilanden",
    "Costa Rica": "Costa Rica",
    "Cote d'Ivoire": "Ivoorkust",
    "Croatia": "Kroatië",
    "Cuba": "Cuba",
    "Curacao": "Curaçao",
    "Cyprus": "Cyprus",
    "Czech Republic": "Tsjechië",
    "Denmark": "Denemarken",
    "Djibouti": "Djibouti",
    "Dominica": "Dominica",
    "Dominican Republic": "Dominicaanse Republiek",
    "Ecuador": "Ecuador",
    "Egypt": "Egypte",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Equatoriaal-Guinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estland",
    "Eswatini": "Eswatini",
    "Ethiopia": "Ethiopië",
    "Falkland Islands (Malvinas)": "Falklandeilanden (Malvinas)",
    "Faroe Islands": "Faeröer-eilanden",
    "Fiji": "Fiji",
    "Finland": "Finland",
    "France": "Frankrijk",
    "French Polynesia": "Frans-Polynesië",
    "French Southern Territories": "Franse Zuid- en Antarctische Gebieden",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgië",
    "Germany": "Duitsland",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Griekenland",
    "Greenland": "Groenland",
    "Grenada": "Grenada",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea (Conakry)": "Guinee (Conakry)",
    "Guinea-Bissau": "Guinee-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haïti",
    "Heard Island and McDonald Islands": "Heard- en McDonald-eilanden",
    "Holy See": "Heilige Stoel",
    "Honduras": "Honduras",
    "Hong Kong": "Hongkong",
    "Hungary": "Hongarije",
    "Iceland": "IJsland",
    "India": "India",
    "Indonesia": "Indonesië",
    "Iran (Islamic Republic of)": "Iran (Islamitische Republiek)",
    "Iraq": "Irak",
    "Ireland": "Ierland",
    "Isle of Man": "Isle of Man",
    "Israel": "Israël",
    "Italy": "Italië",
    "Jamaica": "Jamaica",
    "Japan": "Japan",
    "Jersey": "Jersey",
    "Jordan": "Jordanië",
    "Kazakhstan": "Kazachstan",
    "Kenya": "Kenia",
    "Kiribati": "Kiribati",
    "Korea (Democratic People's Republic of)": "Noord-Korea",
    "Korea - Republic of": "Zuid-Korea",
    "Kuwait": "Koeweit",
    "Kyrgyzstan": "Kirgizië",
    "Lao People's Democratic Republic": "Laos",
    "Latvia": "Letland",
    "Lebanon": "Libanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libië",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Litouwen",
    "Luxembourg": "Luxemburg",
    "Macao": "Macau",
    "Madagascar": "Madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Maleisië",
    "Maldives": "Maldiven",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshalleilanden",
    "Mauritania": "Mauritanië",
    "Mauritius": "Mauritius",
    "Mexico": "Mexico",
    "Micronesia (Federated States of)": "Micronesië (Federale Staten van)",
    "Moldova - Republic of": "Moldavië",
    "Monaco": "Monaco",
    "Mongolia": "Mongolië",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Morocco": "Marokko",
    "Mozambique": "Mozambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namibië",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Nederland",
    "New Caledonia": "Nieuw-Caledonië",
    "New Zealand": "Nieuw-Zeeland",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Norfolkeiland",
    "North Macedonia": "Noord-Macedonië",
    "Northern Mariana Islands": "Noordelijke Marianen",
    "Norway": "Noorwegen",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestine - State of": "Palestina - Staat van",
    "Panama": "Panama",
    "Papua New Guinea": "Papoea-Nieuw-Guinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Filipijnen",
    "Pitcairn": "Pitcairn",
    "Poland": "Polen",
    "Portugal": "Portugal",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Qatar",
    "Romania": "Roemenië",
    "Russian Federation": "Russische Federatie",
    "Rwanda": "Rwanda",
    "Saint Barth\u00e9lemy": "Saint-Barthélemy",
    "Saint Helena - Ascension and Tristan da Cunha": "Saint Helena",
    "Saint Kitts and Nevis": "Saint Kitts en Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Martin (French part)": "Saint Martin (Franse deel)",
    "Saint Vincent and the Grenadines": "Saint Vincent en de Grenadines",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "São Tomé en Príncipe",
    "Senegal": "Senegal",
    "Serbia": "Servië",
    "Seychelles": "Seychellen",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Sint Maarten (Dutch part)": "Sint Maarten (Nederlandse deel)",
    "Slovakia": "Slowakije",
    "Slovenia": "Slovenië",
    "Solomon Islands": "Salomonseilanden",
    "Somalia": "Somalië",
    "South Africa": "Zuid-Afrika",
    "South Georgia and the South Sandwich Islands": "Zuid-Georgië en de Zuidelijke Sandwicheilanden",
    "South Sudan": "Zuid-Soedan",
    "Spain": "Spanje",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Soedan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard en Jan Mayen",
    "Sweden": "Zweden",
    "Switzerland": "Zwitserland",
    "Syrian Arab Republic": "Syrië",
    "Tajikistan": "Tadzjikistan",
    "United Republic of Tanzania": "Tanzania",
    "Thailand": "Thailand",
    "Timor-Leste": "Oost-Timor",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad en Tobago",
    "Tunisia": "Tunesië",
    "Turkey": "Turkije",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Turks- en Caicoseilanden",
    "Tuvalu": "Tuvalu",
    "Uganda": "Oeganda",
    "Ukraine": "Oekraïne",
    "United Arab Emirates": "Verenigde Arabische Emiraten",
    "United Kingdom of Great Britain and Northern Ireland": "Verenigd Koninkrijk",
    "United States of America": "Verenigde Staten",
    "United States Minor Outlying Islands": "Kleine afgelegen eilanden van de Verenigde Staten",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Oezbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Venezuela",
    "Viet Nam": "Vietnam",
    "Virgin Islands (British)": "Maagdeneilanden (Brits)",
    "Virgin Islands (U.S.)": "Maagdeneilanden (VS)",
    "Wallis and Futuna": "Wallis en Futuna",
    "Yemen": "Jemen",
    "Zambia": "Zambia",
    "Zimbabwe": "Zimbabwe",
    "Taiwan": "Taiwan",
    "Palestine": "Palestina",
    "Western Sahara": "Westelijke Sahara",
    "Kosovo": "Kosovo",
    "South Ossetia": "Zuid-Ossetië",
    "Abkhazia": "Abchazië",
    "Nagorno-Karabakh (Artsakh)": "Nagorno-Karabach (Artsach)",
    "Transnistria": "Transnistrië",
    "Somaliland": "Somaliland",
    "Northern Cyprus": "Noord-Cyprus",
    "Saudi Arabia": "Saoedi-Arabië",
    "Yugoslavia": "Joegoslavië",

    "Login": "Inloggen",
    "Enter Your Email": "Voer je e-mailadres in",
    "Login With Phone Number": "Inloggen met telefoonnummer",
    "Verify Using Sms/Email": "Verifiëren Via Sms/Email",
    "Resend Code In 100 Seconds": "Code opnieuw verzenden in 100 seconden",
    "Verify": "Verifiëren",
    "Verifying": "Verifiëren bezig",
    "Last Week": "Vorige week",
    "Older Transaction": "Oudere transactie",
    "Total": "Totaal",
    "Conversion Confirmed": "Conversie bevestigd",
    "User Account Limit": "Gebruikersaccountlimiet",
    "Monthly Balance Limit": "Maandelijkse saldolimiet",
    "Total Limit": "Totale limiet",
    "Used": "Gebruikt",
    "Remaining": "Overig",
    "Allowed Minimum Topup Amount": "Toegestane minimale opwaardeerbedrag",
    "Allowed Maximum Topup Amount": "Toegestane maximale opwaardeerbedrag",
    "Incoming Limit": "Inkomende limiet",
    "Daily Limit": "Daglimiet",
    "Monthly Limit": "Maandlimiet",
    "Yearly Limit": "Jaarlimiet",
    "Sending Limit": "Verzendlimiet",
    "Allowed Number Of Transactions": "Toegestaan aantal transacties",
    "Total Amount": "Totaal Bedrag",
    "Amount To Send": "Te verzenden bedrag",
    "Destination Amount": "Bestemmingsbedrag",
    "Note": "Opmerking",
    "Revised Amount": "Herzien bedrag",
    "Save": "Opslaan",
    "Date": "Datum",
    "Action": "Actie",
    "Payment Scheduled At": "Betaling gepland op",
    "Transaction Fee": "Transactiekosten",
    "Number Of Cycle": "Aantal cycli",
    "Subscription Starting Date": "Begindatum van abonnement",
    "Until": "Tot",
    "Next Transaction": "Volgende transactie",
    "Pay Now": "Betaal nu",
    "Transaction Method": "Betaalmethode",
    "Mobile Money Provider": "Mobiele geldprovider",
    "Wallet Name": "Portemonneenaam",
    "Wallet Number": "Portemonneenummer",
    "Crypto Currency": "Cryptovaluta",
    "Wallet Address": "Portemonnee-adres",
    "Select Document Type": "Selecteer documenttype",
    "Edit Document Number": "Bewerk documentnummer",
    "My Withdrawals": "Mijn opnames",
    "Bargainable": "Onderhandelbaar",
    "Description": "Beschrijving",
    "Attached Files": "Aangehechte bestanden",
    "Sent": "Verzonden",
    "Accepted": "Geaccepteerd",
    "Decline": "Weigeren",
    "Pending": "In behandeling",
    "Overall Rating": "Algemene beoordeling",
    "No Review": "Geen beoordeling",
    "0 Rating": "0 beoordeling",
    "Transaction Map": "Transactiemap",
    "Send Money": "Geld sturen",
    "Sending Method": "Verzenden methode",
    "Trouble Sending Money": "Problemen met Geld Verzenden",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "De kosten en het ontvangen bedrag kunnen variëren afhankelijk van de gekozen betaalmethode.",
    "Select Method": "Selecteer Methode",
    "Completed": "Voltooid",
    "How Was Your Experience?": "Hoe was je ervaring?",
    "Leave A Review": "Laat een beoordeling achter",
    "Review History": "Beoordelingsgeschiedenis",
    "Write A Review": "Schrijf een beoordeling",
    "Send And Post": "Verzenden en Plaatsen",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Crypto naar Fiat-conversie is een robuuste en veilige oplossing die naadloos cryptocurrencies naar fiat-valuta's converteert. Het voldoet aan de diverse behoeften van gebruikers door een overvloed aan uitbetalingskanalen aan te bieden die zijn afgestemd op individuele voorkeuren en locaties.\"",
    "Review Created": "Review Aangemaakt",
    "Transactions": "Transacties",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Sleep/Drop of Upload een bestand (Foto/Video/Pdf)",
    "Max Size 1GB": "Max Size 1GB",
    "Title Description": "Titel Beschrijving",
    "Add New": "Voeg Nieuw Toe",
    "Discard": "Weggooien",
    "Save And Post": "Opslaan en Plaatsen",
    "Personal Account": "Persoonlijk Account",
    "Conversion": "Conversie",
    "Spent In Total": "Totaal Besteed",
    "International": "Internationaal",
    "Recharge": "Opladen",
    "Top Up Wallet To Wallet": "Top Up Wallet naar Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Niet Verbonden",
    "More": "Meer",
    "Less": "Minder",
    "Profile Reports": "Profiel Rapporten",
    "Timezone": "Tijdzone",
    "Cancel": "Annuleren",
    "Full Name": "Volledige Naam",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Aanvullende Documenten",
    "Profile Complete": "Profiel Voltooid",
    "Verified": "Geverifieerd",
    "Bank Account": "Bank Rekening",
    "Mobile Wallet": "Mobiele Wallet",
    "Cash Pickup": "Contante Ophaling",
    "Add": "Toevoegen",
    "Configured": "Geconfigureerd",
    "Email": "E-mail",
    "Download Data": "Download Gegevens",
    "Enter The Amount": "Voer Het Bedrag In",
    "They Receive": "Ze Ontvangen",
    "Where Do You Want To Send Money": "Waar Wil Je Geld Versturen",
    "Bank Transfer": "Bankoverschrijving",
    "Receiving Method": "Ontvangstmethode",
    "Payer": "Betaler",
    "Minimum Amount": "Minimale Bedrag",
    "Maximum Amount": "Maximale Bedrag",
    "Search": "Zoek",
    "Show More": "Toon Meer",
    "Select Remark / Reasons (All)": "Selecteer Opmerking / Redenen (Alles)",
    "Attach Files": "Voeg Bestanden Toe",
    "Holiday List": "Vakantie Lijst",
    "No Data To Show": "Geen Gegevens Om Weer Te Geven",
    "Threshold Limits": "Drempel Limieten",
    "Amount Limits": "Bedrag Limieten",
    "OTP Verification Code": "OTP Verificatiecode",
    "Verify Using Authenticator": "Verifiëren Via Authenticator",
    "Sms/Email Verification Code": "Sms/Email Verificatiecode",
    "Invite Them": "Nodig Ze Uit",
    "Lookup": "Zoek Op",
    "Invite Friend": "Nodig Vriend Uit",
    "Invite Via Phone Number": "Nodig Uit Via Telefoonnummer",
    "Invite Via Email Address": "Nodig Uit Via E-mailadres",
    "Next": "Volgende",
    "Back": "Terug",
    "Invite Via Email": "Nodig Uit Via E-mail",
    "Available Currency": "Beschikbare Valuta",
    "Add Remarks And Comments": "Voeg Opmerkingen En Reacties Toe",
    "Recipient Details": "Ontvanger Details",
    "Go Back": "Teruggaan",
    "Trouble Requesting Money": "Probleem Met Geld Aanvragen",
    "Instant": "Direct",
    "Subscription": "Abonnement",
    "Schedule": "Schema",
    "Choose Request Type": "Kies Aanvraag Type",
    "Mobile Airtime": "Mobiele Beltegoed",
    "Incorrect Phone Number": "Onjuist Telefoonnummer",
    "Make Sure You Have Entered A Correct Phone Number.": "Zorg ervoor dat u het juiste telefoonnummer heeft ingevoerd.",
    "Close": "Sluiten",
    "Operator": "Operator",
    "Recipient Mobile Number": "Ontvanger Telefoonnummer",
    "Amount": "Bedrag",
    "Service": "Dienst",
    "Destination": "Bestemming",
    "Top Up Summary": "Top Up Samenvatting",
    "Daily Sending Limit Exceeded": "Dagelijkse Limiet Overschreden",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Stuur een gedetailleerde prijsopgave naar uw klant of koper. Presenteer uw service, producten of aanbiedingen professioneel met een gepersonaliseerde offerte.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Betalingen die op werkdagen voor 15:00 uur worden gedaan, worden vóór middernacht van dezelfde dag bijgeschreven op de ontvangende bankrekening.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Stel uw ontvangende rekeningen in tot drie landen in en haal onmiddellijk geld op van goedgekeurde betalingsverzoeken naar uw geactiveerde uitbetalingskanalen.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom behoudt zich het recht voor om aanvullende documenten (Enhanced Due Diligence - EDD) of verdere informatie op te vragen die nodig zijn voor grondige verificaties, in overeenstemming met zijn wettelijke verplichtingen.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Het moet een door de overheid uitgegeven identiteitsbewijs zijn met een foto. Alle identiteitsdocumenten moeten in het Latijnse alfabet zijn geschreven. Zo niet, dan is een gecertificeerde vertaling vereist.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Laat je werk zien, vergroot je bereik. 'Mijn Portfolio' is je digitale canvas op Instapay. Upload en toon een breed scala aan inhoud - van boeiende afbeeldingen en video's tot informatieve PDF's. Of je nu een kunstenaar, freelancer of ondernemer bent, deze functie stelt je in staat om je werk of projecten visueel aantrekkelijk te presenteren.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Deel je QR-code of betaallink om direct betaald te worden in je Insta Wallet.",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "U heeft 15 minuten om de transactie te voltooien.",
    "Time Remaining": "Voltooi binnen de tijdslimiet om te voorkomen dat de URL verloopt.",
    "Please Process The Payment Via Your Selected Payment Type": "Verwerk de betaling via uw geselecteerde betaalmethode.",
    "Top-Up Wallet": "Betaalmethode",
    "Confirm Your Payment": "PORTEMONNEE OPLADEN",
    "You Will Get": "Bevestig uw betaling",
    "Amount To Pay": "U ontvangt",
    "Proceed To Payment": "Te betalen bedrag",
    "Load/Deposit Money To The Wallet": "Verder naar betaling",
    "Withdraw": "Geld laden/storten in de portemonnee",
    "Elevate Your Business With InstaPay": "Opnemen",
    "Higher Balances & Transaction Limits": "Verbeter uw bedrijf met InstaPay",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Hogere saldi en transactiegrenzen",
    "Full Feature Access": "Ontgrendel de vrijheid om grotere saldi te beheren en hogere transactievolumes te verwerken, met de betrouwbaarheid die u van InstaPay verwacht.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Volledige toegang tot functies",
    "Global Reach": "Wereldwijde Bereikbaarheid",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "Breid uw bedrijf zonder grenzen uit. InstaPay verbindt u met klanten en partners over de hele wereld, waardoor wereldwijde transacties eenvoudiger dan ooit worden.",
    "Advanced Security": "Geavanceerde Beveiliging",
    "Business Registration Available Soon\n": "Zakelijke registratie binnenkort beschikbaar.",
    "Amount After Fee": "Bedrag na Kosten",
    "Allowed Maximum Amount Per Transaction": "Toegestane Maximumbedrag Per Transactie",
    "Transfer": "Overmaken",
    "Uncategorized": "Niet-gecategoriseerd",
    "Currency ISO Code & Amount": "Valuta ISO-code en bedrag",
    "Choose The Top-Up Channel": "Kies het oplaadkanaal",
    "You Selected": "dat u hebt geselecteerd",
    "Payout Channel": "uitbetalingskanaal",
    "Your Details": "uw gegevens",
    "Detailed Confirmed": "details bevestigd",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Versterk uw financiële potentieel door over te schakelen naar een InstaPay Business-account. Ervaar de volledige kracht van InstaPay met verbeterde functies die speciaal zijn ontworpen voor zakelijke groei.",
    "Profile Picture": "Profielfoto",
    "Add Photo Or Logo": "Foto of logo toevoegen",
    "First Name": "Voornaam",
    "Last Name": "Achternaam",
    "Enter Your First Name": "Voer je voornaam in",
    "Enter Your Last Name": "Voer je achternaam in",
    "Email (Optional)": "E-mail (optioneel)",
    "Password Must": "Wachtwoord moet",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Het wordt automatisch ingevuld als je je aanmeldt via een uitnodigingslink.",
    "Change Number": "Nummer wijzigen",
    "Search Country": "Land zoeken",
    "Select From The List": "Kies uit de lijst",
    "Received Amount": "Ontvangen bedrag",

    "null": null,
    "Processing": "Verwerken",
    "Verify Using Sms/Email": "Verifieer via SMS/E-mail",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Bestaat al",
    "You Can Have Only 8 Wallets": "Je kunt slechts 8 wallets hebben",
    "Save": "Opslaan",
    "0 File": "0 bestand",
    "Search From The List": "Zoek in de lijst",
    "Amount To Send": "Bedrag om te verzenden",
    "Wallet To Wallet": "Wallet naar Wallet",
    "TOP-UP WALLET ": "Wallet opwaarderen",
    "Enter The Amount": "Voer het bedrag in",
    "Load/Deposit Money To The Wallet": "Geld laden/storten op de wallet",
    "Choose The Top-Up Channel": "Kies het opwaardeerkanaal",
    "Paypal": "Paypal",
    "You Will Get": "Je ontvangt",
    "Amount To Pay": "Te betalen bedrag",
    "Card Holder Name ": "Naam kaarthouder",
    "Card Number": "Kaartnummer",
    "CVC": "CVC",
    "MM/YY": "MM/JJ",
    "Your Details ": "Jouw gegevens",
    "You Selected": "Geselecteerd",
    "Detailed Confirmed": "Gedetailleerd bevestigd",
    "Transaction Failed": "Transactie mislukt",
    "Something Went Wrong. Try Again.": "Er is iets misgegaan. Probeer opnieuw.",
    "Convert Funds": "Converteer fondsen",
    "How Would You Like To Cashout?:": "Hoe wil je uitbetalen?:",
    "Default Receiving Account": "Standaard ontvangstrekening",
    "Specify Receiving Account": "Specificeer ontvangstrekening",
    "Which Receiving Account Would You Want Use?:": "Welke ontvangstrekening wil je gebruiken?:",
    "Pakistan": "Pakistan",
    "Bank Mobile": "Mobiele bank",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Geen",
    "Request": "Verzoek",
    "Subscription Payment": "Abonnementsbetaling",
    "Is not supported by Paypal, USD will be used as the default currency.": "Wordt niet ondersteund door PayPal, USD wordt gebruikt als standaardvaluta.",
    "Review Payment": "Controleer betaling",
    "Google Authenticator": "google authenticator",
    "Scheduled Has Been Cancelled": "Planning is geannuleerd",
    "Select Country First": "Selecteer eerst een land",
    "Select Gender": "Selecteer geslacht",
    "Select Occupation": "Selecteer beroep",
    "IBAN and SWIFT/BIC": "IBAN en SWIFT/BIC",
    "Mobile Money Provider": "Mobiele geldprovider",
    "Wallet Name": "Portemonneenaam",
    "Wallet Number ": "Portemonneennummer",
    "Crypto Currency": "Cryptovaluta",
    "Wallet Address ": "Portemonnee-adres",
    "Select Document Type ": "Selecteer documenttype",
    "Enter Document Number": "Voer documentnummer in",
    "Msisdn": "Msisdn",
    "Edit ": "Bewerken",
    "My Withdrawls": "Mijn opnames",
    "Declined": "Geweigerd",
    "Accepted": "Geaccepteerd",
    "Overall Ratings": "Algemene beoordelingen",
    "0 Ratings": "0 beoordelingen",
    "No Reviews": "Geen beoordelingen",
    "Send Money": "Geld verzenden",
    "Trouble Sending Money?": "Probleem met het verzenden van geld?",
    "Payment Request": "Betalingsverzoek",
    "How Was Your Experience?": "Hoe was je ervaring?",
    "Leave A Review": "Laat een recensie achter",
    "Write Review Message": "Schrijf een recensieboodschap",
    "Send And Post": "Verstuur en plaats",
    "You Rated": "Je hebt beoordeeld",
    "Review History": "Beoordelingsgeschiedenis",
    "Max Size 1GB": "Max grootte 1GB",
    "Title": "Titel",
    "Description": "Beschrijving",
    "Add New": "Voeg nieuw toe",
    "My Payment Address": "Mijn betalingsadres",
    "Personal Account": "Persoonlijk account",
    "Updated Successfully": "Succesvol bijgewerkt",
    "Spent In": "Uitgegeven aan",
    "TOP UP": "Opwaarderen",
    "Quotation": "Offerte",
    "Amount": "Bedrag",
    "Not Connected": "Niet verbonden",
    "More": "Meer",
    "Less": "Minder",
    "Reports": "Rapporten",
    "User Profile": "Gebruikersprofiel",
    "Cancel": "Annuleren",
    "Saving": "Opslaan",
    "Confirm ": "bevestig",
    "Verify ": "Verifiëren",
    "Delete Country": "Verwijder land",
    "Bank Account": "Bankrekening",
    "Cash Pickup": "Contant ophalen",
    "Mobile Wallet ": "Mobiele portemonnee",
    "Delete Country Withdrawals": "Verwijder landopnames",
    "Yes , Delete It": "Ja, verwijder het",
    "E-sim": "E-sim",
    "Utilities": "Nutsvoorzieningen",
    "Searching": "Zoeken",
    "Recipient Mobile Number": "Telefoonnummer ontvanger",
    "TOP-UP SUMMARY": "Oplaad samenvatting",
    "Trouble Requesting Money?": "Problemen met het aanvragen van geld?",
    "Choose The Beneficiary": "Kies de begunstigde",
    "Enter The amount": "Voer het bedrag in",
    "You selected": "Je hebt geselecteerd",
    "Timezone": "Tijdzone",
    "Starting From": "Startend vanaf",
    "Recurring Cycle": "Terugkerende cyclus",
    "Until I stop": "Totdat ik stop",
    "Schedule To": "Plannen naar",
    "Schedule at": "Plannen om",
    "Selected time must be at least 5 minutes from now.": "De geselecteerde tijd moet minstens 5 minuten van nu zijn.",
    "Is this your Location?": "Is dit je locatie?",
    "Yes ": "Ja",
    "Select Remark Reason": "Selecteer reden opmerking",
    "Attach File": "Bestand bijvoegen",
    "Invite via Phone Number": "Uitnodigen via telefoonnummer",
    "Invite Friend": "Nodig vriend uit",
    "Invite via Email Address": "Uitnodigen via e-mailadres",
    "Invite via": "Uitnodigen via",
    "Phone": "Telefoon:",
    "Message": "Bericht:",
    "Send": "Verzenden",
    "Invited Succesfully!": "Succesvol uitgenodigd!",
    "Email": "E-mail",
    "Bank Transfer": "Bankoverschrijving",
    "Mobile Wallet": "Mobiele portemonnee",
    "Select The Bank": "Selecteer de bank",
    "Select The Payer": "Selecteer de betaler",
    "Min Amount:": "Minimaal bedrag:",
    "Max Amount": "Maximumbedrag",
    "Other Beneficiaries:": "Andere begunstigden:",
    "Back ": "Terug",
    "Next": "Volgende",
    "Lookup": "Zoek op",
    "Invite Them": "Nodig ze uit",
    "Delete Channel ": "Verwijder kanaal",
    "Set As Default": "Stel in als standaard",
    "See More": "Meer bekijken",
    "Session Details": "Sessiedetails",
    "Platform": "Platform",
    "Windows": "Windows",
    "Browser Name": "Browsernaam",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Browser versie",
    "IP Address": "IP-adres",
    "Karachi": "Karachi",
    "State": "Staat",
    "Sindh": "Sindh",
    "Active": "Actief",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Deze valuta portemonnee is momenteel geblokkeerd/deactiveerd en er kunnen geen fondsen worden toegevoegd.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Deze valuta portemonnee is momenteel geblokkeerd/deactiveerd en er kunnen geen fondsen worden omgezet.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Deze valuta portemonnee is momenteel geblokkeerd/deactiveerd en je kunt het saldo niet opnemen.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Deze valuta portemonnee is momenteel geblokkeerd/deactiveerd en je kunt het overzicht niet downloaden.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Deze valuta portemonnee is momenteel ingesteld als je standaard en kan niet geblokkeerd worden. Om door te gaan, stel eerst een andere valuta portemonnee in als standaard.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Deze valuta portemonnee is momenteel ingesteld als je standaard en kan niet gedeactiveerd worden. Om door te gaan, stel eerst een andere valuta portemonnee in als standaard.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Weet je zeker dat je de opnamegegevens voor dit land wilt verwijderen?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Stel je ontvangende rekeningen in tot drie landen in en haal direct fondsen op uit goedgekeurde betalingsverzoeken naar je geactiveerde uitbetalingskanalen.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Geen begunstigde gevonden uit Pakistan met de gegevens van Allied Bank Limited",
    "Invitation Message Has Been Successfully Sent To": "De uitnodigingsboodschap is succesvol verzonden naar",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "De vergoeding en het ontvangen bedrag kunnen variëren afhankelijk van de gekozen betaalmethode.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "Deel je QR-code of betaallink om direct betaald te worden in je Insta-portemonnee.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Sleep/Drop of upload een bestand (Foto/Video/PDF)",
    "Login Again": "log opnieuw in",
    "Unblock The Wallet": "ontgrendel de portemonnee",
    "Invalid Amount": "ongeldige bedrag",
    "Add Payment Card": "voeg betaalkaart toe",
    "Save Details To Use Next Time": "sla details op voor later gebruik",
    "Paid": "betaald",
    "Started On": "begonnen op",
    "Num Of Cycles": "aantal cycli",
    "Ends On": "eindigt op",
    "Next Payment At": "volgende betaling om",
    "Beneficiary": "begunstigde",
    "Recieved Quotation": "ontvangen offerte",
    "Sent Quotation": "verzonden offerte",
    "Decline Offer": "weiger aanbod",
    "Are You Sure That You Want To Decline The Offer?": "bent u zeker dat u het aanbod wilt weigeren?",
    "Decline The Offer": "weiger het aanbod",
    "Bargain Offer": "onderhandel aanbod",
    "Enter Counter Amount": "voer tegenbod bedrag in",
    "Custom Amount": "aangepast bedrag",
    "Send The Offer": "stuur het aanbod",
    "The Offer Is Sent": "het aanbod is verzonden",
    "Countered Offer": "tegenbod",
    "Applied": "toegepast",
    "Accept Quotation": "accepteer offerte",
    "Quotation Not Found Or Already Exist": "Offerte niet gevonden of bestaat al",
    "Recieved Request": "ontvangen verzoek",
    "Sent Request": "verzonden verzoek",
    "Search Request": "zoek verzoek",
    "Search Quotation": "zoek offerte",
    "QR Status": "QR-status",
    "QR Code Description": "beschrijving van de QR-code",
    "Download Your QR Code": "Download je QR-code",
    "Your QR Code": "je QR-code",
    "Your QR Code Poster": "je QR-code poster",
    "Download  ": "download",
    "Select Relation": "selecteer relatie",
    "Nominee Permanent Address": "permanent adres van de genomineerde",
    "Enter Your Message": "voer je bericht in",
    "Select": "selecteer",
    "Select File Type": "selecteer bestandstype",
    "Send TOP-UP And E-Sim": "Stuur top-up en E-sim",
    "Search Users": "zoek gebruikers",
    "Select Payment Request Type": "selecteer type betalingsverzoek",
    "Wallet To Wallet Transfer": "wallet naar wallet transfer",
    "Holiday List": "vakantie lijst",
    "No Data To Show": "geen gegevens om weer te geven",
    "They Recieve": "zij ontvangen",
    "You Will Be Logged Out In 60 Seconds": "Je wordt over 60 seconden uitgelogd",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Beweeg je muis of druk op een toets op je toetsenbord om de timer terug te zetten naar 15 minuten",
    "You've Been Logged Out": "Je bent uitgelogd",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Om veiligheidsredenen ben je na 15 minuten inactiviteit uitgelogd. Log opnieuw in als je InstaPay wilt gebruiken.",
    "Cash": "Contant",
    "Business": "Zakelijk",
    "Gift": "Geschenk",
    "Salary": "Salaris",
    "Lottery": "Loterij",
    "Savings": "Spaargeld",
    "Other": "Anders",
    "Select Source Of Fund": "Selecteer bron van fondsen",
    "Select your source of funds": "Selecteer uw bron van fondsen",
    "Request Times Out. Please Try Again!": "Verzoektime-out. Probeer het opnieuw!",
    "Resend Code In 100 Seconds": "Code opnieuw verzenden in 100 seconden",
    "Computer Service": "Computerservice",
    "Family Support": "Familie ondersteuning",
    "Education": "Onderwijs",
    "Gift And Other Donations": "Geschenken en andere donaties",
    "Medical Treatment": "Medische behandeling",
    "Maintenance Or Other Expenses": "Onderhoud of andere kosten",
    "Travel": "Reizen",
    "Small Value Remittance": "Kleine waarde overboeking",
    "Liberalized Remittance": "Geliberaliseerde overboeking",
    "Construction Expenses": "Bouwkosten",
    "Hotel Accommodation": "Hotelaccommodatie",
    "Advertising And/Or Public Relations Related Expenses": "Advertentie- en/of PR-gerelateerde kosten",
    "Fees For Advisory Or Consulting Service": "Vergoedingen voor advies- of consultancydiensten",
    "Business Related Insurance Payment": "Zakelijke verzekeringsbetalingen",
    "Insurance Claims Payment": "Verzekeringsclaims betalingen",
    "Delivery Fees": "Leveringskosten",
    "Payments For Exported Goods": "Betalingen voor geëxporteerde goederen",
    "Payment For Services": "Betaling voor diensten",
    "Payment Of Loans": "Betaling van leningen",
    "Office Expenses": "Kantoorkosten",
    "Residential Property Purchase": "Aankoop van residentieel vastgoed",
    "Property Rental Payment": "Betaling van huur voor onroerend goed",
    "Royalty, Trademark, Patent And Copyright Fees": "Royalty-, merk-, octrooi- en auteursrechtenkosten",
    "Investment In Shares": "Investering in aandelen",
    "Fund Investment": "Beleggingsfondsen",
    "Tax Payment": "Belastingbetaling",
    "Transportation Fees": "Vervoerskosten",
    "Utility Bills": "Nutsvoorzieningen",
    "Personal Transfer": "Persoonlijke overboeking",
    "Payment Of Salary": "Salarisbetaling",
    "Payment Of Rewards": "Betaling van beloningen",
    "Payment Of Influencer": "Betaling van influencers",
    "Broker, Commitment, Guarantee And Other Fees": "Kosten voor bemiddeling, verplichtingen, garanties en andere vergoedingen",
    "Other Purposes": "Andere doeleinden",
    "Aunt": "Tante",
    "Brother": "Broer",
    "Brother-In-Law": "Schoonzoon",
    "Cousin": "Neef",
    "Daughter": "Dochter",
    "Father": "Vader",
    "Father-In-Law": "Schoonvader",
    "Friend": "Vriend",
    "Grandfather": "Opa",
    "Grandmother": "Oma",
    "Husband": "Echtgenoot",
    "Mother": "Moeder",
    "Mother-In-Law": "Schoonmoeder",
    "Nephew": "Neef",
    "Niece": "Nichte",
    "Self (i.e. The Sender, Himself)": "Zelf (d.w.z. de afzender zelf)",
    "Sister": "Zus",
    "Sister-In-Law": "Schoonzus",
    "Son": "Zoen",
    "Uncle": "Oom",
    "Wife": "Vrouw",
    "Others Not Listed": "Anderen niet vermeld",
    "Passport": "Paspoort",
    "National Identification Card": "Nationale identiteitskaart",
    "Driving License": "Rijbewijs",
    "Social Security Card/Number": "Socialezekerheidskaart/-nummer",
    "Tax Payer Identification Card/Number": "Belastingbetaler identificatiekaart/-nummer",
    "Senior Citizen Identification Card": "Seniorenidentificatiekaart",
    "Birth Certificate": "Geboorteakte",
    "Village Elder Identification Card": "Identiteitskaart van het dorpshoofd",
    "Permanent Residency Identification Card": "Identiteitskaart voor permanente verblijfsstatus",
    "Alien Registration Certificate/Card": "Buitenlandse registratiekaart/-certificaat",
    "PAN Card": "PAN Kaart",
    "Voter’s Identification Card": "Identiteitskaart van de kiezer",
    "Health Insurance Card/Number": "Gezondheidszorgkaart/-nummer",
    "Employer Identification Card": "Werkgeversidentificatiekaart",
    "Others Not Listed": "Overige niet vermeld",
    "Bank Account": "Bankrekening",
    "Cash Pickup": "Contant geld ophalen",
    "Card": "Kaart",
    "Arts & Entertainment": "Kunst & Vermaak",
    "Banking & Finance": "Bankieren & Financiën",
    "Education & Research": "Onderwijs & Onderzoek",
    "Engineering & Construction": "Engineering & Bouw",
    "Healthcare & Medicine": "Gezondheidszorg & Geneeskunde",
    "Information Technology & Services": "Informatie Technologie & Diensten",
    "Legal & Compliance": "Juridisch & Compliance",
    "Manufacturing & Production": "Productie & Fabricage",
    "Marketing & Sales": "Marketing & Verkoop",
    "Non-profit & Charity": "Non-profit & Goeddoelen",
    "Real Estate & Property": "Onroerend goed & Eigendom",
    "Retail & Wholesale": "Detailhandel & Groothandel",
    "Science & Pharmaceuticals": "Wetenschap & Farmaceutica",
    "Transport & Logistics": "Vervoer & Logistiek",
    "Travel & Tourism": "Reizen & Toerisme",
    "Unemployed": "Werkloos",
    "Other": "Anders",
    "Cash": "Contant",
    "Business": "Zakelijk",
    "Gift": "Geschenk",
    "Salary": "Salaris",
    "Lottery": "Loterij",
    "Savings": "Sparen",
    "Good Morning": "Goedemorgen",
    "Good Evening": "Goedenavond",
    "Completed": "Voltooid",
    "Load/Deposit Money To The Wallet": "Verder naar betaling",
    "Enter The Amount": "Voer het bedrag in",
    "Choose The Top-Up Channel": "Kies het oplaadkanaal",
    "Good Night!": "Goedenacht!",
    "You Will Get": "Bevestig uw betaling",
    "Amount To Pay": "U ontvangt",
    "Top-Up Wallet": "Betaalmethode",
    "Card Holder Name ": "Naam kaarthouder",
    "Card Number": "Kaartnummer",
    "CVC": "CVC",
    "MM/YY": "MM/JJ",
    "Save Details To Use Next Time": "sla details op voor later gebruik",
    "Select Or Add Payment Card": "Selecteer of voeg een betaalkaart toe",
    "Your Details ": "Jouw gegevens",
    "You Selected": "Geselecteerd",
    "Detailed Confirmed": "Gedetailleerd bevestigd",
    "Transaction Failed": "Transactie mislukt",
    "Something Went Wrong. Try Again.": "Er is iets misgegaan. Probeer opnieuw.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Mislukt",
    "Initiated": "Gestart",
    "Revise": "Herzien",
    "Declined": "Geweigerd",
    "Bargain-Accepted": "Onderhandelen geaccepteerd",
    "Accepted": "Geaccepteerd",
    "Decline The Offer": "Weiger het aanbod",
    "Pending": "In behandeling",
    "Cancelled": "Geannuleerd",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Stuur een gedetailleerde prijsopgave naar uw klant of koper. Presenteer uw service, producten of aanbiedingen professioneel met een gepersonaliseerde offerte.",
    "Wallet to wallet": "Portemonnee naar portemonnee",
    "Please follow these examples:": "Volg deze voorbeelden:",
    "👤 InstaPay/Instagram Username: instauser": "👤 InstaPay/Instagram Gebruikersnaam: instauser",
    "📧 Email: user@email.com": "📧 E-mail: user@email.com",
    "Full Name": "Volledige Naam",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "Welkom bij InstaPay! Om ervoor te zorgen dat je direct geld kunt opnemen van de ontvangen middelen, moet je je ontvangende accounts instellen in de instellingen. Hiermee kun je naadloos geld overmaken naar je favoriete bankrekeningen, mobiele portemonnees, cryptowallets of contantophaalpunten.",
    "You Have No Beneficiary": "Je hebt geen begunstigde",
    "Add Now": "Voeg nu toe",
    "Wallet-Status": "Portemonnee-status",
    "Select Payment Card": "Selecteer betaalkaart",
    "Your funds have been credited to your wallet.": "Je geld is bijgeschreven op je portemonnee.",
    "Thanks for using InstaPay": "Bedankt voor het gebruik van InstaPay",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Ontvang direct betaald: Stel je ontvangende accounts nu in!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Volg deze eenvoudige stappen om uw ontvangende accounts in te stellen en ervoor te zorgen dat u altijd toegang hebt tot uw geld:",
    "STEP 01": "STAP 01",
    "Go to Settings": "Ga naar Instellingen",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "Open uw InstaPay-app en navigeer naar de sectie \"Instellingen\".",
    "STEP 02": "STAP 02",
    "Select Receiving Accounts": "Selecteer Ontvangende Accounts",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Tik op \"Ontvangende Accounts\" om uw gegevens toe te voegen.",
    "Start Adding Accounts": "Begin met het Toevoegen van Accounts",
    "Phone Number: 44795396600 (With Country Code)": "Telefoonnummer: 44795396600 (Met Landcode)",
    "Select Country First": "Selecteer eerst een land",
    "First Name": "Voornaam",
    "Last Name": "Achternaam",
    "Address Line": "Adresregel",
    "Email Address": "E-mailadres",
    "Select City": "Selecteer stad",
    "Select Gender": "Selecteer geslacht",
    "Select Occupation": "Selecteer beroep",
    "Male": "Man",
    "Female": "Vrouw",
    "Non-Binary": "Niet-binair",
    "Prefer Not To Say'": "Liever niet zeggen",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Geen gebruiker gevonden",
    "Email Address ": "E-mailadres",
    "Select Document": "Selecteer document",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Laat je werk zien, vergroot je bereik. 'Mijn Portfolio' is je digitale canvas op Instapay. Upload en toon een breed scala aan inhoud - van boeiende afbeeldingen en video's tot informatieve PDF's. Of je nu een kunstenaar, freelancer of ondernemer bent, deze functie stelt je in staat om je werk of projecten visueel aantrekkelijk te presenteren.",
    "Add New": "Nieuw toevoegen",
    "Money Out": "Geld uit",
    "Browsername": "Browsernaam",
    "Browserversion": "Browserversie",
    "Credit": "Krediet",
    "Debit": "Debet",
    "Top Up": "Opladen",
    "Wallet To Wallet": "Portemonnee naar portemonnee",
    "Accountant": "Boekhouder",
    "Architect": "Architect",
    "Artist": "Kunstenaar",
    "Banker": "Bankier",
    "Business Owner": "Bedrijfseigenaar",
    "Consultant": "Consultant",
    "Doctor": "Dokter",
    "Engineer": "Ingenieur",
    "Farmer": "Boer",
    "Government Employee": "Overheidsmedewerker",
    "IT Professional": "IT-professional",
    "Lawyer": "Advocaat",
    "Nurse": "Verpleegkundige",
    "Retailer": "Detailhandelaar",
    "Salesperson": "Vertegenwoordiger",
    "Student": "Student",
    "Teacher": "Leraar",
    "Prefer Not to Say": "Lieber niet zeggen",
    "Timezone": "Tijdzone",
    "Subscription Payment": "Abonnementsbetaling",
    "Starting From": "Startend vanaf",
    "Recurring Cycle": "Terugkerende cyclus",
    "Until I stop": "Totdat ik stop",
    "Schedule To": "Plannen naar",
    "Schedule at": "Plannen om",
    "Selected time must be at least 5 minutes from now.": "De geselecteerde tijd moet minstens 5 minuten van nu zijn.",
    "They Recieve": "zij ontvangen",
    "Attach Files": "Voeg Bestanden Toe",
    "Wallet Selected": "Wallet geselecteerd",
    "You Have Selected PKR Currency Wallet For This Transaction": "U hebt de PKR-valuta wallet geselecteerd voor deze transactie",
    "Instant": "Direct",
    "Subscription": "Abonnement",
    "Schedule": "Schema",
    "Sms/Email Verification Code": "Sms/Email Verificatiecode",
    "Confirm Your Password": "Bevestig uw wachtwoord",
    "This Security Feature Requires Password Confirmation!": "Deze beveiligingsfunctie vereist wachtwoordbevestiging!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Gebruik de applicatie om de QR-code te scannen of gebruik je geheime code om Google Twee-factor Authenticatie te activeren",
    "OR": "OF",
    "Paste This Key In The Googe Authenticator App": "Plak deze sleutel in de Google Authenticator-app",
    "Show Secret Key": "Toon geheime sleutel",
    "Enter The Six-Digit Code From The Application": "Voer de zescijferige code uit de applicatie in",
    "6 Digit Code": "6-cijferige code",
    "Enable": "Inschakelen",
    "Disable": "Uitschakelen",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Na het scannen van de streepjescode-afbeelding toont de app een zescijferige code die je hieronder kunt invoeren",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "Google Twee-factor Authenticatie is nu gekoppeld aan je Instapay-account",
    "Min Amount:": "Minimaal bedrag:",
    "Minimum Amount": "Minimale Bedrag",
    "Maximum Amount": "Maximale Bedrag",
    "Identity Verification Required": "Identiteitsverificatie vereist",
    "Select Your Wallet Currency": "Selecteer uw portemonnee-valuta",
    "Setup Authenticator": "Stel de authenticator in",
    "Note": "Opmerking",
    "This Is A Test Notice": "Dit is een testbericht",
    "Session Expired": "Sessie verlopen",
    "You have been logged out for security reasons, please login again to continue.": "U bent om beveiligingsredenen uitgelogd, log opnieuw in om door te gaan.",
    "Redirecting You To Login Page In 5 Seconds": "U wordt binnen 5 seconden doorgestuurd naar de inlogpagina",
    "Searching": "Zoeken",
    "Recipient Mobile Number": "Telefoonnummer ontvanger",
    "TOP-UP SUMMARY": "Oplaad samenvatting",
    "Amount": "Bedrag",
    "Something went wrong while getting countries.": "Er is iets misgegaan bij het ophalen van landen.",
    "Select Destination Country": "Selecteer bestemmingsland.",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR wordt niet ondersteund door Paypal, USD wordt gebruikt als de standaardvaluta.",
    "Select A Date": "Selecteer een datum",
    "Select A Timezone": "Selecteer een tijdzone",
    "Proceed": "Doorgaan",
    "Proceeding": "Bezig",
    "You Can Now Close This Webpage And Go Back To Instagram": "U kunt deze webpagina nu sluiten en terugkeren naar Instagram",
    "Select A Time": "Selecteer een tijd",
    "Location Permission Denied": "Locatietoestemming geweigerd",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Volg de onderstaande instructies om locatievoorzieningen in uw browser in te schakelen nadat u eerder toestemming heeft geweigerd:",
    "1: Chrome Browser": "1: Chrome-browser",
    "Open the Chrome app.": "Open de Chrome-app.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Tik op het menu met drie stippen (rechtsboven) en selecteer Instellingen.",
    "Scroll down to Site Settings > Location.": "Scroll naar beneden naar Site-instellingen > Locatie.",
    "Locate the website in the list or search for it.": "Zoek de website in de lijst of zoek ernaar.",
    "Tap the website and select Allow under Location Access.": "Tik op de website en selecteer Toestaan bij Locatietoegang.",
    "2: Firefox Browser": "2: Firefox-browser",
    "Open the Firefox app.": "Open de Firefox-app.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Tik op het menu met drie lijnen (rechtsonder) en ga naar Instellingen.",
    "Select Privacy & Security > Site Permissions > Location.": "Selecteer Privacy en Beveiliging > Machtigingen voor sites > Locatie.",
    "Find the website in the list and set the permission to Allow.": "Zoek de website in de lijst en stel de toestemming in op Toestaan.",
    "3: Samsung Internet Browser": "3: Samsung Internet-browser",
    "Open the Samsung Internet app.": "Open de Samsung Internet-app.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Tik op het menu met drie lijnen (rechtsonder) en selecteer Instellingen.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Ga naar Sites en downloads > Machtigingen voor sites > Locatie.",
    "Find the website and change the permission to Allow.": "Zoek de website en wijzig de toestemming naar Toestaan.",
    "4: Safari Browser": "4: Safari-browser",
    "Open the Settings app.": "Open de app Instellingen.",
    "Scroll down and select Safari.": "Scroll naar beneden en selecteer Safari.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Tik op Locatie en stel in op Volgende keer vragen of Toestaan tijdens gebruik van de app.",
    "Revisit the website and when prompt, allow location access": "Bezoek de website opnieuw en sta locatie-toegang toe wanneer hierom wordt gevraagd.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "Tip: Als de website niet in de browserinstellingen verschijnt, wis de cache of geschiedenis van je browser en probeer het opnieuw.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Om een betalingsverzoek te verzenden, deel je locatie—dit helpt ons de beveiliging te verbeteren en vertrouwen op te bouwen voor een soepelere ervaring!",
    "Decline": "Afwijzen",
    "Share Location": "Deel locatie",
    "Already Exist": "Bestaat Al",
  "Withdraw": "Opnemen",
  "Seller Left A reply": "Verkoper heeft een reactie achtergelaten",
  "Check Now": "Controleer nu",
  "You Left A Review": "Je hebt een beoordeling achtergelaten",
  "View Larger Map": "Grotere kaart bekijken",
  "English (UK)": "Engels (Verenigd Koninkrijk)",
  "QR Code Title": "QR-codetitel",
    "Notice!": "Let op!",
  "By proceeding with this transaction, I hereby confirm that I am acting independently and of my own free will. I affirm that I have not been influenced, coached, coerced, or persuaded by any third party to carry out this transaction for investment purposes or any other reason.": "Door deze transactie voort te zetten, bevestig ik hierbij dat ik onafhankelijk en uit eigen vrije wil handel. Ik bevestig dat ik niet ben beïnvloed, begeleid, gedwongen of overgehaald door een derde partij om deze transactie uit te voeren voor investeringsdoeleinden of om welke andere reden dan ook.",
  "I understand that this confirmation is required to ensure compliance and to safeguard my account and transactions.": "Ik begrijp dat deze bevestiging vereist is om naleving te garanderen en om mijn account en transacties te beschermen.",
  "Confirm and Proceed": "Bevestigen en doorgaan",
  "Blocked Wallet": "Geblokkeerde Portemonnee",
  "Nominee's Full Name": "Volledige naam van genomineerde",
  "Insufficient Balance": "Onvoldoende saldo",
  "Upload Photo": "Foto uploaden",
  "Security Alert": "Beveiligingswaarschuwing",
  "Please remember to add your security questions for smooth experience. You will not be able to access the features of InstaPay unless You add the security questions.": "Vergeet niet uw beveiligingsvragen toe te voegen voor een soepele ervaring. U krijgt geen toegang tot de functies van InstaPay tenzij u de beveiligingsvragen toevoegt.",
  "Setup Security Questions": "Beveiligingsvragen instellen",
  "Geo Fencing": "Geofence",
  "Manage Payment & Quotation Access": "Beheer toegang tot betalingen en offertes",
  "Control countries from where you can receive Payment Requests and Quotations. Toggle on/off access for specific countries.": "Beheer vanuit welke landen je betalingsverzoeken en offertes kunt ontvangen. Schakel toegang voor specifieke landen in/uit.",
  "Search Countries ": "Zoek landen",
  "Filter": "Filter",
  "Payment Requests": "Betalingsverzoeken",
  "#No": "#Nr",
  "Country": "Land",
  "Account’s Country": "Land van account",
  "Current Location": "Huidige locatie",
  "Save Changes": "Wijzigingen opslaan",
  "Asia": "Azië",
  "Europe": "Europa",
  "Africa": "Afrika",
  "Oceania": "Oceanië",
  "Americas": "Amerika’s",
  "Polar": "Polair",
  "Finger Biometric": "Vingerafdruk biometrie",
  "Confirm": "Bevestigen",
  "Resend available in 77 seconds": "Opnieuw verzenden beschikbaar over 77 seconden",
  "Invalid OTP!": "Ongeldige OTP!",
  "Finger Biometric Updated": "Vingerafdruk biometrie bijgewerkt",
  "Your finger biometric has been disabled successfully!": "Uw vingerafdruk biometrie is succesvol uitgeschakeld!",
  "Your finger biometric has been enabled successfully!": "Uw vingerafdruk biometrie is succesvol ingeschakeld!",
  "Afghanistan": "Afghanistan",
  "Armenia": "Armenië",
  "Azerbaijan": "Azerbeidzjan",
  "Bahrain": "Bahrein",
  "Bangladesh": "Bangladesh",
  "Bhutan": "Bhutan",
  "Brunei": "Brunei",
  "Cambodia": "Cambodja",
  "China": "China",
  "East Timor": "Oost-Timor",
  "Georgia": "Georgië",
  "Hong Kong S.A.R.": "Hongkong S.A.R.",
  "India": "India",
  "Indonesia": "Indonesië",
  "Iran": "Iran",
  "Iraq": "Irak",
  "Israel": "Israël",
  "Japan": "Japan",
  "Jordan": "Jordanië",
  "Kazakhstan": "Kazachstan",
  "Kuwait": "Koeweit",
  "Kyrgyzstan": "Kirgizië",
  "Laos": "Laos",
  "Lebanon": "Libanon",
  "Macau S.A.R.": "Macau S.A.R.",
  "Malaysia": "Maleisië",
  "Maldives": "Malediven",
  "Mongolia": "Mongolië",
  "Myanmar": "Myanmar",
  "Nepal": "Nepal",
  "North Korea": "Noord-Korea",
  "Oman": "Oman",
  "Palestinian Territory Occupied": "Bezette Palestijnse Gebieden",
  "Philippines": "Filipijnen",
  "Qatar": "Qatar",
  "Saudi Arabia": "Saoedi-Arabië",
  "Singapore": "Singapore",
  "South Korea": "Zuid-Korea",
  "Sri Lanka": "Sri Lanka",
  "Syria": "Syrië",
  "Taiwan": "Taiwan",
  "Tajikistan": "Tadzjikistan",
  "Thailand": "Thailand",
  "Turkey": "Turkije",
  "Turkmenistan": "Turkmenistan",
  "United Arab Emirates": "Verenigde Arabische Emiraten",
  "Uzbekistan": "Oezbekistan",
  "Vietnam": "Vietnam",
  "Yemen": "Jemen",
  "Åland Islands": "Ålandeilanden",
  "Albania": "Albanië",
  "Andorra": "Andorra",
  "Austria": "Oostenrijk",
  "Belarus": "Wit-Rusland",
  "Belgium": "België",
  "Bosnia and Herzegovina": "Bosnië en Herzegovina",
  "Bulgaria": "Bulgarije",
  "Croatia": "Kroatië",
  "Cyprus": "Cyprus",
  "Czech Republic": "Tsjechië",
  "Denmark": "Denemarken",
  "Estonia": "Estland",
  "Faroe Islands": "Faeröer Eilanden",
  "Finland": "Finland",
  "France": "Frankrijk",
  "Germany": "Duitsland",
  "Gibraltar": "Gibraltar",
  "Greece": "Griekenland",
  "Guernsey and Alderney": "Guernsey en Alderney",
  "Hungary": "Hongarije",
  "Iceland": "IJsland",
  "Ireland": "Ierland",
  "Italy": "Italië",
  "Jersey": "Jersey",
  "Kosovo": "Kosovo",
  "Latvia": "Letland",
  "Liechtenstein": "Liechtenstein",
  "Lithuania": "Litouwen",
  "Luxembourg": "Luxemburg",
  "Malta": "Malta",
  "Isle of Man": "Isle of Man",
  "Moldova": "Moldavië",
  "Monaco": "Monaco",
  "Montenegro": "Montenegro",
  "Netherlands": "Nederland",
  "North Macedonia": "Noord-Macedonië",
  "Norway": "Noorwegen",
  "Poland": "Polen",
  "Portugal": "Portugal",
  "Romania": "Roemenië",
  "Russia": "Rusland",
  "San Marino": "San Marino",
  "Serbia": "Servië",
  "Slovakia": "Slowakije",
  "Slovenia": "Slovenië",
  "Spain": "Spanje",
  "Svalbard and Jan Mayen Islands": "Svalbard en Jan Mayen Eilanden",
  "Sweden": "Zweden",
  "Switzerland": "Zwitserland",
  "Ukraine": "Oekraïne",
  "United Kingdom": "Verenigd Koninkrijk",
  "Vatican City": "Vaticaanstad",
  "Algeria": "Algerije",
  "Angola": "Angola",
  "Benin": "Benin",
  "Botswana": "Botswana",
  "British Indian Ocean Territory": "Brits Indische Oceaanterritorium",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cameroon": "Kameroen",
  "Cape Verde": "Kaapverdië",
  "Central African Republic": "Centraal-Afrikaanse Republiek",
  "Chad": "Tsjaad",
  "Comoros": "Comoren",
  "Congo": "Congo",
  "Côte d'Ivoire (Ivory Coast)": "Ivoorkust",
  "Democratic Republic of the Congo": "Democratische Republiek Congo",
  "Djibouti": "Djibouti",
  "Egypt": "Egypte",
  "Equatorial Guinea": "Equatoriaal-Guinea",
  "Eritrea": "Eritrea",
  "Ethiopia": "Ethiopië",
  "French Southern Territories": "Franse Zuidelijke Gebieden",
  "Gabon": "Gabon",
  "Gambia": "Gambia",
  "Ghana": "Ghana",
  "Guinea": "Guinee",
  "Guinea-Bissau": "Guinee-Bissau",
  "Kenya": "Kenia",
  "Lesotho": "Lesotho",
  "Liberia": "Liberia",
  "Libya": "Libië",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Mali": "Mali",
  "Mauritania": "Mauritanië",
  "Mauritius": "Mauritius",
  "Mayotte": "Mayotte",
  "Morocco": "Marokko",
  "Mozambique": "Mozambique",
  "Namibia": "Namibië",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Réunion": "Réunion",
  "Rwanda": "Rwanda",
  "Saint Helena": "Sint-Helena",
  "São Tomé and Príncipe": "Sao Tomé en Principe",
  "Senegal": "Senegal",
  "Seychelles": "Seychellen",
  "Sierra Leone": "Sierra Leone",
  "Somalia": "Somalië",
  "South Africa": "Zuid-Afrika",
  "South Sudan": "Zuid-Soedan",
  "Sudan": "Soedan",
  "Swaziland (Eswatini)": "Eswatini",
  "Tanzania": "Tanzania",
  "Togo": "Togo",
  "Tunisia": "Tunesië",
  "Uganda": "Oeganda",
  "Western Sahara": "Westelijke Sahara",
  "Zambia": "Zambia",
  "Zimbabwe": "Zimbabwe",
  "American Samoa": "Amerikaans-Samoa",
  "Australia": "Australië",
  "Christmas Island": "Christmaseiland",
  "Cocos (Keeling) Islands": "Cocoseilanden (Keeling)",
  "Cook Islands": "Cookeilanden",
  "Fiji": "Fiji",
  "French Polynesia": "Frans-Polynesië",
  "Guam": "Guam",
  "Kiribati": "Kiribati",
  "Marshall Islands": "Marshalleilanden",
  "Micronesia": "Micronesië",
  "Nauru": "Nauru",
  "New Caledonia": "Nieuw-Caledonië",
  "New Zealand": "Nieuw-Zeeland",
  "Niue": "Niue",
  "Norfolk Island": "Norfolk-eiland",
  "Northern Mariana Islands": "Noordelijke Marianen",
  "Palau": "Palau",
  "Papua New Guinea": "Papoea-Nieuw-Guinea",
  "Pitcairn Islands": "Pitcairneilanden",
  "Samoa": "Samoa",
  "Solomon Islands": "Salomonseilanden",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Tuvalu": "Tuvalu",
  "Vanuatu": "Vanuatu",
  "Wallis and Futuna": "Wallis en Futuna",
  "North & South America": "Noord- en Zuid-Amerika",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua en Barbuda",
  "Argentina": "Argentinië",
  "Aruba": "Aruba",
  "Barbados": "Barbados",
  "Belize": "Belize",
  "Bermuda": "Bermuda",
  "Bolivia": "Bolivia",
  "Bonaire, Sint Eustatius, and Saba": "Bonaire, Sint Eustatius en Saba",
  "Brazil": "Brazilië",
  "Canada": "Canada",
  "Cayman Islands": "Kaaimaneilanden",
  "Chile": "Chili",
  "Colombia": "Colombia",
  "Costa Rica": "Costa Rica",
  "Cuba": "Cuba",
  "Curaçao": "Curaçao",
  "Dominica": "Dominica",
  "Dominican Republic": "Dominicaanse Republiek",
  "Ecuador": "Ecuador",
  "El Salvador": "El Salvador",
  "Falkland Islands": "Falklandeilanden",
  "French Guiana": "Frans-Guyana",
  "Greenland": "Groenland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guatemala": "Guatemala",
  "Guyana": "Guyana",
  "Haiti": "Haïti",
  "Honduras": "Honduras",
  "Jamaica": "Jamaica",
  "Martinique": "Martinique",
  "Mexico": "Mexico",
  "Montserrat": "Montserrat",
  "Nicaragua": "Nicaragua",
  "Panama": "Panama",
  "Paraguay": "Paraguay",
  "Peru": "Peru",
  "Puerto Rico": "Puerto Rico",
  "Saint Kitts and Nevis": "Saint Kitts en Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint-Pierre en Miquelon",
  "Saint Vincent and the Grenadines": "Saint Vincent en de Grenadines",
  "Saint Barthélemy": "Saint-Barthélemy",
  "Saint Martin (French part)": "Saint-Martin (Frans gedeelte)",
  "Sint Maarten (Dutch part)": "Sint Maarten (Nederlands gedeelte)",
  "South Georgia and the South Sandwich Islands": "Zuid-Georgia en de Zuidelijke Sandwicheilanden",
  "Suriname": "Suriname",
  "The Bahamas": "De Bahama's",
  "Trinidad and Tobago": "Trinidad en Tobago",
  "Turks and Caicos Islands": "Turks- en Caicoseilanden",
  "United States": "Verenigde Staten",
  "United States Minor Outlying Islands": "Kleine afgelegen eilanden van de Verenigde Staten",
  "Uruguay": "Uruguay",
  "Venezuela": "Venezuela",
  "British Virgin Islands": "Britse Maagdeneilanden",
  "U.S. Virgin Islands": "Amerikaanse Maagdeneilanden",
  "Antarctica": "Antarctica",
  "Your Preferences Updated Successfully": "Uw voorkeuren zijn succesvol bijgewerkt"
}