export const tr = {
    "How Others Can Find You": "Diğerleri Sizi Nasıl Bulabilir",
    "Schedules": "Programlar",
    "Login": "Giriş",
    "Start Your InstaPay Journey Today!": "Bugün InstaPay Yolculuğunuza Başlayın!",
    "Phone Number or Email": "Telefon Numarası veya E-posta",
    "Login Using Social Media": "Sosyal Medya Kullanarak Giriş Yap",
    "Continue": "Devam Et",
    "Don't Have an Account? Sign Up": "Hesabınız Yok mu? Kaydol",
    "Sign Up": "Kaydol",
    "Edit": "Düzenle",
    "Enter Your Password": "Şifrenizi Girin",
    "Forgot Password?": "Şifremi Unuttum?",
    "Account Type": "Hesap Türü",
    "Individual": "Bireysel",
    "Business": "İşletme",
    "Already Have an Account? Sign In": "Zaten Hesabınız Var mı? Giriş Yap",
    "Sing In": "Giriş Yap",
    "Enter Your Personal Details": "Kişisel Bilgilerinizi Girin",
    "Add Photo or Logo": "Fotoğraf veya Logo Ekle",
    "Enter Your Name": "Adınızı Girin",
    "Back": "Geri",
    "Phone Number": "Telefon Numarası",
    "Email Address": "E-posta Adresi",
    "Create a Strong Password": "Güçlü Bir Şifre Oluşturun",
    "Password Requirements": "Şifre Gereksinimleri",
    "Must be between 9 and 64 characters": "9 ile 64 karakter arasında olmalıdır",
    "Include at least two of the following:": "Aşağıdakilerden en az ikisini içermelidir:",
    "Uppercase character": "Büyük harf",
    "Lowercase character": "Küçük harf",
    "Number": "Numara",
    "Special character": "Özel karakter",
    "Confirm Password": "Şifreyi Onaylayın",
    "Finalize Your Process": "Sürecinizi Tamamlayın",
    "Referral Code (Optional)": "Referans Kodu (İsteğe Bağlı)",
    "It will autofill if you're signing up with an invitation link.": "Davet linkiyle kayıt oluyorsanız otomatik doldurulur.",
    "I agree with": "Şartlar ve Koşullar ile",
    "Terms & Conditions": "Gizlilik Politikası'nı kabul ediyorum",
    "Privacy Policy": "Hesabınızı Oluşturun",
    "Create Your Account": "Kodu Girin",
    "Enter Code": "E-posta adresinize ve telefon numaranıza bir seferlik doğrulama kodu gönderildi.",
    "A one-time verification code has been sent to your email address  and phone number.": "Saniye içinde yeniden gönderilebilir",
    "Resend available in ": "Doğrula",
    "seconds": "Doğrulama Kodu Doğrulandı",
    "Verify": "Lütfen bir sonraki adıma devam edin.",
    "Verification Code Verified": "Bitir",
    "Please continue to the next step.": "Bitirme",
    "Finish": "Şirket Adı",
    "Finishing": "İşletme Detaylarınızı Girin",
    "Company Name": "Ülke",
    "Enter Your Business Details": "İşletme Detaylarınızı Girin",
    "Country ": "Ülke",
    "You cannot change the country once your account is verified.": "Hesabınız doğrulandıktan sonra ülkeyi değiştiremezsiniz.",
    "Learn more": "Daha fazla öğrenin",
    "Optional": "İsteğe Bağlı",
    "Continuing": "Devam Ediyor",
    "Didn't receive the verification code?": "Doğrulama kodunu almadınız mı?",
    "Resend Now": "Şimdi Yeniden Gönder",
    "Search Here": "Burada Ara",
    "Search for users, services, and more on InstaPay": "InstaPay'de kullanıcıları, hizmetleri ve daha fazlasını ara",
    "Search users here": "Kullanıcıları burada ara",
    "Earn money with our affiliate program!": "Ortaklık programımızla para kazanın!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Takipçilerinizle InstaPay ortaklık kodunuzu paylaşarak pasif gelir elde edin. Yaptıkları her işlem için, komisyon kazanarak çevrimiçi etkinizden maksimum kar sağlayın.",
    "Learn More": "Daha Fazla Bilgi Edinin",
    "Balance Amount": "Bakiye Miktarı",
    "You spent less compared to last month.": "Geçen aya göre daha az harcadınız.",
    "Referral Rewards": "Referans Ödülleri",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "En Son İletişimlerinize Hızlı Transfer:",
    "Number of Transactions": "İşlem Sayısı",
    "Total Transaction Amount": "Toplam İşlem Tutarı",
    "Total Sent": "Toplam Gönderilen",
    "Total Received": "Toplam Alınan",
    "Add Funds": "Fon Ekle",
    "Send Money": "Para Gönder\n",
    "Request Money": "Para İste",
    "Send a Quote": "Teklif Gönder",
    "Pay With Crypto": "Kripto ile Öde",
    "Payment Insights": "Ödeme İçgörüleri",
    "Recent Transactions ": "Son İşlemler",
    "Today": "Bugün",
    "My Wallets": "Benim Cüzdanlarım",
    "Balance": "Bakiye",
    "Wallet ID": "Wallet ID",
    "Monthly limit": "Aylık Limit",
    "Top Up": "Yükle",
    "Summary": "Özet",
    "Settings": "Ayarlar",
    "More": "Daha Fazla",
    "Upgrade to Business Account": "İşletme Hesabına Yükselt",
    "Conversion": "Dönüşüm",
    "Enter Amount": "Miktar Gir",
    "Exchanged Amount": "Dönüştürülen Miktar",
    "Convert": "Dönüştür",
    "You Are Converting": "Dönüştürüyorsunuz",
    "From": "Den",
    "To": "E",
    "Exchange Rate": "Döviz Kuru",
    "Fees": "Not Ekle",
    "You Will Receive": "Alacaksınız",
    "Slide To Confirm": "Onaylamak için Kaydır",
    "Verification Code Confirmed - You'll be redirected to the next step": "Doğrulama Kodu Onaylandı - Bir sonraki adıma yönlendirileceksiniz",
    "Moving to next step in": "Bir sonraki adıma geçiliyor",
    "Thanks for using InstaPay": "InstaPay'i kullanmış olduğunuz için teşekkürler",
    "Your funds have been credited to your wallet.": "Fonlarınız cüzdanınıza yatırıldı.",
    "Dashboard": "Panel",
    "Accounts": "Hesaplar",
    "Transactions": "İşlemler",
    "Other Transactions": "Diğer İşlemler",
    "Payments": "Ödemeler",
    "Beneficiaries": "Faydalanıcılar",
    "Referrals": "Referanslar",
    "Quotations": "Teklifler",
    "Pending": "Bekleyen",
    "My QR Code Sticker": "QR Kod Sticker'ım",
    "My Portfolio": "Portföyüm",
    "My Payment Address": "Ödeme Adresim",
    "Analytics": "Analitik",
    "Profile": "Profil",
    "Dark Mode": "Karanlık Mod",
    "Support": "Destek",
    "Logout": "Çıkış",
    "Powered By": "Sağlayıcı",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "İsviçre Fintech Şirketi",
    "MAIN MENU": "ANA MENÜ",
    "OTHERS": "DİĞERLERİ",
    "Wallet": "Wallet",
    "Default": "Varsayılan",
    "Breakdown": "Ayrıntılı",
    "Credit": "Kredi",
    "Debit": "Borç",
    "Wallet Management": "Wallet Yönetimi",
    "Top-up Your Wallet": "Cüzdanınızı Doldurun",
    "Download Statement": "Hesap Özeti İndir",
    "Block the Wallet": "Cüzdanı Bloke Et",
    "Wallet Status": "Cüzdan Durumu",
    "-Select-File-Type-": "-Dosya Türü Seç-",
    "Download Now": "Şimdi İndir",
    "Downloading": "İndiriliyor",
    "All": "Tümü",
    "Sent": "Gönderilen",
    "Received": "Alınan",
    "Requested": "İstenen",
    "Quoted": "Teklif Edilen",
    "Search Transactions": "İşlemleri Ara",
    "Date & Time": "Tarih & Saat",
    "Type": "Tür",
    "Transaction ID": "İşlem ID",
    "Recipient": "Alıcı",
    "Amount": "Miktar",
    "Status": "Durum",
    "Payment Type": "Ödeme Türü",
    "Sent Payments": "Gönderilen Ödemeler",
    "Received Payments": "Alınan Ödemeler",
    "Date": "Tarih",
    "Cycles/Time": "Döngüler/Zaman",
    "Payment As": "Ödeme Olarak",
    "Name": "İsim",
    "Cancel": "İptal",
    "Subscriptions & Scheduled": "Abonelikler & Planlanan",
    "Select the option that matches your needs from the list below:": "Aşağıdaki listeden ihtiyaçlarınıza uygun seçeneği seçin:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Arkadaşlara, aileye veya yerel veya uluslararası işletmelere kolayca para gönderin. Banka yatırımları, mobil cüzdanlar, nakit teslim alma, ödeme kartları ve \ncüzdan-cüzdana transferler dahil olmak üzere birden fazla transfer seçeneği arasından seçim yapın. InstaPay'in sunduğu kolaylık ve güvenlikle kesintisiz\n ve anında para transferlerinin keyfini çıkarın.",
    "International Transfer": "Uluslararası Transfer",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Sınır ötesi para göndermeyi kolaylaştırın. Arkadaşlara, aileye veya işletmelere uluslararası olarak sadece birkaç tıklama ile fon transferi yapın. Banka hesabı, \nmobil para/mobil cüzdan, nakit teslim alma yeri veya ödeme kartı dahil olmak üzere birçok ödeme kanalı arasından seçim yapın rahat ve güvenli işlemler için.",
    "Select the country where you want to send the money!": "Para göndermek istediğiniz ülkeyi seçin!",
    "Search Country": "Ülke Ara",
    "Trouble Sending Money?": "Para Göndermede Sorun mu Yaşıyorsunuz?",
    "Confirm": "Onayla",
    "Select Payout Channel": "Ödeme Kanalı Seç",
    "Select your most convenient option to send the money.": "Para göndermek için en uygun seçeneği seçin.",
    "Select The Bank": "Bankayı Seç",
    "Search Bank": "Banka Ara",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Para gönderme işlemini alıcının banka hesabına doğrudan yapın. Dünyanın her yerindeki herhangi bir bankaya fonları sorunsuz bir şekilde aktarmanın kolaylığını ve güvenliğini yaşayın.",
    "Bank Transfer": "Banka Transferi",
    "Enter the amount": "Miktar girin",
    "Tax": "Vergi",
    "Amount the recipient will receive": "Alıcı tarafından alınacak miktar",
    "Select Remarks / Reason": "Açıklama / Sebep Seç",
    "Comments": "Yorumlar",
    "Attach Files": "Dosya Ekle",
    "Groups": "Gruplar",
    "Manage Groups": "Grup Yönetimi",
    "Add New Group": "Yeni Grup Ekle",
    "Invite": "Bir Kişi Davet Et",
    "Manage Beneficiaries": "Faydalanıcıları Yönet",
    "Recipients List": "Alıcı Listesi",
    "Search Contacts": "Kişileri Ara",
    "Confirm Your Payment": "Ödemenizi Onaylayın",
    "Select Country": "Ülke Seç",
    "Select the Recipient's Payment Channel": "Alıcının Ödeme Kanalını Seç",
    "Change Selection": "Seçimi Değiştir",
    "You Are Transferring ": "Transfer Ediyorsunuz",
    "Oops! Something Went Wrong.": "Aman! Bir şeyler ters gitti.",
    "We're Sorry About That. ": "Bunun için üzgünüz.",
    "Go back to Payments Page.": "Ödemeler Sayfasına geri dönün.",
    "Wallet to Wallet Transfer": "Cüzdan-Cüzdana Transfer",
    "Request a customized price quote from service providers or vendors.": "Hizmet sağlayıcılardan veya satıcılardan özelleştirilmiş fiyat teklifi isteyin.",
    "Enter the Wallet ID": "Wallet ID Girin",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Alıcının Wallet ID, E-posta veya Telefon Numarasını girin.",
    "Recipient not on InstaPay?": "Alıcı InstaPay'de Değil mi? Davet Et.",
    "Invite Them": "Davet Et",
    "My Beneficiaries": "Faydalanıcılarım",
    "Enter the Amount You Wish to Transfer.": "Transfer Etmek İstediğiniz Miktarı Girin.",
    "Sending to ": "Gönderiliyor",
    "You Have Selected Wallet to Wallet Transfer": "Cüzdan-Cüzdana Transferi Seçtiniz",
    "Authenticate Your Payment.": "Ödemenizi Doğrulayın.",
    "You are transferring": "Transfer Ediyorsunuz",
    "Total ": "Toplam",
    "Where Is My Payment?": "Ödemem Nerede?",
    "Share": "Paylaş",
    "Your Payment Is Confirmed": "Ödemeniz Onaylandı",
    "QR Code Payment": "QR Kod Ödemesi",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "InstaPay kullanıcılarına güvenli cüzdan-cüzdana hizmetimizle anında para transferi yapın. Saniyeler içinde sorunsuz bir şekilde fon gönderin ve alın.",
    "Scan QR with Your Camera or Upload from Your Device.": "Kameranızla QR Taraması yapın veya Cihazınızdan Yükleyin.",
    "Upload from Your Device.": "Cihazınızdan Yükleyin.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Alternatif olarak, InstaPay Benzersiz Ödeme Adresi (UPA) Kullanın",
    "Enter InstaPay UPA": "InstaPay UPA Girin",
    "Recipient not on InstaPay? Invite Them": "Alıcı InstaPay'de Değil mi? Davet Et",
    "Verify QR Code": "QR Kodunu Doğrula",
    "Scan/Upload QR Code of the Recipient": "Alıcının QR Kodunu Tara/Yükle",
    "Payment Confirmed": "Ödeme Onaylandı",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Hizmet sağlayıcılar veya satıcılardan ihtiyacınız olan hizmetler veya ürünler için doğru maliyet tahminleri almak üzere özelleştirilmiş fiyat teklifleri isteyin.",
    "Choose The Beneficiary or Group": "Faydalanıcı veya Grubu Seç",
    "Enter the Amount You Wish to Request.": "İstediğiniz Miktarı Girin.",
    "You Are Requesting Money": "Para İstiyorsunuz",
    "You are requesting": "İstiyorsunuz",
    "As": "Olarak",
    "Conversion of Your Cryptocurrencies": "Kripto Para Birimlerinizin Dönüşümü",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Kullanıcı merkezli, güvenli ve verimli Nakit Çıkışı Kripto-Fiat Dönüşümümüzü deneyimleyin.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Likide Etmek İstediğiniz Kripto Para Birimini Seçin.",
    "Next": "İleri",
    "Enter the Crypto Wallet Address of the Recipient.": "Alıcının Kripto Cüzdan Adresini Girin.",
    "Minimum Transactional Value: $100.": "Minimum İşlem Değeri: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Not: Kripto fiyatları sıkça değişir. Siparişi onaylama sayfasındaki son teklife bakın.",
    "Select Network": "Ağı Seç",
    "Send A Quote": "Teklif Gönder",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Müşterilerinize veya müşterilerinize ayrıntılı bir fiyat teklifi gönderin. Hizmetlerinizi, ürünlerinizi veya tekliflerinizi kişiselleştirilmiş bir teklifle profesyonel bir şekilde sunun.",
    "Choose the Beneficiary": "Faydalanıcıyı Seç",
    "Allow Receiver to Bargain": "Alıcıya Pazarlık İzni Ver",
    "Enter the Title for Your Quotation.": "Teklifiniz İçin Başlık Girin.",
    "Enter the Description for Your Quotation": "Teklifiniz İçin Açıklama Girin",
    "You are Quoting": "Teklif Ediyorsunuz",
    "Title": "Başlık",
    "Description": "Açıklama",
    "Attachments": "Ekler",
    "Confirm Your Quotation": "Teklifinizi Onaylayın",
    "Verify The Quotation": "Teklifi Doğrulayın",
    "Your quotation is confirmed": "Teklifiniz onaylandı",
    "Request A Quote": "Teklif İste",
    "Coming Soon": "Yakında",
    "Add Beneficiary": "Faydalanıcı Ekle",
    "Search for Beneficiaries": "Faydalanıcıları Ara",
    "Connected Accounts": "Bağlı Hesaplar",
    "Details": "Detaylar",
    "First Name": "Ad",
    "Last Name": "Soyad",
    "Country": "Ülke",
    "City": "Şehir",
    "Address Line": "Adres Satırı",
    "Mobile Number": "Mobil Numara",
    "Relationship With Beneficiary": "Faydalanıcıyla İlişkiniz",
    "Bank Account": "Banka Hesabı",
    "Mobile Money/Mobile Wallet": "Mobil Para/Mobil Wallet",
    "Payment Card": "Ödeme Kartı",
    "Crypto Wallet": "Kripto Cüzdan",
    "InstaPay Account": "InstaPay Hesabı",
    "Cash Pickup": "Nakit Teslim Alma",
    "IBAN / SWIFT Code": "IBAN veya Swift/BIC kodu",
    "Swift Code": "Swift Kodu",
    "Account Number": "Hesap Numarası",
    "Bank Name": "Banka Adı",
    "Branch Name": "Şube Adı",
    "Branch Street": "Şube Sokağı",
    "Province": "İl",
    "Postal Code": "Posta Kodu",
    "Mobile Money Provider": "Mobil Para Sağlayıcısı",
    "Wallet Name": "Wallet Adı",
    "Wallet Number": "Wallet Numarası",
    "Card Holder Name": "Kart Sahibinin Adı",
    "Card Number": "Kart Numarası",
    "Expiry Date": "Son Kullanma Tarihi",
    "Crypto Currency": "Kripto Para Birimi",
    "Wallet Address": "Wallet Adresi",
    "Wallet Holder Name": "Wallet Sahibinin Adı",
    "Wallet Currency": "Wallet Para Birimi",
    "Select Document Type": "Belge Türünü Seçin",
    "Enter Document Number": "Belge Numarasını Girin",
    "Add Individual Account": "Bireysel Hesap Ekle",
    "Add Business Account": "İşletme Hesabı Ekle",
    "Company Address": "Şirket Adresi",
    "Company Email": "Şirket E-posta",
    "Company Phone No": "Şirket Telefon No",
    "Total Referrals": "Toplam Tavsiyeler",
    "My Earnings": "Kazançlarım",
    "Your Referral ID": "Referans ID'niz",
    "Share Your Unique Referral Link": "Benzersiz Referans Bağlantınızı Paylaşın",
    "How do I Refer a Friend to InstaPay?": "InstaPay'e Bir Arkadaşı Nasıl Tavsiye Ederim?",
    "Step 01": "Adım 01",
    "Share your InstaPay unique referral link with your friends.": "InstaPay benzersiz referans bağlantınızı arkadaşlarınızla paylaşın.",
    "Step 02": "Adım 02",
    "Earn rewards when they transact $100 or more": "$100 veya daha fazla işlem yaptıklarında ödüller kazanın",
    "Step 03": "Adım 03",
    "Earn up to $0.06 for every transaction your followers make.": "Takipçilerinizin yaptığı her işlem için $0.06'ya kadar kazanın.",
    "My Referrals": "Tavsiyelerim",
    "Referral Complete": "Tavsiye Tamamlandı",
    "Received Quotations": "Alınan Teklifler",
    "Sent Quotations": "Gönderilen Teklifler",
    "Search Pending Items": "Bekleyen Öğeleri Ara",
    "Actions": "Eylemler",
    "Received Requests": "Alınan İstekler",
    "Sent Requests": "Gönderilen İstekler",
    "No Actions": "Eylem Yok",
    "You Requested": "İstediniz",
    "Via": "Aracılığıyla",
    "How was your experience?": "Deneyiminiz nasıldı?",
    "Leave a Review": "Bir Yorum Bırakın",
    "Overall Ratings": "Genel Puanlar",
    "Visit Profile": "Profili Ziyaret Et",
    "Ratings": "Puanlar",
    "Reviews": "Yorumlar",
    "No reviews available": "Mevcut yorum yok",
    "Select Your Currency": "Para Biriminizi Seçin",
    "Proceed": "İlerle",
    "Commision": "Komisyon",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "InstaPay QR Kod Sticker'ı ile Sorunsuz İşlemlerin Kilidini Açın",
    "Effortless and Swift:": "Rahat ve Hızlı:",
    "Instantly download your unique InstaPay QR code sticker.": "Benzersiz InstaPay QR kod sticker'ınızı anında indirin.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Ödeme terminalleri gibi karmaşık altyapılara ihtiyaç duymadan, sorunsuz, nakitsiz ödemeleri deneyimleyin.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Ödemeleri hızlıca toplayın - kredi kartı veya telefon numaralarının yorucu girişine artık gerek yok.",
    "Each payment includes customer information for your convenience.": "Her ödeme, kolaylığınız için müşteri bilgilerini içerir.",
    "Economically Smart:": "Ekonomik Olarak Akıllı:",
    "Zero activation fees – start immediately without upfront costs.": "Aktivasyon ücreti yok - ön maliyetler olmadan hemen başlayın.",
    "Forget about fixed monthly charges; pay as you transact.": "Sabit aylık ücretleri unutun; işlem yaptığınızda ödeyin.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Donanımdan vazgeçin; terminal maliyetleri yok demek sizin için daha fazla tasarruf anlamına gelir.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Sadece QR kod ödemesi başına %0.75 düşük işlem ücretlerinden yararlanın.",
    "We don't impose minimum commissions; you save more with each transaction.": "Minimum komisyonlar dayatmıyoruz; her işlemle daha fazla tasarruf edersiniz.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Anında ödemelerin sevincini yaşayın, nakit akışınızı artırın.",
    "Make Every Transaction Count with InstaPay": "Her İşlemi InstaPay ile Değerli Kılın",
    "Wallet QR": "Wallet QR",
    "Download QR": "QR İndir",
    "QR Code Status": "QR Kod Durumu",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "QR Kodunuzu veya ödeme adresi bağlantınızı paylaşarak InstaPay cüzdanınıza anında ödeme alın.",
    "QR Code Title": "QR Kod Başlığı",
    "View Transactions": "İşlemleri Görüntüle",
    "My QR Codes:": "QR Kodlarım:",
    "Load More": "Daha Fazla Yükle",
    "Benefits Of Portfolio": "Portföyün Faydaları",
    "Benefits:": "Faydalar:",
    "Enhanced Visibility:": "Geliştirilmiş Görünürlük:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Kamu profili yükseltin. Portföyünüz, Ödeme Adresinizden doğrudan erişilebilen dinamik bir vitrin görevi görür.",
    "Engage Your Audience:": "Kitleyi Etkileme:",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Ziyaretçilerle yaratıcı veya profesyonel yolculuğunuz üzerinden bağlantı kurun. İşinizin sizin için konuşmasına izin vererek, potansiyel müşteriler veya destekçilerle daha derin bir etkileşim yaratın.",
    "Integrated Social Proof:": "Entegre Sosyal Kanıt:",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Sosyal medya etkinizi sergileyin. Ziyaretçiler sosyal medya erişiminizi görerek portföyünüze güvenilirlik ve bağlam ekler.",
    "Seamless Transactions:": "Sorunsuz İşlemler:",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Ziyaretçilerin, kamu sayfanızdan doğrudan ödeme talep etmelerini veya başlatmalarını sağlayın. Portföyünüz sadece işinizi sergilemekle kalmaz, aynı zamanda kolay finansal etkileşimleri de kolaylaştırır.",
    "Personalized Storytelling:": "Kişiselleştirilmiş Anlatı:\n",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Benim Hakkımda' bölümünü kullanarak hikayenizi, misyonunuzu veya vizyonunuzu paylaşın, kitleyle kişisel bir bağlantı yaratın.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Benim Portföyüm' sadece bir galeriden fazlasıdır; InstaPay'de markanızı oluşturmak, kitleyi etkilemek ve finansal etkileşimlerinizi düzenlemek için bir araçtır.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "İşinizi Sergileyin, Etkinizi Artırın. 'Benim Portföyüm' InstaPay'deki dijital tuvalinizdir. Büyüleyici görsellerden ve videolardan bilgilendirici PDF'lere kadar çeşitli içerikleri yükleyin ve sergileyin. \nSanatçı, serbest çalışan veya girişimci olun, bu özellik işinizi veya projelerinizi görsel olarak çekici bir şekilde sunmanıza olanak tanır.",
    "Add/Manage Portfolio": "Portföy Ekle/Yönet",
    "Add New": "Yeni Ekle",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "InstaPay Ödeme Adresiniz ile Sosyal Medya Kazançlarınızı Maksimize Edin",
    "Make It Effortless for Your Audience to Support You:": "Kitleye Sizi Desteklemeyi Kolaylaştırın:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "InstaPay Ödeme Adresinizi sosyal medya gönderilerinize kolayca entegre edin.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Ödeme adresi bağlantınızı her bir gönderinize, hikayelerinize veya profilinizde belirgin bir şekilde kolayca kopyalayıp yapıştırın.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Etkileyiciler, sanatçılar, girişimciler veya topluluğu desteklemeye hevesli olan herkes için idealdir.",
    "Instant Support, Endless Possibilities:": "Anında Destek, Sonsuz Olanaklar:\n",
    "Transform the way you monetize your social media content.": "Sosyal medya içeriğinizden kazanç elde etme şeklinizi dönüştürün.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Takipçilerinizin, favori sosyal ağlarından doğrudan, sadece bir tıklama ile anında size fon göndermelerine olanak tanıyın.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Canlı yayınlar sırasında gerçek zamanlı destek için, yaratıcı içeriğiniz için bir bahşiş sistemi veya bir sonraki büyük projeniz için fonlama olarak idealdir.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "InstaPay Ödeme Adresinizi Maksimum Etki için Nereye Yerleştirebilirsiniz:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Sosyal Medya Profilleri: Sürekli görünürlük için biyografinize veya hakkında bölümüne sabitleyin.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Gönderiler & Duyurular: İşinizi veya özel destek çağrılarınızı vurgulayan bireysel gönderilerinize entegre edin.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Hikayeler & Videolar: Anında katkıları yakalamak için etkileşimli hikayelerinize veya çarpıcı videolarınıza entegre edin.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Canlı Yayınlar: CANLI oturumlarınız sırasında paylaşarak, izleyicilerin gerçek zamanlı olarak katkıda bulunmasını kolaylaştırın.",
    "Simple, Fast, and Secure:": "Basit, Hızlı ve Güvenli:",
    "No complex setup – get started in moments.": "Karmaşık kurulum yok - anında başlayın.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "InstaPay'in güvenilir ödeme işleme hızı ve güvenliğinin keyfini çıkarın.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "InstaPay hesabına ihtiyaç duymadan her yerden, herkes tarafından ödeme alın.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "InstaPay Ödeme Adresi ile Sosyal Medya Oyununuzu Yükseltin.",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Sanal Ödeme Adresi)",
    "Payment Address Title": "Ödeme Adresi Başlığı",
    "Currency": "Para Birimi",
    "Payment Address Description": "Ödeme Adresi Açıklaması",
    "Update": "Güncelle",
    "Updating": "Güncelleniyor",
    "Total Transaction": "Toplam İşlem",
    "Last 30 days": "Son 30 gün",
    "Last 1 month": "Son 1 ay",
    "Last 1 year": "Son 1 yıl",
    "Custom Date": "Özel Tarih",
    "Payment Methods": "Ödeme Yöntemleri",
    "Payment Types": "Ödeme Türleri",
    "Requested Amount": "İstenen Miktar",
    "Quoted Amount": "Teklif Edilen Miktar",
    "About Me": "Benim Hakkımda",
    "Followers": "Takipçiler",
    "InstaPay ID": "InstaPay ID",
    "Download QR Code": "QR Kodu İndir",
    "Initiate A Payment": "Bir Ödeme Başlat",
    "Report this person": "Bu kişiyi rapor et",
    "Add now": "Şimdi ekle",
    "Recent Reviews": "Son Yorumlar",
    "Reviews As Seller": "Satıcı Olarak Yorumlar",
    "Reviews As Buyer": "Alıcı Olarak Yorumlar",
    "Select Language": "Dil Seç",
    "Profile Completion": "Profil Tamamlama",
    "Profile Completed": "Profil Tamamlandı",
    "Basic Info": "Temel Bilgiler",
    "Username": "Kullanıcı Adı",
    "Gender": "Cinsiyet",
    "Male": "Erkek",
    "Female": "Kadın",
    "-Select-Gender-": "-Cinsiyet Seç-",
    "Date Of Birth": "Doğum Tarihi",
    "Address": "Adres",
    "Edit Profile": "Profili Düzenle",
    "Update Profile": "Profili Güncelle",
    "Updating Profile": "Profil Güncelleniyor",
    "Complete Now": "Şimdi Tamamla",
    "Password": "Şifre",
    "Change Password": "Şifre Değiştir",
    "New Password": "Yeni Şifre",
    "Re-enter New Password": "Yeni Şifreyi Yeniden Girin",
    "Please follow this guide for a strong password": "Güçlü bir şifre için bu rehberi takip edin",
    "Include at least one special character.": "En az bir özel karakter içerir.",
    "Minimum of 8 characters.": "Minimum 8 karakter.",
    "Include at least one number": "En az bir sayı içerir",
    "Change it often for enhanced security.": "Artırılmış güvenlik için sık sık değiştirin.",
    "Security Questions": "Güvenlik Soruları",
    "Security Question": "Güvenlik Sorusu",
    "Select your question": "Sorunuzu seçin",
    "Answer To The Question": "Soruya Cevap",
    "Nominee": "Vekil",
    "Relationship with Nominee": "Vekille İlişki",
    "Nominee Contact Number": "Vekil İletişim Numarası",
    "Nominee Address": "Vekil Adresi",
    "Delete Nominee": "Vekili Sil",
    "Update Nominee": "Vekili Güncelle",
    "Details Updated Successfully!": "Detaylar Başarıyla Güncellendi!",
    "Success": "Başarılı",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "İstediğiniz bir Vekil seçebilirsiniz, sağladığı kişi reşit olmamalıdır, InstaPay platformu üzerinden bunu yapabilirsiniz. Vekil atamak için ıslak imzanın gerekli olmadığını ve çevrimiçi kaydın nihai ve bağlayıcı \nolarak kabul edileceğini kabul edersiniz. Bir InstaPay Wallet sahibinin vefatı durumunda, Wallet'taki bakiye kayıtlı Vekile aktarılacaktır. KEMIT KINGDOM, miktarı Vekile devrettikten sonra tüm \nyükümlülüklerinden kurtulmuş olacaktır. Vekil, InstaPay tarafından istenen, kimlik belirleme ve ölüm kanıtı dahil olmak üzere gerekli tüm belgeleri sağlayacaktır.",
    "Add Nominee": "Vekil Ekle",
    "Two Factor Authentication": "İki Faktörlü Kimlik Doğrulama",
    "SMS Number": "SMS Numarası",
    "Updated Successfully!": "Başarıyla Güncellendi!",
    "Social Network Accounts": "Sosyal Ağ Hesapları",
    "Here, you can set up and manage your integration settings.": "Burada entegrasyon ayarlarınızı kurabilir ve yönetebilirsiniz.",
    "Social Network Account": "Sosyal Ağ Hesabı",
    "Activate Account": "Hesabı Etkinleştir",
    "Enter the code below in the InstaPay Chatbot.": "InstaPay Chatbot'unda aşağıdaki kodu girin.",
    "The code will expire in ": "Kodun süresi dolacak",
    "Notifications": "Bildirimler",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Bildirimleri nasıl aldığınızı özelleştirin. Bu ayarlar, takip ettiğiniz aktivitelere uygulanır.",
    "Activity": "Aktivite",
    "Email": "E-posta",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Kaynak",
    "Payment Requests": "Ödeme Talepleri",
    "Bulk Payments": "Toplu Ödemeler",
    "Identity Verification": "Kimlik Doğrulama",
    "Verify Your Identity": "Kimliğinizi Doğrulayın",
    "Identity Verification Status:": "Kimlik Doğrulama Durumu:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Kendinizi kısaca tanımlayın ve InstaPay'i kullanma nedenlerinizi açıklayın.",
    "Enter Your Message": "Mesajınızı Girin",
    "Select Occupation": "Meslek Seç",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Resmi bir kimlik belgesi olmalı ve fotoğraf içermelidir. Tüm kimlik belgeleri Latin alfabesi ile yazılmalıdır. Aksi takdirde, onaylı bir çeviri gereklidir.",
    "Accepted Documents": "Kabul Edilen Belgeler",
    "A national identity card": "Ulusal kimlik kartı",
    "A valid passport": "Geçerli bir pasaport",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Geçerli ikamet izinleri (sadece bir Avrupa ülkesi tarafından veya İsviçre'de verilmişse kabul edilir)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometrik Yüz Tanıma: Videoyu pasaport fotoğrafıyla karşılaştırma",
    "Address Verification:": "Adres Doğrulama:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Adres kanıtı olarak 3 aydan eski olmayan bir banka ekstresi veya fatura sağlayın",
    "Additional Documents:": "Ek Belgeler:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom, kapsamlı doğrulamalar yapmak için gerekli olan ek belgeleri (Geliştirilmiş İnceleme - EDD) veya daha fazla bilgi talep etme hakkını saklı tutar, yasal yükümlülükleri doğrultusunda.",
    "Start Verification": "Doğrulamaya Başla",
    "Sessions": "Oturumlar",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Hesabınıza giriş yapan cihazların bir listesi. Tanımadıklarınızı kaldırın.",
    "Your Withdrawal Options": "Çekim Seçenekleriniz",
    "Choose your preferred method for withdrawing payments.": "Ödemeleri çekmek için tercih ettiğiniz yöntemi seçin.",
    "No withdrawal options currently available in your country.": "Ülkenizde şu anda mevcut çekim seçeneği yok.",
    "Download Your Data": "Verilerinizi İndirin",
    "Request a Copy of Your Data": "Verilerinizin Bir Kopyasını İsteyin",
    "Submit a request to download a copy of your data": "Verilerinizin bir kopyasını indirmek için bir talep gönderin",
    "Which Data Should Be Included in the Download?": "İndirmede Hangi Verilerin Dahil Edilmesi Gerekiyor?",
    "Select all applicable options.": "Uygulanabilir tüm seçenekleri seçin.",
    "Personal Information": "Kişisel Bilgiler",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Bu bilgiler, hesabınızı korumak, deneyiminizi kişiselleştirmek ve gerektiğinde sizinle iletişime geçmek için kullanılır.",
    "Name and Date Of Birth": "İsim ve doğum tarihi",
    "Phone number": "Telefon numarası",
    "Financial Information": "Finansal Bilgiler",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Bu bilgileri, hızlı ödeme yapabilmek ve sadece birkaç tıklama ile para gönderip alabilmek için kullanıyoruz.",
    "Bank accounts": "Banka hesapları",
    "Debit or credit cards": "Borç veya kredi kartları",
    "Credit Information": "Kredi Bilgileri",
    "Includes your credit application information": "Kredi başvuru bilgilerinizi içerir",
    "Other Information (not included in file)": "Diğer Bilgiler (dosyada yer almayan)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Cihaz bilgileri, teknik kullanım verileri, coğrafi konum bilgileri, pazarlama tercihleri, onay geçmişi ve kredi, kimlik doğrulama, PayPal ile iletişim ve üçüncü taraf işlemciler gibi diğer hizmetler için \nkullanılan verileri içerir.",
    "Choose file type:": "Dosya türünü seçin:",
    "Select file type": "Dosya türünü seçin",
    "PDF file can be easily opened on any computer": "PDF dosyası herhangi bir bilgisayarda kolayca açılabilir",
    "Submit Request": "Talep Gönder",
    "Delete Your Account": "Hesabınızı Silin",
    "Ask us to delete your data and close this account": "Verilerinizi silmemizi ve bu hesabı kapatmamızı isteyin",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Verilerinizi hizmet sağlamak için kullanıyoruz, bu yüzden verilerinizi sildiğimizde hesabınızı da kapatmamız gerekiyor.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Hesabınız sınırlıysa, Çözüm Merkezi'ne gidin, 'Hesap Sınırlamalarına Git' seçeneğini seçin, ardından 'Çöz' düğmesine tıklayın.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Herhangi bir bekleyen ödemenin temizlendiğinden emin olun. Bunlar temizlenene kadar hesabınızı kapatamayız.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Eksi bakiyeniz varsa, bakiyenizin yanındaki Çöz bağlantısına tıklayın.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Hesabınız temizlendiğinde ve eğer uygun iseniz, hesabınızı kapatacağız ve verilerinizi sileceğiz. Hukuki ve düzenleyici nedenlerle bazı verilerinizi saklamamız gerekiyor. Daha fazla bilgi için ",
    "privacy statement": "gizlilik beyanımıza bakın",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Durumunuzu bildirmek ve atmanız gereken başka adımlar olup olmadığını öğrenmek için size e-posta göndereceğiz.",
    "Have more questions about data deletion?": "Veri silme hakkında daha fazla sorunuz mu var?",
    "Contact us": "Bize ulaşın",
    "I understand that account deletion is permanent and can't be reversed.": "Hesap silmenin kalıcı olduğunu ve geri alınamayacağını anlıyorum.",
    "Are you sure you want to close your account?": "Hesabınızı kapatmak istediğinizden emin misiniz?",
    "Yes, Continue": "Evet, Devam Et",
    "Logging In": "Giriş Yapma",
    "Complete your profile to streamline your transaction process.": "İşlem sürecinizi hızlandırmak için profilinizi tamamlayın.",
    "Skip": "Atla",
    "Complete Your Profile": "Profilinizi Tamamlayın",
    "API Key": "API Anahtarı",
    "Get API Key": "API Anahtarı Al",
    "Generating New API Key": "Yeni API Anahtarı Üretme",
    "Generate API Key": "API Anahtarı Üret",
    "Regenerate API Key": "API Anahtarı Yeniden Üret",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "InstaPay'in sorunsuz işlemlerinin tam potansiyelinden yararlanmak için, API anahtarınızı oluşturmak için bu hızlı adımları takip edin. İlk olarak, [kayıt URL'nizde] bir hesap oluşturun ve Geliştirici \nPanosuna erişin. 'API Anahtarları' bölümüne gidin, yeni bir anahtar oluşturun ve güvenli bir şekilde sakladığınızdan emin olun.",
    "Delete API Key": "API Anahtarını Sil",
    "Webhook URL": "Webhook URL'si",
    "KYB Verification": "KYB Doğrulaması",
    "Additional Files": "Ek Dosyalar",
    "Business Verification": "İşletme Doğrulaması",
    "--Select-Your-Company-Type--": "--Şirket Türünüzü Seçin--",
    "Documents Required:": "Gerekli Belgeler:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Resmi Şirket Sicil Özeti (Entitenin Kayıt/Kuruluş Belgesi)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Bu belge, şirketin resmi 'kimlik kartı' olarak hizmet eder ve yerel işletme sicilinden alınmalıdır. İşletmenin güncel durumunu (faaliyette veya kapalı) yansıtır.",
    "The document should be current, with an issuance date not exceeding 3 months.": "Belge güncel olmalı ve düzenlenme tarihi 3 ayı aşmamalıdır.",
    "The document must include the following details:": "Belge aşağıdaki detayları içermelidir:",
    "Legal name of the company": "Şirketin yasal adı",
    "Full registered address": "Tam kayıtlı adres",
    "Company registration number": "Şirket kayıt numarası",
    "Date of incorporation": "Kuruluş tarihi",
    "Description of the company's activities/purpose": "Şirketin faaliyetleri / amacının tanımı",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Yasal temsilcinin bilgileri: tam adı, doğum tarihi ve yeri, ikamet adresi (minimum gereklilik: ikamet ülkesi) ve uyruk",
    "If applicable, the document should also include:": "Uygulanabilirse, belge ayrıca şunları içermelidir:",
    "Brand or trading names": "Marka veya ticaret adları",
    "Value Added Tax (VAT) number": "Katma Değer Vergisi (KDV) numarası",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Nihai Faydalanıcı Sahiplerinin (UBOs) listesi - bazı ülkelerde bu zorunludur, aksi takdirde ayrı bir belge olarak sunulabilir.",
    "Proof of Legal Representative's Authority": "Yasal Temsilcinin Yetkisinin Kanıtı",
    "This document validates an individual's authority to represent the company": "Bu belge, bir bireyin şirketi temsil etme yetkisini doğrular.",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Genellikle, yasal temsilci şirketin sicil özetinde belirtilir. Ancak, bu durum söz konusu değilse, Vekaletname veya Yetki Devri gibi bir belge temin edilmelidir.",
    "Power of Attorney/Delegation of Powers:": "Vekaletname/Yetki Devri:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Yetki veren kişi, bunu yapmaya yasal olarak yetkili olmalıdır. Bu kişi, şirketin sicil özetinde yasal temsilci olarak listelenmelidir.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Mülkiyet Yapısı ve Nihai Faydalanıcı Sahipleri (UBOs) Dokümantasyonu",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Şirketin ilgili borsada listelendiğine dair kanıt sağlanmalıdır. Bu, şirketin detaylarını gösteren borsa web sitesinden bir sayfa olabilir.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "Şirketin hisselerinin %75'inden azı halka açıksa, hisselerin %25'inden fazlasına sahip veya kontrol eden tüm hissedarlar, Nihai Faydalanıcı Sahibi (UBO) doğrulama gerekliliklerine tabi olacaktır.",
    "Collection of Identification Documents": "Kimlik Belgesi Toplama",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Kimlik belgesi, bireyin bir fotoğrafını içeren devlet tarafından verilmelidir. Tüm kimlik belgeleri Latin alfabesi ile yazılmalıdır. Aksi takdirde,\nonaylı bir çeviri gereklidir.",
    "Accepted documents:": "Kabul edilen belgeler:",
    "Valid National Identity Card": "Geçerli Ulusal Kimlik Kartı",
    "Valid Passport": "Geçerli Pasaport",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Geçerli İkamet İzinleri - Yalnızca bir Avrupa ülkesi tarafından verilmişse kabul edilecektir.",
    "Only legal representatives are subject to the identification document collection process.": "Sadece yasal temsilciler, kimlik belgesi toplama sürecine tabidir.",
    "Additional Requirement:": "Ek Gereklilik:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "Vekaletname veya Yetki Devri içeren durumlarda, her iki taraf (yetki veren kişi ve alan kişi) kimliklerinin bir kopyasını sağlamalıdır.",
    "Additional Documents": "Ek Belgeler",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Kamu Hizmeti Faturası (gaz, elektrik, sabit telefon veya cep telefonu faturası gibi); veya Son Kullanıcı'nın adresini ve adını gösteren bir devlet dairesi tarafından verilmiş bir belge \n(örneğin, İkametgah Belgesi); veya",
    "Bank Statement; or": "Banka Hesap Özeti; veya",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "Son Kullanıcı'nın adresini ve adını gösteren bir devlet dairesi tarafından verilmiş bir belge (örneğin, İkametgah Belgesi).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom, kapsamlı doğrulamalar yapmak için gereken ek belgeleri veya daha fazla bilgi talep etme hakkını, yasal yükümlülükleri doğrultusunda saklı tutar.",
    "Note:": "Not:",
    "Partners": "Ortaklar",
    "Add New Partner": "Yeni Ortak Ekle",
    "Designation": "Unvan",
    "Partner Name": "Ortak Adı",
    "Partner Email": "Ortak E-posta",
    "Partner Designation": "Ortak Unvanı",
    "Add Now": "Şimdi Ekle",
    "Adding": "Ekleniyor",
    "Delete Partner": "Ortağı Sil",
    "Delete": "Sil",
    "Deleting": "Siliniyor",
    "Are you sure, you want to remove sxz as partner?": "sxz'yi ortak olarak kaldırmak istediğinizden emin misiniz?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Entitenin Sicil Özeti (Entitenin Kayıt/Oluşturma Belgesi)",
    "This document should be either:": "Bu belge aşağıdakilerden biri olmalıdır:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Entitenin detaylarını, oluşturulma tarihini ve amacını sağlayan entitenin oluşturulmasının kanıtı.",
    "A local registry extract:": "Yerel sicil özeti:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Ülkenin düzenlemelerine göre gerekli olup, entitenin güncel 'kimlik kartı' olarak hizmet eder ve durumunu (faal veya kapalı) belirtir.",
    "If a registry extract exists, it must not be older than 3 months.": "Bir sicil özeti varsa, 3 aydan eski olmamalıdır.",
    "Entity name": "Entite adı",
    "Registration number (if applicable, based on the country's requirements)": "Kayıt numarası (uygulanabilirse, ülkenin gerekliliklerine bağlı olarak)",
    "Date of creation": "Oluşturulma tarihi",
    "Description of the entity's activities or purpose": "Entitenin faaliyetleri veya amacının açıklaması",
    "Trading names": "Ticaret adları",
    "Articles of Association": "Ana Sözleşme",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Ana Sözleşme, entitenin işletme kurallarını belirten anayasal belgesi olarak hizmet eder.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Bu belge için zaman sınırlaması yoktur, bu da en güncel sürümün istendiği anlamına gelir. Genellikle, bu tür entiteler en az bir yıllık toplantı gerektirir ki bu da Ana Sözleşmenin güncellenmiş \nbir sürümüne yol açabilir.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Oluşabilecek değişiklikler arasında entitenin adının, adresinin, amacının, yönetim kurulunun, rollerinin, sorumluluklarının vb. güncellenmesi yer alabilir.",
    "The document should contain information about:": "Belge aşağıdaki bilgileri içermelidir:",
    "The entity itself, including its legal name, registered address, and purpose": "Entitenin kendisi, hukuki adı, kayıtlı adresi ve amacı dahil",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Yönetim kurulu üyelerinin atama süreci, rolleri ve yetkileri gibi iç işletme kuralları. Bu liste tükenmez.",
    "How the entity is funded": "Entitenin nasıl finanse edildiği",
    "Appointment/Removal/Changes on Board of Management Members:": "Yönetim Kurulu Üyelerinin Atanması/Kaldırılması/Değişiklikleri:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Bu güncellemeler genellikle entitenin ihtiyaçlarına veya Ana Sözleşmede belirtilenlere göre gerçekleşir. Değişiklikler, yönetim kurulu toplantı tutanakları aracılığıyla belgelenir, bu tutanaklar kaydedilir \nve güncellenmiş üye listesini yansıtacak şekilde onaylanır, örneğin başkan, başkan yardımcısı, muhasebeci ve genel sekreter gibi.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "En son yönetim kurulu toplantı tutanakları, mevcutsa Ana Sözleşme ile birlikte istenecektir.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Not: Eğer güncel yönetim kurulu üyeleri listesi en son Ana Sözleşmede yer almıyorsa ve yönetim kurulu toplantı tutanakları mevcut değilse, bu liste sağlanmalıdır. \nEn son atanan yasal temsilci tarafından tarihli ve imzalı olmalı ve ilgili belgelerle atanmasının doğrulanabilmesi gerekmektedir.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Bu belge, bir bireyin entiteyi temsil etme yetkisini gösterir. Bu belge şunlardan biri olabilir:",
    "The latest Articles of Association": "En son Ana Sözleşme",
    "The current list of board management members (which may be included in the board management meeting minutes)": "Güncel yönetim kurulu üyeleri listesi (bu liste yönetim kurulu toplantı tutanaklarında yer alabilir)",
    "The document should specify:": "Belge aşağıdakileri belirtmelidir:",
    "Full name of the individual(s)": "Birey(ler)in tam adı",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Birey(ler)in fonksiyonu. Bu bilgi, Ana Sözleşme ile karşılaştırılarak doğrulanacak ve bireyin fonksiyonunun entiteyi bağlama yetkisine sahip olduğundan emin olunacaktır. Bu, başvuru sahibi başkan değilse \nve diğer fonksiyonların Ana Sözleşmede belirtilen sınırlı temsil yetkisine sahip olduğunda özellikle önemlidir.",
    "Additional information about the legal representative that will be collected includes:": "Yasal temsilci hakkında toplanacak ek bilgiler şunları içerir:",
    "Date and place of birth": "Doğum tarihi ve yeri",
    "Residential address (at least the country of residence should be stated)": "İkamet adresi (en azından ikamet edilen ülke belirtilmeli)",
    "Nationality": "Uyruk",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Mülkiyet Yapısı ve Nihai Faydalanıcı Sahipleri (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Genellikle, bu tür entiteler, yapıları ve amaçları göz önünde bulundurulduğunda, faydalanıcı sahiplere sahip değildir. Ancak, yönetim kurulu üyeleri tarama amaçları, kimlik toplama ve doğrulama vb. \niçin bu şekilde tanımlanacak ve işlem görecektir.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Kâr elde eden birkaç dernek, özellikle faydalanıcı sahiplere sahip olabilir. Bu gibi durumlarda, bu ayrıntı\nAna Sözleşmede belirtilecektir. Bu bireyler kolayca tanımlanacak ve yönetim kurulu üyeleriyle birlikte bu şekilde işlem görecektir.\"",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Ek Gereklilik: Vekaletname veya Yetki Devri içeren durumlarda, her iki taraf (yetki veren kişi ve alan kişi) kimlik belgelerinin bir kopyasını sağlamalıdır.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Tek Kişilik İşletmenin Sicil Özeti (Entitenin Kayıt/Oluşturma Belgesi)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Bu belge, bu tür bir kaydın zorunlu olduğu ülkelerde tek kişilik işletmeler için yerel bir sicil olarak hizmet eder. Bireyin tek kişilik işletme olarak profesyonel bir faaliyette bulunduğunu doğrular. \nBu belgenin adı ülkeden ülkeye değişir.",
    "Where applicable, the document should not be older than 3 months.": "Uygulanabilirse, belgenin 3 aydan eski olmaması gerekmektedir.",
    "The document must contain:": "Belge aşağıdaki bilgileri içermelidir:",
    "Individual's name": "Bireyin adı",
    "Residential address": "İkamet adresi",
    "Registration number": "Kayıt numarası",
    "The sole trader is the only individual subject to the identification document collection process.": "Tek kişilik işletme sahibi, kimlik belgesi toplama sürecine tabi tek bireydir.",
    "No Transactions": "İşlem Yok",
    "No Pending Items": "Bekleyen Öğe Yok",
    "No Beneficiaries": "Faydalanıcı Yok",
    "Send Money To": "Para Gönder",
    "Confirmation": "Onay",
    "You are setting": "Varsayılan olarak ayarlıyorsunuz.",
    "as default.": "Önceden",
    "Previously": "varsayılan QR idi.",
    "was default QR.": "Kategori",
    "Category": "-Listeden-Seç-",
    "-Select-From-The-List-": "Ücretler",
    "Add a note": "Kategoriler Başarıyla Eklendi!",
    "Categories Added Successfully!": "Abonelik başlangıç tarihi",
    "Subscription starting date": "Kadar",
    "Until": "Bir Sonraki İşlem",
    "Next Transaction": "İşlem Yöntemi",
    "Transaction Method": "Teklifi kabul et",
    "Accept the quotation": "Para Birimi Seç",
    "Choose Currency": "Kabul Et",
    "Accept": "Teklifi pazarlık et",
    "Bargain the quotation": "Karşı Miktar Gir:",
    "Enter Counter Amount:": "Pazarlık",
    "Bargain": "Teklifi iptal et",
    "Decline the quotation": "Bu teklifi reddetmek istediğinizden emin misiniz?",
    "Are you sure, you want to decline this quotation?": "Reddet",
    "Decline": "Kişisel Hesap",
    "Jan": "Oca",
    "Feb": "Şub",
    "Mar": "Mar",
    "Apr": "Nis",
    "May": "May",
    "Jun": "Haz",
    "Jul": "Tem",
    "Aug": "Ağu",
    "Sep": "Eyl",
    "Oct": "Eki",
    "Nov": "Kas",
    "Dec": "Ara",
    "Today": "Bugün",
    "Spent In": "Harcanan",
    "Revised Amount": "Revize Edilen Tutar",
    "You spent more comparing to last month": "Geçen aya kıyasla daha fazla harcadınız",
    "Last": "Geçen",
    "days": "Günler",
    "Year": "Yıllar",
    "Instant Mobile Top-up Across 150+ Countries": "150'den Fazla Ülkede Anında Mobil Kontör Yükleme",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Sevdiklerinizi mutlu edin veya kendi telefonunuzu nerede olursanız olun şarj edin! InstaPay Mobil Hava Yolu hizmeti, 150'den fazla ülkede mobil telefonlara anında kontör yükleme imkanı sunar. Hediyeler için mükemmel veya her zaman bağlı kalmayı sağlamak için tasarlanmış olan hizmetimiz, kolaylık, hız ve güvenlik için tasarlanmıştır.",
    "How It Works - Step-by-Step Guide:": "Nasıl Çalışır - Adım Adım Kılavuz:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. 'Mobil Yükleme' Seçin: Bu özelliğe WhatsApp, Twitter, Telegram veya Instagram gibi platformlarda InstaPay uygulaması içinden doğrudan erişin.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Para Birimi ve Miktarı Seçin: Birden fazla para birimi arasından seçim yapın (USD, GBP, EUR, vb.) ve ne kadar yüklemek istediğinizi belirtin.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Mobil Numarası Girin: Yüklemek istediğiniz numarayı yazın—kendinizin veya sevdiğiniz birinin. Sistemimiz güvenli ve hızlı bir işlemi garanti eder.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Onaylayın ve Ödeyin: Yükleme detaylarını iki kez kontrol edin, onaylayın ve tercih ettiğiniz yöntemle ödeme yapın. İşlem anında ve güvenli bir şekilde gerçekleşir.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Anında Onay Alın: Yükleme başarılı olduğunda hem siz hem de alıcı (uygulanabilirse) bir onay bildirimi alacaksınız.",
    "Benefits:": "Faydalar:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Küresel Erişim: 150'den fazla ülkeye rahatlıkla hava yolu gönderin.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Anında Etki: Faydaları hemen görün—son dakika hediyeleri veya acil iletişimler için mükemmel.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Tam Güvenlik: Güvenli ödeme sistemimizle huzur içinde olun.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Çeşitli Ödeme Seçenekleri: Ödemeler için kredi kartları, PayPal veya InstaPay cüzdanınızı seçin.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Neşe yaymaya veya bağlantıda kalmaya hazır mısınız? Şimdi yükleme yapın ve birinin gününü yapın—veya kendi gününüzü!",
    "Pick a language": "Bir dil seçin",
    "French": "Fransızca",
    "Spanish": "İspanyolca",
    "Portuguese": "Portekizce",
    "German": "Almanca",
    "Ukrainian": "Ukraynaca",
    "Italian": "İtalyanca",
    "Russian": "Rusça",
    "Arabic": "Arapça",
    "Polish": "Lehçe",
    "Dutch (Nederlands)": "Hollandaca",
    "Yoruba": "Yoruba",
    "Indonesian": "Endonezyaca",
    "Turkish": "Türkçe",
    "Swahili": "Svahili",
    "Hausa": "Hausa",
    "Hindi": "Hintçe",
    "Urdu": "Urduca",
    "Chinese": "Çince",
    "Select Your Preferred Language": "Tercih Ettiğiniz Dili Seçin",
    "Please select the language you would \nlike to use for the eKYC process.": "Lütfen eKYC süreci için kullanmak istediğiniz dili seçin.",
    "Select Your Nationality": "Milliyetinizi Seçin",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Ülkeniz için desteklenen kimlik belgelerini görmek için milliyetinizi seçin.",
    "Grant permission to use the camera.": "Kamerayı kullanmak için izin verin.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Belgeyi yakalamak için kamera erişim izni vermek istediğinizde \"İzin Ver\"e dokunun veya tıklayın.",
    "Upload Your Documents from \nGallery/Photos": "Belgelerinizi Galeri/Fotoğraflardan Yükleyin",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Kameranızla ilgili sorunlar yaşıyorsanız veya sisteminizde kamera yoksa, belgenin fotoğraflarını yerel depolamanızdan manuel olarak yükleyebilirsiniz.",
    "Upload Your ID.": "Kimliğinizi Yükleyin.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Kimliğinizin net ve bulanık olmadığından emin olun, böylece hızlı bir şekilde doğrulanabilirsiniz.",
    "Valid Proof of Address": "Geçerli Adres Kanıtı",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Adres kanıtınızın güncel olduğundan ve eKYC doğrulamanızın başlangıç tarihinden itibaren son üç ay içinde olduğundan emin olun.\n\n2. Adres kanıtınızın net ve bulanık olmadığından emin olun, böylece hızlı bir şekilde doğrulanabilirsiniz.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Önemli Bilgilendirme\n\neKYC doğrulamanıza başlamadan önce lütfen bu kılavuzdaki tüm adımları dikkatlice okuyun.\n\nYaygın hatalardan kaçının:\n\n* Tüm belgelerinizin net, okunabilir ve güncel olduğundan emin olun.\n* Sağladığınız bilgilerin resmi belgelerinizdeki bilgilerle eşleştiğini kontrol edin.\n* Gecikmeleri veya reddedilmeleri önlemek için talimatları dikkatlice izleyin.\n\nNot: \neKYC doğrulamanız başarısız olursa ve yeniden göndermeniz gerekirse, yeniden gönderim için ek ücretler alınacaktır.\n\nBu yönergeleri izleyerek, sorunsuz ve başarılı bir eKYC doğrulaması sağlayabilirsiniz.\n\nHerhangi bir sorunuz veya yardıma ihtiyacınız varsa, lütfen destek ekibimizle support@insta-pay.ch adresinden iletişime geçin.",
    "Personal Transactions": "Kişisel İşlemler",
    "Business Transactions": "Ticari İşlemler",
    "Shopping & Purchases": "Alışveriş ve Satın Alımlar",
    "Bills & Utilities": "Faturalar ve Hizmetler",
    "Entertainment & Leisure": "Eğlence ve Boş Zaman",
    "Investments & Savings": "Yatırımlar ve Tasarruflar",
    "Health & Wellness": "Sağlık ve Zindelik",
    "Transportation": "Ulaşım",
    "Education": "Eğitim",
    "Miscellaneous": "Çeşitli",
    "Family Support": "Aile desteği",
    "WALLET": "WALLET",
    "Add a currency": "Bir para birimi ekleyin",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Bir para birimi eklediğinizde, o para biriminde aldığınız tüm ödemeler InstaPay bakiyenize eklenecektir. Aksi belirtilmedikçe, ödemeleri göndermek veya talep etmek için birincil para biriminiz kullanılacaktır.",

    "balance amount ": "bakiye miktarı",
    "referral ": "yönlendirme",
    "crypto wallet balance": "kripto cüzdanı bakiyesi",
    "money in": "para giriş",
    "money out": "para çıkış",
    "analytics": "analitik",
    "see more": "daha fazla gör",
    "manage my Wallets and see the transaction details": "Cüzdanlarımı yönetin ve işlem detaylarımı görün",
    "set as default Wallet": "varsayılan Cüzdan olarak ayarla",
    "add currency": "para birimi ekle",
    "convert funds ": "fonları dönüştür",
    "withdraw balance": "bakiyeyi çek",
    "chart names": "grafik adları",
    "withdraw balance from PKR wallet": "PKR cüzdanından bakiye çek",
    "select a payout channel": "bir ödeme kanalı seç",
    "you have entered an amount below the minimum payment range": "minimum ödeme aralığının altında bir tutar girdiniz",
    "change": "değiştir",
    "edit": "düzenle",
    "moving to next step in ...": "sonraki adıma geçiliyor ...",
    "you are withdrawing ...": "çekiyorsunuz ...",
    "where is my payment": "ödemem nerede",
    "download": "indir",
    "IBAN / SWIFT Code": "IBAN atau kode SWIFT/BIC",
    "swift code": "kode SWIFT",
    "account number": "nomor rekening",
    "branch street": "şube cadde",
    "city": "kota",
    "province": "provinsi",
    "postal code": "kode pos",
    "all payout channels": "semua saluran pembayaran",
    "bussiness beneficiary": "penerima bisnis",
    "no quotation": "teklif yok",
    "no pending items": "bekleyen öğe yok",
    "QR status": "QR durumu",
    "Portfolio": "Portföy",
    "edit whole portfolio page": "tüm portföy sayfasını düzenle",
    "personal account": "kişisel hesap",
    "Chart names": "Grafik isimleri",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "daha fazla",
    "not connected": "bağlı değil",
    "code": "kod",
    "ratings": "değerlendirmeler",
    "current password": "mevcut şifre",
    "include at least one uppercase and lowercase": "en az bir büyük ve küçük harf içermelidir",
    "my social network accounts": "sosyal ağ hesaplarım",
    "SMS ": "SMS",
    "view identity verfication guide": "kimlik doğrulama kılavuzunu görüntüle",
    "boimetric face recognition": "biyometrik yüz tanıma",
    "please select a payer (withdrawl options)": "lütfen bir ödeyici seçin (çekim seçenekleri)",
    "IBAN number ": "IBAN numarası",
    "account holder name": "hesap sahibi adı",
    "download data": "verileri indir",
    "bank transfer": "banka transferi",
    "mobile wallet": "mobil cüzdan",
    "all bank names": "tüm banka adları",
    "pay via card": "kartla öde",
    "you have entered an amount that is below the payer range": "ödeyici aralığının altında bir miktar girdiniz",
    "trouble sending money": "para gönderme sorunu",
    "please follow these example": "lütfen bu örnekleri takip edin",
    "wallet ID ": "Wallet kimliği",
    "username": "kullanıcı adı",
    "email": "e-posta",
    "phone number": "telefon numarası",
    "no user found": "kullanıcı bulunamadı",
    "search": "arama",
    "scan/upload QR code of the recipient": "alıcıya ait QR kodunu tarat/yükle",
    "invite them": "onları davet et",
    "trouble quoting money": "para teklifinde sorun",
    "select a country": "bir ülke seçin",
    "airtime details": "kontör detayları",
    "enter the phone number": "telefon numarasını girin",
    "service type": "hizmet türü",
    "select a service": "bir hizmet seçin",
    "service list": "hizmet listesi",
    "amount entered is less than the ....": "girilen tutar .... azdır",
    "total": "toplam",
    "total credit the receiver gets": "Alıcının aldığı toplam kredi",
    "take ": "al",
    "OTP has been verified , You'll be redirected......": "OTP doğrulandı, yönlendirileceksiniz......",
    "Link Your Instagram Account to InstaPay": "Instagram Hesabınızı InstaPay'e Bağlayın",
    "Step 01 – From Instagram App": "Adım 01 – Instagram Uygulamasından",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Instagram uygulamasını açın, \"InstaPay\" profil sayfasını arayın, sohbet kutusunu açmak için \"Mesaj\"ı seçin, \"Merhaba\" yazın veya söyleyin ve InstaPay chatbot ile konuşmayı başlatmak için gönder'e tıklayın.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Adım 02 – InstaPay Instagram Profil Sayfasından",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "InstaPay chatbot iki işlem butonu gösterecektir: \"Kaydol\" ve \"Bağlan\". \"Bağlan\" seçeneğini seçin ve chatbot size InstaPay kullanıcı adınızı girmenizi isteyecektir.",
    "Step 03 – From InstaPay Instagram Profile Page": "Adım 03 – InstaPay Instagram Profil Sayfasından",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "InstaPay chatbot, InstaPay doğrulama kodunu isteyecektir. InstaPay ayarlarından \"Sosyal Ağ Hesaplarım\" altında aldığınız kodu girin ve gönder'e tıklayın.",
    "Step 04 – From InstaPay Instagram Profile Page": "Adım 04 – InstaPay Instagram Profil Sayfasından",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Instagram hesabınızın artık InstaPay ile senkronize edildiğini onaylayan bir tebrik mesajı alacaksınız. InstaPay hizmetlerini kullanmaya başlamak için \"Ana Menü\"ye tıklayın.",
    "Additional Tips": "Ekstra İpuçları",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Hesabınızı Güvenceye Alın: Bağlantılı InstaPay hizmetlerinizi korumak için iki faktörlü kimlik doğrulama (2FA) etkinleştirerek Instagram hesabınızın güvende olduğundan emin olun.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Daha Fazlasını Keşfedin: Bağlandıktan sonra, InstaPay'in sunduğu sorunsuz işlemlerden anında çekimlere kadar geniş hizmet yelpazesini keşfedin.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Güncel Kalın: Deneyiminizi daha entegre hale getirmek için yakında diğer sosyal medya hesaplarının bağlantısını destekleyeceğimiz için güncellemelerimizi takip edin.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Yardım ve Destek: Bağlantı sürecinde herhangi bir sorunla karşılaşırsanız, yardım için destek ekibimizle iletişime geçin.",
    "Future Enhancements": "Gelecek İyileştirmeler",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Yakın gelecekte InstaPay, daha fazla sosyal medya hesabını bağlamanıza izin verecek, size daha fazla esneklik ve rahatlık sağlayacak. Heyecan verici güncellemeler için bizi izlemeye devam edin!",
    "Personal Transactions": "Kişisel İşlemler",
    "Business Transactions": "Ticari İşlemler",
    "Shopping & Purchases": "Alışveriş ve Satın Alımlar",
    "Bills & Utilities": "Faturalar ve Hizmetler",
    "Entertainment & Leisure": "Eğlence ve Boş Zaman",
    "Investments & Savings": "Yatırımlar ve Tasarruflar",
    "Health & Wellness": "Sağlık ve Zindelik",
    "Transportation": "Ulaşım",
    "Education": "Eğitim",
    "Miscellaneous": "Çeşitli",
    "Family Support": "Aile desteği",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "InstaPay Yolculuğunuza Bugün Başlayın!",
    "Enter your phone number": "Telefon numaranızı girin",
    "Login with email": "E-posta ile giriş yapın",
    "Don't have an account?": "Hesabınız yok mu?",
    "Sign Up": "Kaydolun",
    "Continue": "Devam et",
    "We recommend you download and install the Google Authenticator app": "Google Authenticator uygulamasını indirmenizi ve yüklemenizi öneririz",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "İki faktörlü kimlik doğrulama (2FA) çevrimiçi kendinizi korumanın en iyi yoludur.",
    "Strong security with Google Authenticator": "Google Authenticator ile güçlü güvenlik",
    "Enter your password": "Şifrenizi girin",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Google Authenticator uygulamasını indirmenizi ve yüklemenizi öneririz, böylece doğrulama kodlarınızı anında alabilirsiniz, SMS gecikmelerinden veya sorunlarından kaçınabilirsiniz.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "İki Faktörlü Kimlik Doğrulama Kodu",
    "Problem with the token?": "Token ile ilgili sorun mu var?",
    "Verify using SMS": "SMS ile doğrulayın",
    "Verify": "Doğrula",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "SMS Doğrulama",
    "Enter the SMS code sent to +41********4053": "+41********4053 numarasına gönderilen SMS kodunu girin",
    "Verify Using Authenticator App": "Doğrulamak için Authenticator uygulamasını kullanın",
    "Resend code in 120 seconds": "120 saniye içinde kodu yeniden gönder",
    "Verify ": "Doğrula",
    "SMS - Web Mobile": null,
    "My own account": "Kendi hesabım",
    "Friends & Family": "Arkadaşlar & Aile",
    "Aunt": "Teyze",
    "Brother-in-law": "Kayınbirader",
    "Cousin": "Kuzen",
    "Daughter": "Kız",
    "Father": "Baba",
    "Father-in-law": "Kayınpeder",
    "Friend": "Arkadaş",
    "Grandfather": "Dede",
    "Grandmother": "Büyükanne",
    "Husband": "Koca",
    "Mother": "Anne",
    "Mother-in-law": "Kayınvalide",
    "Nephew": "Yeğen (erkek)",
    "Niece": "Yeğen (kız)",
    "Self (i.e. the sender, himself)": "Kendi (yani gönderen)",
    "Sister": "Kız kardeş",
    "Sister-in-law": "Baldız",
    "Son": "Oğul",
    "Uncle": "Amca",
    "Wife": "Eş",
    "Others not listed": "Diğerleri listelenmemiş",
    "Merchant": "Tüccar",
    "No relationship": "Hiçbir ilişki",
    "Add Funds": "Para Ekle",
    "Enter the Amount": "Miktarı Girin:",
    "Fees": "Ücretler:",
    "You Will Get": "Alacağınız:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ İşlem değeri [Currency ISO code & Amount] - [Currency ISO code & Amount] arasında olmalıdır",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Geçersiz Miktar: Mevcut kimlik doğrulama seviyeniz ekleyebileceğiniz maksimum miktarı sınırlar. Daha yüksek limitlerden yararlanmak için kimlik doğrulamanızı yükseltin.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Döviz fiyatı piyasa koşullarına göre sık sık değişir. Nihai teklifiniz olarak sipariş onay sayfasındaki fiyata bakın.",
    "Next": "Sonraki",
    "Upgrade Identity Verification": "Kimlik Doğrulamayı Yükseltin",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "İstediğiniz kişiden, istediğiniz yerde, sosyal ağ platformları dahil para talep edin. InstaPay ile ödeme talepleriniz kabul edildikten sonra anında erişilebilir hale gelir ve fonlar Alacak Hesaplarınıza gönderilir. İşlemlerinizi basitleştirin ve ödemeleri kolaylıkla ve güvenli bir şekilde yönetin.",
    "You’ve been logged out.": "Oturumunuz kapatıldı.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Güvenliğiniz için 15 dakika hareketsizlikten sonra oturumunuz kapatıldı. InstaPay'i kullanmak istiyorsanız lütfen tekrar giriş yapın.",
    "LOGIN AGAIN": "TEKRAR GİRİŞ YAP",
    "Brother": "Kardeş",
    "Current Password": "Mevcut şifre",
    "The new password cannot be the same as the old one": "Yeni şifre eskiyle aynı olamaz.",
    "Minimum password length: [X] characters ": "Minimum şifre uzunluğu: [X] karakter",
    "Include at least one uppercase and one lowercase letter.": "En az bir büyük harf ve bir küçük harf içermelidir.",
    "Nominee Information": "Aday Bilgileri",
    "Verification Code Preferences": "Doğrulama Kodu Tercihleri",
    "How Others Can Find You": "Başkaları Sizi Nasıl Bulabilir",
    "My Social Network Accounts": "Sosyal Ağ Hesaplarım",
    "Login Activity": "Giriş Etkinliği",
    "Receiving Accounts": "Alıcı Hesapları",
    "Change Your Password To Continue": "Devam Etmek İçin Şifrenizi Değiştirin",
    "Continue with": "Devam Et",
    "Back to Login": "Girişe Dön",
    "Send OTP": "OTP Gönder",
    "A Verification code has been sent to your email and number.": "Doğrulama kodu e-posta ve numaranıza gönderildi.",
    "Resend available in": "Tekrar gönderim kullanılabilir",
    "Create a strong Password": "Güçlü Bir Şifre Oluşturun",
    "Password must:": "Şifre şunları içermelidir:",
    "Be between 9 and 64 characters": "9 ile 64 karakter arasında olmalıdır",
    "Include at least two of the following:": "En az ikisini içermelidir:",
    "Lowercase Character": "Küçük Harf",
    "Special Character": "Özel Karakter",
    "Password Updated Successfully!": "Şifre Başarıyla Güncellendi!",
    "Click below to login": "Giriş yapmak için aşağıya tıklayın",

    "Send Money": "Para Gönder",
    "Enter the amount": null,
    "Recent Transfer": "Son Transfer",
    "See all": "Hepsini gör",
    "Send again": "Tekrar gönder",
    "You Send": "Siz Gönderin",
    "They Receive": "Onlar Alır",
    "Receive Method": "Alma Yöntemi",
    "Select method": "Yöntemi seçin",
    "Next": "Sonraki",
    "Trouble sending money?": "Para gönderme sorun mu?",
    "Where do you want to send money ?": "Parayı nereye göndermek istiyorsunuz?",
    "Search ": "Ara",
    "Confirm": "Onayla",
    "Bank Deposit": "Banka Mevduatı",
    "Cash Pick-up": "Nakit Çekme",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Banka Kartı",
    "Crypto Wallet": "Wallet kripto",
    "What is the receiver’s Mobile Wallet?": "Alıcının Wallet mobil nedir?",
    "Transfer fees": "Transfer ücretleri",
    "Total to pay": "Toplam ödenecek tutar",
    "Choose the beneficiary!": "Alıcıyı seç!",
    "Country": "Ülke",
    "Receive method": "Alma yöntemi",
    "You send": "Siz gönderin",
    "change": "değiştir",
    "edit": "yeni ekle",
    "add new": "Alıcılar",
    "Beneficiaries": "Alıcıları Yönet",
    "Manage Beneficiaries": "Ara",
    "Search": "Sonraki",
    "CASH PICK-UP": "NAKİT ÇEKME",
    "Where will your receiver collect the money": "Alıcınız parayı nereden alacak",
    "Cash is available to collect within minutes from any branch of [name] ": "Nakit, [isim] şubesinden birkaç dakika içinde alınabilir",
    "BANK ACCOUNT": "Banka Hesabı",
    "Transfer to recipient’s bank account": "Alıcının banka hesabına transfer",
    "BANK CARD": "BANKA KARTI",
    "Fast transfer directly to recipient’s bank card": "Alıcının banka kartına doğrudan hızlı transfer",
    "CRYPTO WALLET": "WALLET KRIPTO",
    "Fast transfer directly to a crypto wallet": "Kripto cüzdana doğrudan hızlı transfer",
    "Review transfer": "Transferi gözden geçirin",
    "Receiver's Details": "Alıcı bilgileri",
    "Edit": "Düzenle",
    "Name": "İsim",
    "Reason for sending": "Gönderme nedeni",
    "Sending": "Gönderme",
    "Receiver gets": "Alıcı alır",
    "Exchange rate": "Döviz kuru",
    "Estimated time": "Tahmini süre",
    "Select Payment Type": "Ödeme Türünü Seçin",
    "Credit cards": "Kredi kartları",
    "Domestic cards": "Yerli kartlar",
    "Mobile Wallets": "Wallet mobil",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KREDİ KARTI",
    "Credit Card": "Kredi Kartı",
    "Select card": "Kartı seç",
    "Card Number": "Kart numarası",
    "Enter the 16-digit card number on the card": "Kart üzerindeki 16 haneli kart numarasını girin",
    "Expiry Date": "Son kullanma tarihi",
    "Enter the expiration date of the card": "Kartın son kullanma tarihini girin",
    "CVV Number": "CVV Numarası",
    "Enter the 3 or 4 digit number on the card": "Kart üzerindeki 3 veya 4 haneli numarayı girin",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Wallet seçin",
    "My Wallet": "Cüzdanım",
    "Conversion": "Dönüşüm",
    "Convert": "Dönüştür",
    "Your payment is confirmed": "Ödemeniz onaylandı",
    "Thanks for using InstaPay": "InstaPay kullandığınız için teşekkür ederiz",
    "Status": "Durum",
    "Share": "Paylaş",
    "Transfer created": "Transfer oluşturuldu",
    "Payment received": "Ödeme alındı",
    "Payment processed": "Ödeme işlendi",
    "Transfer successful": "Transfer başarılı",
    "It may take few hours for the funds to appear in [user name] account": "Fonların [kullanıcı adı] hesabında görünmesi birkaç saat sürebilir",
    "Transfer failed": "Transfer başarısız",
    "Transaction Details": "İşlem Detayları",
    "Need help?": "Yardıma mı ihtiyacınız var?",
    "Service Details": "Hizmet Detayları",
    "Funds Deposit Speed": "Fonların Kullanılabilirliği",
    "Cut-off Time": "Son Kabul Saati",
    "Bank holiday list": "Banka Tatil Listesi",
    "Threshold limits": "İşlem Limitleri",
    "null": null,
    "Instant, Real Time": "Anında, gerçek zamanlı",
    "Same day, T+1": "Aynı gün, T+1",
    "Non-instant, T+1": "Anında değil, T+1",
    "Instant Payment, Real Time": "Anında Ödeme, gerçek zamanlı",
    "Transaction processed before 3:00 PM Local time": "İşlem yerel saatle 15:00'dan önce işlendi",
    "Delivered the same day": "Aynı gün teslim edildi",
    "Transactions processed post 3:00 PM": "15:00'dan sonra işlenen işlemler",
    "T+1 business days": "T+1 iş günü",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Hafta içi 15:00'dan önce yapılan ödemeler aynı gün gece yarısına kadar alıcı banka hesabına yatırılacaktır.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Hafta içi 15:00'dan sonra yapılan ödemeler ertesi gün gece yarısına kadar yatırılacaktır.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Cumartesi, Pazar veya resmi tatil günlerinde yapılan ödemeler, bir sonraki iş gününün gece yarısına kadar hesaba yatırılacaktır.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 Yerel Saat",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "İşlem başına minimum limit:",
    "Maximum per transaction limit:": "İşlem başına maksimum limit:",
    "Daily threshold limit:": "Günlük limit:",
    "Weekly threshold limit:": "Haftalık limit:",
    "Monthly threshold limit:": "Aylık limit:",
    "Yearly threshold limit:": "Yıllık limit:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Temel Hesap (Doğrulanmamış)",
    "Identity Verified": "Kimlik Doğrulandı",

    "Good Evening!": "İyi Akşamlar!",
    "Total Balance": "Toplam Hesap Bakiyesi",
    "Quick Transfer": "Hızlı Fon Transferi",
    "No transactions": "Hiçbir işlem bulunamadı",
    "Blocked balance": "Bloke Edilmiş Bakiye",
    "Available balance": "Mevcut Bakiye",
    "Insufficient Balance in PKR": "PKR'de Yetersiz Bakiye",
    "Chat with us": "Bizimle Sohbet Edin",
    "Add Currency": "Para Birimi Ekle",
    "Education": "Eğitim",
    "Divers": "Çeşitli",
    "Family support": "Aile Desteği",
    "Others": "Diğer Giderler",
    "Transport": "Ulaşım",
    "schedule payment": "Planlı Ödeme",
    "subscription payment": "Yinelenen Ödeme",
    "No payment": "Hiçbir ödeme bulunamadı",
    "No Beneficiaries": "Hiçbir Yararlanıcı Mevcut Değil",
    "Code Postal": "Posta Kodu",
    "Additional Information": "Ek Bilgiler",
    "Gender": "Cinsiyet",
    "Date Of Birth": "Doğum Tarihi",
    "Nationality": "Milliyet",
    "Occupation": "Meslek (tüm seçenekler de dahil)",
    "ID Type": "Kimlik Türü (tüm seçenekler de dahil)",
    "ID Number": "Kimlik Numarası",
    "Select Beneficiary Payment Channels": "Yararlanıcı Ödeme Kanallarını Seçin",
    "wallet crypto": "Kripto Cüzdanı",
    "Add another wallet": "Başka bir cüzdan ekle",
    "Enterprise": "İşletme",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "İşletme yararlanıcıları yakında geliyor! Küresel olarak iş işlemleri yapabileceksiniz ve sınırlar ötesine erişiminizi genişletebileceksiniz.",
    "Joined At": "Katılma tarihi",
    "Profile Link": "Profil bağlantısı",
    "comission": "Komisyon",
    "my withdrawls": "Çekimlerim",
    "My Referrals": "Yönlendirmelerim",
    "search quotation": "Teklifleri Ara",
    "no quotation": "Hiçbir teklif bulunamadı",
    "actions": "Eylemler",
    "search request": "Arama isteği",
    "no pending items ": "Bekleyen hiçbir öğe bulunamadı",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "QR Kodunuzu veya ödeme bağlantınızı paylaşarak InstaPay cüzdanınızda anında ödeme alın",
    "Description": "Açıklama",
    "save": "Kaydet",
    "Share your Insta-Pay unique payment link": "Benzersiz InstaPay ödeme bağlantınızı paylaşın",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Halka açık profilinizi yükseltin. Portföyünüz, Ödeme Adresinizden doğrudan erişilebilen dinamik bir vitrin olarak hizmet eder.",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "\"Profesyonel veya yaratıcı yolculuğunuz boyunca kitlenizle bağlantı kurun. Çalışmalarınız kendini sergilesin ve potansiyel müşterilere veya destekçilere daha derin bir şekilde hitap etsin.\"",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Sosyal medya erişiminizi gösterin. Ziyaretçiler, sosyal medya etkinizin portföyünüze güvenilirlik kattığını görebilirler.",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Ziyaretçilerin genel sayfanızdan doğrudan ödeme talep etmesine veya başlatmasına olanak tanıyın. Portföyünüz sadece bir sergi değil, aynı zamanda kolay finansal işlemleri kolaylaştırmak için bir yoldur.",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Benim Hakkımda\" bölümünü kullanarak hikayenizi, misyonunuzu veya vizyonunuzu paylaşın ve kitlenizle kişisel bir bağ kurun.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Benim Portföyüm\" sadece bir galeri değil; markanızı inşa etmek, kitlenizle etkileşim kurmak ve InstaPay üzerinde finansal etkileşimleri basitleştirmek için bir araçtır.",
    "No Portfolio Items": "Hiçbir Portföy Öğesi Bulunamadı",
    "Add New Portfolio Item": "Yeni Portföy Öğesi Ekle",
    "Title": "Başlık",
    "drag/drop or upload a file(photo/video/pdf)": "Bir dosyayı sürükleyin/bırakın veya yükleyin (fotoğraf/video/pdf)",
    "discard": "İptal Et",
    "save and post ": "Kaydet ve Paylaş",
    "My Payment Address": "Ödeme Adresim",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "Etkileyiciler, sanatçılar, girişimciler veya topluluklarının kolayca destek göstermesini isteyen herkes için mükemmeldir.",
    "Personal Account": "Kişisel Hesap Durumu",
    "spent in total": "Toplam Harcanan",
    "not connected": "Bağlantı Yok",
    "more": "Daha Fazla",
    "less": "Daha Az",
    "profile reports": "Profil Raporları",
    "All notifications are in English langauge": "Tüm bildirimler İngilizce",
    "timezone": "Saat Dilimi",
    "nominee information": "Nominee Bilgileri",
    "full name": "Tam İsim",
    "Relation with Nominee": "Nominee ile İlişki",
    "source": "Kaynak",
    "Google Authenticator": "Google Doğrulayıcı",
    "notifications": "Bildirimler",
    "login": "Giriş Yap",
    "View Identity Verification Guide": "Kimlik Doğrulama Kılavuzunu Görüntüle",
    "verified": "Doğrulandı",
    "select ": "Seç",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "Fotoğraflı bir devlet tarafından verilmiş kimlik belgesi olmalıdır. Tüm kimlikler Latin alfabesinde olmalıdır. Aksi takdirde, onaylı bir çeviri gereklidir.",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom, yasal yükümlülüklerine uygun olarak tam doğrulama yapabilmek için ek belgeler (Gelişmiş Müşteri Tanıma - EDD) veya herhangi bir bilgi talep etme hakkını saklı tutar.",
    "sessions": "Oturumlar",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Anında Çekimlerin Kilidini Açın: Şimdi Alıcı Hesaplarınızı Ayarlayın!",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "Ödeme almak istediğiniz yerleri kolayca yönetin; banka hesaplarınızın, mobil cüzdanlarınızın, kripto cüzdanlarınızın, ödeme kartlarınızın veya nakit teslim alma noktalarınızın detaylarını ekleyerek. İhtiyacınız olduğunda fonlarınıza anında erişimi sağlamak için aşağıdaki adımları izleyin.",
    "Select the receiving channel below and setup the details.": "Aşağıdaki alıcı kanalını seçin ve detayları ayarlayın.",
    "Please Select A Payer": "Lütfen Bir Ödeme Yapan Seçin",
    "Number": "Numara",
    "Account Holder Name": "Hesap Sahibinin Adı",
    "Province": "İl",
    "postal": "Posta Adresi",
    "delete channel": "Kanalı Sil",
    "set as default": "Varsayılan Olarak Ayarla",
    "edit": "Düzenle",
    "all channels are in english language in recieving account page": "Alıcı hesapları sayfasındaki tüm kanallar İngilizce'dir",
    "select file type": "Dosya Türünü Seçin",
    "download data": "Veriyi İndir",
    "INTERNATIONAL TRANSFER": "ULUSLARARASI TRANSFER",
    "enter the amount": "Tutarı Girin",
    "they recieve ": "Alıcı Alıyor",
    "trouble sending money": "Para Gönderme Sorunu mu?",
    "select destination country": "Hedef Ülkeyi Seçin",
    "bank transfer": "Banka Transferi",
    "recieving method": "Alım Yöntemi",
    "payer": "Ödeme Yapan",
    "The fee and the received amount may vary depending on the chosen Payment Method": "Seçilen ödeme yöntemine bağlı olarak ücretler ve alınan miktar değişebilir.",
    "search ": "Ara",
    "show more": "Daha Fazla Göster",
    "select remark reasons": "Açıklama Sebeplerini Seçin",
    "credit and debit card": "Kredi ve Banka Kartları",
    "Transfer Fees": "Transfer Ücretleri",
    "Estimated Time": "Tahmini Süre:",
    "within minutes": "Dakikalar İçinde",
    "total ": "Toplam",
    "OTP verifications code": "Tek Kullanımlık Şifre (OTP) Doğrulama Kodu",
    "verify using sms/email": "SMS/E-posta ile Doğrula",
    "sms/email verification code": "SMS/E-posta Doğrulama Kodu",
    "Verify Using Authenticator": "Doğrulayıcı Kullanarak Doğrula",
    "WALLET TO WALLET TRANSFER": "CUZDANLAR ARASI TRANSFER",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Alıcının Cüzdan Kimliği, Kullanıcı Adı, E-posta Adresi veya Telefon Numarasını girin.",
    "search": "Ara",
    "lookup": "Sorgula",
    "Recipient Not on InstaPay?": "Alıcı InstaPay'de değil mi?",
    "invite them": "Onları davet edin",
    "Please follow these examples": "Lütfen şu örnekleri takip edin:",
    "Wallet ID: GY68J782": "Cüzdan Kimliği: GY68J782",
    "InstaPay/Instagram Username": "InstaPay/Instagram Kullanıcı Adı: instauser",
    "Email: user@email.com": "E-posta: user@email.com",
    "With Country code": "Telefon Numarası (Ülke Kodu ile birlikte)",
    "invite friend": "Arkadaşı Davet Et",
    "invite via phone number": "Telefon Numarası Yoluyla Davet Et",
    "invite via email address": "E-posta Adresi Yoluyla Davet Et",
    "next": "İleri",
    "back": "Geri",
    "Personalized Message": "Kişiselleştirilmiş Mesaj",
    "available currency": "Kullanılabilir Para Birimi",
    "attach files": "Dosyaları Ekle",
    "total amount": "Toplam Tutar",
    "instant payment": "Anında Ödeme",
    "starting from": "Başlangıç",
    "recurring cycle": "Yinelenen Döngü",
    "until I stop": "Durdurulana Kadar",
    "start from": "Başlangıç",
    "cycle": "Döngü",
    "total": "Toplam",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "Alıcı, döviz kurlarına bağlı olarak hafifçe ayarlanmış bir miktar alabilir.",
    "schedule to": "Planlanan",
    "schedule at": "Planlanmış Saat",
    "Scheduled Date": "Planlanmış Tarih",
    "Scheduled time": "Planlanmış Saat",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "Alıcı, döviz kurlarındaki değişikliklere bağlı olarak hafifçe ayarlanmış bir miktar alabilir.",
    "QR CODE PAYMENT ": "QR KODU ÖDEMESİ",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "Alternatif olarak, InstaPay Alfanümerik QR Kodunu kullanın.",
    "PYAYMENT REQUEST": "ÖDEME TALEBİ",
    "choose the beneficiary": "Yararlanıcıyı Seçin",
    "trouble requesting money": "Para Talep Etmede Sorun mu?",
    "Standard Request": "Standart Talep",
    "Subscription Request": "Yinelenen Talep",
    "Schedule Request": "Planlı Talep",
    "Security Request": "Güvenlik Depozito Talebi",
    "Instalment Request": "Taksit Planı",
    "Split Request": "Bölünmüş Ödeme",
    "You recieved": "Aldığınız Miktar",
    "is this your location?": "Bu sizin konumunuz mu?",
    "yes": "Evet",
    "Authenticator Code": "Her şeyin doğru olduğundan emin olmak için uygulamada gösterilen kodu girin.",
    "Enter a code shown in the app to make sure everything works fine.": "Uygulamada gösterilen kodu girin, her şeyin doğru olduğundan emin olun.",
    "SEND A QUOTE": "TEKLİF GÖNDER",
    "Trouble Quoting Money?": "Teklif Gönderirken Sorun mu Yaşıyorsunuz?",
    "Please follow these examples:": "Lütfen bu örnekleri takip edin:",
    "Full Name: Muhammad Imtiaz": "Tam Ad: Muhammad Imtiaz",
    "InstaPay/Intagram Username: instauser": "InstaPay/Instagram Kullanıcı Adı: instauser",
    "Phone Number: 44795396600 (With Country Code)": "Telefon Numarası: 44795396600 (Ülke Kodu ile birlikte)",
    "SEND TOP-UP AND E-SIM": "KONTÖR GÖNDERME VE E-SIM",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "Dünya genelinde bağlantıda kalın! InstaPay'in Kontör Yükleme hizmeti, telefonları anında şarj etmenizi, eSIM satın almanızı, markalı kuponlar, hediye kartları almanızı ve 150'den fazla ülkede fatura ödemenizi sağlar. Hediyeler için ideal veya hazırlıklı kalmak için, hizmetimiz kolaylık, hız ve güvenliği öncelik olarak alır.",
    "MOBILE AIRTIME AND E-SIM": "MOBİL KONTÖR VE E-SIM",
    "Select the country where you want to top-up a mobile phone!": "Mobil kontör yüklemek için ülkeyi seçin!",
    "Other Operator": "Diğer Operatörler",
    "airtime": "Kontör",
    "bundle": "Paket",
    "data": "Veri",
    "Enter custom amount": "Özel Tutar Girin",
    "Credit & Debit Card": "Kredi ve Banka Kartı",
    "Bank Transfer": "Banka Transferi",
    "Mobile Wallet": "Mobil Cüzdan",
    "InstaPay Wallet": "InstaPay Cüzdanı",
    "PayPal": "PayPal",
    "add card payment": "Kart Ödemesi Ekle",
    "save details to use next time.": "Gelecekte Kullanmak İçin Detayları Kaydet",
    "Recipient Mobile Number": "Alıcının Mobil Numarası",
    "Amount": "Tutar",
    "Service": "Hizmet",
    "Destination": "Hedef",
    "Provider": "Sağlayıcı",
    "Airtime": "Kontör",
    "Pakistan": "Pakistan",
    "Telenor Pakistan": "Telenor Pakistan",
    "Within minutes": "Dakikalar İçinde",
    "top-up summary": "Kontör Özeti",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PayPal tarafından PKR desteklenmemektedir, varsayılan olarak USD kullanılacaktır.",
    "Amount in USD": "Tutar (USD)",
    "Send Top-up": "Kontör Gönder",
    "available sunservices": "Mevcut Güneş Hizmetleri",
    "login with email": "E-posta ile Giriş Yap",
    "Already Have an Account?": "Zaten Bir Hesabınız Var mı? Giriş Yapın",
    "profile picture": "Profil Fotoğrafı",
    "add photo/logo": "Fotoğraf/Logo Ekle",
    "first name": "Ad",
    "enter your first name": "Adınızı Girin",
    "last name": "Soyad",
    "enter your last name": "Soyadınızı Girin",
    "email(optional)": "E-posta (İsteğe Bağlı)",
    "enter your email address": "E-posta Adresinizi Girin",
    "password must": "Şifre Gereksinimi",
    "It will be autofill, if you're signing up with invitation link": "Davet Kaydı İçin Otomatik Doldur",
    "change number": "Numarayı Değiştir",
    "signup error": "Kayıt Hatası",
    "Something went wrong while sending sms!": "SMS gönderilirken bir hata oluştu! Lütfen daha sonra tekrar deneyin.",
    "Errors": "Hatalar meydana geldi. Lütfen detaylar için aşağıdaki listeye bakın.",
    "Generic error": "Genel Hata",
    "Trust Payments API requires the 'requests' library": "Trust Payments API'nin düzgün çalışması için 'requests' kütüphanesi gereklidir.",
    "Send error": "Gönderim sırasında hata oluştu. Lütfen tekrar deneyin.",
    "Receive error": "Alım sırasında hata oluştu. Lütfen tekrar deneyin.",
    "Invalid credentials provided": "Geçersiz kimlik bilgileri. Lütfen doğrulayın ve tekrar deneyin.",
    "An issue occurred whilst trying to connect to Trust Payments servers": "Trust Payments sunucularına bağlanırken bir sorun oluştu. Lütfen bağlantınızı kontrol edin ve tekrar deneyin.",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "Trust Payments sunucularına bağlanırken beklenmeyen bir hata oluştu. Bu sorun devam ederse, support@trustpayments.com ile iletişime geçin.",
    "Unknown error. If this persists please contact Trust Payments": "Bilinmeyen hata. Sorun devam ederse, lütfen Trust Payments desteği ile iletişime geçin.",
    "Incorrect usage of the Trust Payments API": "Trust Payments API'sinin yanlış kullanımı. Lütfen talebi doğrulayın ve tekrar deneyin.",
    "Invalid card details": "Geçersiz kart bilgileri. Lütfen kontrol edin ve doğru bilgileri girin.",
    "Invalid date": "Geçersiz tarih sağlandı.",
    "Invalid date/time": "Geçersiz tarih/saat formatı. Lütfen doğru formatı sağlayın.",
    "Invalid details": "Geçersiz bilgiler. Lütfen doğrulayın ve doğru bilgileri girin.",
    "Card number does not match card type": "Kart numarası belirtilen kart türüyle eşleşmiyor.",
    "Malformed XML": "XML biçimlendirme hatası. Lütfen XML'i doğrulayın ve düzeltin.",
    "XML does not match schema": "XML beklenen şemaya uygun değil.",
    "Invalid file format": "Geçersiz dosya formatı. Lütfen desteklenen bir format kullanın.",
    "Empty file contents": "Boş dosya içeriği. Lütfen geçerli içerik sağlayın.",
    "Invalid file contents": "Geçersiz dosya içeriği. Lütfen dosyayı doğrulayın ve tekrar deneyin.",
    "Malformed JSON": "Bozuk JSON. Lütfen JSON formatını düzeltin.",
    "StApi Error": "StApi Hatası",
    "Invalid fields specified in request": "Talepte belirtilen geçersiz alanlar. Lütfen doğrulayın ve düzeltin.",
    "Missing parent": "Eksik ebeveyn bilgisi.",
    "Refund requires settled parent or parent that's due to settle today": "İade, bugün yerleşmesi gereken veya yerleşmiş bir ebeveyn işlem gerektirir.",
    "Refund requires authorisation parent": "İade, bir yetkilendirme ebeveyn işlemi gerektirir.",
    "Refund amount too great": "İade tutarı izin verilen limiti aşıyor.",
    "No acquirer specified": "Edinici bilgisi sağlanmadı.",
    "Repeat amount too great": "Tekrarlanan tutar izin verilen limiti aşıyor.",
    "Split amount too great": "Bölünmüş tutar izin verilen limiti aşıyor.",
    "Cannot refund a declined transaction": "Reddedilmiş bir işlem iade edilemez.",
    "Refund requires a settled parent": "İade, yerleşmiş bir ebeveyn işlem gerektirir.",
    "Reversal requires a cancelled auth parent": "Geri alma, iptal edilmiş bir yetkilendirme işlemi gerektirir.",
    "Cannot override amount in child transaction": "Çocuk işlemlerinde tutar değiştirilemez.",
    "Cannot override currency in child transaction": "Çocuk işlemlerinde para birimi değiştirilemez.",
    "Subscription requires RECUR account": "Abonelik, yinelenen bir hesap kurulumu gerektirir.",
    "Subscription requires successful parent": "Abonelik, başarılı bir ebeveyn işlem gerektirir.",
    "Risk Decisions must have AUTH as parent": "Risk kararları, bir yetkilendirme işlemine bağlı olmalıdır.",
    "Chargebacks must have AUTH/REFUND as parent": "Geri ödemeler, bir yetkilendirme veya iade işlemine bağlanmalıdır.",
    "Refund amount less than Minimum allowed": "İade tutarı izin verilen minimum değerden az.",
    "Refund requires paypaltransactionid": "İade, bir PayPal işlem kimliği gerektirir.",
    "Invalid split transaction": "Geçersiz bölünmüş işlem.",
    "Cannot reverse AUTH processed more than 48 hours ago": "48 saatten daha önce işlenmiş bir yetkilendirme geri alınamaz.",
    "Reversal requires acquirerreferencedata": "Geri alma, edinici referans verilerini gerektirir.",
    "Cannot reverse AUTH processed by a different acquirer": "Farklı bir edinici tarafından işlenen bir yetkilendirme geri alınamaz.",
    "Payment type does not support repeats": "Ödeme türü tekrar eden işlemleri desteklemez.",
    "Reversal missing required data": "Geri alma için eksik veri.",
    "Missing token": "Eksik token bilgisi.",
    "Subscription with an accountcheck parent not supported on current acquirer": "Bir hesap kontrol ebeveyni ile abonelik, mevcut edinici tarafından desteklenmez.",
    "Subscription cannot be used as a parent": "Abonelik, bir ebeveyn işlem olarak kullanılamaz.",
    "Invalid parent": "Geçersiz ebeveyn işlem türü.",
    "Payment type does not support refunds": "Ödeme türü iade desteklemiyor.",
    "Invalid incremental transaction": "Geçersiz artımlı işlem.",
    "Partial reversals not supported": "Kısmi geri almalar desteklenmez.",
    "THREEDQUERY parent/child must have the same payment type as the child": "Ebeveyn ve çocuk işlemler aynı 3D güvenli sorgular için ödeme türüne sahip olmalıdır.",
    "Payment type does not support card scheme updates": "Ödeme türü kart şeması güncellemelerini desteklemez.",
    "Cannot reverse AUTH at this time, please try again": "Bu anda yetkilendirme geri alınamaz. Lütfen daha sonra tekrar deneyin.",
    "Cannot determine token": "Token belirlenemiyor. Lütfen doğrulayın ve tekrar deneyin.",
    "Service Temporarily Disabled": "Hizmet geçici olarak devre dışı. Lütfen daha sonra tekrar deneyin.",
    "Login first request": "Talepte bulunmadan önce oturum açılması gerekir.",
    "Invalid username/password": "Geçersiz kullanıcı adı veya şifre. Lütfen tekrar deneyin.",
    "Invalid session": "Geçersiz oturum. Lütfen tekrar giriş yapın.",
    "Session has expired": "Oturum süresi doldu. Lütfen tekrar giriş yapın.",
    "Password expired": "Şifrenizin süresi doldu. Lütfen şifrenizi sıfırlayın.",
    "Password has been previously used": "Şifre daha önce kullanılmış. Lütfen yeni bir şifre seçin.",
    "MyST user account has been locked": "MyST kullanıcı hesabı kilitlendi. Lütfen destekle iletişime geçin.",
    "New password does not match confirmed password": "Yeni şifre onaylanan şifre ile uyuşmuyor.",
    "Incorrect current password": "Mevcut şifre yanlış. Lütfen tekrar deneyin.",
    "Invalid selection": "Geçersiz seçim yapıldı. Lütfen geçerli bir seçenek seçin.",
    "User already exists": "Kullanıcı zaten mevcut. Lütfen giriş yapın veya şifreyi sıfırlayın.",
    "No transaction found": "İşlem bulunamadı.",
    "Invalid selected transactions": "Geçersiz seçili işlemler.",
    "Data supplied has not been saved": "Veriler kaydedilmedi. Lütfen tekrar deneyin.",
    "Invalid request type": "Geçersiz istek türü.",
    "Missing request type, at least one request type must be selected": "Eksik istek türü. En az biri seçilmelidir.",
    "Invalid payment type": "Geçersiz ödeme türü.",
    "Missing payment type, at least one payment type must be selected": "Eksik ödeme türü. En az biri seçilmelidir.",
    "Invalid error code": "Geçersiz hata kodu.",
    "Missing error code, at least one error code must be selected": "Eksik hata kodu. En az biri seçilmelidir.",
    "Invalid filter description": "Geçersiz filtre açıklaması.",
    "Invalid destination description": "Geçersiz hedef açıklaması.",
    "Invalid notification type": "Geçersiz bildirim türü.",
    "Invalid destination": "Geçersiz hedef.",
    "Invalid field selected": "Geçersiz alan seçildi.",
    "Invalid email from address": "Gönderici için geçersiz e-posta adresi.",
    "Invalid email subject": "Geçersiz e-posta konu satırı.",
    "Invalid email type": "Geçersiz e-posta türü.",
    "Unable to process request": "İstek işlenemedi. Lütfen daha sonra tekrar deneyin.",
    "No file selected for upload": "Yüklemek için dosya seçilmedi.",
    "Invalid file size": "Geçersiz dosya boyutu. Kabul edilebilir boyutta bir dosya sağlayın.",
    "Invalid filename": "Geçersiz dosya adı.",
    "Invalid extension": "Geçersiz dosya uzantısı.",
    "User requires at least one site reference": "Kullanıcı en az bir site referansına sahip olmalıdır.",
    "Only ST-level users can have '*' access": "Sadece ST seviyesindeki kullanıcılar '*' erişim haklarına sahip olabilir.",
    "Request failed": "İstek başarısız oldu. Lütfen daha sonra tekrar deneyin.",
    "Invalid File Contents": "Geçersiz dosya içeriği.",
    "Maximum number of files uploaded": "Yüklenen maksimum dosya sayısı. Lütfen bazı dosyaları silin ve tekrar deneyin.",
    "Insufficient gateway access privileges": "Yetersiz ağ geçidi erişim ayrıcalıkları.",
    "Maximum file size limit reached": "Maksimum dosya boyutu limiti aşıldı.",
    "Username(s) must be a valid user(s)": "Kullanıcı adı geçerli kullanıcılar olmalıdır.",
    "Site reference(s) must be a valid site(s)": "Site referansları geçerli siteler olmalıdır.",
    "Unable to send email, please verify the details and try again": "E-posta gönderilemiyor. Lütfen detayları doğrulayın ve tekrar deneyin.",
    "Negative already exists": "Negatif bir değer zaten mevcut. Lütfen doğrulayın ve düzeltmeler yapın.",
    "Cannot delete a search owned by another user": "Başka bir kullanıcının sahip olduğu arama silinemiyor.",
    "Invalid search": "Geçersiz arama. Lütfen geçerli kriterlerle tekrar deneyin.",
    "Cannot delete the specified search, the search name cannot be found": "Belirtilen arama silinemiyor. Arama adı bulunamadı.",
    "Search parameter is too short": "Arama parametresi çok kısa. Lütfen daha spesifik arama terimleri girin.",
    "Duplicate custom fields defined": "Çift özel alanlar tanımlandı. Lütfen tekrarları çözün.",
    "Cannot allocate selected users, insufficient privileges": "Seçili kullanıcılar atanamıyor. Yetersiz ayrıcalıklar.",
    "Allocated users have access to additional sites": "Atanan kullanıcılar ek sitelere erişime sahiptir.",
    "Allocated users have access to additional users": "Atanan kullanıcılar ek kullanıcılara erişime sahiptir.",
    "User with current role cannot be allocated users": "Mevcut rolü olan kullanıcılar başka kullanıcıları atayamaz.",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Bu site, tarayıcınızın çerezleri kabul etmesini gerektirir. Çerezleri kabul etmek için aşağıdaki 'Kabul Ediyorum' seçeneğine tıklayın.",
    "User requires at least one site reference or site group": "Kullanıcı en az bir site referansına veya site grubuna sahip olmalıdır.",
    "Allocated users have access to additional site groups": "Atanan kullanıcılar ek site gruplarına erişime sahiptir.",
    "No statement found": "Bildirim bulunamadı.",
    "Data supplied has not been updated in MobilePay 3rd-party service": "MobilePay üçüncü taraf hizmetinde sağlanan veriler güncellenmedi.",
    "Bypass": "Baypas",
    "Insufficient access privileges": "Yetersiz erişim ayrıcalıkları.",
    "Coding error": "Kodlama hatası algılandı.",
    "Insufficient privileges": "Bu işlemi tamamlamak için yetersiz ayrıcalıklar.",
    "Invalid request": "Geçersiz istek. Lütfen doğrulayın ve tekrar deneyin.",
    "Invalid field": "Geçersiz alan girildi. Lütfen doğrulayın ve girişinizi düzeltin.",
    "Unknown site": "Bilinmeyen site.",
    "Banned card": "Yasaklı kart.",
    "XML element parse error": "XML öğesi ayrıştırma hatası. Lütfen XML yapısını doğrulayın.",
    "Maestro must use SecureCode": "Maestro işlemleri doğrulama için SecureCode kullanmalıdır.",
    "Multiple email addresses must be separated with , or ;": "Birden fazla e-posta adresi virgül (,) veya noktalı virgül (;) ile ayrılmalıdır.",
    "Invalid site reference for alias": "Takma ad için geçersiz site referansı.",
    "Invalid version number": "Geçersiz sürüm numarası. Lütfen geçerli bir sürüm numarası girin.",
    "Unknown user": "Bilinmeyen kullanıcı. Lütfen doğrulayın ve tekrar deneyin.",
    "Cannot determine account": "Hesap belirlenemiyor.",
    "JSON element parse error": "JSON öğesi ayrıştırma hatası. Lütfen JSON yapısını doğrulayın.",
    "Wallet type configuration error": "Cüzdan türü yapılandırma hatası.",
    "Wallet type not supported on this request": "Bu istek için desteklenmeyen cüzdan türü.",
    "The card number you have provided is incorrect, please verify your details and try again": "Sağlanan kart numarası yanlış. Lütfen bilgilerinizi doğrulayın ve tekrar deneyin.",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "Sağladığınız güvenlik kodu (CVV2) yanlış. Lütfen doğrulayın ve tekrar deneyin.",
    "The expiry date you have provided is incorrect, please verify your details and try again": "Sağlanan son kullanma tarihi yanlış. Lütfen bilgilerinizi doğrulayın ve tekrar deneyin.",
    "The expiry month you have provided is incorrect, please verify your details and try again": "Sağlanan son kullanma ayı yanlış. Lütfen doğrulayın ve tekrar deneyin.",
    "The expiry year you have provided is incorrect, please verify your details and try again": "Sağlanan son kullanma yılı yanlış. Lütfen doğrulayın ve tekrar deneyin.",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "Bağlantı hataları nedeniyle ödemeniz işlenemiyor. Talep Kimliği uyuşmazlığı. Lütfen tekrar deneyin.",
    "The issue number you have provided is incorrect, please verify your details and try again": "Sağlanan sayı hatalı. Lütfen doğrulayın ve tekrar deneyin.",
    "The payment type you have provided is incorrect, please verify your details and try again": "Sağlanan ödeme türü yanlış. Lütfen doğrulayın ve tekrar deneyin.",
    "Unable to process your payment, please contact the website": "Ödemeniz işlenemiyor. Lütfen destek almak için web sitesiyle iletişime geçin.",
    "There are errors with these fields: {0}": "Bu alanlarda hatalar var: {0}",
    "No account found": "Hesap bulunamadı.",
    "Refund cannot be processed": "İade işlenemiyor.",
    "Transaction de-activated": "İşlem devre dışı bırakıldı.",
    "Socket receive error": "Soket alım hatası.",
    "Socket connection error": "Soket bağlantı hatası.",
    "Socket closed": "Soket kapalı.",
    "Invalid data received": "Geçersiz veri alındı.",
    "Invalid SQL": "Geçersiz SQL sorgusu.",
    "Timeout": "Zaman aşımı meydana geldi.",
    "Invalid acquirer": "Geçersiz edinici bilgileri.",
    "Unable to connect to acquirer": "Ediniciye bağlanılamıyor.",
    "Invalid response from acquirer": "Ediniciden geçersiz yanıt alındı.",
    "No available transport": "Uygun taşıma bulunamadı.",
    "File size too large": "Dosya boyutu çok büyük.",
    "Socket send error": "Soket gönderim hatası.",
    "Communication error": "İletişim hatası.",
    "Proxy error": "Proxy hatası.",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "Bağlantı hataları nedeniyle ödemeniz işlenemiyor. Lütfen bilgilerinizi doğrulayın ve tekrar deneyin ({0}).",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "Bağlantı hataları nedeniyle ödemeniz işlenemiyor (HTTP yanıt durumu {0}). Lütfen bilgilerinizi doğrulayın ve tekrar deneyin ({1}).",
    "Wrong number of emails": "Sağlanan e-posta adreslerinin sayısı yanlış.",
    "Bank System Error": "Banka sistemi hatası.",
    "Wrong number of transactions": "Sağlanan işlem sayısı yanlış.",
    "Invalid transport configuration": "Geçersiz taşıma yapılandırması.",
    "No valid updates specified": "Geçerli güncellemeler belirtilmedi.",
    "Transaction reference not found": "İşlem referansı bulunamadı.",
    "Settlebaseamount too large": "Temel miktar çok büyük.",
    "Transaction not updatable": "İşlem güncellenemez.",
    "No searchable filter specified": "Aranabilir filtre belirtilmedi.",
    "Timeout Error": "Zaman aşımı hatası. Lütfen tekrar deneyin.",
    "3-D Secure Transport Error": "3-D Güvenli taşıma hatası.",
    "Unauthenticated": "Kimliği doğrulanmamış istek.",
    "Site Suspended": "Site askıya alındı.",
    "No updates performed": "Güncellemeler yapılmadı.",
    "Invalid Request": "Geçersiz istek.",
    "Invalid Response": "Geçersiz yanıt.",
    "Invalid Acquirer": "Geçersiz edinici bilgisi.",
    "Invalid account data": "Geçersiz hesap verisi.",
    "Missing": "Eksik bilgi.",
    "Payment Error": "Ödeme hatası.",
    "Invalid acquirer for 3-D Secure": "3-D Güvenlik için geçersiz edinici.",
    "Invalid payment type for 3-D Secure": "3-D Güvenlik için geçersiz ödeme türü.",
    "Invalid updates specified": "Geçersiz güncellemeler belirtildi.",
    "Manual investigation required": "Manuel inceleme gereklidir.",
    "Invalid headers": "Geçersiz başlıklar.",
    "Max fraudscore exceeded": "Maksimum dolandırıcılık puanı aşıldı.",
    "Invalid filters": "Geçersiz filtreler sağlandı.",
    "Merchant System Error": "Ticaret sistemi hatası.",
    "Your payment is being processed. Please wait...": "Ödemeniz işleniyor. Lütfen bekleyin...",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "Aynı istekte 'istek türü açıklaması' ve 'istek türü açıklamaları' belirtilemez.",
    "Acquirer missing original transaction data": "Edinici orijinal işlem verilerini eksik.",
    "Insufficient funds": "Yetersiz bakiye.",
    "Unable to process due to scheme restrictions": "Şema kısıtlamaları nedeniyle işlenemiyor.",
    "Failed Screening": "Tarama başarısız oldu.",
    "Unable to process due to restrictions": "Kısıtlamalar nedeniyle işlenemiyor.",
    "Invalid process": "Geçersiz işlem.",
    "Risk Referral": "Risk yönlendirmesi gerekli.",
    "Name Pick required": "Seçim için isim gerekli.",
    "Address Pick required": "Seçim için adres gerekli.",
    "IP not in range": "IP adresi aralıkta değil.",
    "Invalid button configuration": "Geçersiz düğme yapılandırması.",
    "Unrecognised response from acquirer": "Ediniciden tanınmayan yanıt.",
    "Decline": "Reddedildi.",
    "Uncertain result": "Belirsiz sonuç.",
    "Soft Decline": "Yumuşak red.",
    "Refer to Issuer": "Daha fazla bilgi için kredi kartı veren kurum ile iletişime geçin.",
    "Request is queued please check the transaction later for the status": "İstek sıraya alındı. Lütfen işlem durumunu daha sonra kontrol edin.",
    "Generic Retry": "Genel yeniden deneme.",
    "Soft Decline retry": "Yumuşak red yeniden deneme.",
    "There has been a problem with your payment, please verify your details and try again": "Ödemenizde bir sorun oluştu. Lütfen bilgilerinizi doğrulayın ve tekrar deneyin.",
    "Unknown error": "Bilinmeyen hata.",

    "Login": "Giriş yap",
    "Enter Your Email": "E-posta adresinizi girin",
    "Login With Phone Number": "Telefon numarasıyla giriş yap",
    "Verify Using Sms/Email": "SMS/E-posta ile Doğrulama",
    "Resend Code In 100 Seconds": "Kodu 100 saniye içinde yeniden gönder",
    "Verify": "Doğrula",
    "Verifying": "Doğrulama yapılıyor",
    "Last Week": "Geçen hafta",
    "Older Transaction": "Eski işlem",
    "Total": "Toplam",
    "Conversion Confirmed": "Dönüşüm onaylandı",
    "User Account Limit": "Kullanıcı hesap limiti",
    "Monthly Balance Limit": "Aylık bakiye limiti",
    "Total Limit": "Toplam limit",
    "Used": "Kullanıldı",
    "Remaining": "Kalan",
    "Allowed Minimum Topup Amount": "İzin verilen minimum bakiye yükleme miktarı",
    "Allowed Maximum Topup Amount": "İzin verilen maksimum bakiye yükleme miktarı",
    "Incoming Limit": "Gelen limit",
    "Daily limit": "Günlük Limit",
    "Monthly limit": "Aylık Limit",
    "Yearly limit": "Yıllık Limit",
    "Sending Limit": "Gönderim limiti",
    "Allowed Number Of Transactions": "İzin verilen işlem sayısı",
    "Total Amount": "Toplam Tutar",
    "Amount To Send": "Gönderilecek Miktar",
    "Destination Amount": "Hedef Miktar",
    "Note": "Not",
    "Revised Amount": "Düzeltilmiş Miktar",
    "Save": "Kaydet",
    "Date": "Tarih",
    "Action": "İşlem",
    "Payment Scheduled At": "Ödeme Zamanı",
    "Transaction Fee": "İşlem Ücreti",
    "Number Of Cycle": "Döngü Sayısı",
    "Subscription Starting Date": "Abonelik Başlangıç Tarihi",
    "Until": "Kadar",
    "Next Transaction": "Sonraki İşlem",
    "Pay Now": "Şimdi Öde",
    "Transaction Method": "İşlem Yöntemi",
    "Mobile Money Provider": "Mobil Para Sağlayıcısı",
    "Wallet Name": "Cüzdan Adı",
    "Wallet Number": "Cüzdan Numarası",
    "Crypto Currency": "Kripto Para",
    "Wallet Address": "Cüzdan Adresi",
    "Select Document Type": "Belge Türünü Seçin",
    "Edit Document Number": "Belge Numarasını Düzenle",
    "My Withdrawals": "Çekimlerim",
    "Bargainable": "Pazarlık Yapılabilir",
    "Description": "Açıklama",
    "Attached Files": "Ekli Dosyalar",
    "Sent": "Gönderildi",
    "Accepted": "Kabul Edildi",
    "Decline": "Reddedildi",
    "Pending": "Beklemede",
    "Overall Rating": "Genel Değerlendirme",
    "No Review": "Yorum Yok",
    "0 Rating": "0 Değerlendirme",
    "Transaction Map": "İşlem Haritası",
    "Send Money": "Para Gönder",
    "Sending Method": "Gönderim Yöntemi",
    "Trouble Sending Money": "Para Gönderme Sorunu",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Ücret ve alınan tutar, seçilen ödeme yöntemine bağlı olarak değişebilir.",
    "Select Method": "Yöntem Seçin",
    "Completed": "Tamamlandı",
    "How Was Your Experience?": "Deneyiminiz nasıldı?",
    "Leave A Review": "Yorum bırak",
    "Review History": "Yorum Geçmişi",
    "Write A Review": "Yorum Yaz",
    "Send And Post": "Gönder ve Yayınla",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Kripto'dan Fiat'a Dönüşüm, kripto paraları fiat para birimlerine sorunsuz bir şekilde dönüştüren güçlü ve güvenli bir çözümdür. Kullanıcıların çeşitli ihtiyaçlarına hitap eder, bireysel tercihlere ve konumlara göre özelleştirilmiş birçok ödeme kanalı sunar.\"",
    "Review Created": "İnceleme Oluşturuldu",
    "Transactions": "İşlemler",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Sürükle/Bırak veya Dosya Yükle (Foto/Video/Pdf)",
    "Max Size 1GB": "Maksimum Boyut 1GB",
    "Title Description": "Başlık Açıklaması",
    "Add New": "Yeni Ekle",
    "Discard": "Sil",
    "Save And Post": "Gönder ve Yayınla",
    "Personal Account": "Kişisel Hesap",
    "Conversion": "Dönüşüm",
    "Spent In Total": "Toplam Harcanan",
    "International": "Uluslararası",
    "Recharge": "Yeniden Yükle",
    "Top Up Wallet To Wallet": "Cüzdandan Cüzdana Yükleme",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Cüzdan",
    "Not Connected": "Bağlanmadı",
    "More": "Daha Fazla",
    "Less": "Daha Az",
    "Profile Reports": "Profil Raporları",
    "Timezone": "Zaman Dilimi",
    "Cancel": "İptal Et",
    "Full Name": "Tam Ad",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Ek Belgeler",
    "Profile Complete": "Profil Tamamlandı",
    "Verified": "Doğrulandı",
    "Bank Account": "Banka Hesabı",
    "Mobile Wallet": "Mobil Cüzdan",
    "Cash Pickup": "Nakit Teslimat",
    "Add": "Ekle",
    "Configured": "Yapılandırılmış",
    "Email": "E-posta",
    "Download Data": "Veri İndir",
    "Enter The Amount": "Tutarı Girin",
    "They Receive": "Aldıkları Tutar",
    "Where Do You Want To Send Money": "Parayı Nereye Göndermek İstiyorsunuz",
    "Bank Transfer": "Banka Transferi",
    "Receiving Method": "Alım Yöntemi",
    "Payer": "Ödeyen",
    "Minimum Amount": "Minimum Tutar",
    "Maximum Amount": "Maksimum Tutar",
    "Search": "Ara",
    "Show More": "Daha Fazla Göster",
    "Select Remark / Reasons (All)": "Açıklama / Sebep Seçin (Tümü)",
    "Attach Files": "Dosya Ekle",
    "Holiday List": "Tatil Listesi",
    "No Data To Show": "Gösterilecek Veri Yok",
    "Threshold Limits": "Eşik Limitleri",
    "Amount Limits": "Tutar Limitleri",
    "OTP Verification Code": "OTP Doğrulama Kodu",
    "Verify Using Authenticator": "Authenticator ile Doğrulama",
    "Sms/Email Verification Code": "SMS/E-posta Doğrulama Kodu",
    "Invite Them": "Onları Davet Et",
    "Lookup": "Ara",
    "Invite Friend": "Arkadaşını Davet Et",
    "Invite Via Phone Number": "Telefon Numarasıyla Davet Et",
    "Invite Via Email Address": "E-posta Adresiyle Davet Et",
    "Next": "Sonraki",
    "Back": "Geri",
    "Invite Via Email": "E-posta ile Davet Et",
    "Available Currency": "Mevcut Para Birimi",
    "Add Remarks And Comments": "Açıklama ve Yorum Ekleyin",
    "Recipient Details": "Alıcı Detayları",
    "Go Back": "Geri Git",
    "Trouble Requesting Money": "Para Talep Etme Sorunu",
    "Instant": "Anında",
    "Subscription": "Abonelik",
    "Schedule": "Takvim",
    "Choose Request Type": "Talep Türünü Seçin",
    "Mobile Airtime": "Mobil Kontör",
    "Incorrect Phone Number": "Hatalı Telefon Numarası",
    "Make Sure You Have Entered A Correct Phone Number.": "Doğru Telefon Numarası Girdiğinizden Emin Olun.",
    "Close": "Kapat",
    "Operator": "Operatör",
    "Recipient Mobile Number": "Alıcının Telefon Numarası",
    "Amount": "Tutar",
    "Service": "Hizmet",
    "Destination": "Hedef",
    "Top Up Summary": "Top Up Özeti",
    "Pakistan": "Pakistan",
    "Daily Sending Limit Exceeded": "Günlük Gönderim Limiti Aşıldı",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Müşterinize veya alıcınıza detaylı bir fiyat teklifi gönderin. Hizmetinizi, ürünlerinizi veya tekliflerinizi profesyonelce kişiselleştirilmiş bir teklif ile sunun.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Hafta içi saat 15:00'ten önce yapılan ödemeler, aynı günün gece yarısına kadar alıcı banka hesabına yatırılacaktır.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Üç ülkeye kadar ödeme alma hesaplarınızı ayarlayın ve onaylı ödeme taleplerinden fonları hemen etkinleştirilmiş ödeme kanallarınıza çekin.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Krallığı, Hukuki Yükümlülüklerine Uygun Olarak, Tamamlayıcı Doğrulamalar Yapabilmek İçin Gerekli Herhangi Bir Ekstra Belge (Gelişmiş Müşteri Tanıma - EDD) Veya Ek Bilgi Talep Etme Hakkını Saklı Tutar.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Bu, Fotoğraf İçeren Hükümet Tarafından Verilen Bir Kimlik Belgesi Olmalıdır. Tüm Kimlik Belgeleri Latin Alfabesiyle Yazılmalıdır. Aksi Takdirde, Sertifikalı Bir Tercüme Gereklidir.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "İşinizi Sergileyin, Erişiminizi Artırın. 'Portföyüm', Instapay'deki Dijital Kanvasınızdır. Çeşitli İçerikleri Yükleyin ve Sergileyin - Etkileyici Resimler ve Videolardan, Bilgilendirici PDF'lere Kadar. İster Sanatçı, Serbest Çalışan, İster Girişimci Olun, Bu Özellik, Çalışmalarınızı veya Projelerinizi Görsel Olarak Çekici Bir Şekilde Sunmanıza İmkan Tanır.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "QR Kodunuzu veya Ödeme Bağlantınızı Paylaşarak Insta Cüzdanınızda Hızla Ödeme Alın.",
    "Afghanistan": "Afganistan",
    "Aland Islands": "Åland Adaları",
    "Albania": "Arnavutluk",
    "Algeria": "Cezayir",
    "American Samoa": "Amerikan Samoası",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antigua and Barbuda": "Antigua ve Barbuda",
    "Argentina": "Arjantin",
    "Armenia": "Ermenistan",
    "Aruba": "Aruba",
    "Australia": "Avustralya",
    "Austria": "Avusturya",
    "Azerbaijan": "Azerbaycan",
    "Bahamas": "Bahamalar",
    "Bahrain": "Bahreyn",
    "Bangladesh": "Bangladeş",
    "Barbados": "Barbados",
    "Belarus": "Beyaz Rusya",
    "Belgium": "Belçika",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Butan",
    "Bolivia (Plurinational State of)": "Bolivya",
    "Bonaire - Sint Eustatius and Saba": "Karayip Hollanda",
    "Bosnia and Herzegovina": "Bosna Hersek",
    "Botswana": "Botsvana",
    "Brazil": "Brezilya",
    "British Indian Ocean Territory": "Britanya Hint Okyanusu Toprağı",
    "Brunei Darussalam": "Brunei",
    "Bulgaria": "Bulgaristan",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Yeşil Burun Adaları",
    "Cambodia": "Kamboçya",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cayman Islands": "Cayman Adaları",
    "Central African Republic": "Orta Afrika Cumhuriyeti",
    "Chad": "Çad",
    "Chile": "Şili",
    "China": "Çin",
    "Christmas Island": "Christmas Adası",
    "Cocos (Keeling) Islands": "Cocos (Keeling) Adaları",
    "Colombia": "Kolombiya",
    "Comoros": "Komorlar",
    "Congo": "Kongo Cumhuriyeti",
    "Congo - Democratic Republic of the": "Demokratik Kongo Cumhuriyeti",
    "Cook Islands": "Cook Adaları",
    "Costa Rica": "Kosta Rika",
    "Cote d'Ivoire": "Fildişi Sahili",
    "Croatia": "Hırvatistan",
    "Cuba": "Küba",
    "Curacao": "Curaçao",
    "Cyprus": "Kıbrıs",
    "Czech Republic": "Çek Cumhuriyeti",
    "Denmark": "Danimarka",
    "Djibouti": "Cibuti",
    "Dominica": "Dominika",
    "Dominican Republic": "Dominik Cumhuriyeti",
    "Ecuador": "Ekvador",
    "Egypt": "Mısır",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Ekvator Ginesi",
    "Eritrea": "Eritre",
    "Estonia": "Estonya",
    "Eswatini": "Esvatini",
    "Ethiopia": "Etiyopya",
    "Falkland Islands (Malvinas)": "Falkland Adaları (Malvinas)",
    "Faroe Islands": "Faroe Adaları",
    "Fiji": "Fiji",
    "Finland": "Finlandiya",
    "France": "Fransa",
    "French Polynesia": "Fransız Polinezyası",
    "French Southern Territories": "Fransız Güney ve Antarktika Toprakları",
    "Gabon": "Gabon",
    "Gambia": "Gambiya",
    "Georgia": "Gürcistan",
    "Germany": "Almanya",
    "Ghana": "Gana",
    "Gibraltar": "Cebelitarık",
    "Greece": "Yunanistan",
    "Greenland": "Grönland",
    "Grenada": "Grenada",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea (Conakry)": "Gine (Conakry)",
    "Guinea-Bissau": "Gine-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Heard Island and McDonald Islands": "Heard Adası ve McDonald Adaları",
    "Holy See": "Vatikan",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Macaristan",
    "Iceland": "İzlanda",
    "India": "Hindistan",
    "Indonesia": "Endonezya",
    "Iran (Islamic Republic of)": "İran (İslam Cumhuriyeti)",
    "Iraq": "Irak",
    "Ireland": "İrlanda",
    "Isle of Man": "Man Adası",
    "Israel": "İsrail",
    "Italy": "İtalya",
    "Jamaica": "Jamaika",
    "Japan": "Japonya",
    "Jersey": "Jersey",
    "Jordan": "Ürdün",
    "Kazakhstan": "Kazakistan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Korea (Democratic People's Republic of)": "Kuzey Kore",
    "Korea - Republic of": "Güney Kore",
    "Kuwait": "Kuveyt",
    "Kyrgyzstan": "Kırgızistan",
    "Lao People's Democratic Republic": "Laos Halk Demokratik Cumhuriyeti",
    "Latvia": "Letonya",
    "Lebanon": "Lübnan",
    "Lesotho": "Lesoto",
    "Liberia": "Liberya",
    "Libya": "Libya",
    "Liechtenstein": "Lihtenştayn",
    "Lithuania": "Litvanya",
    "Luxembourg": "Lüksemburg",
    "Macao": "Makao",
    "Madagascar": "Madagaskar",
    "Malawi": "Malavi",
    "Malaysia": "Malezya",
    "Maldives": "Maldivler",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshall Adaları",
    "Mauritania": "Moritanya",
    "Mauritius": "Mauritius",
    "Mexico": "Meksika",
    "Micronesia (Federated States of)": "Mikronezya Federal Devletleri",
    "Moldova - Republic of": "Moldova Cumhuriyeti",
    "Monaco": "Monako",
    "Mongolia": "Moğolistan",
    "Montenegro": "Karadağ",
    "Montserrat": "Montserrat",
    "Morocco": "Fas",
    "Mozambique": "Mozambik",
    "Myanmar": "Myanmar",
    "Namibia": "Namibya",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Hollanda Krallığı",
    "New Caledonia": "Yeni Kaledonya",
    "New Zealand": "Yeni Zelanda",
    "Nicaragua": "Nikaragua",
    "Niger": "Nijer",
    "Nigeria": "Nijerya",
    "Niue": "Niue",
    "Norfolk Island": "Norfolk Adası",
    "North Macedonia": "Kuzey Makedonya",
    "Northern Mariana Islands": "Kuzey Mariana Adaları",
    "Norway": "Norveç",
    "Oman": "Umman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestine - State of": "Filistin Devleti",
    "Panama": "Panama",
    "Papua New Guinea": "Papua Yeni Gine",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Filipinler",
    "Pitcairn": "Pitcairn Adaları",
    "Poland": "Polonya",
    "Portugal": "Portekiz",
    "Puerto Rico": "Porto Riko",
    "Qatar": "Katar",
    "Romania": "Romanya",
    "Russian Federation": "Rusya Federasyonu",
    "Rwanda": "Ruanda",
    "Saint Barth\u00e9lemy": "Saint Barthélemy",
    "Saint Helena - Ascension and Tristan da Cunha": "Saint Helena - Ascension ve Tristan da Cunha",
    "Saint Kitts and Nevis": "Saint Kitts ve Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Martin (French part)": "Saint Martin (Fransca kısmı)",
    "Saint Vincent and the Grenadines": "Saint Vincent ve Grenadinler",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "São Tomé ve Príncipe",
    "Senegal": "Senegal",
    "Serbia": "Sırbistan",
    "Seychelles": "Seyşeller",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapur",
    "Sint Maarten (Dutch part)": "Sint Martin (Hollanda kısmı)",
    "Slovakia": "Slovakya",
    "Slovenia": "Slovenya",
    "Solomon Islands": "Solomon Adaları",
    "Somalia": "Somali",
    "South Africa": "Güney Afrika",
    "South Georgia and the South Sandwich Islands": "Güney Georgia ve Güney Sandwich Adaları",
    "South Sudan": "Güney Sudan",
    "Spain": "İspanya",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Surinam",
    "Svalbard and Jan Mayen": "Svalbard ve Jan Mayen",
    "Sweden": "İsveç",
    "Switzerland": "İsviçre",
    "Syrian Arab Republic": "Suriye",
    "Tajikistan": "Tacikistan",
    "United Republic of Tanzania": "Tanzanya",
    "Thailand": "Tayland",
    "Timor-Leste": "Doğu Timor",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad ve Tobago",
    "Tunisia": "Tunus",
    "Turkey": "Türkiye",
    "Turkmenistan": "Türkmenistan",
    "Turks and Caicos Islands": "Turks ve Caicos Adaları",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukrayna",
    "United Arab Emirates": "Birleşik Arap Emirlikleri",
    "United Kingdom of Great Britain and Northern Ireland": "Birleşik Krallık",
    "United States of America": "Amerika Birleşik Devletleri",
    "United States Minor Outlying Islands": "Amerika Birleşik Devletleri Küçük Dış Adaları",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Özbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Venezuela (Bolivarcı Cumhuriyet)",
    "Viet Nam": "Vietnam",
    "Virgin Islands (British)": "Virjin Adaları (Birleşik Krallık)",
    "Virgin Islands (U.S.)": "Virjin Adaları (ABD)",
    "Wallis and Futuna": "Wallis ve Futuna",
    "Yemen": "Yemen",
    "Zambia": "Zambiya",
    "Zimbabwe": "Zimbabve",
    "Taiwan": "Tayvan",
    "Palestine": "Filistin",
    "Western Sahara": "Batı Sahra",
    "Kosovo": "Kosova",
    "South Ossetia": "Güney Osetya",
    "Abkhazia": "Abhazya",
    "Nagorno-Karabakh (Artsakh)": "Dağlık Karabağ (Artsakh)",
    "Transnistria": "Transdinyester",
    "Somaliland": "Somaliland",
    "Northern Cyprus": "Kuzey Kıbrıs",
    "Saudi Arabia": "Suudi Arabistan",
    "Yugoslavia": "Yugoslavya",

    "Login": "Giriş yap",
    "Enter Your Email": "E-postanızı girin",
    "Login With Phone Number": "Telefon numarasıyla giriş yap",
    "Verify Using Sms/Email": "SMS/E-posta ile Doğrulama",
    "Resend Code In 100 Seconds": "Kodu 100 saniye içinde yeniden gönder",
    "Verify": "Doğrula",
    "Verifying": "Doğrulama yapılıyor",
    "Last Week": "Geçen hafta",
    "Older Transaction": "Eski işlem",
    "Total": "Toplam",
    "Conversion Confirmed": "Dönüşüm onaylandı",
    "User Account Limit": "Kullanıcı hesap limiti",
    "Monthly Balance Limit": "Aylık bakiye limiti",
    "Total Limit": "Toplam limit",
    "Used": "Kullanıldı",
    "Remaining": "Kalan",
    "Allowed Minimum Topup Amount": "İzin verilen minimum bakiye yükleme miktarı",
    "Allowed Maximum Topup Amount": "İzin verilen maksimum bakiye yükleme miktarı",
    "Incoming Limit": "Gelen limit",
    "Daily Limit": "Günlük Limit",
    "Monthly Limit": "Aylık Limit",
    "Yearly Limit": "Yıllık Limit",
    "Sending Limit": "Gönderim limiti",
    "Allowed Number Of Transactions": "İzin verilen işlem sayısı",
    "Total Amount": "Toplam Tutar",
    "Amount To Send": "Gönderilecek Miktar",
    "Destination Amount": "Hedef Miktar",
    "Note": "Not",
    "Revised Amount": "Düzeltilmiş Miktar",
    "Save": "Kaydet",
    "Date": "Tarih",
    "Action": "İşlem",
    "Payment Scheduled At": "Ödeme Zamanı",
    "Transaction Fee": "İşlem Ücreti",
    "Number Of Cycle": "Döngü Sayısı",
    "Subscription Starting Date": "Abonelik Başlangıç Tarihi",
    "Until": "Kadar",
    "Next Transaction": "Sonraki İşlem",
    "Pay Now": "Şimdi Öde",
    "Transaction Method": "İşlem Yöntemi",
    "Mobile Money Provider": "Mobil Para Sağlayıcısı",
    "Wallet Name": "Cüzdan Adı",
    "Wallet Number": "Cüzdan Numarası",
    "Crypto Currency": "Kripto Para",
    "Wallet Address": "Cüzdan Adresi",
    "Select Document Type": "Belge Türünü Seçin",
    "Edit Document Number": "Belge Numarasını Düzenle",
    "My Withdrawals": "Çekimlerim",
    "Bargainable": "Pazarlık Yapılabilir",
    "Description": "Açıklama",
    "Attached Files": "Ekli Dosyalar",
    "Sent": "Gönderildi",
    "Accepted": "Kabul Edildi",
    "Decline": "Reddedildi",
    "Pending": "Beklemede",
    "Overall Rating": "Genel Değerlendirme",
    "No Review": "Yorum Yok",
    "0 Rating": "0 Değerlendirme",
    "Transaction Map": "İşlem Haritası",
    "Send Money": "Para Gönder",
    "Sending Method": "Gönderim Yöntemi",
    "Trouble Sending Money": "Para Gönderme Sorunu",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Ücret ve alınan tutar, seçilen ödeme yöntemine bağlı olarak değişebilir.",
    "Select Method": "Yöntem Seçin",
    "Completed": "Tamamlandı",
    "How Was Your Experience?": "Deneyiminiz nasıldı?",
    "Leave A Review": "Yorum bırak",
    "Review History": "Yorum Geçmişi",
    "Write A Review": "Yorum Yaz",
    "Send And Post": "Gönder ve Yayınla",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Kripto'dan Fiat'a Dönüşüm, kripto paraları fiat para birimlerine sorunsuz bir şekilde dönüştüren güçlü ve güvenli bir çözümdür. Kullanıcıların çeşitli ihtiyaçlarına hitap eder, bireysel tercihlere ve konumlara göre özelleştirilmiş birçok ödeme kanalı sunar.\"",
    "Review Created": "İnceleme Oluşturuldu",
    "Transactions": "İşlemler",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Sürükle/Bırak veya Dosya Yükle (Foto/Video/Pdf)",
    "Max Size 1GB": "Maksimum Boyut 1GB",
    "Title Description": "Başlık Açıklaması",
    "Add New": "Yeni Ekle",
    "Discard": "Sil",
    "Save And Post": "Gönder ve Yayınla",
    "Personal Account": "Kişisel Hesap",
    "Conversion": "Dönüşüm",
    "Spent In Total": "Toplam Harcanan",
    "International": "Uluslararası",
    "Recharge": "Yeniden Yükle",
    "Top Up Wallet To Wallet": "Cüzdandan Cüzdana Yükleme",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Cüzdan",
    "Not Connected": "Bağlanmadı",
    "More": "Daha Fazla",
    "Less": "Daha Az",
    "Profile Reports": "Profil Raporları",
    "Timezone": "Zaman Dilimi",
    "Cancel": "İptal Et",
    "Full Name": "Tam Ad",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Ek Belgeler",
    "Profile Complete": "Profil Tamamlandı",
    "Verified": "Doğrulandı",
    "Bank Account": "Banka Hesabı",
    "Mobile Wallet": "Mobil Cüzdan",
    "Cash Pickup": "Nakit Teslimat",
    "Add": "Ekle",
    "Configured": "Yapılandırılmış",
    "Email": "E-posta",
    "Download Data": "Veri İndir",
    "Enter The Amount": "Tutarı Girin",
    "They Receive": "Aldıkları Tutar",
    "Where Do You Want To Send Money": "Parayı Nereye Göndermek İstiyorsunuz",
    "Bank Transfer": "Banka Transferi",
    "Receiving Method": "Alım Yöntemi",
    "Payer": "Ödeyen",
    "Minimum Amount": "Minimum Tutar",
    "Maximum Amount": "Maksimum Tutar",
    "Search": "Ara",
    "Show More": "Daha Fazla Göster",
    "Select Remark / Reasons (All)": "Açıklama / Sebep Seçin (Tümü)",
    "Attach Files": "Dosya Ekle",
    "Holiday List": "Tatil Listesi",
    "No Data To Show": "Gösterilecek Veri Yok",
    "Threshold Limits": "Eşik Limitleri",
    "Amount Limits": "Tutar Limitleri",
    "OTP Verification Code": "OTP Doğrulama Kodu",
    "Verify Using Authenticator": "Authenticator ile Doğrulama",
    "Sms/Email Verification Code": "SMS/E-posta Doğrulama Kodu",
    "Invite Them": "Onları Davet Et",
    "Lookup": "Ara",
    "Invite Friend": "Arkadaşını Davet Et",
    "Invite Via Phone Number": "Telefon Numarasıyla Davet Et",
    "Invite Via Email Address": "E-posta Adresiyle Davet Et",
    "Next": "Sonraki",
    "Back": "Geri",
    "Invite Via Email": "E-posta ile Davet Et",
    "Available Currency": "Mevcut Para Birimi",
    "Add Remarks And Comments": "Açıklama ve Yorum Ekleyin",
    "Recipient Details": "Alıcı Detayları",
    "Go Back": "Geri Git",
    "Trouble Requesting Money": "Para Talep Etme Sorunu",
    "Instant": "Anında",
    "Subscription": "Abonelik",
    "Schedule": "Takvim",
    "Choose Request Type": "Talep Türünü Seçin",
    "Mobile Airtime": "Mobil Kontör",
    "Incorrect Phone Number": "Hatalı Telefon Numarası",
    "Make Sure You Have Entered A Correct Phone Number.": "Doğru Telefon Numarası Girdiğinizden Emin Olun.",
    "Close": "Kapat",
    "Operator": "Operatör",
    "Recipient Mobile Number": "Alıcının Telefon Numarası",
    "Amount": "Tutar",
    "Service": "Hizmet",
    "Destination": "Hedef",
    "Top Up Summary": "Top Up Özeti",
    "Daily Sending Limit Exceeded": "Günlük Gönderim Limiti Aşıldı",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Müşterinize veya alıcınıza detaylı bir fiyat teklifi gönderin. Hizmetinizi, ürünlerinizi veya tekliflerinizi profesyonelce kişiselleştirilmiş bir teklif ile sunun.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Hafta içi saat 15:00'ten önce yapılan ödemeler, aynı günün gece yarısına kadar alıcı banka hesabına yatırılacaktır.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Üç ülkeye kadar ödeme alma hesaplarınızı ayarlayın ve onaylı ödeme taleplerinden fonları hemen etkinleştirilmiş ödeme kanallarınıza çekin.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Krallığı, Hukuki Yükümlülüklerine Uygun Olarak, Tamamlayıcı Doğrulamalar Yapabilmek İçin Gerekli Herhangi Bir Ekstra Belge (Gelişmiş Müşteri Tanıma - EDD) Veya Ek Bilgi Talep Etme Hakkını Saklı Tutar.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Bu, Fotoğraf İçeren Hükümet Tarafından Verilen Bir Kimlik Belgesi Olmalıdır. Tüm Kimlik Belgeleri Latin Alfabesiyle Yazılmalıdır. Aksi Takdirde, Sertifikalı Bir Tercüme Gereklidir.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "İşinizi Sergileyin, Erişiminizi Artırın. 'Portföyüm', Instapay'deki Dijital Kanvasınızdır. Çeşitli İçerikleri Yükleyin ve Sergileyin - Etkileyici Resimler ve Videolardan, Bilgilendirici PDF'lere Kadar. İster Sanatçı, Serbest Çalışan, İster Girişimci Olun, Bu Özellik, Çalışmalarınızı veya Projelerinizi Görsel Olarak Çekici Bir Şekilde Sunmanıza İmkan Tanır.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "QR Kodunuzu veya Ödeme Bağlantınızı Paylaşarak Insta Cüzdanınızda Hızla Ödeme Alın.",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "İşlemi tamamlamak için 15 dakikanız var.",
    "Time Remaining": "Lütfen URL'nin süresi dolmadan süre sınırı içinde tamamlayın.",
    "Please Process The Payment Via Your Selected Payment Type": "Lütfen seçtiğiniz ödeme yöntemi ile ödemeyi işleyin.",
    "Top-Up Wallet": "Ödeme Türü",
    "Confirm Your Payment": "CÜZDANI DOLDUR",
    "You Will Get": "Ödemenizi Onaylayın",
    "Amount To Pay": "Alacağınız Miktar",
    "Proceed To Payment": "Ödenecek Tutar",
    "Load/Deposit Money To The Wallet": "Ödemeye devam et",
    "Withdraw": "Cüzdana para yükleyin/depozito yatırın",
    "Elevate Your Business With InstaPay": "Çekim Yap",
    "Higher Balances & Transaction Limits": "İşletmenizi InstaPay ile yükseltin",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Daha yüksek bakiyeler ve işlem limitleri",
    "Full Feature Access": "Daha büyük bakiyeleri yönetme ve daha yüksek işlem hacimlerini işleme özgürlüğünü açın, InstaPay'den beklediğiniz güvenilirlikle birlikte.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Tam Özellik Erişimi",
    "Global Reach": "Küresel Erişim",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "İşinizi sınırlar olmadan genişletin. InstaPay, sizi dünya genelindeki müşterilere ve ortaklara bağlayarak küresel işlemleri her zamankinden daha kolay hale getiriyor.",
    "Advanced Security": "Gelişmiş Güvenlik",
    "Business Registration Available Soon\n": "İş kaydı yakında mevcut olacak.",
    "Amount After Fee": "Ücret Sonrası Tutar",
    "Allowed Maximum Amount Per Transaction": "İşlem Başına İzin Verilen Maksimum Tutar",
    "Transfer": "Transfer",
    "Uncategorized": "Kategorize Edilmemiş",
    "Currency ISO Code & Amount": "Para Birimi ISO Kodu ve Tutar",
    "Choose The Top-Up Channel": "Yükleme kanalını seçin",
    "You Selected": "seçtiğiniz",
    "Payout Channel": "ödeme kanalı",
    "Your Details": "bilgileriniz",
    "Detailed Confirmed": "ayrıntılar onaylandı",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Finansal potansiyelinizi artırmak için InstaPay İş Hesabına geçin. İş büyümesi için özel olarak tasarlanmış geliştirilmiş özelliklerle InstaPay’in tam gücünü deneyimleyin.",
    "Profile Picture": "Profil resmi",
    "Add Photo Or Logo": "Fotoğraf veya Logo Ekle",
    "First Name": "Ad",
    "Last Name": "Soyad",
    "Enter Your First Name": "Adınızı girin",
    "Enter Your Last Name": "Soyadınızı girin",
    "Email (Optional)": "E-posta (isteğe bağlı)",
    "Password Must": "Şifre olmalı",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Davet bağlantısıyla kaydoluyorsanız otomatik olarak doldurulacaktır.",
    "Change Number": "Numarayı değiştir",
    "Search Country": "Ülke ara",
    "Select From The List": "Listeden seç",
    "Received Amount": "Alınan miktar",

    "null": null,
    "Processing": "İşleniyor",
    "Verify Using Sms/Email": "SMS/E-posta ile doğrula",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Zaten mevcut",
    "You Can Have Only 8 Wallets": "Sadece 8 cüzdanınız olabilir",
    "Save": "Kaydet",
    "0 File": "0 dosya",
    "Search From The List": "Listeden ara",
    "Amount To Send": "Gönderilecek tutar",
    "Wallet To Wallet": "Cüzdan'dan Cüzdan'a",
    "TOP-UP WALLET ": "CÜZDANI DOLDUR",
    "Enter The Amount": "Miktarı girin",
    "Load/Deposit Money To The Wallet": "Cüzdana para yükle/para yatır",
    "Choose The Top-Up Channel": "Para yükleme kanalını seçin",
    "Paypal": "Paypal",
    "You Will Get": "Alacağınız miktar",
    "Amount To Pay": "Ödenecek tutar",
    "Card Holder Name ": "Kart sahibinin adı",
    "Card Number": "Kart numarası",
    "CVC": "CVC",
    "MM/YY": "AA/YY",
    "Your Details ": "Detaylarınız",
    "You Selected": "Seçtiniz",
    "Detailed Confirmed": "Detaylı onaylandı",
    "Transaction Failed": "İşlem başarısız oldu",
    "Something Went Wrong. Try Again.": "Bir şeyler ters gitti. Tekrar deneyin.",
    "Convert Funds": "Fonları dönüştür",
    "How Would You Like To Cashout?:": "Nasıl nakit çıkışı yapmak istersiniz?:",
    "Default Receiving Account": "Varsayılan Alıcı Hesabı",
    "Specify Receiving Account": "Alıcı Hesabını Belirtin",
    "Which Receiving Account Would You Want Use?:": "Hangi alıcı hesabını kullanmak istersiniz?:",
    "Pakistan": "Pakistan",
    "Bank Mobile": "Banka Mobil",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Hiçbiri",
    "Request": "Talep et",
    "Subscription Payment": "Abonelik Ödemesi",
    "Is not supported by Paypal, USD will be used as the default currency.": "PayPal tarafından desteklenmiyor, varsayılan para birimi olarak USD kullanılacaktır.",
    "Review Payment": "Ödemeyi İnceleyin",
    "Google Authenticator": "google authenticator",
    "Scheduled Has Been Cancelled": "Zamanlanmış işlem iptal edildi",
    "Select Country First": "Önce ülke seçin",
    "Select Gender": "Cinsiyet seçin",
    "Select Occupation": "Meslek seçin",
    "IBAN and SWIFT/BIC": "IBAN ve SWIFT/BIC",
    "Mobile Money Provider": "Mobil Para Sağlayıcısı",
    "Wallet Name": "Cüzdan Adı",
    "Wallet Number ": "Cüzdan Numarası",
    "Crypto Currency": "Kripto Para",
    "Wallet Address ": "Cüzdan Adresi",
    "Select Document Type ": "Belge Türü Seç",
    "Enter Document Number": "Belge Numarasını Gir",
    "Msisdn": "MSISDN",
    "Edit ": "Düzenle",
    "My Withdrawls": "Çekimlerim",
    "Declined": "Reddedildi",
    "Accepted": "Kabul edildi",
    "Overall Ratings": "Genel Derecelendirmeler",
    "0 Ratings": "0 Derecelendirme",
    "No Reviews": "İnceleme Yok",
    "Send Money": "Para Gönder",
    "Trouble Sending Money?": "Para gönderme sorunu?",
    "Payment Request": "Ödeme Talebi",
    "How Was Your Experience?": "Deneyiminiz nasıldı?",
    "Leave A Review": "Bir inceleme bırak",
    "Write Review Message": "İnceleme mesajı yaz",
    "Send And Post": "Gönder ve paylaş",
    "You Rated": "Derecelendirdiniz",
    "Review History": "İnceleme geçmişi",
    "Max Size 1GB": "Maksimum boyut 1GB",
    "Title": "Başlık",
    "Description": "Açıklama",
    "Add New": "Yeni ekle",
    "My Payment Address": "Ödeme Adresim",
    "Personal Account": "Kişisel Hesap",
    "Updated Successfully": "Başarıyla güncellendi",
    "Spent In": "Harcanan",
    "TOP UP": "Yükleme",
    "Quotation": "Teklif",
    "Amount": "Miktar",
    "Not Connected": "Bağlantı yok",
    "More": "Daha fazla",
    "Less": "Daha az",
    "Reports": "Raporlar",
    "User Profile": "Kullanıcı profili",
    "Cancel": "İptal",
    "Saving": "Kaydetme",
    "Confirm ": "onayla",
    "Verify ": "Doğrula",
    "Delete Country": "Ülke sil",
    "Bank Account": "Banka hesabı",
    "Cash Pickup": "Nakit Teslim Alma",
    "Mobile Wallet ": "Mobil cüzdan",
    "Delete Country Withdrawals": "Ülke Çekimleri Sil",
    "Yes , Delete It": "Evet, sil",
    "E-sim": "E-sim",
    "Utilities": "Hizmetler",
    "Searching": "Arama",
    "Recipient Mobile Number": "Alıcı telefon numarası",
    "TOP-UP SUMMARY": "Yükleme Özeti",
    "Trouble Requesting Money?": "Para Gönderme Sorunu?",
    "Choose The Beneficiary": "Alıcıyı Seçin",
    "Enter The amount": "Miktarı girin",
    "You selected": "Seçtiniz",
    "Timezone": "Zaman dilimi",
    "Starting From": "Başlangıç Tarihi",
    "Recurring Cycle": "Tekrarlayan Döngü",
    "Until I stop": "Durana kadar",
    "Schedule To": "Planla",
    "Schedule at": "Planla",
    "Selected time must be at least 5 minutes from now.": "Seçilen zaman en az 5 dakika sonra olmalıdır.",
    "Is this your Location?": "Bu sizin konumunuz mu?",
    "Yes ": "Evet",
    "Select Remark Reason": "Yorum nedenini seçin",
    "Attach File": "Dosya ekle",
    "Invite via Phone Number": "Telefon Numarasıyla Davet Et",
    "Invite Friend": "Arkadaş Davet Et",
    "Invite via Email Address": "E-posta Adresiyle Davet Et",
    "Invite via": "Davet Et via",
    "Phone": "Telefon:",
    "Message": "Mesaj:",
    "Send": "Gönder",
    "Invited Succesfully!": "Başarıyla Davet Edildi!",
    "Email": "E-posta",
    "Bank Transfer": "Banka Transferi",
    "Mobile Wallet": "Mobil Cüzdan",
    "Select The Bank": "Bankayı Seç",
    "Select The Payer": "Ödeyeni Seç",
    "Min Amount:": "Minimum Miktar:",
    "Max Amount": "Maksimum Miktar",
    "Other Beneficiaries:": "Diğer Yararlanıcılar:",
    "Back ": "Geri",
    "Next": "Sonraki",
    "Lookup": "Ara",
    "Invite Them": "Onları Davet Et",
    "Delete Channel ": "Kanalı Sil",
    "Set As Default": "Varsayılan olarak ayarla",
    "See More": "Daha fazla göster",
    "Session Details": "Oturum detayları",
    "Platform": "Platform",
    "Windows": "Windows",
    "Browser Name": "Tarayıcı Adı",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Tarayıcı Sürümü",
    "IP Address": "IP Adresi",
    "Karachi": "Karachi",
    "State": "Eyalet",
    "Sindh": "Sindh",
    "Active": "Aktif",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Bu para birimi cüzdanı şu anda engellenmiş/devre dışı bırakılmıştır ve fon eklenemez.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Bu para birimi cüzdanı şu anda engellenmiş/devre dışı bırakılmıştır ve fon dönüştürülemez.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Bu para birimi cüzdanı şu anda engellenmiş/devre dışı bırakılmıştır ve bakiyenizi çekemezsiniz.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Bu para birimi cüzdanı şu anda engellenmiş/devre dışı bırakılmıştır ve bildirimi indiremezsiniz.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Bu para birimi cüzdanı şu anda varsayılan olarak ayarlanmış ve engellenemez. Devam etmek için lütfen önce başka bir para birimi cüzdanını varsayılan olarak ayarlayın.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Bu para birimi cüzdanı şu anda varsayılan olarak ayarlanmış ve devre dışı bırakılamaz. Devam etmek için lütfen önce başka bir para birimi cüzdanını varsayılan olarak ayarlayın.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Bu ülkenin para çekme detaylarını silmek istediğinizden emin misiniz?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Üç ülkeye kadar alıcı hesaplarınızı ayarlayın ve onaylanmış ödeme taleplerinden anında fon çekin, etkinleştirilmiş ödeme kanallarınıza.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Pakistan'dan Allied Bank Limited bilgilerine sahip bir alıcı bulunamadı",
    "Invitation Message Has Been Successfully Sent To": "Davet mesajı başarıyla gönderildi",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Ücret ve alınan miktar, seçilen ödeme yöntemine bağlı olarak değişebilir.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "QR Kodunuzu veya ödeme bağlantınızı paylaşarak Insta cüzdanınızda hemen ödeme alın.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Dosya sürükle/bırak veya yükle (Fotoğraf/Videolar/PDF)",
    "Login Again": "tekrar giriş yap",
    "Unblock The Wallet": "cüzdanı aç",
    "Invalid Amount": "geçersiz miktar",
    "Add Payment Card": "ödeme kartı ekle",
    "Save Details To Use Next Time": "detayları kaydet, bir dahaki sefere kullanmak için",
    "Paid": "ödenmiş",
    "Started On": "başlama tarihi",
    "Num Of Cycles": "döngü sayısı",
    "Ends On": "bitiş tarihi",
    "Next Payment At": "bir sonraki ödeme",
    "Beneficiary": "alıcı",
    "Recieved Quotation": "alınan teklif",
    "Sent Quotation": "gönderilen teklif",
    "Decline Offer": "teklifi reddet",
    "Are You Sure That You Want To Decline The Offer?": "teklifi reddetmek istediğinizden emin misiniz?",
    "Decline The Offer": "teklifi reddet",
    "Bargain Offer": "pazarlık teklifi",
    "Enter Counter Amount": "karşı teklif miktarını girin",
    "Custom Amount": "özel miktar",
    "Send The Offer": "teklifi gönder",
    "The Offer Is Sent": "teklif gönderildi",
    "Countered Offer": "karşı teklif",
    "Applied": "uygulandı",
    "Accept Quotation": "teklifi kabul et",
    "Quotation Not Found Or Already Exist": "Teklif bulunamadı veya zaten var",
    "Recieved Request": "alınan talep",
    "Sent Request": "gönderilen talep",
    "Search Request": "talep ara",
    "Search Quotation": "teklif ara",
    "QR Status": "QR durumu",
    "QR Code Description": "QR kodu açıklaması",
    "Download Your QR Code": "QR Kodunuzu indirin",
    "Your QR Code": "QR kodunuz",
    "Your QR Code Poster": "QR kodu posteriniz",
    "Download  ": "indir",
    "Select Relation": "ilişki seç",
    "Nominee Permanent Address": "nominee kalıcı adresi",
    "Enter Your Message": "mesajınızı girin",
    "Select": "seç",
    "Select File Type": "dosya türü seç",
    "Send TOP-UP And E-Sim": "Top-up ve E-sim gönder",
    "Search Users": "kullanıcıları ara",
    "Select Payment Request Type": "ödeme talebi türünü seç",
    "Wallet To Wallet Transfer": "cüzdanlar arası transfer",
    "Holiday List": "tatil listesi",
    "No Data To Show": "gösterilecek veri yok",
    "They Recieve": "onlar alıyor",
    "You Will Be Logged Out In 60 Seconds": "60 saniye içinde oturumunuz kapatılacak",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Zamanlayıcıyı 15 dakikaya sıfırlamak için farenizi hareket ettirin veya klavyenizde herhangi bir tuşa basın",
    "You've Been Logged Out": "Oturumunuz kapatıldı",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Güvenliğiniz için, 15 dakika hareketsiz kaldığınızda oturumunuz kapatıldı. InstaPay'i kullanmak isterseniz tekrar giriş yapın.",
    "Cash": "Nakit",
    "Business": "İş",
    "Gift": "Hediye",
    "Salary": "Maaş",
    "Lottery": "Piyango",
    "Savings": "Tasarruf",
    "Other": "Diğer",
    "Select Source Of Fund": "Fon Kaynağını Seçin",
    "Select your source of funds": "Fon kaynağınızı seçin",
    "Request Times Out. Please Try Again!": "İstek zaman aşımına uğradı. Lütfen tekrar deneyin!",
    "Resend Code In 100 Seconds": "Kodu 100 saniye içinde yeniden gönder",
    "Computer Service": "Bilgisayar Hizmeti",
    "Family Support": "Aile Desteği",
    "Education": "Eğitim",
    "Gift And Other Donations": "Hediye ve diğer bağışlar",
    "Medical Treatment": "Tıbbi tedavi",
    "Maintenance Or Other Expenses": "Bakım veya diğer harcamalar",
    "Travel": "Seyahat",
    "Small Value Remittance": "Küçük değerli para transferi",
    "Liberalized Remittance": "Serbestleştirilmiş para transferi",
    "Construction Expenses": "İnşaat harcamaları",
    "Hotel Accommodation": "Otel konaklaması",
    "Advertising And/Or Public Relations Related Expenses": "Reklam ve/veya halkla ilişkiler ile ilgili harcamalar",
    "Fees For Advisory Or Consulting Service": "Danışmanlık veya danışmanlık hizmeti için ücretler",
    "Business Related Insurance Payment": "İşle ilgili sigorta ödemesi",
    "Insurance Claims Payment": "Sigorta tazminat ödemesi",
    "Delivery Fees": "Teslimat ücretleri",
    "Payments For Exported Goods": "İhraç edilen mallar için ödemeler",
    "Payment For Services": "Hizmetler için ödeme",
    "Payment Of Loans": "Kredi ödemesi",
    "Office Expenses": "Ofis harcamaları",
    "Residential Property Purchase": "Konut mülkü alımı",
    "Property Rental Payment": "Mülk kira ödemesi",
    "Royalty, Trademark, Patent And Copyright Fees": "Telif hakkı, ticari marka, patent ve telif hakkı ücretleri",
    "Investment In Shares": "Hisse senetlerine yatırım",
    "Fund Investment": "Fon yatırımı",
    "Tax Payment": "Vergi ödemesi",
    "Transportation Fees": "Ulaşım ücretleri",
    "Utility Bills": "Fatura ödemeleri",
    "Personal Transfer": "Kişisel transfer",
    "Payment Of Salary": "Maaş ödemesi",
    "Payment Of Rewards": "Prim ödemesi",
    "Payment Of Influencer": "Etkileyiciye ödeme",
    "Broker, Commitment, Guarantee And Other Fees": "Komisyon, taahhüt, garanti ve diğer ücretler",
    "Other Purposes": "Diğer amaçlar",
    "Aunt": "Teyze",
    "Brother": "Kardeş",
    "Brother-In-Law": "Baldız",
    "Cousin": "Kuzen",
    "Daughter": "Kız",
    "Father": "Baba",
    "Father-In-Law": "Kayınbaba",
    "Friend": "Arkadaş",
    "Grandfather": "Dede",
    "Grandmother": "Nine",
    "Husband": "Koca",
    "Mother": "Anne",
    "Mother-In-Law": "Kayınvalide",
    "Nephew": "Yeğen",
    "Niece": "Kız kardeş",
    "Self (i.e. The Sender, Himself)": "Baldız",
    "Sister": "Oğul",
    "Sister-In-Law": "Görümce/Elti",
    "Son": "Oğul",
    "Uncle": "Amca",
    "Wife": "Eş",
    "Others Not Listed": "Diğerleri belirtilmemiş",
    "Passport": "Pasaport",
    "National Identification Card": "Kimlik Kartı",
    "Driving License": "Ehliyet",
    "Social Security Card/Number": "Sosyal Güvenlik Kartı/Numarası",
    "Tax Payer Identification Card/Number": "Vergi Mükellefi Kimlik Kartı/Numarası",
    "Senior Citizen Identification Card": "Yaşlı Kimlik Kartı",
    "Birth Certificate": "Doğum Belgesi",
    "Village Elder Identification Card": "Köy Yaşlısı Kimlik Kartı",
    "Permanent Residency Identification Card": "Sürekli Oturum Kimlik Kartı",
    "Alien Registration Certificate/Card": "Yabancı Kayıt Sertifikası/Kartı",
    "PAN Card": "PAN Kartı",
    "Voter’s Identification Card": "Seçmen Kimlik Kartı",
    "Health Insurance Card/Number": "Sağlık Sigortası Kartı/Numarası",
    "Employer Identification Card": "İşveren Kimlik Kartı",
    "Others Not Listed": "Diğer listelenmemiş",
    "Bank Account": "Banka Hesabı",
    "Cash Pickup": "Nakit Teslimat",
    "Card": "Kart",
    "Arts & Entertainment": "Sanat ve Eğlence",
    "Banking & Finance": "Bankacılık ve Finans",
    "Education & Research": "Eğitim ve Araştırma",
    "Engineering & Construction": "Mühendislik ve İnşaat",
    "Healthcare & Medicine": "Sağlık ve Tıp",
    "Information Technology & Services": "Bilgi Teknolojisi ve Hizmetler",
    "Legal & Compliance": "Hukuk ve Uyum",
    "Manufacturing & Production": "Üretim ve İmalat",
    "Marketing & Sales": "Pazarlama ve Satış",
    "Non-profit & Charity": "Kar amacı gütmeyen ve Hayır Kurumları",
    "Real Estate & Property": "Emlak ve Gayrimenkul",
    "Retail & Wholesale": "Perakende ve Toptan Satış",
    "Science & Pharmaceuticals": "Bilim ve İlaçlar",
    "Transport & Logistics": "Ulaşım ve Lojistik",
    "Travel & Tourism": "Seyahat ve Turizm",
    "Unemployed": "İşsiz",
    "Other": "Diğer",
    "Cash": "Nakit",
    "Business": "İş",
    "Gift": "Hediye",
    "Salary": "Maaş",
    "Lottery": "Piyango",
    "Savings": "Tasarruf",
    "Good Morning": "Günaydın",
    "Good Evening": "İyi akşamlar",
    "Completed": "Tamamlandı",
    "Load/Deposit Money To The Wallet": "Ödemeye devam et",
    "Enter The Amount": "Miktarı girin",
    "Choose The Top-Up Channel": "Yükleme kanalını seçin",
    "Good Night!": "İyi Geceler!",
    "You Will Get": "Ödemenizi Onaylayın",
    "Amount To Pay": "Alacağınız Miktar",
    "Top-Up Wallet": "Ödeme Türü",
    "Card Holder Name ": "Kart sahibinin adı",
    "Card Number": "Kart numarası",
    "CVC": "CVC",
    "MM/YY": "AA/YY",
    "Save Details To Use Next Time": "detayları kaydet, bir dahaki sefere kullanmak için",
    "Select Or Add Payment Card": "Ödeme Kartı Seçin veya Ekleyin",
    "Your Details ": "Detaylarınız",
    "You Selected": "Seçtiniz",
    "Detailed Confirmed": "Detaylı onaylandı",
    "Transaction Failed": "İşlem başarısız oldu",
    "Something Went Wrong. Try Again.": "Bir şeyler ters gitti. Tekrar deneyin.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Başarısız",
    "Initiated": "Başlatıldı",
    "Revise": "Gözden Geçir",
    "Declined": "Reddedildi",
    "Bargain-Accepted": "Pazarlık-Kabul Edildi",
    "Accepted": "Kabul Edildi",
    "Decline The Offer": "Teklifi Reddet",
    "Pending": "Beklemede",
    "Cancelled": "İptal Edildi",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Müşterinize veya alıcınıza detaylı bir fiyat teklifi gönderin. Hizmetinizi, ürünlerinizi veya tekliflerinizi profesyonelce kişiselleştirilmiş bir teklif ile sunun.",
    "Wallet to wallet": "Cüzdandan cüzdana",
    "Please follow these examples:": "Lütfen bu örnekleri takip edin:",
    "👤 InstaPay/Instagram Username: instauser": "👤 InstaPay/Instagram Kullanıcı Adı: instauser",
    "📧 Email: user@email.com": "📧 E-posta: user@email.com",
    "Full Name": "Tam Ad",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "InstaPay'e hoş geldiniz! Aldığınız fonlardan anında nakit çekebilmeniz için ayarlardan alıcı hesaplarınızı kurmanız gerekmektedir. Bu, paranızı tercih ettiğiniz banka hesaplarına, mobil cüzdanlara, kripto cüzdanlara veya nakit teslim alma noktalarına sorunsuz bir şekilde aktarmanızı sağlar.",
    "You Have No Beneficiary": "Faydalanıcı bulunamadı",
    "Add Now": "Şimdi ekle",
    "Wallet-Status": "Cüzdan durumu",
    "Select Payment Card": "Ödeme kartını seç",
    "Your funds have been credited to your wallet.": "Fonlarınız cüzdanınıza yatırıldı.",
    "Thanks for using InstaPay": "InstaPay kullandığınız için teşekkürler",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Anında ödeme alın: Alıcı hesaplarınızı şimdi kurun!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Alacak hesaplarınızı ayarlamak ve her zaman fonlarınıza erişmeye hazır olduğunuzdan emin olmak için bu basit adımları izleyin:",
    "STEP 01": "ADIM 01",
    "Go to Settings": "Ayarlar'a Git",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "InstaPay uygulamanızı açın ve \"Ayarlar\" bölümüne gidin.",
    "STEP 02": "ADIM 02",
    "Select Receiving Accounts": "Alacak Hesaplarını Seç",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Ayrıntılarınızı eklemeye başlamak için \"Alacak Hesapları\"na dokunun.",
    "Start Adding Accounts": "Hesapları Eklemeye Başlayın",
    "Phone Number: 44795396600 (With Country Code)": "Telefon Numarası: 44795396600 (Ülke Kodu ile)",
    "Select Country First": "Önce ülke seçin",
    "First Name": "Ad",
    "Last Name": "Soyad",
    "Address Line": "Adres Satırı",
    "Email Address": "E-posta Adresi",
    "Select City": "Şehir seç",
    "Select Gender": "Cinsiyet seçin",
    "Select Occupation": "Meslek seçin",
    "Male": "Erkek",
    "Female": "Kadın",
    "Non-Binary": "Non-Binary",
    "Prefer Not To Say'": "Söylemek İstemiyorum",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Kullanıcı Bulunamadı",
    "Email Address ": "E-posta Adresi",
    "Select Document": "Belge Seç",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "İşinizi Sergileyin, Erişiminizi Artırın. 'Portföyüm', Instapay'deki Dijital Kanvasınızdır. Çeşitli İçerikleri Yükleyin ve Sergileyin - Etkileyici Resimler ve Videolardan, Bilgilendirici PDF'lere Kadar. İster Sanatçı, Serbest Çalışan, İster Girişimci Olun, Bu Özellik, Çalışmalarınızı veya Projelerinizi Görsel Olarak Çekici Bir Şekilde Sunmanıza İmkan Tanır.",
    "Add New": "Yeni Ekle",
    "Money Out": "Para Çıkışı",
    "Browsername": "Tarayıcı Adı",
    "Browserversion": "Tarayıcı Sürümü",
    "Credit": "Kredi",
    "Debit": "Borç",
    "Top Up": "Yükleme",
    "Wallet To Wallet": "Cüzdandan Cüzdana",
    "Accountant": "Muhasebeci",
    "Architect": "Mimar",
    "Artist": "Sanatçı",
    "Banker": "Bankacı",
    "Business Owner": "İş Sahibi",
    "Consultant": "Danışman",
    "Doctor": "Doktor",
    "Engineer": "Mühendis",
    "Farmer": "Çiftçi",
    "Government Employee": "Devlet Çalışanı",
    "IT Professional": "BT Uzmanı",
    "Lawyer": "Avukat",
    "Nurse": "Hemşire",
    "Retailer": "Perakendeci",
    "Salesperson": "Satıcı",
    "Student": "Öğrenci",
    "Teacher": "Öğretmen",
    "Prefer Not to Say": "Belirtmek istemem",
    "Timezone": "Zaman dilimi",
    "Subscription Payment": "Abonelik Ödemesi",
    "Starting From": "Başlangıç Tarihi",
    "Recurring Cycle": "Tekrarlayan Döngü",
    "Until I stop": "Durana kadar",
    "Schedule To": "Planla",
    "Schedule at": "Planla",
    "Selected time must be at least 5 minutes from now.": "Seçilen zaman en az 5 dakika sonra olmalıdır.",
    "They Recieve": "onlar alıyor",
    "Attach Files": "Dosya Ekle",
    "Wallet Selected": "Cüzdan Seçildi",
    "You Have Selected PKR Currency Wallet For This Transaction": "Bu İşlem İçin PKR Para Birimi Cüzdanını Seçtiniz",
    "Instant": "Anında",
    "Subscription": "Abonelik",
    "Schedule": "Takvim",
    "Sms/Email Verification Code": "SMS/E-posta Doğrulama Kodu",
    "Confirm Your Password": "Şifrenizi Onaylayın",
    "This Security Feature Requires Password Confirmation!": "Bu Güvenlik Özelliği Şifre Onayı Gerektiriyor!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Uygulamayı Kullanarak QR Kodu Tarayın veya Google İki Faktörlü Kimlik Doğrulamasını Etkinleştirmek İçin Gizli Kodunuzu Kullanın",
    "OR": "VEYA",
    "Paste This Key In The Googe Authenticator App": "Bu Anahtarı Google Authenticator Uygulamasına Yapıştırın",
    "Show Secret Key": "Gizli Anahtarı Göster",
    "Enter The Six-Digit Code From The Application": "Uygulamadan Altı Haneli Kodu Girin",
    "6 Digit Code": "6 Haneli Kod",
    "Enable": "Etkinleştir",
    "Disable": "Devre Dışı Bırak",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Barkod Görselini Taradıktan Sonra, Uygulama Aşağıdaki Alana Girebileceğiniz Altı Haneli Bir Kod Gösterir",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "Google İki Faktörlü Kimlik Doğrulaması Şimdi Instapay Hesabınıza Bağlandı",
    "Min Amount:": "Minimum Miktar:",
    "Minimum Amount": "Minimum Tutar",
    "Maximum Amount": "Maksimum Tutar",
    "Identity Verification Required": "Kimlik doğrulama gerekli",
    "Select Your Wallet Currency": "Cüzdan para biriminizi seçin",
    "Setup Authenticator": "Kimlik doğrulayıcıyı ayarlayın",
    "Note": "Not",
    "This Is A Test Notice": "Bu bir test bildirimi",
    "Session Expired": "Oturum süresi doldu",
    "You have been logged out for security reasons, please login again to continue.": "Güvenlik nedeniyle çıkış yaptınız, devam etmek için tekrar giriş yapın.",
    "Redirecting You To Login Page In 5 Seconds": "5 saniye içinde giriş sayfasına yönlendiriliyorsunuz",
    "Searching": "Arama",
    "Recipient Mobile Number": "Alıcı telefon numarası",
    "TOP-UP SUMMARY": "Yükleme Özeti",
    "Amount": "Miktar",
    "Something went wrong while getting countries.": "Bir hata oluştu, ülkeler alınırken.",
    "Select Destination Country": "Hedef Ülkeyi Seçin.",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR, Paypal tarafından desteklenmiyor, USD varsayılan para birimi olarak kullanılacaktır.",
    "Select A Date": "Bir Tarih Seçin",
    "Select A Timezone": "Bir Zaman Dilimi Seçin",
    "Proceed": "Devam Et",
    "Proceeding": "Devam Ediliyor",
    "You Can Now Close This Webpage And Go Back To Instagram": "Artık bu web sayfasını kapatabilir ve Instagram'a geri dönebilirsiniz",
    "Select A Time": "Bir Zaman Seçin",
    "Location Permission Denied": "Konum İzni Reddedildi",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Daha önce izin reddedildikten sonra tarayıcınızda konum hizmetlerini etkinleştirmek için aşağıdaki talimatları izleyin:",
    "1: Chrome Browser": "1: Chrome Tarayıcı",
    "Open the Chrome app.": "Chrome uygulamasını açın.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Sağ üst köşedeki üç noktalı menüye dokunun ve Ayarlar'ı seçin.",
    "Scroll down to Site Settings > Location.": "Site Ayarları > Konum bölümüne ilerleyin.",
    "Locate the website in the list or search for it.": "Listeden web sitesini bulun veya arayın.",
    "Tap the website and select Allow under Location Access.": "Web sitesine dokunun ve Konum Erişimi altında İzin Ver'i seçin.",
    "2: Firefox Browser": "2: Firefox Tarayıcı",
    "Open the Firefox app.": "Firefox uygulamasını açın.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Sağ alt köşedeki üç çizgili menüye dokunun ve Ayarlar'a gidin.",
    "Select Privacy & Security > Site Permissions > Location.": "Gizlilik ve Güvenlik > Site İzinleri > Konum'u seçin.",
    "Find the website in the list and set the permission to Allow.": "Listeden web sitesini bulun ve izni İzin Ver olarak ayarlayın.",
    "3: Samsung Internet Browser": "3: Samsung İnternet Tarayıcı",
    "Open the Samsung Internet app.": "Samsung Internet uygulamasını açın.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Sağ alt köşedeki üç çizgili menüye dokunun ve Ayarlar'ı seçin.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Siteler ve İndirmeler > Site İzinleri > Konum'a gidin.",
    "Find the website and change the permission to Allow.": "Web sitesini bulun ve izni İzin Ver olarak değiştirin.",
    "4: Safari Browser": "4: Safari Tarayıcı",
    "Open the Settings app.": "Ayarlar uygulamasını açın.",
    "Scroll down and select Safari.": "Aşağı kaydırın ve Safari'yi seçin.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Konuma dokunun ve Sonraki Sefer Sor veya Uygulamayı Kullanırken İzin Ver olarak ayarlayın.",
    "Revisit the website and when prompt, allow location access": "Web sitesini yeniden ziyaret edin ve istendiğinde konum erişimine izin verin.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "İpucu: Web sitesi tarayıcı ayarlarında görünmüyorsa, tarayıcınızın önbelleğini veya geçmişini temizleyin ve tekrar deneyin.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Bir ödeme talebi göndermek için lütfen konumunuzu paylaşın—bu, güvenliği artırmamıza ve daha sorunsuz bir deneyim için güven oluşturmamıza yardımcı olur!",
    "Decline": "Reddet",
    "Share Location": "Konum paylaş",
     "Already Exist": "Zaten Var",
  "Withdraw": "Çekmek",
  "Seller Left A reply": "Satıcı bir yanıt bıraktı",
  "Check Now": "Şimdi Kontrol Et",
  "You Left A Review": "Bir İnceleme Bıraktınız",
  "View Larger Map": "Daha Büyük Haritayı Görüntüle",
  "English (UK)": "İngilizce (Birleşik Krallık)",
  "QR Code Title": "QR Kod Başlığı",
   "Notice!": "Dikkat!",
  "By proceeding with this transaction, I hereby confirm that I am acting independently and of my own free will. I affirm that I have not been influenced, coached, coerced, or persuaded by any third party to carry out this transaction for investment purposes or any other reason.": "Bu işlemi gerçekleştirerek, bağımsız ve kendi özgür irademle hareket ettiğimi onaylıyorum. Bu işlemi yatırım amaçlı veya başka herhangi bir sebeple yapmam için üçüncü bir taraf tarafından etkilenmediğimi, yönlendirilmediğimi, zorlanmadığımı veya ikna edilmediğimi beyan ederim.",
  "I understand that this confirmation is required to ensure compliance and to safeguard my account and transactions.": "Bu onayın, uyumluluğu sağlamak ve hesabımı ile işlemlerimi korumak için gerekli olduğunu anlıyorum.",
  "Confirm and Proceed": "Onayla ve Devam Et",
  "Blocked Wallet": "Bloke Cüzdan",
  "Nominee's Full Name": "Adayın Tam Adı",
  "Insufficient Balance": "Yetersiz Bakiye",
  "Upload Photo": "Fotoğraf Yükle",
   "Security Alert": "Güvenlik Uyarısı",
  "Please remember to add your security questions for smooth experience. You will not be able to access the features of InstaPay unless You add the security questions.": "Sorunsuz bir deneyim için güvenlik sorularınızı eklemeyi unutmayın. Güvenlik sorularınızı eklemezseniz InstaPay özelliklerine erişemezsiniz.",
  "Setup Security Questions": "Güvenlik Sorularını Ayarla",
  "Geo Fencing": "Coğrafi Sınırlandırma",
  "Manage Payment & Quotation Access": "Ödeme ve Teklif Erişimini Yönet",
  "Control countries from where you can receive Payment Requests and Quotations. Toggle on/off access for specific countries.": "Ödeme talepleri ve teklifler alabileceğiniz ülkeleri kontrol edin. Belirli ülkeler için erişimi açın/kapatın.",
  "Search Countries ": "Ülke Ara",
  "Filter": "Filtre",
  "Payment Requests": "Ödeme Talepleri",
  "#No": "#No",
  "Country": "Ülke",
  "Account’s Country": "Hesap Ülkesi",
  "Current Location": "Mevcut Konum",
  "Save Changes": "Değişiklikleri Kaydet",
  "Asia": "Asya",
  "Europe": "Avrupa",
  "Africa": "Afrika",
  "Oceania": "Okyanusya",
  "Americas": "Amerika",
  "Polar": "Kutup",
  "Finger Biometric": "Parmak Biyometrisi",
  "Confirm": "Onayla",
  "Resend available in 77 seconds": "77 saniye içinde tekrar gönderilebilir",
  "Invalid OTP!": "Geçersiz OTP!",
  "Finger Biometric Updated": "Parmak biyometrisi güncellendi",
  "Your finger biometric has been disabled successfully!": "Parmak biyometriniz başarıyla devre dışı bırakıldı!",
  "Your finger biometric has been enabled successfully!": "Parmak biyometriniz başarıyla etkinleştirildi!",
  "Afghanistan": "Afganistan",
  "Armenia": "Ermenistan",
  "Azerbaijan": "Azerbaycan",
  "Bahrain": "Bahreyn",
  "Bangladesh": "Bangladeş",
  "Bhutan": "Bhutan",
  "Brunei": "Brunei",
  "Cambodia": "Kamboçya",
  "China": "Çin",
  "East Timor": "Doğu Timor",
  "Georgia": "Gürcistan",
  "Hong Kong S.A.R.": "Hong Kong Ö.İ.B.",
  "India": "Hindistan",
  "Indonesia": "Endonezya",
  "Iran": "İran",
  "Iraq": "Irak",
  "Israel": "İsrail",
  "Japan": "Japonya",
  "Jordan": "Ürdün",
  "Kazakhstan": "Kazakistan",
  "Kuwait": "Kuveyt",
  "Kyrgyzstan": "Kırgızistan",
  "Laos": "Laos",
  "Lebanon": "Lübnan",
  "Macau S.A.R.": "Makao Ö.İ.B.",
  "Malaysia": "Malezya",
  "Maldives": "Maldivler",
  "Mongolia": "Moğolistan",
  "Myanmar": "Myanmar",
  "Nepal": "Nepal",
  "North Korea": "Kuzey Kore",
  "Oman": "Umman",
  "Palestinian Territory Occupied": "İşgal Altındaki Filistin Toprakları",
  "Philippines": "Filipinler",
  "Qatar": "Katar",
  "Saudi Arabia": "Suudi Arabistan",
  "Singapore": "Singapur",
  "South Korea": "Güney Kore",
  "Sri Lanka": "Sri Lanka",
  "Syria": "Suriye",
  "Taiwan": "Tayvan",
  "Tajikistan": "Tacikistan",
  "Thailand": "Tayland",
  "Turkey": "Türkiye",
  "Turkmenistan": "Türkmenistan",
  "United Arab Emirates": "Birleşik Arap Emirlikleri",
  "Uzbekistan": "Özbekistan",
  "Vietnam": "Vietnam",
  "Yemen": "Yemen",
  "Åland Islands": "Åland Adaları",
  "Albania": "Arnavutluk",
  "Andorra": "Andorra",
  "Austria": "Avusturya",
  "Belarus": "Beyaz Rusya",
  "Belgium": "Belçika",
  "Bosnia and Herzegovina": "Bosna-Hersek",
  "Bulgaria": "Bulgaristan",
  "Croatia": "Hırvatistan",
  "Cyprus": "Kıbrıs",
  "Czech Republic": "Çek Cumhuriyeti",
  "Denmark": "Danimarka",
  "Estonia": "Estonya",
  "Faroe Islands": "Faroe Adaları",
  "Finland": "Finlandiya",
  "France": "Fransa",
  "Germany": "Almanya",
  "Gibraltar": "Cebelitarık",
  "Greece": "Yunanistan",
  "Guernsey and Alderney": "Guernsey ve Alderney",
  "Hungary": "Macaristan",
  "Iceland": "İzlanda",
  "Ireland": "İrlanda",
  "Italy": "İtalya",
  "Jersey": "Jersey",
  "Kosovo": "Kosova",
  "Latvia": "Letonya",
  "Liechtenstein": "Lihtenştayn",
  "Lithuania": "Litvanya",
  "Luxembourg": "Lüksemburg",
  "Malta": "Malta",
  "Isle of Man": "Man Adası",
  "Moldova": "Moldova",
  "Monaco": "Monako",
  "Montenegro": "Karadağ",
  "Netherlands": "Hollanda",
  "North Macedonia": "Kuzey Makedonya",
  "Norway": "Norveç",
  "Poland": "Polonya",
  "Portugal": "Portekiz",
  "Romania": "Romanya",
  "Russia": "Rusya",
  "San Marino": "San Marino",
  "Serbia": "Sırbistan",
  "Slovakia": "Slovakya",
  "Slovenia": "Slovenya",
  "Spain": "İspanya",
  "Svalbard and Jan Mayen Islands": "Svalbard ve Jan Mayen Adaları",
  "Sweden": "İsveç",
  "Switzerland": "İsviçre",
  "Ukraine": "Ukrayna",
  "United Kingdom": "Birleşik Krallık",
  "Vatican City": "Vatikan Şehri",
  "Algeria": "Cezayir",
  "Angola": "Angola",
  "Benin": "Benin",
  "Botswana": "Botsvana",
  "British Indian Ocean Territory": "Britanya Hint Okyanusu Toprakları",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cameroon": "Kamerun",
  "Cape Verde": "Yeşil Burun Adaları",
  "Central African Republic": "Orta Afrika Cumhuriyeti",
  "Chad": "Çad",
  "Comoros": "Komorlar",
  "Congo": "Kongo",
  "Côte d'Ivoire (Ivory Coast)": "Fildişi Sahili",
  "Democratic Republic of the Congo": "Demokratik Kongo Cumhuriyeti",
  "Djibouti": "Cibuti",
  "Egypt": "Mısır",
  "Equatorial Guinea": "Ekvator Ginesi",
  "Eritrea": "Eritre",
  "Ethiopia": "Etiyopya",
  "French Southern Territories": "Fransız Güney Toprakları",
  "Gabon": "Gabon",
  "Gambia": "Gambiya",
  "Ghana": "Gana",
  "Guinea": "Gine",
  "Guinea-Bissau": "Gine-Bissau",
  "Kenya": "Kenya",
  "Lesotho": "Lesotho",
  "Liberia": "Liberya",
  "Libya": "Libya",
  "Madagascar": "Madagaskar",
  "Malawi": "Malavi",
  "Mali": "Mali",
  "Mauritania": "Moritanya",
  "Mauritius": "Mauritius",
  "Mayotte": "Mayotte",
  "Morocco": "Fas",
  "Mozambique": "Mozambik",
  "Namibia": "Namibya",
  "Niger": "Nijer",
  "Nigeria": "Nijerya",
  "Réunion": "Réunion",
  "Rwanda": "Ruanda",
  "Saint Helena": "Saint Helena",
  "São Tomé and Príncipe": "São Tomé ve Príncipe",
  "Senegal": "Senegal",
  "Seychelles": "Seyşeller",
  "Sierra Leone": "Sierra Leone",
  "Somalia": "Somali",
  "South Africa": "Güney Afrika",
  "South Sudan": "Güney Sudan",
  "Sudan": "Sudan",
  "Swaziland (Eswatini)": "Esvatini",
  "Tanzania": "Tanzanya",
  "Togo": "Togo",
  "Tunisia": "Tunus",
  "Uganda": "Uganda",
  "Western Sahara": "Batı Sahra",
  "Zambia": "Zambiya",
  "Zimbabwe": "Zimbabve",
  "American Samoa": "Amerikan Samoası",
  "Australia": "Avustralya",
  "Christmas Island": "Christmas Adası",
  "Cocos (Keeling) Islands": "Cocos (Keeling) Adaları",
  "Cook Islands": "Cook Adaları",
  "Fiji": "Fiji",
  "French Polynesia": "Fransız Polinezyası",
  "Guam": "Guam",
  "Kiribati": "Kiribati",
  "Marshall Islands": "Marshall Adaları",
  "Micronesia": "Mikronezya",
  "Nauru": "Nauru",
  "New Caledonia": "Yeni Kaledonya",
  "New Zealand": "Yeni Zelanda",
  "Niue": "Niue",
  "Norfolk Island": "Norfolk Adası",
  "Northern Mariana Islands": "Kuzey Mariana Adaları",
  "Palau": "Palau",
  "Papua New Guinea": "Papua Yeni Gine",
  "Pitcairn Islands": "Pitcairn Adaları",
  "Samoa": "Samoa",
  "Solomon Islands": "Solomon Adaları",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Tuvalu": "Tuvalu",
  "Vanuatu": "Vanuatu",
  "Wallis and Futuna": "Wallis ve Futuna",
  "North & South America": "Kuzey ve Güney Amerika",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua ve Barbuda",
  "Argentina": "Arjantin",
  "Aruba": "Aruba",
  "Barbados": "Barbados",
  "Belize": "Belize",
  "Bermuda": "Bermuda",
  "Bolivia": "Bolivya",
  "Bonaire, Sint Eustatius, and Saba": "Bonaire, Sint Eustatius ve Saba",
  "Brazil": "Brezilya",
  "Canada": "Kanada",
  "Cayman Islands": "Cayman Adaları",
  "Chile": "Şili",
  "Colombia": "Kolombiya",
  "Costa Rica": "Kosta Rika",
  "Cuba": "Küba",
  "Curaçao": "Curaçao",
  "Dominica": "Dominika",
  "Dominican Republic": "Dominik Cumhuriyeti",
  "Ecuador": "Ekvador",
  "El Salvador": "El Salvador",
  "Falkland Islands": "Falkland Adaları",
  "French Guiana": "Fransız Guyanası",
  "Greenland": "Grönland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guatemala": "Guatemala",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Honduras": "Honduras",
  "Jamaica": "Jamaika",
  "Martinique": "Martinik",
  "Mexico": "Meksika",
  "Montserrat": "Montserrat",
  "Nicaragua": "Nikaragua",
  "Panama": "Panama",
  "Paraguay": "Paraguay",
  "Peru": "Peru",
  "Puerto Rico": "Porto Riko",
  "Saint Kitts and Nevis": "Saint Kitts ve Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint Pierre ve Miquelon",
  "Saint Vincent and the Grenadines": "Saint Vincent ve Grenadinler",
  "Saint Barthélemy": "Saint Barthélemy",
  "Saint Martin (French part)": "Saint Martin (Fransız bölümü)",
  "Sint Maarten (Dutch part)": "Sint Maarten (Hollanda bölümü)",
  "South Georgia and the South Sandwich Islands": "Güney Georgia ve Güney Sandwich Adaları",
  "Suriname": "Surinam",
  "The Bahamas": "Bahamalar",
  "Trinidad and Tobago": "Trinidad ve Tobago",
  "Turks and Caicos Islands": "Turks ve Caicos Adaları",
  "United States": "Amerika Birleşik Devletleri",
  "United States Minor Outlying Islands": "ABD Küçük Uzak Adaları",
  "Uruguay": "Uruguay",
  "Venezuela": "Venezuela",
  "British Virgin Islands": "Britanya Virjin Adaları",
  "U.S. Virgin Islands": "ABD Virjin Adaları",
  "Antarctica": "Antarktika",
  "Your Preferences Updated Successfully": "Tercihleriniz başarıyla güncellendi"
}