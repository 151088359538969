export const ha = {
    "How Others Can Find You": "Yaya sauran suke iya ka gano",
    "Schedules": "Mafiɗi",
    "Login": "Shiga",
    "Start Your InstaPay Journey Today!": "Fara Tafiyarka ta InstaPay Yau!",
    "Phone Number or Email": "Lambar Waya ko Imel",
    "Login Using Social Media": "Shiga da Amfani da Kafofin Sada Zumunta",
    "Continue": "Ci gaba",
    "Don't Have an Account? Sign Up": "Baka da Asusun? Yi Rijista",
    "Sign Up": "Yi Rijista",
    "Edit": "Shirya",
    "Enter Your Password": "Shigar da Kalmar Sirrinka",
    "Forgot Password?": "Manta Kalmar Sirri?",
    "Account Type": "Nau'in Asusun",
    "Individual": "Mutum",
    "Business": "Kasuwanci",
    "Already Have an Account? Sign In": "Kana da Asusun? Shiga",
    "Sing In": "Shiga",
    "Enter Your Personal Details": "Shigar da Bayananka na Kai",
    "Add Photo or Logo": "Ƙara Hoto ko Tambari",
    "Enter Your Name": "Shigar da Sunanka",
    "Back": "Komawa",
    "Phone Number": "Lambar Waya",
    "Email Address": "Adireshin Imel",
    "Create a Strong Password": "Ƙirƙiri Kalmar Sirri Mai Ƙarfi",
    "Password Requirements": "Bukatun Kalmar Sirri",
    "Must be between 9 and 64 characters": "Dole ne tsakanin haruffa 9 zuwa 64",
    "Include at least two of the following:": "Haɗa aƙalla biyu daga cikin waɗannan:",
    "Uppercase character": "Harafin Babba",
    "Lowercase character": "Harafin Ƙarami",
    "Number": "Lambar",
    "Special character": "Harafin Musamman",
    "Confirm Password": "Tabbatar da Kalmar Sirri",
    "Finalize Your Process": "Kammala Tsarinka",
    "Referral Code (Optional)": "Lambar Shawarwari (Zabi)",
    "It will autofill if you're signing up with an invitation link.": "Zai cika ta atomatik idan kana yin rijista da hanyar haɗin gayyata.",
    "I agree with": "Na yarda da",
    "Terms & Conditions": "Sharudda & Yanayi",
    "Privacy Policy": "Manufar Sirri",
    "Create Your Account": "Ƙirƙiri Asusunka",
    "Enter Code": "Shigar da Lambar",
    "A one-time verification code has been sent to your email address  and phone number.": "An aika lambar tabbatarwa sau ɗaya zuwa adireshin imel ɗinka da lambar waya.",
    "Resend available in ": "Za a iya sake aikawa a cikin",
    "seconds": "daƙiƙa",
    "Verify": "Tabbatar",
    "Verification Code Verified": "An Tabbatar da Lambar Tabbatarwa",
    "Please continue to the next step.": "Da fatan za a ci gaba zuwa mataki na gaba.",
    "Finish": "Gama",
    "Finishing": "Ƙarewa",
    "Company Name": "Sunan Kamfani",
    "Enter Your Business Details": "Shigar da Bayanan Kasuwancinka",
    "Country ": "Ƙasa",
    "You cannot change the country once your account is verified.": "Ba za ka iya canza ƙasar da zarar an tabbatar da asusunka ba.",
    "Learn more": "Ƙarin koyo",
    "Optional": "Zabi",
    "Continuing": "Ci gaba",
    "Didn't receive the verification code?": "Ba ka karɓi lambar tabbatarwa ba?",
    "Resend Now": "Aika Yanzu",
    "Search Here": "Bincika Anan",
    "Search for users, services, and more on InstaPay": "Nemi masu amfani, ayyuka, da ƙari akan InstaPay",
    "Search users here": "Nemi masu amfani anan",
    "Earn money with our affiliate program!": "Samu kuɗi tare da shirinmu na haɗin gwiwa!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Samu kuɗin shiga na rashin aiki ta hanyar raba lambar haɗin gwiwar InstaPay ɗinku tare da mabiyanku. Ga kowane ma'amala da suka yi,\nza ku samu kwamiti, ta hakan ku ƙara yawan ribar da kuke samu daga tasirinku akan layi.",
    "Learn More": "Ƙarin Koyo",
    "Balance Amount": "Adadin Kuɗi a Ma'auni",
    "You spent less compared to last month.": "Ka kashe ƙasa idan aka kwatanta da watan da ya gabata.",
    "Referral Rewards": "Kyaututtukan Shawarwari",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Saurin Canja wurin zuwa Lambobin Tuntuɓarku Na Baya-bayan nan:",
    "Quick Transfer" :"Canja wurin Asusun gaggawa",
    "Number of Transactions": "Yawan Ma'amaloli",
    "Total Transaction Amount": "Jimlar Adadin Ma'amala",
    "Total Sent": "Jimlar Aika",
    "Total Received": "Jimlar Karɓa",
    "Add Funds": "Ƙara Kuɗi",
    "Send Money": "Aika Kuɗi\n",
    "Request Money": "Neman Kuɗi",
    "Send a Quote": "Aika Fa'ida",
    "Pay With Crypto": "Biya da Crypto",
    "Payment Insights": "Haske akan Biyan Kuɗi",
    "Recent Transactions ": "Ma'amaloli na Kwanan nan",
    "Today": "Yau",
    "My Wallets": "Wallets ɗina",
    "Balance": "Ma'auni",
    "Wallet ID": "Wallet ID",
    "Monthly limit": "Iyakar Wata-wata",
    "Top Up": "Ƙara Kuɗi",
    "Summary": "Takaitawa",
    "Settings": "Saituna",
    "More": "Ƙari",
    "Upgrade to Business Account": "Haɓaka zuwa Asusun Kasuwanci",
    "Conversion": "Musayar",
    "Enter Amount": "Shigar da Adadi",
    "Exchanged Amount": "Adadin da Aka Musanya",
    "Convert": "Musanya",
    "You Are Converting": "Kuna Musanya",
    "From": "Daga",
    "To": "Zuwa",
    "Exchange Rate": "Ƙimar Musayar",
    "Fees": "Kuɗi",
    "You Will Receive": "Za Ku Karɓa",
    "Slide To Confirm": "Matsa Don Tabbatarwa",
    "Verification Code Confirmed - You'll be redirected to the next step": "An Tabbatar da Lambar Tabbatarwa - Za a maida ku zuwa mataki na gaba",
    "Moving to next step in": "Ana matsawa zuwa mataki na gaba a cikin",
    "Thanks for using InstaPay": "Na gode da amfani da InstaPay",
    "Your funds have been credited to your wallet.": "An saka kuɗin ku a cikin wallet ɗinku.",
    "Dashboard": "Allon Sarrafa",
    "Accounts": "Asusun",
    "Transactions": "Ma'amaloli",
    "Other Transactions": "Sauran Ma'amaloli",
    "Payments": "Biyan kuɗi",
    "Beneficiaries": "Masu Amfana",
    "Referrals": "Shawarwari",
    "Quotations": "Ƙididdiga",
    "Pending": "Ana Jira",
    "My QR Code Sticker": "Tambarin Lambar QR ɗina",
    "My Portfolio": "Fayil ɗina",
    "My Payment Address": "Adireshin Biyan Kuɗina",
    "Analytics": "Bincike",
    "Profile": "Bayanin Kai",
    "Dark Mode": "Yanayin Duhu",
    "Support": "Taimako",
    "Logout": "Fita",
    "Powered By": "Ƙarfafawa ta",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "A Swiss Company",
    "MAIN MENU": "MAIN MENU",
    "OTHERS": "OTHERS",
    "Wallet": "Wallet",
    "Default": "Default",
    "Breakdown": "Breakdown",
    "Credit": "Credit",
    "Debit": "Debit",
    "Wallet Management": "Wallet Management",
    "Top-up Your Wallet": "Top-up Your Wallet",
    "Download Statement": "Download Statement",
    "Block the Wallet": "Block the Wallet",
    "Wallet Status": "Wallet Status",
    "-Select-File-Type-": "-Select-File-Type-",
    "Download Now": "Download Now",
    "Downloading": "Downloading",
    "All": "All",
    "Sent": "Sent",
    "Received": "Received",
    "Requested": "Requested",
    "Quoted": "Quoted",
    "Search Transactions": "Search Transactions",
    "Date & Time": "Date & Time",
    "Type": "Type",
    "Transaction ID": "Transaction ID",
    "Recipient": "Recipient",
    "Amount": "Amount",
    "Status": "Status - Matsayi",
    "Payment Type": "Payment Type",
    "Sent Payments": "Sent Payments",
    "Received Payments": "Received Payments",
    "Date": "Date",
    "Cycles/Time": "Cycles/Time",
    "Payment As": "Payment As",
    "Name": "Name",
    "Cancel": "Cancel",
    "Subscriptions & Scheduled": "Subscriptions & Scheduled",
    "Select the option that matches your needs from the list below:": "Select the option that matches your needs from the list below:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Sauƙaƙe aika kuɗi ga abokai, iyali, ko kasuwanci a gida ko ƙasashen waje. Zaɓi daga zaɓuɓɓukan canja wurin daban-daban, ciki har da \najiyar banki, wallets na wayar hannu, wurin ɗaukar kuɗi, katunan biyan kuɗi, da canja wurin wallet-zuwa-wallet. Ji daɗin sauƙi da tsaron \nInstaPay don canja wurin kuɗi cikin sauri da sauƙi.",
    "International Transfer": "Canja Wuri na Ƙasa da Ƙasa",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Aika kuɗi zuwa ƙetare cikin sauƙi. Canja wurin kuɗi na ƙasa da ƙasa ga abokai, iyali, ko kasuwanci cikin dannawa kaɗan. Zaɓi daga\nfannoni masu yawa na biyan kuɗi ciki har da asusun banki, kuɗin hannu/wallet na wayar hannu, wurin ɗaukar kuɗi, ko katin biyan kuɗi\ndon ma'amaloli masu dacewa da tsaro.",
    "Select the country where you want to send the money!": "Zaɓi ƙasar inda kake son aika kuɗin!",
    "Search Country": "Bincika Ƙasa",
    "Trouble Sending Money?": "Matsala wajen Aika Kuɗi?",
    "Confirm": "Tabbatar",
    "Select Payout Channel": "Zaɓi Hanyar Biyan Kuɗi",
    "Select your most convenient option to send the money.": "Zaɓi zaɓin da ya fi sauƙi a gare ka don aika kuɗin.",
    "Select The Bank": "Zaɓi Bankin",
    "Search Bank": "Bincika Banki",
    "Send money directly to a recipient's bank account. Enjoy the convenience and security of transferring funds seamlessly to any bank around the world.": "Aika kuɗi kai tsaye zuwa asusun bankin mai karɓa. Ji daɗin sauƙi da tsaro na canja wurin kuɗi cikin santsi zuwa kowane banki a faɗin duniya.",
    "Bank Transfer": "Canja wurin Banki",
    "Enter the amount": "Shigar da adadin",
    "Tax": "Haraji",
    "Amount the recipient will receive": "Adadin da mai karɓa zai samu",
    "Select Remarks / Reason": "Zaɓi Bayanai / Dalili",
    "Comments": "Sharhi",
    "Attach Files": "Haɗa Fayiloli",
    "Groups": "Ƙungiyoyi",
    "Manage Groups": "Sarrafa Ƙungiyoyi",
    "Add New Group": "Ƙara Sabuwar Ƙungiya",
    "Invite": "Gayyatar Mutum",
    "Manage Beneficiaries": "Sarrafa Masu Amfana",
    "Recipients List": "Jerin Masu Karɓa",
    "Search Contacts": "Bincika Lambobin Sadarwa",
    "Confirm Your Payment": "Tabbatar da Biyan Kuɗinka",
    "Select Country": "Zaɓi Ƙasa",
    "Select the Recipient's Payment Channel": "Zaɓi Hanyar Biyan Kuɗi ta Mai Karɓa",
    "Change Selection": "Canja Zaɓi",
    "You Are Transferring ": "Kuna Canja wurin",
    "Oops! Something Went Wrong.": "Ayya! Wani Abu ya Samu Matsala.",
    "We're Sorry About That. ": "Muna da Hakuri game da Hakan.",
    "Go back to Payments Page.": "Koma zuwa Shafin Biyan Kuɗi.",
    "Wallet to Wallet Transfer": "Canja wurin Wallet zuwa Wallet",
    "Request a customized price quote from service providers or vendors.": "Nemi ƙimar farashi na musamman daga masu samar da ayyuka ko 'yan kasuwa.",
    "Enter the Wallet ID": "Shigar da Wallet ID",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Shigar da Wallet ID, Imel, ko Lambar Waya ta mai karɓa.",
    "Recipient not on InstaPay?": "Shin Mai Karɓan Ba Ya Kan InstaPay? Gayyace Su.",
    "Invite Them": "Gayyace Su",
    "My Beneficiaries": "Masu Amfanina",
    "Enter the Amount You Wish to Transfer.": "Shigar da Adadin da Kake Son Canja wurin.",
    "Sending to ": "Ana aikawa zuwa",
    "You Have Selected Wallet to Wallet Transfer": "Ka Zaɓi Canja wurin Wallet zuwa Wallet",
    "Authenticate Your Payment.": "Tabbatar da Biyan Kuɗinka.",
    "You are transferring": "Kuna canja wurin",
    "Total ": "Jimla",
    "Where Is My Payment?": "Ina Biyana yake?",
    "Share": "Raba",
    "Your Payment Is Confirmed": "An Tabbatar da Biyan Kuɗinka",
    "QR Code Payment": "Biyan Kuɗi ta QR Code",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Canja wurin kuɗi nan take zuwa wasu masu amfani da InstaPay tare da sabis ɗinmu na wallet-to-wallet mai aminci. Aika da karɓar kuɗi cikin sauƙi \nba tare da wata matsala ba cikin daƙiƙoƙi.",
    "Scan QR with Your Camera or Upload from Your Device.": "Sken QR tare da Kyamararka ko Aiko daga Na'urarka.",
    "Upload from Your Device.": "Aiko daga Na'urarka.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Ko kuma, Yi Amfani da Adireshin Biyan Kuɗi na Musamman na InstaPay (UPA)",
    "Enter InstaPay UPA": "Shigar da InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Shin Mai Karɓan Ba Ya Kan InstaPay? Gayyace Su",
    "Verify QR Code": "Tabbatar da QR Code",
    "Scan/Upload QR Code of the Recipient": "Sken/Upload QR Code na Mai Karɓa",
    "Payment Confirmed": "An Tabbatar da Biyan",
    "Request customized price quotes from service providers or vendors to receive accurate cost estimates for the services or products you need.": "Nemi ƙididdiga na musamman daga masu samar da ayyuka ko 'yan kasuwa don samun ƙimar kuɗi madaidaiciya ga ayyukan ko samfuran da kuke buƙata.",
    "Choose The Beneficiary or Group": "Zaɓi Mai Amfana ko Ƙungiya",
    "Enter the Amount You Wish to Request.": "Shigar da Adadin da Kake Son Nema.",
    "You Are Requesting Money": "Kuna Neman Kuɗi",
    "You are requesting": "Kuna neman",
    "As": "A Matsayin",
    "Conversion of Your Cryptocurrencies": "Musayar Kuɗaɗen Ku na Cryptocurrency",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Ku fuskanci tsarinmu mai amfani da mai amfani, aminci, da inganci na Cash-Out Crypto zuwa Musayar Fiat.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Zaɓi Cryptocurrency da Kake Nufin Sayarwa.",
    "Next": "Na Gaba",
    "Enter the Crypto Wallet Address of the Recipient.": "Shigar da Adireshin Wallet na Crypto na Mai Karɓa.",
    "Minimum Transactional Value: $100.": "Ƙimar Ma'amala Mafi Ƙaranci: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Lura: Farashin Crypto na canjawa da yawa. Duba ƙididdigar ƙarshe a shafin tabbatar da oda.",
    "Select Network": "Zaɓi Hanyar Sadarwa",
    "Send A Quote": "Aika Fa'ida",
    "Send a detailed price quote to your clients or customers. Present your services, products, or offers professionally with a personalized quotation.": "Aika ƙididdigar farashi ta cikakke ga abokan cinikinku ko kwastomomin ku. Gabatar da ayyukanku, samfuran, ko tayi cikin ƙwarewa tare da ƙididdiga ta musamman.",
    "Choose the Beneficiary": "Zaɓi Mai Amfana",
    "Allow Receiver to Bargain": "Bada Dama ga Mai Karɓa ya Tattauna",
    "Enter the Title for Your Quotation.": "Shigar da Take don Ƙididdigarka.",
    "Enter the Description for Your Quotation": "Shigar da Bayanin Ƙididdigarka",
    "You are Quoting": "Kuna Yiwa Ƙididdiga",
    "Title": "Take",
    "Description": "Bayani",
    "Attachments": "Haɗe-haɗe",
    "Confirm Your Quotation": "Tabbatar da Ƙididdigarka",
    "Verify The Quotation": "Tabbatar da Ƙididdiga",
    "Your quotation is confirmed": "An tabbatar da ƙididdigarku",
    "Request A Quote": "Nemi Kiyasi",
    "Coming Soon": "Ana Zuwa Nan Ba Da Jimawa Ba",
    "Add Beneficiary": "Ƙara Mai Amfana",
    "Search for Beneficiaries": "Neman Masu Amfana",
    "Connected Accounts": "Asusun da Aka Haɗa",
    "Details": "Bayanai",
    "First Name": "Sunan Farko",
    "Last Name": "Sunan Karshe",
    "Country": "Ƙasa",
    "City": "Birni",
    "Address Line": "Layin Adireshin",
    "Mobile Number": "Lambar Waya",
    "Relationship With Beneficiary": "Dangantaka da Mai Amfana",
    "Bank Account": "Asusun Banki",
    "Mobile Money/Mobile Wallet": "Kuɗin Waya/Mobile Wallet",
    "Payment Card": "Katin Biyan Kuɗi",
    "Crypto Wallet": "Wallet na Crypto",
    "InstaPay Account": "Asusun InstaPay",
    "Cash Pickup": "Daukar Kudi",
    "IBAN / SWIFT Code": "IBAN Ko Lambar Swift/BIC",
    "Swift Code": "Lambar Swift",
    "Account Number": "Lambar Asusun",
    "Bank Name": "Sunan Banki",
    "Branch Name": "Sunan Reshe",
    "Branch Street": "Titin Reshe",
    "Province": "Lardin",
    "Postal Code": "Lambar Fosta",
    "Mobile Money Provider": "Mai Bayar da Kuɗin Waya",
    "Wallet Name": "Sunan Wallet",
    "Wallet Number": "Lambar Wallet",
    "Card Holder Name": "Sunan Mai Riƙe da Kati",
    "Card Number": "Lambar Kati",
    "Expiry Date": "Ranar Karewa",
    "Crypto Currency": "Kuɗin Crypto",
    "Wallet Address": "Adireshin Wallet",
    "Wallet Holder Name": "Sunan Mai Riƙe da Wallet",
    "Wallet Currency": "Kuɗin Wallet",
    "Select Document Type": "Zaɓi Nau'in Takarda",
    "Enter Document Number": "Shigar da Lambar Takarda",
    "Add Individual Account": "Ƙara Asusun Mutum",
    "Add Business Account": "Ƙara Asusun Kasuwanci",
    "Company Address": "Adireshin Kamfani",
    "Company Email": "Imel na Kamfani",
    "Company Phone No": "Lambar Wayar Kamfani",
    "Total Referrals": "Jimlar Shawarwari",
    "My Earnings": "Kudin Shigana",
    "Your Referral ID": "ID na Tura Kai",
    "Share Your Unique Referral Link": "Raba Mahaɗin Tura Kai na Musamman",
    "How do I Refer a Friend to InstaPay?": "Yaya Zan Tura Aboki zuwa InstaPay?",
    "Step 01": "Mataki na 01",
    "Share your InstaPay unique referral link with your friends.": "Raba mahaɗin tura kai na musamman na InstaPay tare da abokanka.",
    "Step 02": "Step 02 - Mataki na 02",
    "Earn rewards when they transact $100 or more": "Samu lada idan suka yi mu'amala da $100 ko fiye",
    "Step 03": "Step 03 - Mataki na 03",
    "Earn up to $0.06 for every transaction your followers make.": "Samu har zuwa $0.06 ga kowane mu'amala da mabiyanka suka yi.",
    "My Referrals": " Na Tura Kai",
    "Referral Complete": "An Kammala Tura Kai",
    "Received Quotations": "An Karɓi Ƙididdiga",
    "Sent Quotations": "An Aika Ƙididdiga",
    "Search Pending Items": "Bincika Abubuwan Jiran",
    "Actions": "Matakan Aiki",
    "Received Requests": "An Karɓi Bukatun",
    "Sent Requests": "An Aika Bukatun",
    "No Actions": "Babu Matakan Aiki",
    "You Requested": "Ka Nema",
    "Via": "Ta Hanyar",
    "How was your experience?": "Yaya Gogewarka?",
    "Leave a Review": "Bar Sharhi",
    "Overall Ratings": "Jimillar Kimantawa",
    "Visit Profile": "Ziyarci Bayanin Kai",
    "Ratings": "Kimantawa",
    "Reviews": "Sharhi",
    "No reviews available": "Babu sharhin da ake da shi",
    "Select Your Currency": "Zaɓi Kuɗin Ka",
    "Proceed": "Ci Gaba",
    "Commision": "Hukumar",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Unlock Seamless Transactions with InstaPay QR Code Sticker - Buɗe Mu'amaloli Marasa Tsayawa tare da Sticker na Lambar QR ta InstaPay",
    "Effortless and Swift:": "Ba tare da kokari ba kuma da sauri.",
    "Instantly download your unique InstaPay QR code sticker.": "Sauke manhajar tambarin QR code na musamman na InstaPay nan take.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Kunna biyan kudi ba tare da tsangwama ba, ba tare da amfani da tsarin hada-hadar kudi mai rikitarwa irin su na'urorin biyan kudi ba.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Saurin tattara kudaden biya – babu buƙatar shigar da lambobin katin kuɗi ko waya mai gajiya.",
    "Each payment includes customer information for your convenience.": "Kowane biya ya ƙunshi bayanan abokin ciniki don sauƙin ku.",
    "Economically Smart:": "Wayewa a Tattalin Arziki:",
    "Zero activation fees – start immediately without upfront costs.": "Babu kuɗin kunna – fara nan take ba tare da biyan kuɗi na farko ba.",
    "Forget about fixed monthly charges; pay as you transact.": "Manta da cajin wata-wata; biya yayin da kake yin ma'amala.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Kwace kayan aiki; rashin kuɗin na'urar biyan kuɗi na nufin ƙarin tanadi a gare ku.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Ji daɗin ƙananan kuɗin ma'amala a kawai 0.75% a kowane biyan kuɗi ta QR code.",
    "We don't impose minimum commissions; you save more with each transaction.": "Ba mu sanya mafi ƙarancin kwamiti; kuna tanadi da yawa a kowace ma'amala.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Ji daɗin biyan kuɗi nan take, yana inganta gudanar da kuɗin ku.",
    "Make Every Transaction Count with InstaPay": "Tabbatar da Kowace Ma'amala tana da Muhimmanci tare da InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Sauke QR",
    "QR Code Status": "Status na QR Code",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Raba lambar QR Code ko hanyar adireshin biyan ku don karɓar kuɗi cikin sauri a cikin Wallet ɗin ku na InstaPay.",
    "QR Code Title": "Take na QR Code",
    "View Transactions": "Duba Ma'amaloli",
    "My QR Codes:": "Lambobin QR na:",
    "Load More": "Ɗaukar Ƙari",
    "Benefits Of Portfolio": "Fa'idodin Portfolio",
    "Benefits:": "Fa'idodi:",
    "Enhanced Visibility:": "Ƙara Ganuwa:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address.": "Ɗaga bayananku na jama'a. Portfolio ɗin ku yana aiki a matsayin nunin dindindin, ana samun dama kai tsaye daga Adireshin Biyan ku.",
    "Engage Your Audience:": "Haɗa Kai da Masu Sauraron Ku:",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Haɗa kai da baƙi ta hanyar tafiyar ku ta ƙirƙira ko ƙwararru. Bari ayyukanku su yi magana a madadinku, suna samar da zurfin haɗin gwiwa tare da abokan ciniki ko magoya baya da ke yiwuwa.",
    "Integrated Social Proof:": "Tabbatarwa ta Zamantakewa da aka Haɗa:",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Nuna tasirin ku na kafofin watsa labarun. Baƙi za su iya ganin yadda kuke isa ga kafofin watsa labarai, suna ƙara inganci da mahallin ga portfolio ɗin ku.",
    "Seamless Transactions:": "Ma'amaloli Marasa Matsala:",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Baiwa baƙi damar neman ko fara biyan kuɗi kai tsaye daga shafin ku na jama'a. Portfolio ɗin ku ba kawai yana nuna ayyukanku ba, har ma yana sauƙaƙa hulɗar kuɗi cikin sauƙi.",
    "Personalized Storytelling:": "Labarin Kai na Musamman:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Yi amfani da sashen 'Game da Ni' don raba labarinku, manufa, ko hangen nesa, wanda ke samar da alaƙa ta sirri tare da masu sauraron ku.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "My Portfolio' ba kawai hoton baje koli bane; kayan aiki ne don gina alamar ku, haɗa kai da masu sauraron ku, kuma saukake hulɗar kuɗin ku a InstaPay.\n",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Nuna Ayyukanku, Fadada Isarku. 'My Portfolio' shine murfin dijital dinku a InstaPay. Loda kuma nuna abubuwa daban-daban – daga hotuna masu jan hankali da bidiyo zuwa PDF masu bayani. \nKo kai zane-zane, mai zaman kansa, ko dan kasuwa ne, wannan fasalin yana baka damar gabatar da ayyukanka ko ayyukanka cikin hanyar gani mai jan hankali.",
    "Add/Manage Portfolio": "Ƙara/Sarrafa Portfolio",
    "Add New": "Ƙara Sabo",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maksimantar da Samunku a Kafofin Watsa Labarai da Adireshin Biyan Ku na InstaPay",
    "Make It Effortless for Your Audience to Support You:": "Sanya Yana da Sauki Ga Masu Sauraronku Don Tallafa Muku:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Sauƙi haɗa Adireshin Biyan Ku na InstaPay cikin sakonnin kafofin watsa labarun ku.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Sauƙaƙe kwafa da liƙa adireshin biyan kuɗin ku a cikin kowane ɗaya daga cikin sakonninku, labarai, ko a bayyane a bayanan martabarku.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Dacewa ga masu tasiri, masu zane-zane, 'yan kasuwa, ko kowa da kowa tare da al'umma da ke sha'awar nuna goyon bayansu.",
    "Instant Support, Endless Possibilities:": "Tallafi Nan take, Dama Mara iyaka:",
    "Transform the way you monetize your social media content.": "Sauya yadda kuke samun kudi daga abubuwan da kuke wallafawa a kafofin watsa labarai.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Baiwa mabiyanku damar aika muku kuɗi nan take da dannawa ɗaya kai tsaye daga hanyoyin sadarwar zamantakewar da suka fi so.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Dacewa don tallafi a ainihin lokacin yayin gudana kai tsaye, a matsayin tsarin tukwici don abubuwan da kuke halitta, ko don samar da kuɗi don babban aikin ku na gaba.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Ina Zaku Sanya Adireshin Biyan Ku na InstaPay Don Tasirin Mafi Girma:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Bayanan Kafofin Watsa Labarai: Makala shi a tarihin ku ko sashen game da ku don ganuwa akai-akai.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Sakonni & Sanarwa: Haɗa shi cikin sakonninku na mutum, musamman waɗanda ke nuna ayyukanku ko kira na musamman don tallafi.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Labarai & Faifan Bidiyo: Haɗa shi cikin labaran ku na hulɗa ko faifan bidiyo masu jan hankali don samun gudummawar nan take.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Gudana Kai Tsaye: Raba shi yayin zaman ku na Kai Tsaye, yana sa ya zama mai sauƙi ga masu kallo su bayar da gudummawa a ainihin lokaci.",
    "Simple, Fast, and Secure:": "Sauƙi, Azumi, kuma Tsaro:",
    "No complex setup – get started in moments.": "Babu tsarin saiti mai rikitarwa – fara a cikin lokuta.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Ji daɗin tsaro da saurin sarrafa biyan kuɗi na InstaPay da ake dogara da shi.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Karɓar kuɗi daga kowa, ko'ina, ba tare da buƙatar su da asusun InstaPay ba.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Daga Matsayin Wasan Kafofin Watsa Labaranku da Adireshin Biyan Ku na InstaPay",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Adireshin Biyan Kudi na Virtual)",
    "Payment Address Title": "Take na Adireshin Biya",
    "Currency": "Kudin",
    "Payment Address Description": "Bayanin Adireshin Biya",
    "Update": "Sabunta",
    "Updating": "Ana Sabuntawa",
    "Total Transaction": "Jimlar Ma'amala",
    "Last 30 days": "Ƙarshen kwanaki 30",
    "Last 1 month": "Ƙarshen wata 1",
    "Last 1 year": "Ƙarshen shekara 1",
    "Custom Date": "Kwanan Wata na Musamman",
    "Payment Methods": "Hanyoyin Biya",
    "Payment Types": "Nau'ikan Biya",
    "Requested Amount": "Adadin da Aka Nema",
    "Quoted Amount": "Adadin da Aka Ambata",
    "About Me": "Game da Ni",
    "Followers": "Mabiyansu",
    "InstaPay ID": "ID na InstaPay",
    "Download QR Code": "Sauke Lambar QR",
    "Initiate A Payment": "Fara Biyan Kudi",
    "Report this person": "Rahoto Wannan Mutum",
    "Add now": "Ƙara Yanzu",
    "Recent Reviews": "Bita na Kwanan nan",
    "Reviews As Seller": "Bita A Matsayin Mai Sayarwa",
    "Reviews As Buyer": "Bita A Matsayin Mai Siye",
    "Select Language": "Zabi Yare",
    "Profile Completion": "Kammala Bayanin Martaba",
    "Profile Completed": "An Kammala Bayanin Martaba",
    "Basic Info": "Bayanan Asali",
    "Username": "Sunan Mai Amfani",
    "Gender": "Jinsi",
    "Male": "Namiji",
    "Female": "Mace",
    "-Select-Gender-": "-Zabi-Jinsi-",
    "Date Of Birth": "Ranar Haihuwa",
    "Address": "Adireshi",
    "Edit Profile": "Gyara Bayanin Martaba",
    "Update Profile": "Sabunta Bayanin Martaba",
    "Updating Profile": "Ana Sabunta Bayanin Martaba",
    "Complete Now": "Kammala Yanzu",
    "Password": "Kalmar Sirri",
    "Change Password": "Canza Kalmar Sirri",
    "New Password": "Sabuwar Kalmar Sirri",
    "Re-enter New Password": "Sake Shigar da Sabuwar Kalmar Sirri",
    "Please follow this guide for a strong password": "Don Allah bi wannan jagorar domin samun kalmar sirri mai ƙarfi",
    "Include at least one special character.": "Haɗa aƙalla alamar musamman ɗaya.",
    "Minimum of 8 characters.": "Mafi ƙarancin haruffa 8.",
    "Include at least one number": "Haɗa aƙalla lamba ɗaya",
    "Change it often for enhanced security.": "Canza shi akai-akai domin ƙara tsaro.",
    "Security Questions": "Tambayoyin Tsaro",
    "Security Question": "Tambaya ta Tsaro",
    "Select your question": "Zabi tambayarku",
    "Answer To The Question": "Amsa ga Tambaya",
    "Nominee": "Wanda Aka Zaba",
    "Relationship with Nominee": "Alaƙa da Wanda Aka Zaba",
    "Nominee Contact Number": "Lambar Tuntuɓar Wanda Aka Zaba",
    "Nominee Address": "Adireshin Wanda Aka Zaba",
    "Delete Nominee": "Cire Wanda Aka Zaba",
    "Update Nominee": "Sabunta Wanda Aka Zaba",
    "Details Updated Successfully!": "Bayanai An Sabunta Su Nasara!",
    "Success": "Nasara",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay platform itself. You acknowledge that no wet signature is required to \nappoint the nominee, and the online registration will be considered final and binding. In the event of the death of an InstaPay Wallet holder, the balance in the Wallet \nwill be transferred to the registered Nominee. KEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to the Nominee. The Nominee \nwill provide any documents required by InstaPay, including those for identification and proof of death.": "Ka iya zaɓa Nominee na damuwa, idan aka samu damuwa, ta yadda za ku yi amfani da shafukan InstaPay. Kuna sanar da ba za a buƙatar saƙo ƙara ga Nominee, kuma a bayyana cewa daftarwa \nta intanet zai kasance ƙarshe da ya kafa. A cikin damuwa da mutumin da ya mutu mai tsabta ga hali, rikicin a cikin Wallet zai yi nadin ga Nominee da aka daftar. KEMIT KINGDOM zai fara ba da \ntallafin sa kan duk wani damuwan bayi bayan gudanar da sakon Wallet ga Nominee. Nominee zai ba da aikace-aikacen dokokin da suke buƙatar InstaPay, kamar su na bayanin hakkin da suka \nbuƙata da gaskiya.",
    "Add Nominee": "Zaɓa Nominee",
    "Two Factor Authentication": "Biyan kwafin Biyu",
    "SMS Number": "Namban SMS",
    "Updated Successfully!": "Cire Don ci gaba!",
    "Social Network Accounts": "Shafukan Yanar Gizo na Jam'iyyu",
    "Here, you can set up and manage your integration settings.": "A nan, za ka iya kafa da ayyukan kwarewa da kuma kula da tallafin intanet na ku.",
    "Social Network Account": "Shafukan Yanar Gizo",
    "Activate Account": "Kariya Akauntin",
    "Enter the code below in the InstaPay Chatbot.": "Shiga ta koda ta hanyar da lambobin da ke ɗaya a InstaPay Chatbot.",
    "The code will expire in ": "Lambobin zai bincike a",
    "Notifications": "Bayanai",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Kula da yadda za ka sami bayanai. Wannan ayyuka sun nuna ga abubuwan da kake damu da su.",
    "Activity": "Abubuwa",
    "Email": "Imel",
    "Push": "Yiwa Yanar Gizo",
    "SMS": "SMS",
    "Source": "Makirci",
    "Payment Requests": "Maɓayye na Kuɗi",
    "Bulk Payments": "Kudi Masu Kudi",
    "Identity Verification": "Sabbin Binciken Kwarewa",
    "Verify Your Identity": "Kwarewa Ta Lura Ku",
    "Identity Verification Status:": "Hali Na Kwarewa Ta Lura:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Ka bayyana yadda za ka ce ku da sababbin da za ka yi amfani da InstaPay.",
    "Enter Your Message": "Shiga Manufofin Ku",
    "Select Occupation": "Zaɓa Sabon Aiki",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified \ntranslation is required.": "Za a iya kula manufofin da aikin ƙasar ya bayar da shi tare da hoto. Duk manufofin da ake kula zai bayar da harsashin Latin. Idan ba haka ba, za a buƙatar hujjar da aka sa ba.",
    "Accepted Documents": "Manufofin da aka karɓi",
    "A national identity card": "Kati yankin tsarin jama'a",
    "A valid passport": "Kati yankin haɓaka",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Manufofin Tsarin ɗakunan launi (aka karɓa kawai idan aka bayyana ta wajen ƙasar Turai ko na Switzerland)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometric Facial Recognition: Kariya ga fideo tare da hoto na takardar banki",
    "Address Verification:": "Kariya ga ƙwarewar ƙaramin tsarin launi: Kariya ga fideo tare da hoto na takardar banki",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Rufe takardar banki ko bukatar buɗe kan wani fasali mai amfani da manufofin adireshin (sauki kawai da uku na wata a cikin watan guda biyu) don bukatar ayyuka ta adireshin.",
    "Additional Documents:": "Manufofin sauran:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough \nverifications, in accordance with its legal obligations.": "Kemit Kingdom ya ba da damuwa ya shawo damuwa don su bukatar sa ranar damuwa da manufofin sauran (Kwarewar Ba Da Ayyukan Samar Da Harshe - EDD) ko bayanai masu bukatun masu \namfani don yin kwarewa masu ɗaukaka, a karkashin ayyukan shirin da aka cire tsarin tsarin, a hanyoyin ayyukan hukuncin shi.",
    "Start Verification": "Farawa Kwarewa",
    "Sessions": "Rukuni",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Wannan shi ne jerin kayayyakin da suka sauke da ayyukan ku. Cire duk wanda ba ku sanar da shi.",
    "Your Withdrawal Options": "Zaɓuɓɓukan Janyewar Ku",
    "Choose your preferred method for withdrawing payments.": "Zaɓi hanyar da kuka fi so don janye kuɗi.",
    "No withdrawal options currently available in your country.": "Babu zaɓuɓɓukan janye kudi a halin yanzu a ƙasarku.",
    "Download Your Data": "Sauke Bayananku",
    "Request a Copy of Your Data": "Nemi Kwafin Bayanan Ku",
    "Submit a request to download a copy of your data": "Gabatar da buƙata don saukar da kwafin bayananku",
    "Which Data Should Be Included in the Download?": "Wane Bayani Ya Kamata a Haɗa a Saukarwa?",
    "Select all applicable options.": "Zaɓi duk zaɓuɓɓukan da suka dace.",
    "Personal Information": "Bayanan Kai",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Ana amfani da wannan bayanin don tabbatar da asusunku, keɓance ƙwarewar ku, kuma a tuntuɓe ku kamar yadda ake buƙata.",
    "Name and Date Of Birth": "Sunan ku da kwanan watan haihuwa",
    "Phone number": "Lambar waya",
    "Financial Information": "Bayanan Kuɗi",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Muna amfani da wannan bayanin don saukaka biyan kuɗi da aika ko karɓar kuɗi cikin dannawa kaɗan.",
    "Bank accounts": "Asusun banki",
    "Debit or credit cards": "Katunan debiti ko bashi",
    "Credit Information": "Bayanan Bashin",
    "Includes your credit application information": "Ya ƙunshi bayanan aikace-aikacen bashin ku",
    "Other Information (not included in file)": "Sauran Bayanai (ba a haɗa a fayil ba)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Ya ƙunshi bayanan na'ura, bayanan amfani na fasaha, bayanan wuri na geolocation, zaɓin talla, tarihin yarda, da bayanan da ake amfani da su don wasu ayyuka kamar bashi, tabbatar da shaidar, \nsadarwa tare da PayPal, da masu sarrafa na uku.",
    "Choose file type:": "Zaɓi nau'in fayil:",
    "Select file type": "Zaɓi nau'in fayil",
    "PDF file can be easily opened on any computer": "Ana iya buɗe fayil din PDF cikin sauƙi a kan kowace kwamfuta",
    "Submit Request": "Aika Buƙata",
    "Delete Your Account": "Nemi a goge asusunku",
    "Ask us to delete your data and close this account": "Ku tambaye mu mu goge bayananku kuma mu rufe wannan asusun",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Muna amfani da bayananku don samar da ayyuka, don haka idan muka goge bayananku, dole ne mu kuma rufe asusunku.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Idan an iyakance asusunku, je zuwa Cibiyar Warware Matsaloli, zaɓi 'Je zuwa Iyakokin Asusun', sai ka danna 'Warware'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Tabbatar an biya duk wani bashin da ake bin ku. Ba za mu iya rufe asusunku ba har sai an yi haka.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Idan kana da ragowar bashi, danna mahadar Warware da ke kusa da ragowar kuɗin ka.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Da zarar an share asusunku, kuma idan kun cancanta, za mu rufe asusunku kuma mu goge bayanan ku. Muna bukatar mu ci gaba da riƙe wasu daga cikin bayananku saboda dalilai na doka da ka'ida.\nDon ƙarin koyo, duba",
    "privacy statement": "sanarwar sirri",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Za mu aiko muku da imel don sanar da ku matsayin, kuma idan akwai wasu matakai da ya kamata ku dauka.",
    "Have more questions about data deletion?": "Kuna da ƙarin tambayoyi game da share bayanai?",
    "Contact us": "Tuntuɓi mu",
    "I understand that account deletion is permanent and can't be reversed.": "Na fahimci cewa share asusu abu ne na dindindin kuma ba za a iya mayar da shi ba.",
    "Are you sure you want to close your account?": "Kuna tabbata kuna son rufe asusunku?",
    "Yes, Continue": "E, Ci gaba",
    "Logging In": "Shiga",
    "Complete your profile to streamline your transaction process.": "Cika bayanan ku don sauƙaƙe tsarin cinikayya.",
    "Skip": "Tsallake",
    "Complete Your Profile": "Cika Bayananku",
    "API Key": "Mabuɗin API",
    "Get API Key": "Samun Mabuɗin API",
    "Generating New API Key": "Samar da Sabon Mabuɗin API",
    "Generate API Key": "Samar da Mabuɗin API",
    "Regenerate API Key": "Sake Samar da Mabuɗin API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. First, sign up for an account at \n[your registration URL] and access the Developer Dashboard. Navigate to the 'API Keys' section, generate a new key, and ensure its secure storage.": "Don ganin damuwar da ayyukan daya daga cikin abubuwan da aka samar da ayyuka a cikin InstaPay, yi aikin da wani saukin kyauta domin samar da API key na ku. A nan, ajiye ayyukan a cikin \n[your registration URL] kuma shiga cikin Burtalin ƙwarewa. Kara godewa wurin 'Kalubale na API', yi wani sabon kyauta, kuma duba cewa ya kasance mai inganci.",
    "Delete API Key": "Share tukuna na API",
    "Webhook URL": "URL na Webhook",
    "KYB Verification": "Kadai na KYB",
    "Additional Files": "Manufofin sauran",
    "Business Verification": "Kadai na tasiri na kasa",
    "--Select-Your-Company-Type--": "--Zaɓa-jin-talatin-Labari-- (Zaɓa Tumbin Aiki Na Kuɗin Kuɗi)",
    "Documents Required:": "Manufofin Da Ake Buɗe",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Manufofin na rasuwar shirye-shirye na Kuɗin Kuɗi (Manufofin Dattijan Kayan Aiki na Kuɗin Kuɗi)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. It reflects the current status of the business \n(active or closed).": "Wannan manufofi ne ya dace da 'takardar ɗan' na kwampanya da aka buɗe daga wajen kasa na aiki. Ya nuna hali na ƙwarewa na aiki (kuma yayi amfani da shi ko ya ƙarɓi). Za a samu shi daga tsohon \nkasa na aiki na aiki.",
    "The document should be current, with an issuance date not exceeding 3 months.": "Manufofin ya bukatar samun shine a yau, da tarihinsa ba za a yi ƙarshe da ƙarancin watan guda biyu ba.",
    "The document must include the following details:": "Takardar dole ta ƙunshi waɗannan bayanan:",
    "Legal name of the company": "Sunan launin kuɗi na kwampanya",
    "Full registered address": "Cikakken adireshin rajista",
    "Company registration number": "Namban launin kuɗin kwampanya",
    "Date of incorporation": "Ranar ɗaukar ciki",
    "Description of the company's activities/purpose": "Bayani game da aiki/karatu na kwampanya",
    "Legal representative's information: full name, date and place of birth, residential address (minimum requirement: country of residence), and nationality": "Bayani na tsohon launin kuɗi: sunan launin kuɗi na gudun karɓi, ranar da aka ja, wani adireshin ɗaukar ciki (ayi nasarar karfafa: watan kasar ta gudun karɓi), da kuma ilimi",
    "If applicable, the document should also include:": "Idan ya dace, takardar ya kamata ta ƙunshi:",
    "Brand or trading names": "Sunan ko launin kuɗin buɗe",
    "Value Added Tax (VAT) number": "Lambar Harajin Ƙara Darajar (VAT)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, it may be provided as a separate document.": "Jerin Kuɗi Mai Sauka na Ma'ajin Kuɗi (UBOs) - wannan shi ne ayyukan shirya",
    "Proof of Legal Representative's Authority": "Shaida ta Wakilcin Shari'a",
    "This document validates an individual's authority to represent the company": "Wannan manufofi ya ba da damuwa kan damuwar damuwa na wani mutum don taimakawa kwampanya.",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a document such as a Power of Attorney or Delegation \nof Powers must be secured.": "Kamar yadda babu damuwa, tsohon launin kuɗi ya samu suna a cikin manufofi na kwampanya. A bayan haka, idan hakan bai hana damuwa, za a bukaci samun wani manufofi kamar misalin Kuɗin Aiki ko \nKarɓar Kuɗi don taimakawa.",
    "Power of Attorney/Delegation of Powers:": "Ikon Lauya/Wakilcin Iko:",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal representative in the company's registry extract.": "Wanda ke ba da iko dole ne ya kasance da halattacciyar damar yin haka. Wannan mutum ya kamata a jera shi a matsayin wakilin shari'a a cikin fitar da rajistar kamfanin.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Tsayar da Mallaka da Takardun Masu Amfani da Amfani na Ƙarshe (UBOs)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the stock exchange website displaying \nthe company's details.": "Akwai buƙatar bayar da shaida na jera kamfanin a kan musayar hannun jari da ya dace. Wannan na iya zama shafi daga gidan yanar gizon musayar hannun jari da ke nuna bayanan kamfanin.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of the shares will be subject to \nUltimate Beneficial Owner (UBO) verification requirements.": "Idan kasa da 75% na hannun jarin kamfanin suna hannun jama'a, dukkan masu hannun jari da suka mallaki ko suke sarrafa sama da 25% na hannun jarin za su kasance a ƙarƙashin buƙatun tabbatar \nda Mai Amfani da Amfani na Ƙarshe (UBO).",
    "Collection of Identification Documents": "Tattarawar Takardun Shaida",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification documents must be written in the Latin alphabet. If not, \na certified translation is required.": "Takardar shaidar dole ne gwamnati ta bayar, dauke da hoton mutum. Dole ne duk takardun shaida su kasance a rubuce cikin haruffan Latin. Idan ba haka ba, ana buƙatar fassara da aka tabbatar.",
    "Accepted documents:": "Takardu da aka karɓa:",
    "Valid National Identity Card": "Katin Shaidar Ƙasa Mai Inganci",
    "Valid Passport": "Fasfo Mai Inganci",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Izinin Zama na Dindindin - Waɗannan za a karɓa ne kawai idan an fitar da su ta ƙasar Turai.",
    "Only legal representatives are subject to the identification document collection process.": "Kawai wakilan shari'a ne ke ƙarƙashin tsarin tattarawar takardun shaida.",
    "Additional Requirement:": "Ƙarin Bukata:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties (the individual granting authority and the one receiving it) must provide a copy of their ID.": "A yanayin da ake da Wakilci na Iko ko Delega na Iko, ɓangarorin biyu (mutumin da ke bada iko da kuma wanda ke karɓar sa) dole ne su samar da kwafin katin shaidar su.",
    "Additional Documents": "Ƙarin Takardu",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department that shows the End-user’s address \nand name (e.g., Residential Certificate); or": "Lissafin Amfanin Gida (kamar na gas, wutar lantarki, tarho ko lissafin wayar hannu); ko wata takarda da hukumar gwamnati ta fitar wacce ke nuna adireshin Mai amfani da sunansa \n(misali, Takardar Shaidar Zama); ko\"",
    "Bank Statement; or": "Takardar Bayanin Banki; ko",
    "a document issued by a government department that shows the End-user’s address and name (e.g., Residential Certificate).": "wata takarda da hukumar gwamnati ta fitar wacce ke nuna adireshin Mai amfani da suna (misali, Takardar Shaidar Zama).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting thorough verifications, in line with \nits legal obligations.": "Kemit Kingdom tana riƙe da haƙƙin neman duk wani ƙarin takardu ko ƙarin bayanai da suka wajaba don gudanar da cikakken bincike, bisa ga nauyin doka da ke kanta.",
    "Note:": "Lura:",
    "Partners": "Abokan hulɗa",
    "Add New Partner": "Ƙara Sabon Abokin Hulɗa",
    "Designation": "Mukami",
    "Partner Name": "Sunan Abokin Hulɗa",
    "Partner Email": "Imel ɗin Abokin Hulɗa",
    "Partner Designation": "Mukamin Abokin Hulɗa",
    "Add Now": "Ƙara Yanzu",
    "Adding": "Ana Ƙara",
    "Delete Partner": "Cire Abokin Hulɗa",
    "Delete": "Cire",
    "Deleting": "Ana Cirewa",
    "Are you sure, you want to remove sxz as partner?": "Kuna tabbata, kuna so ku cire sxz a matsayin abokin hulɗa?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Fitar da Rajista na Hukumar (Takardar Rajista/Halittar Hukumar)",
    "This document should be either:": "Wannan takarda na iya kasancewa:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Shaida na halittar hukumar, tare da bayanin hukumar, ranar halitta, da manufa.",
    "A local registry extract:": "Fitar da rajista ta gida:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status (active or closed).": "Ake buƙata bisa ƙa'idodin ƙasar, tana aiki a matsayin 'katin shaida' na yanzu don hukumar, tana nuna matsayinta (mai aiki ko an rufe).",
    "If a registry extract exists, it must not be older than 3 months.": "Idan akwai fitar da rajista, dole ne kada ya wuce watanni 3 da haihuwa.",
    "Entity name": "Sunan hukumar",
    "Registration number (if applicable, based on the country's requirements)": "Lambar rajista (idan ya dace, bisa ga buƙatun ƙasar)",
    "Date of creation": "Ranar halitta",
    "Description of the entity's activities or purpose": "Bayanin ayyukan hukumar ko manufarta",
    "Trading names": "Sunayen kasuwanci",
    "Articles of Association": "Kundin Tsarin Mulki",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating rules.": "Kundin Tsarin Mulki yana aiki a matsayin takardar tsarin mulki na hukumar, yana tsara dokokin aikinta.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of entities require at least one annual meeting \nthat may result in an updated version of the Articles of Association.": "Babu iyakar lokaci akan wannan takarda, ma'ana ana buƙatar mafi sabunta sigar. Gabaɗaya, irin waɗannan hukumomi suna buƙatar taro na shekara-shekara aƙalla guda ɗaya wanda zai iya haifar da \nsabunta sigar Kundin Tsarin Mulki.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, roles, responsibilities, etc.": "Misalan canje-canjen da za su iya faruwa sun haɗa da sabuntawa ga sunan hukumar, adireshi, manufa, kwamitin gudanarwa, rawa, alhakai, da sauransu.",
    "The document should contain information about:": "Takardar ya kamata ta ƙunshi bayani game da:",
    "The entity itself, including its legal name, registered address, and purpose": "Hukumar kanta, ciki har da sunanta na doka, adireshin rajista, da manufa",
    "Its internal operating rules, such as the appointment process for management board members, their roles and powers, among other details. This list is not exhaustive.": "Dokokin aiki na cikin gida, kamar tsarin naɗa mambobin kwamitin gudanarwa, rawa da iko, da sauran bayanai. Wannan jerin ba shi da iyaka.",
    "How the entity is funded": "Yadda ake samar da kuɗi ga hukumar",
    "Appointment/Removal/Changes on Board of Management Members:": "Naɗa/Cire/Canje-canje a Kwamitin Gudanarwa:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are documented through board of management \nmeeting minutes, which are registered and certified to reflect the updated list of members, such as the president, vice-president, treasurer, and general secretary.": "Waɗannan sabuntawa galibi suna faruwa bisa buƙatar hukumar ko kamar yadda Kundin Tsarin Mulki ya tanada. Ana rubuta canje-canje ta hanyar mintunan taron kwamitin gudanarwa, waɗanda \nake rajista da tabbatar da su don nuna jerin sabbin mambobi, kamar shugaban, mataimakin shugaban, mai kula da kuɗi, da babban sakatare.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the Articles of Association, if available.": "Za a buƙaci mafi sabon mintunan taron kwamitin gudanarwa, da jerin mambobin na yanzu, tare da Kundin Tsarin Mulki, idan akwai.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there are no board meeting minutes available, \nthis list must be provided. It should be dated and signed by the most recently appointed legal representative, whose appointment can be verified through related \ndocumentation.": "Lura: Idan jerin mambobin kwamitin gudanarwa na yanzu ba a haɗa shi a cikin mafi sabon Kundin Tsarin Mulki ba kuma babu mintunan taron kwamiti da ake da su, dole ne a bayar da wannan jerin. \nYa kamata a yi masa kwanan wata kuma a sa hannu ta wakilin doka da aka ɗauka kwanan nan, wanda naɗin sa za a iya tabbatar da shi ta hanyar takardu masu alaƙa.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Wannan takarda tana nuna ikon mutum don wakiltar hukumar. Wannan takardar na iya zama:",
    "The latest Articles of Association": "Mafi sabon Kundin Tsarin Mulki",
    "The current list of board management members (which may be included in the board management meeting minutes)": "Jerin mambobin kwamitin gudanarwa na yanzu (wanda kuma za a iya haɗa shi a cikin mintunan taron kwamitin gudanarwa)",
    "The document should specify:": "Takardar ya kamata ta bayyana:",
    "Full name of the individual(s)": "Cikakken sunan mutum/mutane",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to ensure that the individual's function permits them to bind the entity. \nThis is particularly important when the applicant is not the president, and other functions have limited representation authority as stated in the Articles of Association.": "Aikin mutum/mutane. Za a bincika wannan bayanin tare da Kundin Tsarin Mulki don tabbatar da cewa aikin mutum ya ba shi damar ɗaure hukumar. Wannan yana da muhimmanci musamman idan mai \nnema ba shi ne shugaban ba, kuma sauran ayyuka suna da iyakantaccen iko na wakilci kamar yadda Kundin Tsarin Mulki ya bayyana.",
    "Additional information about the legal representative that will be collected includes:": "Ƙarin bayani game da wakilin shari'a da za a tattara ya haɗa da:\n",
    "Date and place of birth": "Ranar haihuwa da wurin haihuwa",
    "Residential address (at least the country of residence should be stated)": "Adireshin zama (aƙalla ƙasar zama ya kamata a ambata)",
    "Nationality": "Ƙasa",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Tsayar da Mallaka da Takardun Masu Amfani da Amfani na Ƙarshe (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board members will be identified and treated as such for \nscreening purposes, ID collection and verification, etc.": "Gabaɗaya, irin waɗannan hukumomi, saboda tsarinsu da manufarsu, ba su da masu amfani da amfani. Duk da haka, za a gane mambobin kwamitin kuma a ɗauke su a matsayin haka don dalilai \nna tantancewa, tarin ID da tabbatarwa, da sauransu.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, this detail will be mentioned \nin the Articles of Association. These individuals will be easily identified and treated as such, alongside management board members.": "Ƙananan yawan ƙungiyoyi zasu iya samun masu amfani da amfani, musamman waɗanda ke samar da riba. A irin waɗannan yanayi, za a ambaci wannan bayanin a Kundin Tsarin Mulki. \nWaɗannan mutane za a gane su da sauki kuma a ɗauke su a haka, tare da mambobin kwamitin gudanarwa.\"",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Ƙarin Buƙata: A lokutan da abin ya shafi Ikon Lauya ko Wakilcin Iko, dukkan bangarorin biyu (wanda ke ba da iko da wanda ke karɓa) dole ne su bayar da kwafin takardun shaidarsu.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Fitar da Rajista na Mai Ciniki Kai (Takardar Rajista/Halittar Hukumar)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Wannan takarda tana aiki a matsayin rajistar gida ga masu cinikin kai, wanda ake buƙata a ƙasashen da irin wannan rajista ta zama dole. Tana tabbatar da cewa mutum yana aikin sana'a a matsayin \nmai ciniki kai. Sunan wannan takarda ya bambanta daga ƙasa zuwa ƙasa.",
    "Where applicable, the document should not be older than 3 months.": "Inda ya dace, takardar ba za ta wuce watanni 3 da haihuwa ba.",
    "The document must contain:": "Takardar dole ta ƙunshi:",
    "Individual's name": "Sunan mutum",
    "Residential address": "Adireshin zama",
    "Registration number": "Lambar rajista",
    "The sole trader is the only individual subject to the identification document collection process.": "Mai cinikayya shi kaɗai ne mutumin da ake buƙata a tsarin tarin takardun shaida.",
    "No Transactions": "Babu Ma'amaloli",
    "No Pending Items": "Babu Abubuwan da ke Jiran Aiki",
    "No Beneficiaries": "Babu Masu Amfana",
    "Send Money To": "Aika Kuɗi Zuwa",
    "Confirmation": "Tabbatarwa",
    "You are setting": "Kuna saita",
    "as default.": "a matsayin tsoho.",
    "Previously": "A baya",
    "was default QR.": "shine QR na tsoho.",
    "Category": "Rukuni",
    "-Select-From-The-List-": "-Zaɓi-Daga-Jerin-",
    "Add a note": "Ƙara bayani",
    "Categories Added Successfully!": "An Ƙara Rukunoni da Nasara!",
    "Subscription starting date": "Ranar fara biyan kuɗi",
    "Until": "Har zuwa",
    "Next Transaction": "Ma'amala ta Gaba",
    "Transaction Method": "Hanyar Ma'amala",
    "Accept the quotation": "Karɓi ƙididdigar",
    "Choose Currency": "Zaɓi Kuɗi",
    "Accept": "Karɓa",
    "Bargain the quotation": "Sasantawa akan ƙididdigar",
    "Enter Counter Amount:": "Shigar da Adadin da Za a Sake Yi:",
    "Bargain": "Sasantawa",
    "Decline the quotation": "Soke ƙididdigar",
    "Are you sure, you want to decline this quotation?": "Kuna tabbata, kuna son ƙin wannan ƙididdigar?",
    "Decline": "Ƙi",
    "Personal Account": "Asusun Kai",
    "Top-up Your Wallet": "Sanya Wallet ɗinku",
    "Download Statement": "Zazzage Bayanin",
    "Block the Wallet": "Toshe Wallet",
    "Wallet Management": "Gudanar da Wallet",
    "Wallet Status": "Matsayin Wallet",
    "Breakdown": "Rushewa",
    "Credit": "Kiredit",
    "Debit": "Zare kudi",
    "All": "Duka",
    "Sent": "An aika",
    "Received": "An karɓa",
    "Requested": "An nema",
    "Quoted": "An nakalto",
    "Date & Time": "Kwanan Wata & Lokaci",
    "Type": "Nau'in",
    "Transaction ID": "ID na kasuwanci",
    "Payment Type": "Nau'in Biyan Kuɗi",
    "Recipient": "Mai karɓa",
    "Amount": "Adadin",
    "Status": "Matsayi",
    "Search Transactions": "Bincika Ma'amaloli",
    "Subscriptions & Scheduled": "Biyan kuɗi & Tsara",
    "Sent Payments": "An aika Biyan kuɗi",
    "Received Payments": "Biya Da Aka Samu",
    "Date": "Kwanan wata",
    "Cycles/Time": "Zagaye/Lokaci",
    "Payment As": "Biya As",
    "Name": "Suna",
    "Cancel": "Soke",
    "Default": "Tsohuwar",
    "Select the option that matches your needs from the list below:": "Zaɓi zaɓin da ya dace da bukatunku daga lissafin da ke ƙasa:",
    "Trouble Requesting Money?": "Matsala Neman Kudi?",
    "Initiate A Payment": "Fara Biyan Kuɗi",
    "MAIN MENU": "BABBAN MENU",
    "OTHERS": "WASU",
    "Jan": "Jan",
    "Feb": "Fab",
    "Mar": "Mar",
    "Apr": "Afr",
    "May": "May",
    "Jun": "Yuni",
    "Jul": "Yuli",
    "Aug": "Agu",
    "Sep": "Sat",
    "Oct": "Okt",
    "Nov": "Nuw",
    "Dec": "Dis",
    "Today": "Yau",
    "Spent In": "An sa",
    "Revised Amount": "Kudin aiki",
    "You spent more comparing to last month": "Ku yi bada magana a karkashin wata",
    "Last": "Na",
    "days": "Kwanan",
    "Year": "Shekara",
    "Instant Mobile Top-up Across 150+ Countries": "Cika Layin Waya Nan take A Kasashe Sama da 150",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Ka faranta wa masoyanka rai ko ka cika wayarka, duk inda kake! Sabis na Cika Layin Waya na InstaPay yana baka damar cika layukan waya nan take a kasashe sama da 150. Dacewa don kyaututtuka ko tabbatar da kai kullum a haɗe, an tsara sabis ɗinmu don sauƙi, sauri, da tsaro.",
    "How It Works - Step-by-Step Guide:": "Yadda Yake Aiki - Jagora Mataki-da-Mataki:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Zaɓi 'Cika Layi': Samu damar wannan fasalin kai tsaye a cikin app ɗin InstaPay akan dandamali kamar WhatsApp, Twitter, Telegram, ko Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Zaɓi Kuɗi da Adadin: Zaɓi daga kuɗaɗe da yawa (USD, GBP, EUR, da sauransu) kuma ka nuna adadin da kake son cikawa.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Shigar da Lambar Waya: Rubuta lambar da kake son cikawa—ta naka ko ta wani da kake so. Tsarinmu yana tabbatar da tsari mai aminci da sauri.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Tabbatar da Biya: Duba bayanan cika layi sau biyu, tabbatar, kuma ka biya ta amfani da hanyar da kake so. Ana sarrafa ma'amalar nan take kuma cikin aminci.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Karɓi Tabbacin Nan take: Duk kai da wanda aka cika wa (idan ya dace) za su sami sanarwar tabbaci da zarar an yi nasarar cika layin.",
    "Benefits:": "Fa'idodi:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Iza ga Duniya: Aika layin waya cikin sauki zuwa kasashe sama da 150.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Tasirin Nan take: Gani fa'idodin nan take—cikakke don kyaututtuka na ƙarshe ko sadarwa mai gaggawa.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Cikakken Tsaro: Jin daɗin kwanciyar hankali tare da tsarin biyanmu mai aminci.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Zaɓuɓɓukan Biyan Kuɗi Daban-daban: Zaɓi daga katunan kuɗi, PayPal, ko walat ɗin InstaPay don biyan kuɗi.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Shirye don yada farin ciki ko ci gaba da haɗi? Cika layi yanzu kuma ka faranta ran wani—ko naka!",
    "Pick a language": "Zaɓi harshe",
    "French": "Faransanci",
    "Spanish": "Sifaniyanci",
    "Portuguese": "Harshen Portuguese",
    "German": "Jamusanci",
    "Ukrainian": "Yaren mutanen Ukraine",
    "Italian": "Italiyanci",
    "Russian": "Rashanci",
    "Arabic": "Larabci",
    "Polish": "Harshen Polish",
    "Dutch (Nederlands)": "Holanci",
    "Yoruba": "Yoruba",
    "Indonesian": "Harshen Indonesian",
    "Turkish": "Turanci",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Harshen Hindi",
    "Urdu": "Harshen Urdu",
    "Chinese": "Harshen Sinanci",
    "Select Your Preferred Language": "Zaɓi Harshen da Kake So",
    "Please select the language you would \nlike to use for the eKYC process.": "Da fatan zaɓi yaren da kake son amfani da shi don aikin eKYC.",
    "Select Your Nationality": "Zaɓi Ƙasarka",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Zaɓi ƙasarka don ganin takardun shaida da ake tallafawa don ƙasarka.",
    "Grant permission to use the camera.": "Ba da izinin amfani da kyamara.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Matsa ko danna \"Bada izini\" lokacin da aka tambaye ka don ba da izinin samun damar kyamara don ɗaukar takaddar.",
    "Upload Your Documents from \nGallery/Photos": "Loda Takardunku daga Hotuna/Galeri",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Idan kuna fuskantar matsaloli tare da kyamararku ko idan tsarin ku baya da shi, kuna iya loda hotunan takaddar daga cikin ajiya ta cikin gida.",
    "Upload Your ID.": "Loda ID ɗinku.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Tabbatar da cewa ID ɗinku a sarari ba tare da ɗimbin ɗauri ba don tabbatar da ingantaccen tabbaci.",
    "Valid Proof of Address": "Ingantaccen Shaida na Adireshi",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Tabbatar da cewa shaidar adireshin ku na yanzu kuma kwanan wata na tsarewa ya kasance cikin watanni uku daga ranar farawar tabbatarwar ku ta eKYC.\n\n2. Tabbatar da cewa shaidar adireshin ku a sarari ba tare da ɗimbin ɗauri ba don tabbatar da ingantaccen tabbaci.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Sanarwa Muhimma\n\nDa fatan za a karanta duk matakan wannan jagorar da kyau kafin fara tantancewar eKYC ɗinku.\n\nGuji kurakuran gama gari:\n\n* Tabbatar da cewa duk takardunku sun kasance a bayyane, ana iya karantawa kuma na zamani.\n* Duba cewa bayanan da kuka bayar sun yi daidai da bayanan da ke cikin takardunku na hukuma.\n* Bi umarnin daidai don guje wa jinkiri ko ƙin karɓa.\n\nLura: \nIdan tantancewar eKYC ɗinku ta gaza kuma kuna buƙatar sake mika shi, za a cajeku ƙarin kuɗi don sake mika shi.\n\nTa bin waɗannan jagororin, zaku iya tabbatar da cewa tantancewar eKYC ɗinku ta kasance mai sauƙi da nasara.\n\nDon duk wani tambaya ko taimako, da fatan za a tuntuɓi ƙungiyar tallafinmu a support@insta-pay.ch",
    "Personal Transactions": "Mu'amaloli na Kashin Kai",
    "Business Transactions": "Mu'amaloli na Kasuwanci",
    "Shopping & Purchases": "Siyayya da Saye",
    "Bills & Utilities": "Kudin Lantarki & Kudin Ruwa",
    "Entertainment & Leisure": "Nishaɗi da Hutawa",
    "Investments & Savings": "Zuba Jari da Ajiya",
    "Health & Wellness": "Lafiya & Jin Dadi",
    "Transportation": "Sufuri",
    "Education": "Ilimi",
    "Miscellaneous": "Na daban",
    "Family Support": "Tallafin iyali",
    "WALLET": "WALLET",
    "Add a currency": "Ƙara kudin",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Lokacin da ka ƙara kudin, duk wani biyan da ka karɓa a cikin kudin zai kasance a cikin ma'aunin InstaPay naka. Ana amfani da kudin ku na farko don aika ko neman biyan kuɗi sai dai in an bayyana akasin haka.",

    "balance amount ": "adadin ma'auni",
    "referral ": "nemi",
    "crypto wallet balance": "ma'aunin aljihun crypto",
    "money in": "kudi a ciki",
    "money out": "kudi a waje",
    "analytics": "bincike",
    "see more": "duba ƙari",
    "manage my Wallets and see the transaction details": "sarrafa Wallets na kuma duba bayanan ma'amaloli na",
    "set as default Wallet": "saita a matsayin Wallet na tsoho",
    "add currency": "ƙara kudin",
    "convert funds ": "juyar da kuɗi",
    "withdraw balance": "janye ma'auni",
    "chart names": "sunan ginshiƙi",
    "withdraw balance from PKR wallet": "fitar da ma'auni daga walat PKR",
    "select a payout channel": "zaɓi hanyar biyan kuɗi",
    "you have entered an amount below the minimum payment range": "kun shigar da adadi da ke ƙasa da ƙaramar adadin biyan kuɗi",
    "change": "canjawa",
    "edit": "gyara",
    "moving to next step in ...": "tafi zuwa mataki na gaba a ...",
    "you are withdrawing ...": "kuna cire ...",
    "where is my payment": "ina biyan kuɗi na",
    "download": "zazzage",
    "IBAN / SWIFT Code": "IBAN ko lambar SWIFT/BIC",
    "swift code": "lambar SWIFT",
    "account number": "lambar asusu",
    "branch street": "titin reshe",
    "city": "birni",
    "province": "lardi",
    "postal code": "lambar gidan waya",
    "all payout channels": "duk tashoshin biyan kuɗi",
    "bussiness beneficiary": "maibenefin kasuwanci",
    "no quotation": "ba faɗar farashi",
    "no pending items": "babu abubuwan da ake jira",
    "QR status": "Matsayin QR",
    "Portfolio": "Jaka",
    "edit whole portfolio page": "gyara duk shafin jaka",
    "personal account": "asusun kai",
    "Chart names": "Sunayen ginshiƙi",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "sauran",
    "not connected": "ba a haɗa ba",
    "code": "lambar sirri",
    "ratings": "kimantawa",
    "current password": "kalmar wucewa na yanzu",
    "include at least one uppercase and lowercase": "hade akalla babban harafi da ƙaramin harafi",
    "my social network accounts": "asabobina na hanyar sadarwa",
    "SMS ": "SMS",
    "view identity verfication guide": "duba jagorar tabbatar da ainihi",
    "boimetric face recognition": "gane fuska na biometric",
    "please select a payer (withdrawl options)": "don Allah zaɓi mai biyan kuɗi (zaɓuɓɓukan cirewa)",
    "IBAN number ": "lambar IBAN",
    "account holder name": "sunan mai asusun",
    "download data": "zazzage bayanai",
    "bank transfer": "canja wurin banki",
    "mobile wallet": "aljihun tafi da gidanka",
    "all bank names": "duk sunayen banki",
    "pay via card": "biya ta katin",
    "you have entered an amount that is below the payer range": "ka shigar da adadin da ke ƙasa da kewayon mai biya",
    "trouble sending money": "matsala a aika kudi",
    "please follow these example": "don Allah ku bi waɗannan misalan",
    "wallet ID ": "ID ɗin Wallet",
    "username": "sunanka",
    "email": "imel",
    "phone number": "lambar waya",
    "no user found": "ba a sami mai amfani ba",
    "search": "bincika",
    "scan/upload QR code of the recipient": "duba/ɗora lambar QR na mai karɓa",
    "invite them": "gayyace su",
    "trouble quoting money": "matsala wajen fadin farashin kudi",
    "select a country": "zaɓi ƙasa",
    "airtime details": "cikakkun bayanai na airtime",
    "enter the phone number": "shigar da lambar wayar",
    "service type": "nau'in sabis",
    "select a service": "zaɓi sabis",
    "service list": "jerin sabis",
    "amount entered is less than the ....": "adadin da aka shigar ya ƙasa da ....",
    "total": "adadin",
    "total credit the receiver gets": "jimlar kuɗi da mai karɓa zai samu",
    "take ": "dauki",
    "OTP has been verified , You'll be redirected......": "An tabbatar da OTP, za a tura ku......",
    "Link Your Instagram Account to InstaPay": "Hada asusun Instagram dinka da InstaPay",
    "Step 01 – From Instagram App": "Mataki na 01 – Daga Manhajar Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Bude manhajar Instagram, nema shafin bayanin \"InstaPay\", zaɓi \"Sako\" don buɗe akwatin hira, rubuta/ce \"Sannu\" kuma danna tura don fara tattaunawa da chatbot na InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Mataki na 02 – Daga Shafin Bayanai na Instagram na InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Chatbot na InstaPay zai nuna maɓallan aiki biyu: \"Yi Rijista\" da \"Haɗa\". Zaɓi \"Haɗa\", kuma chatbot zai nemi ka shigar da sunan mai amfani na InstaPay.",
    "Step 03 – From InstaPay Instagram Profile Page": "Mataki na 03 – Daga Shafin Bayanai na Instagram na InstaPa",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Chatbot na InstaPay zai nemi lambar tabbatarwa ta InstaPay. Shigar da lambar da ka samu daga cikin saitunan InstaPay a ƙarƙashin \"Asusun Na Na Social Network\" kuma danna tura.",
    "Step 04 – From InstaPay Instagram Profile Page": "Mataki na 04 – Daga Shafin Bayanai na Instagram na InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Za ka karɓi saƙon taya murna da ke tabbatar da cewa an haɗa asusun Instagram ɗinka yanzu tare da InstaPay. Danna \"Babban Menu\" don fara amfani da sabis ɗin InstaPay.",
    "Additional Tips": "Ƙarin Nasihu",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Kare Asusunka: Tabbatar cewa asusun Instagram ɗinka yana da aminci ta kunna tabbatarwa ta hanyoyi biyu (2FA) don kare sabis ɗin InstaPay da aka haɗa.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Bincika Ƙari: Da zarar an haɗa, bincika kewayon sabis da InstaPay ke bayarwa, daga ma'amaloli masu sauƙi zuwa cire kuɗi nan take.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Ci gaba da Sabunta: Kula da sabuntawa daga gare mu, yayin da muke shirin tallafawa haɗin wasu asusun na social network don sanya kwarewarka ta kasance haɗe da juna sosai.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Taimako & Tallafi: Idan ka fuskanci matsaloli yayin aiwatar da haɗin, tuntuɓi tawagar tallafi don taimako.",
    "Future Enhancements": "Sabbin Ingantawa",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "Nan gaba kadan, InstaPay zai ba ka damar haɗa ƙarin asusun na social network, yana ba ka ƙarin sassauci da sauƙi. Ci gaba da kula da sabbin abubuwan ban sha'awa!",
    "Personal Transactions": "Mu'amaloli na Kashin Kai",
    "Business Transactions": "Mu'amaloli na Kasuwanci",
    "Shopping & Purchases": "Siyayya da Saye",
    "Bills & Utilities": "Kudin Lantarki & Kudin Ruwa",
    "Entertainment & Leisure": "Nishaɗi da Hutawa",
    "Investments & Savings": "Zuba Jari da Ajiya",
    "Health & Wellness": "Lafiya & Jin Dadi",
    "Transportation": "Sufuri",
    "Education": "Ilimi",
    "Miscellaneous": "Na daban",
    "Family Support": "Tallafin iyali",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Fara Tafiyar InstaPay Dinka A Yau!",
    "Enter your phone number": "Shigar da lambar wayarka",
    "Login with email": "Shiga tare da imel",
    "Don't have an account?": "Babu asusu?",
    "Sign Up": "Yi rajista",
    "Continue": "Ci gaba",
    "We recommend you download and install the Google Authenticator app": "Muna ba da shawarar ka sauke kuma ka girka app ɗin Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Tabbatarwa ta hanyoyi biyu (2FA) ita ce hanya mafi kyau don kare kanka akan layi.",
    "Strong security with Google Authenticator": "Kariya mai ƙarfi tare da Google Authenticator",
    "Enter your password": "Shigar da kalmar sirrinka",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Muna ba da shawarar ka sauke kuma ka girka app ɗin Google Authenticator don tabbatar da cewa ka karɓi lambobin tabbatarwarka nan take, guje wa kowanne jinkiri ko matsaloli tare da SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Lambar Tantancewa ta Hanyoyi Biyu",
    "Problem with the token?": "Matsala da token?",
    "Verify using SMS": "Yi amfani da SMS don tabbatarwa",
    "Verify": "Tabbatar",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Tabbatarwa ta SMS",
    "Enter the SMS code sent to +41********4053": "Shigar da lambar SMS da aka aiko zuwa +41********4053",
    "Verify Using Authenticator App": "Yi amfani da app ɗin Authenticator don tabbatarwa",
    "Resend code in 120 seconds": "Sake aika lambar a cikin daƙiƙa 120",
    "Verify ": "Tabbatar",
    "SMS - Web Mobile": null,
    "My own account": "Asusuna na kaina",
    "Friends & Family": "Abokai & Iyali",
    "Aunt": "Gwaggo",
    "Brother-in-law": "Dan uwa",
    "Cousin": "Danja",
    "Daughter": "‘Ya",
    "Father": "Uba",
    "Father-in-law": "Uban miji",
    "Friend": "Aboki",
    "Grandfather": "Kaka",
    "Grandmother": "Kaka (na miji)",
    "Husband": "Miji",
    "Mother": "Uwa",
    "Mother-in-law": "Uwar miji",
    "Nephew": "Zankwabo",
    "Niece": "Zankwaba",
    "Self (i.e. the sender, himself)": "Kai (shi ke nan mai aikawa)",
    "Sister": "Kanne",
    "Sister-in-law": "Kanuwa",
    "Son": "Da",
    "Uncle": "Kawu",
    "Wife": "Mata",
    "Others not listed": "Sauran wanda ba a jera ba",
    "Merchant": "Ɗan kasuwa",
    "No relationship": "Babu dangantaka",
    "Add Funds": "Ƙara Kuɗi",
    "Enter the Amount": "Shigar da Adadin:",
    "Fees": "Kudade:",
    "You Will Get": "Za ku karɓa:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Darajar ma'amala yakamata ta kasance tsakanin [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Adadi mara inganci: Matakin tabbatar da shaida na yanzu yana iyakance adadin da za ku iya ƙara. Da fatan za a haɓaka tabbatar da shaida don cin gajiyar iyakokin mafi girma.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Farashin kuɗi yana canzawa akai-akai bisa yanayin kasuwa. Da fatan za a koma zuwa farashin shafin tabbatar da oda azaman ƙarshe.",
    "Next": "Na gaba",
    "Upgrade Identity Verification": "Haɓaka Tabbatar da Shaida",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Nemi kuɗi daga kowa, ko'ina, gami da kan dandamalin sadarwar zamantakewa. Tare da InstaPay, buƙatun biyan kuɗin ku nan take ana iya samun su da zarar an karɓa kuma ana tura kuɗin zuwa Asusun Karɓarku. Sauƙaƙe ma'amalolinku kuma ku sarrafa biyan kuɗi cikin sauƙi da aminci.",
    "You’ve been logged out.": "An fitar da ku.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Domin tsarinku, an fitar da ku bayan mintuna 15 na rashin aiki. Da fatan za a sake shiga idan kuna son amfani da InstaPay.",
    "LOGIN AGAIN": "SAKE SHIGA",
    "Brother": "ɗan'uwa",
    "Current Password": "Kalmar sirri na yanzu",
    "The new password cannot be the same as the old one": "Sabon kalmar sirri ba zai zama kamar tsohuwar ba.",
    "Minimum password length: [X] characters ": "Mafi ƙarancin tsawon kalmar sirri: [X] haruffa",
    "Include at least one uppercase and one lowercase letter.": "Ka haɗa da aƙalla babban harafi da ƙaramin harafi.",
    "Nominee Information": "Bayanin Wanda aka Zaba",
    "Verification Code Preferences": "Zaɓuɓɓukan Lambar Tabbatarwa",
    "How Others Can Find You": "Yadda Wasu Zasu Iya Neman Ku",
    "My Social Network Accounts": "Asusun Sadarwar Zamani Na",
    "Login Activity": "Ayyukan Shiga",
    "Receiving Accounts": "Asusun Karɓa",
    "Change Your Password To Continue": "Canja Kalmar Sirrinka Don Ci gaba",
    "Continue with": "Ci gaba da",
    "Back to Login": "Koma zuwa Shiga",
    "Send OTP": "Aika OTP",
    "A Verification code has been sent to your email and number.": "An aika da lambar tabbatarwa zuwa imel ɗinku da lambar ku.",
    "Resend available in": "Akwai sake aikawa a cikin",
    "Create a strong Password": "Ƙirƙiri M kalmar sirri mai ƙarfi",
    "Password must:": "Kalmar sirri dole:",
    "Be between 9 and 64 characters": "Kasance tsakanin haruffa 9 zuwa 64",
    "Include at least two of the following:": "Haɗa aƙalla guda biyu daga cikin waɗannan:",
    "Lowercase Character": "Harafi ƙarami",
    "Special Character": "Harafi na Musamman",
    "Password Updated Successfully!": "An Sabunta Kalmar Sirri da Nasara!",
    "Click below to login": "Danna ƙasa don shiga",

    "Send Money": "Aika Kudi",
    "Enter the amount": null,
    "Recent Transfer": "Sabon Canja wurin",
    "See all": "Duba duk",
    "Send again": "Aika sake",
    "You Send": "Kana Aika",
    "They Receive": "Suna Karɓa",
    "Receive Method": "Hanyar Karɓa",
    "Select method": "Zaɓi hanyar",
    "Next": "Na gaba",
    "Trouble sending money?": "Matsala wajen aika kudi?",
    "Where do you want to send money ?": "Ina kake son aika kudi?",
    "Search ": "Bincika",
    "Confirm": "Tabbatar",
    "Bank Deposit": "Ajiya na Banki",
    "Cash Pick-up": "Karɓar Kuɗi",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Katin Banki",
    "Crypto Wallet": "Wallet na Crypto",
    "What is the receiver’s Mobile Wallet?": "Menene Wallet na wayar mai karɓa?",
    "Transfer fees": "Kuɗin canja wuri",
    "Total to pay": "Jimlar biyan kuɗi",
    "Choose the beneficiary!": "Zaɓi mai amfana!",
    "Country": "Ƙasar",
    "Receive method": "Hanyar karɓa",
    "You send": "Kana aika",
    "change": "canza",
    "edit": "ƙara sabo",
    "add new": "Masu Amfana",
    "Beneficiaries": "Sarrafa Masu Amfana",
    "Manage Beneficiaries": "Bincika",
    "Search": "Na gaba",
    "CASH PICK-UP": "KARƁAR KUDI",
    "Where will your receiver collect the money": "Inda mai karɓa zai karɓi kuɗi",
    "Cash is available to collect within minutes from any branch of [name] ": "Ana samun kuɗi a cikin mintuna daga kowanne reshe na [suna]",
    "BANK ACCOUNT": "ASUSUN BANKI",
    "Transfer to recipient’s bank account": "Canja wuri zuwa asusun bankin mai karɓa",
    "BANK CARD": "KATIN BANKI",
    "Fast transfer directly to recipient’s bank card": "Canja wuri kai tsaye zuwa katin bankin mai karɓa",
    "CRYPTO WALLET": "WALLET NA CRYPTO",
    "Fast transfer directly to a crypto wallet": "Canja wuri kai tsaye zuwa Wallet na crypto",
    "Review transfer": "Duba canja wuri",
    "Receiver's Details": "Bayanin mai karɓa",
    "Edit": "Gyara",
    "Name": "Suna",
    "Reason for sending": "Dalilin aika",
    "Sending": "Aika",
    "Receiver gets": "Mai karɓa ya karɓa",
    "Exchange rate": "Kudin canjin kuɗi",
    "Estimated time": "Lokacin da aka kiyasta",
    "Select Payment Type": "Zaɓi Nau'in Biyan Kuɗi",
    "Credit cards": "Katinan kuɗi",
    "Domestic cards": "Katinan cikin gida",
    "Mobile Wallets": "Wallet na wayar hannu",
    "InstaPay Wallet": "Wallet na InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KATINAN KUƊI",
    "Credit Card": "Katinan kuɗi",
    "Select card": "Zaɓi katin",
    "Card Number": "Lambar Katin",
    "Enter the 16-digit card number on the card": "Shigar da lambar katin ɗin 16",
    "Expiry Date": "Ranar ƙarewa",
    "Enter the expiration date of the card": "Shigar da ranar ƙarewar katin",
    "CVV Number": "Lambar CVV",
    "Enter the 3 or 4 digit number on the card": "Shigar da lambar 3 ko 4 da ke jikin katin",
    "INSTAPAY WALLET": "WALLET NA INSTAPAY",
    "Select Wallet": "Zaɓi Wallet",
    "My Wallet": "Wallet na",
    "Conversion": "Canji",
    "Convert": "Canja",
    "Your payment is confirmed": "An tabbatar da biyan kuɗin ku",
    "Thanks for using InstaPay": "Na gode da amfani da InstaPay",
    "Status": "Matsayi",
    "Share": "Raba",
    "Transfer created": "An ƙirƙiri canja wuri",
    "Payment received": "An karɓi biyan kuɗi",
    "Payment processed": "An sarrafa biyan kuɗi",
    "Transfer successful": "Canja wurin ya yi nasara",
    "It may take few hours for the funds to appear in [user name] account": "Zai iya ɗaukar sa'o'i kafin kuɗin ya bayyana a cikin asusun [sunan mai amfani]",
    "Transfer failed": "Canja wurin ya gaza",
    "Transaction Details": "Bayanin mu'amala",
    "Need help?": "Kuna buƙatar taimako?",
    "Service Details": "Cikakkun Bayanai na Sabis",
    "Funds Deposit Speed": "Samun Kuɗi",
    "Cut-off Time": "Lokacin Rufe",
    "Bank holiday list": "Jerin Ranakun Hutu na Banki",
    "Threshold limits": "Iyakokin Ma'amala",
    "null": null,
    "Instant, Real Time": "Nan take, ainihin lokaci",
    "Same day, T+1": "Ranar da aka yi, T+1",
    "Non-instant, T+1": "Ba nan take ba, T+1",
    "Instant Payment, Real Time": "Biyan Kudi Nan take, ainihin lokaci",
    "Transaction processed before 3:00 PM Local time": "An sarrafa ma'amala kafin ƙarfe 3:00 na rana lokaci na gida",
    "Delivered the same day": "An kawo a rana ɗaya",
    "Transactions processed post 3:00 PM": "Ma'amaloli da aka sarrafa bayan ƙarfe 3:00 na rana",
    "T+1 business days": "T+1 kwanakin kasuwanci",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Za a ba da kuɗin biyan kuɗi da aka yi a ranakun mako kafin ƙarfe 3:00 na rana a asusun bankin da ke karɓar kuɗin kafin ƙarfe 12:00 na dare a rana ɗaya.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Za a ba da kuɗin biyan kuɗi da aka yi a ranakun mako bayan ƙarfe 3:00 na rana kafin ƙarfe 12:00 na dare a ranar da ke biyo bayan ta.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Za a ba da kuɗin biyan kuɗi da aka yi a ranar Asabar, Lahadi ko ranar hutu a asusun kafin ƙarfe 12:00 na dare na ranar kasuwanci ta farko mai zuwa.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 Lokaci na Gida",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Mafi ƙanƙanta adadin ma'amala:",
    "Maximum per transaction limit:": "Mafi girma adadin ma'amala:",
    "Daily threshold limit:": "Iyakar yau da kullun:",
    "Weekly threshold limit:": "Iyakar mako-mako:",
    "Monthly threshold limit:": "Iyakar wata-wata:",
    "Yearly threshold limit:": "Iyakar shekara-shekara:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Asusun Asali (Ba a Tabbatar ba)",
    "Identity Verified": "An Tabbatar da Shaidar",

    "Good Evening!": "Barka da Yamma!",
    "Total Account Balance": "Jimillar Asusun Kuɗi",
    "Quick Funds Transfer": "Canja wurin Kuɗi Mai Sauri",
    "No transactions found": "Babu wata ma'amala da aka samu",
    "Blocked balance": "Ajiyar da aka toshe",
    "Available balance": "Adadin da ake da shi",
    "Insufficient Balance in PKR": "Rashin Isasshen Kuɗi a PKR",
    "Chat with us": "Yi magana da mu",
    "Add Currency": "Ƙara Kuɗi",
    "Education": "Ilimi",
    "Miscellaneous": "Na dabam-dabam",
    "Family support": "Tallafin Iyali",
    "Other Expenses": "Sauran Kuɗin Shiga",
    "Transport": "Sufuri",
    "Scheduled Payment": "Biyan Kuɗi da aka tsara",
    "Recurring Payment": "Biyan Kuɗi Mai maimaitawa",
    "No payment found": "Babu biyan kuɗi da aka samu",
    "No Beneficiaries Available": "Babu masu cin gajiyar da ake da su",
    "Postal Code": "Lambar Gidan Wasiƙa",
    "Additional Information": "Karin Bayani",
    "Gender": "Jinsi",
    "Date of Birth": "Ranar Haihuwa",
    "Nationality": "Ƙasa",
    "Occupation (all options also)": "Aikin Yi",
    "ID Type (all options also)": "Nau'in ID",
    "ID Number": "Lambar ID",
    "Select Beneficiary Payment Channels": "Zaɓi Hanyoyin Biyan Kuɗi na Masu Amfani",
    "Crypto Wallet": "Wallet ɗin Krypto",
    "Add another wallet": "Ƙara wata walat ɗaya",
    "Business": "Kasuwanci",
    "Business beneficiaries are coming soon! You will be able to conduct business transactions globally, expanding your reach across borders.\n": "Masu cin gajiyar kasuwanci za su zo nan ba da jimawa ba! Za ku iya yin kasuwanci a duniya, kuna faɗaɗa aikinku a cikin ƙasashe daban-daban.",
    "Joined on": "An haɗa akan",
    "Profile link": "Hanyar Bayanan martaba",
    "Commission": "Kashi",
    "My Withdrawals": "Abubuwan fitar da kuɗi na",
    "My Referrals": "Na’amfani na",
    "Search Quotes": "Nemi Maganganu",
    "No quotes found": "Babu maganganu da aka samu",
    "Actions": "Ayyuka",
    "Search request": "Nemi buƙata",
    "No pending items found": "Babu abubuwa da ake jira da aka samu",
    "Share your QR Code or pay link to instantly receive payments in your InstaPay wallet": "Raba lambar QR ɗinka ko hanyar biyan kuɗi don karɓar kuɗi a walat ɗin InstaPay naka nan take",
    "Description": "Bayani",
    "Save": "Ajiye",
    "Share your unique InstaPay payment link": "Raba hanyar biyan kuɗin InstaPay naka na musamman",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Ƙara bayananka na jama’a. Kundin bayananka yana aiki azaman tarin bayanai mai fa’ida wanda ake iya samun dama da shi kai tsaye daga adireshin biyan kuɗinka",
    "Connect with your audience through your professional or creative journey. Let your work showcase itself and engage potential clients or supporters more deeply.\n": "Haɗa kai da masu sauraron ka ta hanyar tafiyarka ta ƙwarewa ko ƙirƙira. Bar aikinka ya yi magana don kansa kuma ya jawo hankalin abokan ciniki ko masu goyon baya sosai.",
    "Display your social media reach. Visitors can see your social media impact, adding credibility to your portfolio.\n": "Nuna yawan masu bibiyar ka a kafafen sada zumunta. Masu ziyara za su iya ganin tasirin ka a kafafen sada zumunta, suna ƙara amincewa da kundin bayananka.",
    "Enable visitors to request or initiate payments directly from your public page. Your portfolio is not just a showcase but also a way to facilitate easy financial transactions.\n": "Baiwa masu ziyara damar neman ko fara biyan kuɗi kai tsaye daga shafin jama'a naka. Kundin bayananka ba kawai nunin abubuwa ba ne, har ma hanyar sauƙaƙe mu'amalolin kudi.",
    "Use the \"About Me\" section to share your story, your mission, or vision, building a personal connection with your audience.": "Yi amfani da sashin \"Game da Ni\" don raba labarinka, manufarka, ko hangen nesa, don gina dangantaka ta musamman da masu sauraron ka.",
    "\"My Portfolio\" is more than just a gallery; it's a tool for building your brand, engaging with your audience, and simplifying financial interactions on InstaPay.": "\"Kundin Bayani na\" fiye da gallery kawai ne; yana aiki azaman kayan aiki don gina alamar ka, yin hulɗa da masu sauraro, da sauƙaƙe mu'amalolin kudi a InstaPay.",
    "No Portfolio Items Found": "Babu Abubuwan Portfolio da aka samu",
    "Add New Portfolio Item": "Ƙara Sabon Abu na Portfolio",
    "Title": "Take",
    "Drag/Drop or upload a file (photo/video/pdf)": "Ja/ Saka ko loda fayil (hoto/video/pdf)",
    "Discard": "Zubar",
    "Save and Post": "Ajiye da Fitar",
    "My Payment Address": "Adireshin Biyan Kuɗina",
    "Perfect for influencers, artists, entrepreneurs, or anyone who wants to let their community easily show support.": "Mafi dacewa ga masu tasiri, masu fasaha, 'yan kasuwa, ko duk wanda ke son bai wa al'umma damar nuna goyon baya cikin sauƙi.",
    "Personal Account": "Asusun Kaina",
    "Total Spent": "Jimillar da aka kashe",
    "Personal Account Status": "Matsayin Asusun Kaina",
    "Not Connected": "Ba a haɗa ba",
    "More": "Fiye",
    "Less": "Kasa",
    "Profile Reports": "Rahotanni na Bayanan Martaba",
    "All notifications are in English": "Duk sanarwar suna cikin Turanci",
    "Timezone": "Lokacin yankin",
    "Nominee Information": "Bayanai na Mai Nominawa",
    "Full Name": "Cikakken Sunan",
    "Date Of Birth": "Ranar Haihuwa",
    "Relation with Nominee": "Dangantaka da Mai Nominawa",
    "Source": "Tushen",
    "Google Authenticator": "Google Authenticator",
    "Notifications": "Sanarwa",
    "Login": "Shiga",
    "View Identity Verification Guide": "Duba Jagoran Tabbatar da Shaida",
    "Verified": "An tabbatar",
    "Select": "Zaɓi",
    "It must be a government-issued ID document with a photo. All identification must be in the Latin alphabet. Otherwise, a certified translation is required.": "Dole ne ya zama takaddar shaida daga gwamnati mai hoto. Duk takaddun shaida dole ne su kasance a cikin haruffan Latin. Idan ba haka ba, ana buƙatar fassarar da aka tabbatar.",
    "Kemit Kingdom reserves the right to request additional documents (Enhanced Due Diligence - EDD) or any information required to perform full verification, in line with its legal obligations.": "Kemit Kingdom na da hakkin neman ƙarin takardu (Binciken Tsaro na Ƙarin Bayani - EDD) ko kowanne bayanin da ake buƙata don gudanar da cikakken tabbatarwa, bisa ga ƙa'idodinsa na doka.",
    "Sessions": "Zaman",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Bude Fitar da Kuɗi na Gaggawa: Saita Asusun Karɓar Kuɗi Yanzu!",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up points. Follow the steps below to ensure instant access to your funds whenever needed.": "Sauƙaƙe sarrafa inda kuke son karɓar biyan kuɗi ta hanyar ƙara bayanan asusun bankinku, walat ɗinku na wayar salula, walat ɗinku na crypto, katunan biyan kuɗi, ko wuraren karɓar kuɗi na kashiya. Bi matakan da ke ƙasa don tabbatar da samun damar kuɗinku nan take duk lokacin da ake buƙata.",
    "Select the receiving channel below and set up the details.": "Zaɓi hanyar karɓa da ke ƙasa sannan ka saita bayanan.",
    "Please Select A Payer": "Da fatan zaɓi mai biyan kuɗi",
    "Number": "Lamba",
    "Account Holder's Name": "Sunan Mai Riƙe da Asusun",
    "Province": "Jaha",
    "Postal Address": "Adireshin Post",
    "Delete Channel": "Goge Hanyar",
    "Set as Default": "Saita a matsayin Na Default",
    "Edit": "Gyara",
    "All channels are in English on the receiving accounts page": "Dukkan hanyoyi suna cikin Turanci a shafin asusun da ke karɓar kuɗi",
    "Select File Type": "Zaɓi Nau'in Fayil",
    "Download Data": "Sauke Bayanai",
    "INTERNATIONAL TRANSFER": "CANJIN KUDI NA DUNIYA",
    "Enter the Amount": "Shigar da Adadin",
    "Recipient Receives": "Mai Karɓa Na Karɓa",
    "Trouble Sending Money?": "Matsala wajen Aika Kuɗi?",
    "Select Destination Country": "Zaɓi Kasar Da Za a Tura",
    "Bank Transfer": "Canja wurin Banki",
    "Receiving Method": "Hanyar Karɓa",
    "Payer": "Mai Biyan Kuɗi",
    "Fees and the received amount may vary based on the chosen payment method.": "Kudin sabis da adadin da za a karɓa na iya canzawa dangane da hanyar biyan kuɗi da aka zaɓa.",
    "Search": "Nemi",
    "Show More": "Nuna Karin Bayani",
    "Select Remark Reasons": "Zaɓi Dalilan Tsokaci",
    "Credit and Debit Cards": "Katin Kiredit da Katin Zare Kudi",
    "Transfer Fees": "Kudin Canja Wurin",
    "Estimated Time:": "Lokacin Kimantawa:",
    "Within Minutes": "A cikin Minti",
    "Total": "Jimlar",
    "OTP Verification Code": "Lambar Tabbatarwa ta OTP",
    "Verify via SMS/Email": "Tabbatar da SMS/Imel",
    "SMS/Email Verification Code": "Lambar Tabbatarwa ta SMS/Imel",
    "Verify Using Authenticator": "Tabbatar da Amfani da Mai Tabbatarwa",
    "WALLET TO WALLET TRANSFER": "CANJIN WALAT DAGA WALAT",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Shigar da ID Walat, Sunan Mai Amfani, Imel ko Lambar Wayar mai karɓa.",
    "Lookup": "Duba",
    "Recipient Not on InstaPay?": "Mai Karɓa Ba a InstaPay Ba?",
    "Invite them": "Gayyace su",
    "Please follow these examples:": "Da fatan za a bi waɗannan misalan:",
    "Wallet ID: GY68J782": "ID ɗin Walat: GY68J782",
    "InstaPay/Instagram Username: instauser": "InstaPay/Instagram Sunan mai amfani: instauser",
    "Email: user@email.com": "Imel: user@email.com",
    "Phone Number (with Country Code)": "Lambar Waya (tare da Lambar Kasa)",
    "Invite Friend": "Gayyatar Aboki",
    "Invite via Phone Number": "Gayyatar ta Lambar Waya",
    "Invite via Email Address": "Gayyatar ta Adireshin Imel",
    "Next": "Na gaba",
    "Back": "Baya",
    "Personalized Message": "Sako na Musamman",
    "Available Currency": "Kuɗin da ake da shi",
    "Attach Files": "Haɗa Fayiloli",
    "Total Amount": "Jimillar Kuɗi",
    "Instant Payment": "Biyan Kuɗi Nan Take",
    "Starting From": "Daga",
    "Recurring Cycle": "Zagaye na dawowa",
    "Until Stopped": "Har sai an dakatar",
    "Cycle": "Zagaye",
    "The recipient may receive a slightly adjusted amount due to the exchange rate at the time of the transaction.": "Mai karɓa na iya samun ƙididdiga ɗan ƙasa ko ƙari saboda canjin farashin a lokacin mu’amala.",
    "Scheduled To": "An tsara don",
    "Scheduled At": "An tsara a",
    "Scheduled Date": "Ranar da aka tsara",
    "Scheduled Time": "Lokacin da aka tsara",
    "The recipient may receive a slightly adjusted amount due to exchange rates.": "Mai karɓa na iya samun ƙididdiga ɗan ƙasa ko ƙari saboda canjin farashin.",
    "QR CODE PAYMENT": "Biyan kuɗi ta lambar QR",
    "Alternatively, use the InstaPay Alphanumeric QR Code.": "Hakanan, yi amfani da lambar QR ta alphanumeric na InstaPay.",
    "PAYMENT REQUEST": "BUƙATAR Biyan Kuɗi",
    "Choose the Beneficiary": "Zaɓi Mai Karɓa",
    "Trouble Requesting Money?": "Matsala wajen neman kuɗi?",
    "Standard Request": "Buƙata ta Al'ada",
    "Recurring Request": "Buƙata ta dawowa",
    "Scheduled Request": "Buƙata ta Tsarawa",
    "Security Deposit Request": "Buƙatar ajiyar tsaro",
    "Instalment Plan": "Shirin biyan kashi",
    "Split Payment": "Raba biyan kuɗi",
    "You received": "Ka karɓa",
    "Is this your location?": "Shin wannan shine wurinka?",
    "Yes": "E",
    "Authenticator Code": "Lambar Tantancewa",
    "Enter the code shown in the app to confirm everything works properly.": "Shigar da lambar da aka nuna a cikin manhajar don tabbatar da cewa komai yana aiki daidai.",
    "Verify Using SMS/Email": "Tantancewa ta SMS/Imel",
    "SEND A QUOTE": "A TURA WANI TUNANI",
    "Trouble Sending a Quote?": "Matsala wajen tura tunani?",
    "Full Name: Muhammad Imtiaz": "Sunan Cikakke: Muhammad Imtiaz",
    "Phone Number: 44795396600 (With Country Code)": "Lambar Waya: 44795396600 (Tare da Lambar Kasa)",
    "Enter the code shown in the app to ensure everything is correct.": "Shigar da lambar da aka nuna a cikin manhajar don tabbatar da cewa komai yana daidai.",
    "SEND TOP-UP AND E-SIM": "AIKA TOP-UP DA E-SIM",
    "Stay connected globally! InstaPay's Top-Up service allows you to recharge phones instantly, buy eSIMs, branded vouchers, gift cards, and pay utility bills in over 150 countries. Ideal for gifts or keeping yourself ready, our service prioritizes ease, speed, and security.": "Ci gaba da kasancewa da haɗin kai a duniya! Aikin Top-Up na InstaPay yana ba ku damar caji wayoyi nan take, siyan eSIMs, takardun kyautar alama, kati na kyauta, da biyan kuɗin sabis a ƙasashe fiye da 150. Mafi dacewa don kyaututtuka ko shirya kanku, sabis ɗinmu yana fifita sauƙi, sauri, da tsaro.",
    "MOBILE AIRTIME AND E-SIM": "LAYIN WAYA DA E-SIM",
    "Select the country for mobile top-up!": "Zaɓi ƙasa don caji waya!",
    "Other Operators": "Wasu Masu Aiki",
    "Airtime": "Lokacin Waya",
    "Bundle": "Haɗin",
    "Data": "Bayanai",
    "Enter Custom Amount": "Shigar da Adadin Al'ada",
    "Credit & Debit Card": "Katin Kiredit da Debit",
    "Mobile Wallet": "Walat ɗin Waya",
    "InstaPay Wallet": "Walat ɗin InstaPay",
    "PayPal": "PayPal",
    "Add Card Payment": "Ƙara Biyan Katin",
    "Save Details for Future Use": "Ajiye Bayanan don Amfani na Nan Gaba",
    "Recipient Mobile Number": "Lambar Waya ta Mai Karɓa",
    "Amount": "Adadin",
    "Service": "Sabis",
    "Destination": "Manufa",
    "Provider": "Mai bayarwa",
    "Pakistan": "Pakistan",
    "Telenor Pakistan": "Telenor Pakistan",
    "Top-Up Summary": "Bayanin Caji",
    "PKR is not supported by PayPal, USD will be used as default.": "PKR ba a goyi bayan PayPal ba, USD za a yi amfani da shi a matsayin tsoho.",
    "Amount in USD": "Adadin a cikin USD",
    "Send Top-Up": "Aika Caji",
    "Available Sunservices": "Samun Sunservices",
    "Login With Email": "Shiga Tare da Imel",
    "Already Have an Account? Log In": "Kana da Account? Shiga",
    "Profile Picture": "Hoton Bayani",
    "Add Photo/Logo": "Ƙara Hoto/Logo",
    "First Name": "Sunan Farko",
    "Enter Your First Name": "Shigar da Sunan Farko",
    "Last Name": "Sunan Karshe",
    "Enter Your Last Name": "Shigar da Sunan Karshe",
    "Email (Optional)": "Imel (Zaɓi)",
    "Enter Your Email Address": "Shigar da Adireshin Imel",
    "Password Must": "Kalmar Wucewa Dole",
    "Auto-fill for Invitation Sign-Up": "Cike ta atomatik don Rijistar Tashar",
    "Change Number": "Canza Lamba",
    "Sign-Up Error": "Kuskurin Rijista",
    "Something went wrong while sending SMS! Please try again later.": "Wani abu ya samu matsala yayin aika SMS! Da fatan za a sake gwadawa daga baya.",
    "Errors occurred. Please refer to the following list for details.": "An samu kurakurai. Da fatan za a duba jerin mai zuwa don karin bayani.",
    "Generic Error": "Kurin na gama",
    "Trust Payments API requires the 'requests' library to function properly.": "API na Trust Payments yana bukatar dakin karatu 'requests' don aiki yadda ya kamata.",
    "Error during sending process. Please retry.": "Kuskurin a yayin aikawa. Da fatan za a sake gwadawa.",
    "Error during receiving process. Please retry.": "Kuskurin a yayin karɓa. Da fatan za a sake gwadawa.",
    "Invalid credentials. Please verify and try again.": "Bayani mara kyau. Da fatan za a tabbatar da shi sannan a sake gwadawa.",
    "An issue occurred while connecting to Trust Payments servers. Please check your connection and retry.": "An sami matsala yayin haɗawa da sabobin Trust Payments. Da fatan za a duba haɗin ku sannan a sake gwadawa.",
    "Unexpected error while connecting to Trust Payments servers. If this problem persists, contact support@trustpayments.com.": "Kuskurin da ba a zata ba yayin haɗawa da sabobin Trust Payments. Idan wannan matsalar ta ci gaba, tuntuɓi support@trustpayments.com.",
    "Unknown error. Please contact Trust Payments support if the issue continues.": "Kuskurin da ba a sani ba. Da fatan za a tuntuɓi goyon bayan Trust Payments idan matsalar ta ci gaba.",
    "Incorrect usage of Trust Payments API. Please verify the request and try again.": "Amfani mara kyau na API na Trust Payments. Da fatan za a tabbatar da bukatar sannan a sake gwadawa.",
    "Invalid card details. Please check and enter correct details.": "Bayanai marasa kyau na kati. Da fatan za a duba sannan a shigar da bayanai masu kyau.",
    "Invalid date provided.": "Ranar da aka bayar ba ta dace ba.",
    "Invalid date/time format. Please provide the correct format.": "Tsarin ranar/lokaci ba daidai ba. Don Allah a samar da tsarin da ya dace.",
    "Invalid details. Please verify and enter correct information.": "Bayani mara kyau. Don Allah a tabbatar kuma shigar da cikakken bayani.",
    "Card number does not match the specified card type.": "Lambar katin ba ta dace da nau'in katin da aka bayyana ba.",
    "XML formatting error. Please verify and correct the XML.": "Kuskuren tsarawa na XML. Don Allah a tabbatar da gyara XML.",
    "XML does not match the expected schema.": "XML ba ya dace da tsarin da ake bukata.",
    "Invalid file format. Please use a supported format.": "Tsarin fayil mara kyau. Don Allah a yi amfani da tsarin da ake goyon baya.",
    "Empty file content. Please provide valid content.": "Abun cikin fayil ɗin ya kasance fanko. Don Allah a samar da ingantaccen abun ciki.",
    "Invalid file content. Please verify the file and try again.": "Abun cikin fayil mara kyau. Don Allah a tabbatar da fayil ɗin kuma ku sake gwadawa.",
    "Malformed JSON. Please correct the JSON format.": "JSON mara kyau. Don Allah a gyara tsarin JSON.",
    "StApi Error": "Kuskuren StApi",
    "Invalid fields specified in the request. Please verify and correct them.": "An ƙayyade filayen da ba su dace ba a cikin buƙatar. Da fatan za a duba kuma a gyara su.",
    "Missing parent information.": "Babu bayanin mahaifi.",
    "Refund requires a settled parent transaction or one due for settlement today.": "Dawo da kuɗi yana buƙatar cinikayya ta mahaifi da aka kammala ko ɗaya da za a kammala yau.",
    "Refund requires an authorization parent transaction.": "Dawo da kuɗi yana buƙatar cinikayya ta mahaifi da aka ba da izini.",
    "Refund amount exceeds allowable limit.": "Adadin dawo da kuɗi ya wuce iyakar da aka yarda.",
    "No acquirer information provided.": "Babu bayanin mai karɓa.",
    "Repeat amount exceeds allowable limit.": "Adadin maimaita ya wuce iyakar da aka yarda.",
    "Split amount exceeds allowable limit.": "Adadin rarraba ya wuce iyakar da aka yarda.",
    "Cannot refund a declined transaction.": "Ba za a iya dawo da cinikayya da aka ƙi ba.",
    "Refund requires a settled parent transaction.": "Dawo da kuɗi yana buƙatar cinikayya ta mahaifi da aka kammala.",
    "Reversal requires a canceled authorization transaction.": "Mayar da kuɗi yana buƙatar ɓatar da aikin izini.",
    "Cannot override amount in a child transaction.": "Ba za a iya canza adadin cikin wata ƙananan ma'amala ba.",
    "Cannot override currency in a child transaction.": "Ba za a iya canza kuɗin wata ƙananan ma'amala ba.",
    "Subscription requires a recurring account setup.": "Sabon shiga yana buƙatar saita asusun sake maimaitawa.",
    "Subscription requires a successful parent transaction.": "Sabon shiga yana buƙatar nasarar ma'amala ta uwa.",
    "Risk decisions must be tied to an authorization transaction.": "Yanke shawarar haɗari dole ne a haɗa da izinin ma'amala.",
    "Chargebacks must be linked to an authorization or refund transaction.": "Dawo da kuɗi dole ne a haɗa da izinin ma'amala ko ma'amalar dawo da kuɗi.",
    "Refund amount is less than the minimum allowed.": "Adadin dawowa ya kasa ƙaramin adadin da aka yarda da shi.",
    "Refund requires a PayPal transaction ID.": "Dawo da kuɗi yana buƙatar ID na ma'amala ta PayPal.",
    "Invalid split transaction.": "Ma'amala raba ba ta dace ba.",
    "Cannot reverse an authorization processed more than 48 hours ago.": "Ba za a iya mayar da izinin ma'amala wanda aka aiwatar fiye da sa'o'i 48 da suka wuce ba.",
    "Reversal requires acquirer reference data.": "Mayar da kuɗi yana buƙatar bayanan nuni daga mai saye.",
    "Cannot reverse authorization processed by a different acquirer.": "Ba za a iya mayar da izinin ma'amala da wani mai saye daban ya aiwatar ba.",
    "Payment type does not support repeated transactions.": "Nau'in biyan kuɗi ba ya goyon bayan ma'amaloli da aka maimaita.",
    "Reversal missing required data.": "Mayar da kuɗi yana rasa bayanan da ake buƙata.",
    "Missing token information.": "Rashin bayanan alamomi.",
    "Subscription with an account check parent not supported by the current acquirer.": "Sabon shiga tare da binciken asusu ta uwa ba a goyon bayan mai saye na yanzu.",
    "Subscription cannot be used as a parent transaction.": "Sabon shiga ba zai iya zama ma'amala ta uwa ba.",
    "Invalid parent transaction type.": "Nau'in ma'amala ta uwa ba ta dace ba.",
    "Payment type does not support refunds.": "Nau'in biyan kuɗi ba ya goyon bayan dawo da kuɗi.",
    "Invalid incremental transaction.": "Mummunar ma'amala ta ƙara.",
    "Partial reversals are not supported.": "Ba a goyi bayan mayar da rarraba ba.",
    "Parent and child transactions must have the same payment type for 3D secure queries.": "Dole ne ma'amaloli na uwa da ɗan ɗan su zama da nau'in biyan kuɗi ɗaya don tambayoyin tsaro na 3D.",
    "Payment type does not support card scheme updates.": "Nau'in biyan kuɗi ba ya tallafawa sabuntawa na tsarin katin.",
    "Cannot reverse authorization at this time. Please try again later.": "Ba za a iya mayar da izinin ba a wannan lokaci ba. Don Allah a sake gwadawa daga baya.",
    "Unable to determine token. Please verify and try again.": "Ba za a iya tantance alamar ba. Don Allah a duba kuma a gwada sake.",
    "Service is temporarily disabled. Please try again later.": "Ayyukan sun kasance a ɗan lokaci na wucin gadi. Don Allah a gwada daga baya.",
    "Login required before making a request.": "Dole ne a shiga kafin yin buƙata.",
    "Invalid username or password. Please try again.": "Sunan mai amfani ko kalmar sirri ba daidai ba. Don Allah a gwada sake.",
    "Invalid session. Please log in again.": "Mummunar zaman. Don Allah a shiga da sabon login.",
    "Session expired. Please log in again.": "Lokacin zama ya ƙare. Don Allah a shiga daga baya.",
    "Password expired. Please reset your password.": "Kalmar sirri ta ƙare. Don Allah a sake saita kalmar sirrin ku.",
    "Password has been previously used. Please choose a new password.": "An yi amfani da kalmar sirri a baya. Don Allah a zaɓi sabuwar kalmar sirri.",
    "MyST user account has been locked. Please contact support.": "Asusun mai amfani na MyST an kulle shi. Don Allah a tuntuɓi goyon bayan.",
    "New password does not match the confirmed password.": "Sabuwar kalmar sirri ba ta dace da kalmar sirri da aka tabbatar da ita ba.",
    "Incorrect current password. Please try again.": "Kalmar sirri ta yanzu ba daidai ba. Don Allah a gwada sake.",
    "Invalid selection made. Please choose a valid option.": "An yi zaɓi mara inganci. Don Allah a zaɓi zaɓi mai inganci.",
    "User already exists. Please log in or reset the password if needed.": "Mai amfani yana nan tuni. Don Allah a shiga ko a sake saita kalmar sirri idan ana bukata.",
    "No transaction found.": "Babu ma'amala da aka samu.",
    "Invalid selected transactions.": "Mummunan zaɓin ma'amaloli.",
    "Data has not been saved. Please try again.": "Bayanai ba a adana ba. Don Allah a sake gwadawa.",
    "Invalid request type.": "Nau'in buƙata ba daidai ba.",
    "Missing request type. At least one must be selected.": "Ana buƙatar nau'in buƙata. Dole ne a zaɓi ɗaya.",
    "Invalid payment type.": "Nau'in biyan kuɗi ba daidai ba.",
    "Missing payment type. At least one must be selected.": "Ana buƙatar nau'in biyan kuɗi. Dole ne a zaɓi ɗaya.",
    "Invalid error code.": "Lambar kuskure ba daidai ba.",
    "Missing error code. At least one must be selected.": "Ana buƙatar lambar kuskure. Dole ne a zaɓi ɗaya.",
    "Invalid filter description.": "Bayanin tacewa ba daidai ba.",
    "Invalid destination description.": "Bayanin manufa ba daidai ba.",
    "Invalid notification type.": "Nau'in sanarwa ba daidai ba.",
    "Invalid destination.": "Manufa ba daidai ba.",
    "Invalid field selected.": "Filin da aka zaɓa ba daidai ba.",
    "Invalid email address for the sender.": "Adireshin imel na mai aikawa ba daidai ba.",
    "Invalid email subject line.": "Layin taken imel ba daidai ba.",
    "Invalid email type.": "Nau'in imel ba daidai ba.",
    "Unable to process the request. Please try again later.": "Ba za a iya aiwatar da buƙatar ba. Don Allah a sake gwadawa daga baya.",
    "No file selected for upload.": "Babu fayil ɗin da aka zaɓa don lodi.",
    "Invalid file size. Please provide a file of acceptable size.": "Girman fayil ba daidai ba. Don Allah a samar da fayil mai girma da za a iya karɓa.",
    "Invalid filename.": "Sunan fayil ba daidai ba.",
    "Invalid file extension.": "Tsawon ƙarin fayil ba daidai ba.",
    "User requires at least one site reference.": "Mai amfani yana buƙatar akalla ɗaya daga cikin shafukan da aka ambata.",
    "Only ST-level users can have '*' access rights.": "Sai kawai masu amfani na matakin ST ne za su iya samun hakkin shiga '*'",
    "Request failed. Please try again later.": "Buƙatar ta gaza. Don Allah a sake gwadawa daga baya.",
    "Invalid file contents.": "Abubuwan fayil ba daidai ba.",
    "Maximum number of files uploaded. Please delete some files and retry.": "Mafi yawan fayilolin da aka loda. Don Allah a share wasu fayiloli sannan a sake gwadawa.",
    "Insufficient gateway access privileges.": "Rashin izinin samun hanyar sadarwa.",
    "Maximum file size limit reached.": "An cimma iyakar girman fayil.",
    "Username(s) must be valid users.": "Sunan mai amfani(s) dole ne su zama masu amfani da suka dace.",
    "Site reference(s) must be valid sites.": "Shafukan da aka ambata dole ne su zama shafukan da suka dace.",
    "Unable to send email. Please verify the details and try again.": "Ba za a iya aika imel ba. Don Allah a duba cikakkun bayanan kuma a sake gwadawa.",
    "A negative value already exists. Please verify and make corrections.": "Ana riga ana samun ƙimar ƙasa. Da fatan za a duba kuma kuyi gyara.",
    "Cannot delete a search owned by another user.": "Ba za a iya gogewa neman da wani mai amfani ya mallaka ba.",
    "Invalid search. Please try again with valid criteria.": "Neman ba daidai ba. Da fatan za a sake gwadawa da ƙa'idodi masu inganci.",
    "Cannot delete the specified search. The search name cannot be found.": "Ba za a iya gogewa binciken da aka ƙayyade ba. Ba a samu sunan binciken ba.",
    "Search parameter is too short. Please enter more specific search terms.": "Ƙayyadaddun sharuɗɗan binciken suna da gajarta. Da fatan za a shigar da karin takamaiman kalmomin bincike.",
    "Duplicate custom fields defined. Please resolve the duplicates.": "An ayyana filayen al'ada masu maimaituwa. Da fatan a warware maimaitawa.",
    "Cannot allocate selected users. Insufficient privileges.": "Ba za a iya rarraba masu amfani da aka zaɓa ba. Babu izinin isasshe.",
    "Allocated users have access to additional sites.": "An rarraba masu amfani suna da damar shiga shafuka na ƙari.",
    "Allocated users have access to additional users.": "An rarraba masu amfani suna da damar shiga masu amfani na ƙari.",
    "Users with the current role cannot allocate other users.": "Masu amfani tare da matsayin yanzu ba za su iya rarraba wasu masu amfani ba.",
    "This site requires that your browser accepts cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Wannan shafin yana buƙatar cewa burauzan ku ya amince da kukis don shiga. Za a iya karɓar kukis ta danna 'Na yarda' a ƙasa.",
    "User requires at least one site reference or site group.": "Mai amfani yana buƙatar aƙalla wani shahararren shafin ko ƙungiyar shafin.",
    "Allocated users have access to additional site groups.": "An rarraba masu amfani suna da damar shiga ƙungiyoyin shafuka na ƙari.",
    "No statement found.": "Babu bayanin da aka samu.",
    "Data supplied has not been updated in the MobilePay third-party service.": "Bayanai da aka bayar ba a sabunta su a cikin sabis na MobilePay na ɓangare na uku ba.",
    "Bypass": "Tsallake",
    "Insufficient access privileges.": "Babu izinin shiga da ya dace.",
    "Coding error detected.": "An gano kuskuren lambar.",
    "Insufficient privileges to complete this action.": "Babu izinin isasshe don kammala wannan aikin.",
    "Invalid request. Please verify and try again.": "Neman ba daidai ba. Da fatan za a duba kuma kuyi ƙoƙari daga baya.",
    "Invalid field entered. Please verify and correct the entry.": "An shigar da filin da ba daidai ba. Don Allah a duba kuma a gyara shigarwar.",
    "Unknown site.": "Shafin da ba a sani ba.",
    "Banned card.": "Katin da aka haramta.",
    "XML element parsing error. Please verify the XML structure.": "Kuskuren fassarar ɓangaren XML. Don Allah a duba tsarin XML.",
    "Maestro transactions must use SecureCode for validation.": "Ma'amalolin Maestro dole ne suyi amfani da SecureCode don tantancewa.",
    "Multiple email addresses must be separated with commas (,) or semicolons (;).": "Adireshin imel da yawa dole ne a raba su da comma (,) ko semicolon (;).",
    "Invalid site reference for alias.": "Bayanan shafin da ba daidai ba don alias.",
    "Invalid version number. Please enter a valid version number.": "Lambar sigar da ba daidai ba. Don Allah a shigar da lambar sigar da ta dace.",
    "Unknown user. Please verify and try again.": "Mai amfani wanda ba a sani ba. Don Allah a duba kuma a gwada sake.",
    "Cannot determine account.": "Ba za a iya tantance asusun ba.",
    "JSON element parsing error. Please verify the JSON structure.": "Kuskeren fassarar ɓangaren JSON. Don Allah a duba tsarin JSON.",
    "Wallet type configuration error.": "Kuskeren daidaitawar nau'in walat.",
    "Wallet type not supported for this request.": "Nau'in walat bai dace da wannan buƙatar ba.",
    "The card number provided is incorrect. Please verify your details and try again.": "Lambar katin da aka bayar ba daidai ba ce. Don Allah a duba bayanan ku kuma ku gwada sake.",
    "The security code (CVV2) you provided is incorrect. Please verify and try again.": "Lambar tsaro (CVV2) da kuka bayar ba daidai ba ce. Don Allah a duba kuma ku gwada sake.",
    "The expiry date provided is incorrect. Please verify your details and try again.": "Ranar ƙarewa da aka bayar ba daidai ba ce. Don Allah a duba bayanan ku kuma ku gwada sake.",
    "The expiry month provided is incorrect. Please verify and try again.": "Watan ƙarewa da aka bayar ba daidai ba ne. Don Allah a duba bayanan ku kuma ku gwada sake.",
    "The expiry year provided is incorrect. Please verify and try again.": "Shekarar ƙarewa da aka bayar ba daidai ba ce. Don Allah a duba bayanan ku kuma ku gwada sake.",
    "Unable to process your payment due to connection errors. Request ID mismatch. Please try again.": "Ba za a iya aiwatar da biyan kuɗin ku ba saboda kuskuren haɗi. Mismatch na ID na buƙata. Don Allah a gwada sake.",
    "The issue number provided is incorrect. Please verify and try again.": "Lambar batun da aka bayar ba daidai ba ce. Don Allah a duba kuma ku gwada sake.",
    "The payment type provided is incorrect. Please verify and try again.": "Nau'in biyan kuɗin da aka bayar ba daidai ba ne. Don Allah duba kuma gwada sake.",
    "Unable to process your payment. Please contact the website for support.": "Ba za a iya aiwatar da biyan kuɗinka ba. Don Allah tuntuɓi shafin yanar gizon don samun tallafi.",
    "There are errors with these fields: {0}": "Akwai kuskure a cikin waɗannan filayen: {0}",
    "No account found.": "Ba a sami asusu ba.",
    "Refund cannot be processed.": "Ba za a iya aiwatar da maidowa ba.",
    "Transaction deactivated.": "An dakatar da ma'amala.",
    "Socket receive error.": "Kuskurin karɓar socket.",
    "Socket connection error.": "Kuskurin haɗin socket.",
    "Socket closed.": "Socket rufe.",
    "Invalid data received.": "An karɓi bayanan da ba daidai ba.",
    "Invalid SQL query.": "Tambayar SQL ba daidai ba.",
    "Timeout occurred.": "Lokacin da ya wuce.",
    "Invalid acquirer details.": "Bayanan mai karɓa ba daidai ba.",
    "Unable to connect to the acquirer.": "Ba za a iya haɗawa da mai karɓa ba.",
    "Invalid response from the acquirer.": "Amsa daga mai karɓa ba daidai ba.",
    "No available transport found.": "Ba a samu sufuri ba.",
    "File size too large.": "Girman fayil yana da yawa.",
    "Socket send error.": "Kuskurin aika socket.",
    "Communication error.": "Kuskurin sadarwa.",
    "Proxy error.": "Kuskurin wakili.",
    "Unable to process your payment due to connection errors. Please verify your details and try again ({0}).": "Ba za a iya sarrafa biyan kuɗinku saboda kuskuren haɗi ba. Da fatan za a duba bayanan ku kuma ku sake gwadawa ({0}).",
    "Unable to process your payment due to connection errors (HTTP response status {0}). Please verify your details and try again ({1}).": "Ba za a iya sarrafa biyan kuɗinku saboda kuskuren haɗi (HTTP amsa matsayi {0}). Da fatan za a duba bayanan ku kuma ku sake gwadawa ({1}).",
    "Incorrect number of email addresses provided.": "Adadin adireshin imel ɗin da aka bayar ba daidai ba.",
    "Bank system error.": "Kuskurin tsarin banki.",
    "Incorrect number of transactions provided.": "Adadin ma’amaloli da aka bayar ba daidai ba.",
    "Invalid transport configuration.": "Tsarin sufuri mara inganci.",
    "No valid updates specified.": "Babu sabuntawa masu inganci da aka bayyana.",
    "Transaction reference not found.": "Ba a samu hanyar tura ma’amala ba.",
    "Settle base amount is too large.": "Mafi girman adadin tushe don ƙidaya yana da girma sosai.",
    "Transaction not updatable.": "Ba a sabunta ma’amala ba.",
    "No searchable filter specified.": "Babu matattarar bincike da aka bayyana.",
    "Timeout error. Please try again.": "Kuskuren lokaci. Da fatan za a sake gwadawa.",
    "3-D Secure transport error.": "Kuskuren sufuri na 3-D Secure.",
    "Unauthenticated request.": "Buƙatar da ba a tabbatar ba.",
    "Site suspended.": "Shafin an dakatar.",
    "No updates performed.": "Babu sabuntawa da aka yi.",
    "Invalid request.": "Buƙata mara inganci.",
    "Invalid response.": "Amsar mara inganci.",
    "Invalid acquirer information.": "Bayanan mai tara ba daidai ba.",
    "Invalid account data.": "Bayanai na asusun ba daidai ba.",
    "Missing information.": "Bayanai masu wucewa.",
    "Payment error.": "Kuskuren biyan kuɗi.",
    "Invalid acquirer for 3-D Secure.": "Ba daidai ba acquirer don 3-D Secure.",
    "Invalid payment type for 3-D Secure.": "Ba daidai ba nau'in biyan kuɗi don 3-D Secure.",
    "Invalid updates specified.": "Sabuntawa da ba daidai ba an ayyana.",
    "Manual investigation required.": "Ana buƙatar binciken hannu.",
    "Invalid headers.": "Jagororin ba daidai ba.",
    "Maximum fraud score exceeded.": "An wuce mafi girman maki zamba.",
    "Invalid filters provided.": "An bayar da tacewa mara kyau.",
    "Merchant system error.": "Kuskurene a tsarin mai sayarwa.",
    "Your payment is being processed. Please wait...": "Ana aiwatar da biyan kuɗin ku. Da fatan za a jira...",
    "Cannot specify both 'request type description' and 'request type descriptions' in a single request.": "Ba za a iya ƙayyade duka 'bayanin nau'in buƙata' da 'bayanan nau'in buƙata' a cikin buƙatar guda ɗaya ba.",
    "Acquirer missing original transaction data.": "Acquirer yana rasa bayanan asalin ciniki.",
    "Insufficient funds.": "Kudi ba su ishe ba.",
    "Unable to process due to scheme restrictions.": "Ba za a iya aiwatar da shi saboda ƙuntatawar tsarin ba.",
    "Failed screening.": "Binciken ya gaza.",
    "Unable to process due to restrictions.": "Ba za a iya aiwatar da shi saboda ƙuntatawar ba.",
    "Invalid process.": "Tsari mara kyau.",
    "Risk referral required.": "Ana buƙatar tura zuwa hadarin.",
    "Name is required for selection.": "Sunan yana buƙatar zaɓi.",
    "Address is required for selection.": "Adireshin yana buƙatar zaɓi.",
    "IP address not in range.": "Adireshin IP ba ya cikin kewayon.",
    "Invalid button configuration.": "Tsarin maɓallin ba daidai ba.",
    "Unrecognized response from acquirer.": "Ba a gane amsa daga acquirer ba.",
    "Decline.": "Ɓata.",
    "Uncertain result.": "Sakamako mara tabbas.",
    "Soft decline.": "Ɓata mai laushi.",
    "Refer to the issuer for more information.": "Tuntuɓi mai bayar da izini don ƙarin bayani.",
    "Request is queued. Please check the transaction status later.": "Ana jira buƙatar. Da fatan za a duba matsayin ciniki daga baya.",
    "Generic retry.": "Maimaitawa na gaba ɗaya.",
    "Soft decline retry.": "Maimaitawa na Ɓata mai laushi.",
    "There has been a problem with your payment. Please verify your details and try again.": "An samu matsala da biyan kuɗin ku. Da fatan za a tabbatar da cikakkun bayananku kuma ku sake gwadawa.",
    "Unknown error.": "Kuskuren da ba a sani ba.",

    "Login": "Shiga",
    "Enter Your Email": "Shigar da imel ɗinku",
    "Login With Phone Number": "Shiga tare da lambar waya",
    "Verify Using Sms/Email": "Tabbatar da amfani da SMS/Email",
    "Resend Code In 100 Seconds": "Aika lambar sake cikin daƙiƙa 100",
    "Verify": "Tabbatar",
    "Verifying": "Tabbatarwa na ci gaba",
    "Last Week": "Mako na ƙarshe",
    "Older Transaction": "Ma'amala tsohuwa",
    "Total": "Jimla",
    "Conversion Confirmed": "Canji an tabbatar",
    "User Account Limit": "Iyakar asusun mai amfani",
    "Monthly Balance Limit": "Iyakar ajiya na wata-wata",
    "Total Limit": "Jimlar iyaka",
    "Used": "Amfani",
    "Remaining": "Mafi Kusa",
    "Allowed Minimum Topup Amount": "Adadin ƙananan lodin da aka yarda da shi",
    "Allowed Maximum Topup Amount": "Adadin mafi girma na lodin da aka yarda da shi",
    "Incoming Limit": "Iyakar shigarwa",
    "Daily limit": "Iyakar Kullum",
    "Monthly limit": "Iyakar Watan",
    "Yearly limit": "Iyakar Shekara",
    "Sending Limit": "Iyakar aika",
    "Allowed Number Of Transactions": "Yawan mu'amaloli da aka yarda",
    "Total Amount": "Jimlar adadi",
    "Amount To Send": "Kudin da za a aika",
    "Destination Amount": "Kudin da aka nufa",
    "Note": "Lura",
    "Revised Amount": "Kudin da aka gyara",
    "Save": "Ajiye",
    "Date": "Ranar",
    "Action": "Aiki",
    "Payment Scheduled At": "Lokacin Biyan Kuɗi",
    "Transaction Fee": "Kudin Harka",
    "Number Of Cycle": "Yawan Zagaye",
    "Subscription Starting Date": "Ranar Fara Rajista",
    "Until": "Zuwa",
    "Next Transaction": "Harkar Gaba",
    "Pay Now": "Biya Yanzu",
    "Transaction Method": "Hanyar Harka",
    "Mobile Money Provider": "Mai Bayar da Kuɗin Wayar Hannu",
    "Wallet Name": "Sunan Wallet",
    "Wallet Number": "Lambar Wallet",
    "Crypto Currency": "Kuɗin Digital",
    "Wallet Address": "Adireshin Wallet",
    "Select Document Type": "Zaɓi Nau'in Takarda",
    "Edit Document Number": "Gyara Lambar Takarda",
    "My Withdrawals": "Fitarwar Ku",
    "Bargainable": "Zai Iya Tattaunawa",
    "Description": "Bayani",
    "Attached Files": "Fayilolin da aka haɗa",
    "Sent": "An Aika",
    "Accepted": "An Karɓa",
    "Decline": "An ƙi",
    "Pending": "A cikin jiran aiki",
    "Overall Rating": "Jimlar Kimantawa",
    "No Review": "Babu Bita",
    "0 Rating": "Kimantawa 0",
    "Transaction Map": "Taswirar Ma'amala",
    "Send Money": "Aika Kudi",
    "Sending Method": "Hanyar Aiko",
    "Trouble Sending Money": "Matsala a Aika Kuɗi",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Kudin da aka karɓa da kuma adadin na iya canzawa bisa ga hanyar biyan kuɗi da aka zaɓa.",
    "Select Method": "Zaɓi Hanya",
    "Completed": "An Kammala",
    "How Was Your Experience?": "Yaya Kasancewar ƙwarewar ku?",
    "Leave A Review": "Bar Bita",
    "Review History": "Tarihin Bita",
    "Write A Review": "Rubuta Bita",
    "Send And Post": "Aika da wallafa",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Canjin Crypto zuwa Fiat yana da ƙarfi kuma mai aminci wanda ke canza cryptocurrencies zuwa kuɗin fiat ba tare da matsala ba. Yana biyan bukatun masu amfani daban-daban, yana ba da hanyoyin biyan kuɗi da dama waɗanda aka keɓe don dacewa da abubuwan da ake so na kowane mutum da wurare.\"",
    "Review Created": "Bita an ƙirƙira",
    "Transactions": "Ma'amaloli",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Ja/Dora ko loda fayil (Hotuna/Video/PDF)",
    "Max Size 1GB": "Mafi girman girman 1GB",
    "Title Description": "Bayanin taken",
    "Add New": "Ƙara Sabbi",
    "Discard": "Watsi",
    "Save And Post": "Ajiye da Buga",
    "Personal Account": "Asusun Kai",
    "Conversion": "Canji",
    "Spent In Total": "Jimlar da aka kashe",
    "International": "Kasashen Duniya",
    "Recharge": "Caji",
    "Top Up Wallet To Wallet": "Cika Wallet zuwa Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Ba a haɗa ba",
    "More": "Karin",
    "Less": "Ƙaranci",
    "Profile Reports": "Rahotannin Bayanai",
    "Timezone": "Lokacin Zama",
    "Cancel": "Soke",
    "Full Name": "Cikakken Suna",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Takardun Kari",
    "Profile Complete": "Cikakken Bayani",
    "Verified": "An Tabbatar",
    "Bank Account": "Asusun Banki",
    "Mobile Wallet": "Wallet na Waya",
    "Cash Pickup": "Karɓar Kuɗi",
    "Add": "Ƙara",
    "Configured": "An Shirya",
    "Email": "Imel",
    "Download Data": "Zazzage Bayanai",
    "Enter The Amount": "Shigar da Adadi",
    "They Receive": "Suna Karɓa",
    "Where Do You Want To Send Money": "Ina kake son Aika Kuɗi",
    "Bank Transfer": "Canja Wuri na Banki",
    "Receiving Method": "Hanyar Karɓa",
    "Payer": "Mai Biyan Kuɗi",
    "Minimum Amount": "Mafi ƙarancin Adadi",
    "Maximum Amount": "Mafi girman adadi",
    "Search": "Neman",
    "Show More": "Nuna ƙari",
    "Select Remark / Reasons (All)": "Zaɓi Sharhi / Dalilai (Duk)",
    "Attach Files": "Haɗa Fayiloli",
    "Holiday List": "Jerin Hutu",
    "No Data To Show": "Babu Bayanai Don Nuna",
    "Threshold Limits": "Iyakoki na Ƙa'idodi",
    "Amount Limits": "Iyakokin Adadi",
    "OTP Verification Code": "OTP Lambar Tabbatarwa",
    "Verify Using Authenticator": "Tabbatar da Amfani da Authenticator",
    "Sms/Email Verification Code": "SMS/Email Lambar Tabbatarwa",
    "Invite Them": "Kira Su",
    "Lookup": "Duba",
    "Invite Friend": "Kira Aboki",
    "Invite Via Phone Number": "Kira ta Lambar Waya",
    "Invite Via Email Address": "Kira ta Adireshin Imel",
    "Next": "Na gaba",
    "Back": "Komawa",
    "Invite Via Email": "Kira ta Imel",
    "Available Currency": "Kuɗin Da Ake da shi",
    "Add Remarks And Comments": "Ƙara Sharhi da Ra'ayoyi",
    "Recipient Details": "Cikakkun Bayanin Mai Karɓa",
    "Go Back": "Komawa",
    "Trouble Requesting Money": "Matsala wajen Neman Kuɗi",
    "Instant": "Nan da nan",
    "Subscription": "Rijista",
    "Schedule": "Jadawali",
    "Choose Request Type": "Zaɓi Nau'in Neman",
    "Mobile Airtime": "Lokacin Waya",
    "Incorrect Phone Number": "Lambar Waya Mara Daidai",
    "Make Sure You Have Entered A Correct Phone Number.": "Tabbatar cewa ka shigar da lambar waya daidai.",
    "Close": "Rufe",
    "Operator": "Mai Aiki",
    "Recipient Mobile Number": "Lambar Waya Mai Karɓa",
    "Amount": "Adadi",
    "Service": "Sabis",
    "Destination": "Wuri",
    "Top Up Summary": "Taƙaitaccen Cika",
    "Pakistan": "Pakistan",
    "Daily Sending Limit Exceeded": "Iyakance Adadin Aiki na Ranar ya wuce",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Aika da cikakken ƙididdiga na farashi ga abokin ciniki ko mai siye. Nuna sabis ɗinku, kayayyakin ku, ko tayin ku cikin kwararru tare da ƙididdiga na musamman.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Biyan kuɗi da aka yi a cikin kwanakin aiki kafin karfe 3:00 PM za a loda zuwa asusun bankin da ya karɓa kafin ƙarfe 12 na dare na ranar.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Saita asusun karɓar ku a cikin ƙasashe har zuwa uku kuma a fitar da kuɗi nan da nan daga buƙatun biyan kuɗi da aka amince da su zuwa hanyoyin biyan kuɗi da aka kunna.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom yana da hakkin neman duk wani ƙarin takardu (Ƙarin Duba Mai Zurfi - EDD) ko ƙarin bayanai da ake buƙata don gudanar da ingantattun bincike, bisa ga wajiban doka.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Dole ne ya zama takardar shaidar gwamnati mai dauke da hoto. Duk takardun shaida dole ne a rubuta su cikin Haruffan Latin. Idan ba haka ba, ana buƙatar fassarar da aka tabbatar.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Nuna aikinka, faɗaɗa hanyoyin samun. 'Portfolio Na' shine zanen dijital naka a Instapay. Loda da nuna nau'ikan abubuwa daban-daban - daga hotuna masu jan hankali da bidiyo zuwa PDFs masu bayanai. Ko kai mai zane, mai zaman kansa ko mai kasuwanci, wannan fasalin yana ba ka damar nuna aikinka ko ayyukanka cikin hanya mai jan hankali ta fuskar kallo.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Raba lambar QR ɗinka ko haɗin biyan kuɗi don samun biyan kuɗi nan da nan a cikin Insta Wallet ɗinka",
    "Afghanistan": "Afaganistan",
    "Aland Islands": "Tsibirin Aland",
    "Albania": "Albaniya",
    "Algeria": "Aljeriya",
    "American Samoa": "Samowa ta Amurka",
    "Andorra": "Andora",
    "Angola": "Angola",
    "Anguilla": "Angila",
    "Antigua and Barbuda": "Antigwa da Barbuda",
    "Argentina": "Arjantina",
    "Armenia": "Armeniya",
    "Aruba": "Aruba",
    "Australia": "Ostareliya",
    "Austria": "Ostiriya",
    "Azerbaijan": "Azerbaijan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrain",
    "Bangladesh": "Bangladash",
    "Barbados": "Barbados",
    "Belarus": "Belarus",
    "Belgium": "Belgiyom",
    "Belize": "Beliz",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Bhutan",
    "Bolivia (Plurinational State of)": "Bolibiya",
    "Bonaire - Sint Eustatius and Saba": "Bonaire - Sint Eustatius da Saba",
    "Bosnia and Herzegovina": "Bosniya da Herzegovina",
    "Botswana": "Botswana",
    "Brazil": "Brezil",
    "British Indian Ocean Territory": "Yankin Tekun Indiya na Biritaniya",
    "Brunei Darussalam": "Brunai",
    "Bulgaria": "Bulgariya",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Kabo Verde",
    "Cambodia": "Kambodiya",
    "Cameroon": "Kamaru",
    "Canada": "Kanada",
    "Cayman Islands": "Tsibirin Cayman",
    "Central African Republic": "Jamhuriyar Afirka ta Tsakiya",
    "Chad": "Cadi",
    "Chile": "Cile",
    "China": "Sin",
    "Christmas Island": "Tsibirin Kirismeti",
    "Cocos (Keeling) Islands": "Tsibirin Cocos (Keeling)",
    "Colombia": "Kolombiya",
    "Comoros": "Kwamoras",
    "Congo": "Kongo",
    "Congo - Democratic Republic of the": "Jamhuriyar Dimokuradiyyar Kongo",
    "Cook Islands": "Tsibirin Cook",
    "Costa Rica": "Costa Rica",
    "Cote d'Ivoire": "Bakon Fildi",
    "Croatia": "Kuroshiya",
    "Cuba": "Kuba",
    "Curacao": "Kurakao",
    "Cyprus": "Sipra",
    "Czech Republic": "Jamhuriyar Czech",
    "Denmark": "Danmark",
    "Djibouti": "Jibuti",
    "Dominica": "Dominika",
    "Dominican Republic": "Jamhuriyar Dominican",
    "Ecuador": "Ekwado",
    "Egypt": "Masar",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Guinea ta Tsakiya",
    "Eritrea": "Eritireya",
    "Estonia": "Estoniya",
    "Eswatini": "Eswatini",
    "Ethiopia": "Habasha",
    "Falkland Islands (Malvinas)": "Tsibiran Falkland (Malvinas)",
    "Faroe Islands": "Tsibiran Faroe",
    "Fiji": "Fiji",
    "Finland": "Finland",
    "France": "Faransa",
    "French Polynesia": "Polynesia ta Faransa",
    "French Southern Territories": "Karkashin Kudu na Faransa",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Jojia",
    "Germany": "Jamus",
    "Ghana": "Gana",
    "Gibraltar": "Gibraltar",
    "Greece": "Girka",
    "Greenland": "Greenland",
    "Grenada": "Grenada",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea (Conakry)": "Guinea (Conakry)",
    "Guinea-Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Heard Island and McDonald Islands": "Tsibiran Heard da Tsibiran McDonald",
    "Holy See": "Holy See",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hungary",
    "Iceland": "Iceland",
    "India": "Indiya",
    "Indonesia": "Indoneziya",
    "Iran (Islamic Republic of)": "Iran (Jamhuriyar Musulunci)",
    "Iraq": "Iraki",
    "Ireland": "Ireland",
    "Isle of Man": "Tsibirin Man",
    "Israel": "Isra'il",
    "Italy": "Italiya",
    "Jamaica": "Jamaika",
    "Japan": "Japan",
    "Jersey": "Jersey",
    "Jordan": "Jordan",
    "Kazakhstan": "Kazakhstan",
    "Kenya": "Kenya",
    "Kiribati": "Kiribati",
    "Korea (Democratic People's Republic of)": "Korea (Democratic People's Republic of)",
    "Korea - Republic of": "Korea - Republic of",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Kyrgyzstan",
    "Lao People's Democratic Republic": "Laos People's Democratic Republic",
    "Latvia": "Latvia",
    "Lebanon": "Lebanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libya",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Lithuania",
    "Luxembourg": "Luxembourg",
    "Macao": "Macao",
    "Madagascar": "Madagascar",
    "Malawi": "Malawi",
    "Malaysia": "Malaysia",
    "Maldives": "Maldives",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshall Islands",
    "Mauritania": "Mauritania",
    "Mauritius": "Mauritius",
    "Mexico": "Mexico",
    "Micronesia (Federated States of)": "Micronesia (Federated States of)",
    "Moldova - Republic of": "Moldova - Republic of",
    "Monaco": "Monaco",
    "Mongolia": "Mongolia",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Morocco": "Maroko",
    "Mozambique": "Mozambique",
    "Myanmar": "Myanmar",
    "Namibia": "Namibiya",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Netherlands",
    "New Caledonia": "Sabuwar Caledonia",
    "New Zealand": "Sabuwar Zelandi",
    "Nicaragua": "Nikaragwa",
    "Niger": "Nijar",
    "Nigeria": "Najeriya",
    "Niue": "Niue",
    "Norfolk Island": "Tsibirin Norfolk",
    "North Macedonia": "Macedoniya ta Arewa",
    "Northern Mariana Islands": "Tsibirin Mariana na Arewa",
    "Norway": "Norway",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestine - State of": "Falasɗinu - Jamhuriyar",
    "Panama": "Panama",
    "Papua New Guinea": "Papuwa Sabuwar Guinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Filipins",
    "Pitcairn": "Tsibirin Pitcairn",
    "Poland": "Poland",
    "Portugal": "Portugal",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Qatar",
    "Romania": "Romania",
    "Russian Federation": "Rasha",
    "Rwanda": "Ruwanda",
    "Saint Barth\u00e9lemy": "Saint Barthélemy",
    "Saint Helena - Ascension and Tristan da Cunha": "Saint Helena",
    "Saint Kitts and Nevis": "Saint Kitts da Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Martin (French part)": "Saint Martin (Sashin Faransa)",
    "Saint Vincent and the Grenadines": "Saint Vincent da Grenadines",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "São Tomé da Príncipe",
    "Senegal": "Senegal",
    "Serbia": "Serbia",
    "Seychelles": "Seychelles",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapore",
    "Sint Maarten (Dutch part)": "Sint Martin",
    "Slovakia": "Slovakia",
    "Slovenia": "Slovenia",
    "Solomon Islands": "Solomon Islands",
    "Somalia": "Somaliya",
    "South Africa": "Afirka ta Kudu",
    "South Georgia and the South Sandwich Islands": "Koriya ta Kudu",
    "South Sudan": "Jamhuriyar Sudan ta Kudu",
    "Spain": "Spain",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard da Jan Mayen",
    "Sweden": "Sweden",
    "Switzerland": "Switzerland",
    "Syrian Arab Republic": "Syria",
    "Tajikistan": "Tajikistan",
    "United Republic of Tanzania": "Jamhuriya Tarayyar Tanzaniya",
    "Thailand": "Thailand",
    "Timor-Leste": "Timor-Leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad da Tobago",
    "Tunisia": "Tunisia",
    "Turkey": "Turkey",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Turks da Caicos Islands",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraine",
    "United Arab Emirates": "Hadaddiyar Larabawa",
    "United Kingdom of Great Britain and Northern Ireland": "United Kingdom na Ingila da Arewacin Ireland",
    "United States of America": "United States na Amurka",
    "United States Minor Outlying Islands": "United States na Tsibirin Kananan Wurare",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Uzbekistan",
    "Vanuatu": "Banuatu",
    "Venezuela (Bolivarian Republic of)": "Benezuela",
    "Viet Nam": "Biyetinam",
    "Virgin Islands (British)": "Tsibiran Birtaniya na Virgin",
    "Virgin Islands (U.S.)": "Tsibiran Amurka na Virgin",
    "Wallis and Futuna": "Wallis da Futuna",
    "Yemen": "Yemen",
    "Zambia": "Zambiya",
    "Zimbabwe": "Zimbabuwe",
    "Taiwan": "Taiwan",
    "Palestine": "Falasdinu",
    "Western Sahara": "Sahara ta Yamma",
    "Kosovo": "Kosovo",
    "South Ossetia": "Ossetia ta Kudu",
    "Abkhazia": "Abkhazia",
    "Nagorno-Karabakh (Artsakh)": "Nagorno-Karabakh",
    "Transnistria": "Transnistria",
    "Somaliland": "Somaliland",
    "Northern Cyprus": "Cyprus ta Arewa",
    "Saudi Arabia": "Saudiyya",
    "Yugoslavia": "Yugoslavia",

    "Login": "Shiga",
    "Enter Your Email": "Shigar da imel ɗinku",
    "Login With Phone Number": "Shiga tare da lambar waya",
    "Verify Using Sms/Email": "Tabbatar da amfani da SMS/Email",
    "Resend Code In 100 Seconds": "Aika lambar sake cikin daƙiƙa 100",
    "Verify": "Tabbatar",
    "Verifying": "Tabbatarwa na ci gaba",
    "Last Week": "Mako na ƙarshe",
    "Older Transaction": "Ma'amala tsohuwa",
    "Total": "Jimla",
    "Conversion Confirmed": "Canji an tabbatar",
    "User Account Limit": "Iyakar asusun mai amfani",
    "Monthly Balance Limit": "Iyakar ajiya na wata-wata",
    "Total Limit": "Jimlar iyaka",
    "Used": "Amfani",
    "Remaining": "Mafi Kusa",
    "Allowed Minimum Topup Amount": "Adadin ƙananan lodin da aka yarda da shi",
    "Allowed Maximum Topup Amount": "Adadin mafi girma na lodin da aka yarda da shi",
    "Incoming Limit": "Iyakar shigarwa",
    "Daily Limit": "Iyakar Kullum",
    "Monthly Limit": "Iyakar Watan",
    "Yearly Limit": "Iyakar Shekara",
    "Sending Limit": "Iyakar aika",
    "Allowed Number Of Transactions": "Yawan mu'amaloli da aka yarda",
    "Total Amount": "Jimlar adadi",
    "Amount To Send": "Kudin da za a aika",
    "Destination Amount": "Kudin da aka nufa",
    "Note": "Lura",
    "Revised Amount": "Kudin da aka gyara",
    "Save": "Ajiye",
    "Date": "Ranar",
    "Action": "Aiki",
    "Payment Scheduled At": "Lokacin Biyan Kuɗi",
    "Transaction Fee": "Kudin Harka",
    "Number Of Cycle": "Yawan Zagaye",
    "Subscription Starting Date": "Ranar Fara Rajista",
    "Until": "Zuwa",
    "Next Transaction": "Harkar Gaba",
    "Pay Now": "Biya Yanzu",
    "Transaction Method": "Hanyar Harka",
    "Mobile Money Provider": "Mai Bayar da Kuɗin Wayar Hannu",
    "Wallet Name": "Sunan Wallet",
    "Wallet Number": "Lambar Wallet",
    "Crypto Currency": "Kuɗin Digital",
    "Wallet Address": "Adireshin Wallet",
    "Select Document Type": "Zaɓi Nau'in Takarda",
    "Edit Document Number": "Gyara Lambar Takarda",
    "My Withdrawals": "Fitarwar Ku",
    "Bargainable": "Zai Iya Tattaunawa",
    "Description": "Bayani",
    "Attached Files": "Fayilolin da aka haɗa",
    "Sent": "An Aika",
    "Accepted": "An Karɓa",
    "Decline": "An ƙi",
    "Pending": "A cikin jiran aiki",
    "Overall Rating": "Jimlar Kimantawa",
    "No Review": "Babu Bita",
    "0 Rating": "Kimantawa 0",
    "Transaction Map": "Taswirar Ma'amala",
    "Send Money": "Aika Kudi",
    "Sending Method": "Hanyar Aiko",
    "Trouble Sending Money": "Matsala a Aika Kuɗi",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Kudin da aka karɓa da kuma adadin na iya canzawa bisa ga hanyar biyan kuɗi da aka zaɓa.",
    "Select Method": "Zaɓi Hanya",
    "Completed": "An Kammala",
    "How Was Your Experience?": "Yaya Kasancewar ƙwarewar ku?",
    "Leave A Review": "Bar Bita",
    "Review History": "Tarihin Bita",
    "Write A Review": "Rubuta Bita",
    "Send And Post": "Aika da wallafa",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Canjin Crypto zuwa Fiat yana da ƙarfi kuma mai aminci wanda ke canza cryptocurrencies zuwa kuɗin fiat ba tare da matsala ba. Yana biyan bukatun masu amfani daban-daban, yana ba da hanyoyin biyan kuɗi da dama waɗanda aka keɓe don dacewa da abubuwan da ake so na kowane mutum da wurare.\"",
    "Review Created": "Bita an ƙirƙira",
    "Transactions": "Ma'amaloli",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Ja/Dora ko loda fayil (Hotuna/Video/PDF)",
    "Max Size 1GB": "Mafi girman girman 1GB",
    "Title Description": "Bayanin taken",
    "Add New": "Ƙara Sabbi",
    "Discard": "Watsi",
    "Save And Post": "Ajiye da Buga",
    "Personal Account": "Asusun Kai",
    "Conversion": "Canji",
    "Spent In Total": "Jimlar da aka kashe",
    "International": "Kasashen Duniya",
    "Recharge": "Caji",
    "Top Up Wallet To Wallet": "Cika Wallet zuwa Wallet",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Ba a haɗa ba",
    "More": "Karin",
    "Less": "Ƙaranci",
    "Profile Reports": "Rahotannin Bayanai",
    "Timezone": "Lokacin Zama",
    "Cancel": "Soke",
    "Full Name": "Cikakken Suna",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Takardun Kari",
    "Profile Complete": "Cikakken Bayani",
    "Verified": "An Tabbatar",
    "Bank Account": "Asusun Banki",
    "Mobile Wallet": "Wallet na Waya",
    "Cash Pickup": "Karɓar Kuɗi",
    "Add": "Ƙara",
    "Configured": "An Shirya",
    "Email": "Imel",
    "Download Data": "Zazzage Bayanai",
    "Enter The Amount": "Shigar da Adadi",
    "They Receive": "Suna Karɓa",
    "Where Do You Want To Send Money": "Ina kake son Aika Kuɗi",
    "Bank Transfer": "Canja Wuri na Banki",
    "Receiving Method": "Hanyar Karɓa",
    "Payer": "Mai Biyan Kuɗi",
    "Minimum Amount": "Mafi ƙarancin Adadi",
    "Maximum Amount": "Mafi girman adadi",
    "Search": "Neman",
    "Show More": "Nuna ƙari",
    "Select Remark / Reasons (All)": "Zaɓi Sharhi / Dalilai (Duk)",
    "Attach Files": "Haɗa Fayiloli",
    "Holiday List": "Jerin Hutu",
    "No Data To Show": "Babu Bayanai Don Nuna",
    "Threshold Limits": "Iyakoki na Ƙa'idodi",
    "Amount Limits": "Iyakokin Adadi",
    "OTP Verification Code": "OTP Lambar Tabbatarwa",
    "Verify Using Authenticator": "Tabbatar da Amfani da Authenticator",
    "Sms/Email Verification Code": "SMS/Email Lambar Tabbatarwa",
    "Invite Them": "Kira Su",
    "Lookup": "Duba",
    "Invite Friend": "Kira Aboki",
    "Invite Via Phone Number": "Kira ta Lambar Waya",
    "Invite Via Email Address": "Kira ta Adireshin Imel",
    "Next": "Na gaba",
    "Back": "Komawa",
    "Invite Via Email": "Kira ta Imel",
    "Available Currency": "Kuɗin Da Ake da shi",
    "Add Remarks And Comments": "Ƙara Sharhi da Ra'ayoyi",
    "Recipient Details": "Cikakkun Bayanin Mai Karɓa",
    "Go Back": "Komawa",
    "Trouble Requesting Money": "Matsala wajen Neman Kuɗi",
    "Instant": "Nan da nan",
    "Subscription": "Rijista",
    "Schedule": "Jadawali",
    "Choose Request Type": "Zaɓi Nau'in Neman",
    "Mobile Airtime": "Lokacin Waya",
    "Incorrect Phone Number": "Lambar Waya Mara Daidai",
    "Make Sure You Have Entered A Correct Phone Number.": "Tabbatar cewa ka shigar da lambar waya daidai.",
    "Close": "Rufe",
    "Operator": "Mai Aiki",
    "Recipient Mobile Number": "Lambar Waya Mai Karɓa",
    "Amount": "Adadi",
    "Service": "Sabis",
    "Destination": "Wuri",
    "Top Up Summary": "Taƙaitaccen Cika",
    "Daily Sending Limit Exceeded": "Iyakance Adadin Aiki na Ranar ya wuce",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Aika da cikakken ƙididdiga na farashi ga abokin ciniki ko mai siye. Nuna sabis ɗinku, kayayyakin ku, ko tayin ku cikin kwararru tare da ƙididdiga na musamman.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Biyan kuɗi da aka yi a cikin kwanakin aiki kafin karfe 3:00 PM za a loda zuwa asusun bankin da ya karɓa kafin ƙarfe 12 na dare na ranar.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Saita asusun karɓar ku a cikin ƙasashe har zuwa uku kuma a fitar da kuɗi nan da nan daga buƙatun biyan kuɗi da aka amince da su zuwa hanyoyin biyan kuɗi da aka kunna.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom yana da hakkin neman duk wani ƙarin takardu (Ƙarin Duba Mai Zurfi - EDD) ko ƙarin bayanai da ake buƙata don gudanar da ingantattun bincike, bisa ga wajiban doka.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Dole ne ya zama takardar shaidar gwamnati mai dauke da hoto. Duk takardun shaida dole ne a rubuta su cikin Haruffan Latin. Idan ba haka ba, ana buƙatar fassarar da aka tabbatar.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Nuna aikinka, faɗaɗa hanyoyin samun. 'Portfolio Na' shine zanen dijital naka a Instapay. Loda da nuna nau'ikan abubuwa daban-daban - daga hotuna masu jan hankali da bidiyo zuwa PDFs masu bayanai. Ko kai mai zane, mai zaman kansa ko mai kasuwanci, wannan fasalin yana ba ka damar nuna aikinka ko ayyukanka cikin hanya mai jan hankali ta fuskar kallo.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Raba lambar QR ɗinka ko haɗin biyan kuɗi don samun biyan kuɗi nan da nan a cikin Insta Wallet ɗinka",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "Kana da minti 15 ka kammala ciniki.",
    "Time Remaining": "Don Allah ka kammala a cikin lokacin da aka kayyade don kauce wa ƙarewar URL.",
    "Please Process The Payment Via Your Selected Payment Type": "Don Allah ka aiwatar da biyan kuɗi ta hanyar nau'in biyan kuɗin da ka zaɓa.",
    "Top-Up Wallet": "Nau'in biyan kuɗi",
    "Confirm Your Payment": "CIKA WALLET",
    "You Will Get": "Tabbatar da Biyan kuɗinka",
    "Amount To Pay": "Za ka samu",
    "Proceed To Payment": "Adadin da za a biya",
    "Load/Deposit Money To The Wallet": "Ci gaba da biyan kuɗi",
    "Withdraw": "Shigar da kuɗi/cikawa a cikin walat",
    "Elevate Your Business With InstaPay": "Cire kuɗi",
    "Higher Balances & Transaction Limits": "Inganta Kasuwancinka tare da InstaPay",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Mafi girman ma'auni da iyaka na ciniki",
    "Full Feature Access": "Buɗe 'yancin sarrafa manyan ma'auni da aiwatar da manyan ayyukan ciniki, tare da amincin da kake tsammanin daga InstaPay.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Cikakken damar fasali",
    "Global Reach": "Duniya ta Nesa",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "Faɗaɗa kasuwancinku ba tare da iyaka ba. InstaPay yana haɗa ku da abokan ciniki da abokan hulɗa a duk duniya, yana sauƙaƙa ma'amaloli na duniya fiye da da.",
    "Advanced Security": "Tsaro Na Ci Gaba",
    "Business Registration Available Soon\n": "Rajistar kasuwanci za ta kasance nan ba da jimawa ba.",
    "Amount After Fee": "Adadin Bayan Kuɗi",
    "Allowed Maximum Amount Per Transaction": "Matsakaicin Adadin da Aka Amince da Shi Kowane Ma’amala",
    "Transfer": "Canja wurin kuɗi",
    "Uncategorized": "Ba a Rarraba ba",
    "Currency ISO Code & Amount": "Lambar ISO na Kuɗi & Adadi",
    "Choose The Top-Up Channel": "Zaɓi tashar Top-Up",
    "You Selected": "da kuka zaɓa",
    "Payout Channel": "tashar biyan kuɗi",
    "Your Details": "cikakkun bayananku",
    "Detailed Confirmed": "bayanai sun tabbata",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Ƙara yuwuwar kuɗin ku ta hanyar haɓakawa zuwa Asusun Kasuwanci na InstaPay. Ji cikakken ƙarfin InstaPay tare da ƙarin fasalulluka da aka tsara musamman don haɓaka kasuwanci.",
    "Profile Picture": "Hoton bayanai",
    "Add Photo Or Logo": "Ƙara Hoto ko Logo",
    "First Name": "Sunan farko",
    "Last Name": "Sunan ƙarshe",
    "Enter Your First Name": "Shigar da sunan farkonku",
    "Enter Your Last Name": "Shigar da sunan ƙarshe",
    "Email (Optional)": "Imel (na zaɓi)",
    "Password Must": "Kalmar sirri dole ne",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Za a cika shi ta atomatik idan kuna rijista tare da haɗin gayyata.",
    "Change Number": "Canza lamba",
    "Search Country": "Nemo ƙasa",
    "Select From The List": "Zaɓi daga jerin",
    "Received Amount": "Adadin da aka karɓa",

    "null": null,
    "Processing": "Gudanarwa",
    "Verify Using Sms/Email": "Tabbatar da SMS/Imel",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Tuni ya wanzu",
    "You Can Have Only 8 Wallets": "Kuna iya samun walat guda 8 kawai",
    "Save": "Ajiye",
    "0 File": "Fayil 0",
    "Search From The List": "Nemo daga jerin",
    "Amount To Send": "Adadin da za a aika",
    "Wallet To Wallet": "Wallet zuwa Wallet",
    "TOP-UP WALLET ": "CIKA WALLET",
    "Enter The Amount": "Shigar da adadi",
    "Load/Deposit Money To The Wallet": "Loda/Adana Kudi zuwa walat",
    "Choose The Top-Up Channel": "Zaɓi tashar cika walat",
    "Paypal": "Paypal",
    "You Will Get": "Za ku samu",
    "Amount To Pay": "Adadin da za a biya",
    "Card Holder Name ": "Sunan mai katin",
    "Card Number": "Lambar katin",
    "CVC": "CVC",
    "MM/YY": "MM/YY",
    "Your Details ": "Bayanan ku",
    "You Selected": "Kun zaɓi",
    "Detailed Confirmed": "An tabbatar da daki-daki",
    "Transaction Failed": "An kasa kulla ma’amala",
    "Something Went Wrong. Try Again.": "Wani abu ya faru ba daidai ba. Sake gwadawa.",
    "Convert Funds": "Canza kuɗi",
    "How Would You Like To Cashout?:": "Yaya kuke son cire kuɗi?:",
    "Default Receiving Account": "Asusun Karɓar Tsoho",
    "Specify Receiving Account": "Fayyace Asusun Karɓar",
    "Which Receiving Account Would You Want Use?:": "Wanne asusun karɓa kuke son amfani da shi?:",
    "Pakistan": "Pakistan",
    "Bank Mobile": "Banki Mobile",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Babu",
    "Request": "Nemi",
    "Subscription Payment": "Biyan Kuɗin Rajista",
    "Is not supported by Paypal, USD will be used as the default currency.": "Ba a tallafa PayPal ba, za a yi amfani da USD a matsayin tsohuwar kudin.",
    "Review Payment": "Duba Biya",
    "Google Authenticator": "Tabbatar da Google",
    "Scheduled Has Been Cancelled": "An soke jadawalin",
    "Select Country First": "Zaɓi ƙasa da farko",
    "Select Gender": "Zaɓi jinsi",
    "Select Occupation": "Zaɓi aikin yi",
    "IBAN and SWIFT/BIC": "IBAN da SWIFT/BIC",
    "Mobile Money Provider": "Mai bayar da kuɗi ta hannu",
    "Wallet Name": "Sunan Wallet",
    "Wallet Number ": "Lambar Wallet",
    "Crypto Currency": "Kudaden Crypto",
    "Wallet Address ": "Adireshin Wallet",
    "Select Document Type ": "Zaɓi Nau'in Takardu",
    "Enter Document Number": "Shigar da Lambar Takardu",
    "Msisdn": "MSISDN",
    "Edit ": "Shirya",
    "My Withdrawls": "Fitarwata",
    "Declined": "An ƙi",
    "Accepted": "An amince",
    "Overall Ratings": "Jimlar Kimantawa",
    "0 Ratings": "0 Kimantawa",
    "No Reviews": "Babu Bita",
    "Send Money": "Tura Kuɗi",
    "Trouble Sending Money?": "Matsala wajen tura kuɗi?",
    "Payment Request": "Neman Biya",
    "How Was Your Experience?": "Ta yaya kwarewarka ta kasance?",
    "Leave A Review": "Bar bita",
    "Write Review Message": "Rubuta saƙon bita",
    "Send And Post": "Aika kuma aika",
    "You Rated": "Ka ba da kimantawa",
    "Review History": "Tarihin bita",
    "Max Size 1GB": "Matsakaicin girman 1GB",
    "Title": "Take",
    "Description": "Bayani",
    "Add New": "Ƙara Sabon",
    "My Payment Address": "Adireshin Biya na",
    "Personal Account": "Asusun Kaina",
    "Updated Successfully": "An sabunta nasara",
    "Spent In": "An kashe a",
    "TOP UP": "Sake cika",
    "Quotation": "Bayani",
    "Amount": "Adadi",
    "Not Connected": "Ba a haɗa ba",
    "More": "Karin bayani",
    "Less": "Kasa",
    "Reports": "Rahotanni",
    "User Profile": "Bayanai na mai amfani",
    "Cancel": "Soke",
    "Saving": "Ajiye",
    "Confirm ": "Tabbatar",
    "Verify ": "Duba",
    "Delete Country": "Cire ƙasa",
    "Bank Account": "Asusun banki",
    "Cash Pickup": "Karɓar Kuɗi",
    "Mobile Wallet ": "Wallet na wayar hannu",
    "Delete Country Withdrawals": "Cire Zuba Kasuwanci",
    "Yes , Delete It": "I, Cire",
    "E-sim": "E-sim",
    "Utilities": "Ayyuka",
    "Searching": "Bincike",
    "Recipient Mobile Number": "Lambar waya ta mai karɓa",
    "TOP-UP SUMMARY": "Taƙaitaccen lissafin ƙara",
    "Trouble Requesting Money?": "Matsala wajen buƙatar Kuɗi?",
    "Choose The Beneficiary": "Zaɓi Mai Amfana",
    "Enter The amount": "Shigar da adadi",
    "You selected": "Ka zaɓa",
    "Timezone": "Lokaci na yanki",
    "Starting From": "Farawa daga",
    "Recurring Cycle": "Zagaye Mai Dawo",
    "Until I stop": "Har sai na daina",
    "Schedule To": "Shirya Zuwa",
    "Schedule at": "Shirya a",
    "Selected time must be at least 5 minutes from now.": "Lokaci da aka zaɓa dole ne ya kasance akalla minti 5 daga yanzu.",
    "Is this your Location?": "Wannan shine wurinka?",
    "Yes ": "I",
    "Select Remark Reason": "Zaɓi dalilin sharhi",
    "Attach File": "Haɗa fayil",
    "Invite via Phone Number": "Gayyatar ta Lambar Waya",
    "Invite Friend": "Gayyatar Aboki",
    "Invite via Email Address": "Gayyatar ta Adireshin Imel",
    "Invite via": "Gayyatar ta",
    "Phone": "Waya:",
    "Message": "Saƙo:",
    "Send": "Aika",
    "Invited Succesfully!": "An gayyata Cikin Nasara!",
    "Email": "Imel",
    "Bank Transfer": "Canjin Banki",
    "Mobile Wallet": "Asusun Waya",
    "Select The Bank": "Zaɓi banki",
    "Select The Payer": "Zaɓi Mai Biya",
    "Min Amount:": "Mafi ƙarancin Kudi:",
    "Max Amount": "Mafi yawan Kudi",
    "Other Beneficiaries:": "Wasu Masu Amfana:",
    "Back ": "Komawa",
    "Next": "Gaba",
    "Lookup": "Neman",
    "Invite Them": "Kira su",
    "Delete Channel ": "Cire tashar",
    "Set As Default": "Saita a matsayin na al'ada",
    "See More": "Duba ƙari",
    "Session Details": "Cikakkun bayanan zaman",
    "Platform": "Dandalin",
    "Windows": "Windows",
    "Browser Name": "Sunan mai bincike",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Sigar Mai Bincike",
    "IP Address": "Adireshin IP",
    "Karachi": "Karachi",
    "State": "Jihar",
    "Sindh": "Sindh",
    "Active": "Aiki",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Wannan walat ɗin kuɗi a halin yanzu an dakatar da shi/ƙin aiki kuma ba za a iya ƙara kuɗi ba.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Wannan walat ɗin kuɗi a halin yanzu an dakatar da shi/ƙin aiki kuma ba za a iya sauya kuɗi ba.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Wannan walat ɗin kuɗi a halin yanzu an dakatar da shi/ƙin aiki kuma ba za ku iya janye kudi ba.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Wannan walat ɗin kuɗi a halin yanzu an dakatar da shi/ƙin aiki kuma ba za ku iya sauke bayanin ba.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Wannan walat ɗin kuɗi a halin yanzu an saita shi azaman na al'ada kuma ba za a iya dakatar da shi ba. Don ci gaba, da fatan za a saita wani walat ɗin kuɗi na daban azaman na al'ada na farko.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Wannan walat ɗin kuɗi a halin yanzu an saita shi azaman na al'ada kuma ba za a iya kashe shi ba. Don ci gaba, da fatan za a saita wani walat ɗin kuɗi na daban azaman na al'ada na farko.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Shin kuna da tabbacin cewa kuna son share bayanan janye kudi na wannan ƙasa?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Saita asusun karɓar ku a ƙasashe uku kuma ku fitar da kuɗi nan da nan daga buƙatun biyan kuɗi waɗanda aka amince da su zuwa hanyoyin biyan kuɗi da aka kunna.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Ba a sami mai amfana daga Pakistan tare da cikakkun bayanan Allied Bank Limited ba",
    "Invitation Message Has Been Successfully Sent To": "Saƙon gayyata an aika shi cikin nasara zuwa",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Kudin da aka samu da adadin da aka karɓa na iya bambanta dangane da hanyar biyan kuɗin da aka zaɓa.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "Raba QR Code ɗinku ko hanyar haɗin biyan kuɗi don samun biyan kuɗi nan da nan a cikin walat ɗinku na insta.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Ja/Ƙaddamar ko loda fayil (Hoton/Vidio/PDF)",
    "Login Again": "Shiga sake",
    "Unblock The Wallet": "Buɗe walat",
    "Invalid Amount": "Mafi ƙarancin kuɗi",
    "Add Payment Card": "Ƙara katin biyan kuɗi",
    "Save Details To Use Next Time": "Ajiye bayanai don amfani da su a karo na gaba",
    "Paid": "An biya",
    "Started On": "An fara a",
    "Num Of Cycles": "Yawan zagaye",
    "Ends On": "Yana ƙare a",
    "Next Payment At": "Biyan kuɗi na gaba a",
    "Beneficiary": "Mai amfana",
    "Recieved Quotation": "An karɓi ƙididdiga",
    "Sent Quotation": "An aika ƙididdiga",
    "Decline Offer": "Karya tayin",
    "Are You Sure That You Want To Decline The Offer?": "Shin kuna da tabbacin cewa kuna son ƙi tayin?",
    "Decline The Offer": "Karya tayin",
    "Bargain Offer": "Tayin tawaye",
    "Enter Counter Amount": "Shigar da adadin martani",
    "Custom Amount": "Adadi na musamman",
    "Send The Offer": "Aika tayin",
    "The Offer Is Sent": "Tayin an tura",
    "Countered Offer": "Tayin martani",
    "Applied": "An yi amfani",
    "Accept Quotation": "Karɓi ƙididdiga",
    "Quotation Not Found Or Already Exist": "Ba a sami ƙididdiga ba ko yana nan",
    "Recieved Request": "An karɓi buƙata",
    "Sent Request": "An aika buƙata",
    "Search Request": "Bincika buƙata",
    "Search Quotation": "Bincika ƙididdiga",
    "QR Status": "Matsayin QR",
    "QR Code Description": "Bayanin QR code",
    "Download Your QR Code": "Zazzage QR ɗinku",
    "Your QR Code": "QR ɗinku",
    "Your QR Code Poster": "Bango na QR ɗinku",
    "Download  ": "Zazzage",
    "Select Relation": "Zaɓi dangantaka",
    "Nominee Permanent Address": "Adireshin dindindin na wanda aka zaɓa",
    "Enter Your Message": "Shigar da saƙon ku",
    "Select": "Zaɓi",
    "Select File Type": "Zaɓi nau'in fayil",
    "Send TOP-UP And E-Sim": "Aika ƙara da E-sim",
    "Search Users": "Bincika masu amfani",
    "Select Payment Request Type": "Zaɓi nau'in buƙatar biyan kuɗi",
    "Wallet To Wallet Transfer": "Canja wurin wallet zuwa wallet",
    "Holiday List": "Jerin hutu",
    "No Data To Show": "Babu bayanai da za a nuna",
    "They Recieve": "Sun karɓa",
    "You Will Be Logged Out In 60 Seconds": "Za a cire ka daga tsarin cikin dakika 60",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Matsa linzamin kwamfuta ko danna kowanne maɓallin keyboard don sake saita agogon zuwa mintuna 15",
    "You've Been Logged Out": "An cire ka daga tsarin",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Don tsaronka, an cire ka daga tsarin bayan mintuna 15 na rashin aiki. Da fatan za ka shiga sake idan kana son amfani da InstaPay.",
    "Cash": "Kudi",
    "Business": "Kasuwanci",
    "Gift": "Kyauta",
    "Salary": "Albashi",
    "Lottery": "Lotto",
    "Savings": "Ajiya",
    "Other": "Sauran",
    "Select Source Of Fund": "Zaɓi Tushen Kuɗi",
    "Select your source of funds": "Zaɓi tushen kuɗin ku",
    "Request Times Out. Please Try Again!": "Bari lokaci ya ƙare. Da fatan za a sake gwadawa!",
    "Resend Code In 100 Seconds": "Aika lambar sake cikin daƙiƙa 100",
    "Computer Service": "Sabis na Kwamfuta",
    "Family Support": "Taimakon Iyali",
    "Education": "Ilmi",
    "Gift And Other Donations": "Kayan kyauta da sauran tallafi",
    "Medical Treatment": "Magani",
    "Maintenance Or Other Expenses": "Kulawa ko sauran kashe-kashe",
    "Travel": "Tafiya",
    "Small Value Remittance": "Kananan ƙima na aika kuɗi",
    "Liberalized Remittance": "Sauya kuɗi na 'yanci",
    "Construction Expenses": "Kudin gini",
    "Hotel Accommodation": "Masaukin otel",
    "Advertising And/Or Public Relations Related Expenses": "Kasuwancin talla da/ko alaƙa da hulɗa da jama'a",
    "Fees For Advisory Or Consulting Service": "Kudin shawarwari ko sabis na shawarwari",
    "Business Related Insurance Payment": "Biyan inshorar kasuwanci",
    "Insurance Claims Payment": "Biyan buƙatun inshora",
    "Delivery Fees": "Kudin isarwa",
    "Payments For Exported Goods": "Biyan kayan da aka fitar",
    "Payment For Services": "Biyan sabis",
    "Payment Of Loans": "Biyan bashi",
    "Office Expenses": "Kudin ofis",
    "Residential Property Purchase": "Siyan kadarorin zama",
    "Property Rental Payment": "Biyan haya na kadarori",
    "Royalty, Trademark, Patent And Copyright Fees": "Kuɗin haƙƙin mallaka, alamar kasuwanci, haƙƙin mallakar tunani da haƙƙin mallaka",
    "Investment In Shares": "Zuba jari a cikin hannayen jari",
    "Fund Investment": "Zuba jari a cikin hannun jari",
    "Tax Payment": "Biyan haraji",
    "Transportation Fees": "Kudin sufuri",
    "Utility Bills": "Biyan kuɗin sabis",
    "Personal Transfer": "Canja wurin mutum",
    "Payment Of Salary": "Biyan albashi",
    "Payment Of Rewards": "Biyan lada",
    "Payment Of Influencer": "Biyan tasiri",
    "Broker, Commitment, Guarantee And Other Fees": "Kudin dillalai, alkawari, garanti da sauran kuɗaɗe",
    "Other Purposes": "Sauran manufofi",
    "Aunt": "Aunt",
    "Brother": "Brother",
    "Brother-In-Law": "Brother-in-law",
    "Cousin": "Cousin",
    "Daughter": "Daughter",
    "Father": "Father",
    "Father-In-Law": "Father-in-law",
    "Friend": "Friend",
    "Grandfather": "Grandfather",
    "Grandmother": "Grandmother",
    "Husband": "Husband",
    "Mother": "Mother",
    "Mother-In-Law": "Mother-in-law",
    "Nephew": "Nephew",
    "Niece": "Niece",
    "Self (i.e. The Sender, Himself)": "Self (wato mai aikawa da saƙon)",
    "Sister": "Sister",
    "Sister-In-Law": "Sister-in-law",
    "Son": "Son",
    "Uncle": "Mjomba",
    "Wife": "Matar",
    "Others Not Listed": "Sauran wadanda ba a lissafa ba",
    "Passport": "Passport",
    "National Identification Card": "National Identification Card",
    "Driving License": "Driving License",
    "Social Security Card/Number": "Social Security Card/Namba",
    "Tax Payer Identification Card/Number": "Tax Payer Identification Card/Namba",
    "Senior Citizen Identification Card": "Senior Citizen Identification Card",
    "Birth Certificate": "Takaddar Haihuwa",
    "Village Elder Identification Card": "Katin Shaidar Shugaban Kauye",
    "Permanent Residency Identification Card": "Katin Shaidar Zaman Dindindin",
    "Alien Registration Certificate/Card": "Takardar Rajistar Bafarawa/Kati",
    "PAN Card": "Katin PAN",
    "Voter’s Identification Card": "Katin Shaidar Masu Zabe",
    "Health Insurance Card/Number": "Katin/Lambar Inshorar Lafiya",
    "Employer Identification Card": "Katin Shaidar Mai Aiki",
    "Others Not Listed": "Sauran da ba a lissafa ba",
    "Bank Account": "Asusun Banki",
    "Cash Pickup": "Cire Kudi",
    "Card": "Kati",
    "Arts & Entertainment": "Arts da Nishaɗi",
    "Banking & Finance": "Banki da Kuɗi",
    "Education & Research": "Ilimi da Bincike",
    "Engineering & Construction": "Injiniya da Gina",
    "Healthcare & Medicine": "Kiwon Lafiya da Magunguna",
    "Information Technology & Services": "Fasaha ta Bayanai da Ayyuka",
    "Legal & Compliance": "Doka da Bin Ka'idoji",
    "Manufacturing & Production": "Kera da Samarwa",
    "Marketing & Sales": "Talla da Sayarwa",
    "Non-profit & Charity": "Kungiya Mara Riba da Agaji",
    "Real Estate & Property": "Gidaje da Kadarori",
    "Retail & Wholesale": "Kasuwanci da Kasuwancin Kasuwa",
    "Science & Pharmaceuticals": "Kimiyya da Magunguna",
    "Transport & Logistics": "Sufuri da Lojistiks",
    "Travel & Tourism": "Tafiya da Turawa",
    "Unemployed": "Maras Aiki",
    "Other": "Sauran",
    "Cash": "Kudi",
    "Business": "Kasuwanci",
    "Gift": "Kyauta",
    "Salary": "Albashi",
    "Lottery": "Loto",
    "Savings": "Ajiya",
    "Good Morning": "Good Morning",
    "Good Evening": "Barka da yamma",
    "Completed": "An Kammala",
    "Load/Deposit Money To The Wallet": "Ci gaba da biyan kuɗi",
    "Enter The Amount": "Shigar da adadi",
    "Choose The Top-Up Channel": "Zaɓi tashar Top-Up",
    "Good Night!": "Barka da dare!",
    "You Will Get": "Tabbatar da Biyan kuɗinka",
    "Amount To Pay": "Za ka samu",
    "Top-Up Wallet": "Nau'in biyan kuɗi",
    "Card Holder Name ": "Sunan mai katin",
    "Card Number": "Lambar katin",
    "CVC": "CVC",
    "MM/YY": "MM/YY",
    "Save Details To Use Next Time": "Ajiye bayanai don amfani da su a karo na gaba",
    "Select Or Add Payment Card": "Zaɓi ko Ƙara Katin Biyan Kudi",
    "Your Details ": "Bayanan ku",
    "You Selected": "Kun zaɓi",
    "Detailed Confirmed": "An tabbatar da daki-daki",
    "Transaction Failed": "An kasa kulla ma’amala",
    "Something Went Wrong. Try Again.": "Wani abu ya faru ba daidai ba. Sake gwadawa.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Kasawa",
    "Initiated": "An fara",
    "Revise": "Gyara",
    "Declined": "An ƙi",
    "Bargain-Accepted": "Tattaunawa-An Karɓa",
    "Accepted": "An Karɓa",
    "Decline The Offer": "Karyata Tayin",
    "Pending": "Na jiran",
    "Cancelled": "An Soke",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Aika da cikakken ƙididdiga na farashi ga abokin ciniki ko mai siye. Nuna sabis ɗinku, kayayyakin ku, ko tayin ku cikin kwararru tare da ƙididdiga na musamman.",
    "Wallet to wallet": "Daga Wallet zuwa Wallet",
    "Please follow these examples:": "Da fatan za ku bi waɗannan misalan:",
    "👤 InstaPay/Instagram Username: instauser": "👤 InstaPay/Instagram Suna na Mai amfani: instauser",
    "📧 Email: user@email.com": "📧 Imel: user@email.com",
    "Full Name": "Cikakken Suna",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "Barka da zuwa InstaPay! Don tabbatar da cewa zaku iya cire kuɗin da kuka karɓa nan take, kuna buƙatar saita asusun karɓa a cikin saituna. Wannan zai ba ku damar canja kuɗi cikin sauƙi zuwa asusun banki da kuka fi so, walat ɗin hannu, walat ɗin crypto, ko wuraren karɓar kuɗi.",
    "You Have No Beneficiary": "Babu wanda zai amfana da kai",
    "Add Now": "Kara yanzu",
    "Wallet-Status": "Matsayin walat",
    "Select Payment Card": "Zaɓi katin biyan kuɗi",
    "Your funds have been credited to your wallet.": "An ƙara kuɗinku zuwa walat ɗinku.",
    "Thanks for using InstaPay": "Na gode da amfani da InstaPay",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Samu kuɗi nan take: Saita asusunku na karɓa yanzu!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Bi waɗannan sauƙaƙan matakai don saita asusun karɓa na ku kuma tabbatar da cewa koyaushe kuna shirye don samun damar kuɗin ku:",
    "STEP 01": "MATAKI NA 01",
    "Go to Settings": "Je zuwa Saituna",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "Buɗe manhajar InstaPay ɗin ku kuma je zuwa sashin \"Saituna\".",
    "STEP 02": "MATAKI NA 02",
    "Select Receiving Accounts": "Zaɓi Asusun Karɓa",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Matsa kan \"Asusun Karɓa\" don fara ƙara bayanan ku.",
    "Start Adding Accounts": "Fara Ƙara Asusun",
    "Phone Number: 44795396600 (With Country Code)": "Lambar Waya: 44795396600 (Tare da Lambar Kasa)",
    "Select Country First": "Zaɓi ƙasa da farko",
    "First Name": "Sunan farko",
    "Last Name": "Sunan ƙarshe",
    "Address Line": "Layin Adireshi",
    "Email Address": "Adireshin Imel",
    "Select City": "Zaɓi Birni",
    "Select Gender": "Zaɓi jinsi",
    "Select Occupation": "Zaɓi aikin yi",
    "Male": "Namiji",
    "Female": "Matar",
    "Non-Binary": "Babu jinsin",
    "Prefer Not To Say'": "Ba na son faɗi",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Babu Mai Amfani da Aka Samu",
    "Email Address ": "Adireshin Imel",
    "Select Document": "Zaɓi Takarda",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Nuna aikinka, faɗaɗa hanyoyin samun. 'Portfolio Na' shine zanen dijital naka a Instapay. Loda da nuna nau'ikan abubuwa daban-daban - daga hotuna masu jan hankali da bidiyo zuwa PDFs masu bayanai. Ko kai mai zane, mai zaman kansa ko mai kasuwanci, wannan fasalin yana ba ka damar nuna aikinka ko ayyukanka cikin hanya mai jan hankali ta fuskar kallo.",
    "Add New": "Ƙara Sabon",
    "Money Out": "Kuɗi fita",
    "Browsername": "Sunan Browser",
    "Browserversion": "Sigogin Browser",
    "Credit": "Kiredit",
    "Debit": "Ragi",
    "Top Up": "Sake Caji",
    "Wallet To Wallet": "Aljihu zuwa Aljihu",
    "Accountant": "Ma'ajin Kuɗi",
    "Architect": "Mai Gina-gine",
    "Artist": "Mawaka",
    "Banker": "Ma'aikacin Banki",
    "Business Owner": "Mai Kasuwanci",
    "Consultant": "Mai Ba da Shawara",
    "Doctor": "Likita",
    "Engineer": "Injiniya",
    "Farmer": "Manomi",
    "Government Employee": "Ma'aikacin Gwamnati",
    "IT Professional": "Ƙwararren IT",
    "Lawyer": "Lauya",
    "Nurse": "Nas",
    "Retailer": "Mai Sayarwa",
    "Salesperson": "Mai siyar da kaya",
    "Student": "Dalibi",
    "Teacher": "Malami",
    "Prefer Not to Say": "Ina son barin a haka",
    "Timezone": "Lokaci na yanki",
    "Subscription Payment": "Biyan Kuɗin Rajista",
    "Starting From": "Farawa daga",
    "Recurring Cycle": "Zagaye Mai Dawo",
    "Until I stop": "Har sai na daina",
    "Schedule To": "Shirya Zuwa",
    "Schedule at": "Shirya a",
    "Selected time must be at least 5 minutes from now.": "Lokaci da aka zaɓa dole ne ya kasance akalla minti 5 daga yanzu.",
    "They Recieve": "Sun karɓa",
    "Attach Files": "Haɗa Fayiloli",
    "Wallet Selected": "An Zaɓi Wallet",
    "You Have Selected PKR Currency Wallet For This Transaction": "Kun Zaɓi Wallet Na Kuɗi PKR Don Wannan Ma'amala",
    "Instant": "Nan da nan",
    "Subscription": "Rijista",
    "Schedule": "Jadawali",
    "Sms/Email Verification Code": "SMS/Email Lambar Tabbatarwa",
    "Confirm Your Password": "Tabbatar da Kalmar Sirri",
    "This Security Feature Requires Password Confirmation!": "Wannan fasalin Tsaro yana buƙatar Tabbatar da Kalmar Sirri!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Yi Amfani da Aikace-aikacen don Scan QR Code ko Yi Amfani da Kadin Sirrinka don kunna Google Two-Factor Authentication",
    "OR": "KO",
    "Paste This Key In The Googe Authenticator App": "Manna Wannan Mabuɗin a cikin Google Authenticator App",
    "Show Secret Key": "Nuna Mabuɗin Sirri",
    "Enter The Six-Digit Code From The Application": "Shigar da Lambar Dijiti guda Shida daga Aikace-aikacen",
    "6 Digit Code": "Lambar Dijiti 6",
    "Enable": "Kunna",
    "Disable": "Kashe",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Bayan Scan Hoto na Barcode, Aikace-aikacen zai nuna Lambar Dijiti guda Shida da zaka iya shigar a ƙasa",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "Google Two-Factor Authentication yanzu yana haɗe da Asusun Instapay ɗinka",
    "Min Amount:": "Mafi ƙarancin Kudi:",
    "Minimum Amount": "Mafi ƙarancin Adadi",
    "Maximum Amount": "Mafi girman adadi",
    "Identity Verification Required": "Bukatar Tabbatar da Shaidar",
    "Select Your Wallet Currency": "Zabi Kuɗin Walat ɗinku",
    "Setup Authenticator": "Saita Mai Tabbatarwa",
    "Note": "Lura",
    "This Is A Test Notice": "Wannan Wani Sanarwar Gwaji ne",
    "Session Expired": "Zaman ya ƙare",
    "You have been logged out for security reasons, please login again to continue.": "An fitar da ku saboda dalilan tsaro, don Allah shiga sake don ci gaba.",
    "Redirecting You To Login Page In 5 Seconds": "Za a tura ku zuwa shafin shiga cikin dakikoki 5",
    "Searching": "Bincike",
    "Recipient Mobile Number": "Lambar waya ta mai karɓa",
    "TOP-UP SUMMARY": "Taƙaitaccen lissafin ƙara",
    "Amount": "Adadi",
    "Something went wrong while getting countries.": "Wani abu ya faru yayin da aka sami ƙasashe.",
    "Select Destination Country": "Zaɓi ƙasar manufa.",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR ba a goyi bayan Paypal ba, USD za a yi amfani da shi a matsayin kudin daidaitawa.",
    "Select A Date": "Zaɓi Wata Ranar",
    "Select A Timezone": "Zaɓi Yankin Lokaci",
    "Proceed": "Ci gaba",
    "Proceeding": "Ana Ci gaba",
    "You Can Now Close This Webpage And Go Back To Instagram": "Zaku iya rufe wannan shafin yanar gizon yanzu kuma ku koma zuwa Instagram",
    "Select A Time": "Zaɓi Lokaci",
    "Location Permission Denied": "An ƙi izinin wurin",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Bi umarnin da ke ƙasa don kunna sabis na wurin a cikin burauzarku bayan ƙin izini a baya:",
    "1: Chrome Browser": "1: Chrome Browser",
    "Open the Chrome app.": "Buɗe manhajar Chrome.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Matsa menu ɗin maki uku (saman dama) ka zaɓi Saituna.",
    "Scroll down to Site Settings > Location.": "Jeka ƙasa zuwa Saitunan Shafin > Wuri.",
    "Locate the website in the list or search for it.": "Nemo shafin yanar gizon a jerin ko bincika shi.",
    "Tap the website and select Allow under Location Access.": "Matsa shafin yanar gizon ka zaɓi Bada izini a ƙarƙashin Samun Wuri.",
    "2: Firefox Browser": "2: Firefox Browser",
    "Open the Firefox app.": "Buɗe manhajar Firefox.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Matsa menu ɗin layuka uku (ƙasan dama) ka je zuwa Saituna.",
    "Select Privacy & Security > Site Permissions > Location.": "Zaɓi Sirri & Tsaro > Izinin Shafi > Wuri.",
    "Find the website in the list and set the permission to Allow.": "Nemo shafin yanar gizon a jerin kuma sa izini zuwa Bada izini.",
    "3: Samsung Internet Browser": "3: Samsung Internet Browser",
    "Open the Samsung Internet app.": "Buɗe manhajar Samsung Internet.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Matsa menu ɗin layuka uku (ƙasan dama) ka zaɓi Saituna.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Je zuwa Shafuka da Zazzagewa > Izinin Shafi > Wuri.",
    "Find the website and change the permission to Allow.": "Nemo shafin yanar gizon ka canza izini zuwa Bada izini.",
    "4: Safari Browser": "4: Mai Bincike na Safari",
    "Open the Settings app.": "Buɗe manhajar Saituna.",
    "Scroll down and select Safari.": "Yi gungurawa ƙasa ka zaɓi Safari.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Matsa wurin da aka saita zuwa Tambaya Sannan Ko Bari Yayin Amfani da Manhajar.",
    "Revisit the website and when prompt, allow location access": "Sake ziyartar gidan yanar gizon kuma idan an buƙata, ba da damar samun wurin.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "Tukwici: Idan gidan yanar gizon bai bayyana a cikin saitunan mai bincike ba, share bayanan ƙwaƙwalwar ajiya ko tarihin bincike sannan ka sake gwadawa.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Don aika buƙatar biyan kuɗi, don Allah a raba wurinku—wannan yana taimaka mana wajen ƙara tsaro da gina amana don ƙwarewa mai sauƙi!",
    "Decline": "ƙi",
    "Share Location": "Raba wurin",
    "Already Exist": "Tuni Akwai",
    "Withdraw": "Cire",
    "Seller Left A reply": "Mai sayarwa ya bar amsa",
    "Check Now": "Duba yanzu",
    "You Left A Review": "Ka bar bita",
    "View Larger Map": "Duba Babban Taswira",
    "English (UK)": "Turanci (UK)",
    "QR Code Title": "Sunan Lambar QR",
      "Notice!": "Gargaɗi!",
  "By proceeding with this transaction, I hereby confirm that I am acting independently and of my own free will. I affirm that I have not been influenced, coached, coerced, or persuaded by any third party to carry out this transaction for investment purposes or any other reason.": "Ta hanyar ci gaba da wannan mu'amala, na tabbatar da cewa ina yin hakan ne bisa 'yancina kuma da yardar kaina. Na tabbatar cewa ba wani bangare na uku da ya shafi shawara, koyarwa, tilasta ko lallashin ni don yin wannan mu'amala don saka jari ko wata dalili.",
  "I understand that this confirmation is required to ensure compliance and to safeguard my account and transactions.": "Na fahimci cewa wannan tabbatarwa tana da mahimmanci don tabbatar da bin ka'ida da kuma kare asusuna da ma'amalolina.",
  "Confirm and Proceed": "Tabbatar da ci gaba",
  "Blocked Wallet": "Aljihun da Aka Rufe",
  "Nominee's Full Name": "Cikakken Sunan Mai Nomin",
  "Insufficient Balance": "Rashin Ƙari",
  "Upload Photo": "Loda Hoto",
   "Security Alert": "Gargadi na Tsaro",
  "Please remember to add your security questions for smooth experience. You will not be able to access the features of InstaPay unless You add the security questions.": "Da fatan za a tuna da ƙara tambayoyinku na tsaro don ƙwarewar da ta fi dacewa. Ba za ku iya samun damar fasalulluka na InstaPay ba sai kun ƙara tambayoyin tsaro.",
  "Setup Security Questions": "Saita Tambayoyin Tsaro","Geo Fencing": "Kariya ta Wurin Geo",
  "Manage Payment & Quotation Access": "Sarrafa Samun Biyan Kuɗi & Kwatance",
  "Control countries from where you can receive Payment Requests and Quotations. Toggle on/off access for specific countries.": "Kula da ƙasashen da za ku iya karɓar Buƙatun Biya da Kwatance. Kunna/Kashe damar ga ƙasashe na musamman.",
  "Search Countries ": "Bincika Ƙasashe",
  "Filter": "Tace",
  "Payment Requests": "Buƙatun Biya",
  "#No": "#Lamba",
  "Country": "Ƙasa",
  "Account’s Country": "a",
  "Current Location": "Matsayin Yanzu",
  "Save Changes": "Ajiye Canje-canje",
  "Asia": "Asiya",
  "Europe": "Turai",
  "Africa": "Afirka",
  "Oceania": "Oceania",
  "Americas": "Amurka",
  "Polar": "Polar",
  "Finger Biometric": "Biometric yatsa",
  "Confirm": "Tabbatar",
  "Resend available in 77 seconds": "Sake aikawa yana samuwa cikin daƙiƙa 77",
  "Invalid OTP!": "OTP mara inganci!",
  "Finger Biometric Updated": "An sabunta biometric yatsa",
  "Your finger biometric has been disabled successfully!": "An kashe biometric yatsanku cikin nasara!",
  "Your finger biometric has been enabled successfully!": "An kunna biometric yatsanku cikin nasara!",
  "Afghanistan": "Afganistan",
  "Armenia": "Armenia",
  "Azerbaijan": "Azarbaijan",
  "Bahrain": "Baharain",
  "Bangladesh": "Bangiladesh",
  "Bhutan": "Bhutan",
  "Brunei": "Brunei",
  "Cambodia": "Kambodiya",
  "China": "Sin",
  "East Timor": "Gabashin Timor",
  "Georgia": "Jojia",
  "Hong Kong S.A.R.": "Hong Kong S.A.R.",
  "India": "Indiya",
  "Indonesia": "Indonesiya",
  "Iran": "Iran",
  "Iraq": "Iraki",
  "Israel": "Isra'ila",
  "Japan": "Japan",
  "Jordan": "Jordan",
  "Kazakhstan": "Kazakistan",
  "Kuwait": "Kuwaiti",
  "Kyrgyzstan": "Kyrgyzstan",
  "Laos": "Laos",
  "Lebanon": "Lebanon",
  "Macau S.A.R.": "Macau S.A.R.",
  "Malaysia": "Malaysia",
  "Maldives": "Maldives",
  "Mongolia": "Mongolia",
  "Myanmar": "Myanmar",
  "Nepal": "Nepal",
  "North Korea": "Koriya Ta Arewa",
  "Oman": "Oman",
  "Palestinian Territory Occupied": "Yankin Palasɗinu da Aka Mamaye",
  "Philippines": "Filipinas",
  "Qatar": "Katar",
  "Saudi Arabia": "Saudiyya",
  "Singapore": "Singapur",
  "South Korea": "Koriya ta Kudu",
  "Sri Lanka": "Sri Lanka",
  "Syria": "Siriya",
  "Taiwan": "Taiwan",
  "Tajikistan": "Tajikistan",
  "Thailand": "Thailand",
  "Turkey": "Turkiyya",
  "Turkmenistan": "Turkumenistan",
  "United Arab Emirates": "Haɗaɗɗiyar Daular Larabawa",
  "Uzbekistan": "Uzbekistan",
  "Vietnam": "Vietnam",
  "Yemen": "Yemen",
  "Åland Islands": "Tsibirai Åland",
  "Albania": "Albaniya",
  "Andorra": "Andora",
  "Austria": "Ostiriya",
  "Belarus": "Belarus",
  "Belgium": "Belgiyam",
  "Bosnia and Herzegovina": "Bosniya da Herzegovina",
  "Bulgaria": "Bulgariya",
  "Croatia": "Kuroshiya",
  "Cyprus": "Sifurus",
  "Czech Republic": "Jamhuriyar Cak",
  "Denmark": "Danmark",
  "Estonia": "Estoniya",
  "Faroe Islands": "Tsibirin Faroe",
  "Finland": "Finland",
  "France": "Faransa",
  "Germany": "Jamus",
  "Gibraltar": "Gibraltar",
  "Greece": "Girka",
  "Guernsey and Alderney": "Guernsey da Alderney",
  "Hungary": "Hungari",
  "Iceland": "Islanti",
  "Ireland": "Ireland",
  "Italy": "Italiya",
  "Jersey": "Jersey",
  "Kosovo": "Kosovo",
  "Latvia": "Latbiya",
  "Liechtenstein": "Liechtenstein",
  "Lithuania": "Lituweniya",
  "Luxembourg": "Luxembourg",
  "Malta": "Malta",
  "Isle of Man": "Tsibirin Man",
  "Moldova": "Moldova",
  "Monaco": "Monako",
  "Montenegro": "Montenegro",
  "Netherlands": "Netherlands",
  "North Macedonia": "Masedoniya ta Arewa",
  "Norway": "Norway",
  "Poland": "Poland",
  "Portugal": "Portugal",
  "Romania": "Romaniya",
  "Russia": "Rasha",
  "San Marino": "San Marino",
  "Serbia": "Sabiya",
  "Slovakia": "Sulobakiya",
  "Slovenia": "Sulobeniya",
  "Spain": "Sipaniya",
  "Svalbard and Jan Mayen Islands": "Tsibirai Svalbard da Jan Mayen",
  "Sweden": "Suwedan",
  "Switzerland": "Suwizalan",
  "Ukraine": "Yukuren",
  "United Kingdom": "Birtaniya",
  "Vatican City": "Birnin Vatican",
  "Algeria": "Aljeriya",
  "Angola": "Angola",
  "Benin": "Benin",
  "Botswana": "Botswana",
  "British Indian Ocean Territory": "Yankin Tekun Indiya na Birtaniya",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cameroon": "Kamaru",
  "Cape Verde": "Tsibiran Cape Verde",
  "Central African Republic": "Jamhuriyar Afirka Ta Tsakiya",
  "Chad": "Chadi",
  "Comoros": "Kwamoras",
  "Congo": "Kongo",
  "Côte d'Ivoire (Ivory Coast)": "Gabar Ivor",
  "Democratic Republic of the Congo": "Jamhuriyar Dimokradiyyar Kongo",
  "Djibouti": "Jibuti",
  "Egypt": "Masar",
  "Equatorial Guinea": "Guinea Ta Ikwaita",
  "Eritrea": "Eritireya",
  "Ethiopia": "Habasha",
  "French Southern Territories": "Yankunan Kudancin Faransa",
  "Gabon": "Gabon",
  "Gambia": "Gambiya",
  "Ghana": "Ghana",
  "Guinea": "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  "Kenya": "Kenya",
  "Lesotho": "Lesoto",
  "Liberia": "Laberiya",
  "Libya": "Libiya",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Mali": "Mali",
  "Mauritania": "Moritaniya",
  "Mauritius": "Moritus",
  "Mayotte": "Mayotte",
  "Morocco": "Maroko",
  "Mozambique": "Mozambik",
  "Namibia": "Namibiya",
  "Niger": "Nijar",
  "Nigeria": "Nijeriya",
  "Réunion": "Réunion",
  "Rwanda": "Ruwanda",
  "Saint Helena": "Tsibirin Saint Helena",
  "São Tomé and Príncipe": "São Tomé da Príncipe",
  "Senegal": "Senegal",
  "Seychelles": "Seychelles",
  "Sierra Leone": "Saliyo",
  "Somalia": "Somaliya",
  "South Africa": "Afirka ta Kudu",
  "South Sudan": "Kudancin Sudan",
  "Sudan": "Sudan",
  "Swaziland (Eswatini)": "Eswatini",
  "Tanzania": "Tanzaniya",
  "Togo": "Togo",
  "Tunisia": "Tunisiya",
  "Uganda": "Yuganda",
  "Western Sahara": "Saharar Yamma",
  "Zambia": "Zambiya",
  "Zimbabwe": "Zimbabwe",
  "American Samoa": "Samowa ta Amurka",
  "Australia": "Ostareliya",
  "Christmas Island": "Tsibirin Kirsimeti",
  "Cocos (Keeling) Islands": "Tsibirin Cocos (Keeling)",
  "Cook Islands": "Tsibiran Cook",
  "Fiji": "Fiji",
  "French Polynesia": "Polynesia ta Faransa",
  "Guam": "Guam",
  "Kiribati": "Kiribati",
  "Marshall Islands": "Tsibiran Marshall",
  "Micronesia": "Mikuronesiya",
  "Nauru": "Nauru",
  "New Caledonia": "Kaledoniya Sabo",
  "New Zealand": "Sabuwar Zealand",
  "Niue": "Niue",
  "Norfolk Island": "Tsibirin Norfolk",
  "Northern Mariana Islands": "Tsibiran Mariana na Arewa",
  "Palau": "Palau",
  "Papua New Guinea": "Papua New Guinea",
  "Pitcairn Islands": "Tsibiran Pitcairn",
  "Samoa": "Samoa",
  "Solomon Islands": "Tsibiran Solomon",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Tuvalu": "Tuvalu",
  "Vanuatu": "Vanuatu",
  "Wallis and Futuna": "Wallis da Futuna",
  "North & South America": "Arewacin & Kudancin Amurka",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua da Barbuda",
  "Argentina": "Argentina",
  "Aruba": "Aruba",
  "Barbados": "Barbados",
  "Belize": "Belize",
  "Bermuda": "Bermuda",
  "Bolivia": "Bolivia",
  "Bonaire, Sint Eustatius, and Saba": "Bonaire, Sint Eustatius, da Saba",
  "Brazil": "Brazil",
  "Canada": "Kanada",
  "Cayman Islands": "Tsibiran Cayman",
  "Chile": "Chile",
  "Colombia": "Kolombiya",
  "Costa Rica": "Costa Rica",
  "Cuba": "Kuba",
  "Curaçao": "Curaçao",
  "Dominica": "Dominika",
  "Dominican Republic": "Jamhuriyar Dominica",
  "Ecuador": "Ekwado",
  "El Salvador": "El Salvador",
  "Falkland Islands": "Tsibiran Falkland",
  "French Guiana": "Guyana ta Faransa",
  "Greenland": "Greenland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guatemala": "Guatemala",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Honduras": "Honduras",
  "Jamaica": "Jamaica",
  "Martinique": "Martinique",
  "Mexico": "Mexico",
  "Montserrat": "Montserrat",
  "Nicaragua": "Nicaragua",
  "Panama": "Panama",
  "Paraguay": "Paraguay",
  "Peru": "Peru",
  "Puerto Rico": "Puerto Rico",
  "Saint Kitts and Nevis": "Saint Kitts da Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint Pierre da Miquelon",
  "Saint Vincent and the Grenadines": "Saint Vincent da Grenadines",
  "Saint Barthélemy": "Saint Barthélemy",
  "Saint Martin (French part)": "Saint Martin (ɓangaren Faransa)",
  "Sint Maarten (Dutch part)": "Sint Maarten (ɓangaren Dutch)",
  "South Georgia and the South Sandwich Islands": "Kudancin Georgia da Kudancin Sandwich Islands",
  "Suriname": "Suriname",
  "The Bahamas": "Bahamas",
  "Trinidad and Tobago": "Trinidad da Tobago",
  "Turks and Caicos Islands": "Tsibirin Turks da Caicos",
  "United States": "Amurka",
  "United States Minor Outlying Islands": "Kananan tsibirai masu nisa na Amurka",
  "Uruguay": "Uruguay",
  "Venezuela": "Venezuela",
  "British Virgin Islands": "Tsibiran Virgin na Birtaniya",
  "U.S. Virgin Islands": "Tsibiran Virgin na Amurka",
  "Antarctica": "Antarctic",
  "Your Preferences Updated Successfully": "Zaɓuɓɓukanku sun sabunta cikin nasara"
}