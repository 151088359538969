export const pt = {
    "How Others Can Find You": "Como os outros podem encontrar você",
    "Schedules": "Horários",
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Comece Sua Jornada com InstaPay Hoje!",
    "Phone Number or Email": "Número de Telefone ou Email",
    "Login Using Social Media": "Login Usando Redes Sociais",
    "Continue": "Continuar",
    "Don't Have an Account? Sign Up": "Não tem uma conta? Cadastre-se",
    "Sign Up": "Cadastre-se",
    "Edit": "Editar",
    "Enter Your Password": "Digite Sua Senha",
    "Forgot Password?": "Esqueceu a Senha?",
    "Account Type": "Tipo de Conta",
    "Individual": "Individual",
    "Business": "Empresarial",
    "Already Have an Account? Sign In": "Já Tem uma Conta? Entrar",
    "Sing In": "Entrar",
    "Enter Your Personal Details": "Digite Seus Dados Pessoais",
    "Add Photo or Logo": "Adicionar Foto ou Logotipo",
    "Enter Your Name": "Digite Seu Nome",
    "Back": "Voltar",
    "Phone Number": "Número de Telefone",
    "Email Address": "Endereço de Email",
    "Create a Strong Password": "Crie uma Senha Forte",
    "Password Requirements": "Requisitos de Senha",
    "Must be between 9 and 64 characters": "Deve ter entre 9 e 64 caracteres",
    "Include at least two of the following:": "Incluir pelo menos dois dos seguintes:",
    "Uppercase character": "Caractere maiúsculo",
    "Lowercase character": "Caractere minúsculo",
    "Number": "Número",
    "Special character": "Caractere especial",
    "Confirm Password": "Confirmar Senha",
    "Finalize Your Process": "Finalize Seu Processo",
    "Referral Code (Optional)": "Código de Referência (Opcional)",
    "It will autofill if you're signing up with an invitation link.": "Preencherá automaticamente se você está se cadastrando com um link de convite.",
    "I agree with": "Eu concordo com",
    "Terms & Conditions": "Termos & Condições",
    "Privacy Policy": "Política de Privacidade",
    "Create Your Account": "Criar Sua Conta",
    "Enter Code": "Digite o Código",
    "A one-time verification code has been sent to your email address  and phone number.": "Foi enviado um código de verificação único para o seu endereço de correio eletrónico  endereço e número de telefone.",
    "Resend available in ": "Reenvio disponível em",
    "seconds": "segundos",
    "Verify": "Verificar",
    "Verification Code Verified": "Código de Verificação Verificado",
    "Please continue to the next step.": "Por favor, continue para a próxima etapa.",
    "Finish": "Finalizar",
    "Finishing": "Finalizando",
    "Company Name": "Nome da Empresa",
    "Enter Your Business Details": "Digite os Detalhes da Sua Empresa",
    "Country ": "País",
    "You cannot change the country once your account is verified.": "Você não pode mudar o país uma vez que sua conta esteja verificada.",
    "Learn more": "Saiba mais",
    "Optional": "Opcional",
    "Continuing": "Continuando",
    "Didn't receive the verification code?": "Não recebeu o código de verificação?",
    "Resend Now": "Reenviar Agora",
    "Search Here": "Pesquisar Aqui",
    "Search for users, services, and more on InstaPay": "Pesquise por usuários, serviços e mais no InstaPay",
    "Search users here": "Pesquisar usuários aqui",
    "Earn money with our affiliate program!": "Ganhe dinheiro com nosso programa de afiliados!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Gere renda passiva compartilhando seu código de afiliado InstaPay com seus seguidores. Para cada \ntransação que eles realizarem, você ganha uma comissão, maximizando assim os lucros da sua influência online.",
    "Learn More": "Saiba Mais",
    "Balance Amount": "Quantia do Saldo",
    "You spent less compared to last month.": "Você gastou menos em comparação com o último mês.",
    "Referral Rewards": "Recompensas de Referência",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Transferência Rápida para Seus Contatos Mais Recentes:",
    "Number of Transactions": "Número de Transações",
    "Total Transaction Amount": "Quantia Total da Transação",
    "Total Sent": "Total Enviado",
    "Total Received": "Total Recebido",
    "Add Funds": "Adicionar Fundos",
    "Send Money": "Enviar Dinheiro",
    "Request Money": "Solicitar Dinheiro",
    "Send a Quote": "Enviar uma Cotação",
    "Pay With Crypto": "Pagar com Cripto",
    "Payment Insights": "Insights de Pagamento",
    "Recent Transactions ": "Transações Recentes",
    "Today": "Hoje",
    "My Wallets": "Minhas Wallets",
    "Balance": "Saldo",
    "Wallet ID": "ID da Wallet",
    "Monthly limit": "Limite Mensal",
    "Top Up": "Recarregar",
    "Summary": "Resumo",
    "Settings": "Configurações",
    "More": "Mais",
    "Upgrade to Business Account": "Atualizar para Conta Empresarial",
    "Conversion": "Conversão",
    "Enter Amount": "Digite a Quantia",
    "Exchanged Amount": "Quantia Convertida",
    "Convert": "Converter",
    "You Are Converting": "Você Está Convertendo",
    "From": "De",
    "To": "Para",
    "Exchange Rate": "Taxa de Câmbio",
    "Fees": "Taxas",
    "You Will Receive": "Você Receberá",
    "Slide To Confirm": "Deslizar para Confirmar",
    "Verification Code Confirmed - You'll be redirected to the next step": "Código de Verificação Confirmado - Você será redirecionado para a próxima etapa",
    "Moving to next step in": "Indo para a próxima etapa em",
    "Thanks for using InstaPay": "Obrigado por usar InstaPay",
    "Your funds have been credited to your wallet.": "Seus fundos foram creditados na sua wallet.",
    "Dashboard": "Painel de Controle",
    "Accounts": "Contas",
    "Transactions": "Transações",
    "Other Transactions": "Outras Transações",
    "Payments": "Pagamentos",
    "Beneficiaries": "Beneficiários",
    "Referrals": "Referências",
    "Quotations": "Cotações",
    "Pending": "Pendente",
    "My QR Code Sticker": "Meu Adesivo de Código QR",
    "My Portfolio": "Meu Portfólio",
    "My Payment Address": "Meu Endereço de Pagamento",
    "Analytics": "Análises",
    "Profile": "Perfil",
    "Dark Mode": "Modo Escuro",
    "Support": "Suporte",
    "Logout": "Sair",
    "Powered By": "Desenvolvido Por",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Uma Empresa Suíça de Fintech",
    "MAIN MENU": "MENU PRINCIPAL",
    "OTHERS": "OUTROS",
    "Wallet": "Wallet",
    "Default": "Padrão",
    "Breakdown": "Detalhamento",
    "Credit": "Crédito",
    "Debit": "Débito",
    "Wallet Management": "Gerenciamento da Wallet",
    "Top-up Your Wallet": "Recarregar Sua Wallet",
    "Download Statement": "Baixar Extrato",
    "Block the Wallet": "Bloquear a Wallet",
    "Wallet Status": "Status da Wallet",
    "-Select-File-Type-": "-Selecionar-Tipo-de-Arquivo-",
    "Download Now": "Baixar Agora",
    "Downloading": "Baixando",
    "All": "Todos",
    "Sent": "Enviado",
    "Received": "Recebido",
    "Requested": "Solicitado",
    "Quoted": "Cotado",
    "Search Transactions": "Pesquisar Transações",
    "Date & Time": "Data & Hora",
    "Type": "Tipo",
    "Transaction ID": "ID da Transação",
    "Recipient": "Destinatário",
    "Amount": "Quantia",
    "Status": "Status",
    "Payment Type": "Tipo de Pagamento",
    "Sent Payments": "Pagamentos Enviados",
    "Received Payments": "Pagamentos Recebidos",
    "Date": "Data",
    "Cycles/Time": "Ciclos/Tempo",
    "Payment As": "Pagamento Como",
    "Name": "Nome",
    "Cancel": "Cancelar",
    "Subscriptions & Scheduled": "Assinaturas & Agendados",
    "Select the option that matches your needs from the list below:": "Selecione a opção que corresponde às suas necessidades da lista abaixo:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Envie dinheiro facilmente para amigos, familiares ou empresas local ou internacionalmente.\nEscolha entre várias opções de transferência, incluindo depósitos bancários, mobile wallets,\nretirada em dinheiro, cartões de pagamento e transferências de Wallet para Wallet. Desfrute da conveniência\ne segurança do InstaPay para transferências de dinheiro instantâneas e sem emendas.\n",
    "International Transfer": "Transferência Internacional",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Envie dinheiro além-fronteiras com facilidade. Transfira fundos internacionalmente\npara amigos, familiares ou empresas em apenas alguns cliques. Escolha entre\numa ampla gama de canais de pagamento, incluindo conta bancária,\ndinheiro móvel/mobile wallet, local de retirada de dinheiro ou cartão de pagamento\npara transações convenientes e seguras.",
    "Select the country where you want to send the money!": "Selecione o país para onde você deseja enviar o dinheiro!",
    "Search Country": "Pesquisar País",
    "Trouble Sending Money?": "Problemas para Enviar Dinheiro?",
    "Confirm": "Confirmar",
    "Select Payout Channel": "Selecione o Canal de Pagamento",
    "Select your most convenient option to send the money.": "Selecione sua opção mais conveniente para enviar o dinheiro.",
    "Select The Bank": "Selecione o Banco",
    "Search Bank": "Pesquisar Banco",
    "Send money directly to a recipient's bank account. Enjoy the convenience \nand security of transferring funds seamlessly to any bank around the world.": "Envie dinheiro diretamente para a conta bancária do destinatário. Desfrute da conveniência e segurança de \ntransferir fundos sem problemas para qualquer banco ao redor do mundo.\n",
    "Bank Transfer": "Transferência Bancária",
    "Enter the amount": "Digite o valor",
    "Tax": "Imposto",
    "Amount the recipient will receive": "Valor que o destinatário receberá",
    "Select Remarks / Reason": "Selecionar Observações / Motivo",
    "Comments": "Comentários",
    "Attach Files": "Anexar Arquivos",
    "Groups": "Grupos",
    "Manage Groups": "Gerenciar Grupos",
    "Add New Group": "Adicionar Novo Grupo",
    "Invite": "Convidar uma Pessoa",
    "Manage Beneficiaries": "Gerenciar Beneficiários",
    "Recipients List": "Lista de Destinatários",
    "Search Contacts": "Pesquisar Contatos",
    "Confirm Your Payment": "Confirmar Seu Pagamento",
    "Select Country": "Selecionar País",
    "Select the Recipient's Payment Channel": "Selecionar o Canal de Pagamento do Destinatário",
    "Change Selection": "Alterar Seleção",
    "You Are Transferring ": "Você Está Transferindo",
    "Oops! Something Went Wrong.": "Ops! Algo Deu Errado.",
    "We're Sorry About That. ": "Lamentamos Por Isso.",
    "Go back to Payments Page.": "Voltar para a Página de Pagamentos.",
    "Wallet to Wallet Transfer": "Transferência de Wallet para Wallet",
    "Request a customized price quote from service providers or vendors.": "Solicite um orçamento personalizado de prestadores de serviços ou fornecedores.",
    "Enter the Wallet ID": "Digite o ID da Wallet",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Digite o ID da Wallet, Email ou Número de Telefone do destinatário.",
    "Recipient not on InstaPay?": "O Destinatário Não Está no InstaPay? Convide-o.",
    "Invite Them": "Convide-o",
    "My Beneficiaries": "Meus Beneficiários",
    "Enter the Amount You Wish to Transfer.": "Digite o Valor que Deseja Transferir.",
    "Sending to ": "Enviando para",
    "You Have Selected Wallet to Wallet Transfer": "Você Selecionou Transferência de Wallet para Wallet",
    "Authenticate Your Payment.": "Autentique Seu Pagamento.",
    "You are transferring": "Você está transferindo",
    "Total ": "Total",
    "Where Is My Payment?": "Onde Está Meu Pagamento?",
    "Share": "Compartilhar",
    "Your Payment Is Confirmed": "Seu Pagamento Está Confirmado",
    "QR Code Payment": "Pagamento com Código QR",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Transfira dinheiro instantaneamente para outros usuários do InstaPay com nosso serviço seguro de Wallet para Wallet.\nEnvie e receba fundos sem problemas em segundos.",
    "Scan QR with Your Camera or Upload from Your Device.": "Digitalize o QR com sua Câmera ou Faça o Upload a partir do seu Dispositivo.",
    "Upload from Your Device.": "Faça o Upload a partir do seu Dispositivo.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Alternativamente, Use o Endereço de Pagamento Único do InstaPay (UPA)",
    "Enter InstaPay UPA": "Digite o InstaPay UPA",
    "Recipient not on InstaPay? Invite Them": "Destinatário não está no InstaPay? Convide-o",
    "Verify QR Code": "Verificar Código QR",
    "Scan/Upload QR Code of the Recipient": "Digitalizar/Upload do Código QR do Destinatário",
    "Payment Confirmed": "Pagamento Confirmado",
    "Request customized price quotes from service providers or vendors to receive \naccurate cost estimates for the services or products you need.": "Solicite orçamentos personalizados de prestadores de serviços ou fornecedores para receber\nestimativas de custos precisas para os serviços ou produtos que você precisa",
    "Choose The Beneficiary or Group": "Escolha o Beneficiário ou Grupo",
    "Enter the Amount You Wish to Request.": "Digite o Valor que Deseja Solicitar.",
    "You Are Requesting Money": "Você Está Solicitando Dinheiro",
    "You are requesting": "Você está solicitando",
    "As": "Como",
    "Conversion of Your Cryptocurrencies": "Conversão de Suas Criptomoedas",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Experimente nossa conversão segura, eficiente e centrada no usuário de Cripto para Fiat.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Escolha a Criptomoeda que Pretende Liquidar.",
    "Next": "Próximo",
    "Enter the Crypto Wallet Address of the Recipient.": "Digite o Endereço da Wallet de Cripto do Destinatário.",
    "Minimum Transactional Value: $100.": "Valor Mínimo de Transação: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Nota: Os preços das criptos mudam frequentemente. Consulte a cotação final na página de confirmação do pedido.",
    "Select Network": "Selecionar Rede",
    "Send A Quote": "Enviar um Orçamento",
    "Send a detailed price quote to your clients or customers. Present your services, products, \nor offers professionally with a personalized quotation.": "Envie um orçamento detalhado para seus clientes ou consumidores. Apresente seus serviços, produtos,\nou ofertas profissionalmente com um orçamento personalizado.",
    "Choose the Beneficiary": "Escolha o Beneficiário",
    "Allow Receiver to Bargain": "Permitir que o Receptor Negocie",
    "Enter the Title for Your Quotation.": "Digite o Título do Seu Orçamento.",
    "Enter the Description for Your Quotation": "Digite a Descrição do Seu Orçamento",
    "You are Quoting": "Você Está Cotando",
    "Title": "Título",
    "Description": "Descrição",
    "Attachments": "Anexos",
    "Confirm Your Quotation": "Confirmar Seu Orçamento",
    "Verify The Quotation": "Verificar o Orçamento",
    "Your quotation is confirmed": "Seu orçamento está confirmado",
    "Request A Quote": "Solicitar uma Cotação",
    "Coming Soon": "Em Breve",
    "Add Beneficiary": "Adicionar Beneficiário",
    "Search for Beneficiaries": "Pesquisar por Beneficiários",
    "Connected Accounts": "Contas Conectadas",
    "Details": "Detalhes",
    "First Name": "Primeiro Nome",
    "Last Name": "Último Nome",
    "Country": "País",
    "City": "Cidade",
    "Address Line": "Linha de Endereço",
    "Mobile Number": "Número de Celular",
    "Relationship With Beneficiary": "Relacionamento com o Beneficiário",
    "Bank Account": "Conta Bancária",
    "Mobile Money/Mobile Wallet": "Dinheiro Móvel/Mobile Wallet",
    "Payment Card": "Cartão de Pagamento",
    "Crypto Wallet": "Wallet de Cripto",
    "InstaPay Account": "Conta InstaPay",
    "Cash Pickup": "Retirada em Dinheiro",
    "IBAN / SWIFT Code": "Código IBAN ou Swift/BIC",
    "Swift Code": "Código Swift",
    "Account Number": "Número da Conta",
    "Bank Name": "Nome do Banco",
    "Branch Name": "Nome da Agência",
    "Branch Street": "Rua da Agência",
    "Province": "Província",
    "Postal Code": "Código Postal",
    "Mobile Money Provider": "Provedor de Dinheiro Móvel",
    "Wallet Name": "Nome da Wallet",
    "Wallet Number": "Número da Wallet",
    "Card Holder Name": "Nome do Titular do Cartão",
    "Card Number": "Número do Cartão",
    "Expiry Date": "Data de Validade",
    "Crypto Currency": "Criptomoeda",
    "Wallet Address": "Endereço da Wallet",
    "Wallet Holder Name": "Nome do Titular da Wallet",
    "Wallet Currency": "Moeda da Wallet",
    "Select Document Type": "Selecionar Tipo de Documento",
    "Enter Document Number": "Digite o Número do Documento",
    "Add Individual Account": "Adicionar Conta Individual",
    "Add Business Account": "Adicionar Conta Empresarial",
    "Company Address": "Endereço da Empresa",
    "Company Email": "Email da Empresa",
    "Company Phone No": "Telefone da Empresa",
    "Total Referrals": "Total de Referências",
    "My Earnings": "Meus Ganhos",
    "Your Referral ID": "Seu ID de Referência",
    "Share Your Unique Referral Link": "Compartilhe Seu Link Único de Referência do InstaPay",
    "How do I Refer a Friend to InstaPay?": "Como Indicar um Amigo para o InstaPay?",
    "Step 01": "Etapa 01",
    "Share your InstaPay unique referral link with your friends.": "Compartilhe seu link único de referência do InstaPay com seus amigos.",
    "Step 02": "Etapa 02",
    "Earn rewards when they transact $100 or more": "Ganhe recompensas quando eles realizarem transações de $100 ou mais",
    "Step 03": "Etapa 03",
    "Earn up to $0.06 for every transaction your followers make.": "Ganhe até $0.06 por cada transação que seus seguidores realizarem.",
    "My Referrals": "Minhas Referências",
    "Referral Complete": "Referência Completa",
    "Received Quotations": "Cotações Recebidas",
    "Sent Quotations": "Cotações Enviadas",
    "Search Pending Items": "Pesquisar Itens Pendentes",
    "Actions": "Ações",
    "Received Requests": "Solicitações Recebidas",
    "Sent Requests": "Solicitações Enviadas",
    "No Actions": "Sem Ações",
    "You Requested": "Você Solicitou",
    "Via": "Via",
    "How was your experience?": "Como foi sua experiência?",
    "Leave a Review": "Deixe uma Avaliação",
    "Overall Ratings": "Avaliações Gerais",
    "Visit Profile": "Visitar Perfil",
    "Ratings": "Avaliações",
    "Reviews": "Resenhas",
    "No reviews available": "Sem resenhas disponíveis",
    "Select Your Currency": "Selecione Sua Moeda",
    "Proceed": "Prosseguir",
    "Commision": "Comissão",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Desbloqueie Transações Sem Esforço com o Adesivo de Código QR do InstaPay",
    "Effortless and Swift:": "Rápido e Fácil:",
    "Instantly download your unique InstaPay QR code sticker.": "Baixe instantaneamente seu adesivo único de código QR do InstaPay.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Experimente pagamentos sem dinheiro e sem problemas sem precisar de infraestrutura complexa como\nterminais de pagamento.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Colete pagamentos rapidamente – sem mais entrada tediosa de números de cartão de crédito ou telefone.",
    "Each payment includes customer information for your convenience.": "Cada pagamento inclui informações do cliente para sua conveniência.",
    "Economically Smart:": "Economicamente Inteligente:",
    "Zero activation fees – start immediately without upfront costs.": "Zero taxas de ativação – comece imediatamente sem custos iniciais.",
    "Forget about fixed monthly charges; pay as you transact.": "Esqueça sobre cobranças mensais fixas; pague conforme você transaciona.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Dispense o hardware; sem custos de terminal significa mais economia para você.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Desfrute de taxas de transação baixas de apenas 0,75% por pagamento com código QR.",
    "We don't impose minimum commissions; you save more with each transaction.": "Não impomos comissões mínimas; você economiza mais a cada transação.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Experimente a alegria dos pagamentos instantâneos, melhorando seu fluxo de caixa.",
    "Make Every Transaction Count with InstaPay": "Faça Cada Transação Contar com InstaPay",
    "Wallet QR": "Wallet QR",
    "Download QR": "Baixar QR",
    "QR Code Status": "Status do Código QR",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Compartilhe seu Código QR ou link de endereço de pagamento para receber pagamentos instantaneamente na sua Wallet do InstaPay.",
    "QR Code Title": "Título do Código QR",
    "View Transactions": "Ver Transações",
    "My QR Codes:": "Meus Códigos QR:",
    "Load More": "Carregar Mais",
    "Benefits Of Portfolio": "Benefícios do Portfólio",
    "Benefits:": "Benefícios:",
    "Enhanced Visibility:": "Visibilidade Aprimorada:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible \ndirectly from your Payment Address.": "Eleve seu perfil público. Seu portfólio atua como uma vitrine dinâmica, acessível\ndiretamente do seu Endereço de Pagamento.",
    "Engage Your Audience:": "Envolver Seu Público:",
    "Connect with visitors through your creative or professional journey. Let your work \nspeak for you, creating a deeper engagement with potential clients or supporters.": "Conecte-se com visitantes através de sua jornada criativa ou profissional. Deixe seu trabalho falar por você, criando um envolvimento \nmais profundo com potenciais clientes ou apoiadores.",
    "Integrated Social Proof:": "Prova Social Integrada:\n",
    "Display your social media influence. Visitors can see your social media reach, \nadding credibility and context to your portfolio.": "Exiba sua influência nas redes sociais. Os visitantes podem ver seu alcance nas redes sociais, adicionando credibilidade e contexto ao \nseu portfólio.",
    "Seamless Transactions:": "Transações Sem Esforço:\n",
    "Enable visitors to request or initiate payments right from your public page. Your \nportfolio not only showcases your work but also facilitates easy financial interactions.": "Permita que os visitantes solicitem ou iniciem pagamentos diretamente da sua página pública. Seu\nportfólio não apenas mostra seu trabalho, mas também facilita interações financeiras fáceis.",
    "Personalized Storytelling:": "Narrativa Personalizada:",
    "Use the 'About Me' section to share your story, mission, or vision, creating a\n personal connection with your audience.": "Use a seção 'Sobre Mim' para compartilhar sua história, missão ou visão, criando uma conexão pessoal com seu público.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your \naudience, and streamline your financial interactions on InstaPay.": "Meu Portfólio' é mais do que apenas uma galeria; é uma ferramenta para construir sua marca, engajar seu público e simplificar suas \ninterações financeiras no InstaPay.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Mostre Seu Trabalho, Amplie Seu Alcance. 'Meu Portfólio' é sua tela digital no InstaPay. Faça o upload e exiba uma variedade de \nconteúdos – desde imagens cativantes e vídeos a PDFs informativos. Se você é um artista, freelancer ou empreendedor, esta \nfuncionalidade permite apresentar seu trabalho ou projetos de uma maneira visualmente atraente.\"\n",
    "Add/Manage Portfolio": "Adicionar/Gerenciar Portfólio",
    "Add New": "Adicionar Novo",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maximize Seus Ganhos nas Redes Sociais com Seu Endereço de Pagamento do InstaPay",
    "Make It Effortless for Your Audience to Support You:": "Torne Fácil para o Seu Público Apoiar Você:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Integre facilmente seu Endereço de Pagamento do InstaPay em suas postagens nas redes sociais.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Copie e cole facilmente o link do seu endereço de pagamento em cada uma de suas postagens, histórias ou de forma proeminente\nno seu perfil.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideal para influenciadores, artistas, empreendedores ou qualquer pessoa com uma comunidade ansiosa para mostrar seu apoio.",
    "Instant Support, Endless Possibilities:": "Suporte Instantâneo, Possibilidades Infinitas:",
    "Transform the way you monetize your social media content.": "Transforme a maneira como você monetiza seu conteúdo nas redes sociais.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Permita que seus seguidores lhe enviem fundos instantaneamente com apenas um clique, diretamente de suas redes sociais favoritas.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideal para suporte em tempo real durante transmissões ao vivo, como um sistema de gorjetas para seu conteúdo criativo ou para \nfinanciar seu próximo grande projeto.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Onde Colocar Seu Endereço de Pagamento do InstaPay para Máximo Impacto:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Perfis de Redes Sociais: Fixe-o na sua bio ou seção sobre para visibilidade constante.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Postagens & Anúncios: Incorpore-o em suas postagens individuais, especialmente aquelas que destacam seu trabalho ou chamadas específicas\npara apoio.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Histórias & Reels: Integre-o em suas histórias interativas ou reels cativantes para capturar contribuições instantâneas.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Transmissões ao Vivo: Compartilhe-o durante suas sessões AO VIVO, facilitando para os espectadores contribuírem em tempo real.",
    "Simple, Fast, and Secure:": "Simples, Rápido e Seguro:",
    "No complex setup – get started in moments.": "Sem configuração complexa – comece em momentos.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Aproveite a segurança e a velocidade do processamento de pagamentos confiável do InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Receba pagamentos de qualquer pessoa, em qualquer lugar, sem a necessidade de uma conta no InstaPay.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Eleve Seu Jogo nas Redes Sociais com o Endereço de Pagamento do InstaPay",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Endereço de Pagamento Virtual)",
    "Payment Address Title": "Título do Endereço de Pagamento",
    "Currency": "Moeda",
    "Payment Address Description": "Descrição do Endereço de Pagamento",
    "Update": "Atualizar",
    "Updating": "Atualizando",
    "Total Transaction": "Total de Transações",
    "Last 30 days": "Últimos 30 dias",
    "Last 1 month": "Último 1 mês",
    "Last 1 year": "Último 1 ano",
    "Custom Date": "Data Personalizada",
    "Payment Methods": "Métodos de Pagamento",
    "Payment Types": "Tipos de Pagamento",
    "Requested Amount": "Quantia Solicitada",
    "Quoted Amount": "Quantia Cotada",
    "About Me": "Sobre Mim",
    "Followers": "Seguidores",
    "InstaPay ID": "ID do InstaPay",
    "Download QR Code": "Baixar Código QR",
    "Initiate A Payment": "Iniciar um Pagamento",
    "Report this person": "Denunciar esta pessoa",
    "Add now": "Adicionar agora",
    "Recent Reviews": "Avaliações Recentes",
    "Reviews As Seller": "Avaliações Como Vendedor",
    "Reviews As Buyer": "Avaliações Como Comprador",
    "Select Language": "Selecionar Idioma",
    "Profile Completion": "Conclusão do Perfil",
    "Profile Completed": "Perfil Completo",
    "Basic Info": "Informações Básicas",
    "Username": "Nome de Usuário",
    "Gender": "Gênero",
    "Male": "Masculino",
    "Female": "Feminino",
    "-Select-Gender-": "-Selecionar-Gênero-",
    "Date Of Birth": "Data de Nascimento",
    "Address": "Endereço",
    "Edit Profile": "Editar Perfil",
    "Update Profile": "Atualizar Perfil",
    "Updating Profile": "Atualizando Perfil",
    "Complete Now": "Completar Agora",
    "Password": "Senha",
    "Change Password": "Mudar Senha",
    "New Password": "Nova Senha",
    "Re-enter New Password": "Reinserir Nova Senha",
    "Please follow this guide for a strong password": "Siga este guia para uma senha forte",
    "Include at least one special character.": "Incluir pelo menos um caractere especial.",
    "Minimum of 8 characters.": "Mínimo de 8 caracteres.",
    "Include at least one number": "Incluir pelo menos um número",
    "Change it often for enhanced security.": "Mude-a frequentemente para maior segurança.",
    "Security Questions": "Perguntas de Segurança",
    "Security Question": "Pergunta de Segurança",
    "Select your question": "Selecione sua pergunta",
    "Answer To The Question": "Resposta Para a Pergunta",
    "Nominee": "Nomeado",
    "Relationship with Nominee": "Relação com o Nomeado",
    "Nominee Contact Number": "Número de Contato do Nomeado",
    "Nominee Address": "Endereço do Nomeado",
    "Delete Nominee": "Excluir Nomeado",
    "Update Nominee": "Atualizar Nomeado",
    "Details Updated Successfully!": "Detalhes Atualizados com Sucesso!",
    "Success": "Sucesso",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay \nplatform itself. You acknowledge that no wet signature is required to appoint the nominee, and \nthe online registration will be considered final and binding. In the event of the death of an \nInstaPay Wallet holder, the balance in the Wallet will be transferred to the registered Nominee. \nKEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to \nthe Nominee. The Nominee will provide any documents required by InstaPay, including those \nfor identification and proof of death.": "Você pode eleger um Nomeado de sua escolha, desde que não seja menor de idade, através da plataforma do InstaPay. Você reconhece \nque não é necessária uma assinatura física para nomear o nomeado, e o registro online será considerado final e vinculativo. No caso de \nfalecimento de um titular de Wallet do InstaPay, o saldo na Wallet será transferido para o Nomeado registrado. A KEMIT KINGDOM será \ndesonerada de todas as suas responsabilidades após a entrega do valor ao Nomeado. O Nomeado fornecerá quaisquer documentos \nexigidos pelo InstaPay, incluindo aqueles para identificação e comprovação de óbito.",
    "Add Nominee": "Adicionar Nomeado",
    "Two Factor Authentication": "Autenticação de Dois Fatores",
    "SMS Number": "Número SMS",
    "Updated Successfully!": "Atualizado com Sucesso!",
    "Social Network Accounts": "Contas de Redes Sociais",
    "Here, you can set up and manage your integration settings.": "Aqui, você pode configurar e gerenciar suas configurações de integração.",
    "Social Network Account": "Conta de Rede Social",
    "Activate Account": "Ativar Conta",
    "Enter the code below in the InstaPay Chatbot.": "Digite o código abaixo no Chatbot do InstaPay.",
    "The code will expire in ": "O código expirará em",
    "Notifications": "Notificações",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Personalize como você recebe notificações. Estas configurações se aplicam às atividades que você está monitorando.",
    "Activity": "Atividade",
    "Email": "Email",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Fonte",
    "Payment Requests": "Pedidos de pagamento",
    "Bulk Payments": "Pagamentos em massa",
    "Identity Verification": "Verificação de Identidade",
    "Verify Your Identity": "Verifique Sua Identidade",
    "Identity Verification Status:": "Status da Verificação de Identidade:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Descreva-se brevemente e explique suas razões para usar o InstaPay.",
    "Enter Your Message": "Digite Sua Mensagem",
    "Select Occupation": "Selecionar Ocupação",
    "It must be a government-issued identification document with a photo. All identification documents \nmust be written in the Latin alphabet. If not, a certified translation is required.": "Deve ser um documento de identificação emitido pelo governo com foto. Todos os documentos de identificação devem ser escritos em \nalfabeto latino. Se não, uma tradução certificada é necessária.",
    "Accepted Documents": "Documentos Aceitos",
    "A national identity card": "Cartão de identidade nacional",
    "A valid passport": "Passaporte válido",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Permissões de residência válidas (aceitas apenas se emitidas por um país europeu ou na Suíça)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Reconhecimento Facial Biométrico: Comparação de vídeo com fotografia do passaporte",
    "Address Verification:": "Verificação de Endereço:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Forneça um extrato bancário ou fatura de serviços públicos (com menos de 3 meses de idade) como prova de endereço",
    "Additional Documents:": "Documentos Adicionais:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) \nor further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom reserva o direito de solicitar quaisquer documentos adicionais (Diligência Devida Aumentada - EDD) ou informações \nadicionais necessárias para realizar verificações completas, de acordo com suas obrigações legais.\"",
    "Start Verification": "Iniciar Verificação",
    "Sessions": "Sessões",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Esta é uma lista de dispositivos que acessaram sua conta. Remova qualquer um que você não reconheça.",
    "Your Withdrawal Options": "Suas Opções de Retirada",
    "Choose your preferred method for withdrawing payments.": "Escolha seu método preferido para retirar pagamentos.",
    "No withdrawal options currently available in your country.": "Atualmente não há opções de retirada disponíveis em seu país.",
    "Download Your Data": "Baixe Seus Dados",
    "Request a Copy of Your Data": "Solicite uma Cópia de Seus Dados",
    "Submit a request to download a copy of your data": "Envie uma solicitação para baixar uma cópia de seus dados",
    "Which Data Should Be Included in the Download?": "Quais Dados Devem Ser Incluídos no Download?",
    "Select all applicable options.": "Selecione todas as opções aplicáveis.",
    "Personal Information": "Informações Pessoais",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Esta informação é usada para proteger sua conta, personalizar sua experiência e contatá-lo conforme necessário.",
    "Name and Date Of Birth": "Nome e data de nascimento",
    "Phone number": "Número de telefone",
    "Financial Information": "Informações Financeiras",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Usamos essas informações para permitir um pagamento mais rápido e para enviar ou receber dinheiro em apenas alguns cliques.",
    "Bank accounts": "Contas bancárias",
    "Debit or credit cards": "Cartões de débito ou crédito",
    "Credit Information": "Informações de Crédito",
    "Includes your credit application information": "Inclui suas informações de solicitação de crédito",
    "Other Information (not included in file)": "Outras Informações (não incluídas no arquivo)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Inclui informações do dispositivo, dados de uso técnico, informações de geolocalização, preferências de marketing, histórico de consentimento,\ne dados usados para outros serviços como crédito, verificação de identidade, comunicações com o PayPal e processadores\nterceirizados.",
    "Choose file type:": "Escolha o tipo de arquivo:",
    "Select file type": "Selecione o tipo de arquivo",
    "PDF file can be easily opened on any computer": "O arquivo PDF pode ser facilmente aberto em qualquer computador",
    "Submit Request": "Enviar Solicitação",
    "Delete Your Account": "Excluir Sua Conta",
    "Ask us to delete your data and close this account": "Peça-nos para excluir seus dados e fechar esta conta",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Usamos seus dados para fornecer serviços, então se excluirmos seus dados, também precisaremos fechar sua conta.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Se sua conta estiver limitada, vá para o Centro de Resolução, selecione 'Ir para Limitações da Conta' e clique em 'Resolver'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Certifique-se de que todos os pagamentos pendentes foram liquidados. Não poderemos fechar sua conta até que isso aconteça.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Se você tiver um saldo negativo, clique no link Resolver ao lado do seu saldo.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Uma vez que sua conta esteja regularizada, e se você for elegível, fecharemos sua conta e excluiremos seus dados. Precisamos\nmanter alguns de seus dados por razões legais e regulatórias. Para saber mais, veja nossa ",
    "privacy statement": "declaração de privacidade",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Enviaremos um e-mail para informá-lo sobre o status e se há outras etapas que você precisa tomar.",
    "Have more questions about data deletion?": "Tem mais perguntas sobre a exclusão de dados?",
    "Contact us": "Contate-nos",
    "I understand that account deletion is permanent and can't be reversed.": "Entendo que a exclusão da conta é permanente e não pode ser revertida.",
    "Are you sure you want to close your account?": "Tem certeza de que deseja fechar sua conta?",
    "Yes, Continue": "Sim, Continuar",
    "Logging In": "Fazendo Login",
    "Complete your profile to streamline your transaction process.": "Complete seu perfil para agilizar seu processo de transação.",
    "Skip": "Pular",
    "Complete Your Profile": "Complete Seu Perfil",
    "API Key": "Chave API",
    "Get API Key": "Obter Chave API",
    "Generating New API Key": "Gerando Nova Chave API",
    "Generate API Key": "Gerar Chave API",
    "Regenerate API Key": "Regenerar Chave API",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. \nFirst, sign up for an account at [your registration URL] and access the Developer Dashboard. Navigate to the \n'API Keys' section, generate a new key, and ensure its secure storage.": "Para aproveitar todo o potencial das transações sem interrupções do InstaPay, siga estes passos rápidos para gerar sua chave API.\nPrimeiro, inscreva-se para uma conta em [seu URL de registro] e acesse o Painel de Desenvolvedor. Navegue até a seção 'Chaves API', \ngere uma nova chave e garanta sua armazenagem segura.",
    "Delete API Key": "Excluir Chave API",
    "Webhook URL": "URL do Webhook",
    "KYB Verification": "Verificação KYB",
    "Additional Files": "Arquivos Adicionais",
    "Business Verification": "Verificação Empresarial",
    "--Select-Your-Company-Type--": "--Selecione-Seu-Tipo-de-Empresa--",
    "Documents Required:": "Documentos Necessários:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Extrato Oficial do Registro da Empresa (Documento de Registro/Incorporação da Entidade)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. \nIt reflects the current status of the business (active or closed).": "Este documento serve como a 'carteira de identidade' oficial da empresa e deve ser obtido no registro comercial local.\nReflete o status atual da empresa (ativo ou fechado).",
    "The document should be current, with an issuance date not exceeding 3 months.": "O documento deve ser atual, com uma data de emissão não superior a 3 meses.",
    "The document must include the following details:": "O documento deve incluir os seguintes detalhes:",
    "Legal name of the company": "Nome legal da empresa",
    "Full registered address": "Endereço completo registrado",
    "Company registration number": "Número de registro da empresa",
    "Date of incorporation": "Data de incorporação",
    "Description of the company's activities/purpose": "Descrição das atividades/propósito da empresa",
    "Legal representative's information: full name, date and place of birth, residential address \n(minimum requirement: country of residence), and nationality": "Informações do representante legal: nome completo, data e local de nascimento, endereço residencial\n(requisito mínimo: país de residência) e nacionalidade",
    "If applicable, the document should also include:": "Se aplicável, o documento também deve incluir:",
    "Brand or trading names": "Nomes de marca ou comerciais",
    "Value Added Tax (VAT) number": "Número do Imposto sobre o Valor Acrescentado (IVA)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, \nit may be provided as a separate document.": "Lista de Proprietários Beneficiários Finais (UBOs) - isso é obrigatório em alguns países, caso contrário,\npode ser fornecido como um documento separado.",
    "Proof of Legal Representative's Authority": "Prova da Autoridade do Representante Legal",
    "This document validates an individual's authority to represent the company": "Este documento valida a autoridade de um indivíduo para representar a empresa",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a \ndocument such as a Power of Attorney or Delegation of Powers must be secured.": "Normalmente, o representante legal é nomeado no extrato de registro da empresa. No entanto, se não for o caso, um\ndocumento como Procuração ou Delegação de Poderes deve ser assegurado.",
    "Power of Attorney/Delegation of Powers:": "Procuração/Delegação de Poderes:\n",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal \nrepresentative in the company's registry extract.": "O indivíduo que confere a autoridade deve estar legitimamente habilitado a fazê-lo. Este indivíduo deve estar listado como representante legal\nno extrato de registro da empresa.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Documentação da Estrutura de Propriedade e Proprietários Beneficiários Finais (UBOs)\n",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the \nstock exchange website displaying the company's details.": "Deve ser fornecida evidência de que a empresa está listada na bolsa de valores relevante. Isso pode ser uma página do\nsite da bolsa de valores exibindo os detalhes da empresa.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of \nthe shares will be subject to Ultimate Beneficial Owner (UBO) verification requirements.": "Se menos de 75% das ações da empresa forem de propriedade pública, todos os acionistas que possuem ou controlam mais de 25% das\nações estarão sujeitos aos requisitos de verificação do Proprietário Beneficiário Final (UBO).",
    "Collection of Identification Documents": "Coleta de Documentos de Identificação",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification \ndocuments must be written in the Latin alphabet. If not, a certified translation is required.": "O documento de identificação deve ser emitido pelo governo, contendo uma fotografia do indivíduo. Todos os documentos de identificação\ndevem estar escritos em alfabeto latino. Se não, é necessária uma tradução certificada.\n",
    "Accepted documents:": "Documentos aceitos:",
    "Valid National Identity Card": "Cartão de Identidade Nacional válido",
    "Valid Passport": "Passaporte válido",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Permissões de Residência válidas - Serão aceitas apenas se emitidas por um país europeu.",
    "Only legal representatives are subject to the identification document collection process.": "Apenas representantes legais estão sujeitos ao processo de coleta de documentos de identificação.",
    "Additional Requirement:": "Requisito Adicional:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their ID.": "Em casos envolvendo Procuração ou Delegação de Poderes, ambas as partes\n(o indivíduo que concede a autoridade e o que a recebe) devem fornecer uma cópia de sua identificação.",
    "Additional Documents": "Documentos Adicionais\n",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department \nthat shows the End-user’s address and name (e.g., Residential Certificate); or": "Conta de Utilidade (como gás, eletricidade, telefone ou conta de celular); ou um documento emitido por um departamento governamental\nque mostra o endereço e nome do Usuário Final (por exemplo, Certificado Residencial); ou",
    "Bank Statement; or": "Extrato Bancário; ou\n",
    "a document issued by a government department that shows the End-user’s address and name \n(e.g., Residential Certificate).": "um documento emitido por um departamento governamental que mostra o endereço e nome do Usuário Final (por exemplo, Certificado Residencial).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting \nthorough verifications, in line with its legal obligations.": "A Kemit Kingdom mantém o direito de solicitar quaisquer documentos adicionais ou informações adicionais necessárias para realizar\nverificações completas, de acordo com suas obrigações legais.",
    "Note:": "Nota:",
    "Partners": "Parceiros",
    "Add New Partner": "Adicionar Novo Parceiro",
    "Designation": "Designação",
    "Partner Name": "Nome do Parceiro",
    "Partner Email": "Email do Parceiro",
    "Partner Designation": "Designação do Parceiro",
    "Add Now": "Adicionar Agora",
    "Adding": "Adicionando",
    "Delete Partner": "Excluir Parceiro",
    "Delete": "Excluir",
    "Deleting": "Excluindo",
    "Are you sure, you want to remove sxz as partner?": "Tem certeza de que deseja remover sxz como parceiro?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Extrato do Registro da Entidade (Documento de Registro/Criação da Entidade)",
    "This document should be either:": "Este documento deve ser:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Uma prova de criação da entidade, fornecendo os detalhes da entidade, data de criação e propósito.",
    "A local registry extract:": "Um extrato do registro local:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status \n(active or closed).": "Requerido conforme as regulamentações do país, servindo como a atual 'carteira de identidade' da entidade, indicando seu status\n(ativa ou fechada).",
    "If a registry extract exists, it must not be older than 3 months.": "Se existir um extrato do registro, este não deve ter mais de 3 meses.",
    "Entity name": "Nome da entidade",
    "Registration number (if applicable, based on the country's requirements)": "Número de registro (se aplicável, com base nas exigências do país)",
    "Date of creation": "Data de criação",
    "Description of the entity's activities or purpose": "Descrição das atividades ou propósito da entidade",
    "Trading names": "Nomes comerciais",
    "Articles of Association": "Estatutos Sociais",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating \nrules.": "Os Estatutos Sociais servem como o documento constitucional da entidade, delineando suas regras de operação.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of \nentities require at least one annual meeting that may result in an updated version of the Articles of Association.": "Não há restrição de tempo neste documento, o que significa que a versão mais recente é solicitada. Tipicamente, este tipo de entidades exige pelo \nmenos uma reunião anual que pode resultar em uma versão atualizada dos Estatutos Sociais.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, \nroles, responsibilities, etc.": "Exemplos de mudanças que podem ocorrer incluem atualizações no nome da entidade, endereço, propósito, conselho de administração, funções, \nresponsabilidades, etc.",
    "The document should contain information about:": "O documento deve conter informações sobre:",
    "The entity itself, including its legal name, registered address, and purpose": "A própria entidade, incluindo seu nome legal, endereço registrado e propósito",
    "Its internal operating rules, such as the appointment process for management board members, their \nroles and powers, among other details. This list is not exhaustive.": "Suas regras operacionais internas, como o processo de nomeação para membros do conselho de administração, seus papéis e poderes, \nentre outros detalhes. Esta lista não é exaustiva.",
    "How the entity is funded": "Como a entidade é financiada",
    "Appointment/Removal/Changes on Board of Management Members:": "Nomeação/Remoção/Mudanças nos Membros do Conselho de Administração:",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are \ndocumented through board of management meeting minutes, which are registered and certified to reflect the updated \nlist of members, such as the president, vice-president, treasurer, and general secretary.": "Essas atualizações geralmente ocorrem com base nas necessidades da entidade ou conforme estipulado nos Estatutos Sociais. As mudanças são\ndocumentadas através das atas das reuniões do conselho de administração, que são registradas e certificadas para refletir a lista atualizada de \nmembros, como o presidente, vice-presidente, tesoureiro e secretário-geral.\n",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the \nArticles of Association, if available.": "As últimas atas da reunião do conselho de administração, incluindo a lista atual de membros, serão necessárias além dos\nEstatutos Sociais, se disponíveis.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there \nare no board meeting minutes available, this list must be provided. It should be dated and signed by the most recently \nappointed legal representative, whose appointment can be verified through related documentation.": "Nota: Se a lista atual de membros do conselho de administração não estiver incluída nos últimos Estatutos Sociais e não houver atas de reuniões do \nconselho disponíveis, esta lista deve ser fornecida. Ela deve ser datada e assinada pelo representante legal mais recentemente nomeado, cuja \nnomeação pode ser verificada por meio de documentação relacionada.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Este documento demonstra a autoridade de um indivíduo para representar a entidade. Este documento pode ser:",
    "The latest Articles of Association": "Os últimos Estatutos Sociais",
    "The current list of board management members \n(which may be included in the board management meeting minutes)": "A lista atual de membros do conselho de administração (que pode ser incluída nas atas da reunião do conselho de administração)",
    "The document should specify:": "O documento deve especificar:",
    "Full name of the individual(s)": "Nome completo do(s) indivíduo(s)",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to \nensure that the individual's function permits them to bind the entity. This is particularly important when the \napplicant is not the president, and other functions have limited representation authority as stated in \nthe Articles of Association.": "Função do(s) indivíduo(s). Essas informações serão verificadas com os Estatutos Sociais para garantir que a função do indivíduo lhe permita vincular a \nentidade. Isso é particularmente importante quando o requerente não é o presidente, e outras funções têm autoridade de representação limitada \nconforme declarado nos Estatutos Sociais.\n",
    "Additional information about the legal representative that will be collected includes:": "Informações adicionais sobre o representante legal que serão coletadas incluem:",
    "Date and place of birth": "Data e local de nascimento",
    "Residential address (at least the country of residence should be stated)": "Endereço residencial (pelo menos o país de residência deve ser indicado)",
    "Nationality": "Nacionalidade",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Estrutura de Propriedade e Proprietários Beneficiários Finais (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board \nmembers will be identified and treated as such for screening purposes, ID collection and verification, etc.": "Tipicamente, este tipo de entidades, dada sua estrutura e propósito, não têm proprietários beneficiários. No entanto, os membros do conselho serão \nidentificados e tratados como tal para fins de triagem, coleta e verificação de identidade, etc.\n",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, \nthis detail will be mentioned in the Articles of Association. These individuals will be easily identified and treated as such, \nalongside management board members.": "Um pequeno número de associações pode ter proprietários beneficiários, especialmente aquelas que geram lucros. Nestes casos, esse detalhe será \nmencionado nos Estatutos Sociais. Esses indivíduos serão facilmente identificados e tratados como tal, junto com os membros do conselho de \nadministração.\n",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Requisito Adicional: Em casos envolvendo Procuração ou Delegação de Poderes, ambas as partes\n(a pessoa que concede a autoridade e a que a recebe) devem fornecer uma cópia de seus documentos de identificação.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Extrato do Registro de Comerciante Individual (Documento de Registro/Criação da Entidade)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Este documento serve como um registro local para comerciantes individuais, exigido em países onde tal registro é obrigatório. Verifica que o indivíduo está \nenvolvido em uma atividade profissional como comerciante individual. O nome deste documento varia de país para país.",
    "Where applicable, the document should not be older than 3 months.": "Onde aplicável, o documento não deve ter mais de 3 meses.",
    "The document must contain:": "O documento deve conter:",
    "Individual's name": "Nome do indivíduo",
    "Residential address": "Endereço residencial",
    "Registration number": "Número de registro",
    "The sole trader is the only individual subject to the identification document collection process.": "O comerciante individual é o único sujeito ao processo de coleta de documentos de identificação.",
    "No Transactions": "Sem Transações",
    "No Pending Items": "Sem Itens Pendentes",
    "No Beneficiaries": "Sem Beneficiários",
    "Send Money To": "Enviar Dinheiro Para",
    "Confirmation": "Confirmação",
    "You are setting": "Você está definindo",
    "as default.": "como padrão.",
    "Previously": "Anteriormente",
    "was default QR.": "era o QR padrão.",
    "Category": "Categoria",
    "-Select-From-The-List-": "-Selecionar-Da-Lista-",
    "Add a note": "Adicionar uma nota",
    "Categories Added Successfully!": "Categorias Adicionadas com Sucesso!",
    "Subscription starting date": "Data de início da assinatura",
    "Until": "Até",
    "Next Transaction": "Próxima Transação",
    "Transaction Method": "Método de Transação",
    "Accept the quotation": "Aceitar a cotação",
    "Choose Currency": "Escolher Moeda",
    "Accept": "Aceitar",
    "Bargain the quotation": "Negociar a cotação",
    "Enter Counter Amount:": "Inserir Valor Contraproposta:",
    "Bargain": "Negociar",
    "Decline the quotation": "Cancelar a cotação",
    "Are you sure, you want to decline this quotation?": "Tem certeza de que deseja recusar esta cotação?",
    "Decline": "Recusar",
    "Personal Account": "Conta Pessoal",
    "Jan": "Jan",
    "Feb": "Fev",
    "Mar": "Mar",
    "Apr": "Abr",
    "May": "Mai",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Ago",
    "Sep": "Set",
    "Oct": "Out",
    "Nov": "Nov",
    "Dec": "Dez",
    "Today": "Hoje",
    "Spent In": "Gasto em",
    "Revised Amount": "Valor revisado",
    "You spent more comparing to last month": "Você gastou mais em comparação com o mês passado",
    "Last": "Passado",
    "days": "Dias",
    "Year": "Anos",
    "Instant Mobile Top-up Across 150+ Countries": "Recarga Móvel Instantânea em Mais de 150 Países",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Alegre seus entes queridos ou recarregue seu próprio telefone, não importa onde você esteja! O serviço de Recarga Móvel da InstaPay permite que você recarregue telefones móveis instantaneamente em mais de 150 países. Perfeito para presentes ou para garantir que você esteja sempre conectado, nosso serviço é projetado para facilidade, rapidez e segurança.",
    "How It Works - Step-by-Step Guide:": "Como Funciona - Guia Passo a Passo:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Escolha 'Recarga Móvel': Acesse essa funcionalidade diretamente no app InstaPay em plataformas como WhatsApp, Twitter, Telegram ou Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Selecione Moeda e Quantidade: Escolha entre várias moedas (USD, GBP, EUR, etc.) e especifique quanto você deseja recarregar.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Digite o Número do Celular: Digite o número que você deseja recarregar—o seu ou de um ente querido. Nosso sistema garante um processo seguro e rápido.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Confirme e Pague: Verifique os detalhes da recarga, confirme e faça o pagamento usando seu método preferido. A transação é processada instantaneamente e com segurança.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Receba Confirmação Instantânea: Tanto você quanto o destinatário (se aplicável) receberão uma notificação de confirmação assim que a recarga for bem-sucedida.",
    "Benefits:": "Benefícios:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Alcance Global: Envie crédito de forma fácil para mais de 150 países.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Impacto Instantâneo: Veja os benefícios imediatamente—perfeito para presentes de última hora ou comunicações urgentes.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Segurança Completa: Desfrute de paz de espírito com nosso sistema de pagamento seguro.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Opções de Pagamento Versáteis: Escolha entre cartões de crédito, PayPal ou sua carteira InstaPay para pagamentos.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Pronto para espalhar alegria ou manter-se conectado? Recarregue agora e faça o dia de alguém—ou o seu!",
    "Pick a language": "Escolha um idioma",
    "French": "Francês",
    "Spanish": "Espanhol",
    "Portuguese": "Português",
    "German": "Alemão",
    "Ukrainian": "Ucraniano",
    "Italian": "Italiano",
    "Russian": "Russo",
    "Arabic": "Árabe",
    "Polish": "Polonês",
    "Dutch (Nederlands)": "Holandês",
    "Yoruba": "Iorubá",
    "Indonesian": "Indonésio",
    "Turkish": "Turco",
    "Swahili": "Suaíli",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chinês",
    "Select Your Preferred Language": "Selecione o seu idioma preferido",
    "Please select the language you would \nlike to use for the eKYC process.": "Por favor selecione o idioma que gostaria de usar para o processo de eKYC.",
    "Select Your Nationality": "Selecione sua nacionalidade",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Escolha sua nacionalidade para ver os documentos de identificação suportados para seu país.",
    "Grant permission to use the camera.": "Conceda permissão para usar a câmera.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Toque ou clique em \"Permitir\" quando solicitado para conceder permissão de acesso à câmera para capturar o documento.",
    "Upload Your Documents from \nGallery/Photos": "Envie seus documentos da galeria/fotos",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Se você encontrar problemas com sua câmera ou se seu sistema não tiver uma, você pode enviar manualmente fotos do documento do seu armazenamento local.",
    "Upload Your ID.": "Carregue o seu documento de identidade.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Certifique-se de que o seu documento de identidade está claro e sem borrões para garantir uma verificação rápida.",
    "Valid Proof of Address": "Prova de Endereço Válida",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Certifique-se de que a sua prova de endereço está atual e que a data do extrato é dentro dos últimos três meses a partir da data de início da sua verificação eKYC.\n\n2. Certifique-se de que a sua prova de endereço está clara e sem borrões para garantir uma verificação rápida.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Aviso Importante\n\nPor favor, reveja todos os passos deste guia antes de prosseguir com a sua verificação eKYC.\n\nEvite erros comuns:\n\n* Certifique-se de que todos os seus documentos estão claros, legíveis e atualizados.\n* Verifique se as informações fornecidas correspondem aos detalhes dos seus documentos oficiais.\n* Siga as instruções com precisão para evitar atrasos ou rejeições.\n\nNota: \nSe a sua verificação eKYC falhar e precisar reenviá-la, será cobrada novamente pelo processo de reenvio.\n\nSeguindo estas diretrizes, pode ajudar a garantir uma verificação eKYC suave e bem-sucedida.\n\nPara quaisquer perguntas ou assistência, por favor contacte a nossa equipa de suporte em support@insta-pay.ch",
    "Personal Transactions": "Transações pessoais",
    "Business Transactions": "Transações comerciais",
    "Shopping & Purchases": "Compras e aquisições",
    "Bills & Utilities": "Contas e serviços públicos",
    "Entertainment & Leisure": "Entretenimento e Lazer",
    "Investments & Savings": "Investimentos e Poupança",
    "Health & Wellness": "Saúde e Bem-Estar",
    "Transportation": "Transporte",
    "Education": "Educação",
    "Miscellaneous": "Diversos",
    "Family Support": "Apoio familiar",
    "WALLET": "WALLET",
    "Add a currency": "Adicionar uma moeda",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Quando você adiciona uma moeda, todos os pagamentos que você receber nessa moeda serão creditados ao seu saldo InstaPay. Sua moeda principal será usada para enviar ou solicitar pagamentos, a menos que especificado de outra forma.",

    "balance amount ": "valor do saldo",
    "referral ": "referência",
    "crypto wallet balance": "saldo da carteira crypto",
    "money in": "dinheiro entrando",
    "money out": "dinheiro saindo",
    "analytics": "análise",
    "see more": "ver mais",
    "manage my Wallets and see the transaction details": "gerenciar meus Wallets e ver o detalhe de minhas transações",
    "set as default Wallet": "definir como Wallet padrão",
    "add currency": "adicionar moeda",
    "convert funds ": "converter fundos",
    "withdraw balance": "retirar saldo",
    "chart names": "nomes de gráficos",
    "withdraw balance from PKR wallet": "retirar saldo da carteira PKR",
    "select a payout channel": "selecionar um canal de pagamento",
    "you have entered an amount below the minimum payment range": "você inseriu um valor abaixo do valor mínimo exigido",
    "change": "mudar",
    "edit": "editar",
    "moving to next step in ...": "passando para a próxima etapa em ...",
    "you are withdrawing ...": "você está retirando ...",
    "where is my payment": "onde está meu pagamento",
    "download": "baixar",
    "IBAN / SWIFT Code": "IBAN ou código SWIFT/BIC",
    "swift code": "código SWIFT",
    "account number": "número da conta",
    "branch street": "rua da agência",
    "city": "cidade",
    "province": "província",
    "postal code": "código postal",
    "all payout channels": "todos os canais de pagamento",
    "bussiness beneficiary": "beneficiário comercial",
    "no quotation": "sem cotação",
    "no pending items": "sem itens pendentes",
    "QR status": "Status do QR",
    "Portfolio": "Portfólio",
    "edit whole portfolio page": "editar toda a página do portfólio",
    "personal account": "conta pessoal",
    "Chart names": "Nomes dos gráficos",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "mais",
    "not connected": "não conectado",
    "code": "código",
    "ratings": "avaliações",
    "current password": "senha atual",
    "include at least one uppercase and lowercase": "incluir pelo menos uma letra maiúscula e minúscula",
    "my social network accounts": "minhas contas de redes sociais",
    "SMS ": "SMS",
    "view identity verfication guide": "ver guia de verificação de identidade",
    "boimetric face recognition": "reconhecimento facial biométrico",
    "please select a payer (withdrawl options)": "por favor selecione um pagador (opções de retirada)",
    "IBAN number ": "número IBAN",
    "account holder name": "nome do titular da conta",
    "download data": "baixar dados",
    "bank transfer": "transferência bancária",
    "mobile wallet": "carteira móvel",
    "all bank names": "todos os nomes dos bancos",
    "pay via card": "pagar com cartão",
    "you have entered an amount that is below the payer range": "você inseriu um valor abaixo da faixa do pagador",
    "trouble sending money": "problema ao enviar dinheiro",
    "please follow these example": "por favor, siga estes exemplos",
    "wallet ID ": "ID da Wallet",
    "username": "nome de usuário",
    "email": "e-mail",
    "phone number": "número de telefone",
    "no user found": "nenhum usuário encontrado",
    "search": "pesquisar",
    "scan/upload QR code of the recipient": "escaneie/carregue o código QR do destinatário",
    "invite them": "convide-os",
    "trouble quoting money": "problema ao cotar dinheiro",
    "select a country": "selecionar um país",
    "airtime details": "detalhes da recarga",
    "enter the phone number": "digite o número de telefone",
    "service type": "tipo de serviço",
    "select a service": "selecionar um serviço",
    "service list": "lista de serviços",
    "amount entered is less than the ....": "o valor inserido é menor que ....",
    "total": "total",
    "total credit the receiver gets": "crédito total que o destinatário recebe",
    "take ": "pegar",
    "OTP has been verified , You'll be redirected......": "OTP foi verificado, você será redirecionado......",
    "Link Your Instagram Account to InstaPay": "Vincule sua conta do Instagram ao InstaPay",
    "Step 01 – From Instagram App": "Etapa 01 – Do aplicativo Instagram",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Abra o aplicativo Instagram, procure a página de perfil \"InstaPay\", selecione \"Mensagem\" para abrir a caixa de bate-papo, digite/diga \"Oi\" e clique em enviar para iniciar a conversa com o chatbot do InstaPay.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Etapa 02 – Da página de perfil Instagram do InstaPay",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "O chatbot do InstaPay exibirá dois botões de ação: \"Registrar\" e \"Conectar\". Selecione \"Conectar\" e o chatbot solicitará que você insira seu nome de usuário do InstaPay.",
    "Step 03 – From InstaPay Instagram Profile Page": "Etapa 03 – Da página de perfil Instagram do InstaPay",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "O chatbot do InstaPay solicitará então o código de verificação do InstaPay. Insira o código que você obteve nas configurações do InstaPay em \"Minhas Contas de Redes Sociais\" e clique em enviar.",
    "Step 04 – From InstaPay Instagram Profile Page": "Etapa 04 – Da página de perfil Instagram do InstaPay",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Você receberá uma mensagem de congratulações confirmando que sua conta do Instagram agora está sincronizada com o InstaPay. Clique em \"Menu Principal\" para começar a usar os serviços do InstaPay.",
    "Additional Tips": "Dicas adicionais",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Proteja sua conta: Certifique-se de que sua conta do Instagram está segura ativando a autenticação de dois fatores (2FA) para proteger seus serviços InstaPay vinculados.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Explore mais: Uma vez vinculado, explore a ampla gama de serviços que o InstaPay oferece, desde transações sem interrupções até saques instantâneos.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Fique atualizado: Fique de olho nas nossas atualizações, pois em breve suportaremos a vinculação de outras contas de redes sociais para tornar sua experiência ainda mais integrada.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Ajuda e suporte: Se você enfrentar algum problema durante o processo de vinculação, entre em contato com nossa equipe de suporte para obter ajuda.",
    "Future Enhancements": "Melhorias futuras",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "No futuro próximo, o InstaPay permitirá que você vincule mais contas de redes sociais, proporcionando ainda mais flexibilidade e conveniência. Fique atento para atualizações empolgantes!",
    "Personal Transactions": "Transações pessoais",
    "Business Transactions": "Transações comerciais",
    "Shopping & Purchases": "Compras e aquisições",
    "Bills & Utilities": "Contas e serviços públicos",
    "Entertainment & Leisure": "Entretenimento e Lazer",
    "Investments & Savings": "Investimentos e Poupança",
    "Health & Wellness": "Saúde e Bem-Estar",
    "Transportation": "Transporte",
    "Education": "Educação",
    "Miscellaneous": "Diversos",
    "Family Support": "Apoio familiar",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Comece sua jornada InstaPay hoje!",
    "Enter your phone number": "Insira seu número de telefone",
    "Login with email": "Entrar com email",
    "Don't have an account?": "Não tem uma conta?",
    "Sign Up": "Cadastre-se",
    "Continue": "Continuar",
    "We recommend you download and install the Google Authenticator app": "Recomendamos que você baixe e instale o aplicativo Google Authenticator",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "A autenticação de dois fatores (2FA) é a melhor maneira de se proteger online.",
    "Strong security with Google Authenticator": "Segurança forte com Google Authenticator",
    "Enter your password": "Insira sua senha",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Recomendamos que você baixe e instale o aplicativo Google Authenticator para garantir que você receba seus códigos de verificação instantaneamente, evitando quaisquer atrasos ou problemas com SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Código de Autenticação de Dois Fatores",
    "Problem with the token?": "Problema com o token?",
    "Verify using SMS": "Verifique usando SMS",
    "Verify": "Verificar",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "Verificação por SMS",
    "Enter the SMS code sent to +41********4053": "Insira o código SMS enviado para +41********4053",
    "Verify Using Authenticator App": "Verifique usando o aplicativo Authenticator",
    "Resend code in 120 seconds": "Reenviar código em 120 segundos",
    "Verify ": "Verificar",
    "SMS - Web Mobile": null,
    "My own account": "Minha própria conta",
    "Friends & Family": "Amigos e Família",
    "Aunt": "Tia",
    "Brother-in-law": "Cunhado",
    "Cousin": "Primo",
    "Daughter": "Filha",
    "Father": "Pai",
    "Father-in-law": "Sogro",
    "Friend": "Amigo",
    "Grandfather": "Avô",
    "Grandmother": "Avó",
    "Husband": "Marido",
    "Mother": "Mãe",
    "Mother-in-law": "Sogra",
    "Nephew": "Sobrinho",
    "Niece": "Sobrinha",
    "Self (i.e. the sender, himself)": "Eu mesmo (ou seja, o remetente)",
    "Sister": "Irmã",
    "Sister-in-law": "Cunhada",
    "Son": "Filho",
    "Uncle": "Tio",
    "Wife": "Esposa",
    "Others not listed": "Outros não listados",
    "Merchant": "Comerciante",
    "No relationship": "Sem relação",
    "Add Funds": "Adicionar fundos",
    "Enter the Amount": "Insira o valor:",
    "Fees": "Taxas:",
    "You Will Get": "Você receberá:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ O valor da transação deve estar entre [Currency ISO code & Amount] - [Currency ISO code & Amount]",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Quantia inválida: Seu nível atual de verificação de identidade limita a quantidade máxima que você pode adicionar. Por favor, atualize sua verificação de identidade para se beneficiar de limites mais altos.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ O preço da moeda muda frequentemente com base nas condições de mercado. Consulte o preço na página de confirmação do pedido como sua cotação final.",
    "Next": "Próximo",
    "Upgrade Identity Verification": "Atualizar verificação de identidade",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Solicite dinheiro de qualquer pessoa, em qualquer lugar, incluindo nas plataformas de redes sociais. Com o InstaPay, seus pedidos de pagamento são acessíveis instantaneamente após serem aceitos e os fundos são enviados para suas contas de recebimento. Simplifique suas transações e gerencie pagamentos com facilidade e segurança.",
    "You’ve been logged out.": "Você foi desconectado.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Por sua segurança, você foi desconectado após 15 minutos de inatividade. Por favor, faça login novamente se desejar usar o InstaPay.",
    "LOGIN AGAIN": "FAZER LOGIN NOVAMENTE",
    "Brother": "Irmão",
    "Current Password": "Senha atual",
    "The new password cannot be the same as the old one": "A nova senha não pode ser igual à antiga.",
    "Minimum password length: [X] characters ": "Comprimento mínimo da senha: [X] caracteres",
    "Include at least one uppercase and one lowercase letter.": "Inclua pelo menos uma letra maiúscula e uma minúscula.",
    "Nominee Information": "Informações do nomeado",
    "Verification Code Preferences": "Preferências do código de verificação",
    "How Others Can Find You": "Como outros podem te encontrar",
    "My Social Network Accounts": "Minhas contas de redes sociais",
    "Login Activity": "Atividade de login",
    "Receiving Accounts": "Contas de recebimento",
    "Change Your Password To Continue": "Altere sua senha para continuar",
    "Continue with": "Continuar com",
    "Back to Login": "Voltar para o login",
    "Send OTP": "Enviar OTP",
    "A Verification code has been sent to your email and number.": "Um código de verificação foi enviado para seu email e número.",
    "Resend available in": "Reenvio disponível em",
    "Create a strong Password": "Crie uma senha forte",
    "Password must:": "A senha deve:",
    "Be between 9 and 64 characters": "Ter entre 9 e 64 caracteres",
    "Include at least two of the following:": "Incluir pelo menos dois dos seguintes:",
    "Lowercase Character": "Caractere minúsculo",
    "Special Character": "Caractere especial",
    "Password Updated Successfully!": "Senha atualizada com sucesso!",
    "Click below to login": "Clique abaixo para fazer login",

    "Send Money": "Enviar Dinheiro",
    "Enter the amount": null,
    "Recent Transfer": "Transferência Recente",
    "See all": "Ver tudo",
    "Send again": "Enviar novamente",
    "You Send": "Você Envia",
    "They Receive": "Eles Recebem",
    "Receive Method": "Método de Recebimento",
    "Select method": "Selecionar método",
    "Next": "Próximo",
    "Trouble sending money?": "Problemas ao enviar dinheiro?",
    "Where do you want to send money ?": "Para onde você quer enviar dinheiro?",
    "Search ": "Procurar",
    "Confirm": "Confirmar",
    "Bank Deposit": "Depósito Bancário",
    "Cash Pick-up": "Retirada de Dinheiro",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Cartão Bancário",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Qual é o Wallet móvel do destinatário?",
    "Transfer fees": "Taxas de transferência",
    "Total to pay": "Total a pagar",
    "Choose the beneficiary!": "Escolha o beneficiário!",
    "Country": "País",
    "Receive method": "Método de Recebimento",
    "You send": "Você envia",
    "change": "editar",
    "edit": "adicionar novo",
    "add new": "Beneficiários",
    "Beneficiaries": "Gerenciar Beneficiários",
    "Manage Beneficiaries": "Procurar",
    "Search": "Próximo",
    "CASH PICK-UP": "RETIRADA EM DINHEIRO",
    "Where will your receiver collect the money": "Onde seu destinatário coletará o dinheiro?",
    "Cash is available to collect within minutes from any branch of [name] ": "O dinheiro está disponível para retirada em minutos de qualquer agência de [nome]",
    "BANK ACCOUNT": "CONTA BANCÁRIA",
    "Transfer to recipient’s bank account": "Transferir para a conta bancária do destinatário",
    "BANK CARD": "CARTÃO BANCÁRIO",
    "Fast transfer directly to recipient’s bank card": "Transferência rápida diretamente para o cartão bancário do destinatário",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Transferência rápida diretamente para um Wallet crypto",
    "Review transfer": "Revisar transferência",
    "Receiver's Details": "Detalhes do Destinatário",
    "Edit": "Editar",
    "Name": "Nome",
    "Reason for sending": "Motivo do envio",
    "Sending": "Enviando",
    "Receiver gets": "O destinatário recebe",
    "Exchange rate": "Taxa de câmbio",
    "Estimated time": "Tempo estimado",
    "Select Payment Type": "Selecione o Tipo de Pagamento",
    "Credit cards": "Cartões de crédito",
    "Domestic cards": "Cartões Domésticos",
    "Mobile Wallets": "Wallets móveis",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "CARTÃO DE CRÉDITO",
    "Credit Card": "Cartão de crédito",
    "Select card": "Selecionar cartão",
    "Card Number": "Número do Cartão",
    "Enter the 16-digit card number on the card": "Digite o número do cartão de 16 dígitos",
    "Expiry Date": "Data de Validade",
    "Enter the expiration date of the card": "Digite a data de validade do cartão",
    "CVV Number": "Número CVV",
    "Enter the 3 or 4 digit number on the card": "Digite o número de 3 ou 4 dígitos no cartão",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Selecionar Wallet",
    "My Wallet": "Meu Wallet",
    "Conversion": "Conversão",
    "Convert": "Converter",
    "Your payment is confirmed": "Seu pagamento está confirmado",
    "Thanks for using InstaPay": "Obrigado por usar InstaPay",
    "Status": "Status",
    "Share": "Compartilhar",
    "Transfer created": "Transferência criada",
    "Payment received": "Pagamento recebido",
    "Payment processed": "Pagamento processado",
    "Transfer successful": "Transferência bem-sucedida",
    "It may take few hours for the funds to appear in [user name] account": "Pode levar algumas horas para os fundos aparecerem na conta de [nome do usuário]",
    "Transfer failed": "Transferência falhou",
    "Transaction Details": "Detalhes da Transação",
    "Need help?": "Precisa de ajuda?",
    "Service Details": "Detalhes do Serviço",
    "Funds Deposit Speed": "Disponibilidade de Fundos",
    "Cut-off Time": "Hora Limite",
    "Bank holiday list": "Lista de Feriados Bancários",
    "Threshold limits": "Limites Transacionais",
    "null": null,
    "Instant, Real Time": "Instantâneo, em tempo real",
    "Same day, T+1": "No mesmo dia, T+1",
    "Non-instant, T+1": "Não instantâneo, T+1",
    "Instant Payment, Real Time": "Pagamento instantâneo, em tempo real",
    "Transaction processed before 3:00 PM Local time": "Transação processada antes das 15:00 hora local",
    "Delivered the same day": "Entregue no mesmo dia",
    "Transactions processed post 3:00 PM": "Transações processadas após as 15:00",
    "T+1 business days": "T+1 dias úteis",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Os pagamentos realizados em dias úteis antes das 15:00 serão creditados na conta bancária de destino até a meia-noite do mesmo dia.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Os pagamentos realizados em dias úteis após as 15:00 serão creditados até a meia-noite do dia seguinte.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Os pagamentos realizados em um sábado, domingo ou feriado serão creditados na conta até a meia-noite do próximo dia útil.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09hrs - 18 hrs hora local",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Limite mínimo por transação:",
    "Maximum per transaction limit:": "Limite máximo por transação:",
    "Daily threshold limit:": "Limite diário:",
    "Weekly threshold limit:": "Limite semanal:",
    "Monthly threshold limit:": "Limite mensal:",
    "Yearly threshold limit:": "Limite anual:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Conta básica (Não verificada)",
    "Identity Verified": "Identidade verificada",

    "Good Evening!": "Boa Noite!",
    "Total Balance": "Saldo Total da Conta",
    "Quick Transfer": "Transferência Rápida de Fundos",
    "No transactions": "Nenhuma transação encontrada",
    "Blocked balance": "Saldo Bloqueado",
    "Available balance": "Saldo Disponível",
    "Insufficient Balance in PKR": "Saldo Insuficiente em PKR",
    "Chat with us": "Converse conosco",
    "Add Currency": "Adicionar Moeda",
    "Education": "Educação",
    "Divers": "Diversos",
    "Family support": "Suporte Familiar",
    "Others": "Outras Despesas",
    "Transport": "Transporte",
    "schedule payment": "Pagamento Agendado",
    "subscription payment": "Pagamento Recorrente",
    "No payment": "Nenhum pagamento encontrado",
    "No Beneficiaries": "Nenhum Beneficiário Disponível",
    "Code Postal": "Código Postal",
    "Additional Information": "Informação Adicional",
    "Gender": "Gênero",
    "Date Of Birth": "Data de Nascimento",
    "Nationality": "Nacionalidade",
    "Occupation": "Ocupação (todas as opções também)",
    "ID Type": "Tipo de Identificação (todas as opções também)",
    "ID Number": "Número de Identificação",
    "Select Beneficiary Payment Channels": "Selecionar Canais de Pagamento do Beneficiário",
    "wallet crypto": "Carteira Cripto",
    "Add another wallet": "Adicionar outra carteira",
    "Enterprise": "Negócios",
    "Business beneficiaries are next in our pipeline. Soon, you'll be able to conduct business transactions globally, expanding your reach worldwide.": "Beneficiários empresariais em breve! Você poderá realizar transações comerciais globalmente, expandindo seu alcance além das fronteiras.",
    "Joined At": "Registrado em",
    "Profile Link": "Link do Perfil",
    "comission": "Comissão",
    "my withdrawls": "Meus Saques",
    "My Referrals": "Minhas Referências",
    "search quotation": "Procurar Cotações",
    "no quotation": "Nenhuma cotação encontrada",
    "actions": "Ações",
    "search request": "Procurar solicitação",
    "no pending items ": "Nenhum item pendente encontrado",
    "Share your QR Code or pay link to instantly get paid in your insta wallet": "Compartilhe seu Código QR ou link de pagamento para receber pagamentos instantaneamente na sua carteira InstaPay",
    "Description": "Descrição",
    "save": "Salvar",
    "Share your Insta-Pay unique payment link": "Compartilhe seu link de pagamento exclusivo InstaPay",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Eleve seu perfil público. Seu portfólio atua como uma vitrine dinâmica, acessível diretamente pelo seu Endereço de Pagamento",
    "Connect with visitors through your creative or professional journey. Let your work speak for you, creating a deeper engagement with potential clients or supporters.": "Conecte-se com seu público através de sua jornada profissional ou criativa. Deixe seu trabalho se destacar e envolva potenciais clientes ou apoiadores de forma mais profunda.",
    "Display your social media influence. Visitors can see your social media reach, adding credibility and context to your portfolio.": "Mostre seu alcance nas redes sociais. Os visitantes podem ver seu impacto nas redes sociais, agregando credibilidade ao seu portfólio.",
    "Enable visitors to request or initiate payments right from your public page. Your portfolio not only showcases your work but also facilitates easy financial interactions.": "Permita que os visitantes solicitem ou iniciem pagamentos diretamente da sua página pública. Seu portfólio não é apenas uma vitrine, mas também uma maneira de facilitar transações financeiras de forma fácil.",
    "Use the 'About Me' section to share your story, mission, or vision, creating a personal connection with your audience.": "Use a seção \"Sobre Mim\" para compartilhar sua história, missão ou visão, criando uma conexão pessoal com seu público.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your audience, and streamline your financial interactions on InstaPay.": "Meu Portfólio é mais do que uma galeria; é uma ferramenta para construir sua marca, envolver seu público e simplificar as interações financeiras no InstaPay.",
    "No Portfolio Items": "Nenhum Item de Portfólio Encontrado",
    "Add New Portfolio Item": "Adicionar Novo Item ao Portfólio",
    "Title": "Título",
    "drag/drop or upload a file(photo/video/pdf)": "Arrastar/Soltar ou fazer upload de um arquivo (foto/vídeo/pdf)",
    "discard": "Descartar",
    "save and post ": "Salvar e Publicar",
    "My Payment Address": "Meu Endereço de Pagamento",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show their support.": "Perfeito para influenciadores, artistas, empreendedores ou qualquer pessoa que queira deixar sua comunidade mostrar apoio facilmente.",
    "Personal Account": "Status da Conta Pessoal",
    "spent in total": "Total Gasto",
    "not connected": "Não Conectado",
    "more": "Mais",
    "less": "Menos",
    "profile reports": "Relatórios de Perfil",
    "All notifications are in English langauge": "Todas as notificações estão em inglês",
    "timezone": "Fuso Horário",
    "nominee information": "Informação do Indicado",
    "full name": "Nome Completo",
    "Relation with Nominee": "Relação com o Indicado",
    "source": "Fonte",
    "Google Authenticator": "Google Authenticator",
    "notifications": "Notificações",
    "login": "Entrar",
    "View Identity Verification Guide": "Ver Guia de Verificação de Identidade",
    "verified": "Verificado",
    "select ": "Selecionar",
    "It must be a government-issued identification document with a photo. All identification documents must be written in the Latin alphabet. If not, a certified translation is required.": "Deve ser um documento de identificação emitido pelo governo com foto. Toda identificação deve estar em alfabeto latino. Caso contrário, é necessária uma tradução certificada.",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) or further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom reserva-se o direito de solicitar documentos adicionais (Diligência Devida Reforçada - EDD) ou qualquer informação necessária para realizar uma verificação completa, de acordo com suas obrigações legais.",
    "sessions": "Sessões",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Desbloqueie Saques Instantâneos: Configure Suas Contas de Recebimento Agora!",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up locations. Follow the steps below to ensure you can instantly access your funds whenever you need them.": "Gerencie facilmente onde deseja receber pagamentos adicionando os detalhes de suas contas bancárias, carteiras móveis, carteiras cripto, cartões de pagamento ou pontos de retirada em dinheiro. Siga os passos abaixo para garantir o acesso instantâneo aos seus fundos sempre que precisar.",
    "Select the receiving channel below and setup the details.": "Selecione o canal de recebimento abaixo e configure os detalhes.",
    "Please Select A Payer": "Por favor, Selecione um Pagador",
    "Number": "Número",
    "Account Holder Name": "Nome do Titular da Conta",
    "Province": "Província",
    "postal": "Endereço Postal",
    "delete channel": "Excluir Canal",
    "set as default": "Definir como Padrão",
    "edit": "Editar",
    "all channels are in english language in recieving account page": "Todos os canais estão em inglês na página de contas de recebimento",
    "select file type": "Selecionar Tipo de Arquivo",
    "download data": "Baixar Dados",
    "INTERNATIONAL TRANSFER": "TRANSFERÊNCIA INTERNACIONAL",
    "enter the amount": "Digite o Valor",
    "they recieve ": "O Beneficiário Recebe",
    "trouble sending money": "Problemas para Enviar Dinheiro?",
    "select destination country": "Selecione o País de Destino",
    "bank transfer": "Transferência Bancária",
    "recieving method": "Método de Recebimento",
    "payer": "Pagador",
    "The fee and the received amount may vary depending on the chosen Payment Method": "As tarifas e o valor recebido podem variar de acordo com o método de pagamento escolhido.",
    "search ": "Pesquisar",
    "show more": "Mostrar Mais",
    "select remark reasons": "Selecione Motivos de Observação",
    "credit and debit card": "Cartões de Crédito e Débito",
    "Transfer Fees": "Tarifas de Transferência",
    "Estimated Time": "Tempo Estimado:",
    "within minutes": "Em Minutos",
    "total ": "Total",
    "OTP verifications code": "Código de Verificação OTP",
    "verify using sms/email": "Verificar via SMS/Email",
    "sms/email verification code": "Código de Verificação por SMS/Email",
    "Verify Using Authenticator": "Verificar Usando Autenticador",
    "WALLET TO WALLET TRANSFER": "TRANSFERÊNCIA DE CARTEIRA PARA CARTEIRA",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Digite o ID da Carteira, Nome de Usuário, Email ou Número de Telefone do destinatário.",
    "search": "Pesquisar",
    "lookup": "Buscar",
    "Recipient Not on InstaPay?": "Destinatário Não Está no InstaPay?",
    "invite them": "Convide-o",
    "Please follow these examples": "Por favor, siga estes exemplos:",
    "Wallet ID: GY68J782": "ID da Carteira: GY68J782",
    "InstaPay/Instagram Username": "Nome de Usuário do InstaPay/Instagram: instauser",
    "Email: user@email.com": "Email: user@email.com",
    "With Country code": "Número de Telefone (com Código de País)",
    "invite friend": "Convidar Amigo",
    "invite via phone number": "Convidar via Número de Telefone",
    "invite via email address": "Convidar via Endereço de Email",
    "next": "Próximo",
    "back": "Voltar",
    "Personalized Message": "Mensagem Personalizada",
    "available currency": "Moeda Disponível",
    "attach files": "Anexar Arquivos",
    "total amount": "Valor Total",
    "instant payment": "Pagamento Instantâneo",
    "starting from": "A Partir de",
    "recurring cycle": "Ciclo Recorrente",
    "until I stop": "Até Interromper",
    "start from": "A Partir de",
    "cycle": "Ciclo",
    "total": "Total",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction": "O beneficiário pode receber um valor ligeiramente ajustado devido às taxas de câmbio.",
    "schedule to": "Agendado Para",
    "schedule at": "Agendado Em",
    "Scheduled Date": "Data Agendada",
    "Scheduled time": "Hora Agendada",
    "The recipient may receive a slightly adjusted amount based on the exchange rate at the time of the transaction.": "O beneficiário pode receber um valor ligeiramente ajustado devido às taxas de câmbio.",
    "QR CODE PAYMENT ": "PAGAMENTO COM CÓDIGO QR",
    "Alternatively, Use the InstaPay AlphaNumeric QR Code.": "Alternativamente, use o Código QR Alfanumérico do InstaPay.",
    "PYAYMENT REQUEST": "SOLICITAÇÃO DE PAGAMENTO",
    "choose the beneficiary": "Escolha o Beneficiário",
    "trouble requesting money": "Problemas ao Solicitar Dinheiro?",
    "Standard Request": "Solicitação Padrão",
    "Subscription Request": "Solicitação Recorrente",
    "Schedule Request": "Solicitação Agendada",
    "Security Request": "Solicitação de Depósito de Segurança",
    "Instalment Request": "Plano de Parcelamento",
    "Split Request": "Pagamento Dividido",
    "You recieved": "Você Recebeu",
    "is this your location?": "Esta é Sua Localização?",
    "yes": "Sim",
    "Authenticator Code": "Código do Autenticador",
    "Enter a code shown in the app to make sure everything works fine.": "Digite o código mostrado no aplicativo para garantir que tudo está correto.",
    "SEND A QUOTE": "ENVIAR UMA COTAÇÃO",
    "Trouble Quoting Money?": "Problemas para Enviar uma Cotação?",
    "Please follow these examples:": "Por favor, siga estes exemplos:",
    "Full Name: Muhammad Imtiaz": "Nome Completo: Muhammad Imtiaz",
    "InstaPay/Intagram Username: instauser": "Nome de Usuário do InstaPay/Instagram: instauser",
    "Phone Number: 44795396600 (With Country Code)": "Número de Telefone: 44795396600 (com Código de País)",
    "SEND TOP-UP AND E-SIM": "ENVIAR RECARGA E E-SIM",
    "Keep yourself and your loved ones connected and covered, no matter where you are! InstaPay's Send Top-up service allows you to instantly recharge mobile phones, get eSIMs, purchase branded vouchers & gift cards, and pay for utilities & bills in over 150 countries. Ideal for gifts or ensuring you always have what you need, our service is designed for ease, speed, and security.": "Mantenha-se Conectado em Nível Mundial! O serviço de Recarga do InstaPay permite que você recarregue telefones instantaneamente, compre eSIMs, vouchers de marca, cartões-presente e pague contas de serviços públicos em mais de 150 países. Ideal para presentes ou para se manter preparado, nosso serviço prioriza a facilidade, a velocidade e a segurança.",
    "MOBILE AIRTIME AND E-SIM": "TEMPO DE AR PARA MÓVEIS E E-SIM",
    "Select the country where you want to top-up a mobile phone!": "Selecione o país para recarga móvel!",
    "Other Operator": "Outros Operadores",
    "airtime": "Tempo de Ar",
    "bundle": "Pacote",
    "data": "Dados",
    "Enter custom amount": "Digite Valor Personalizado",
    "Credit & Debit Card": "Cartão de Crédito e Débito",
    "Bank Transfer": "Transferência Bancária",
    "Mobile Wallet": "Carteira Móvel",
    "InstaPay Wallet": "Carteira InstaPay",
    "PayPal": "PayPal",
    "add card payment": "Adicionar Pagamento com Cartão",
    "save details to use next time.": "Salvar Detalhes para Uso Futuro",
    "Recipient Mobile Number": "Número de Telefone do Destinatário",
    "Amount": "Valor",
    "Service": "Serviço",
    "Destination": "Destino",
    "Provider": "Provedor",
    "Airtime": "Tempo de Ar",
    "Pakistan": "Paquistão",
    "Telenor Pakistan": "Telenor Paquistão",
    "Within minutes": "Em Minutos",
    "top-up summary": "Resumo de Recarga",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR não é suportado pelo PayPal, USD será usado como padrão.",
    "Amount in USD": "Valor em USD",
    "Send Top-up": "Enviar Recarga",
    "available sunservices": "Serviços Disponíveis",
    "login with email": "Entrar com Email",
    "Already Have an Account?": "Já tem uma conta? Faça login",
    "profile picture": "Foto de Perfil",
    "add photo/logo": "Adicionar Foto/Logo",
    "first name": "Primeiro Nome",
    "enter your first name": "Digite Seu Primeiro Nome",
    "last name": "Sobrenome",
    "enter your last name": "Digite Seu Sobrenome",
    "email(optional)": "Email (Opcional)",
    "enter your email address": "Digite Seu Endereço de Email",
    "password must": "Senha Obrigatória",
    "It will be autofill, if you're signing up with invitation link": "Autopreencher para Registro por Convite",
    "change number": "Alterar Número",
    "signup error": "Erro de Registro",
    "Something went wrong while sending sms!": "Algo deu errado ao enviar o SMS! Por favor, tente novamente mais tarde.",
    "Errors": "Ocorreram erros. Consulte a lista a seguir para obter detalhes.",
    "Generic error": "Erro Genérico",
    "Trust Payments API requires the 'requests' library": "A API de Trust Payments requer a biblioteca 'requests' para funcionar corretamente.",
    "Send error": "Erro durante o processo de envio. Por favor, tente novamente.",
    "Receive error": "Erro durante o processo de recebimento. Por favor, tente novamente.",
    "Invalid credentials provided": "Credenciais inválidas. Por favor, verifique e tente novamente.",
    "An issue occurred whilst trying to connect to Trust Payments servers": "Ocorreu um problema ao se conectar aos servidores da Trust Payments. Verifique sua conexão e tente novamente.",
    "Unexpected error connecting to Trust Payments servers. If the problem persists please contact support@trustpayments.com": "Erro inesperado ao conectar aos servidores da Trust Payments. Se o problema persistir, entre em contato com support@trustpayments.com.",
    "Unknown error. If this persists please contact Trust Payments": "Erro desconhecido. Entre em contato com o suporte da Trust Payments se o problema continuar.",
    "Incorrect usage of the Trust Payments API": "Uso incorreto da API de Trust Payments. Verifique a solicitação e tente novamente.",
    "Invalid card details": "Detalhes do cartão inválidos. Verifique e insira os detalhes corretos.",
    "Invalid date": "Data inválida fornecida.",
    "Invalid date/time": "Formato de data/hora inválido. Forneça o formato correto.",
    "Invalid details": "Detalhes inválidos. Verifique e insira as informações corretas.",
    "Card number does not match card type": "O número do cartão não corresponde ao tipo de cartão especificado.",
    "Malformed XML": "Erro de formatação XML. Verifique e corrija o XML.",
    "XML does not match schema": "O XML não corresponde ao esquema esperado.",
    "Invalid file format": "Formato de arquivo inválido. Use um formato compatível.",
    "Empty file contents": "Conteúdo do arquivo vazio. Forneça um conteúdo válido.",
    "Invalid file contents": "Conteúdo do arquivo inválido. Verifique o arquivo e tente novamente.",
    "Malformed JSON": "JSON mal formado. Corrija o formato JSON.",
    "StApi Error": "Erro StApi",
    "Invalid fields specified in request": "Campos inválidos especificados na solicitação. Verifique e corrija-os.",
    "Missing parent": "Informação de pai ausente.",
    "Refund requires settled parent or parent that's due to settle today": "O reembolso requer uma transação principal liquidada ou uma transação principal com liquidação prevista para hoje.",
    "Refund requires authorisation parent": "O reembolso requer uma transação principal de autorização.",
    "Refund amount too great": "O valor do reembolso excede o limite permitido.",
    "No acquirer specified": "Nenhuma informação do adquirente fornecida.",
    "Repeat amount too great": "O valor da repetição excede o limite permitido.",
    "Split amount too great": "O valor da divisão excede o limite permitido.",
    "Cannot refund a declined transaction": "Não é possível reembolsar uma transação recusada.",
    "Refund requires a settled parent": "O reembolso requer uma transação principal liquidada.",
    "Reversal requires a cancelled auth parent": "A reversão requer uma transação de autorização cancelada.",
    "Cannot override amount in child transaction": "Não é possível substituir o valor em uma transação secundária.",
    "Cannot override currency in child transaction": "Não é possível substituir a moeda em uma transação secundária.",
    "Subscription requires RECUR account": "A assinatura requer uma configuração de conta recorrente.",
    "Subscription requires successful parent": "A assinatura requer uma transação principal bem-sucedida.",
    "Risk Decisions must have AUTH as parent": "Decisões de risco devem estar vinculadas a uma transação de autorização.",
    "Chargebacks must have AUTH/REFUND as parent": "Estornos devem ser vinculados a uma transação de autorização ou reembolso.",
    "Refund amount less than Minimum allowed": "O valor do reembolso é menor que o mínimo permitido.",
    "Refund requires paypaltransactionid": "O reembolso requer um ID de transação do PayPal.",
    "Invalid split transaction": "Transação dividida inválida.",
    "Cannot reverse AUTH processed more than 48 hours ago": "Não é possível reverter uma autorização processada há mais de 48 horas.",
    "Reversal requires acquirerreferencedata": "A reversão requer dados de referência do adquirente.",
    "Cannot reverse AUTH processed by a different acquirer": "Não é possível reverter uma autorização processada por um adquirente diferente.",
    "Payment type does not support repeats": "O tipo de pagamento não suporta transações repetidas.",
    "Reversal missing required data": "Reversão faltando dados obrigatórios.",
    "Missing token": "Informação de token ausente.",
    "Subscription with an accountcheck parent not supported on current acquirer": "Assinatura com um pai de verificação de conta não suportada pelo adquirente atual.",
    "Subscription cannot be used as a parent": "A assinatura não pode ser usada como uma transação principal.",
    "Invalid parent": "Tipo de transação principal inválido.",
    "Payment type does not support refunds": "O tipo de pagamento não suporta reembolsos.",
    "Invalid incremental transaction": "Transação incremental inválida.",
    "Partial reversals not supported": "Reversões parciais não são suportadas.",
    "THREEDQUERY parent/child must have the same payment type as the child": "Transações pai e filho devem ter o mesmo tipo de pagamento para consultas 3D Secure.",
    "Payment type does not support card scheme updates": "O tipo de pagamento não suporta atualizações do esquema do cartão.",
    "Cannot reverse AUTH at this time, please try again": "Não é possível reverter a autorização no momento. Tente novamente mais tarde.",
    "Cannot determine token": "Não foi possível determinar o token. Verifique e tente novamente.",
    "Service Temporarily Disabled": "Serviço temporariamente desativado. Tente novamente mais tarde.",
    "Login first request": "Login necessário antes de fazer uma solicitação.",
    "Invalid username/password": "Nome de usuário ou senha inválidos. Tente novamente.",
    "Invalid session": "Sessão inválida. Faça login novamente.",
    "Session has expired": "Sessão expirada. Faça login novamente.",
    "Password expired": "Senha expirada. Redefina sua senha.",
    "Password has been previously used": "A senha foi usada anteriormente. Escolha uma nova senha.",
    "MyST user account has been locked": "A conta do usuário MyST foi bloqueada. Entre em contato com o suporte.",
    "New password does not match confirmed password": "A nova senha não corresponde à senha confirmada.",
    "Incorrect current password": "Senha atual incorreta. Tente novamente.",
    "Invalid selection": "Seleção inválida. Escolha uma opção válida.",
    "User already exists": "O usuário já existe. Faça login ou redefina a senha, se necessário.",
    "No transaction found": "Nenhuma transação encontrada.",
    "Invalid selected transactions": "Transações selecionadas inválidas.",
    "Data supplied has not been saved": "Os dados não foram salvos. Tente novamente.",
    "Invalid request type": "Tipo de solicitação inválido.",
    "Missing request type, at least one request type must be selected": "Faltando tipo de solicitação. Pelo menos um deve ser selecionado.",
    "Invalid payment type": "Tipo de pagamento inválido.",
    "Missing payment type, at least one payment type must be selected": "Faltando tipo de pagamento. Pelo menos um deve ser selecionado.",
    "Invalid error code": "Código de erro inválido.",
    "Missing error code, at least one error code must be selected": "Código de erro ausente. Pelo menos um deve ser selecionado.",
    "Invalid filter description": "Descrição do filtro inválida.",
    "Invalid destination description": "Descrição do destino inválida.",
    "Invalid notification type": "Tipo de notificação inválido.",
    "Invalid destination": "Destino inválido.",
    "Invalid field selected": "Campo selecionado inválido.",
    "Invalid email from address": "Endereço de email do remetente inválido.",
    "Invalid email subject": "Linha de assunto do email inválida.",
    "Invalid email type": "Tipo de email inválido.",
    "Unable to process request": "Não foi possível processar a solicitação. Tente novamente mais tarde.",
    "No file selected for upload": "Nenhum arquivo selecionado para upload.",
    "Invalid file size": "Tamanho do arquivo inválido. Forneça um arquivo de tamanho aceitável.",
    "Invalid filename": "Nome do arquivo inválido.",
    "Invalid extension": "Extensão de arquivo inválida.",
    "User requires at least one site reference": "O usuário requer pelo menos uma referência de site.",
    "Only ST-level users can have '*' access": "Somente usuários de nível ST podem ter acesso '*' completo.",
    "Request failed": "Solicitação falhou. Tente novamente mais tarde.",
    "Invalid File Contents": "Conteúdo do arquivo inválido.",
    "Maximum number of files uploaded": "Número máximo de arquivos enviados. Por favor, exclua alguns arquivos e tente novamente.",
    "Insufficient gateway access privileges": "Privilégios insuficientes de acesso ao gateway.",
    "Maximum file size limit reached": "Tamanho máximo do arquivo atingido.",
    "Username(s) must be a valid user(s)": "Os nomes de usuário devem ser usuários válidos.",
    "Site reference(s) must be a valid site(s)": "As referências de site devem ser sites válidos.",
    "Unable to send email, please verify the details and try again": "Não foi possível enviar o e-mail. Por favor, verifique os detalhes e tente novamente.",
    "Negative already exists": "Um valor negativo já existe. Por favor, verifique e faça as correções necessárias.",
    "Cannot delete a search owned by another user": "Não é possível excluir uma pesquisa de outro usuário.",
    "Invalid search": "Pesquisa inválida. Por favor, tente novamente com critérios válidos.",
    "Cannot delete the specified search, the search name cannot be found": "Não é possível excluir a pesquisa especificada. O nome da pesquisa não foi encontrado.",
    "Search parameter is too short": "Parâmetro de pesquisa muito curto. Por favor, insira termos de pesquisa mais específicos.",
    "Duplicate custom fields defined": "Campos personalizados duplicados definidos. Por favor, resolva as duplicidades.",
    "Cannot allocate selected users, insufficient privileges": "Não é possível alocar os usuários selecionados. Privilégios insuficientes.",
    "Allocated users have access to additional sites": "Usuários alocados têm acesso a sites adicionais.",
    "Allocated users have access to additional users": "Usuários alocados têm acesso a outros usuários adicionais.",
    "User with current role cannot be allocated users": "Usuários com o papel atual não podem alocar outros usuários.",
    "This site requires that your browser accept cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Este site requer que seu navegador aceite cookies para fazer login. Os cookies podem ser aceitos clicando em 'Eu aceito' abaixo.",
    "User requires at least one site reference or site group": "O usuário requer pelo menos uma referência de site ou grupo de sites.",
    "Allocated users have access to additional site groups": "Os usuários alocados têm acesso a grupos de sites adicionais.",
    "No statement found": "Nenhuma declaração encontrada.",
    "Data supplied has not been updated in MobilePay 3rd-party service": "Os dados fornecidos não foram atualizados no serviço de terceiros do MobilePay.",
    "Bypass": "Ignorar",
    "Insufficient access privileges": "Privilégios de acesso insuficientes.",
    "Coding error": "Erro de codificação detectado.",
    "Insufficient privileges": "Privilégios insuficientes para concluir esta ação.",
    "Invalid request": "Solicitação inválida. Por favor, verifique e tente novamente.",
    "Invalid field": "Campo inválido inserido. Por favor, verifique e corrija a entrada.",
    "Unknown site": "Site desconhecido.",
    "Banned card": "Cartão banido.",
    "XML element parse error": "Erro ao analisar o elemento XML. Por favor, verifique a estrutura do XML.",
    "Maestro must use SecureCode": "Transações Maestro devem usar o SecureCode para validação.",
    "Multiple email addresses must be separated with , or ;": "Vários endereços de e-mail devem ser separados por vírgulas (,) ou ponto e vírgula (;).",
    "Invalid site reference for alias": "Referência de site inválida para alias.",
    "Invalid version number": "Número de versão inválido. Por favor, insira um número de versão válido.",
    "Unknown user": "Usuário desconhecido. Por favor, verifique e tente novamente.",
    "Cannot determine account": "Não é possível determinar a conta.",
    "JSON element parse error": "Erro ao analisar o elemento JSON. Por favor, verifique a estrutura do JSON.",
    "Wallet type configuration error": "Erro de configuração do tipo de carteira.",
    "Wallet type not supported on this request": "Tipo de carteira não suportado para esta solicitação.",
    "The card number you have provided is incorrect, please verify your details and try again": "O número do cartão fornecido está incorreto. Por favor, verifique seus dados e tente novamente.",
    "The security code (CVV2) you have provided is incorrect, please verify your details and try again": "O código de segurança (CVV2) fornecido está incorreto. Por favor, verifique e tente novamente.",
    "The expiry date you have provided is incorrect, please verify your details and try again": "A data de validade fornecida está incorreta. Por favor, verifique seus dados e tente novamente.",
    "The expiry month you have provided is incorrect, please verify your details and try again": "O mês de validade fornecido está incorreto. Por favor, verifique e tente novamente.",
    "The expiry year you have provided is incorrect, please verify your details and try again": "O ano de validade fornecido está incorreto. Por favor, verifique e tente novamente.",
    "Unable to process your payment due to connection errors - request id mismatch, please try again": "Não é possível processar seu pagamento devido a erros de conexão. Divergência de ID de solicitação. Por favor, tente novamente.",
    "The issue number you have provided is incorrect, please verify your details and try again": "O número do problema fornecido está incorreto. Por favor, verifique e tente novamente.",
    "The payment type you have provided is incorrect, please verify your details and try again": "O tipo de pagamento fornecido está incorreto. Por favor, verifique e tente novamente.",
    "Unable to process your payment, please contact the website": "Não é possível processar seu pagamento. Por favor, entre em contato com o site para suporte.",
    "There are errors with these fields: {0}": "Há erros nestes campos: {0}",
    "No account found": "Nenhuma conta encontrada.",
    "Refund cannot be processed": "Reembolso não pode ser processado.",
    "Transaction de-activated": "Transação desativada.",
    "Socket receive error": "Erro ao receber dados do socket.",
    "Socket connection error": "Erro de conexão de socket.",
    "Socket closed": "Socket fechado.",
    "Invalid data received": "Dados inválidos recebidos.",
    "Invalid SQL": "Consulta SQL inválida.",
    "Timeout": "Tempo limite excedido.",
    "Invalid acquirer": "Detalhes do adquirente inválidos.",
    "Unable to connect to acquirer": "Não foi possível conectar ao adquirente.",
    "Invalid response from acquirer": "Resposta inválida do adquirente.",
    "No available transport": "Nenhum transporte disponível encontrado.",
    "File size too large": "Tamanho do arquivo muito grande.",
    "Socket send error": "Erro ao enviar dados pelo socket.",
    "Communication error": "Erro de comunicação.",
    "Proxy error": "Erro de proxy.",
    "Unable to process your payment due to connection errors, please verify your details and try again ({0})": "Não é possível processar seu pagamento devido a erros de conexão. Por favor, verifique seus dados e tente novamente ({0}).",
    "Unable to process your payment due to connection errors (HTTP response status {0}), please verify your details and try again ({1})": "Não é possível processar seu pagamento devido a erros de conexão (status de resposta HTTP {0}). Por favor, verifique seus dados e tente novamente ({1}).",
    "Wrong number of emails": "Número incorreto de endereços de e-mail fornecido.",
    "Bank System Error": "Erro do sistema bancário.",
    "Wrong number of transactions": "Número incorreto de transações fornecido.",
    "Invalid transport configuration": "Configuração de transporte inválida.",
    "No valid updates specified": "Nenhuma atualização válida especificada.",
    "Transaction reference not found": "Referência da transação não encontrada.",
    "Settlebaseamount too large": "O valor base do ajuste é muito grande.",
    "Transaction not updatable": "Transação não atualizável.",
    "No searchable filter specified": "Nenhum filtro de pesquisa especificado.",
    "Timeout Error": "Erro de tempo limite. Por favor, tente novamente.",
    "3-D Secure Transport Error": "Erro de transporte 3-D Secure.",
    "Unauthenticated": "Solicitação não autenticada.",
    "Site Suspended": "Site suspenso.",
    "No updates performed": "Nenhuma atualização realizada.",
    "Invalid Request": "Solicitação inválida.",
    "Invalid Response": "Resposta inválida.",
    "Invalid Acquirer": "Informações do adquirente inválidas.",
    "Invalid account data": "Dados da conta inválidos.",
    "Missing": "Informações ausentes.",
    "Payment Error": "Erro de pagamento.",
    "Invalid acquirer for 3-D Secure": "Adquirente inválido para 3-D Secure.",
    "Invalid payment type for 3-D Secure": "Tipo de pagamento inválido para 3-D Secure.",
    "Invalid updates specified": "Atualizações inválidas especificadas.",
    "Manual investigation required": "Investigação manual necessária.",
    "Invalid headers": "Cabeçalhos inválidos.",
    "Max fraudscore exceeded": "Pontuação máxima de fraude excedida.",
    "Invalid filters": "Filtros inválidos fornecidos.",
    "Merchant System Error": "Erro no sistema do comerciante.",
    "Your payment is being processed. Please wait...": "Seu pagamento está sendo processado. Por favor, aguarde...",
    "Cannot specify both requesttypedescription and requesttypedescriptions on a single request": "Não é possível especificar 'descrição do tipo de solicitação' e 'descrições do tipo de solicitação' em uma única solicitação.",
    "Acquirer missing original transaction data": "Dados da transação original ausentes do adquirente.",
    "Insufficient funds": "Fundos insuficientes.",
    "Unable to process due to scheme restrictions": "Não é possível processar devido a restrições do esquema.",
    "Failed Screening": "Falha na triagem.",
    "Unable to process due to restrictions": "Não é possível processar devido a restrições.",
    "Invalid process": "Processo inválido.",
    "Risk Referral": "Encaminhamento de risco necessário.",
    "Name Pick required": "Nome é necessário para seleção.",
    "Address Pick required": "Endereço é necessário para seleção.",
    "IP not in range": "Endereço IP fora do intervalo.",
    "Invalid button configuration": "Configuração de botão inválida.",
    "Unrecognised response from acquirer": "Resposta não reconhecida do adquirente.",
    "Decline": "Rejeição.",
    "Uncertain result": "Resultado incerto.",
    "Soft Decline": "Rejeição suave.",
    "Refer to Issuer": "Consulte o emissor para mais informações.",
    "Request is queued please check the transaction later for the status": "Solicitação está na fila. Por favor, verifique o status da transação mais tarde.",
    "Generic Retry": "Nova tentativa genérica.",
    "Soft Decline retry": "Nova tentativa de rejeição suave.",
    "There has been a problem with your payment, please verify your details and try again": "Houve um problema com seu pagamento. Por favor, verifique seus dados e tente novamente.",
    "Unknown error": "Erro desconhecido.",

    "Login": "Entrar",
    "Enter Your Email": "Insira seu e-mail",
    "Login With Phone Number": "Entrar com número de telefone",
    "Verify Using Sms/Email": "Verificar Usando Sms/Email",
    "Resend Code In 100 Seconds": "Reenviar código em 100 segundos",
    "Verify": "Verificar",
    "Verifying": "Verificando",
    "Last Week": "Semana passada",
    "Older Transaction": "Transação antiga",
    "Total": "Total",
    "Conversion Confirmed": "Conversão confirmada",
    "User Account Limit": "Limite de conta de usuário",
    "Monthly Balance Limit": "Limite mensal de saldo",
    "Total Limit": "Limite total",
    "Used": "Usado",
    "Remaining": "Restante",
    "Allowed Minimum Topup Amount": "Valor mínimo permitido de recarga",
    "Allowed Maximum Topup Amount": "Valor máximo permitido de recarga",
    "Incoming Limit": "Limite de entrada",
    "Daily limit": "Limite diário",
    "Monthly limit": "Limite mensal",
    "Yearly limit": "Limite anual",
    "Sending Limit": "Limite de envio",
    "Allowed Number Of Transactions": "Número permitido de transações",
    "Total Amount": "Quantia Total",
    "Amount To Send": "Valor a enviar",
    "Destination Amount": "Valor de destino",
    "Note": "Nota",
    "Revised Amount": "Valor revisado",
    "Save": "Salvar",
    "Date": "Data",
    "Action": "Ação",
    "Payment Scheduled At": "Pagamento agendado em",
    "Transaction Fee": "Taxa de transação",
    "Number Of Cycle": "Número de ciclos",
    "Subscription Starting Date": "Data de início da assinatura",
    "Until": "Até",
    "Next Transaction": "Próxima transação",
    "Pay Now": "Pague agora",
    "Transaction Method": "Método de transação",
    "Mobile Money Provider": "Provedor de dinheiro móvel",
    "Wallet Name": "Nome da carteira",
    "Wallet Number": "Número da carteira",
    "Crypto Currency": "Criptomoeda",
    "Wallet Address": "Endereço da carteira",
    "Select Document Type": "Selecionar tipo de documento",
    "Edit Document Number": "Editar número do documento",
    "My Withdrawals": "Meus saques",
    "Bargainable": "Negociável",
    "Description": "Descrição",
    "Attached Files": "Arquivos anexados",
    "Sent": "Enviado",
    "Accepted": "Aceito",
    "Decline": "Recusar",
    "Pending": "Pendente",
    "Overall Rating": "Avaliação geral",
    "No Review": "Sem avaliação",
    "0 Rating": "0 Avaliação",
    "Transaction Map": "Mapa de transações",
    "Send Money": "Enviar dinheiro",
    "Sending Method": "Método de envio",
    "Trouble Sending Money": "Problemas para enviar dinheiro",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "A taxa e o valor recebido podem variar dependendo do método de pagamento escolhido.",
    "Select Method": "Selecionar método",
    "Completed": "Concluído",
    "How Was Your Experience?": "Como foi sua experiência?",
    "Leave A Review": "Deixe uma avaliação",
    "Review History": "Histórico de avaliações",
    "Write A Review": "Escrever uma avaliação",
    "Send And Post": "Enviar e Publicar",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out A conversão de Cripto para Fiat é uma solução robusta e segura que converte de forma simples criptomoedas em moedas fiduciárias. Atende às diversas necessidades dos usuários, oferecendo uma infinidade de canais de pagamento adaptados às preferências individuais e locais.\"",
    "Review Created": "Revisão criada",
    "Transactions": "Transações",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Arraste/solte ou carregue um arquivo (Foto/Vídeo/Pdf)",
    "Max Size 1GB": "Tamanho máximo 1GB",
    "Title Description": "Descrição do título",
    "Add New": "Adicionar novo",
    "Discard": "Descartar",
    "Save And Post": "Salvar e publicar",
    "Personal Account": "Conta pessoal",
    "Conversion": "Conversão",
    "Spent In Total": "Total gasto",
    "International": "Internacional",
    "Recharge": "Recarregar",
    "Top Up Wallet To Wallet": "Recarregar de carteira para carteira",
    "PayPal": "PayPal",
    "Instapay Wallet": "Carteira Instapay",
    "Not Connected": "Não Conectado",
    "More": "Mais",
    "Less": "Menos",
    "Profile Reports": "Relatórios de perfil",
    "Timezone": "Fuso horário",
    "Cancel": "Cancelar",
    "Full Name": "Nome completo",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Documentos Suplementares",
    "Profile Complete": "Perfil completo",
    "Verified": "Verificado",
    "Bank Account": "Conta bancária",
    "Mobile Wallet": "Carteira móvel",
    "Cash Pickup": "Retirada em dinheiro",
    "Add": "Adicionar",
    "Configured": "Configurado",
    "Email": "E-mail",
    "Download Data": "Baixar dados",
    "Enter The Amount": "Digitar o Valor",
    "They Receive": "Eles Recebem",
    "Where Do You Want To Send Money": "Onde você deseja enviar dinheiro",
    "Bank Transfer": "Transferência bancária",
    "Receiving Method": "Método de recebimento",
    "Payer": "Pagador",
    "Minimum Amount": "Valor mínimo",
    "Maximum Amount": "Quantia Máxima",
    "Search": "Pesquisar",
    "Show More": "Mostrar Mais",
    "Select Remark / Reasons (All)": "Selecionar Observação / Razões (Todos)",
    "Attach Files": "Anexar Arquivos",
    "Holiday List": "Lista de Férias",
    "No Data To Show": "Nenhum Dado para Exibir",
    "Threshold Limits": "Limites de Limite",
    "Amount Limits": "Limites de Quantia",
    "OTP Verification Code": "Código de Verificação OTP",
    "Verify Using Authenticator": "Verificar Usando Authenticator",
    "Sms/Email Verification Code": "Código de Verificação Sms/Email",
    "Invite Them": "Convidá-los",
    "Lookup": "Procurar",
    "Invite Friend": "Convidar Amigo",
    "Invite Via Phone Number": "Convidar por Número de Telefone",
    "Invite Via Email Address": "Convidar por Endereço de Email",
    "Next": "Próximo",
    "Back": "Voltar",
    "Invite Via Email": "Convidar por Email",
    "Available Currency": "Moeda Disponível",
    "Add Remarks And Comments": "Adicionar Observações e Comentários",
    "Recipient Details": "Detalhes do Destinatário",
    "Go Back": "Voltar",
    "Trouble Requesting Money": "Problema ao Solicitar Dinheiro",
    "Instant": "Instantâneo",
    "Subscription": "Assinatura",
    "Schedule": "Agendar",
    "Choose Request Type": "Escolher Tipo de Solicitação",
    "Mobile Airtime": "Tempo de Chamada Móvel",
    "Incorrect Phone Number": "Número de Telefone Incorreto",
    "Make Sure You Have Entered A Correct Phone Number.": "Certifique-se de Ter Inserido um Número de Telefone Correto.",
    "Close": "Fechar",
    "Operator": "Operador",
    "Recipient Mobile Number": "Número de Telefone do Destinatário",
    "Amount": "Quantia",
    "Service": "Serviço",
    "Destination": "Destino",
    "Top Up Summary": "Resumo do Recarregamento",
    "Pakistan": "Paquistão",
    "Daily Sending Limit Exceeded": "Limite Diário de Envio Excedido",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Envie uma cotação detalhada para o seu cliente ou comprador. Apresente seus serviços, produtos ou ofertas de maneira profissional com uma cotação personalizada.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Os pagamentos feitos em dias úteis antes das 15:00 serão creditados na conta bancária do destinatário até a meia-noite do mesmo dia.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Configure suas contas de recebimento em até três países e retire imediatamente fundos de solicitações de pagamento aprovadas para seus canais de pagamento ativados.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "O Reino Kemit reserva-se o direito de solicitar qualquer documento adicional (Diligência Devida Aprimorada - DDA) ou mais informações necessárias para realizar verificações completas, de acordo com suas obrigações legais.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Deve ser um documento de identificação emitido pelo governo com foto. Todos os documentos de identificação devem ser escritos no alfabeto latino. Se não for, é necessário uma tradução certificada.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Exiba seu trabalho, amplie seu alcance. 'Meu Portfólio' é sua tela digital no Instapay. Carregue e exiba uma ampla gama de conteúdo - de imagens e vídeos cativantes a PDFs informativos. Seja você um artista, freelancer ou empresário, esse recurso permite apresentar seu trabalho ou projetos de forma visualmente atraente.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Compartilhe seu código QR ou link de pagamento para ser pago instantaneamente na sua Insta Wallet.",
    "Afghanistan": "Afeganistão",
    "Aland Islands": "Ilhas Áland",
    "Albania": "Albânia",
    "Algeria": "Argélia",
    "American Samoa": "Samoa Americana",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguila",
    "Antigua and Barbuda": "Antígua e Barbuda",
    "Argentina": "Argentina",
    "Armenia": "Armênia",
    "Aruba": "Aruba",
    "Australia": "Austrália",
    "Austria": "Áustria",
    "Azerbaijan": "Azerbaijão",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrein",
    "Bangladesh": "Bangladesh",
    "Barbados": "Barbados",
    "Belarus": "Bielorrússia",
    "Belgium": "Bélgica",
    "Belize": "Belize",
    "Benin": "Benim",
    "Bermuda": "Bermudas",
    "Bhutan": "Butão",
    "Bolivia (Plurinational State of)": "Bolívia (Estado Plurinacional da)",
    "Bonaire - Sint Eustatius and Saba": "Bonaire, Sint Eustáquio e Saba",
    "Bosnia and Herzegovina": "Bósnia e Herzegovina",
    "Botswana": "Botsuana",
    "Brazil": "Brasil",
    "British Indian Ocean Territory": "Território Britânico do Oceano Índico",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgária",
    "Burkina Faso": "Burquina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Cabo Verde",
    "Cambodia": "Camboja",
    "Cameroon": "Camarões",
    "Canada": "Canadá",
    "Cayman Islands": "Ilhas Cayman",
    "Central African Republic": "República Centro-Africana",
    "Chad": "Chade",
    "Chile": "Chile",
    "China": "China",
    "Christmas Island": "Ilha Christmas",
    "Cocos (Keeling) Islands": "Ilhas Cocos (Keeling)",
    "Colombia": "Colômbia",
    "Comoros": "Comores",
    "Congo": "Congo",
    "Congo - Democratic Republic of the": "Congo - República Democrática do",
    "Cook Islands": "Ilhas Cook",
    "Costa Rica": "Costa Rica",
    "Cote d'Ivoire": "Costa do Marfim",
    "Croatia": "Croácia",
    "Cuba": "Cuba",
    "Curacao": "Curaçao",
    "Cyprus": "Chipre",
    "Czech Republic": "República Checa",
    "Denmark": "Dinamarca",
    "Djibouti": "Djibuti",
    "Dominica": "Dominica",
    "Dominican Republic": "República Dominicana",
    "Ecuador": "Equador",
    "Egypt": "Egito",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Guiné Equatorial",
    "Eritrea": "Eritreia",
    "Estonia": "Estônia",
    "Eswatini": "Eswatini",
    "Ethiopia": "Etiópia",
    "Falkland Islands (Malvinas)": "Ilhas Malvinas",
    "Faroe Islands": "Ilhas Faroe",
    "Fiji": "Fiji",
    "Finland": "Finlândia",
    "France": "França",
    "French Polynesia": "Polinésia Francesa",
    "French Southern Territories": "Territórios Austrais e Antárticos Franceses",
    "Gabon": "Gabão",
    "Gambia": "Gâmbia",
    "Georgia": "Geórgia",
    "Germany": "Alemanha",
    "Ghana": "Gana",
    "Gibraltar": "Gibraltar",
    "Greece": "Grécia",
    "Greenland": "Groenlândia",
    "Grenada": "Granada",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea (Conakry)": "Guiné (Conacri)",
    "Guinea-Bissau": "Guiné-Bissau",
    "Guyana": "Guiana",
    "Haiti": "Haiti",
    "Heard Island and McDonald Islands": "Ilhas Heard e McDonald",
    "Holy See": "Vaticano",
    "Honduras": "Honduras",
    "Hong Kong": "Hong Kong",
    "Hungary": "Hungria",
    "Iceland": "Islândia",
    "India": "Índia",
    "Indonesia": "Indonésia",
    "Iran (Islamic Republic of)": "Irã (República Islâmica)",
    "Iraq": "Iraque",
    "Ireland": "Irlanda",
    "Isle of Man": "Ilha de Man",
    "Israel": "Israel",
    "Italy": "Itália",
    "Jamaica": "Jamaica",
    "Japan": "Japão",
    "Jersey": "Jersey",
    "Jordan": "Jordânia",
    "Kazakhstan": "Cazaquistão",
    "Kenya": "Quênia",
    "Kiribati": "Quiribati",
    "Korea (Democratic People's Republic of)": "Coreia (República Popular Democrática)",
    "Korea - Republic of": "Coreia - República da",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Quirguistão",
    "Lao People's Democratic Republic": "República Democrática Popular do Laos",
    "Latvia": "Letônia",
    "Lebanon": "Líbano",
    "Lesotho": "Lesoto",
    "Liberia": "Libéria",
    "Libya": "Líbia",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Lituânia",
    "Luxembourg": "Luxemburgo",
    "Macao": "Macau",
    "Madagascar": "Madagáscar",
    "Malawi": "Malawi",
    "Malaysia": "Malásia",
    "Maldives": "Maldivas",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Ilhas Marshall",
    "Mauritania": "Mauritânia",
    "Mauritius": "Maurício",
    "Mexico": "México",
    "Micronesia (Federated States of)": "Micronésia (Estados Federados)",
    "Moldova - Republic of": "Moldávia - República da",
    "Monaco": "Mônaco",
    "Mongolia": "Mongólia",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Morocco": "Marrocos",
    "Mozambique": "Moçambique",
    "Myanmar": "Mianmar",
    "Namibia": "Namíbia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Países Baixos",
    "New Caledonia": "Nova Caledônia",
    "New Zealand": "Nova Zelândia",
    "Nicaragua": "Nicarágua",
    "Niger": "Níger",
    "Nigeria": "Nigéria",
    "Niue": "Niue",
    "Norfolk Island": "Ilha Norfolk",
    "North Macedonia": "Macedônia do Norte",
    "Northern Mariana Islands": "Ilhas Marianas do Norte",
    "Norway": "Noruega",
    "Oman": "Omã",
    "Pakistan": "Paquistão",
    "Palau": "Palau",
    "Palestine - State of": "Palestina - Estado da",
    "Panama": "Panamá",
    "Papua New Guinea": "Papua-Nova Guiné",
    "Paraguay": "Paraguai",
    "Peru": "Peru",
    "Philippines": "Filipinas",
    "Pitcairn": "Pitcairn",
    "Poland": "Polônia",
    "Portugal": "Portugal",
    "Puerto Rico": "Porto Rico",
    "Qatar": "Catar",
    "Romania": "Romênia",
    "Russian Federation": "Federação Russa",
    "Rwanda": "Ruanda",
    "Saint Barth\u00e9lemy": "São Bartolomeu",
    "Saint Helena - Ascension and Tristan da Cunha": "Santa Helena - Ascensão e Tristão da Cunha",
    "Saint Kitts and Nevis": "São Cristóvão e Nevis",
    "Saint Lucia": "Santa Lúcia",
    "Saint Martin (French part)": "São Martinho (parte francesa)",
    "Saint Vincent and the Grenadines": "São Vicente e Granadinas",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "São Tomé e Príncipe",
    "Senegal": "Senegal",
    "Serbia": "Sérvia",
    "Seychelles": "Seicheles",
    "Sierra Leone": "Serra Leoa",
    "Singapore": "Cingapura",
    "Sint Maarten (Dutch part)": "Sint Maarten (parte holandesa)",
    "Slovakia": "Eslováquia",
    "Slovenia": "Eslovênia",
    "Solomon Islands": "Ilhas Salomão",
    "Somalia": "Somália",
    "South Africa": "África do Sul",
    "South Georgia and the South Sandwich Islands": "Geórgia do Sul e Ilhas Sandwich do Sul",
    "South Sudan": "Sudão do Sul",
    "Spain": "Espanha",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudão",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard e Jan Mayen",
    "Sweden": "Suécia",
    "Switzerland": "Suíça",
    "Syrian Arab Republic": "Síria",
    "Tajikistan": "Tajiquistão",
    "United Republic of Tanzania": "República Unida da Tanzânia",
    "Thailand": "Tailândia",
    "Timor-Leste": "Timor-Leste",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trindade e Tobago",
    "Tunisia": "Tunísia",
    "Turkey": "Turquia",
    "Turkmenistan": "Turcomenistão",
    "Turks and Caicos Islands": "Ilhas Turks e Caicos",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ucrânia",
    "United Arab Emirates": "Emirados Árabes Unidos",
    "United Kingdom of Great Britain and Northern Ireland": "Reino Unido da Grã-Bretanha e Irlanda do Norte",
    "United States of America": "Estados Unidos da América",
    "United States Minor Outlying Islands": "Ilhas Menores Distantes dos Estados Unidos",
    "Uruguay": "Uruguai",
    "Uzbekistan": "Uzbequistão",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Venezuela (República Bolivariana da)",
    "Viet Nam": "Vietnã",
    "Virgin Islands (British)": "Ilhas Virgens Britânicas",
    "Virgin Islands (U.S.)": "Ilhas Virgens Americanas",
    "Wallis and Futuna": "Wallis e Futuna",
    "Yemen": "Iémen",
    "Zambia": "Zâmbia",
    "Zimbabwe": "Zimbábue",
    "Taiwan": "Taiwan",
    "Palestine": "Palestina",
    "Western Sahara": "Saara Ocidental",
    "Kosovo": "Kosovo",
    "South Ossetia": "Ossetia do Sul",
    "Abkhazia": "Abkházia",
    "Nagorno-Karabakh (Artsakh)": "Nagorno-Karabakh (Artsakh)",
    "Transnistria": "Transnístria",
    "Somaliland": "Somalilândia",
    "Northern Cyprus": "Chipre do Norte",
    "Saudi Arabia": "Arábia Saudita",
    "Yugoslavia": "Iugoslávia",

    "Login": "Entrar",
    "Enter Your Email": "Insira seu e-mail",
    "Login With Phone Number": "Entrar com número de telefone",
    "Verify Using Sms/Email": "Verificar Usando Sms/Email",
    "Resend Code In 100 Seconds": "Reenviar código em 100 segundos",
    "Verify": "Verificar",
    "Verifying": "Verificando",
    "Last Week": "Semana passada",
    "Older Transaction": "Transação antiga",
    "Total": "Total",
    "Conversion Confirmed": "Conversão confirmada",
    "User Account Limit": "Limite de conta de usuário",
    "Monthly Balance Limit": "Limite mensal de saldo",
    "Total Limit": "Limite total",
    "Used": "Usado",
    "Remaining": "Restante",
    "Allowed Minimum Topup Amount": "Valor mínimo permitido de recarga",
    "Allowed Maximum Topup Amount": "Valor máximo permitido de recarga",
    "Incoming Limit": "Limite de entrada",
    "Daily Limit": "Limite diário",
    "Monthly Limit": "Limite mensal",
    "Yearly Limit": "Limite anual",
    "Sending Limit": "Limite de envio",
    "Allowed Number Of Transactions": "Número permitido de transações",
    "Total Amount": "Quantia Total",
    "Amount To Send": "Valor a enviar",
    "Destination Amount": "Valor de destino",
    "Note": "Nota",
    "Revised Amount": "Valor revisado",
    "Save": "Salvar",
    "Date": "Data",
    "Action": "Ação",
    "Payment Scheduled At": "Pagamento agendado em",
    "Transaction Fee": "Taxa de transação",
    "Number Of Cycle": "Número de ciclos",
    "Subscription Starting Date": "Data de início da assinatura",
    "Until": "Até",
    "Next Transaction": "Próxima transação",
    "Pay Now": "Pague agora",
    "Transaction Method": "Método de transação",
    "Mobile Money Provider": "Provedor de dinheiro móvel",
    "Wallet Name": "Nome da carteira",
    "Wallet Number": "Número da carteira",
    "Crypto Currency": "Criptomoeda",
    "Wallet Address": "Endereço da carteira",
    "Select Document Type": "Selecionar tipo de documento",
    "Edit Document Number": "Editar número do documento",
    "My Withdrawals": "Meus saques",
    "Bargainable": "Negociável",
    "Description": "Descrição",
    "Attached Files": "Arquivos anexados",
    "Sent": "Enviado",
    "Accepted": "Aceito",
    "Decline": "Recusar",
    "Pending": "Pendente",
    "Overall Rating": "Avaliação geral",
    "No Review": "Sem avaliação",
    "0 Rating": "0 Avaliação",
    "Transaction Map": "Mapa de transações",
    "Send Money": "Enviar dinheiro",
    "Sending Method": "Método de envio",
    "Trouble Sending Money": "Problemas para enviar dinheiro",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "A taxa e o valor recebido podem variar dependendo do método de pagamento escolhido.",
    "Select Method": "Selecionar método",
    "Completed": "Concluído",
    "How Was Your Experience?": "Como foi sua experiência?",
    "Leave A Review": "Deixe uma avaliação",
    "Review History": "Histórico de avaliações",
    "Write A Review": "Escrever uma avaliação",
    "Send And Post": "Enviar e Publicar",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out A conversão de Cripto para Fiat é uma solução robusta e segura que converte de forma simples criptomoedas em moedas fiduciárias. Atende às diversas necessidades dos usuários, oferecendo uma infinidade de canais de pagamento adaptados às preferências individuais e locais.\"",
    "Review Created": "Revisão criada",
    "Transactions": "Transações",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Arraste/solte ou carregue um arquivo (Foto/Vídeo/Pdf)",
    "Max Size 1GB": "Tamanho máximo 1GB",
    "Title Description": "Descrição do título",
    "Add New": "Adicionar novo",
    "Discard": "Descartar",
    "Save And Post": "Salvar e publicar",
    "Personal Account": "Conta pessoal",
    "Conversion": "Conversão",
    "Spent In Total": "Total gasto",
    "International": "Internacional",
    "Recharge": "Recarregar",
    "Top Up Wallet To Wallet": "Recarregar de carteira para carteira",
    "PayPal": "PayPal",
    "Instapay Wallet": "Carteira Instapay",
    "Not Connected": "Não Conectado",
    "More": "Mais",
    "Less": "Menos",
    "Profile Reports": "Relatórios de perfil",
    "Timezone": "Fuso horário",
    "Cancel": "Cancelar",
    "Full Name": "Nome completo",
    "Google Authenticator": "Google Authenticator",
    "Documents Supplémentaires": "Documentos Suplementares",
    "Profile Complete": "Perfil completo",
    "Verified": "Verificado",
    "Bank Account": "Conta bancária",
    "Mobile Wallet": "Carteira móvel",
    "Cash Pickup": "Retirada em dinheiro",
    "Add": "Adicionar",
    "Configured": "Configurado",
    "Email": "E-mail",
    "Download Data": "Baixar dados",
    "Enter The Amount": "Digitar o Valor",
    "They Receive": "Eles Recebem",
    "Where Do You Want To Send Money": "Onde você deseja enviar dinheiro",
    "Bank Transfer": "Transferência bancária",
    "Receiving Method": "Método de recebimento",
    "Payer": "Pagador",
    "Minimum Amount": "Valor mínimo",
    "Maximum Amount": "Quantia Máxima",
    "Search": "Pesquisar",
    "Show More": "Mostrar Mais",
    "Select Remark / Reasons (All)": "Selecionar Observação / Razões (Todos)",
    "Attach Files": "Anexar Arquivos",
    "Holiday List": "Lista de Férias",
    "No Data To Show": "Nenhum Dado para Exibir",
    "Threshold Limits": "Limites de Limite",
    "Amount Limits": "Limites de Quantia",
    "OTP Verification Code": "Código de Verificação OTP",
    "Verify Using Authenticator": "Verificar Usando Authenticator",
    "Sms/Email Verification Code": "Código de Verificação Sms/Email",
    "Invite Them": "Convidá-los",
    "Lookup": "Procurar",
    "Invite Friend": "Convidar Amigo",
    "Invite Via Phone Number": "Convidar por Número de Telefone",
    "Invite Via Email Address": "Convidar por Endereço de Email",
    "Next": "Próximo",
    "Back": "Voltar",
    "Invite Via Email": "Convidar por Email",
    "Available Currency": "Moeda Disponível",
    "Add Remarks And Comments": "Adicionar Observações e Comentários",
    "Recipient Details": "Detalhes do Destinatário",
    "Go Back": "Voltar",
    "Trouble Requesting Money": "Problema ao Solicitar Dinheiro",
    "Instant": "Instantâneo",
    "Subscription": "Assinatura",
    "Schedule": "Agendar",
    "Choose Request Type": "Escolher Tipo de Solicitação",
    "Mobile Airtime": "Tempo de Chamada Móvel",
    "Incorrect Phone Number": "Número de Telefone Incorreto",
    "Make Sure You Have Entered A Correct Phone Number.": "Certifique-se de Ter Inserido um Número de Telefone Correto.",
    "Close": "Fechar",
    "Operator": "Operador",
    "Recipient Mobile Number": "Número de Telefone do Destinatário",
    "Amount": "Quantia",
    "Service": "Serviço",
    "Destination": "Destino",
    "Top Up Summary": "Resumo do Recarregamento",
    "Daily Sending Limit Exceeded": "Limite Diário de Envio Excedido",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Envie uma cotação detalhada para o seu cliente ou comprador. Apresente seus serviços, produtos ou ofertas de maneira profissional com uma cotação personalizada.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Os pagamentos feitos em dias úteis antes das 15:00 serão creditados na conta bancária do destinatário até a meia-noite do mesmo dia.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Configure suas contas de recebimento em até três países e retire imediatamente fundos de solicitações de pagamento aprovadas para seus canais de pagamento ativados.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "O Reino Kemit reserva-se o direito de solicitar qualquer documento adicional (Diligência Devida Aprimorada - DDA) ou mais informações necessárias para realizar verificações completas, de acordo com suas obrigações legais.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Deve ser um documento de identificação emitido pelo governo com foto. Todos os documentos de identificação devem ser escritos no alfabeto latino. Se não for, é necessário uma tradução certificada.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Exiba seu trabalho, amplie seu alcance. 'Meu Portfólio' é sua tela digital no Instapay. Carregue e exiba uma ampla gama de conteúdo - de imagens e vídeos cativantes a PDFs informativos. Seja você um artista, freelancer ou empresário, esse recurso permite apresentar seu trabalho ou projetos de forma visualmente atraente.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Compartilhe seu código QR ou link de pagamento para ser pago instantaneamente na sua Insta Wallet.",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "Você tem 15 minutos para concluir a transação.",
    "Time Remaining": "Conclua dentro do prazo para evitar a expiração do link.",
    "Please Process The Payment Via Your Selected Payment Type": "Por favor, processe o pagamento via o tipo de pagamento selecionado.",
    "Top-Up Wallet": "Tipo de pagamento",
    "Confirm Your Payment": "RECARREGAR CARTEIRA",
    "You Will Get": "Confirme seu pagamento",
    "Amount To Pay": "Você receberá",
    "Proceed To Payment": "Valor a pagar",
    "Load/Deposit Money To The Wallet": "Prosseguir para o pagamento",
    "Withdraw": "Carregar/depositar dinheiro na carteira",
    "Elevate Your Business With InstaPay": "Retirar",
    "Higher Balances & Transaction Limits": "Eleve seu negócio com InstaPay",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Saldos maiores e limites de transações",
    "Full Feature Access": "Desbloqueie a liberdade de gerenciar saldos maiores e processar volumes maiores de transações, com a confiabilidade que você espera da InstaPay.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Acesso total às funcionalidades",
    "Global Reach": "Alcance Global",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "Expanda seu negócio sem fronteiras. O InstaPay conecta você a clientes e parceiros em todo o mundo, tornando as transações globais mais fáceis do que nunca.",
    "Advanced Security": "Segurança Avançada",
    "Business Registration Available Soon\n": "Registro de negócios disponível em breve.",
    "Amount After Fee": "Montante Após Taxa",
    "Allowed Maximum Amount Per Transaction": "Montante Máximo Permitido Por Transação",
    "Transfer": "Transferência",
    "Uncategorized": "Sem Categoria",
    "Currency ISO Code & Amount": "Código ISO da moeda e valor",
    "Choose The Top-Up Channel": "Escolha o canal de recarga",
    "You Selected": "que você selecionou",
    "Payout Channel": "canal de pagamento",
    "Your Details": "seus detalhes",
    "Detailed Confirmed": "detalhes confirmados",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Potencialize suas finanças atualizando para uma Conta InstaPay Business. Experimente todo o poder do InstaPay com recursos aprimorados projetados especificamente para o crescimento dos negócios.",
    "Profile Picture": "Foto de perfil",
    "Add Photo Or Logo": "Adicionar foto ou logotipo",
    "First Name": "Nome",
    "Last Name": "Sobrenome",
    "Enter Your First Name": "Insira seu nome",
    "Enter Your Last Name": "Insira seu sobrenome",
    "Email (Optional)": "E-mail (opcional)",
    "Password Must": "Senha deve",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Será preenchido automaticamente se você estiver se inscrevendo com um link de convite.",
    "Change Number": "Alterar número",
    "Search Country": "Buscar país",
    "Select From The List": "Selecione da lista",
    "Received Amount": "Valor recebido",

    "null": null,
    "Processing": "Processando",
    "Verify Using Sms/Email": "Verificar usando SMS/Email",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Já existe",
    "You Can Have Only 8 Wallets": "Você pode ter apenas 8 carteiras",
    "Save": "Salvar",
    "0 File": "0 arquivo",
    "Search From The List": "Pesquisar na lista",
    "Amount To Send": "Quantidade a enviar",
    "Wallet To Wallet": "Carteira para Carteira",
    "TOP-UP WALLET ": "RECARREGAR CARTEIRA",
    "Enter The Amount": "Insira o valor",
    "Load/Deposit Money To The Wallet": "Carregar/Depositar dinheiro na carteira",
    "Choose The Top-Up Channel": "Escolha o canal de recarga",
    "Paypal": "Paypal",
    "You Will Get": "Você receberá",
    "Amount To Pay": "Quantidade a pagar",
    "Card Holder Name ": "Nome do titular do cartão",
    "Card Number": "Número do cartão",
    "CVC": "CVC",
    "MM/YY": "MM/AA",
    "Your Details ": "Seus detalhes",
    "You Selected": "Você selecionou",
    "Detailed Confirmed": "Detalhado confirmado",
    "Transaction Failed": "Transação falhou",
    "Something Went Wrong. Try Again.": "Algo deu errado. Tente novamente.",
    "Convert Funds": "Converter fundos",
    "How Would You Like To Cashout?:": "Como você gostaria de sacar?:",
    "Default Receiving Account": "Conta receptora padrão",
    "Specify Receiving Account": "Especifique a conta receptora",
    "Which Receiving Account Would You Want Use?:": "Qual conta receptora você gostaria de usar?:",
    "Pakistan": "Paquistão",
    "Bank Mobile": "Banco móvel",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Nenhum",
    "Request": "Solicitar",
    "Subscription Payment": "Pagamento por assinatura",
    "Is not supported by Paypal, USD will be used as the default currency.": "Não é suportado pelo PayPal, USD será usado como moeda padrão.",
    "Review Payment": "Revisar pagamento",
    "Google Authenticator": "autenticador do google",
    "Scheduled Has Been Cancelled": "Agendamento foi cancelado",
    "Select Country First": "Selecione o país primeiro",
    "Select Gender": "Selecione o gênero",
    "Select Occupation": "Selecione a ocupação",
    "IBAN and SWIFT/BIC": "IBAN e SWIFT/BIC",
    "Mobile Money Provider": "Provedor de dinheiro móvel",
    "Wallet Name": "Nome da carteira",
    "Wallet Number ": "Número da carteira",
    "Crypto Currency": "Criptomoeda",
    "Wallet Address ": "Endereço da carteira",
    "Select Document Type ": "Selecione o tipo de documento",
    "Enter Document Number": "Insira o número do documento",
    "Msisdn": "Msisdn",
    "Edit ": "Editar",
    "My Withdrawls": "Meus saques",
    "Declined": "Recusado",
    "Accepted": "Aceito",
    "Overall Ratings": "Avaliações gerais",
    "0 Ratings": "0 avaliações",
    "No Reviews": "Sem comentários",
    "Send Money": "Enviar dinheiro",
    "Trouble Sending Money?": "Problemas para enviar dinheiro?",
    "Payment Request": "Solicitação de pagamento",
    "How Was Your Experience?": "Como foi sua experiência?",
    "Leave A Review": "Deixe uma avaliação",
    "Write Review Message": "Escreva uma mensagem de avaliação",
    "Send And Post": "Enviar e postar",
    "You Rated": "Você avaliou",
    "Review History": "Histórico de avaliações",
    "Max Size 1GB": "Tamanho máximo 1GB",
    "Title": "Título",
    "Description": "Descrição",
    "Add New": "Adicionar novo",
    "My Payment Address": "Meu endereço de pagamento",
    "Personal Account": "Conta pessoal",
    "Updated Successfully": "Atualizado com sucesso",
    "Spent In": "Gasto em",
    "TOP UP": "Recarregar",
    "Quotation": "Cotação",
    "Amount": "Quantia",
    "Not Connected": "Não conectado",
    "More": "Mais",
    "Less": "Menos",
    "Reports": "Relatórios",
    "User Profile": "Perfil do usuário",
    "Cancel": "Cancelar",
    "Saving": "Salvando",
    "Confirm ": "confirmar",
    "Verify ": "Verificar",
    "Delete Country": "Excluir país",
    "Bank Account": "Conta bancária",
    "Cash Pickup": "Retirada de dinheiro",
    "Mobile Wallet ": "Carteira móvel",
    "Delete Country Withdrawals": "Excluir retiradas do país",
    "Yes , Delete It": "Sim, Exclua",
    "E-sim": "E-sim",
    "Utilities": "Utilidades",
    "Searching": "Pesquisando",
    "Recipient Mobile Number": "Número de telefone do destinatário",
    "TOP-UP SUMMARY": "RESUMO DA RECARGA",
    "Trouble Requesting Money?": "Problemas para solicitar dinheiro?",
    "Choose The Beneficiary": "Escolha o beneficiário",
    "Enter The amount": "Insira o valor",
    "You selected": "Você selecionou",
    "Timezone": "Fuso horário",
    "Starting From": "Começando de",
    "Recurring Cycle": "Ciclo recorrente",
    "Until I stop": "Até eu parar",
    "Schedule To": "Agendar para",
    "Schedule at": "Agendar em",
    "Selected time must be at least 5 minutes from now.": "O horário selecionado deve ser pelo menos 5 minutos a partir de agora.",
    "Is this your Location?": "Esta é sua localização?",
    "Yes ": "Sim",
    "Select Remark Reason": "Selecione o motivo do comentário",
    "Attach File": "Anexar arquivo",
    "Invite via Phone Number": "Convidar por número de telefone",
    "Invite Friend": "Convidar amigo",
    "Invite via Email Address": "Convidar por endereço de e-mail",
    "Invite via": "Convidar via",
    "Phone": "Telefone:",
    "Message": "Mensagem:",
    "Send": "Enviar",
    "Invited Succesfully!": "Convidado com sucesso!",
    "Email": "E-mail",
    "Bank Transfer": "Transferência bancária",
    "Mobile Wallet": "Carteira móvel",
    "Select The Bank": "Selecionar o banco",
    "Select The Payer": "Selecionar o pagador",
    "Min Amount:": "Quantia mínima:",
    "Max Amount": "Quantia máxima",
    "Other Beneficiaries:": "Outros beneficiários:",
    "Back ": "Voltar",
    "Next": "Próximo",
    "Lookup": "Procurar",
    "Invite Them": "Convidá-los",
    "Delete Channel ": "Excluir canal",
    "Set As Default": "Definir como padrão",
    "See More": "Ver mais",
    "Session Details": "Detalhes da sessão",
    "Platform": "Plataforma",
    "Windows": "Windows",
    "Browser Name": "Nome do navegador",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Versão do navegador",
    "IP Address": "Endereço IP",
    "Karachi": "Karachi",
    "State": "Estado",
    "Sindh": "Sindh",
    "Active": "Ativo",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Esta carteira de moeda está atualmente bloqueada/desativada e os fundos não podem ser adicionados.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Esta carteira de moeda está atualmente bloqueada/desativada e os fundos não podem ser convertidos.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Esta carteira de moeda está atualmente bloqueada/desativada e você não pode retirar o saldo.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Esta carteira de moeda está atualmente bloqueada/desativada e você não pode baixar o extrato.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Esta carteira de moeda está atualmente configurada como a sua padrão e não pode ser bloqueada. Para continuar, configure uma carteira de moeda diferente como a sua padrão primeiro.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Esta carteira de moeda está atualmente configurada como a sua padrão e não pode ser desativada. Para continuar, configure uma carteira de moeda diferente como a sua padrão primeiro.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Tem certeza de que deseja excluir os detalhes de retirada deste país?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Configure suas contas de recebimento em até três países e retire instantaneamente os fundos das solicitações de pagamento aprovadas para seus canais de pagamento ativados.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Nenhum beneficiário encontrado no Paquistão com os detalhes do Allied Bank Limited",
    "Invitation Message Has Been Successfully Sent To": "A mensagem de convite foi enviada com sucesso para",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "A taxa e o valor recebido podem variar dependendo do método de pagamento escolhido.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Paquistão",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "Compartilhe seu código QR ou link de pagamento para receber pagamentos instantaneamente na sua carteira Insta.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Arraste/Coloque ou carregue um arquivo (Foto/Vídeo/PDF)",
    "Login Again": "faça login novamente",
    "Unblock The Wallet": "desbloquear a carteira",
    "Invalid Amount": "quantidade inválida",
    "Add Payment Card": "adicionar cartão de pagamento",
    "Save Details To Use Next Time": "salvar detalhes para usar na próxima vez",
    "Paid": "pago",
    "Started On": "iniciado em",
    "Num Of Cycles": "número de ciclos",
    "Ends On": "termina em",
    "Next Payment At": "próximo pagamento em",
    "Beneficiary": "beneficiário",
    "Recieved Quotation": "cotação recebida",
    "Sent Quotation": "cotação enviada",
    "Decline Offer": "recusar oferta",
    "Are You Sure That You Want To Decline The Offer?": "tem certeza de que deseja recusar a oferta?",
    "Decline The Offer": "recusar a oferta",
    "Bargain Offer": "oferta negociada",
    "Enter Counter Amount": "digitar valor da contraoferta",
    "Custom Amount": "valor personalizado",
    "Send The Offer": "enviar a oferta",
    "The Offer Is Sent": "a oferta foi enviada",
    "Countered Offer": "oferta contra-oferta",
    "Applied": "aplicado",
    "Accept Quotation": "aceitar cotação",
    "Quotation Not Found Or Already Exist": "Cotação não encontrada ou já existente",
    "Recieved Request": "solicitação recebida",
    "Sent Request": "solicitação enviada",
    "Search Request": "buscar solicitação",
    "Search Quotation": "buscar cotação",
    "QR Status": "status do QR",
    "QR Code Description": "descrição do código QR",
    "Download Your QR Code": "Baixe seu código QR",
    "Your QR Code": "seu código QR",
    "Your QR Code Poster": "seu cartaz de código QR",
    "Download  ": "baixar",
    "Select Relation": "selecionar relação",
    "Nominee Permanent Address": "endereço permanente do nomeado",
    "Enter Your Message": "digitar sua mensagem",
    "Select": "selecionar",
    "Select File Type": "selecionar tipo de arquivo",
    "Send TOP-UP And E-Sim": "Enviar recarga e E-sim",
    "Search Users": "buscar usuários",
    "Select Payment Request Type": "selecionar tipo de solicitação de pagamento",
    "Wallet To Wallet Transfer": "transferência de carteira para carteira",
    "Holiday List": "lista de feriados",
    "No Data To Show": "sem dados para mostrar",
    "They Recieve": "eles recebem",
    "You Will Be Logged Out In 60 Seconds": "Você será desconectado em 60 segundos",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Mova o mouse ou pressione qualquer tecla no teclado para redefinir o temporizador para 15 minutos",
    "You've Been Logged Out": "Você foi desconectado",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Por segurança, você foi desconectado após 15 minutos de inatividade. Faça login novamente se desejar usar o InstaPay.",
    "Cash": "Dinheiro",
    "Business": "Negócios",
    "Gift": "Presente",
    "Salary": "Salário",
    "Lottery": "Loteria",
    "Savings": "Poupança",
    "Other": "Outro",
    "Select Source Of Fund": "Selecione a fonte de fundos",
    "Select your source of funds": "Selecione sua fonte de fundos",
    "Request Times Out. Please Try Again!": "A solicitação expirou. Por favor, tente novamente!",
    "Resend Code In 100 Seconds": "Reenviar código em 100 segundos",
    "Computer Service": "Serviço de informática",
    "Family Support": "Apoio familiar",
    "Education": "Educação",
    "Gift And Other Donations": "Presentes e outras doações",
    "Medical Treatment": "Tratamento médico",
    "Maintenance Or Other Expenses": "Manutenção ou outras despesas",
    "Travel": "Viagem",
    "Small Value Remittance": "Remessa de pequeno valor",
    "Liberalized Remittance": "Remessa liberalizada",
    "Construction Expenses": "Despesas de construção",
    "Hotel Accommodation": "Alojamento em hotel",
    "Advertising And/Or Public Relations Related Expenses": "Publicidade e/ou despesas relacionadas com relações públicas",
    "Fees For Advisory Or Consulting Service": "Taxas para serviços de consultoria ou consultoria",
    "Business Related Insurance Payment": "Pagamento de seguro relacionado com negócios",
    "Insurance Claims Payment": "Pagamento de sinistros de seguros",
    "Delivery Fees": "Taxas de entrega",
    "Payments For Exported Goods": "Pagamentos por bens exportados",
    "Payment For Services": "Pagamento por serviços",
    "Payment Of Loans": "Pagamento de empréstimos",
    "Office Expenses": "Despesas de escritório",
    "Residential Property Purchase": "Compra de imóvel residencial",
    "Property Rental Payment": "Pagamento de aluguel de propriedade",
    "Royalty, Trademark, Patent And Copyright Fees": "Taxas de royalties, marcas registradas, patentes e direitos autorais",
    "Investment In Shares": "Investimento em ações",
    "Fund Investment": "Investimento em fundos",
    "Tax Payment": "Pagamento de impostos",
    "Transportation Fees": "Taxas de transporte",
    "Utility Bills": "Contas de serviços públicos",
    "Personal Transfer": "Transferência pessoal",
    "Payment Of Salary": "Pagamento de salário",
    "Payment Of Rewards": "Pagamento de recompensas",
    "Payment Of Influencer": "Pagamento de Influenciador",
    "Broker, Commitment, Guarantee And Other Fees": "Taxas de corretagem, compromisso, garantia e outras taxas",
    "Other Purposes": "Outros fins",
    "Aunt": "Tia",
    "Brother": "Irmão",
    "Brother-In-Law": "Cunhado",
    "Cousin": "Primo",
    "Daughter": "Filha",
    "Father": "Pai",
    "Father-In-Law": "Sogro",
    "Friend": "Amigo",
    "Grandfather": "Avô",
    "Grandmother": "Avó",
    "Husband": "Marido",
    "Mother": "Mãe",
    "Mother-In-Law": "Sogra",
    "Nephew": "Sobrinho",
    "Niece": "Sobrinha",
    "Self (i.e. The Sender, Himself)": "Eu mesmo (ou seja, o remetente)",
    "Sister": "Irmã",
    "Sister-In-Law": "Cunhada",
    "Son": "Filho",
    "Uncle": "Tio",
    "Wife": "Esposa",
    "Others Not Listed": "Outros não listados",
    "Passport": "Passaporte",
    "National Identification Card": "Cartão de identificação nacional",
    "Driving License": "Carteira de motorista",
    "Social Security Card/Number": "Cartão/Número de seguro social",
    "Tax Payer Identification Card/Number": "Cartão/Número de identificação fiscal",
    "Senior Citizen Identification Card": "Cartão de identificação de cidadão idoso",
    "Birth Certificate": "Certificado de nascimento",
    "Village Elder Identification Card": "Cartão de Identificação do Ancião da Aldeia",
    "Permanent Residency Identification Card": "Cartão de Identificação de Residência Permanente",
    "Alien Registration Certificate/Card": "Cartão de Registro de Estrangeiro",
    "PAN Card": "Cartão PAN",
    "Voter’s Identification Card": "Cartão de Identificação do Eleitor",
    "Health Insurance Card/Number": "Cartão/Número de Seguro de Saúde",
    "Employer Identification Card": "Cartão de Identificação do Empregador",
    "Others Not Listed": "Outros não listados",
    "Bank Account": "Conta Bancária",
    "Cash Pickup": "Retirada de Dinheiro",
    "Card": "Cartão",
    "Arts & Entertainment": "Artes e Entretenimento",
    "Banking & Finance": "Banco e Finanças",
    "Education & Research": "Educação e Pesquisa",
    "Engineering & Construction": "Engenharia e Construção",
    "Healthcare & Medicine": "Saúde e Medicina",
    "Information Technology & Services": "Tecnologia da Informação e Serviços",
    "Legal & Compliance": "Jurídico e Conformidade",
    "Manufacturing & Production": "Fabricação e Produção",
    "Marketing & Sales": "Marketing e Vendas",
    "Non-profit & Charity": "Organização sem fins lucrativos e Caridade",
    "Real Estate & Property": "Imóveis e Propriedades",
    "Retail & Wholesale": "Varejo e Atacado",
    "Science & Pharmaceuticals": "Ciência e Produtos Farmacêuticos",
    "Transport & Logistics": "Transporte e Logística",
    "Travel & Tourism": "Viagens e Turismo",
    "Unemployed": "Desempregado",
    "Other": "Outro",
    "Cash": "Dinheiro",
    "Business": "Negócios",
    "Gift": "Presente",
    "Salary": "Salário",
    "Lottery": "Loteria",
    "Savings": "Poupança",
    "Good Morning": "Bom dia",
    "Good Evening": "Boa noite",
    "Completed": "Concluído",
    "Load/Deposit Money To The Wallet": "Prosseguir para o pagamento",
    "Enter The Amount": "Insira o valor",
    "Choose The Top-Up Channel": "Escolha o canal de recarga",
    "Good Night!": "Boa noite!",
    "You Will Get": "Confirme seu pagamento",
    "Amount To Pay": "Você receberá",
    "Top-Up Wallet": "Tipo de pagamento",
    "Card Holder Name ": "Nome do titular do cartão",
    "Card Number": "Número do cartão",
    "CVC": "CVC",
    "MM/YY": "MM/AA",
    "Save Details To Use Next Time": "salvar detalhes para usar na próxima vez",
    "Select Or Add Payment Card": "Selecione ou adicione um cartão de pagamento",
    "Your Details ": "Seus detalhes",
    "You Selected": "Você selecionou",
    "Detailed Confirmed": "Detalhado confirmado",
    "Transaction Failed": "Transação falhou",
    "Something Went Wrong. Try Again.": "Algo deu errado. Tente novamente.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Falhou",
    "Initiated": "Iniciado",
    "Revise": "Revisar",
    "Declined": "Recusado",
    "Bargain-Accepted": "Oferta aceita",
    "Accepted": "Aceito",
    "Decline The Offer": "Recusar a oferta",
    "Pending": "Pendente",
    "Cancelled": "Cancelado",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Envie uma cotação detalhada para o seu cliente ou comprador. Apresente seus serviços, produtos ou ofertas de maneira profissional com uma cotação personalizada.",
    "Wallet to wallet": "Carteira para carteira",
    "Please follow these examples:": "Siga estes exemplos:",
    "👤 InstaPay/Instagram Username: instauser": "👤 Nome de usuário do InstaPay/Instagram: instauser",
    "📧 Email: user@email.com": "📧 E-mail: user@email.com",
    "Full Name": "Nome completo",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "Bem-vindo ao InstaPay! Para garantir que você possa sacar instantaneamente os fundos que receber, será necessário configurar suas contas de recebimento nas configurações. Isso permitirá que você transfira dinheiro sem problemas para suas contas bancárias preferidas, carteiras móveis, carteiras de criptomoedas ou locais de retirada de dinheiro.",
    "You Have No Beneficiary": "Você não tem beneficiário",
    "Add Now": "Adicione agora",
    "Wallet-Status": "Status da carteira",
    "Select Payment Card": "Selecione o cartão de pagamento",
    "Your funds have been credited to your wallet.": "Seus fundos foram creditados na sua carteira.",
    "Thanks for using InstaPay": "Obrigado por usar o InstaPay",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Receba instantaneamente: Configure suas contas de recebimento agora!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Siga estas etapas simples para configurar suas contas de recebimento e garantir que você esteja sempre pronto para acessar seus fundos:",
    "STEP 01": "ETAPA 01",
    "Go to Settings": "Vá para Configurações",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "Abra seu aplicativo InstaPay e navegue até a seção \"Configurações\".",
    "STEP 02": "ETAPA 02",
    "Select Receiving Accounts": "Selecione Contas de Recebimento",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Toque em \"Contas de Recebimento\" para começar a adicionar seus dados.",
    "Start Adding Accounts": "Comece a Adicionar Contas",
    "Phone Number: 44795396600 (With Country Code)": "Número de Telefone: 44795396600 (Com Código do País)",
    "Select Country First": "Selecione o país primeiro",
    "First Name": "Nome",
    "Last Name": "Sobrenome",
    "Address Line": "Linha de endereço",
    "Email Address": "Endereço de e-mail",
    "Select City": "Selecionar cidade",
    "Select Gender": "Selecione o gênero",
    "Select Occupation": "Selecione a ocupação",
    "Male": "Masculino",
    "Female": "Feminino",
    "Non-Binary": "Não-binário",
    "Prefer Not To Say'": "Prefiro não dizer",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Nenhum usuário encontrado",
    "Email Address ": "Endereço de e-mail",
    "Select Document": "Selecionar documento",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Exiba seu trabalho, amplie seu alcance. 'Meu Portfólio' é sua tela digital no Instapay. Carregue e exiba uma ampla gama de conteúdo - de imagens e vídeos cativantes a PDFs informativos. Seja você um artista, freelancer ou empresário, esse recurso permite apresentar seu trabalho ou projetos de forma visualmente atraente.",
    "Add New": "Adicionar novo",
    "Money Out": "Dinheiro fora",
    "Browsername": "Nome do navegador",
    "Browserversion": "Versão do navegador",
    "Credit": "Crédito",
    "Debit": "Débito",
    "Top Up": "Recarregar",
    "Wallet To Wallet": "Carteira para carteira",
    "Accountant": "Contador",
    "Architect": "Arquiteto",
    "Artist": "Artista",
    "Banker": "Banqueiro",
    "Business Owner": "Proprietário de negócio",
    "Consultant": "Consultor",
    "Doctor": "Médico",
    "Engineer": "Engenheiro",
    "Farmer": "Fazendeiro",
    "Government Employee": "Funcionário do governo",
    "IT Professional": "Profissional de TI",
    "Lawyer": "Advogado",
    "Nurse": "Enfermeiro/a",
    "Retailer": "Varejista",
    "Salesperson": "Vendedor",
    "Student": "Estudante",
    "Teacher": "Professor/a",
    "Prefer Not to Say": "Prefiro não dizer",
    "Timezone": "Fuso horário",
    "Subscription Payment": "Pagamento por assinatura",
    "Starting From": "Começando de",
    "Recurring Cycle": "Ciclo recorrente",
    "Until I stop": "Até eu parar",
    "Schedule To": "Agendar para",
    "Schedule at": "Agendar em",
    "Selected time must be at least 5 minutes from now.": "O horário selecionado deve ser pelo menos 5 minutos a partir de agora.",
    "They Recieve": "eles recebem",
    "Attach Files": "Anexar Arquivos",
    "Wallet Selected": "Carteira selecionada",
    "You Have Selected PKR Currency Wallet For This Transaction": "Você selecionou a carteira em PKR para esta transação",
    "Instant": "Instantâneo",
    "Subscription": "Assinatura",
    "Schedule": "Agendar",
    "Sms/Email Verification Code": "Código de Verificação Sms/Email",
    "Confirm Your Password": "Confirme sua senha",
    "This Security Feature Requires Password Confirmation!": "Este recurso de segurança requer confirmação de senha!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Use o aplicativo para escanear o código QR ou use seu código secreto para ativar a autenticação de dois fatores do Google",
    "OR": "OU",
    "Paste This Key In The Googe Authenticator App": "Cole esta chave no aplicativo Google Authenticator",
    "Show Secret Key": "Mostrar chave secreta",
    "Enter The Six-Digit Code From The Application": "Digite o código de seis dígitos do aplicativo",
    "6 Digit Code": "Código de 6 dígitos",
    "Enable": "Ativar",
    "Disable": "Desativar",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Após escanear a imagem do código de barras, o aplicativo exibirá um código de seis dígitos que você pode inserir abaixo",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "A autenticação de dois fatores do Google agora está vinculada à sua conta Instapay",
    "Min Amount:": "Quantia mínima:",
    "Minimum Amount": "Valor mínimo",
    "Maximum Amount": "Quantia Máxima",
    "Identity Verification Required": "Verificação de identidade necessária",
    "Select Your Wallet Currency": "Selecione sua moeda de carteira",
    "Setup Authenticator": "Configurar autenticador",
    "Note": "Nota",
    "This Is A Test Notice": "Este é um aviso de teste",
    "Session Expired": "Sessão expirada",
    "You have been logged out for security reasons, please login again to continue.": "Você foi desconectado por motivos de segurança, faça login novamente para continuar.",
    "Redirecting You To Login Page In 5 Seconds": "Redirecionando você para a página de login em 5 segundos",
    "Searching": "Pesquisando",
    "Recipient Mobile Number": "Número de telefone do destinatário",
    "TOP-UP SUMMARY": "RESUMO DA RECARGA",
    "Amount": "Quantia",
    "Something went wrong while getting countries.": "Algo deu errado ao obter os países.",
    "Select Destination Country": "Selecione o país de destino",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR não é compatível com o Paypal, USD será usado como a moeda padrão.",
    "Select A Date": "Selecione uma data",
    "Select A Timezone": "Selecione um fuso horário",
    "Proceed": "Prosseguir",
    "Proceeding": "Em andamento",
    "You Can Now Close This Webpage And Go Back To Instagram": "Agora você pode fechar esta página da web e voltar ao Instagram",
    "Select A Time": "Selecione um horário",
    "Location Permission Denied": "Permissão de localização negada",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Siga as instruções abaixo para ativar os serviços de localização em seu navegador após negar a permissão anteriormente:",
    "1: Chrome Browser": "1: Navegador Chrome",
    "Open the Chrome app.": "Abra o aplicativo Chrome.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Toque no menu de três pontos (canto superior direito) e selecione Configurações.",
    "Scroll down to Site Settings > Location.": "Role para baixo até Configurações do site > Localização.",
    "Locate the website in the list or search for it.": "Localize o site na lista ou pesquise por ele.",
    "Tap the website and select Allow under Location Access.": "Toque no site e selecione Permitir em Acesso à localização.",
    "2: Firefox Browser": "2: Navegador Firefox",
    "Open the Firefox app.": "Abra o aplicativo Firefox.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Toque no menu de três linhas (canto inferior direito) e vá para Configurações.",
    "Select Privacy & Security > Site Permissions > Location.": "Selecione Privacidade e segurança > Permissões do site > Localização.",
    "Find the website in the list and set the permission to Allow.": "Encontre o site na lista e defina a permissão para Permitir.",
    "3: Samsung Internet Browser": "3: Navegador Samsung Internet",
    "Open the Samsung Internet app.": "Abra o aplicativo Samsung Internet.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Toque no menu de três linhas (canto inferior direito) e selecione Configurações.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Vá para Sites e downloads > Permissões do site > Localização.",
    "Find the website and change the permission to Allow.": "Encontre o site e altere a permissão para Permitir.",
    "4: Safari Browser": "4: Navegador Safari",
    "Open the Settings app.": "Abra o aplicativo Configurações.",
    "Scroll down and select Safari.": "Role para baixo e selecione Safari.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Toque em Localização e defina como Perguntar da Próxima Vez ou Permitir Durante o Uso do App.",
    "Revisit the website and when prompt, allow location access": "Revise o site e, quando solicitado, permita o acesso à localização.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "Dica: Se o site não aparecer nas configurações do navegador, limpe o cache ou histórico do navegador e tente novamente.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Para enviar uma solicitação de pagamento, compartilhe sua localização—isso nos ajuda a aumentar a segurança e construir confiança para uma experiência mais tranquila!",
    "Decline": "Recusar",
    "Share Location": "Compartilhar localização",
    "Already Exist": "Já Existe",
  "Withdraw": "Retirar",
  "Seller Left A reply": "O vendedor deixou uma resposta",
  "Check Now": "Verifique agora",
  "You Left A Review": "Você deixou uma avaliação",
  "View Larger Map": "Ver mapa maior",
  "English (UK)": "Inglês (Reino Unido)",
  "QR Code Title": "Título do código QR",
    "Notice!": "Aviso!",
  "By proceeding with this transaction, I hereby confirm that I am acting independently and of my own free will. I affirm that I have not been influenced, coached, coerced, or persuaded by any third party to carry out this transaction for investment purposes or any other reason.": "Ao prosseguir com esta transação, confirmo que estou agindo de forma independente e por minha própria vontade. Afirmo que não fui influenciado, orientado, coagido ou persuadido por terceiros a realizar esta transação para fins de investimento ou qualquer outro motivo.",
  "I understand that this confirmation is required to ensure compliance and to safeguard my account and transactions.": "Compreendo que esta confirmação é necessária para garantir a conformidade e proteger minha conta e transações.",
  "Confirm and Proceed": "Confirmar e prosseguir",
  "Blocked Wallet": "Carteira Bloqueada",
  "Nominee's Full Name": "Nome completo do indicado",
  "Insufficient Balance": "Saldo insuficiente",
  "Upload Photo": "Carregar foto",
    "Security Alert": "Alerta de segurança",
  "Please remember to add your security questions for smooth experience. You will not be able to access the features of InstaPay unless You add the security questions.": "Lembre-se de adicionar suas perguntas de segurança para uma experiência tranquila. Você não poderá acessar os recursos do InstaPay a menos que adicione as perguntas de segurança.",
  "Setup Security Questions": "Configurar perguntas de segurança",
  "Geo Fencing": "Geocercamento",
  "Manage Payment & Quotation Access": "Gerenciar acesso a pagamentos e cotações",
  "Control countries from where you can receive Payment Requests and Quotations. Toggle on/off access for specific countries.": "Controle os países de onde você pode receber solicitações de pagamento e cotações. Ative/desative o acesso para países específicos.",
  "Search Countries ": "Pesquisar países",
  "Filter": "Filtro",
  "Payment Requests": "Solicitações de pagamento",
  "#No": "#Nº",
  "Country": "País",
  "Account’s Country": "País da conta",
  "Current Location": "Localização atual",
  "Save Changes": "Salvar alterações",
  "Asia": "Ásia",
  "Europe": "Europa",
  "Africa": "África",
  "Oceania": "Oceania",
  "Americas": "Américas",
  "Polar": "Polar",
  "Finger Biometric": "Biometria digital",
  "Confirm": "Confirmar",
  "Resend available in 77 seconds": "Reenvio disponível em 77 segundos",
  "Invalid OTP!": "OTP inválido!",
  "Finger Biometric Updated": "Biometria digital atualizada",
  "Your finger biometric has been disabled successfully!": "Sua biometria digital foi desativada com sucesso!",
  "Your finger biometric has been enabled successfully!": "Sua biometria digital foi ativada com sucesso!",
  "Afghanistan": "Afeganistão",
  "Armenia": "Armênia",
  "Azerbaijan": "Azerbaijão",
  "Bahrain": "Bahrein",
  "Bangladesh": "Bangladesh",
  "Bhutan": "Butão",
  "Brunei": "Brunei",
  "Cambodia": "Camboja",
  "China": "China",
  "East Timor": "Timor-Leste",
  "Georgia": "Geórgia",
  "Hong Kong S.A.R.": "Hong Kong R.A.E.",
  "India": "Índia",
  "Indonesia": "Indonésia",
  "Iran": "Irã",
  "Iraq": "Iraque",
  "Israel": "Israel",
  "Japan": "Japão",
  "Jordan": "Jordânia",
  "Kazakhstan": "Cazaquistão",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Quirguistão",
  "Laos": "Laos",
  "Lebanon": "Líbano",
  "Macau S.A.R.": "Macau R.A.E.",
  "Malaysia": "Malásia",
  "Maldives": "Maldivas",
  "Mongolia": "Mongólia",
  "Myanmar": "Myanmar",
  "Nepal": "Nepal",
  "North Korea": "Coreia do Norte",
  "Oman": "Omã",
  "Palestinian Territory Occupied": "Território Palestino Ocupado",
  "Philippines": "Filipinas",
  "Qatar": "Catar",
  "Saudi Arabia": "Arábia Saudita",
  "Singapore": "Singapura",
  "South Korea": "Coreia do Sul",
  "Sri Lanka": "Sri Lanka",
  "Syria": "Síria",
  "Taiwan": "Taiwan",
  "Tajikistan": "Tajiquistão",
  "Thailand": "Tailândia",
  "Turkey": "Turquia",
  "Turkmenistan": "Turquemenistão",
  "United Arab Emirates": "Emirados Árabes Unidos",
  "Uzbekistan": "Uzbequistão",
  "Vietnam": "Vietnã",
  "Yemen": "Iémen",
  "Åland Islands": "Ilhas Åland",
  "Albania": "Albânia",
  "Andorra": "Andorra",
  "Austria": "Áustria",
  "Belarus": "Bielorrússia",
  "Belgium": "Bélgica",
  "Bosnia and Herzegovina": "Bósnia e Herzegovina",
  "Bulgaria": "Bulgária",
  "Croatia": "Croácia",
  "Cyprus": "Chipre",
  "Czech Republic": "República Tcheca",
  "Denmark": "Dinamarca",
  "Estonia": "Estônia",
  "Faroe Islands": "Ilhas Faroé",
  "Finland": "Finlândia",
  "France": "França",
  "Germany": "Alemanha",
  "Gibraltar": "Gibraltar",
  "Greece": "Grécia",
  "Guernsey and Alderney": "Guernsey e Alderney",
  "Hungary": "Hungria",
  "Iceland": "Islândia",
  "Ireland": "Irlanda",
  "Italy": "Itália",
  "Jersey": "Jersey",
  "Kosovo": "Kosovo",
  "Latvia": "Letônia",
  "Liechtenstein": "Liechtenstein",
  "Lithuania": "Lituânia",
  "Luxembourg": "Luxemburgo",
  "Malta": "Malta",
  "Isle of Man": "Ilha de Man",
  "Moldova": "Moldávia",
  "Monaco": "Mônaco",
  "Montenegro": "Montenegro",
  "Netherlands": "Países Baixos",
  "North Macedonia": "Macedônia do Norte",
  "Norway": "Noruega",
  "Poland": "Polônia",
  "Portugal": "Portugal",
  "Romania": "Romênia",
  "Russia": "Rússia",
  "San Marino": "San Marino",
  "Serbia": "Sérvia",
  "Slovakia": "Eslováquia",
  "Slovenia": "Eslovênia",
  "Spain": "Espanha",
  "Svalbard and Jan Mayen Islands": "Ilhas Svalbard e Jan Mayen",
  "Sweden": "Suécia",
  "Switzerland": "Suíça",
  "Ukraine": "Ucrânia",
  "United Kingdom": "Reino Unido",
  "Vatican City": "Cidade do Vaticano",
  "Algeria": "Argélia",
  "Angola": "Angola",
  "Benin": "Benim",
  "Botswana": "Botswana",
  "British Indian Ocean Territory": "Território Britânico do Oceano Índico",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cameroon": "Camarões",
  "Cape Verde": "Cabo Verde",
  "Central African Republic": "República Centro-Africana",
  "Chad": "Chade",
  "Comoros": "Comores",
  "Congo": "Congo",
  "Côte d'Ivoire (Ivory Coast)": "Costa do Marfim",
  "Democratic Republic of the Congo": "República Democrática do Congo",
  "Djibouti": "Djibouti",
  "Egypt": "Egito",
  "Equatorial Guinea": "Guiné Equatorial",
  "Eritrea": "Eritreia",
  "Ethiopia": "Etiópia",
  "French Southern Territories": "Territórios Franceses do Sul",
  "Gabon": "Gabão",
  "Gambia": "Gâmbia",
  "Ghana": "Gana",
  "Guinea": "Guiné",
  "Guinea-Bissau": "Guiné-Bissau",
  "Kenya": "Quênia",
  "Lesotho": "Lesoto",
  "Liberia": "Libéria",
  "Libya": "Líbia",
  "Madagascar": "Madagascar",
  "Malawi": "Maláui",
  "Mali": "Mali",
  "Mauritania": "Mauritânia",
  "Mauritius": "Maurício",
  "Mayotte": "Mayotte",
  "Morocco": "Marrocos",
  "Mozambique": "Moçambique",
  "Namibia": "Namíbia",
  "Niger": "Níger",
  "Nigeria": "Nigéria",
  "Réunion": "Reunião",
  "Rwanda": "Ruanda",
  "Saint Helena": "Santa Helena",
  "São Tomé and Príncipe": "São Tomé e Príncipe",
  "Senegal": "Senegal",
  "Seychelles": "Seychelles",
  "Sierra Leone": "Serra Leoa",
  "Somalia": "Somália",
  "South Africa": "África do Sul",
  "South Sudan": "Sudão do Sul",
  "Sudan": "Sudão",
  "Swaziland (Eswatini)": "Suazilândia (Eswatini)",
  "Tanzania": "Tanzânia",
  "Togo": "Togo",
  "Tunisia": "Tunísia",
  "Uganda": "Uganda",
  "Western Sahara": "Saara Ocidental",
  "Zambia": "Zâmbia",
  "Zimbabwe": "Zimbábue",
  "American Samoa": "Samoa Americana",
  "Australia": "Austrália",
  "Christmas Island": "Ilha do Natal",
  "Cocos (Keeling) Islands": "Ilhas Cocos (Keeling)",
  "Cook Islands": "Ilhas Cook",
  "Fiji": "Fiji",
  "French Polynesia": "Polinésia Francesa",
  "Guam": "Guam",
  "Kiribati": "Kiribati",
  "Marshall Islands": "Ilhas Marshall",
  "Micronesia": "Micronésia",
  "Nauru": "Nauru",
  "New Caledonia": "Nova Caledônia",
  "New Zealand": "Nova Zelândia",
  "Niue": "Niue",
  "Norfolk Island": "Ilha Norfolk",
  "Northern Mariana Islands": "Ilhas Marianas do Norte",
  "Palau": "Palau",
  "Papua New Guinea": "Papua Nova Guiné",
  "Pitcairn Islands": "Ilhas Pitcairn",
  "Samoa": "Samoa",
  "Solomon Islands": "Ilhas Salomão",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Tuvalu": "Tuvalu",
  "Vanuatu": "Vanuatu",
  "Wallis and Futuna": "Wallis e Futuna",
  "North & South America": "América do Norte e do Sul",
  "Anguilla": "Anguila",
  "Antigua and Barbuda": "Antígua e Barbuda",
  "Argentina": "Argentina",
  "Aruba": "Aruba",
  "Barbados": "Barbados",
  "Belize": "Belize",
  "Bermuda": "Bermudas",
  "Bolivia": "Bolívia",
  "Bonaire, Sint Eustatius, and Saba": "Bonaire, Santo Eustáquio e Saba",
  "Brazil": "Brasil",
  "Canada": "Canadá",
  "Cayman Islands": "Ilhas Cayman",
  "Chile": "Chile",
  "Colombia": "Colômbia",
  "Costa Rica": "Costa Rica",
  "Cuba": "Cuba",
  "Curaçao": "Curaçao",
  "Dominica": "Dominica",
  "Dominican Republic": "República Dominicana",
  "Ecuador": "Equador",
  "El Salvador": "El Salvador",
  "Falkland Islands": "Ilhas Malvinas",
  "French Guiana": "Guiana Francesa",
  "Greenland": "Groenlândia",
  "Grenada": "Granada",
  "Guadeloupe": "Guadalupe",
  "Guatemala": "Guatemala",
  "Guyana": "Guiana",
  "Haiti": "Haiti",
  "Honduras": "Honduras",
  "Jamaica": "Jamaica",
  "Martinique": "Martinica",
  "Mexico": "México",
  "Montserrat": "Montserrat",
  "Nicaragua": "Nicarágua",
  "Panama": "Panamá",
  "Paraguay": "Paraguai",
  "Peru": "Peru",
  "Puerto Rico": "Porto Rico",
  "Saint Kitts and Nevis": "São Cristóvão e Névis",
  "Saint Lucia": "Santa Lúcia",
  "Saint Pierre and Miquelon": "São Pedro e Miquelão",
  "Saint Vincent and the Grenadines": "São Vicente e Granadinas",
  "Saint Barthélemy": "São Bartolomeu",
  "Saint Martin (French part)": "São Martinho (parte francesa)",
  "Sint Maarten (Dutch part)": "Sint Maarten (parte neerlandesa)",
  "South Georgia and the South Sandwich Islands": "Geórgia do Sul e Ilhas Sandwich do Sul",
  "Suriname": "Suriname",
  "The Bahamas": "Bahamas",
  "Trinidad and Tobago": "Trinidad e Tobago",
  "Turks and Caicos Islands": "Ilhas Turcas e Caicos",
  "United States": "Estados Unidos",
  "United States Minor Outlying Islands": "Ilhas Menores Distantes dos Estados Unidos",
  "Uruguay": "Uruguai",
  "Venezuela": "Venezuela",
  "British Virgin Islands": "Ilhas Virgens Britânicas",
  "U.S. Virgin Islands": "Ilhas Virgens Americanas",
  "Antarctica": "Antártica",
  "Your Preferences Updated Successfully": "Suas preferências foram atualizadas com sucesso"
}