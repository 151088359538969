export const de = {
    "How Others Can Find You": "Wie andere dich finden können",
    "Schedules": "Zeitpläne",
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Beginnen Sie heute Ihre InstaPay-Reise!",
    "Phone Number or Email": "Telefonnummer oder E-Mail",
    "Login Using Social Media": "Login mit sozialen Medien",
    "Continue": "Fortsetzen",
    "Don't Have an Account? Sign Up": "Noch kein Konto? Registrieren",
    "Sign Up": "Registrieren",
    "Edit": "Bearbeiten",
    "Enter Your Password": "Geben Sie Ihr Passwort ein",
    "Forgot Password?": "Passwort vergessen?",
    "Account Type": "Kontotyp",
    "Individual": "Privatperson",
    "Business": "Geschäft",
    "Already Have an Account? Sign In": "Sie haben bereits ein Konto? Anmelden",
    "Sing In": "Anmelden",
    "Enter Your Personal Details": "Geben Sie Ihre persönlichen Daten ein",
    "Add Photo or Logo": "Foto oder Logo hinzufügen",
    "Enter Your Name": "Geben Sie Ihren Namen ein",
    "Back": "Zurück",
    "Phone Number": "Telefonnummer",
    "Email Address": "E-Mail-Adresse",
    "Create a Strong Password": "Erstellen Sie ein starkes Passwort",
    "Password Requirements": "Passwortanforderungen",
    "Must be between 9 and 64 characters": "Muss zwischen 9 und 64 Zeichen lang sein",
    "Include at least two of the following:": "Enthalten Sie mindestens zwei der Folgenden:",
    "Uppercase character": "Großbuchstabe",
    "Lowercase character": "Kleinbuchstabe",
    "Number": "Zahl",
    "Special character": "Sonderzeichen",
    "Confirm Password": "Passwort bestätigen",
    "Finalize Your Process": "Vervollständigen Sie Ihren Prozess",
    "Referral Code (Optional)": "Empfehlungscode (Optional)",
    "It will autofill if you're signing up with an invitation link.": "Wird automatisch ausgefüllt, wenn Sie sich mit einem Einladungslink anmelden.",
    "I agree with": "Ich stimme zu",
    "Terms & Conditions": "Geschäftsbedingungen",
    "Privacy Policy": "Datenschutzrichtlinie",
    "Create Your Account": "Erstellen Sie Ihr Konto",
    "Enter Code": "Code eingeben",
    "A one-time verification code has been sent to your email address  and phone number.": "Ein einmaliger Verifizierungscode wurde an Ihre E-Mail-Adresse gesendet und Telefonnummer geschickt.",
    "Resend available in ": "Erneuter Versand verfügbar in",
    "seconds": "Sekunden",
    "Verify": "Verifizieren",
    "Verification Code Verified": "Verifizierungscode bestätigt",
    "Please continue to the next step.": "Bitte fahren Sie mit dem nächsten Schritt fort.",
    "Finish": "Fertigstellen",
    "Finishing": "Fertigstellung",
    "Company Name": "Firmenname",
    "Enter Your Business Details": "Geben Sie Ihre Geschäftsdetails ein",
    "Country ": "Land",
    "You cannot change the country once your account is verified.": "Sie können das Land nicht ändern, sobald Ihr Konto verifiziert ist.",
    "Learn more": "Mehr erfahren",
    "Optional": "Optional",
    "Continuing": "Fortfahren",
    "Didn't receive the verification code?": "Sie haben den Bestätigungscode nicht erhalten?",
    "Resend Now": "Jetzt erneut senden",
    "Search Here": "Hier suchen",
    "Search for users, services, and more on InstaPay": "Suchen Sie nach Benutzern, Dienstleistungen und mehr auf InstaPay",
    "Search users here": "Benutzer hier suchen",
    "Earn money with our affiliate program!": "Verdienen Sie Geld mit unserem Partnerprogramm!",
    "Generate passive income by sharing your InstaPay affiliate code with your followers. For each transaction they make, \nyou earn a commission, thereby maximizing the profits from your online influence.": "Generieren Sie passives Einkommen, indem Sie Ihren InstaPay-Partnercode mit Ihren Followern teilen. Für jede Transaktion, die sie tätigen,\nverdienen Sie eine Provision und maximieren so Ihre Gewinne aus Ihrem Online-Einfluss.",
    "Learn More": "Mehr erfahren",
    "Balance Amount": "Saldo",
    "You spent less compared to last month.": "Sie haben weniger ausgegeben als im letzten Monat.",
    "Referral Rewards": "Empfehlung Belohnung",
    "Bitcoin": "Bitcoin",
    "USDT": "USDT",
    "Ethereum": "Ethereum",
    "Quick Transfer to Your Most Recent Contacts:": "Schnelle Überweisung an Ihre neuesten Kontakte:",
    "Number of Transactions": "Anzahl der Transaktionen",
    "Total Transaction Amount": "Gesamtbetrag der Transaktion",
    "Total Sent": "Gesamt gesendet",
    "Total Received": "Gesamt empfangen",
    "Add Funds": "Gelder hinzufügen",
    "Send Money": "Geld senden\n",
    "Request Money": "Geld anfordern",
    "Send a Quote": "Ein Angebot senden",
    "Pay With Crypto": "Mit Krypto bezahlen",
    "Payment Insights": "Zahlungseinblicke",
    "Recent Transactions ": "Neueste Transaktionen",
    "Today": "Heute",
    "My Wallets": "Meine Wallets",
    "Balance": "Saldo",
    "Wallet ID": "Wallet-ID",
    "Monthly limit": "Monatslimit",
    "Top Up": "Aufladen",
    "Summary": "Zusammenfassung",
    "Settings": "Einstellungen",
    "More": "Mehr",
    "Upgrade to Business Account": "Upgrade auf Geschäftskonto",
    "Conversion": "Konvertierung",
    "Enter Amount": "Betrag eingeben",
    "Exchanged Amount": "Umgetauschter Betrag",
    "Convert": "Konvertieren",
    "You Are Converting": "Sie konvertieren",
    "From": "Von",
    "To": "Nach",
    "Exchange Rate": "Wechselkurs",
    "Fees": "-Aus-der-Liste-auswählen-",
    "You Will Receive": "Sie werden erhalten",
    "Slide To Confirm": "Zum Bestätigen wischen",
    "Verification Code Confirmed - You'll be redirected to the next step": "Verifizierungscode bestätigt - Sie werden zum nächsten Schritt weitergeleitet",
    "Moving to next step in": "Weiter zum nächsten Schritt in",
    "Thanks for using InstaPay": "Danke für die Nutzung von InstaPay",
    "Your funds have been credited to your wallet.": "Ihre Gelder wurden Ihrer Wallet gutgeschrieben.",
    "Dashboard": "Dashboard",
    "Accounts": "Konten",
    "Transactions": "Transaktionen",
    "Other Transactions": "Andere Transaktionen",
    "Payments": "Zahlungen",
    "Beneficiaries": "Begünstigte",
    "Referrals": "Empfehlungen",
    "Quotations": "Angebote",
    "Pending": "Ausstehend",
    "My QR Code Sticker": "Mein QR-Code-Aufkleber",
    "My Portfolio": "Mein Portfolio",
    "My Payment Address": "Meine Zahlungsadresse",
    "Analytics": "Analytik",
    "Profile": "Profil",
    "Dark Mode": "Dunkelmodus",
    "Support": "Support",
    "Logout": "Abmelden",
    "Powered By": "Unterstützt von",
    "Kemit Kingdom SA": "Kemit Kingdom SA",
    "A Swiss Company": "Ein Schweizer Fintech-Unternehmen",
    "MAIN MENU": "HAUPTMENÜ",
    "OTHERS": "ANDERE",
    "Wallet": "Wallet",
    "Default": "Standard",
    "Breakdown": "Aufschlüsselung",
    "Credit": "Guthaben",
    "Debit": "Belastung",
    "Wallet Management": "Wallet-Verwaltung",
    "Top-up Your Wallet": "Ihre Wallet aufladen",
    "Download Statement": "Kontoauszug herunterladen",
    "Block the Wallet": "Die Wallet blockieren",
    "Wallet Status": "Wallet-Status",
    "-Select-File-Type-": "-Dateityp auswählen-",
    "Download Now": "Jetzt herunterladen",
    "Downloading": "Herunterladen",
    "All": "Alle",
    "Sent": "Gesendet",
    "Received": "Empfangen",
    "Requested": "Angefordert",
    "Quoted": "Angeboten",
    "Search Transactions": "Transaktionen suchen",
    "Date & Time": "Datum & Uhrzeit",
    "Type": "Typ",
    "Transaction ID": "Transaktions-ID",
    "Recipient": "Empfänger",
    "Amount": "Betrag",
    "Status": "Status",
    "Payment Type": "Zahlungsart",
    "Sent Payments": "Gesendete Zahlungen",
    "Received Payments": "Empfangene Zahlungen",
    "Date": "Datum",
    "Cycles/Time": "Zyklen/Zeit",
    "Payment As": "Zahlung als",
    "Name": "Name",
    "Cancel": "Abbrechen",
    "Subscriptions & Scheduled": "Abonnements & Geplantes",
    "Select the option that matches your needs from the list below:": "Wählen Sie die Option, die Ihren Bedürfnissen aus der Liste unten entspricht:",
    "Easily send money to friends, family, or businesses locally or internationally. \nChoose from multiple transfer options, including bank deposits, mobile wallets, \ncash pick-up, payment cards, and wallet-to-wallet transfers. Enjoy the convenience \nand security of InstaPay for seamless and instant money transfers.": "Senden Sie ganz einfach Geld an Freunde, Familie oder Geschäfte, lokal oder international.\nWählen Sie aus mehreren Überweisungsoptionen, einschließlich Banküberweisungen, mobilen Wallets,\nBargeldabholung, Zahlungskarten und Wallet-zu-Wallet-Überweisungen. Genießen Sie den Komfort\nund die Sicherheit von InstaPay für nahtlose und sofortige Geldtransfers.",
    "International Transfer": "Internationale Überweisung\n",
    "Send money across borders with ease. Transfer funds internationally \nto friends, family, or businesses in just a few clicks. Choose from \na wide range of payment channels including bank account, \nmobile money/mobile wallet, cash pick-up location, or payment card \nfor convenient and secure transactions.": "Senden Sie mühelos Geld über Grenzen hinweg. Überweisen Sie international\nGeld an Freunde, Familie oder Geschäfte mit nur wenigen Klicks. Wählen Sie aus\neiner breiten Palette von Zahlungskanälen, einschließlich Bankkonten,\nMobile Money/Mobile Wallet, Bargeldabholungsorten oder Zahlungskarten\nfür bequeme und sichere Transaktionen.\"",
    "Select the country where you want to send the money!": "Wählen Sie das Land, in das Sie Geld senden möchten!\n",
    "Search Country": "Land suchen",
    "Trouble Sending Money?": "Probleme beim Senden von Geld?",
    "Confirm": "Bestätigen",
    "Select Payout Channel": "Auszahlungskanal wählen",
    "Select your most convenient option to send the money.": "Wählen Sie Ihre bequemste Option, um Geld zu senden.",
    "Select The Bank": "Bank auswählen",
    "Search Bank": "Bank suchen",
    "Send money directly to a recipient's bank account. Enjoy the convenience \nand security of transferring funds seamlessly to any bank around the world.": "Senden Sie Geld direkt auf das Bankkonto des Empfängers. Genießen Sie den Komfort\nund die Sicherheit, Geld nahtlos an jede Bank weltweit zu überweisen.",
    "Bank Transfer": "Banküberweisung",
    "Enter the amount": "Betrag eingeben",
    "Tax": "Steuer",
    "Amount the recipient will receive": "Betrag, den der Empfänger erhalten wird",
    "Select Remarks / Reason": "Bemerkungen/Grund auswählen",
    "Comments": "Kommentare",
    "Attach Files": "Dateien anhängen",
    "Groups": "Gruppen",
    "Manage Groups": "Gruppen verwalten",
    "Add New Group": "Neue Gruppe hinzufügen",
    "Invite": "Person einladen",
    "Manage Beneficiaries": "Begünstigte verwalten",
    "Recipients List": "Empfängerliste",
    "Search Contacts": "Kontakte suchen",
    "Confirm Your Payment": "Zahlung bestätigen",
    "Select Country": "Land auswählen",
    "Select the Recipient's Payment Channel": "Zahlungskanal des Empfängers auswählen",
    "Change Selection": "Auswahl ändern",
    "You Are Transferring ": "Sie überweisen",
    "Oops! Something Went Wrong.": "Oops! Etwas ist schiefgelaufen.",
    "We're Sorry About That. ": "Es tut uns leid.",
    "Go back to Payments Page.": "Zurück zur Zahlungsseite.",
    "Wallet to Wallet Transfer": "Wallet zu Wallet Überweisung",
    "Request a customized price quote from service providers or vendors.": "Fordern Sie ein individuelles Preisangebot von Dienstleistern oder Lieferanten an.",
    "Enter the Wallet ID": "Geben Sie die Wallet-ID ein",
    "Enter the Wallet ID, Email, or Phone Number of the recipient.": "Geben Sie die Wallet-ID, E-Mail oder Telefonnummer des Empfängers ein.",
    "Recipient not on InstaPay?": "Ist der Empfänger nicht bei InstaPay? Laden Sie ihn ein.",
    "Invite Them": "Einladen",
    "My Beneficiaries": "Meine Begünstigten",
    "Enter the Amount You Wish to Transfer.": "Geben Sie den Betrag ein, den Sie überweisen möchten.",
    "Sending to ": "Senden an",
    "You Have Selected Wallet to Wallet Transfer": "Sie haben Wallet zu Wallet Überweisung ausgewählt",
    "Authenticate Your Payment.": "Zahlung authentifizieren.",
    "You are transferring": "Sie überweisen",
    "Total ": "Gesamt",
    "Where Is My Payment?": "Wo ist meine Zahlung?",
    "Share": "Teilen",
    "Your Payment Is Confirmed": "Ihre Zahlung ist bestätigt",
    "QR Code Payment": "QR-Code-Zahlung",
    "Instantly transfer money to other InstaPay users with our secure wallet-to-wallet \nservice. Send and receive funds hassle-free within seconds.": "Überweisen Sie sofort Geld an andere InstaPay-Nutzer mit unserem sicheren Wallet-zu-Wallet Service. Senden und \nempfangen Sie Geld innerhalb von Sekunden problemlos.",
    "Scan QR with Your Camera or Upload from Your Device.": "Scannen Sie den QR-Code mit Ihrer Kamera oder laden Sie ihn von Ihrem Gerät hoch.",
    "Upload from Your Device.": "Von Ihrem Gerät hochladen.",
    "Alternatively, Use the InstaPay Unique Payment Address (UPA)": "Alternativ verwenden Sie die einzigartige InstaPay Zahlungsadresse (UPA)",
    "Enter InstaPay UPA": "Geben Sie die InstaPay UPA ein",
    "Recipient not on InstaPay? Invite Them": "Empfänger nicht bei InstaPay? Laden Sie ihn ein",
    "Verify QR Code": "QR-Code überprüfen",
    "Scan/Upload QR Code of the Recipient": "QR-Code des Empfängers scannen/hochladen",
    "Payment Confirmed": "Zahlung bestätigt",
    "Request customized price quotes from service providers or vendors to receive \naccurate cost estimates for the services or products you need.": "Fordern Sie individuelle Preisangebote von Dienstleistern oder Lieferanten an, um genaue Kostenschätzungen für die \nbenötigten Dienstleistungen oder Produkte zu erhalten.",
    "Choose The Beneficiary or Group": "Wählen Sie den Begünstigten oder die Gruppe aus",
    "Enter the Amount You Wish to Request.": "Geben Sie den Betrag ein, den Sie anfordern möchten.",
    "You Are Requesting Money": "Sie fordern Geld an",
    "You are requesting": "Sie fordern an",
    "As": "Als",
    "Conversion of Your Cryptocurrencies": "Umwandlung Ihrer Kryptowährungen",
    "Experience our user-centric, secure, and efficient Cash-Out Crypto to Fiat Conversion.": "Erleben Sie unsere nutzerzentrierte, sichere und effiziente Umwandlung von Krypto in Fiat.",
    "Choose the Cryptocurrency You Intend to Liquidate.": "Wählen Sie die Kryptowährung aus, die Sie liquidieren möchten.",
    "Next": "Weiter",
    "Enter the Crypto Wallet Address of the Recipient.": "Geben Sie die Krypto-Wallet-Adresse des Empfängers ein.",
    "Minimum Transactional Value: $100.": "Minimaler Transaktionswert: $100.",
    "Note: Crypto prices frequently change. Refer to the final quotation on the confirm order page.": "Hinweis: Kryptopreise ändern sich häufig. Beziehen Sie sich auf das endgültige Angebot auf der Bestellbestätigungsseite.",
    "Select Network": "Netzwerk auswählen",
    "Send A Quote": "Angebot senden",
    "Send a detailed price quote to your clients or customers. Present your services, products, \nor offers professionally with a personalized quotation.": "Senden Sie ein detailliertes Preisangebot an Ihre Kunden oder Klienten. Präsentieren Sie Ihre Dienstleistungen, Produkte\noder Angebote professionell mit einem personalisierten Angebot.",
    "Choose the Beneficiary": "Wählen Sie den Begünstigten aus",
    "Allow Receiver to Bargain": "Empfänger zum Verhandeln zulassen",
    "Enter the Title for Your Quotation.": "Geben Sie den Titel für Ihr Angebot ein.",
    "Enter the Description for Your Quotation": "Geben Sie die Beschreibung für Ihr Angebot ein",
    "You are Quoting": "Sie geben ein Angebot ab",
    "Title": "Titel",
    "Description": "Beschreibung",
    "Attachments": "Anhänge",
    "Confirm Your Quotation": "Ihr Angebot bestätigen",
    "Verify The Quotation": "Das Angebot überprüfen",
    "Your quotation is confirmed": "Ihr Angebot ist bestätigt",
    "Request A Quote": "Angebot anfordern",
    "Coming Soon": "Demnächst verfügbar",
    "Add Beneficiary": "Begünstigten hinzufügen",
    "Search for Beneficiaries": "Nach Begünstigten suchen",
    "Connected Accounts": "Verbundene Konten",
    "Details": "Details",
    "First Name": "Vorname",
    "Last Name": "Nachname",
    "Country": "Land",
    "City": "Stadt",
    "Address Line": "Adresszeile",
    "Mobile Number": "Handynummer",
    "Relationship With Beneficiary": "Beziehung zum Begünstigten",
    "Bank Account": "Bankkonto",
    "Mobile Money/Mobile Wallet": "Mobile Money/Mobile Wallet",
    "Payment Card": "Zahlungskarte",
    "Crypto Wallet": "Krypto-Wallet",
    "InstaPay Account": "InstaPay-Konto",
    "Cash Pickup": "Bargeldabholung",
    "IBAN / SWIFT Code": "IBAN oder Swift/BIC-Code",
    "Swift Code": "Swift-Code",
    "Account Number": "Kontonummer",
    "Bank Name": "Bankname",
    "Branch Name": "Bankfilialenname",
    "Branch Street": "Straße der Bankfiliale",
    "Province": "Provinz",
    "Postal Code": "Postleitzahl",
    "Mobile Money Provider": "Anbieter von Mobile Money",
    "Wallet Name": "Wallet-Name",
    "Wallet Number": "Wallet-Nummer",
    "Card Holder Name": "Name des Karteninhabers",
    "Card Number": "Kartennummer",
    "Expiry Date": "Ablaufdatum",
    "Crypto Currency": "Krypto-Währung",
    "Wallet Address": "Wallet-Adresse",
    "Wallet Holder Name": "Name des Wallet-Inhabers",
    "Wallet Currency": "Wallet-Währung",
    "Select Document Type": "Dokumenttyp auswählen",
    "Enter Document Number": "Dokumentnummer eingeben",
    "Add Individual Account": "Einzelkonto hinzufügen",
    "Add Business Account": "Geschäftskonto hinzufügen",
    "Company Address": "Firmenadresse",
    "Company Email": "Firmen-E-Mail",
    "Company Phone No": "Firmen-Telefonnummer",
    "Total Referrals": "Gesamtzahl der Empfehlungen",
    "My Earnings": "Meine Einnahmen",
    "Your Referral ID": "Ihre Empfehlungs-ID",
    "Share Your Unique Referral Link": "Teilen Sie Ihren einzigartigen Empfehlungslink",
    "How do I Refer a Friend to InstaPay?": "Wie lade ich einen Freund zu InstaPay ein?",
    "Step 01": "Schritt 01",
    "Share your InstaPay unique referral link with your friends.": "Teilen Sie Ihren einzigartigen InstaPay-Empfehlungslink mit Ihren Freunden.",
    "Step 02": "Schritt 02",
    "Earn rewards when they transact $100 or more": "Verdienen Sie Belohnungen, wenn sie Transaktionen über $100 tätigen",
    "Step 03": "Schritt 03",
    "Earn up to $0.06 for every transaction your followers make.": "Verdienen Sie bis zu $0,06 für jede Transaktion, die Ihre Follower tätigen.",
    "My Referrals": "Meine Empfehlungen",
    "Referral Complete": "Empfehlung abgeschlossen",
    "Received Quotations": "Erhaltene Angebote",
    "Sent Quotations": "Gesendete Angebote",
    "Search Pending Items": "Ausstehende Elemente suchen",
    "Actions": "Aktionen",
    "Received Requests": "Erhaltene Anfragen",
    "Sent Requests": "Gesendete Anfragen",
    "No Actions": "Keine Aktionen",
    "You Requested": "Sie haben angefordert",
    "Via": "Über",
    "How was your experience?": "Wie war Ihre Erfahrung?",
    "Leave a Review": "Eine Bewertung hinterlassen",
    "Overall Ratings": "Gesamtbewertungen",
    "Visit Profile": "Profil besuchen",
    "Ratings": "Bewertungen",
    "Reviews": "Rezensionen",
    "No reviews available": "Keine Rezensionen verfügbar",
    "Select Your Currency": "Wählen Sie Ihre Währung aus",
    "Proceed": "Fortfahren",
    "Commision": "Provision",
    "Unlock Seamless Transactions with InstaPay QR Code Sticker": "Nahtlose Transaktionen mit InstaPay QR-Code-Aufkleber freischalten",
    "Effortless and Swift:": "Mühelos und schnell:",
    "Instantly download your unique InstaPay QR code sticker.": "Laden Sie sofort Ihren einzigartigen InstaPay QR-Code-Aufkleber herunter.",
    "Experience hassle-free, cashless payments without needing complex infrastructure like \npayment terminals.": "Erleben Sie problemlose, bargeldlose Zahlungen ohne komplizierte Infrastruktur wie Zahlungsterminals.",
    "Swiftly collect payments – no more tedious entry of credit card or phone numbers.": "Zahlungen schnell erfassen – keine mühsame Eingabe von Kreditkarten- oder Telefonnummern mehr.",
    "Each payment includes customer information for your convenience.": "Jede Zahlung enthält Kundeninformationen für Ihre Bequemlichkeit.",
    "Economically Smart:": "Wirtschaftlich klug:",
    "Zero activation fees – start immediately without upfront costs.": "Keine Aktivierungsgebühren – beginnen Sie sofort ohne Anfangskosten.",
    "Forget about fixed monthly charges; pay as you transact.": "Vergessen Sie feste monatliche Gebühren; zahlen Sie, wie Sie handeln.",
    "Ditch the hardware; no terminal costs mean more savings for you.": "Verzichten Sie auf die Hardware; keine Terminalkosten bedeuten mehr Einsparungen für Sie.",
    "Enjoy low transaction fees at just 0.75% per QR code payment.": "Genießen Sie niedrige Transaktionsgebühren von nur 0,75 % pro QR-Code-Zahlung.",
    "We don't impose minimum commissions; you save more with each transaction.": "Wir erheben keine Mindestprovisionen; Sie sparen mehr bei jeder Transaktion.",
    "Experience the joy of instant payments, enhancing your cash flow.": "Erleben Sie die Freude an sofortigen Zahlungen und verbessern Sie Ihren Cashflow.",
    "Make Every Transaction Count with InstaPay": "Machen Sie jede Transaktion mit InstaPay zählen",
    "Wallet QR": "Wallet QR",
    "Download QR": "QR herunterladen",
    "QR Code Status": "QR-Code-Status",
    "Share your QR Code or payment address link to instantly receive payments in your InstaPay wallet.": "Teilen Sie Ihren QR-Code oder Zahlungsadresslink, um sofort Zahlungen in Ihrem InstaPay Wallet zu erhalten.",
    "QR Code Title": "QR-Code-Titel",
    "View Transactions": "Transaktionen anzeigen",
    "My QR Codes:": "Meine QR-Codes:",
    "Load More": "Mehr laden",
    "Benefits Of Portfolio": "Vorteile des Portfolios",
    "Benefits:": "Vorteile:",
    "Enhanced Visibility:": "Verbesserte Sichtbarkeit:",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible \ndirectly from your Payment Address.": "eben Sie Ihr öffentliches Profil hervor. Ihr Portfolio dient als dynamische Präsentationsplattform, direkt erreichbar \nüber Ihre Zahlungsadresse.",
    "Engage Your Audience:": "Binden Sie Ihr Publikum ein:",
    "Connect with visitors through your creative or professional journey. Let your work \nspeak for you, creating a deeper engagement with potential clients or supporters.": "Vernetzen Sie sich mit Besuchern durch Ihre kreative oder berufliche Reise. Lassen Sie Ihre Arbeit für sich sprechen und \nschaffen Sie so eine tiefere Bindung zu potenziellen Kunden oder Unterstützern.",
    "Integrated Social Proof:": "Integrierter Social Proof:",
    "Display your social media influence. Visitors can see your social media reach, \nadding credibility and context to your portfolio.": "Zeigen Sie Ihren Einfluss in den sozialen Medien. Besucher können Ihre Reichweite in sozialen Medien sehen,\nwas Glaubwürdigkeit und Kontext zu Ihrem Portfolio hinzufügt.",
    "Seamless Transactions:": "Nahtlose Transaktionen:",
    "Enable visitors to request or initiate payments right from your public page. Your \nportfolio not only showcases your work but also facilitates easy financial interactions.": "Ermöglichen Sie Besuchern, direkt von Ihrer öffentlichen Seite aus Zahlungen anzufordern oder zu initiieren. Ihr\nPortfolio präsentiert nicht nur Ihre Arbeit, sondern erleichtert auch finanzielle Interaktionen.",
    "Personalized Storytelling:": "Personalisiertes Storytelling:\n",
    "Use the 'About Me' section to share your story, mission, or vision, creating a\n personal connection with your audience.": "Nutzen Sie den Abschnitt 'Über Mich', um Ihre Geschichte, Mission oder Vision zu teilen und eine\npersönliche Verbindung zu Ihrem Publikum herzustellen.",
    "My Portfolio' is more than just a gallery; it's a tool to build your brand, engage your \naudience, and streamline your financial interactions on InstaPay.": "Mein Portfolio' ist mehr als nur eine Galerie; es ist ein Werkzeug, um Ihre Marke aufzubauen, Ihr Publikum zu binden \nund Ihre finanziellen Interaktionen auf InstaPay zu vereinfachen.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' is your digital canvas on \nInstaPay. Upload and display a diverse range of content – from captivating images \nand videos to informative PDFs. Whether you're an artist, freelancer, or \nentrepreneur, this feature lets you present your work or projects in a visually \nengaging way.": "Präsentieren Sie Ihre Arbeit, Vergrößern Sie Ihre Reichweite. 'Mein Portfolio' ist Ihre digitale Leinwand auf InstaPay. \nLaden Sie eine vielfältige Auswahl an Inhalten hoch – von fesselnden Bildern und Videos bis hin zu informativen PDFs. \nOb Sie Künstler, Freiberufler oder Unternehmer sind, dieses Feature ermöglicht es Ihnen, Ihre Arbeit oder Projekte auf eine \nvisuell ansprechende Weise zu präsentieren.",
    "Add/Manage Portfolio": "Portfolio hinzufügen/verwalten",
    "Add New": "Neu hinzufügen",
    "Maximize Your Social Media Earnings with Your InstaPay Payment Address": "Maximieren Sie Ihre Einnahmen in sozialen Medien mit Ihrer InstaPay Zahlungsadresse",
    "Make It Effortless for Your Audience to Support You:": "Machen Sie es Ihrem Publikum leicht, Sie zu unterstützen:",
    "Easily integrate your InstaPay Payment Address into your social media posts.": "Integrieren Sie Ihre InstaPay Zahlungsadresse einfach in Ihre Beiträge in sozialen Medien.",
    "Easily copy and paste your payment address link into each of your posts, stories, or prominently on your profile.": "Kopieren und fügen Sie den Link Ihrer Zahlungsadresse einfach in jeden Ihrer Beiträge, Stories oder auffällig in Ihrem Profil ein.",
    "Ideal for influencers, artists, entrepreneurs, or anyone with a community eager to show \ntheir support.": "Ideal für Influencer, Künstler, Unternehmer oder jeden, der eine Gemeinschaft hat, die ihre Unterstützung zeigen möchte.",
    "Instant Support, Endless Possibilities:": "Sofortige Unterstützung, endlose Möglichkeiten:",
    "Transform the way you monetize your social media content.": "Verändern Sie die Art und Weise, wie Sie Ihre Inhalte in sozialen Medien monetarisieren.",
    "Allow your followers to send you funds instantly with just a click, directly from their favorite social networks.": "Ermöglichen Sie Ihren Followern, Ihnen sofort Geld mit nur einem Klick direkt aus ihren bevorzugten sozialen Netzwerken zu senden.",
    "Ideal for real-time support during live streams, as a tipping system for your creative content, or for funding your next big \nproject.": "Ideal für Echtzeit-Unterstützung während Live-Streams, als Trinkgeldsystem für Ihre kreativen Inhalte oder zur Finanzierung Ihres \nnächsten großen Projekts.",
    "Where to Place Your InstaPay Payment Address for Maximum Impact:": "Wo Sie Ihre InstaPay Zahlungsadresse für maximale Wirkung platzieren sollten:",
    "Social Media Profiles: Pin it to your bio or about section for constant visibility.": "Soziale-Medien-Profile: Befestigen Sie es in Ihrem Bio- oder Info-Bereich für ständige Sichtbarkeit.",
    "Posts & Announcements: Incorporate it into your individual posts, particularly those highlighting your work or specific \ncalls for support.": "Beiträge & Ankündigungen: Integrieren Sie es in Ihre individuellen Beiträge, insbesondere solche, die Ihre Arbeit oder spezifische \nAufrufe zur Unterstützung hervorheben.",
    "Stories & Reels: Integrate it into your interactive stories or captivating reels to capture instant contributions.": "Stories & Reels: Integrieren Sie es in Ihre interaktiven Stories oder fesselnden Reels, um sofortige Beiträge zu erfassen.",
    "Live Streams: Share it during your LIVE sessions, making it easy for viewers to contribute in real-time.": "Live-Streams: Teilen Sie es während Ihrer LIVE-Sitzungen, um es Zuschauern leicht zu machen, in Echtzeit beizutragen.",
    "Simple, Fast, and Secure:": "Einfach, schnell und sicher:",
    "No complex setup – get started in moments.": "Keine komplexe Einrichtung – starten Sie in wenigen Momenten.",
    "Enjoy the security and speed of InstaPay's trusted payment processing.": "Genießen Sie die Sicherheit und Geschwindigkeit der vertrauenswürdigen Zahlungsabwicklung von InstaPay.",
    "Receive payments from anyone, anywhere, without them needing an InstaPay account.": "Empfangen Sie Zahlungen von jedem, überall, ohne dass diese ein InstaPay-Konto benötigen.",
    "Elevate Your Social Media Game with InstaPay Payment Address": "Steigern Sie Ihr Spiel in sozialen Medien mit der InstaPay Zahlungsadresse",
    "InstaPay VPA (Virtual Payment Address)": "InstaPay VPA (Virtuelle Zahlungsadresse)",
    "Payment Address Title": "Titel der Zahlungsadresse",
    "Currency": "Währung",
    "Payment Address Description": "Beschreibung der Zahlungsadresse",
    "Update": "Aktualisieren",
    "Updating": "Aktualisierung",
    "Total Transaction": "Gesamttransaktion",
    "Last 30 days": "Letzte 30 Tage",
    "Last 1 month": "Letzter 1 Monat",
    "Last 1 year": "Letztes 1 Jahr",
    "Custom Date": "Benutzerdefiniertes Datum",
    "Payment Methods": "Zahlungsmethoden",
    "Payment Types": "Zahlungsarten",
    "Requested Amount": "Angeforderter Betrag",
    "Quoted Amount": "Angebotener Betrag",
    "About Me": "Über Mich",
    "Followers": "Follower",
    "InstaPay ID": "InstaPay ID",
    "Download QR Code": "QR-Code herunterladen",
    "Initiate A Payment": "Zahlung initiieren",
    "Report this person": "Diese Person melden",
    "Add now": "Jetzt hinzufügen",
    "Recent Reviews": "Neueste Bewertungen",
    "Reviews As Seller": "Bewertungen als Verkäufer",
    "Reviews As Buyer": "Bewertungen als Käufer",
    "Select Language": "Sprache auswählen",
    "Profile Completion": "Profilvervollständigung",
    "Profile Completed": "Profil abgeschlossen",
    "Basic Info": "Basisinformationen",
    "Username": "Benutzername",
    "Gender": "Geschlecht",
    "Male": "Männlich",
    "Female": "Weiblich",
    "-Select-Gender-": "-Geschlecht auswählen-",
    "Date Of Birth": "Geburtsdatum",
    "Address": "Adresse",
    "Edit Profile": "Profil bearbeiten",
    "Update Profile": "Profil aktualisieren",
    "Updating Profile": "Profilaktualisierung",
    "Complete Now": "Jetzt vervollständigen",
    "Password": "Passwort",
    "Change Password": "Passwort ändern",
    "New Password": "Neues Passwort",
    "Re-enter New Password": "Neues Passwort erneut eingeben",
    "Please follow this guide for a strong password": "Bitte folgen Sie dieser Anleitung für ein starkes Passwort",
    "Include at least one special character.": "Mindestens ein Sonderzeichen einschließen.",
    "Minimum of 8 characters.": "Mindestens 8 Zeichen.",
    "Include at least one number": "Mindestens eine Zahl einschließen",
    "Change it often for enhanced security.": "Ändern Sie es oft für erhöhte Sicherheit.",
    "Security Questions": "Sicherheitsfragen",
    "Security Question": "Sicherheitsfrage",
    "Select your question": "Wählen Sie Ihre Frage aus",
    "Answer To The Question": "Antwort auf die Frage",
    "Nominee": "Nominierte",
    "Relationship with Nominee": "Beziehung zum Nominierten",
    "Nominee Contact Number": "Kontaktnummer des Nominierten",
    "Nominee Address": "Adresse des Nominierten",
    "Delete Nominee": "Nominierten löschen",
    "Update Nominee": "Nominierten aktualisieren",
    "Details Updated Successfully!": "Details erfolgreich aktualisiert!",
    "Success": "Erfolg",
    "You may elect a Nominee of your choice, provided they are not a minor, through the InstaPay \nplatform itself. You acknowledge that no wet signature is required to appoint the nominee, and \nthe online registration will be considered final and binding. In the event of the death of an \nInstaPay Wallet holder, the balance in the Wallet will be transferred to the registered Nominee. \nKEMIT KINGDOM will be discharged from all its liabilities upon the handover of the amount to \nthe Nominee. The Nominee will provide any documents required by InstaPay, including those \nfor identification and proof of death.": "Sie können einen Nominierten Ihrer Wahl benennen, vorausgesetzt, er ist nicht minderjährig, über die InstaPay-Plattform selbst. \nSie erkennen an, dass keine nasse Unterschrift erforderlich ist, um den Nominierten zu ernennen, und die Online-Registrierung wird \nals endgültig und bindend betrachtet. Im Falle des Todes eines InstaPay Wallet-Inhabers wird das Guthaben in der Wallet auf den \nregistrierten Nominierten übertragen. KEMIT KINGDOM wird von allen seinen Verbindlichkeiten entlastet, sobald der Betrag an den \nNominierten übergeben wird. Der Nominierte wird alle von InstaPay benötigten Dokumente bereitstellen, einschließlich derer zur \nIdentifikation und zum Nachweis des Todes.",
    "Add Nominee": "Nominierten hinzufügen",
    "Two Factor Authentication": "Zwei-Faktor-Authentifizierung",
    "SMS Number": "SMS-Nummer",
    "Updated Successfully!": "Erfolgreich aktualisiert!",
    "Social Network Accounts": "Soziale Netzwerkkonten",
    "Here, you can set up and manage your integration settings.": "Hier können Sie Ihre Integrationseinstellungen einrichten und verwalten.",
    "Social Network Account": "Soziales Netzwerkkonto",
    "Activate Account": "Konto aktivieren",
    "Enter the code below in the InstaPay Chatbot.": "Geben Sie den untenstehenden Code im InstaPay Chatbot ein.",
    "The code will expire in ": "Der Code läuft ab in",
    "Notifications": "Benachrichtigungen",
    "Customize how you receive notifications. These settings apply to the activities you are monitoring.": "Passen Sie an, wie Sie Benachrichtigungen erhalten. Diese Einstellungen gelten für die Aktivitäten, die Sie überwachen.",
    "Activity": "Aktivität",
    "Email": "E-Mail",
    "Push": "Push",
    "SMS": "SMS",
    "Source": "Quelle",
    "Payment Requests": "Zahlungsanfragen",
    "Bulk Payments": "Massenzahlungen",
    "Identity Verification": "Identitätsüberprüfung",
    "Verify Your Identity": "Überprüfen Sie Ihre Identität",
    "Identity Verification Status:": "Status der Identitätsüberprüfung:",
    "Briefly describe yourself and explain your reasons for using InstaPay.": "Beschreiben Sie sich kurz und erklären Sie Ihre Gründe für die Nutzung von InstaPay.",
    "Enter Your Message": "Geben Sie Ihre Nachricht ein",
    "Select Occupation": "Beruf auswählen",
    "It must be a government-issued identification document with a photo. All identification documents \nmust be written in the Latin alphabet. If not, a certified translation is required.": "Es muss ein von der Regierung ausgestelltes Identifikationsdokument mit Foto sein. Alle Identifikationsdokumente müssen in \nlateinischen Buchstaben geschrieben sein. Wenn nicht, ist eine beglaubigte Übersetzung erforderlich.",
    "Accepted Documents": "Akzeptierte Dokumente",
    "A national identity card": "Ein gültiger Personalausweis",
    "A valid passport": "Ein gültiger Reisepass",
    "Valid residence permits (accepted only if issued by a European country or in Switzerland)": "Gültige Aufenthaltsgenehmigungen (nur akzeptiert, wenn von einem europäischen Land oder der Schweiz ausgestellt)",
    "Biometric Facial Recognition: Comparing video with passport photograph": "Biometrische Gesichtserkennung: Vergleich von Video mit Passfoto",
    "Address Verification:": "Adressüberprüfung:",
    "Provide a bank statement or utility bill (less than 3 months old) as proof of address": "Stellen Sie einen Kontoauszug oder eine Nebenkostenabrechnung (weniger als 3 Monate alt) als Adressnachweis bereit",
    "Additional Documents:": "Zusätzliche Dokumente:",
    "Kemit Kingdom reserves the right to request any additional documents (Enhanced Due Diligence - EDD) \nor further information necessary for conducting thorough verifications, in accordance with its legal obligations.": "Kemit Kingdom behält sich das Recht vor, zusätzliche Dokumente (Enhanced Due Diligence - EDD) oder weitere Informationen \nanzufordern, die für eine gründliche Überprüfung erforderlich sind, gemäß seinen rechtlichen Verpflichtungen.\"",
    "Start Verification": "Überprüfung starten",
    "Sessions": "Sitzungen",
    "This is a list of devices that have logged into your account. Remove any that you do not recognize.": "Dies ist eine Liste der Geräte, die sich in Ihr Konto eingeloggt haben. Entfernen Sie alle, die Sie nicht erkennen.",
    "Your Withdrawal Options": "Ihre Auszahlungsoptionen",
    "Choose your preferred method for withdrawing payments.": "Wählen Sie Ihre bevorzugte Methode zur Auszahlung von Zahlungen.",
    "No withdrawal options currently available in your country.": "Derzeit sind keine Auszahlungsoptionen in Ihrem Land verfügbar.",
    "Download Your Data": "Laden Sie Ihre Daten herunter",
    "Request a Copy of Your Data": "Fordern Sie eine Kopie Ihrer Daten an",
    "Submit a request to download a copy of your data": "Reichen Sie eine Anfrage ein, um eine Kopie Ihrer Daten herunterzuladen",
    "Which Data Should Be Included in the Download?": "Welche Daten sollten im Download enthalten sein?",
    "Select all applicable options.": "Wählen Sie alle zutreffenden Optionen aus.",
    "Personal Information": "Persönliche Informationen",
    "This information is used to secure your account, tailor your experience, and contact you as necessary.": "Diese Informationen werden verwendet, um Ihr Konto zu sichern, Ihr Erlebnis anzupassen und Sie bei Bedarf zu kontaktieren.",
    "Name and Date Of Birth": "Name und Geburtsdatum",
    "Phone number": "Telefonnummer",
    "Financial Information": "Finanzinformationen",
    "We use this information to enable faster checkout and to send or receive money in just a few clicks.": "Wir verwenden diese Informationen, um einen schnelleren Checkout zu ermöglichen und um in nur wenigen Klicks Geld zu senden \noder zu empfangen.",
    "Bank accounts": "Bankkonten",
    "Debit or credit cards": "Debit- oder Kreditkarten",
    "Credit Information": "Kreditinformationen",
    "Includes your credit application information": "Beinhaltet Ihre Kreditantragsinformationen",
    "Other Information (not included in file)": "Andere Informationen (nicht in der Datei enthalten)",
    "Includes device info, technical usage data, geolocation information, marketing preferences, consent history, and data used for other services such as credit, \nidentity verification, communications with PayPal, and third-party processors.": "Beinhaltet Geräteinformationen, technische Nutzungsdaten, Geolokalisierungsinformationen, Marketingpräferenzen, \nEinwilligungshistorie und Daten, die für andere Dienste wie Kredit, Identitätsüberprüfung, Kommunikation mit PayPal und \nDrittanbietern verwendet werden.",
    "Choose file type:": "Wählen Sie den Dateityp:",
    "Select file type": "Dateityp auswählen",
    "PDF file can be easily opened on any computer": "PDF-Datei kann auf jedem Computer einfach geöffnet werden",
    "Submit Request": "Anfrage einreichen",
    "Delete Your Account": "Löschen Sie Ihr Konto",
    "Ask us to delete your data and close this account": "Bitten Sie uns, Ihre Daten zu löschen und dieses Konto zu schließen",
    "We use your data to provide services, so if we delete your data, we also need to close your account.": "Wir verwenden Ihre Daten, um Dienstleistungen zu erbringen, also wenn wir Ihre Daten löschen, müssen wir auch Ihr Konto schließen.",
    "If your account is limited, go to the Resolution Center, select 'Go to Account Limitations', then click 'Resolve'.": "Wenn Ihr Konto eingeschränkt ist, gehen Sie zum Resolution Center, wählen Sie 'Go to Account Limitations' und dann klicken Sie auf 'Resolve'.",
    "Make sure any outstanding payments have cleared. We won't be able to close your account until they do.": "Stellen Sie sicher, dass alle ausstehenden Zahlungen geklärt sind. Wir können Ihr Konto erst schließen, wenn dies der Fall ist.",
    "If you have a negative balance, click the Resolve link next to your balance.": "Wenn Sie ein negatives Guthaben haben, klicken Sie auf den Link 'Resolve' neben Ihrem Guthaben.",
    "Once your account is clear, and if you're eligible, we'll close your account and delete your data. We need \nto retain some of your data for legal and regulatory reasons. To learn more, see our ": "Sobald Ihr Konto klar ist und Sie berechtigt sind, werden wir Ihr Konto schließen und Ihre Daten löschen. Wir müssen einige Ihrer Daten \naus rechtlichen und regulatorischen Gründen behalten. Um mehr zu erfahren, sehen Sie unsere ",
    "privacy statement": "Datenschutzerklärung",
    "We'll email you to let you know the status, and if there are any other steps you need to take.": "Wir werden Ihnen eine E-Mail senden, um Sie über den Status zu informieren und ob es weitere Schritte gibt, die Sie unternehmen müssen.",
    "Have more questions about data deletion?": "Haben Sie weitere Fragen zur Datenlöschung?",
    "Contact us": "Kontaktieren Sie uns",
    "I understand that account deletion is permanent and can't be reversed.": "Ich verstehe, dass die Kontolöschung dauerhaft ist und nicht rückgängig gemacht werden kann.",
    "Are you sure you want to close your account?": "Sind Sie sicher, dass Sie Ihr Konto schließen möchten?",
    "Yes, Continue": "Ja, Fortfahren",
    "Logging In": "Einloggen",
    "Complete your profile to streamline your transaction process.": "Vervollständigen Sie Ihr Profil, um Ihren Transaktionsprozess zu vereinfachen.",
    "Skip": "Überspringen",
    "Complete Your Profile": "Vervollständigen Sie Ihr Profil",
    "API Key": "API-Schlüssel",
    "Get API Key": "API-Schlüssel erhalten",
    "Generating New API Key": "Neuen API-Schlüssel generieren",
    "Generate API Key": "API-Schlüssel generieren",
    "Regenerate API Key": "API-Schlüssel regenerieren",
    "To harness the full potential of InstaPay's seamless transactions, follow these quick steps to generate your API key. \nFirst, sign up for an account at [your registration URL] and access the Developer Dashboard. Navigate to the \n'API Keys' section, generate a new key, and ensure its secure storage.": "Um das volle Potenzial der nahtlosen Transaktionen von InstaPay auszuschöpfen, folgen Sie diesen schnellen Schritten, um Ihren API-Schlüssel \nzu generieren. Melden Sie sich zuerst für ein Konto unter [Ihrem Registrierungs-URL] an und greifen Sie auf das Entwickler-Dashboard zu. \nNavigieren Sie zum Abschnitt 'API-Schlüssel', generieren Sie einen neuen Schlüssel und stellen Sie sicher, dass er sicher aufbewahrt wird.",
    "Delete API Key": "API-Schlüssel löschen",
    "Webhook URL": "Webhook-URL",
    "KYB Verification": "KYB-Überprüfung",
    "Additional Files": "Zusätzliche Dateien",
    "Business Verification": "Geschäftsüberprüfung",
    "--Select-Your-Company-Type--": "--Wählen Sie Ihren Unternehmenstyp aus--",
    "Documents Required:": "Erforderliche Dokumente:",
    "Official Company Registry Extract (Entity's Registration/Incorporation Document)": "Offizieller Firmenregisterauszug (Registrierungs-/Inkorporationsdokument der Entität)",
    "This document serves as the company's official 'identity card' and should be obtained from the local business registry. \nIt reflects the current status of the business (active or closed).": "Dieses Dokument dient als offizielle 'Identitätskarte' des Unternehmens und sollte aus dem lokalen Firmenregister bezogen werden.\nEs spiegelt den aktuellen Status des Unternehmens wider (aktiv oder geschlossen).",
    "The document should be current, with an issuance date not exceeding 3 months.": "Das Dokument sollte aktuell sein, mit einem Ausstellungsdatum, das 3 Monate nicht überschreitet.",
    "The document must include the following details:": "Das Dokument muss folgende Angaben enthalten:",
    "Legal name of the company": "Rechtlicher Name des Unternehmens",
    "Full registered address": "Vollständige registrierte Adresse",
    "Company registration number": "Unternehmensregistrierungsnummer",
    "Date of incorporation": "Gründungsdatum",
    "Description of the company's activities/purpose": "Beschreibung der Aktivitäten/Zwecke des Unternehmens",
    "Legal representative's information: full name, date and place of birth, residential address \n(minimum requirement: country of residence), and nationality": "Informationen des gesetzlichen Vertreters: vollständiger Name, Geburtsdatum und -ort, Wohnadresse\n(minimale Anforderung: Land des Wohnsitzes) und Staatsangehörigkeit",
    "If applicable, the document should also include:": "Falls zutreffend, sollte das Dokument auch enthalten:",
    "Brand or trading names": "Marken- oder Handelsnamen",
    "Value Added Tax (VAT) number": "Umsatzsteuer-Identifikationsnummer (USt-IdNr.)",
    "List of Ultimate Beneficial Owners (UBOs) - this is mandatory in some countries, otherwise, \nit may be provided as a separate document.": "Liste der letztendlichen wirtschaftlichen Eigentümer (UBOs) - dies ist in einigen Ländern obligatorisch, ansonsten kann es als separates Dokument \nbereitgestellt werden.",
    "Proof of Legal Representative's Authority": "Nachweis der Befugnis des gesetzlichen Vertreters\n",
    "This document validates an individual's authority to represent the company": "Dieses Dokument validiert die Befugnis einer Person, das Unternehmen zu vertreten\n",
    "Typically, the legal representative is named in the company's registry extract. However, if this is not the case, a \ndocument such as a Power of Attorney or Delegation of Powers must be secured.": "Typischerweise wird der rechtliche Vertreter im Firmenregisterauszug des Unternehmens genannt. Wenn dies jedoch nicht der Fall ist, muss\nein Dokument wie eine Vollmacht oder Delegation von Befugnissen gesichert werden.",
    "Power of Attorney/Delegation of Powers:": "Vollmacht/Delegation von Befugnissen:\n",
    "The individual conferring the authority must be legitimately entitled to do so. This individual should be listed as a legal \nrepresentative in the company's registry extract.": "Die Person, die die Vollmacht erteilt, muss dazu legitimiert sein. Diese Person sollte als gesetzlicher Vertreter im Firmenregisterauszug aufgeführt \nsein.",
    "Ownership Structure and Ultimate Beneficial Owners (UBOs) Documentation": "Dokumentation der Eigentumsstruktur und der Letztbegünstigten Eigentümer (UBOs)",
    "Evidence of the company's listing on the relevant stock exchange should be provided. This could be a page from the \nstock exchange website displaying the company's details.": "Es muss ein Nachweis erbracht werden, dass das Unternehmen an der relevanten Börse gelistet ist. Dies könnte eine Seite von der Börsenwebsite \nsein, die die Details des Unternehmens anzeigt.",
    "If less than 75% of the company's shares are publicly owned, all shareholders who own or control more than 25% of \nthe shares will be subject to Ultimate Beneficial Owner (UBO) verification requirements.": "Wenn weniger als 75% der Aktien des Unternehmens öffentlich gehandelt werden, unterliegen alle Aktionäre, die mehr als 25% der Aktien besitzen \noder kontrollieren, den Überprüfungsanforderungen für den Letztbegünstigten Eigentümer (UBO).",
    "Collection of Identification Documents": "Sammeln von Identifikationsdokumenten\n",
    "The identification document must be government-issued, bearing a photograph of the individual. All identification \ndocuments must be written in the Latin alphabet. If not, a certified translation is required.": "Das Identifikationsdokument muss von der Regierung ausgestellt sein und ein Foto der Person enthalten. Alle Identifikationsdokumente\nmüssen in lateinischer Schrift verfasst sein. Wenn nicht, ist eine beglaubigte Übersetzung erforderlich.",
    "Accepted documents:": "Akzeptierte Dokumente:",
    "Valid National Identity Card": "Gültiger Nationaler Personalausweis",
    "Valid Passport": "Gültiger Reisepass",
    "Valid Resident Permits - These will be accepted only if issued by a European country.": "Gültige Aufenthaltsgenehmigungen - Diese werden nur akzeptiert, wenn sie von einem europäischen Land ausgestellt wurden.",
    "Only legal representatives are subject to the identification document collection process.": "Ausschließlich gesetzliche Vertreter unterliegen dem Prozess der Sammlung von Identifikationsdokumenten.",
    "Additional Requirement:": "Zusätzliche Anforderung:",
    "In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their ID.": "In Fällen, die eine Vollmacht oder eine Delegation von Befugnissen beinhalten, müssen beide Parteien\n(die Person, die die Autorität erteilt und die sie erhält) eine Kopie ihres Ausweises vorlegen.",
    "Additional Documents": "Weitere Dokumente\n",
    "Utility Bill (such as gas, electricity, telephone or mobile phone bill); or a document issued by a government department \nthat shows the End-user’s address and name (e.g., Residential Certificate); or": "Nebenkostenabrechnung (wie Gas-, Strom-, Telefon- oder Mobiltelefonrechnung); oder ein von einer Behörde ausgestelltes Dokument,\ndas die Adresse und den Namen des Endbenutzers anzeigt (z.B. Wohnsitzbescheinigung); oder",
    "Bank Statement; or": "Kontoauszug; oder\n",
    "a document issued by a government department that shows the End-user’s address and name \n(e.g., Residential Certificate).": "ein von einer Behörde ausgestelltes Dokument, das die Adresse und den Namen des Endbenutzers anzeigt (z.B. Wohnsitzbescheinigung).",
    "Kemit Kingdom retains the right to request any additional documents or further information necessary for conducting \nthorough verifications, in line with its legal obligations.": "Kemit Kingdom behält sich das Recht vor, zusätzliche Dokumente oder weitere Informationen anzufordern, die für eine gründliche Überprüfung gemäß\nseinen gesetzlichen Verpflichtungen erforderlich sind.",
    "Note:": "Hinweis:",
    "Partners": "Partner",
    "Add New Partner": "Neuen Partner hinzufügen",
    "Designation": "Bezeichnung",
    "Partner Name": "Partnername",
    "Partner Email": "Partner-E-Mail",
    "Partner Designation": "Bezeichnung des Partners",
    "Add Now": "Jetzt hinzufügen",
    "Adding": "Hinzufügen",
    "Delete Partner": "Partner löschen",
    "Delete": "Löschen",
    "Deleting": "Löschvorgang",
    "Are you sure, you want to remove sxz as partner?": "Sind Sie sicher, dass Sie sxz als Partner entfernen möchten?",
    "Entity's Registry Extract (Entity'sRegistration/Creation Document)": "Auszug aus dem Unternehmensregister (Dokument zur Registrierung/Erstellung der Entität)",
    "This document should be either:": "Dieses Dokument sollte entweder sein:",
    "A proof of creation of the entity, providing the entity's details, creation date, and purpose.": "Ein Nachweis über die Gründung der Entität, der die Details, das Gründungsdatum und den Zweck der Entität enthält.",
    "A local registry extract:": "Ein lokaler Registrierungsauszug:",
    "Required as per the country's regulations, serving as the current 'identity card' for the entity, indicating its status \n(active or closed).": "Erforderlich gemäß den Vorschriften des Landes, dient als aktuelle 'Identitätskarte' der Entität und gibt ihren Status an (aktiv oder geschlossen).",
    "If a registry extract exists, it must not be older than 3 months.": "Wenn ein Registrierungsauszug vorhanden ist, darf dieser nicht älter als 3 Monate sein.",
    "Entity name": "Name der Entität",
    "Registration number (if applicable, based on the country's requirements)": "Registrierungsnummer (falls zutreffend, basierend auf den Anforderungen des Landes)",
    "Date of creation": "Gründungsdatum",
    "Description of the entity's activities or purpose": "Beschreibung der Aktivitäten oder des Zwecks der Entität",
    "Trading names": "Handelsnamen",
    "Articles of Association": "Satzung",
    "The Articles of Association serve as the constitutional document of the entity, outlining its operating \nrules.": "Die Satzung dient als konstitutionelles Dokument der Entität und legt ihre Betriebsregeln fest.",
    "There is no time constraint on this document, meaning the most recent version is requested. Typically, these types of \nentities require at least one annual meeting that may result in an updated version of the Articles of Association.": "Es gibt keine zeitliche Einschränkung für dieses Dokument, was bedeutet, dass die neueste Version angefordert wird. Typischerweise erfordern \ndiese  Arten von Entitäten mindestens eine jährliche Versammlung, die zu einer aktualisierten Version der Satzung führen kann.",
    "Examples of changes that could occur include updates to the entity's name, address, purpose, management board, \nroles, responsibilities, etc.": "Beispiele für mögliche Änderungen umfassen Aktualisierungen des Namens der Entität, der Adresse, des Zwecks, des Verwaltungsrats, der Rollen,\nVerantwortlichkeiten usw.",
    "The document should contain information about:": "Das Dokument sollte Informationen über folgendes enthalten:\n",
    "The entity itself, including its legal name, registered address, and purpose": "Die Entität selbst, einschließlich ihres rechtlichen Namens, der registrierten Adresse und des Zwecks",
    "Its internal operating rules, such as the appointment process for management board members, their \nroles and powers, among other details. This list is not exhaustive.": "Ihre internen Betriebsregeln, wie der Ernennungsprozess für Mitglieder des Verwaltungsrats, ihre Rollen und Befugnisse,\nunter anderen Details. Diese Liste ist nicht erschöpfend.",
    "How the entity is funded": "Wie die Entität finanziert wird\n",
    "Appointment/Removal/Changes on Board of Management Members:": "Ernennung/Entfernung/Änderungen bei den Mitgliedern des Verwaltungsrats:\n",
    "These updates usually occur based on the entity's needs or as stipulated in the Articles of Association. Changes are \ndocumented through board of management meeting minutes, which are registered and certified to reflect the updated \nlist of members, such as the president, vice-president, treasurer, and general secretary.": "Diese Aktualisierungen erfolgen in der Regel aufgrund der Bedürfnisse der Entität oder wie in der Satzung festgelegt. Änderungen werden\nin den Protokollen der Verwaltungsratssitzungen dokumentiert, die registriert und zertifiziert werden, um die aktualisierte Liste der Mitglieder \nwiderzuspiegeln, wie z.B. den Präsidenten, Vizepräsidenten, Schatzmeister und Generalsekretär.",
    "The latest board management meeting minutes, including the current list of members, will be required in addition to the \nArticles of Association, if available.": "Die neuesten Protokolle der Verwaltungsratssitzungen, einschließlich der aktuellen Liste der Mitglieder, werden zusätzlich zu den\nSatzungen benötigt, wenn verfügbar.",
    "Note: If the current list of board management members is not included in the latest Articles of Association and there \nare no board meeting minutes available, this list must be provided. It should be dated and signed by the most recently \nappointed legal representative, whose appointment can be verified through related documentation.": "Hinweis: Wenn die aktuelle Liste der Mitglieder des Verwaltungsrats nicht in den neuesten Satzungen enthalten ist und keine Protokolle der \nVerwaltungsratssitzungen vorhanden sind, muss diese Liste bereitgestellt werden. Sie sollte datiert und von dem zuletzt ernannten gesetzlichen \nVertreter unterschrieben sein, dessen Ernennung durch entsprechende Unterlagen überprüft werden kann.",
    "This document demonstrates an individual's authority to represent the entity. This document could be:": "Dieses Dokument weist die Befugnis einer Person nach, die Entität zu vertreten. Dieses Dokument kann sein:\n",
    "The latest Articles of Association": "Die neuesten Satzungen\n",
    "The current list of board management members \n(which may be included in the board management meeting minutes)": "Die aktuelle Liste der Mitglieder des Verwaltungsrats (die in den Protokollen der Verwaltungsratssitzungen enthalten sein kann)",
    "The document should specify:": "Das Dokument sollte spezifizieren:\n",
    "Full name of the individual(s)": "Vollständiger Name der Person(en)\n",
    "Function of the individual(s). This information will be cross-verified with the Articles of Association to \nensure that the individual's function permits them to bind the entity. This is particularly important when the \napplicant is not the president, and other functions have limited representation authority as stated in \nthe Articles of Association.": "Funktion der Person(en). Diese Informationen werden mit den Satzungen abgeglichen, um sicherzustellen, dass die Funktion der Person sie dazu \nberechtigt, die Entität zu binden. Dies ist besonders wichtig, wenn der Antragsteller nicht der Präsident ist und andere Funktionen gemäß den \nSatzungen eine begrenzte Vertretungsbefugnis haben.",
    "Additional information about the legal representative that will be collected includes:": "Weitere Informationen über den gesetzlichen Vertreter, die gesammelt werden, umfassen:",
    "Date and place of birth": "Geburtsdatum und -ort",
    "Residential address (at least the country of residence should be stated)": "Wohnadresse (mindestens das Land des Wohnsitzes sollte angegeben werden)",
    "Nationality": "Nationalität",
    "Ownership Structure & Ultimate Beneficial Owners (UBOs)": "Eigentumsstruktur und Letztbegünstigte Eigentümer (UBOs)",
    "Typically, these types of entities, given their structure and purpose, do not have beneficial owners. However, board \nmembers will be identified and treated as such for screening purposes, ID collection and verification, etc.": "Typischerweise haben diese Arten von Entitäten aufgrund ihrer Struktur und ihres Zwecks keine wirtschaftlich Berechtigten. Mitglieder des \nVerwaltungsrats werden jedoch identifiziert und als solche für Überprüfungszwecke, ID-Sammlung und -Verifizierung usw. behandelt.",
    "A small number of associations might have beneficial owners, particularly those generating profits. In such instances, \nthis detail will be mentioned in the Articles of Association. These individuals will be easily identified and treated as such, \nalongside management board members.": "Eine kleine Anzahl von Vereinigungen könnte wirtschaftlich Berechtigte haben, insbesondere solche, die Gewinne erzielen. In solchen Fällen \nwird dieses  Detail in den Satzungen erwähnt. Diese Personen werden leicht identifiziert und behandelt, zusammen mit den Mitgliedern des \nVerwaltungsrats.",
    "Additional Requirement: In cases involving a Power of Attorney or Delegation of Powers, both parties \n(the individual granting authority and the one receiving it) must provide a copy of their identification documents.": "Zusätzliche Anforderung: In Fällen, die eine Vollmacht oder eine Delegation von Befugnissen beinhalten, müssen beide Parteien\n(die Person, die die Vollmacht erteilt und die sie erhält) eine Kopie ihres Ausweises vorlegen.",
    "Sole Trader's Registry Extract (Entity's Registration/Creation Document)": "Auszug aus dem Register des Einzelunternehmers (Dokument zur Eintragung/Gründung des Unternehmens)",
    "This document serves as a local registry for sole traders, required in countries where such registration is mandatory. \nIt verifies that the individual is engaged in a professional activity as a sole trader. The name of this document varies from \ncountry to country.": "Dieses Dokument dient als lokales Register für Einzelunternehmer, erforderlich in Ländern, in denen eine solche Registrierung verpflichtend ist.\nEs bestätigt, dass die Person als Einzelunternehmer beruflich tätig ist. Der Name dieses Dokuments variiert von\nLand zu Land.",
    "Where applicable, the document should not be older than 3 months.": "Wo zutreffend, sollte das Dokument nicht älter als 3 Monate sein.",
    "The document must contain:": "Das Dokument muss enthalten:",
    "Individual's name": "Name des Einzelunternehmers",
    "Residential address": "Wohnadresse",
    "Registration number": "Registrierungsnummer",
    "The sole trader is the only individual subject to the identification document collection process.": "Der Einzelunternehmer ist die einzige Person, die dem Prozess der Sammlung von Identifikationsdokumenten unterliegt.",
    "No Transactions": "Keine Transaktionen",
    "No Pending Items": "Keine offenen Posten",
    "No Beneficiaries": "Keine Begünstigten",
    "Send Money To": "Geld an",
    "Confirmation": "senden",
    "You are setting": "Bestätigung",
    "as default.": "Sie stellen",
    "Previously": "als Standard ein.",
    "was default QR.": "Zuvor",
    "Category": "war Standard-QR.",
    "-Select-From-The-List-": "Kategorie",
    "Add a note": "Gebühren",
    "Categories Added Successfully!": "Eine Notiz hinzufügen",
    "Subscription starting date": "Kategorien erfolgreich hinzugefügt!",
    "Until": "Beginn des Abonnements",
    "Next Transaction": "Bis",
    "Transaction Method": "Nächste Transaktion",
    "Accept the quotation": "Transaktionsmethode",
    "Choose Currency": "Das Angebot akzeptieren",
    "Accept": "Währung wählen",
    "Bargain the quotation": "Akzeptieren",
    "Enter Counter Amount:": "Das Angebot verhandeln",
    "Bargain": "Gegenbetrag eingeben:",
    "Decline the quotation": "Verhandeln",
    "Are you sure, you want to decline this quotation?": "Das Angebot ablehnen",
    "Decline": "Sind Sie sicher, dass Sie dieses Angebot ablehnen möchten?",
    "Personal Account": "Ablehnen",
    "undefined": "Persönliches Konto",
    "Jan": "Jan",
    "Feb": "Feb",
    "Mar": "Mär",
    "Apr": "Apr",
    "May": "Mai",
    "Jun": "Jun",
    "Jul": "Jul",
    "Aug": "Aug",
    "Sep": "Sep",
    "Oct": "Okt",
    "Nov": "Nov",
    "Dec": "Dez",
    "Today": "Heute",
    "Spent In": "Verbracht in",
    "Revised Amount": "Überarbeiteter Betrag",
    "You spent more comparing to last month": "Du hast im Vergleich zum letzten Monat mehr ausgegeben",
    "Last": "Letzter",
    "days": "Tage",
    "Year": "Jahre",
    "Instant Mobile Top-up Across 150+ Countries": "Sofortiges Handy-Aufladen in Über 150 Ländern",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Freuen Sie sich darauf, Ihre Lieben zu erfreuen oder Ihr eigenes Telefon aufzuladen, egal wo Sie sich befinden! Mit dem Mobilfunk-Aufladeservice von InstaPay können Sie Handys in über 150 Ländern sofort aufladen. Perfekt für Geschenke oder um immer verbunden zu bleiben, unser Service ist für Einfachheit, Geschwindigkeit und Sicherheit konzipiert.",
    "How It Works - Step-by-Step Guide:": "Wie es funktioniert - Schritt-für-Schritt-Anleitung:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Wählen Sie 'Handy-Aufladen': Greifen Sie direkt in der InstaPay-App auf Plattformen wie WhatsApp, Twitter, Telegram oder Instagram auf diese Funktion zu.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Währung und Betrag wählen: Wählen Sie aus mehreren Währungen (USD, GBP, EUR usw.) und geben Sie an, wie viel Sie aufladen möchten.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Handynummer eingeben: Geben Sie die Nummer ein, die Sie aufladen möchten – Ihre eigene oder die eines geliebten Menschen. Unser System gewährleistet einen sicheren und schnellen Prozess.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Bestätigen und Bezahlen: Überprüfen Sie die Aufladedetails, bestätigen Sie diese und führen Sie die Zahlung mit Ihrer bevorzugten Methode durch. Die Transaktion wird sofort und sicher verarbeitet.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Sofortige Bestätigung erhalten: Sowohl Sie als auch der Empfänger (falls zutreffend) erhalten eine Bestätigungsnachricht, sobald die Aufladung erfolgreich war.",
    "Benefits:": "Vorteile:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Globale Reichweite: Senden Sie mühelos Guthaben in über 150 Länder.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Sofortige Wirkung: Sehen Sie sofort die Vorteile – perfekt für Last-Minute-Geschenke oder dringende Kommunikation.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Volle Sicherheit: Genießen Sie Sicherheit dank unseres sicheren Zahlungssystems.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Vielseitige Zahlungsoptionen: Wählen Sie zwischen Kreditkarten, PayPal oder Ihrem InstaPay-Wallet für Zahlungen.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Bereit, Freude zu verbreiten oder verbunden zu bleiben? Laden Sie jetzt auf und machen Sie jemandem den Tag – oder Ihren eigenen!",
    "Instant Mobile Top-up Across 150+ Countries": "Direct mobiel opwaarderen in meer dan 150 landen",
    "Delight your loved ones or recharge your own phone, no matter where you are! InstaPay’s Mobile Airtime service lets you instantly top up mobile phones in over 150 countries. Perfect for gifts or ensuring you're always connected, our service is designed for ease, speed, and security.": "Verras uw geliefden of laad uw eigen telefoon op, waar u ook bent! Met de mobiele luchttijdservice van InstaPay kunt u direct mobiele telefoons opwaarderen in meer dan 150 landen. Perfect voor cadeaus of om altijd verbonden te blijven, onze service is ontworpen voor gemak, snelheid en veiligheid.",
    "How It Works - Step-by-Step Guide:": "Hoe het werkt - Stap-voor-stap gids:",
    "1. Choose 'Mobile Top-up': Access this feature directly within the InstaPay app on platforms like WhatsApp, Twitter, Telegram, or Instagram.": "1. Kies 'Mobiel opwaarderen': Toegang tot deze functie direct binnen de InstaPay-app op platforms zoals WhatsApp, Twitter, Telegram of Instagram.",
    "2. Select Currency and Amount: Pick from multiple currencies (USD, GBP, EUR, etc.) and specify how much you want to top up.": "2. Selecteer Valuta en Bedrag: Kies uit meerdere valuta's (USD, GBP, EUR, enz.) en specificeer hoeveel u wilt opwaarderen.",
    "3. Enter the Mobile Number: Type in the number you wish to recharge—yours or that of a loved one. Our system ensures a secure and swift process.": "3. Voer het mobiele nummer in: Typ het nummer in dat u wilt opladen—van uzelf of van een dierbare. Ons systeem zorgt voor een veilig en snel proces.",
    "4. Confirm and Pay: Double-check the top-up details, confirm, and make the payment using your preferred method. The transaction processes instantly and securely.": "4. Bevestigen en Betalen: Controleer de oplaadgegevens, bevestig en maak de betaling met uw voorkeursmethode. De transactie wordt onmiddellijk en veilig verwerkt.",
    "5. Receive Instant Confirmation: Both you and the recipient (if applicable) will receive a confirmation notification once the top-up is successful.": "5. Ontvang directe bevestiging: Zowel u als de ontvanger (indien van toepassing) ontvangen een bevestigingsmelding zodra de opwaardering succesvol is.",
    "Benefits:": "Voordelen:",
    "Global Reach: Effortlessly send airtime to over 150 countries.": "Wereldwijd bereik: Stuur moeiteloos luchttijd naar meer dan 150 landen.",
    "Instant Impact: See the benefits immediately—perfect for last-minute gifts or urgent communications.": "Direct effect: Zie de voordelen meteen—perfect voor last-minute cadeaus of dringende communicatie.",
    "Complete Security: Enjoy peace of mind with our secure payment system.": "Volledige Veiligheid: Geniet van gemoedsrust met ons veilige betalingssysteem.",
    "Versatile Payment Options: Choose from credit cards, PayPal, or your InstaPay wallet for payments.": "Veelzijdige Betaalopties: Kies uit creditcards, PayPal, of uw InstaPay-portemonnee voor betalingen.",
    "Ready to spread joy or stay connected? Top-up now and make someone’s day—or your own!": "Klaar om vreugde te verspreiden of verbonden te blijven? Waardeer nu op en maak iemands dag—of de uwe!",
    "Pick a language": "Wählen Sie eine Sprache",
    "French": "Französisch",
    "Spanish": "Spanisch",
    "Portuguese": "Portugiesisch",
    "German": "Deutsch",
    "Ukrainian": "Ukrainisch",
    "Italian": "Italienisch",
    "Russian": "Russisch",
    "Arabic": "Arabisch",
    "Polish": "Polnisch",
    "Dutch (Nederlands)": "Niederländisch",
    "Yoruba": "Yoruba",
    "Indonesian": "Indonesisch",
    "Turkish": "Türkisch",
    "Swahili": "Swahili",
    "Hausa": "Hausa",
    "Hindi": "Hindi",
    "Urdu": "Urdu",
    "Chinese": "Chinesisch",
    "Select Your Preferred Language": "Wählen Sie Ihre bevorzugte Sprache",
    "Please select the language you would \nlike to use for the eKYC process.": "Bitte wählen Sie die Sprache aus, die Sie für den eKYC-Prozess verwenden möchten.",
    "Select Your Nationality": "Wählen Sie Ihre Nationalität",
    "Choose your nationality to see the \nsupported ID documents for  your country.": "Wählen Sie Ihre Nationalität, um die unterstützten Ausweisdokumente für Ihr Land anzuzeigen.",
    "Grant permission to use the camera.": "Erlauben Sie die Nutzung der Kamera.",
    "Tap or click \"Allow\" when prompted to \ngrant permission for camera access to \ncapture the document.": "Tippen oder klicken Sie auf \"Zulassen\", wenn Sie aufgefordert werden, die Erlaubnis zur Kameranutzung zu erteilen, um das Dokument zu erfassen.",
    "Upload Your Documents from \nGallery/Photos": "Laden Sie Ihre Dokumente aus der Galerie/Fotos hoch",
    "If you encounter issues with your camera \nor if your system doesn't have one, you can\n manually upload photos of the document \nfrom your local storage.": "Wenn Sie Probleme mit Ihrer Kamera haben oder Ihr System keine hat, können Sie Fotos des Dokuments manuell aus Ihrem lokalen Speicher hochladen.",
    "Upload Your ID.": "Laden Sie Ihren Ausweis hoch.",
    "Make sure your ID is clear and not blurry to \nensure quick verification.": "Stellen Sie sicher, dass Ihr Ausweis klar und nicht verschwommen ist, um eine schnelle Überprüfung zu gewährleisten.",
    "Valid Proof of Address": "Gültiger Adressnachweis",
    "1. Ensure that your proof of address is \ncurrent and that the statement date is \nwithin the last three months from the \nstart date of your eKYC verification.\n\n2. Make sure your proof of address is clear \nand not blurry to ensure quick verification.": "1. Stellen Sie sicher, dass Ihr Adressnachweis aktuell ist und das Datum des Kontoauszugs innerhalb der letzten drei Monate ab dem Startdatum Ihrer eKYC-Überprüfung liegt.\n\n2. Stellen Sie sicher, dass Ihr Adressnachweis klar und nicht verschwommen ist, um eine schnelle Überprüfung zu gewährleisten.",
    "Important Notice\n\nPlease carefully review all the steps in this guideline before proceeding with your eKYC verification.\n\nAvoid Common Mistakes:\n\n* Ensure all your documents are clear, legible, and up-to-date.\n* Double-check that the information you provide matches the details on your official documents.\n* Follow the instructions precisely to avoid delays or rejections.\n\nNote: \nIf your eKYC verification fails and you need to resubmit, you will be charged again for the resubmission process.\n\nBy following these guidelines, you can help ensure a smooth and successful eKYC verification.\n\nFor any questions or assistance, please contact our support team at support@insta-pay.ch ": "Wichtiger Hinweis\n\nBitte lesen Sie alle Schritte in dieser Anleitung sorgfältig durch, bevor Sie mit Ihrer eKYC-Verifizierung fortfahren.\n\nVermeiden Sie häufige Fehler:\n\n* Stellen Sie sicher, dass alle Ihre Dokumente klar, lesbar und aktuell sind.\n* Überprüfen Sie, ob die von Ihnen angegebenen Informationen mit den Angaben in Ihren offiziellen Dokumenten übereinstimmen.\n* Befolgen Sie die Anweisungen genau, um Verzögerungen oder Ablehnungen zu vermeiden.\n\nHinweis: \nWenn Ihre eKYC-Verifizierung fehlschlägt und Sie sie erneut einreichen müssen, werden Ihnen für den erneuten Einreichungsprozess zusätzliche Gebühren berechnet.\n\nIndem Sie diese Richtlinien befolgen, können Sie eine reibungslose und erfolgreiche eKYC-Verifizierung sicherstellen.\n\nBei Fragen oder für Unterstützung wenden Sie sich bitte an unser Support-Team unter support@insta-pay.ch",
    "Personal Transactions": "Persönliche Transaktionen",
    "Business Transactions": "Geschäftstransaktionen",
    "Shopping & Purchases": "Einkaufen und Einkäufe",
    "Bills & Utilities": "Rechnungen und Versorgungsleistungen",
    "Entertainment & Leisure": "Unterhaltung und Freizeit",
    "Investments & Savings": "Investitionen und Einsparungen",
    "Health & Wellness": "Gesundheit und Wellness",
    "Transportation": "Transport",
    "Education": "Bildung",
    "Miscellaneous": "Verschiedenes",
    "Family Support": "Familienunterstützung",
    "WALLET": "WALLET",
    "Add a currency": "Währung hinzufügen",
    "When you add a currency, any payments you receive in that currency will be credited to your InstaPay balance. Your primary currency will be used for sending or requesting payments unless specified otherwise.": "Wenn Sie eine Währung hinzufügen, werden alle Zahlungen, die Sie in dieser Währung erhalten, Ihrem InstaPay-Guthaben gutgeschrieben. Ihre Hauptwährung wird für das Senden oder Anfordern von Zahlungen verwendet, es sei denn, anders angegeben.",

    "balance amount ": "Saldo Betrag",
    "referral ": "Empfehlung",
    "crypto wallet balance": "Krypto-Wallet-Saldo",
    "money in": "Geld rein",
    "money out": "Geld raus",
    "analytics": "Analytik",
    "see more": "mehr sehen",
    "manage my Wallets and see the transaction details": "meine Wallets verwalten und Transaktionsdetails einsehen",
    "set as default Wallet": "als Standard-Wallet festlegen",
    "add currency": "Währung hinzufügen",
    "convert funds ": "Gelder umwandeln",
    "withdraw balance": "Saldo abheben",
    "chart names": "Diagrammnamen",
    "withdraw balance from PKR wallet": "Saldo aus der PKR-Wallet abheben",
    "select a payout channel": "einen Auszahlungskanal auswählen",
    "you have entered an amount below the minimum payment range": "Sie haben einen Betrag unterhalb des erforderlichen Mindestbetrags eingegeben",
    "change": "ändern",
    "edit": "bearbeiten",
    "moving to next step in ...": "nächster Schritt in ...",
    "you are withdrawing ...": "Sie heben ab ...",
    "where is my payment": "wo ist meine Zahlung",
    "download": "herunterladen",
    "IBAN / SWIFT Code": "IBAN oder SWIFT/BIC-Code",
    "swift code": "SWIFT-Code",
    "account number": "Kontonummer",
    "branch street": "Filialstraße",
    "city": "Stadt",
    "province": "Provinz",
    "postal code": "Postleitzahl",
    "all payout channels": "alle Auszahlungskanäle",
    "bussiness beneficiary": "geschäftlicher Begünstigter",
    "no quotation": "kein Angebot",
    "no pending items": "keine ausstehenden Elemente",
    "QR status": "QR-Status",
    "Portfolio": "Portfolio",
    "edit whole portfolio page": "ganze Portfolio-Seite bearbeiten",
    "personal account": "persönliches Konto",
    "Chart names": "Diagrammnamen",
    "instagram": "instagram",
    "facebook": "facebook",
    "twitter": "twitter",
    "more": "mehr",
    "not connected": "nicht verbunden",
    "code": "Code",
    "ratings": "Bewertungen",
    "current password": "aktuelles Passwort",
    "include at least one uppercase and lowercase": "mindestens einen Groß- und Kleinbuchstaben enthalten",
    "my social network accounts": "meine sozialen Netzwerkkonten",
    "SMS ": "SMS",
    "view identity verfication guide": "Identitätsüberprüfungsanleitung anzeigen",
    "boimetric face recognition": "biometrische Gesichtserkennung",
    "please select a payer (withdrawl options)": "bitte wählen Sie einen Zahler (Abhebungsoptionen)",
    "IBAN number ": "IBAN-Nummer",
    "account holder name": "Name des Kontoinhabers",
    "download data": "Daten herunterladen",
    "bank transfer": "Banküberweisung",
    "mobile wallet": "mobile Geldbörse",
    "all bank names": "alle Banknamen",
    "pay via card": "mit Karte bezahlen",
    "you have entered an amount that is below the payer range": "Sie haben einen Betrag eingegeben, der unter dem Zahlerbereich liegt",
    "trouble sending money": "Probleme beim Geld senden",
    "please follow these example": "bitte folgen Sie diesen Beispielen",
    "wallet ID ": "Wallet-ID",
    "username": "Benutzername",
    "email": "E-Mail",
    "phone number": "Telefonnummer",
    "no user found": "kein Benutzer gefunden",
    "search": "Suche",
    "scan/upload QR code of the recipient": "QR-Code des Empfängers scannen/hochladen",
    "invite them": "sie einladen",
    "trouble quoting money": "Problem beim Angebot",
    "select a country": "Land auswählen",
    "airtime details": "Guthabensdetails",
    "enter the phone number": "Telefonnummer eingeben",
    "service type": "Diensttyp",
    "select a service": "Dienst auswählen",
    "service list": "Dienstliste",
    "amount entered is less than the ....": "der eingegebene Betrag ist geringer als ....",
    "total": "Gesamt",
    "total credit the receiver gets": "gesamtguthaben, das der Empfänger erhält",
    "take ": "nehmen",
    "OTP has been verified , You'll be redirected......": "OTP wurde verifiziert, Sie werden weitergeleitet......",
    "Link Your Instagram Account to InstaPay": "Verknüpfen Sie Ihr Instagram-Konto mit InstaPay",
    "Step 01 – From Instagram App": "Schritt 01 – Von der Instagram-App",
    "Open the Instagram app, search for the \"InstaPay\" profile page, select \"Message\" to open the chat box, type/say \"Hi\" and click send to initiate the conversation with the InstaPay chatbot.": "Öffnen Sie die Instagram-App, suchen Sie die \"InstaPay\"-Profilseite, wählen Sie \"Nachricht\", um das Chat-Fenster zu öffnen, tippen/sagen Sie \"Hallo\" und klicken Sie auf Senden, um das Gespräch mit dem InstaPay-Chatbot zu beginnen.",
    "Step 02 – From InstaPay Instagram Profile Page ": "Schritt 02 – Von der InstaPay-Instagram-Profilseite",
    "InstaPay chatbot will display two action buttons: \"Register\" and \"Connect.\" Select \"Connect,\" and the chatbot will prompt you to enter your InstaPay username.": "Der InstaPay-Chatbot zeigt zwei Aktionsbuttons an: \"Registrieren\" und \"Verbinden\". Wählen Sie \"Verbinden\" und der Chatbot fordert Sie auf, Ihren InstaPay-Benutzernamen einzugeben.",
    "Step 03 – From InstaPay Instagram Profile Page": "Schritt 03 – Von der InstaPay-Instagram-Profilseite",
    "The InstaPay chatbot will then ask for the InstaPay verification code. Enter the code you obtained from the InstaPay settings under \"My Social Network Accounts\" and click send.": "Der InstaPay-Chatbot wird dann nach dem InstaPay-Verifizierungscode fragen. Geben Sie den Code ein, den Sie in den InstaPay-Einstellungen unter \"Meine sozialen Netzwerkkonten\" erhalten haben, und klicken Sie auf Senden.",
    "Step 04 – From InstaPay Instagram Profile Page": "Schritt 04 – Von der InstaPay-Instagram-Profilseite",
    "You will receive a congratulatory message confirming that your Instagram account is now synced with InstaPay. Click on \"Main Menu\" to start using InstaPay services.": "Sie erhalten eine Glückwunschnachricht, die bestätigt, dass Ihr Instagram-Konto nun mit InstaPay synchronisiert ist. Klicken Sie auf \"Hauptmenü\", um die InstaPay-Dienste zu nutzen.",
    "Additional Tips": "Zusätzliche Tipps",
    "1.\tSecure Your Account: Ensure your Instagram account is secure by enabling two-factor authentication (2FA) to protect your linked InstaPay services.": "1. Sichern Sie Ihr Konto: Stellen Sie sicher, dass Ihr Instagram-Konto durch Aktivieren der Zwei-Faktor-Authentifizierung (2FA) gesichert ist, um Ihre verbundenen InstaPay-Dienste zu schützen.",
    "2.\tExplore More: Once linked, explore the wide range of services InstaPay offers, from seamless transactions to instant withdrawals.": "2. Mehr entdecken: Sobald verknüpft, erkunden Sie die breite Palette von Diensten, die InstaPay anbietet, von nahtlosen Transaktionen bis hin zu sofortigen Auszahlungen.",
    "3.\tStay Updated: Keep an eye on our updates, as we will soon support linking other social media accounts to make your experience even more integrated.": "3. Bleiben Sie auf dem Laufenden: Behalten Sie unsere Updates im Auge, da wir bald die Verknüpfung anderer sozialer Netzwerkkonten unterstützen werden, um Ihr Erlebnis noch integrierter zu gestalten.",
    "4.\tHelp & Support: If you face any issues during the linking process, contact our support team for assistance.": "4. Hilfe & Support: Wenn Sie während des Verknüpfungsprozesses auf Probleme stoßen, kontaktieren Sie unser Support-Team für Unterstützung.",
    "Future Enhancements": "Zukünftige Verbesserungen",
    "In the near future, InstaPay will allow you to link more social media accounts, providing you with even more flexibility and convenience. Stay tuned for exciting updates!": "In naher Zukunft wird InstaPay Ihnen ermöglichen, mehr soziale Netzwerkkonten zu verknüpfen, was Ihnen noch mehr Flexibilität und Komfort bietet. Bleiben Sie gespannt auf aufregende Updates!",
    "Personal Transactions": "Persönliche Transaktionen",
    "Business Transactions": "Geschäftstransaktionen",
    "Shopping & Purchases": "Einkaufen und Einkäufe",
    "Bills & Utilities": "Rechnungen und Versorgungsleistungen",
    "Entertainment & Leisure": "Unterhaltung und Freizeit",
    "Investments & Savings": "Investitionen und Einsparungen",
    "Health & Wellness": "Gesundheit und Wellness",
    "Transportation": "Transport",
    "Education": "Bildung",
    "Miscellaneous": "Verschiedenes",
    "Family Support": "Familienunterstützung",
    "Login With Phone Number": null,
    "Login": "Login",
    "Start Your InstaPay Journey Today!": "Starten Sie Ihre InstaPay-Reise noch heute!",
    "Enter your phone number": "Geben Sie Ihre Telefonnummer ein",
    "Login with email": "Mit E-Mail anmelden",
    "Don't have an account?": "Haben Sie kein Konto?",
    "Sign Up": "Registrieren",
    "Continue": "Fortsetzen",
    "We recommend you download and install the Google Authenticator app": "Wir empfehlen Ihnen, die Google Authenticator-App herunterzuladen und zu installieren",
    "Two-factor authentication (2FA) is the best way to protect yourself online.": "Zwei-Faktor-Authentifizierung (2FA) ist der beste Weg, um sich online zu schützen.",
    "Strong security with Google Authenticator": "Starke Sicherheit mit Google Authenticator",
    "Enter your password": "Geben Sie Ihr Passwort ein",
    "Web Mobile": null,
    "We recommend you download and install the Google Authenticator app to ensure you receive your verification codes instantly, avoiding any SMS delays or issues.": "Wir empfehlen Ihnen, die Google Authenticator-App herunterzuladen und zu installieren, um sicherzustellen, dass Sie Ihre Verifizierungscodes sofort erhalten, ohne Verzögerungen oder Probleme mit SMS.",
    "Web - Google Authenticator": null,
    "Google Authenticator": "Google Authenticator",
    "Two Factor Authentication Code": "Zwei-Faktor-Authentifizierungscode",
    "Problem with the token?": "Problem mit dem Token?",
    "Verify using SMS": "Über SMS verifizieren",
    "Verify": "Verifizieren",
    "Web Mobile - Google Authenticator": null,
    "SMS - Web ": null,
    "SMS Verification": "SMS-Verifizierung",
    "Enter the SMS code sent to +41********4053": "Geben Sie den SMS-Code ein, der an +41********4053 gesendet wurde",
    "Verify Using Authenticator App": "Verifizieren Sie sich mit der Authenticator-App",
    "Resend code in 120 seconds": "Code in 120 Sekunden erneut senden",
    "Verify ": "Verifizieren",
    "SMS - Web Mobile": null,
    "My own account": "Mein eigenes Konto",
    "Friends & Family": "Freunde & Familie",
    "Aunt": "Tante",
    "Brother-in-law": "Schwager",
    "Cousin": "Cousin",
    "Daughter": "Tochter",
    "Father": "Vater",
    "Father-in-law": "Schwiegervater",
    "Friend": "Freund",
    "Grandfather": "Großvater",
    "Grandmother": "Großmutter",
    "Husband": "Ehemann",
    "Mother": "Mutter",
    "Mother-in-law": "Schwiegermutter",
    "Nephew": "Neffe",
    "Niece": "Nichte",
    "Self (i.e. the sender, himself)": "Selbst (d.h. der Absender selbst)",
    "Sister": "Schwester",
    "Sister-in-law": "Schwägerin",
    "Son": "Sohn",
    "Uncle": "Onkel",
    "Wife": "Ehefrau",
    "Others not listed": "Andere nicht aufgeführt",
    "Merchant": "Händler",
    "No relationship": "Keine Beziehung",
    "Add Funds": "Geld hinzufügen",
    "Enter the Amount": "Geben Sie den Betrag ein:",
    "Fees": "Gebühren:",
    "You Will Get": "Sie erhalten:",
    "⚠️ The transactional value should be between[Currency ISO code & Amount] - [Currency ISO code & Amount] ": "⚠️ Der Transaktionswert sollte zwischen [Currency ISO code & Amount] - [Currency ISO code & Amount] liegen",
    "⚠️ Invalid Amount: Your current  identity verification level limits the  maximum amount you can add. Please upgrade your identity verification to benefit from higher limits.           ": "⚠️ Ungültiger Betrag: Ihr aktuelles Identitätsüberprüfungsniveau begrenzt den maximalen Betrag, den Sie hinzufügen können. Bitte aktualisieren Sie Ihre Identitätsüberprüfung, um von höheren Limits zu profitieren.",
    "ℹ️ The price of currency changes frequently based on market conditions. Please refer to the price on the confirm order page as your final quotation": "ℹ️ Der Währungspreis ändert sich häufig basierend auf den Marktbedingungen. Bitte beziehen Sie sich auf den Preis auf der Bestellbestätigungsseite als Ihr endgültiges Angebot.",
    "Next": "Weiter",
    "Upgrade Identity Verification": "Identitätsüberprüfung aktualisieren",
    "Request money from anyone, anywhere, including across social networking platforms. With InstaPay, your payment requests are instantly accessible once accepted and funds are sent to your Receivable Accounts. Simplify your transactions and manage payments with ease and security.": "Fordern Sie Geld von jedem, überall an, einschließlich über soziale Netzwerke. Mit InstaPay sind Ihre Zahlungsanforderungen sofort zugänglich, sobald sie akzeptiert werden, und die Gelder werden auf Ihre Empfangskonten überwiesen. Vereinfachen Sie Ihre Transaktionen und verwalten Sie Zahlungen mit Leichtigkeit und Sicherheit.",
    "You’ve been logged out.": "Sie wurden ausgeloggt.",
    "For your security, you’ve been logged out after 15 minutes of inactivity. Please log in again if you wish to use InstaPay.": "Zu Ihrer Sicherheit wurden Sie nach 15 Minuten Inaktivität ausgeloggt. Bitte loggen Sie sich erneut ein, wenn Sie InstaPay weiter nutzen möchten.",
    "LOGIN AGAIN": "ERNEUT ANMELDEN",
    "Brother": "Bruder",
    "Current Password": "Aktuelles Passwort",
    "The new password cannot be the same as the old one": "Das neue Passwort darf nicht mit dem alten übereinstimmen.",
    "Minimum password length: [X] characters ": "Minimale Passwortlänge: [X] Zeichen",
    "Include at least one uppercase and one lowercase letter.": "Mindestens einen Großbuchstaben und einen Kleinbuchstaben enthalten.",
    "Nominee Information": "Informationen zu nominierten Personen",
    "Verification Code Preferences": "Präferenzen für den Bestätigungscode",
    "How Others Can Find You": "Wie andere Sie finden können",
    "My Social Network Accounts": "Meine sozialen Netzwerkkonten",
    "Login Activity": "Anmeldeaktivität",
    "Receiving Accounts": "Empfangskonten",
    "Change Your Password To Continue": "Ändern Sie Ihr Passwort, um fortzufahren",
    "Continue with": "Weiter mit",
    "Back to Login": "Zurück zur Anmeldung",
    "Send OTP": "OTP senden",
    "A Verification code has been sent to your email and number.": "Ein Bestätigungscode wurde an Ihre E-Mail und Nummer gesendet.",
    "Resend available in": "Erneut senden verfügbar in",
    "Create a strong Password": "Erstellen Sie ein starkes Passwort",
    "Password must:": "Das Passwort muss:",
    "Be between 9 and 64 characters": "Zwischen 9 und 64 Zeichen lang sein",
    "Include at least two of the following:": "Mindestens zwei der folgenden enthalten:",
    "Lowercase Character": "Kleinbuchstaben",
    "Special Character": "Sonderzeichen",
    "Password Updated Successfully!": "Passwort erfolgreich aktualisiert!",
    "Click below to login": "Klicken Sie unten, um sich anzumelden",

    "Send Money": "Geld senden",
    "Enter the amount": null,
    "Recent Transfer": "Letzte Überweisung",
    "See all": "Alles sehen",
    "Send again": "Erneut senden",
    "You Send": "Du sendest",
    "They Receive": "Sie erhalten",
    "Receive Method": "Empfangsmethode",
    "Select method": "Methode auswählen",
    "Next": "Weiter",
    "Trouble sending money?": "Probleme beim Senden von Geld?",
    "Where do you want to send money ?": "Wohin möchten Sie Geld senden?",
    "Search ": "Suche",
    "Confirm": "Bestätigen",
    "Bank Deposit": "Bankeinzahlung",
    "Cash Pick-up": "Bargeldabholung",
    "Mobile Wallet": "Mobile Wallet",
    "Bank Card": "Bankkarte",
    "Crypto Wallet": "Wallet crypto",
    "What is the receiver’s Mobile Wallet?": "Was ist das Wallet mobil des Empfängers?",
    "Transfer fees": "Überweisungsgebühren",
    "Total to pay": "Gesamtbetrag",
    "Choose the beneficiary!": "Wählen Sie den Begünstigten!",
    "Country": "Land",
    "Receive method": "Empfangsmethode",
    "You send": "Du sendest",
    "change": "ändern",
    "edit": "neu hinzufügen",
    "add new": "Begünstigte",
    "Beneficiaries": "Begünstigte verwalten",
    "Manage Beneficiaries": "Suche",
    "Search": "Weiter",
    "CASH PICK-UP": "BARGELDABHOLUNG",
    "Where will your receiver collect the money": "Wo wird Ihr Empfänger das Geld abholen?",
    "Cash is available to collect within minutes from any branch of [name] ": "Bargeld ist innerhalb von Minuten in jeder Filiale von [Name] verfügbar",
    "BANK ACCOUNT": "BANKKONTO",
    "Transfer to recipient’s bank account": "Überweisung auf das Bankkonto des Empfängers",
    "BANK CARD": "BANKKARTE",
    "Fast transfer directly to recipient’s bank card": "Schnelle Überweisung direkt auf die Bankkarte des Empfängers",
    "CRYPTO WALLET": "WALLET CRYPTO",
    "Fast transfer directly to a crypto wallet": "Schnelle Überweisung direkt auf ein Wallet crypto",
    "Review transfer": "Überweisung überprüfen",
    "Receiver's Details": "Empfängerdetails",
    "Edit": "Bearbeiten",
    "Name": "Name",
    "Reason for sending": "Grund für das Senden",
    "Sending": "Senden",
    "Receiver gets": "Empfänger erhält",
    "Exchange rate": "Wechselkurs",
    "Estimated time": "Geschätzte Zeit",
    "Select Payment Type": "Zahlungsmethode auswählen",
    "Credit cards": "Kreditkarten",
    "Domestic cards": "Inländische Karten",
    "Mobile Wallets": "Wallets mobil",
    "InstaPay Wallet": "Wallet InstaPay",
    "Crypto (USDT, ETH, BTC)": "Crypto (USDT, ETH, BTC)",
    "PayPal": "PayPal",
    "CREDIT CARD": "KREDITKARTE",
    "Credit Card": "Kreditkarte",
    "Select card": "Karte auswählen",
    "Card Number": "Kartennummer",
    "Enter the 16-digit card number on the card": "Geben Sie die 16-stellige Kartennummer ein",
    "Expiry Date": "Ablaufdatum",
    "Enter the expiration date of the card": "Geben Sie das Ablaufdatum der Karte ein",
    "CVV Number": "CVV-Nummer",
    "Enter the 3 or 4 digit number on the card": "Geben Sie die 3- oder 4-stellige Nummer der Karte ein",
    "INSTAPAY WALLET": "WALLET INSTAPAY",
    "Select Wallet": "Wallet auswählen",
    "My Wallet": "Mein Wallet",
    "Conversion": "Konvertierung",
    "Convert": "Konvertieren",
    "Your payment is confirmed": "Ihre Zahlung ist bestätigt",
    "Thanks for using InstaPay": "Danke, dass Sie InstaPay verwenden",
    "Status": "Status",
    "Share": "Teilen",
    "Transfer created": "Überweisung erstellt",
    "Payment received": "Zahlung erhalten",
    "Payment processed": "Zahlung verarbeitet",
    "Transfer successful": "Überweisung erfolgreich",
    "It may take few hours for the funds to appear in [user name] account": "Es kann einige Stunden dauern, bis die Gelder auf dem Konto von [Benutzername] erscheinen",
    "Transfer failed": "Überweisung fehlgeschlagen",
    "Transaction Details": "Transaktionsdetails",
    "Need help?": "Brauchen Sie Hilfe?",
    "Service Details": "Servicedetails",
    "Funds Deposit Speed": "Verfügbarkeit der Gelder",
    "Cut-off Time": "Annahmeschlusszeit",
    "Bank holiday list": "Liste der Bankfeiertage",
    "Threshold limits": "Transaktionslimits",
    "null": null,
    "Instant, Real Time": "Sofort, in Echtzeit",
    "Same day, T+1": "Gleicher Tag, T+1",
    "Non-instant, T+1": "Nicht sofort, T+1",
    "Instant Payment, Real Time": "Sofortige Zahlung, in Echtzeit",
    "Transaction processed before 3:00 PM Local time": "Transaktion vor 15:00 Uhr Ortszeit bearbeitet",
    "Delivered the same day": "Am selben Tag geliefert",
    "Transactions processed post 3:00 PM": "Transaktionen nach 15:00 Uhr bearbeitet",
    "T+1 business days": "T+1 Geschäftstage",
    "Payments made on weekdays before 3:00PM will be credited to the receiving bank account by midnight of the\nsame day.": "Zahlungen, die an Werktagen vor 15:00 Uhr getätigt werden, werden bis Mitternacht desselben Tages dem Empfängerkonto gutgeschrieben.",
    "Payments made on weekdays after 15:00 will be credited by midnight the following day.": "Zahlungen, die an Werktagen nach 15:00 Uhr getätigt werden, werden bis Mitternacht des folgenden Tages gutgeschrieben.",
    "Payments made on a Saturday, Sunday or Public holiday will be credited to the account by midnight of the 1st following business day": "Zahlungen, die an einem Samstag, Sonntag oder Feiertag getätigt werden, werden dem Konto bis Mitternacht des nächsten Geschäftstages gutgeschrieben.",
    "24*7": "24*7",
    "09hrs - 18 hrs Local Time": "09:00 - 18:00 Ortszeit",
    "https://www.officeholidays.com/countries ": "https://www.officeholidays.com/countries",
    "Min per transaction limit:": "Mindestbetrag pro Transaktion:",
    "Maximum per transaction limit:": "Maximalbetrag pro Transaktion:",
    "Daily threshold limit:": "Tägliches Limit:",
    "Weekly threshold limit:": "Wöchentliches Limit:",
    "Monthly threshold limit:": "Monatliches Limit:",
    "Yearly threshold limit:": "Jährliches Limit:",
    "P2P": "P2P",
    "P2B": "P2B",
    "B2P": "B2P",
    "B2B": "B2B",
    "WALLET TO WALLET": "WALLET TO WALLET",
    "Basic Account (Unverified)": "Basis Konto (Unverifiziert)",
    "Identity Verified": "Identität verifiziert",

    "Good Evening!": "Guten Abend!",
    "Total Account Balance": "Gesamtsalden",
    "Quick Funds Transfer": "Schnelle Geldüberweisung",
    "No transactions found": "Keine Transaktionen gefunden",
    "Blocked balance": "Gesperrtes Guthaben",
    "Available balance": "Verfügbares Guthaben",
    "Insufficient Balance in PKR": "Unzureichendes Guthaben in PKR",
    "Chat with us": "Chatten Sie mit uns",
    "Add Currency": "Währung hinzufügen",
    "Education": "Bildung",
    "Miscellaneous": "Sonstiges",
    "Family support": "Familienunterstützung",
    "Other Expenses": "Andere Ausgaben",
    "Transport": "Transport",
    "Scheduled Payment": "Geplante Zahlung",
    "Recurring Payment": "Wiederkehrende Zahlung",
    "No payment found": "Keine Zahlung gefunden",
    "No Beneficiaries Available": "Keine Begünstigten verfügbar",
    "Postal Code": "Postleitzahl",
    "Additional Information": "Zusätzliche Informationen",
    "Gender": "Geschlecht",
    "Date of Birth": "Geburtsdatum",
    "Nationality": "Nationalität",
    "Occupation (all options also)": "Beruf",
    "ID Type (all options also)": "ID-Typ",
    "ID Number": "ID-Nummer",
    "Select Beneficiary Payment Channels": "Zahlungskanäle für Begünstigte auswählen",
    "Crypto Wallet": "Krypto-Wallet",
    "Add another wallet": "Fügen Sie eine weitere Brieftasche hinzu",
    "Business": "Geschäft",
    "Business beneficiaries are coming soon! You will be able to conduct business transactions globally, expanding your reach across borders.\n": "Geschäftsempfänger kommen bald! Sie können globale Geschäftstransaktionen durchführen und Ihre Reichweite über Grenzen hinweg erweitern.",
    "Joined on": "Beigetreten am",
    "Profile link": "Profil-Link",
    "Commission": "Provision",
    "My Withdrawals": "Meine Abhebungen",
    "My Referrals": "Meine Empfehlungen",
    "Search Quotes": "Zitate suchen",
    "No quotes found": "Keine Zitate gefunden",
    "Actions": "Aktionen",
    "Search request": "Suchanfrage",
    "No pending items found": "Keine ausstehenden Elemente gefunden",
    "Share your QR Code or pay link to instantly receive payments in your InstaPay wallet": "Teilen Sie Ihren QR-Code oder Zahlungslink, um sofort Zahlungen in Ihrer InstaPay-Brieftasche zu erhalten",
    "Description": "Beschreibung",
    "Save": "Speichern",
    "Share your unique InstaPay payment link": "Teilen Sie Ihren einzigartigen InstaPay-Zahlungslink",
    "Elevate your public profile. Your portfolio acts as a dynamic showcase, accessible directly from your Payment Address": "Erhöhen Sie Ihr öffentliches Profil. Ihr Portfolio fungiert als dynamische Präsentation, die direkt von Ihrer Zahlungsadresse aus zugänglich ist",
    "Connect with your audience through your professional or creative journey. Let your work showcase itself and engage potential clients or supporters more deeply.\n": "Verbinden Sie sich mit Ihrem Publikum durch Ihre professionelle oder kreative Reise. Lassen Sie Ihre Arbeit für sich sprechen und potenzielle Kunden oder Unterstützer tiefer ansprechen.",
    "Display your social media reach. Visitors can see your social media impact, adding credibility to your portfolio.\n": "Zeigen Sie Ihre Social-Media-Reichweite an. Besucher können Ihre Social-Media-Wirkung sehen, was Ihrem Portfolio Glaubwürdigkeit verleiht.",
    "Enable visitors to request or initiate payments directly from your public page. Your portfolio is not just a showcase but also a way to facilitate easy financial transactions.\n": "Ermöglichen Sie es Besuchern, Zahlungen direkt von Ihrer öffentlichen Seite anzufordern oder zu initiieren. Ihr Portfolio ist nicht nur eine Ausstellung, sondern auch eine Möglichkeit, einfache finanzielle Transaktionen zu erleichtern.",
    "Use the \"About Me\" section to share your story, your mission, or vision, building a personal connection with your audience.": "Verwenden Sie den Abschnitt „Über mich“, um Ihre Geschichte, Ihre Mission oder Vision zu teilen und eine persönliche Verbindung zu Ihrem Publikum aufzubauen.",
    "\"My Portfolio\" is more than just a gallery; it's a tool for building your brand, engaging with your audience, and simplifying financial interactions on InstaPay.": "„Mein Portfolio“ ist mehr als nur eine Galerie; es ist ein Werkzeug, um Ihre Marke aufzubauen, mit Ihrem Publikum zu interagieren und finanzielle Interaktionen auf InstaPay zu vereinfachen.",
    "No Portfolio Items Found": "Keine Portfolio-Elemente gefunden",
    "Add New Portfolio Item": "Neues Portfolio-Element hinzufügen",
    "Title": "Titel",
    "Drag/Drop or upload a file (photo/video/pdf)": "Ziehen/Ablegen oder eine Datei hochladen (Foto/Video/PDF)",
    "Discard": "Verwerfen",
    "Save and Post": "Speichern und Posten",
    "My Payment Address": "Meine Zahlungsadresse",
    "Perfect for influencers, artists, entrepreneurs, or anyone who wants to let their community easily show support.": "Perfekt für Influencer, Künstler, Unternehmer oder jeden, der seiner Community einfach Unterstützung zeigen möchte.",
    "Personal Account": "Persönliches Konto",
    "Total Spent": "Gesamt Ausgegeben",
    "Personal Account Status": "Status des persönlichen Kontos",
    "Not Connected": "Nicht verbunden",
    "More": "Mehr",
    "Less": "Weniger",
    "Profile Reports": "Profilberichte",
    "All notifications are in English": "Alle Benachrichtigungen sind in Englisch",
    "Timezone": "Zeitzone",
    "Nominee Information": "Nominee-Informationen",
    "Full Name": "Vollständiger Name",
    "Date Of Birth": "Geburtsdatum",
    "Relation with Nominee": "Beziehung zum Nominierten",
    "Source": "Quelle",
    "Google Authenticator": "Google Authenticator",
    "Notifications": "Benachrichtigungen",
    "Login": "Anmelden",
    "View Identity Verification Guide": "Anleitung zur Identitätsverifizierung anzeigen",
    "Verified": "Verifiziert",
    "Select": "Auswählen",
    "It must be a government-issued ID document with a photo. All identification must be in the Latin alphabet. Otherwise, a certified translation is required.": "Es muss sich um ein amtlich ausgestelltes Ausweisdokument mit Foto handeln. Alle Identifikationen müssen im lateinischen Alphabet vorliegen. Andernfalls ist eine beglaubigte Übersetzung erforderlich.",
    "Kemit Kingdom reserves the right to request additional documents (Enhanced Due Diligence - EDD) or any information required to perform full verification, in line with its legal obligations.": "Das Kemit Kingdom behält sich das Recht vor, zusätzliche Dokumente (Erweiterte Sorgfaltsprüfung - EDD) oder alle Informationen anzufordern, die für eine vollständige Verifizierung gemäß seinen gesetzlichen Verpflichtungen erforderlich sind.",
    "Sessions": "Sitzungen",
    "Unlock Instant Withdrawals: Set Up Your Receiving Accounts Now!": "Sofortige Auszahlungen freischalten: Richten Sie jetzt Ihre Empfangskonten ein!",
    "Easily manage where you want to receive payments by adding the details of your bank accounts, mobile wallets, crypto wallets, payment cards, or cash pick-up points. Follow the steps below to ensure instant access to your funds whenever needed.": "Verwalten Sie einfach, wo Sie Zahlungen erhalten möchten, indem Sie die Details Ihrer Bankkonten, mobilen Brieftaschen, Krypto-Brieftaschen, Zahlungskarten oder Abholorte für Bargeld hinzufügen. Befolgen Sie die unten stehenden Schritte, um sicherzustellen, dass Sie jederzeit sofortigen Zugang zu Ihren Mitteln haben.",
    "Select the receiving channel below and set up the details.": "Wählen Sie den Empfangskanal unten aus und richten Sie die Details ein.",
    "Please Select A Payer": "Bitte wählen Sie einen Zahler aus",
    "Number": "Nummer",
    "Account Holder's Name": "Name des Kontoinhabers",
    "Province": "Provinz",
    "Postal Address": "Postadresse",
    "Delete Channel": "Kanal löschen",
    "Set as Default": "Als Standard festlegen",
    "Edit": "Bearbeiten",
    "All channels are in English on the receiving accounts page": "Alle Kanäle sind auf der Seite der empfangenden Konten in Englisch",
    "Select File Type": "Wählen Sie Dateityp aus",
    "Download Data": "Daten herunterladen",
    "INTERNATIONAL TRANSFER": "INTERNATIONALE ÜBERWEISUNG",
    "Enter the Amount": "Geben Sie den Betrag ein",
    "Recipient Receives": "Empfänger erhält",
    "Trouble Sending Money?": "Probleme beim Geld senden?",
    "Select Destination Country": "Ziel-Land auswählen",
    "Bank Transfer": "Banküberweisung",
    "Receiving Method": "Empfangsmethode",
    "Payer": "Zahler",
    "Fees and the received amount may vary based on the chosen payment method.": "Gebühren und der erhaltene Betrag können je nach gewählter Zahlungsmethode variieren.",
    "Search": "Suche",
    "Show More": "Mehr anzeigen",
    "Select Remark Reasons": "Wählen Sie Bemerkungsgründe aus",
    "Credit and Debit Cards": "Kredit- und Debitkarten",
    "Transfer Fees": "Überweisungsgebühren",
    "Estimated Time:": "Geschätzte Zeit:",
    "Within Minutes": "Innerhalb von Minuten",
    "Total": "Gesamt",
    "OTP Verification Code": "OTP-Verifizierungscode",
    "Verify via SMS/Email": "Per SMS/Email verifizieren",
    "SMS/Email Verification Code": "SMS/Email-Verifizierungscode",
    "Verify Using Authenticator": "Mit Authentifikator verifizieren",
    "WALLET TO WALLET TRANSFER": "WALLET ZU WALLET ÜBERWEISUNG",
    "Enter the Wallet ID, Username, Email, or Phone Number of the recipient.": "Geben Sie die Wallet-ID, den Benutzernamen, die E-Mail oder die Telefonnummer des Empfängers ein.",
    "Lookup": "Nachschlagen",
    "Recipient Not on InstaPay?": "Empfänger nicht auf InstaPay?",
    "Invite them": "Laden Sie sie ein",
    "Please follow these examples:": "Bitte folgen Sie diesen Beispielen:",
    "Wallet ID: GY68J782": "Wallet-ID: GY68J782",
    "InstaPay/Instagram Username: instauser": "InstaPay/Instagram-Benutzername: instauser",
    "Email: user@email.com": "E-Mail: user@email.com",
    "Phone Number (with Country Code)": "Telefonnummer (mit Ländervorwahl)",
    "Invite Friend": "Freund einladen",
    "Invite via Phone Number": "Per Telefonnummer einladen",
    "Invite via Email Address": "Per E-Mail-Adresse einladen",
    "Next": "Weiter",
    "Back": "Zurück",
    "Personalized Message": "Personalisierte Nachricht",
    "Available Currency": "Verfügbare Währung",
    "Attach Files": "Dateien anhängen",
    "Total Amount": "Gesamtbetrag",
    "Instant Payment": "Sofortige Zahlung",
    "Starting From": "Ab",
    "Recurring Cycle": "Wiederkehrender Zyklus",
    "Until Stopped": "Bis gestoppt",
    "Cycle": "Zyklus",
    "The recipient may receive a slightly adjusted amount due to the exchange rate at the time of the transaction.": "Der Empfänger kann aufgrund des Wechselkurses zum Zeitpunkt der Transaktion einen leicht angepassten Betrag erhalten.",
    "Scheduled To": "Geplant für",
    "Scheduled At": "Geplant um",
    "Scheduled Date": "Geplantes Datum",
    "Scheduled Time": "Geplante Zeit",
    "The recipient may receive a slightly adjusted amount due to exchange rates.": "Der Empfänger kann aufgrund von Wechselkursen einen leicht angepassten Betrag erhalten.",
    "QR CODE PAYMENT": "QR-CODE-ZAHLUNG",
    "Alternatively, use the InstaPay Alphanumeric QR Code.": "Verwenden Sie alternativ den InstaPay alphanumerischen QR-Code.",
    "PAYMENT REQUEST": "ZAHLUNGSANFRAGE",
    "Choose the Beneficiary": "Wählen Sie den Empfänger",
    "Trouble Requesting Money?": "Probleme bei der Geldanforderung?",
    "Standard Request": "Standardanforderung",
    "Recurring Request": "Wiederkehrende Anfrage",
    "Scheduled Request": "Geplante Anfrage",
    "Security Deposit Request": "Sicherheitsanforderung",
    "Instalment Plan": "Ratenplan",
    "Split Payment": "Aufteilung der Zahlung",
    "You received": "Sie haben erhalten",
    "Is this your location?": "Ist dies Ihr Standort?",
    "Yes": "Ja",
    "Authenticator Code": "Authentifizierungscode",
    "Enter the code shown in the app to confirm everything works properly.": "Geben Sie den im App angezeigten Code ein, um zu bestätigen, dass alles richtig funktioniert.",
    "Verify Using SMS/Email": "Verifizieren mit SMS/E-Mail",
    "SEND A QUOTE": "EIN ANGEBOT SENDEN",
    "Trouble Sending a Quote?": "Probleme beim Senden eines Angebots?",
    "Full Name: Muhammad Imtiaz": "Vollständiger Name: Muhammad Imtiaz",
    "Phone Number: 44795396600 (With Country Code)": "Telefonnummer: 44795396600 (Mit Ländervorwahl)",
    "Enter the code shown in the app to ensure everything is correct.": "Geben Sie den im App angezeigten Code ein, um sicherzustellen, dass alles korrekt ist.",
    "SEND TOP-UP AND E-SIM": "SENDEN SIE AUFLADUNG UND E-SIM",
    "Stay connected globally! InstaPay's Top-Up service allows you to recharge phones instantly, buy eSIMs, branded vouchers, gift cards, and pay utility bills in over 150 countries. Ideal for gifts or keeping yourself ready, our service prioritizes ease, speed, and security.": "Bleiben Sie weltweit verbunden! InstaPay's Auflade-Service ermöglicht es Ihnen, Handys sofort aufzuladen, eSIMs zu kaufen, markenbezogene Gutscheine, Geschenkkarten zu erwerben und Versorgungsrechnungen in über 150 Ländern zu bezahlen. Ideal als Geschenk oder um sich selbst vorzubereiten, priorisiert unser Service Einfachheit, Geschwindigkeit und Sicherheit.",
    "MOBILE AIRTIME AND E-SIM": "MOBILE AUFLADEZEIT UND E-SIM",
    "Select the country for mobile top-up!": "Wählen Sie das Land für die mobile Aufladung!",
    "Other Operators": "Andere Betreiber",
    "Airtime": "Gesprächsguthaben",
    "Bundle": "Paket",
    "Data": "Daten",
    "Enter Custom Amount": "Geben Sie einen benutzerdefinierten Betrag ein",
    "Credit & Debit Card": "Kredit- und Debitkarte",
    "Mobile Wallet": "Mobile Brieftasche",
    "InstaPay Wallet": "InstaPay-Brieftasche",
    "PayPal": "PayPal",
    "Add Card Payment": "Kartenzahlung hinzufügen",
    "Save Details for Future Use": "Details für die zukünftige Nutzung speichern",
    "Recipient Mobile Number": "Empfänger-Handynummer",
    "Amount": "Betrag",
    "Service": "Service",
    "Destination": "Ziel",
    "Provider": "Anbieter",
    "Pakistan": "Pakistan",
    "Telenor Pakistan": "Telenor Pakistan",
    "Top-Up Summary": "Aufladeübersicht",
    "PKR is not supported by PayPal, USD will be used as default.": "PKR wird von PayPal nicht unterstützt, USD wird als Standard verwendet.",
    "Amount in USD": "Betrag in USD",
    "Send Top-Up": "Aufladen senden",
    "Available Sunservices": "Verfügbare Sunservices",
    "Login With Email": "Mit E-Mail anmelden",
    "Already Have an Account? Log In": "Bereits ein Konto? Einloggen",
    "Profile Picture": "Profilbild",
    "Add Photo/Logo": "Foto/Logo hinzufügen",
    "First Name": "Vorname",
    "Enter Your First Name": "Geben Sie Ihren Vornamen ein",
    "Last Name": "Nachname",
    "Enter Your Last Name": "Geben Sie Ihren Nachnamen ein",
    "Email (Optional)": "E-Mail (Optional)",
    "Enter Your Email Address": "Geben Sie Ihre E-Mail-Adresse ein",
    "Password Must": "Passwort muss",
    "Auto-fill for Invitation Sign-Up": "Automatische Ausfüllung für Einladungsanmeldung",
    "Change Number": "Nummer ändern",
    "Sign-Up Error": "Anmeldefehler",
    "Something went wrong while sending SMS! Please try again later.": "Etwas ist schiefgelaufen beim Senden der SMS! Bitte versuchen Sie es später erneut.",
    "Errors occurred. Please refer to the following list for details.": "Fehler sind aufgetreten. Bitte beziehen Sie sich auf die folgende Liste für Details.",
    "Generic Error": "Allgemeiner Fehler",
    "Trust Payments API requires the 'requests' library to function properly.": "Die Trust Payments API erfordert die Bibliothek 'requests', um ordnungsgemäß zu funktionieren.",
    "Error during sending process. Please retry.": "Fehler während des Sendvorgangs. Bitte versuchen Sie es erneut.",
    "Error during receiving process. Please retry.": "Fehler während des Empfangsvorgangs. Bitte versuchen Sie es erneut.",
    "Invalid credentials. Please verify and try again.": "Ungültige Anmeldeinformationen. Bitte überprüfen Sie und versuchen Sie es erneut.",
    "An issue occurred while connecting to Trust Payments servers. Please check your connection and retry.": "Ein Problem ist beim Verbinden mit den Trust Payments-Servern aufgetreten. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.",
    "Unexpected error while connecting to Trust Payments servers. If this problem persists, contact support@trustpayments.com.": "Unerwarteter Fehler beim Verbinden mit den Trust Payments-Servern. Wenn dieses Problem weiterhin besteht, kontaktieren Sie support@trustpayments.com.",
    "Unknown error. Please contact Trust Payments support if the issue continues.": "Unbekannter Fehler. Bitte kontaktieren Sie den Support von Trust Payments, wenn das Problem weiterhin besteht.",
    "Incorrect usage of Trust Payments API. Please verify the request and try again.": "Falsche Verwendung der Trust Payments API. Bitte überprüfen Sie die Anfrage und versuchen Sie es erneut.",
    "Invalid card details. Please check and enter correct details.": "Ungültige Kartendaten. Bitte überprüfen Sie und geben Sie die korrekten Daten ein.",
    "Invalid date provided.": "Ungültiges angegebenes Datum.",
    "Invalid date/time format. Please provide the correct format.": "Ungültiges Datum/Zeit-Format. Bitte geben Sie das korrekte Format an.",
    "Invalid details. Please verify and enter correct information.": "Ungültige Angaben. Bitte überprüfen Sie und geben Sie die richtigen Informationen ein.",
    "Card number does not match the specified card type.": "Die Kartennummer stimmt nicht mit dem angegebenen Kartentyp überein.",
    "XML formatting error. Please verify and correct the XML.": "XML-Formatierungsfehler. Bitte überprüfen Sie und korrigieren Sie das XML.",
    "XML does not match the expected schema.": "XML entspricht nicht dem erwarteten Schema.",
    "Invalid file format. Please use a supported format.": "Ungültiges Dateiformat. Bitte verwenden Sie ein unterstütztes Format.",
    "Empty file content. Please provide valid content.": "Leerer Dateinhalt. Bitte geben Sie gültigen Inhalt an.",
    "Invalid file content. Please verify the file and try again.": "Ungültiger Dateinhalt. Bitte überprüfen Sie die Datei und versuchen Sie es erneut.",
    "Malformed JSON. Please correct the JSON format.": "Fehlerhaftes JSON. Bitte korrigieren Sie das JSON-Format.",
    "StApi Error": "StApi Fehler",
    "Invalid fields specified in the request. Please verify and correct them.": "Ungültige Felder in der Anfrage angegeben. Bitte überprüfen und korrigieren Sie diese.",
    "Missing parent information.": "Fehlende Elterninformationen.",
    "Refund requires a settled parent transaction or one due for settlement today.": "Eine Rückerstattung erfordert eine abgeschlossene übergeordnete Transaktion oder eine, die heute zur Abwicklung fällig ist.",
    "Refund requires an authorization parent transaction.": "Eine Rückerstattung erfordert eine autorisierte übergeordnete Transaktion.",
    "Refund amount exceeds allowable limit.": "Der Rückerstattungsbetrag überschreitet das zulässige Limit.",
    "No acquirer information provided.": "Keine Acquirer-Informationen bereitgestellt.",
    "Repeat amount exceeds allowable limit.": "Der Wiederholungsbetrag überschreitet das zulässige Limit.",
    "Split amount exceeds allowable limit.": "Der Split-Betrag überschreitet das zulässige Limit.",
    "Cannot refund a declined transaction.": "Eine abgelehnte Transaktion kann nicht zurückerstattet werden.",
    "Refund requires a settled parent transaction.": "Eine Rückerstattung erfordert eine abgeschlossene übergeordnete Transaktion.",
    "Reversal requires a canceled authorization transaction.": "Rückbuchung erfordert eine stornierte Autorisierungstransaktion.",
    "Cannot override amount in a child transaction.": "Betrag in einer untergeordneten Transaktion kann nicht überschrieben werden.",
    "Cannot override currency in a child transaction.": "Währung in einer untergeordneten Transaktion kann nicht überschrieben werden.",
    "Subscription requires a recurring account setup.": "Abonnement erfordert die Einrichtung eines wiederkehrenden Kontos.",
    "Subscription requires a successful parent transaction.": "Abonnement erfordert eine erfolgreiche Eltern-Transaktion.",
    "Risk decisions must be tied to an authorization transaction.": "Risikobewertungen müssen an eine Autorisierungstransaktion gebunden sein.",
    "Chargebacks must be linked to an authorization or refund transaction.": "Rückbuchungen müssen mit einer Autorisierungs- oder Rückerstattungstransaktion verknüpft sein.",
    "Refund amount is less than the minimum allowed.": "Der Rückerstattungsbetrag liegt unter dem Mindestbetrag.",
    "Refund requires a PayPal transaction ID.": "Rückerstattung erfordert eine PayPal-Transaktions-ID.",
    "Invalid split transaction.": "Ungültige Split-Transaktion.",
    "Cannot reverse an authorization processed more than 48 hours ago.": "Autorisierung, die mehr als 48 Stunden alt ist, kann nicht rückgängig gemacht werden.",
    "Reversal requires acquirer reference data.": "Rückbuchung erfordert Referenzdaten des Erwerbers.",
    "Cannot reverse authorization processed by a different acquirer.": "Autorisierung, die von einem anderen Erwerber verarbeitet wurde, kann nicht rückgängig gemacht werden.",
    "Payment type does not support repeated transactions.": "Zahlungsmethode unterstützt keine wiederholten Transaktionen.",
    "Reversal missing required data.": "Rückbuchung fehlt erforderliche Daten.",
    "Missing token information.": "Fehlende Token-Informationen.",
    "Subscription with an account check parent not supported by the current acquirer.": "Abonnement mit einer Kontoprüfung durch die Eltern wird vom aktuellen Erwerber nicht unterstützt.",
    "Subscription cannot be used as a parent transaction.": "Abonnement kann nicht als Eltern-Transaktion verwendet werden.",
    "Invalid parent transaction type.": "Ungültiger Eltern-Transaktionstyp.",
    "Payment type does not support refunds.": "Zahlungsmethode unterstützt keine Rückerstattungen.",
    "Invalid incremental transaction.": "Ungültige inkrementelle Transaktion.",
    "Partial reversals are not supported.": "Teilweise Rückbuchungen werden nicht unterstützt.",
    "Parent and child transactions must have the same payment type for 3D secure queries.": "Parent- und Child-Transaktionen müssen denselben Zahlungstyp für 3D-Secure-Abfragen haben.",
    "Payment type does not support card scheme updates.": "Der Zahlungstyp unterstützt keine Kartenschema-Updates.",
    "Cannot reverse authorization at this time. Please try again later.": "Die Autorisierung kann derzeit nicht rückgängig gemacht werden. Bitte versuchen Sie es später erneut.",
    "Unable to determine token. Please verify and try again.": "Token kann nicht bestimmt werden. Bitte überprüfen Sie und versuchen Sie es erneut.",
    "Service is temporarily disabled. Please try again later.": "Der Dienst ist vorübergehend deaktiviert. Bitte versuchen Sie es später erneut.",
    "Login required before making a request.": "Anmeldung erforderlich, bevor eine Anfrage gestellt werden kann.",
    "Invalid username or password. Please try again.": "Ungültiger Benutzername oder Passwort. Bitte versuchen Sie es erneut.",
    "Invalid session. Please log in again.": "Ungültige Sitzung. Bitte melden Sie sich erneut an.",
    "Session expired. Please log in again.": "Sitzung abgelaufen. Bitte melden Sie sich erneut an.",
    "Password expired. Please reset your password.": "Passwort abgelaufen. Bitte setzen Sie Ihr Passwort zurück.",
    "Password has been previously used. Please choose a new password.": "Passwort wurde bereits verwendet. Bitte wählen Sie ein neues Passwort.",
    "MyST user account has been locked. Please contact support.": "MyST-Benutzerkonto wurde gesperrt. Bitte wenden Sie sich an den Support.",
    "New password does not match the confirmed password.": "Neues Passwort stimmt nicht mit dem bestätigten Passwort überein.",
    "Incorrect current password. Please try again.": "Falsches aktuelles Passwort. Bitte versuchen Sie es erneut.",
    "Invalid selection made. Please choose a valid option.": "Ungültige Auswahl getroffen. Bitte wählen Sie eine gültige Option.",
    "User already exists. Please log in or reset the password if needed.": "Benutzer existiert bereits. Bitte melden Sie sich an oder setzen Sie das Passwort zurück, wenn erforderlich.",
    "No transaction found.": "Keine Transaktion gefunden.",
    "Invalid selected transactions.": "Ungültig ausgewählte Transaktionen.",
    "Data has not been saved. Please try again.": "Die Daten wurden nicht gespeichert. Bitte versuchen Sie es erneut.",
    "Invalid request type.": "Ungültiger Anforderungstyp.",
    "Missing request type. At least one must be selected.": "Fehlender Anforderungstyp. Mindestens einer muss ausgewählt werden.",
    "Invalid payment type.": "Ungültiger Zahlungstyp.",
    "Missing payment type. At least one must be selected.": "Fehlender Zahlungstyp. Mindestens einer muss ausgewählt werden.",
    "Invalid error code.": "Ungültiger Fehlercode.",
    "Missing error code. At least one must be selected.": "Fehlender Fehlercode. Mindestens einer muss ausgewählt werden.",
    "Invalid filter description.": "Ungültige Filterbeschreibung.",
    "Invalid destination description.": "Ungültige Zielbeschreibung.",
    "Invalid notification type.": "Ungültiger Benachrichtigungstyp.",
    "Invalid destination.": "Ungültiges Ziel.",
    "Invalid field selected.": "Ungültig ausgewähltes Feld.",
    "Invalid email address for the sender.": "Ungültige E-Mail-Adresse des Absenders.",
    "Invalid email subject line.": "Ungültige Betreffzeile der E-Mail.",
    "Invalid email type.": "Ungültiger E-Mail-Typ.",
    "Unable to process the request. Please try again later.": "Anforderung kann nicht verarbeitet werden. Bitte versuchen Sie es später erneut.",
    "No file selected for upload.": "Keine Datei zum Hochladen ausgewählt.",
    "Invalid file size. Please provide a file of acceptable size.": "Ungültige Dateigröße. Bitte stellen Sie eine Datei in akzeptabler Größe bereit.",
    "Invalid filename.": "Ungültiger Dateiname.",
    "Invalid file extension.": "Ungültige Dateierweiterung.",
    "User requires at least one site reference.": "Der Benutzer benötigt mindestens einen Seitenverweis.",
    "Only ST-level users can have '*' access rights.": "Nur ST-Level-Benutzer können '*' Zugriffrechte haben.",
    "Request failed. Please try again later.": "Anfrage fehlgeschlagen. Bitte versuchen Sie es später erneut.",
    "Invalid file contents.": "Ungültiger Dateinhalt.",
    "Maximum number of files uploaded. Please delete some files and retry.": "Maximale Anzahl an hochgeladenen Dateien erreicht. Bitte löschen Sie einige Dateien und versuchen Sie es erneut.",
    "Insufficient gateway access privileges.": "Unzureichende Gateway-Zugriffsberechtigungen.",
    "Maximum file size limit reached.": "Maximale Dateigrößenbegrenzung erreicht.",
    "Username(s) must be valid users.": "Benutzername(n) müssen gültige Benutzer sein.",
    "Site reference(s) must be valid sites.": "Seitenverweis(e) müssen gültige Seiten sein.",
    "Unable to send email. Please verify the details and try again.": "E-Mail kann nicht gesendet werden. Bitte überprüfen Sie die Details und versuchen Sie es erneut.",
    "A negative value already exists. Please verify and make corrections.": "Ein negativer Wert existiert bereits. Bitte überprüfen Sie und nehmen Sie Korrekturen vor.",
    "Cannot delete a search owned by another user.": "Sie können eine Suche, die einem anderen Benutzer gehört, nicht löschen.",
    "Invalid search. Please try again with valid criteria.": "Ungültige Suche. Bitte versuchen Sie es erneut mit gültigen Kriterien.",
    "Cannot delete the specified search. The search name cannot be found.": "Die angegebene Suche kann nicht gelöscht werden. Der Suchname kann nicht gefunden werden.",
    "Search parameter is too short. Please enter more specific search terms.": "Suchparameter sind zu kurz. Bitte geben Sie spezifischere Suchbegriffe ein.",
    "Duplicate custom fields defined. Please resolve the duplicates.": "Duplikate benutzerdefinierter Felder definiert. Bitte beheben Sie die Duplikate.",
    "Cannot allocate selected users. Insufficient privileges.": "Die ausgewählten Benutzer können nicht zugewiesen werden. Unzureichende Berechtigungen.",
    "Allocated users have access to additional sites.": "Zugewiesene Benutzer haben Zugriff auf zusätzliche Sites.",
    "Allocated users have access to additional users.": "Zugewiesene Benutzer haben Zugriff auf zusätzliche Benutzer.",
    "Users with the current role cannot allocate other users.": "Benutzer mit der aktuellen Rolle können keine anderen Benutzer zuweisen.",
    "This site requires that your browser accepts cookies to sign in. Cookies can be accepted by clicking 'I accept' below.": "Diese Seite erfordert, dass Ihr Browser Cookies akzeptiert, um sich anzumelden. Cookies können durch Klicken auf 'Ich akzeptiere' unten akzeptiert werden.",
    "User requires at least one site reference or site group.": "Der Benutzer benötigt mindestens eine Site-Referenz oder Site-Gruppe.",
    "Allocated users have access to additional site groups.": "Zugewiesene Benutzer haben Zugriff auf zusätzliche Site-Gruppen.",
    "No statement found.": "Keine Erklärung gefunden.",
    "Data supplied has not been updated in the MobilePay third-party service.": "Die bereitgestellten Daten wurden im MobilePay-Drittanbieterdienst nicht aktualisiert.",
    "Bypass": "Umgehen",
    "Insufficient access privileges.": "Unzureichende Zugriffsberechtigungen.",
    "Coding error detected.": "Fehler im Code erkannt.",
    "Insufficient privileges to complete this action.": "Unzureichende Berechtigungen, um diese Aktion abzuschließen.",
    "Invalid request. Please verify and try again.": "Ungültige Anfrage. Bitte überprüfen Sie und versuchen Sie es erneut.",
    "Invalid field entered. Please verify and correct the entry.": "Ungültiges Feld eingegeben. Bitte überprüfen und korrigieren Sie den Eintrag.",
    "Unknown site.": "Unbekannte Seite.",
    "Banned card.": "Gesperrte Karte.",
    "XML element parsing error. Please verify the XML structure.": "XML-Element-Analysefehler. Bitte überprüfen Sie die XML-Struktur.",
    "Maestro transactions must use SecureCode for validation.": "Maestro-Transaktionen müssen SecureCode zur Validierung verwenden.",
    "Multiple email addresses must be separated with commas (,) or semicolons (;).": "Mehrere E-Mail-Adressen müssen durch Kommas (,) oder Semikolons (;) getrennt werden.",
    "Invalid site reference for alias.": "Ungültiger Seitenverweis für Alias.",
    "Invalid version number. Please enter a valid version number.": "Ungültige Versionsnummer. Bitte geben Sie eine gültige Versionsnummer ein.",
    "Unknown user. Please verify and try again.": "Unbekannter Benutzer. Bitte überprüfen Sie und versuchen Sie es erneut.",
    "Cannot determine account.": "Konto kann nicht ermittelt werden.",
    "JSON element parsing error. Please verify the JSON structure.": "JSON-Element-Analysefehler. Bitte überprüfen Sie die JSON-Struktur.",
    "Wallet type configuration error.": "Fehler bei der Konfiguration des Wallet-Typs.",
    "Wallet type not supported for this request.": "Wallet-Typ wird für diese Anfrage nicht unterstützt.",
    "The card number provided is incorrect. Please verify your details and try again.": "Die angegebene Kartennummer ist falsch. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.",
    "The security code (CVV2) you provided is incorrect. Please verify and try again.": "Der Sicherheitscode (CVV2), den Sie angegeben haben, ist falsch. Bitte überprüfen Sie und versuchen Sie es erneut.",
    "The expiry date provided is incorrect. Please verify your details and try again.": "Das angegebene Ablaufdatum ist falsch. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.",
    "The expiry month provided is incorrect. Please verify and try again.": "Der angegebene Ablaufmonat ist falsch. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.",
    "The expiry year provided is incorrect. Please verify and try again.": "Das angegebene Ablaufjahr ist falsch. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.",
    "Unable to process your payment due to connection errors. Request ID mismatch. Please try again.": "Zahlung kann aufgrund von Verbindungsfehlern nicht verarbeitet werden. Fehlerhafte Anforderungs-ID. Bitte versuchen Sie es erneut.",
    "The issue number provided is incorrect. Please verify and try again.": "Die angegebene Ausgabenummer ist falsch. Bitte überprüfen Sie und versuchen Sie es erneut.",
    "The payment type provided is incorrect. Please verify and try again.": "Der angegebene Zahlungstyp ist falsch. Bitte überprüfen Sie ihn und versuchen Sie es erneut.",
    "Unable to process your payment. Please contact the website for support.": "Zahlung kann nicht verarbeitet werden. Bitte wenden Sie sich an die Website für Unterstützung.",
    "There are errors with these fields: {0}": "Es gibt Fehler in diesen Feldern: {0}",
    "No account found.": "Kein Konto gefunden.",
    "Refund cannot be processed.": "Rückerstattung kann nicht bearbeitet werden.",
    "Transaction deactivated.": "Transaktion deaktiviert.",
    "Socket receive error.": "Socket-Empfangsfehler.",
    "Socket connection error.": "Socket-Verbindungsfehler.",
    "Socket closed.": "Socket geschlossen.",
    "Invalid data received.": "Ungültige Daten empfangen.",
    "Invalid SQL query.": "Ungültige SQL-Abfrage.",
    "Timeout occurred.": "Zeitüberschreitung aufgetreten.",
    "Invalid acquirer details.": "Ungültige Akquisiteurdetails.",
    "Unable to connect to the acquirer.": "Keine Verbindung zum Akquisiteur möglich.",
    "Invalid response from the acquirer.": "Ungültige Antwort vom Akquisiteur.",
    "No available transport found.": "Kein verfügbares Transportmittel gefunden.",
    "File size too large.": "Dateigröße zu groß.",
    "Socket send error.": "Socket-Sende-Fehler.",
    "Communication error.": "Kommunikationsfehler.",
    "Proxy error.": "Proxy-Fehler.",
    "Unable to process your payment due to connection errors. Please verify your details and try again ({0}).": "Zahlung kann aufgrund von Verbindungsfehlern nicht verarbeitet werden. Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut ({0}).",
    "Unable to process your payment due to connection errors (HTTP response status {0}). Please verify your details and try again ({1}).": "Zahlung kann aufgrund von Verbindungsfehlern nicht verarbeitet werden (HTTP-Antwortstatus {0}). Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut ({1}).",
    "Incorrect number of email addresses provided.": "Falsche Anzahl an E-Mail-Adressen angegeben.",
    "Bank system error.": "Bankensystemfehler.",
    "Incorrect number of transactions provided.": "Falsche Anzahl an Transaktionen angegeben.",
    "Invalid transport configuration.": "Ungültige Transportkonfiguration.",
    "No valid updates specified.": "Keine gültigen Updates angegeben.",
    "Transaction reference not found.": "Transaktionsreferenz nicht gefunden.",
    "Settle base amount is too large.": "Der Basisbetrag für die Abrechnung ist zu groß.",
    "Transaction not updatable.": "Transaktion nicht aktualisierbar.",
    "No searchable filter specified.": "Kein durchsuchbarer Filter angegeben.",
    "Timeout error. Please try again.": "Timeout-Fehler. Bitte versuchen Sie es erneut.",
    "3-D Secure transport error.": "3-D Secure Transportfehler.",
    "Unauthenticated request.": "Unauthentifizierte Anfrage.",
    "Site suspended.": "Seite ausgesetzt.",
    "No updates performed.": "Keine Updates durchgeführt.",
    "Invalid request.": "Ungültige Anfrage.",
    "Invalid response.": "Ungültige Antwort.",
    "Invalid acquirer information.": "Ungültige Erwerberinformationen.",
    "Invalid account data.": "Ungültige Kontodaten.",
    "Missing information.": "Fehlende Informationen.",
    "Payment error.": "Zahlungsfehler.",
    "Invalid acquirer for 3-D Secure.": "Ungültiger Acquirer für 3-D Secure.",
    "Invalid payment type for 3-D Secure.": "Ungültiger Zahlungstyp für 3-D Secure.",
    "Invalid updates specified.": "Ungültige Updates angegeben.",
    "Manual investigation required.": "Manuelle Untersuchung erforderlich.",
    "Invalid headers.": "Ungültige Header.",
    "Maximum fraud score exceeded.": "Maximale Betrugsbewertung überschritten.",
    "Invalid filters provided.": "Ungültige Filter angegeben.",
    "Merchant system error.": "Fehler im Händlersystem.",
    "Your payment is being processed. Please wait...": "Ihre Zahlung wird bearbeitet. Bitte warten Sie...",
    "Cannot specify both 'request type description' and 'request type descriptions' in a single request.": "Es können nicht sowohl 'Anfragetyp-Beschreibung' als auch 'Anfragetyp-Beschreibungen' in einer einzigen Anfrage angegeben werden.",
    "Acquirer missing original transaction data.": "Acquirer fehlen Original-Transaktionsdaten.",
    "Insufficient funds.": "Unzureichende Mittel.",
    "Unable to process due to scheme restrictions.": "Kann aufgrund von Einschränkungen des Programms nicht bearbeitet werden.",
    "Failed screening.": "Screening fehlgeschlagen.",
    "Unable to process due to restrictions.": "Kann aufgrund von Einschränkungen nicht bearbeitet werden.",
    "Invalid process.": "Ungültiger Prozess.",
    "Risk referral required.": "Risikoreferenz erforderlich.",
    "Name is required for selection.": "Name ist für die Auswahl erforderlich.",
    "Address is required for selection.": "Adresse ist für die Auswahl erforderlich.",
    "IP address not in range.": "IP-Adresse nicht im Bereich.",
    "Invalid button configuration.": "Ungültige Schaltflächenkonfiguration.",
    "Unrecognized response from acquirer.": "Unbekannte Antwort vom Acquirer.",
    "Decline.": "Ablehnung.",
    "Uncertain result.": "Unklarer Resultat.",
    "Soft decline.": "Weiche Ablehnung.",
    "Refer to the issuer for more information.": "Weitere Informationen beim Herausgeber einholen.",
    "Request is queued. Please check the transaction status later.": "Anfrage wird in Warteschlange gestellt. Bitte überprüfen Sie später den Transaktionsstatus.",
    "Generic retry.": "Generischer Retry.",
    "Soft decline retry.": "Retry bei weicher Ablehnung.",
    "There has been a problem with your payment. Please verify your details and try again.": "Es gab ein Problem mit Ihrer Zahlung. Bitte überprüfen Sie Ihre Daten und versuchen Sie es erneut.",
    "Unknown error.": "Unbekannter Fehler.",

    "Login": "Anmeldung",
    "Enter Your Email": "Geben Sie Ihre E-Mail ein",
    "Login With Phone Number": "Mit Telefonnummer anmelden",
    "Verify Using Sms/Email": "Mit SMS/Email verifizieren",
    "Resend Code In 100 Seconds": "Code in 100 Sekunden erneut senden",
    "Verify": "Verifizieren",
    "Verifying": "Wird überprüft",
    "Last Week": "Letzte Woche",
    "Older Transaction": "Ältere Transaktion",
    "Total": "Gesamt",
    "Conversion Confirmed": "Konversion bestätigt",
    "User Account Limit": "Benutzerkontolimit",
    "Monthly Balance Limit": "Monatliches Guthabenlimit",
    "Total Limit": "Gesamtlimit",
    "Used": "Verwendet",
    "Remaining": "Übrig",
    "Allowed Minimum Topup Amount": "Erlaubter Mindestaufstockungsbetrag",
    "Allowed Maximum Topup Amount": "Erlaubter Höchstaufstockungsbetrag",
    "Incoming Limit": "Eingehendes Limit",
    "Daily limit": "Tageslimit",
    "Monthly limit": "Monatliches Limit",
    "Yearly limit": "Jährliches Limit",
    "Sending Limit": "Sendelimit",
    "Allowed Number Of Transactions": "Erlaubte Anzahl an Transaktionen",
    "Total Amount": "Gesamtbetrag",
    "Amount To Send": "Zu sendender Betrag",
    "Destination Amount": "Zielbetrag",
    "Note": "Hinweis",
    "Revised Amount": "Überarbeiteter Betrag",
    "Save": "Speichern",
    "Date": "Datum",
    "Action": "Aktion",
    "Payment Scheduled At": "Geplanter Zahlungstermin",
    "Transaction Fee": "Transaktionsgebühr",
    "Number Of Cycle": "Anzahl der Zyklen",
    "Subscription Starting Date": "Abonnement Startdatum",
    "Until": "Bis",
    "Next Transaction": "Nächste Transaktion",
    "Pay Now": "Jetzt bezahlen",
    "Transaction Method": "Transaktionsmethode",
    "Mobile Money Provider": "Anbieter von Mobile Money",
    "Wallet Name": "Wallet Name",
    "Wallet Number": "Wallet Nummer",
    "Crypto Currency": "Kryptowährung",
    "Wallet Address": "Wallet Adresse",
    "Select Document Type": "Wählen Sie den Dokumenttyp",
    "Edit Document Number": "Bearbeiten Sie die Dokumentnummer",
    "My Withdrawals": "Meine Abhebungen",
    "Bargainable": "Verhandelbar",
    "Description": "Beschreibung",
    "Attached Files": "Angehängte Dateien",
    "Sent": "Gesendet",
    "Accepted": "Akzeptiert",
    "Decline": "Abgelehnt",
    "Pending": "Ausstehend",
    "Overall Rating": "Gesamte Bewertung",
    "No Review": "Keine Bewertung",
    "0 Rating": "0 Bewertung",
    "Transaction Map": "Transaktionskarte",
    "Send Money": "Geld senden",
    "Sending Method": "Sendemethode",
    "Trouble Sending Money": "Problem beim Geld senden",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Die Gebühr und der erhaltene Betrag können je nach gewählter Zahlungsmethode variieren.",
    "Select Method": "Methode auswählen",
    "Completed": "Abgeschlossen",
    "How Was Your Experience?": "Wie war Ihre Erfahrung?",
    "Leave A Review": "Hinterlassen Sie eine Bewertung",
    "Review History": "Bewertungsverlauf",
    "Write A Review": "Schreiben Sie eine Bewertung",
    "Send And Post": "Senden und Posten",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Die Umwandlung von Krypto in Fiat ist eine robuste und sichere Lösung, die Kryptowährungen nahtlos in Fiat-Währungen umwandelt. Sie deckt die unterschiedlichen Bedürfnisse der Nutzer ab und bietet eine Vielzahl von Zahlungsmethoden, die an individuelle Vorlieben und Standorte angepasst sind.\"",
    "Review Created": "Überprüfung erstellt",
    "Transactions": "Transaktionen",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Datei ziehen/ablegen oder hochladen (Foto/Video/PDF)",
    "Max Size 1GB": "Maximalgröße 1GB",
    "Title Description": "Titelbeschreibung",
    "Add New": "Neu hinzufügen",
    "Discard": "Verwerfen",
    "Save And Post": "Speichern und posten",
    "Personal Account": "Persönliches Konto",
    "Conversion": "Umrechnung",
    "Spent In Total": "Insgesamt ausgegeben",
    "International": "International",
    "Recharge": "Aufladen",
    "Top Up Wallet To Wallet": "Wallet zu Wallet aufladen",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Nicht verbunden",
    "More": "Mehr",
    "Less": "Weniger",
    "Profile Reports": "Profilberichte",
    "Timezone": "Zeitzone",
    "Cancel": "Abbrechen",
    "Full Name": "Vollständiger Name",
    "Google Authenticator": "Google Authentifikator",
    "Documents Supplémentaires": "Zusätzliche Dokumente",
    "Profile Complete": "Profil vervollständigt",
    "Verified": "Verifiziert",
    "Bank Account": "Bankkonto",
    "Mobile Wallet": "Mobil-Wallet",
    "Cash Pickup": "Bargeldabholung",
    "Add": "Hinzufügen",
    "Configured": "Konfiguriert",
    "Email": "E-Mail",
    "Download Data": "Daten herunterladen",
    "Enter The Amount": "Betrag eingeben",
    "They Receive": "Sie erhalten",
    "Where Do You Want To Send Money": "Wohin möchten Sie Geld senden?",
    "Bank Transfer": "Banküberweisung",
    "Receiving Method": "Empfangsmethode",
    "Payer": "Zahler",
    "Minimum Amount": "Mindestbetrag",
    "Maximum Amount": "Maximale Menge",
    "Search": "Suchen",
    "Show More": "Mehr anzeigen",
    "Select Remark / Reasons (All)": "Bemerkung / Grund auswählen (Alle)",
    "Attach Files": "Dateien anhängen",
    "Holiday List": "Urlaubsliste",
    "No Data To Show": "Keine Daten zum Anzeigen",
    "Threshold Limits": "Schwellenwertgrenzen",
    "Amount Limits": "Betragsgrenzen",
    "OTP Verification Code": "OTP-Verifizierungscode",
    "Verify Using Authenticator": "Mit Authentifikator verifizieren",
    "Sms/Email Verification Code": "SMS/Email-Verifizierungscode",
    "Invite Them": "Sie einladen",
    "Lookup": "Nachschlagen",
    "Invite Friend": "Freund einladen",
    "Invite Via Phone Number": "Per Telefonnummer einladen",
    "Invite Via Email Address": "Per E-Mail-Adresse einladen",
    "Next": "Nächster",
    "Back": "Zurück",
    "Invite Via Email": "Per E-Mail einladen",
    "Available Currency": "Verfügbare Währung",
    "Add Remarks And Comments": "Bemerkungen und Kommentare hinzufügen",
    "Recipient Details": "Empfängerdetails",
    "Go Back": "Zurück gehen",
    "Trouble Requesting Money": "Probleme bei der Anforderung von Geld",
    "Instant": "Sofort",
    "Subscription": "Abonnement",
    "Schedule": "Zeitplan",
    "Choose Request Type": "Anforderungstyp auswählen",
    "Mobile Airtime": "Mobilfunkzeit",
    "Incorrect Phone Number": "Falsche Telefonnummer",
    "Make Sure You Have Entered A Correct Phone Number.": "Stellen Sie sicher, dass Sie eine richtige Telefonnummer eingegeben haben.",
    "Close": "Schließen",
    "Operator": "Betreiber",
    "Recipient Mobile Number": "Empfängernummer",
    "Amount": "Betrag",
    "Service": "Service",
    "Destination": "Ziel",
    "Top Up Summary": "Aufladezusammenfassung",
    "Pakistan": "Pakistan",
    "Daily Sending Limit Exceeded": "Tägliches Sende-Limit überschritten",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Senden Sie Ihrem Kunden oder Käufer ein detailliertes Preisangebot. Präsentieren Sie Ihre Dienstleistung, Produkte oder Angebote professionell mit einem personalisierten Angebot.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Zahlungen, die an Werktagen vor 15:00 Uhr erfolgen, werden noch am selben Tag bis Mitternacht dem Empfängerkonto gutgeschrieben.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Richten Sie Ihre Empfangskonten in bis zu drei Ländern ein und heben Sie sofort Gelder aus genehmigten Zahlungsanforderungen auf Ihre aktivierten Auszahlungskanäle ab.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom behält sich das Recht vor, gemäß seinen gesetzlichen Verpflichtungen zusätzliche Dokumente (Erweiterte Sorgfaltspflicht - EDD) oder weitere Informationen anzufordern, die für gründliche Verifikationen erforderlich sind.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Es muss ein von der Regierung ausgestelltes Identifikationsdokument mit einem Foto sein. Alle Identifikationsdokumente müssen in lateinischer Schrift verfasst sein. Wenn nicht, ist eine zertifizierte Übersetzung erforderlich.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Zeigen Sie Ihre Arbeit, erweitern Sie Ihre Reichweite. 'Mein Portfolio' ist Ihre digitale Leinwand auf Instapay. Laden Sie eine Vielzahl von Inhalten hoch und präsentieren Sie sie – von fesselnden Bildern und Videos bis hin zu informativen PDFs. Egal, ob Sie ein Künstler, Freelancer oder Unternehmer sind, diese Funktion ermöglicht es Ihnen, Ihre Arbeiten oder Projekte auf visuell ansprechende Weise zu präsentieren.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Teilen Sie Ihren QR-Code oder Zahlungslink, um sofort in Ihrem Insta Wallet bezahlt zu werden.",
    "Afghanistan": "Afghanistan",
    "Aland Islands": "Ålandinseln",
    "Albania": "Albanien",
    "Algeria": "Algerien",
    "American Samoa": "Amerikanisch-Samoa",
    "Andorra": "Andorra",
    "Angola": "Angola",
    "Anguilla": "Anguilla",
    "Antigua and Barbuda": "Antigua und Barbuda",
    "Argentina": "Argentinien",
    "Armenia": "Armenien",
    "Aruba": "Aruba",
    "Australia": "Australien",
    "Austria": "Österreich",
    "Azerbaijan": "Aserbaidschan",
    "Bahamas": "Bahamas",
    "Bahrain": "Bahrain",
    "Bangladesh": "Bangladesch",
    "Barbados": "Barbados",
    "Belarus": "Weißrussland",
    "Belgium": "Belgien",
    "Belize": "Belize",
    "Benin": "Benin",
    "Bermuda": "Bermuda",
    "Bhutan": "Bhutan",
    "Bolivia (Plurinational State of)": "Bolivien (Plurinationaler Staat von)",
    "Bonaire - Sint Eustatius and Saba": "Bonaire - Sint Eustatius und Saba",
    "Bosnia and Herzegovina": "Bosnien und Herzegowina",
    "Botswana": "Botswana",
    "Brazil": "Brasilien",
    "British Indian Ocean Territory": "Britisches Territorium im Indischen Ozean",
    "Brunei Darussalam": "Brunei Darussalam",
    "Bulgaria": "Bulgarien",
    "Burkina Faso": "Burkina Faso",
    "Burundi": "Burundi",
    "Cabo Verde": "Kap Verde",
    "Cambodia": "Kambodscha",
    "Cameroon": "Kamerun",
    "Canada": "Kanada",
    "Cayman Islands": "Kaimaninseln",
    "Central African Republic": "Zentralafrikanische Republik",
    "Chad": "Tschad",
    "Chile": "Chile",
    "China": "China",
    "Christmas Island": "Weihnachtsinsel",
    "Cocos (Keeling) Islands": "Kokosinseln",
    "Colombia": "Kolumbien",
    "Comoros": "Komoren",
    "Congo": "Kongo",
    "Congo - Democratic Republic of the": "Kongo - Demokratische Republik",
    "Cook Islands": "Cookinseln",
    "Costa Rica": "Costa Rica",
    "Cote d'Ivoire": "Elfenbeinküste",
    "Croatia": "Kroatien",
    "Cuba": "Kuba",
    "Curacao": "Curaçao",
    "Cyprus": "Zypern",
    "Czech Republic": "Tschechische Republik",
    "Denmark": "Dänemark",
    "Djibouti": "Dschibuti",
    "Dominica": "Dominica",
    "Dominican Republic": "Dominikanische Republik",
    "Ecuador": "Ecuador",
    "Egypt": "Ägypten",
    "El Salvador": "El Salvador",
    "Equatorial Guinea": "Äquatorialguinea",
    "Eritrea": "Eritrea",
    "Estonia": "Estland",
    "Eswatini": "Eswatini",
    "Ethiopia": "Äthiopien",
    "Falkland Islands (Malvinas)": "Falklandinseln (Malvinas)",
    "Faroe Islands": "Färöer-Inseln",
    "Fiji": "Fidschi",
    "Finland": "Finnland",
    "France": "Frankreich",
    "French Polynesia": "Französisch-Polynesien",
    "French Southern Territories": "Französische Süd- und Antarktisgebiete",
    "Gabon": "Gabon",
    "Gambia": "Gambia",
    "Georgia": "Georgien",
    "Germany": "Deutschland",
    "Ghana": "Ghana",
    "Gibraltar": "Gibraltar",
    "Greece": "Griechenland",
    "Greenland": "Grönland",
    "Grenada": "Grenada",
    "Guam": "Guam",
    "Guatemala": "Guatemala",
    "Guernsey": "Guernsey",
    "Guinea (Conakry)": "Guinea (Konakry)",
    "Guinea-Bissau": "Guinea-Bissau",
    "Guyana": "Guyana",
    "Haiti": "Haiti",
    "Heard Island and McDonald Islands": "Heard- und McDonald-Inseln",
    "Holy See": "Heiliger Stuhl",
    "Honduras": "Honduras",
    "Hong Kong": "Hongkong",
    "Hungary": "Ungarn",
    "Iceland": "Island",
    "India": "Indien",
    "Indonesia": "Indonesien",
    "Iran (Islamic Republic of)": "Iran (Islamische Republik)",
    "Iraq": "Irak",
    "Ireland": "Irland",
    "Isle of Man": "Isle of Man",
    "Israel": "Israel",
    "Italy": "Italien",
    "Jamaica": "Jamaika",
    "Japan": "Japan",
    "Jersey": "Jersey",
    "Jordan": "Jordanien",
    "Kazakhstan": "Kasachstan",
    "Kenya": "Kenia",
    "Kiribati": "Kiribati",
    "Korea (Democratic People's Republic of)": "Korea (Demokratische Volksrepublik)",
    "Korea - Republic of": "Korea - Republik",
    "Kuwait": "Kuwait",
    "Kyrgyzstan": "Kirgisistan",
    "Lao People's Democratic Republic": "Laotische Demokratische Volksrepublik",
    "Latvia": "Lettland",
    "Lebanon": "Libanon",
    "Lesotho": "Lesotho",
    "Liberia": "Liberia",
    "Libya": "Libyen",
    "Liechtenstein": "Liechtenstein",
    "Lithuania": "Litauen",
    "Luxembourg": "Luxemburg",
    "Macao": "Macao",
    "Madagascar": "Madagaskar",
    "Malawi": "Malawi",
    "Malaysia": "Malaysia",
    "Maldives": "Maldiven",
    "Mali": "Mali",
    "Malta": "Malta",
    "Marshall Islands": "Marshallinseln",
    "Mauritania": "Mauretanien",
    "Mauritius": "Mauritius",
    "Mexico": "Mexiko",
    "Micronesia (Federated States of)": "Mikronesien (Föderierte Staaten von)",
    "Moldova - Republic of": "Moldawien - Republik von",
    "Monaco": "Monaco",
    "Mongolia": "Mongolei",
    "Montenegro": "Montenegro",
    "Montserrat": "Montserrat",
    "Morocco": "Marokko",
    "Mozambique": "Mosambik",
    "Myanmar": "Myanmar",
    "Namibia": "Namibia",
    "Nauru": "Nauru",
    "Nepal": "Nepal",
    "Netherlands": "Niederlande",
    "New Caledonia": "Neukaledonien",
    "New Zealand": "Neuseeland",
    "Nicaragua": "Nicaragua",
    "Niger": "Niger",
    "Nigeria": "Nigeria",
    "Niue": "Niue",
    "Norfolk Island": "Norfolkinsel",
    "North Macedonia": "Nordmazedonien",
    "Northern Mariana Islands": "Nördliche Marianen",
    "Norway": "Norwegen",
    "Oman": "Oman",
    "Pakistan": "Pakistan",
    "Palau": "Palau",
    "Palestine - State of": "Palästina",
    "Panama": "Panama",
    "Papua New Guinea": "Papua-Neuguinea",
    "Paraguay": "Paraguay",
    "Peru": "Peru",
    "Philippines": "Philippinen",
    "Pitcairn": "Pitcairninseln",
    "Poland": "Polen",
    "Portugal": "Portugal",
    "Puerto Rico": "Puerto Rico",
    "Qatar": "Katar",
    "Romania": "Rumänien",
    "Russian Federation": "Russische Föderation",
    "Rwanda": "Ruanda",
    "Saint Barth\u00e9lemy": "Saint-Barthélemy",
    "Saint Helena - Ascension and Tristan da Cunha": "Saint Helena, Ascension und Tristan da Cunha",
    "Saint Kitts and Nevis": "Saint Kitts und Nevis",
    "Saint Lucia": "Saint Lucia",
    "Saint Martin (French part)": "Saint Martin (französischer Teil)",
    "Saint Vincent and the Grenadines": "Saint Vincent und die Grenadinen",
    "Samoa": "Samoa",
    "San Marino": "San Marino",
    "Sao Tome and Principe": "São Tomé und Príncipe",
    "Senegal": "Senegal",
    "Serbia": "Serbien",
    "Seychelles": "Seychellen",
    "Sierra Leone": "Sierra Leone",
    "Singapore": "Singapur",
    "Sint Maarten (Dutch part)": "Sint Maarten (niederländischer Teil)",
    "Slovakia": "Slowakei",
    "Slovenia": "Slowenien",
    "Solomon Islands": "Salomonen",
    "Somalia": "Somalia",
    "South Africa": "Südafrika",
    "South Georgia and the South Sandwich Islands": "Südgeorgien und die Südlichen Sandwichinseln",
    "South Sudan": "Südsudan",
    "Spain": "Spanien",
    "Sri Lanka": "Sri Lanka",
    "Sudan": "Sudan",
    "Suriname": "Suriname",
    "Svalbard and Jan Mayen": "Svalbard und Jan Mayen",
    "Sweden": "Schweden",
    "Switzerland": "Schweiz",
    "Syrian Arab Republic": "Syrien",
    "Tajikistan": "Tadschikistan",
    "United Republic of Tanzania": "Vereinigte Republik Tansania",
    "Thailand": "Thailand",
    "Timor-Leste": "Osttimor",
    "Togo": "Togo",
    "Tokelau": "Tokelau",
    "Tonga": "Tonga",
    "Trinidad and Tobago": "Trinidad und Tobago",
    "Tunisia": "Tunesien",
    "Turkey": "Türkei",
    "Turkmenistan": "Turkmenistan",
    "Turks and Caicos Islands": "Turks- und Caicosinseln",
    "Tuvalu": "Tuvalu",
    "Uganda": "Uganda",
    "Ukraine": "Ukraine",
    "United Arab Emirates": "Vereinigte Arabische Emirate",
    "United Kingdom of Great Britain and Northern Ireland": "Vereinigtes Königreich Großbritannien und Nordirland",
    "United States of America": "Vereinigte Staaten von Amerika",
    "United States Minor Outlying Islands": "Vereinigte Staaten von Amerika, abgelegene Inseln",
    "Uruguay": "Uruguay",
    "Uzbekistan": "Usbekistan",
    "Vanuatu": "Vanuatu",
    "Venezuela (Bolivarian Republic of)": "Venezuela (Bolivarische Republik)",
    "Viet Nam": "Vietnam",
    "Virgin Islands (British)": "Jungferninseln (Vereinigtes Königreich)",
    "Virgin Islands (U.S.)": "Jungferninseln (USA)",
    "Wallis and Futuna": "Wallis und Futuna",
    "Yemen": "Jemen",
    "Zambia": "Sambia",
    "Zimbabwe": "Simbabwe",
    "Taiwan": "Taiwan",
    "Palestine": "Palästina",
    "Western Sahara": "Westsahara",
    "Kosovo": "Kosovo",
    "South Ossetia": "Südossetien",
    "Abkhazia": "Abchasien",
    "Nagorno-Karabakh (Artsakh)": "Nagorny Karabach (Arzach)",
    "Transnistria": "Transnistrien",
    "Somaliland": "Somaliland",
    "Northern Cyprus": "Nordzypern",
    "Saudi Arabia": "Saudi-Arabien",
    "Yugoslavia": "Jugoslawien",

    "Login": "Anmeldung",
    "Enter Your Email": "Geben Sie Ihre E-Mail ein",
    "Login With Phone Number": "Mit Telefonnummer anmelden",
    "Verify Using Sms/Email": "Mit SMS/Email verifizieren",
    "Resend Code In 100 Seconds": "Code in 100 Sekunden erneut senden",
    "Verify": "Verifizieren",
    "Verifying": "Wird überprüft",
    "Last Week": "Letzte Woche",
    "Older Transaction": "Ältere Transaktion",
    "Total": "Gesamt",
    "Conversion Confirmed": "Konversion bestätigt",
    "User Account Limit": "Benutzerkontolimit",
    "Monthly Balance Limit": "Monatliches Guthabenlimit",
    "Total Limit": "Gesamtlimit",
    "Used": "Verwendet",
    "Remaining": "Übrig",
    "Allowed Minimum Topup Amount": "Erlaubter Mindestaufstockungsbetrag",
    "Allowed Maximum Topup Amount": "Erlaubter Höchstaufstockungsbetrag",
    "Incoming Limit": "Eingehendes Limit",
    "Daily Limit": "Tageslimit",
    "Monthly Limit": "Monatliches Limit",
    "Yearly Limit": "Jährliches Limit",
    "Sending Limit": "Sendelimit",
    "Allowed Number Of Transactions": "Erlaubte Anzahl an Transaktionen",
    "Total Amount": "Gesamtbetrag",
    "Amount To Send": "Zu sendender Betrag",
    "Destination Amount": "Zielbetrag",
    "Note": "Hinweis",
    "Revised Amount": "Überarbeiteter Betrag",
    "Save": "Speichern",
    "Date": "Datum",
    "Action": "Aktion",
    "Payment Scheduled At": "Geplanter Zahlungstermin",
    "Transaction Fee": "Transaktionsgebühr",
    "Number Of Cycle": "Anzahl der Zyklen",
    "Subscription Starting Date": "Abonnement Startdatum",
    "Until": "Bis",
    "Next Transaction": "Nächste Transaktion",
    "Pay Now": "Jetzt bezahlen",
    "Transaction Method": "Transaktionsmethode",
    "Mobile Money Provider": "Anbieter von Mobile Money",
    "Wallet Name": "Wallet Name",
    "Wallet Number": "Wallet Nummer",
    "Crypto Currency": "Kryptowährung",
    "Wallet Address": "Wallet Adresse",
    "Select Document Type": "Wählen Sie den Dokumenttyp",
    "Edit Document Number": "Bearbeiten Sie die Dokumentnummer",
    "My Withdrawals": "Meine Abhebungen",
    "Bargainable": "Verhandelbar",
    "Description": "Beschreibung",
    "Attached Files": "Angehängte Dateien",
    "Sent": "Gesendet",
    "Accepted": "Akzeptiert",
    "Decline": "Abgelehnt",
    "Pending": "Ausstehend",
    "Overall Rating": "Gesamte Bewertung",
    "No Review": "Keine Bewertung",
    "0 Rating": "0 Bewertung",
    "Transaction Map": "Transaktionskarte",
    "Send Money": "Geld senden",
    "Sending Method": "Sendemethode",
    "Trouble Sending Money": "Problem beim Geld senden",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Die Gebühr und der erhaltene Betrag können je nach gewählter Zahlungsmethode variieren.",
    "Select Method": "Methode auswählen",
    "Completed": "Abgeschlossen",
    "How Was Your Experience?": "Wie war Ihre Erfahrung?",
    "Leave A Review": "Hinterlassen Sie eine Bewertung",
    "Review History": "Bewertungsverlauf",
    "Write A Review": "Schreiben Sie eine Bewertung",
    "Send And Post": "Senden und Posten",
    "Instapay Cash-Out Crypto To Fiat Conversion Is A Robust And Secure Solution That Seamlessly Converts Cryptocurrencies To Fiat Currencies. It Caters To The Diverse Needs Of Users, Offering A Plethora Of Payout Channels Tailored To Individual Preferences And Locations.": "\"Instapay Cash-Out Die Umwandlung von Krypto in Fiat ist eine robuste und sichere Lösung, die Kryptowährungen nahtlos in Fiat-Währungen umwandelt. Sie deckt die unterschiedlichen Bedürfnisse der Nutzer ab und bietet eine Vielzahl von Zahlungsmethoden, die an individuelle Vorlieben und Standorte angepasst sind.\"",
    "Review Created": "Überprüfung erstellt",
    "Transactions": "Transaktionen",
    "Drag/Drop Or Upload A File (Photo/Video/Pdf)": "Datei ziehen/ablegen oder hochladen (Foto/Video/PDF)",
    "Max Size 1GB": "Maximalgröße 1GB",
    "Title Description": "Titelbeschreibung",
    "Add New": "Neu hinzufügen",
    "Discard": "Verwerfen",
    "Save And Post": "Speichern und posten",
    "Personal Account": "Persönliches Konto",
    "Conversion": "Umrechnung",
    "Spent In Total": "Insgesamt ausgegeben",
    "International": "International",
    "Recharge": "Aufladen",
    "Top Up Wallet To Wallet": "Wallet zu Wallet aufladen",
    "PayPal": "PayPal",
    "Instapay Wallet": "Instapay Wallet",
    "Not Connected": "Nicht verbunden",
    "More": "Mehr",
    "Less": "Weniger",
    "Profile Reports": "Profilberichte",
    "Timezone": "Zeitzone",
    "Cancel": "Abbrechen",
    "Full Name": "Vollständiger Name",
    "Google Authenticator": "Google Authentifikator",
    "Documents Supplémentaires": "Zusätzliche Dokumente",
    "Profile Complete": "Profil vervollständigt",
    "Verified": "Verifiziert",
    "Bank Account": "Bankkonto",
    "Mobile Wallet": "Mobil-Wallet",
    "Cash Pickup": "Bargeldabholung",
    "Add": "Hinzufügen",
    "Configured": "Konfiguriert",
    "Email": "E-Mail",
    "Download Data": "Daten herunterladen",
    "Enter The Amount": "Betrag eingeben",
    "They Receive": "Sie erhalten",
    "Where Do You Want To Send Money": "Wohin möchten Sie Geld senden?",
    "Bank Transfer": "Banküberweisung",
    "Receiving Method": "Empfangsmethode",
    "Payer": "Zahler",
    "Minimum Amount": "Mindestbetrag",
    "Maximum Amount": "Maximale Menge",
    "Search": "Suchen",
    "Show More": "Mehr anzeigen",
    "Select Remark / Reasons (All)": "Bemerkung / Grund auswählen (Alle)",
    "Attach Files": "Dateien anhängen",
    "Holiday List": "Urlaubsliste",
    "No Data To Show": "Keine Daten zum Anzeigen",
    "Threshold Limits": "Schwellenwertgrenzen",
    "Amount Limits": "Betragsgrenzen",
    "OTP Verification Code": "OTP-Verifizierungscode",
    "Verify Using Authenticator": "Mit Authentifikator verifizieren",
    "Sms/Email Verification Code": "SMS/Email-Verifizierungscode",
    "Invite Them": "Sie einladen",
    "Lookup": "Nachschlagen",
    "Invite Friend": "Freund einladen",
    "Invite Via Phone Number": "Per Telefonnummer einladen",
    "Invite Via Email Address": "Per E-Mail-Adresse einladen",
    "Next": "Nächster",
    "Back": "Zurück",
    "Invite Via Email": "Per E-Mail einladen",
    "Available Currency": "Verfügbare Währung",
    "Add Remarks And Comments": "Bemerkungen und Kommentare hinzufügen",
    "Recipient Details": "Empfängerdetails",
    "Go Back": "Zurück gehen",
    "Trouble Requesting Money": "Probleme bei der Anforderung von Geld",
    "Instant": "Sofort",
    "Subscription": "Abonnement",
    "Schedule": "Zeitplan",
    "Choose Request Type": "Anforderungstyp auswählen",
    "Mobile Airtime": "Mobilfunkzeit",
    "Incorrect Phone Number": "Falsche Telefonnummer",
    "Make Sure You Have Entered A Correct Phone Number.": "Stellen Sie sicher, dass Sie eine richtige Telefonnummer eingegeben haben.",
    "Close": "Schließen",
    "Operator": "Betreiber",
    "Recipient Mobile Number": "Empfängernummer",
    "Amount": "Betrag",
    "Service": "Service",
    "Destination": "Ziel",
    "Top Up Summary": "Aufladezusammenfassung",
    "Daily Sending Limit Exceeded": "Tägliches Sende-Limit überschritten",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Senden Sie Ihrem Kunden oder Käufer ein detailliertes Preisangebot. Präsentieren Sie Ihre Dienstleistung, Produkte oder Angebote professionell mit einem personalisierten Angebot.",
    "Payments Made On Weekdays Before 3:00 PM Will Be Credited To The Receiving Bank Account By Midnight Of The Same Day.": "Zahlungen, die an Werktagen vor 15:00 Uhr erfolgen, werden noch am selben Tag bis Mitternacht dem Empfängerkonto gutgeschrieben.",
    "Set Up Your Receiving Accounts In Up To Three Countries And Instantly Cash Out Funds From Approved Payment Requests To Your Activated Payout Channels.": "Richten Sie Ihre Empfangskonten in bis zu drei Ländern ein und heben Sie sofort Gelder aus genehmigten Zahlungsanforderungen auf Ihre aktivierten Auszahlungskanäle ab.",
    "Kemit Kingdom Reserves The Right To Request Any Additional Documents (Enhanced Due Diligence - EDD) Or Further Information Necessary For Conducting Thorough Verifications, In Accordance With Its Legal Obligations.": "Kemit Kingdom behält sich das Recht vor, gemäß seinen gesetzlichen Verpflichtungen zusätzliche Dokumente (Erweiterte Sorgfaltspflicht - EDD) oder weitere Informationen anzufordern, die für gründliche Verifikationen erforderlich sind.",
    "It Must Be A Government-Issued Identification Document With A Photo. All Identification Documents Must Be Written In The Latin Alphabet. If Not, A Certified Translation Is Required.": "Es muss ein von der Regierung ausgestelltes Identifikationsdokument mit einem Foto sein. Alle Identifikationsdokumente müssen in lateinischer Schrift verfasst sein. Wenn nicht, ist eine zertifizierte Übersetzung erforderlich.",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Zeigen Sie Ihre Arbeit, erweitern Sie Ihre Reichweite. 'Mein Portfolio' ist Ihre digitale Leinwand auf Instapay. Laden Sie eine Vielzahl von Inhalten hoch und präsentieren Sie sie – von fesselnden Bildern und Videos bis hin zu informativen PDFs. Egal, ob Sie ein Künstler, Freelancer oder Unternehmer sind, diese Funktion ermöglicht es Ihnen, Ihre Arbeiten oder Projekte auf visuell ansprechende Weise zu präsentieren.",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet": "Teilen Sie Ihren QR-Code oder Zahlungslink, um sofort in Ihrem Insta Wallet bezahlt zu werden.",
    "You Have 15 Minutes To Complete The Transaction, Please Complete Within The Time Limit To Avoid URL Expiration": "Sie haben 15 Minuten, um die Transaktion abzuschließen.",
    "Time Remaining": "Bitte schließen Sie innerhalb der vorgegebenen Zeit ab, um das Ablaufen der URL zu vermeiden.",
    "Please Process The Payment Via Your Selected Payment Type": "Bitte führen Sie die Zahlung über Ihre ausgewählte Zahlungsmethode durch.",
    "Top-Up Wallet": "Zahlungsart",
    "Confirm Your Payment": "WALLET AUFLADEN",
    "You Will Get": "Bestätigen Sie Ihre Zahlung",
    "Amount To Pay": "Sie erhalten",
    "Proceed To Payment": "Zu zahlender Betrag",
    "Load/Deposit Money To The Wallet": "Weiter zur Zahlung",
    "Withdraw": "Zurückziehen",
    "Quick Transfer": "Schnelle Geldüberweisung",
    "Elevate Your Business With InstaPay": "Abheben",
    "Higher Balances & Transaction Limits": "Steigern Sie Ihr Geschäft mit InstaPay",
    "Unlock The Freedom To Manage Larger Balances And Process Higher Transaction Volumes, All With The Reliability You Expect From InstaPay.\n": "Höhere Guthaben und Transaktionslimits",
    "Full Feature Access": "Entfalten Sie die Freiheit, größere Guthaben zu verwalten und größere Transaktionsvolumina zu verarbeiten, mit der Zuverlässigkeit, die Sie von InstaPay erwarten.",
    "Enjoy The Complete Suite Of InstaPay’s Tools, From Advanced Analytics To Seamless International Payments, Tailored To Meet Your Business Needs.\n": "Voller Funktionszugang",
    "Global Reach": "Globale Reichweite",
    "Expand Your Business Without Borders InstaPay Connects You To Customers And Partners Worldwide, Making Global Transactions Easier Than Ever.": "Erweitern Sie Ihr Geschäft ohne Grenzen. InstaPay verbindet Sie mit Kunden und Partnern weltweit und macht globale Transaktionen so einfach wie nie zuvor.",
    "Advanced Security": "Erweiterte Sicherheit",
    "Business Registration Available Soon\n": "Geschäftsregistrierung bald verfügbar.",
    "Amount After Fee": "Betrag nach Gebühren",
    "Allowed Maximum Amount Per Transaction": "Zulässiger Höchstbetrag pro Transaktion",
    "Transfer": "Überweisung",
    "Uncategorized": "Nicht kategorisiert",
    "Currency ISO Code & Amount": "Währungs-ISO-Code & Betrag",
    "Choose The Top-Up Channel": "Wählen Sie den Auflade-Kanal",
    "You Selected": "den Sie ausgewählt haben",
    "Payout Channel": "Auszahlungskanal",
    "Your Details": "Ihre Details",
    "Detailed Confirmed": "Details bestätigt",
    "Supercharge Your Financial Potential By Upgrading To An InstaPay Business Account Experience The Full Power Of InstaPay With Enhanced Features Designed Specifically For Business Growth.": "Steigern Sie Ihr finanzielles Potenzial, indem Sie auf ein InstaPay Business-Konto upgraden. Erleben Sie die volle Leistung von InstaPay mit verbesserten Funktionen, die speziell für das Unternehmenswachstum entwickelt wurden.",
    "Profile Picture": "Profilbild",
    "Add Photo Or Logo": "Foto oder Logo hinzufügen",
    "First Name": "Vorname",
    "Last Name": "Nachname",
    "Enter Your First Name": "Geben Sie Ihren Vornamen ein",
    "Enter Your Last Name": "Geben Sie Ihren Nachnamen ein",
    "Email (Optional)": "E-Mail (optional)",
    "Password Must": "Passwort muss",
    "It Will Be Autofill If You're Signing Up With Invitation Link": "Es wird automatisch ausgefüllt, wenn Sie sich über einen Einladungslink registrieren.",
    "Change Number": "Nummer ändern",
    "Search Country": "Land suchen",
    "Select From The List": "Wählen Sie aus der Liste",
    "Received Amount": "Erhaltener Betrag",

    "null": null,
    "Processing": "Verarbeitung",
    "Verify Using Sms/Email": "Über SMS/E-Mail verifizieren",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "Already Exist": "Bereits vorhanden",
    "You Can Have Only 8 Wallets": "Sie können nur 8 Wallets haben",
    "Save": "Speichern",
    "0 File": "0 Datei",
    "Search From The List": "Aus der Liste suchen",
    "Amount To Send": "Zu sendender Betrag",
    "Wallet To Wallet": "Wallet zu Wallet",
    "TOP-UP WALLET ": "WALLET AUFLADEN",
    "Enter The Amount": "Betrag eingeben",
    "Load/Deposit Money To The Wallet": "Geld ins Wallet laden/einzahlen",
    "Choose The Top-Up Channel": "Top-Up-Kanal auswählen",
    "Paypal": "Paypal",
    "You Will Get": "Sie erhalten",
    "Amount To Pay": "Zu zahlender Betrag",
    "Card Holder Name ": "Name des Karteninhabers",
    "Card Number": "Kartennummer",
    "CVC": "CVC",
    "MM/YY": "MM/JJ",
    "Your Details ": "Ihre Details",
    "You Selected": "Sie haben ausgewählt",
    "Detailed Confirmed": "Detailliert bestätigt",
    "Transaction Failed": "Transaktion fehlgeschlagen",
    "Something Went Wrong. Try Again.": "Etwas ist schief gelaufen. Versuchen Sie es erneut.",
    "Convert Funds": "Mittel umwandeln",
    "How Would You Like To Cashout?:": "Wie möchten Sie auszahlen?:",
    "Default Receiving Account": "Standard-Empfangskonto",
    "Specify Receiving Account": "Empfangskonto angeben",
    "Which Receiving Account Would You Want Use?:": "Welches Empfangskonto möchten Sie verwenden?:",
    "Pakistan": "Pakistan",
    "Bank Mobile": "Bank Mobil",
    "PDF": "PDF",
    "CSV": "CSV",
    "None": "Keines",
    "Request": "Anfrage",
    "Subscription Payment": "Abonnementzahlung",
    "Is not supported by Paypal, USD will be used as the default currency.": "Wird von PayPal nicht unterstützt, USD wird als Standardwährung verwendet.",
    "Review Payment": "Zahlung überprüfen",
    "Google Authenticator": "Google Authentifikator",
    "Scheduled Has Been Cancelled": "Zeitplan wurde storniert",
    "Select Country First": "Land zuerst auswählen",
    "Select Gender": "Geschlecht auswählen",
    "Select Occupation": "Beruf auswählen",
    "IBAN and SWIFT/BIC": "IBAN und SWIFT/BIC",
    "Mobile Money Provider": "Mobilgeldanbieter",
    "Wallet Name": "Wallet-Name",
    "Wallet Number ": "Wallet-Nummer",
    "Crypto Currency": "Kryptowährung",
    "Wallet Address ": "Wallet-Adresse",
    "Select Document Type ": "Dokumenttyp auswählen",
    "Enter Document Number": "Dokumentnummer eingeben",
    "Msisdn": "MSISDN",
    "Edit ": "Bearbeiten",
    "My Withdrawls": "Meine Abhebungen",
    "Declined": "Abgelehnt",
    "Accepted": "Akzeptiert",
    "Overall Ratings": "Gesamtbewertungen",
    "0 Ratings": "0 Bewertungen",
    "No Reviews": "Keine Bewertungen",
    "Send Money": "Geld senden",
    "Trouble Sending Money?": "Problem beim Geldsenden?",
    "Payment Request": "Zahlungsanforderung",
    "How Was Your Experience?": "Wie war Ihre Erfahrung?",
    "Leave A Review": "Hinterlassen Sie eine Bewertung",
    "Write Review Message": "Bewertung schreiben",
    "Send And Post": "Senden und posten",
    "You Rated": "Sie haben bewertet",
    "Review History": "Bewertungshistorie",
    "Max Size 1GB": "Maximale Größe 1GB",
    "Title": "Titel",
    "Description": "Beschreibung",
    "Add New": "Neu hinzufügen",
    "My Payment Address": "Meine Zahlungsadresse",
    "Personal Account": "Persönliches Konto",
    "Updated Successfully": "Erfolgreich aktualisiert",
    "Spent In": "Ausgegeben in",
    "TOP UP": "Aufladen",
    "Quotation": "Angebot",
    "Amount": "Betrag",
    "Not Connected": "Nicht verbunden",
    "More": "Mehr",
    "Less": "Weniger",
    "Reports": "Berichte",
    "User Profile": "Benutzerprofil",
    "Cancel": "Abbrechen",
    "Saving": "Speichern",
    "Confirm ": "Bestätigen",
    "Verify ": "Verifizieren",
    "Delete Country": "Land löschen",
    "Bank Account": "Bankkonto",
    "Cash Pickup": "Bargeldabholung",
    "Mobile Wallet ": "Mobiles Wallet",
    "Delete Country Withdrawals": "Landabhebungen löschen",
    "Yes , Delete It": "Ja, löschen",
    "E-sim": "E-sim",
    "Utilities": "Versorgungsunternehmen",
    "Searching": "Suche",
    "Recipient Mobile Number": "Empfängermobilnummer",
    "TOP-UP SUMMARY": "Aufladezusammenfassung",
    "Trouble Requesting Money?": "Probleme beim Anfordern von Geld?",
    "Choose The Beneficiary": "Wählen Sie den Begünstigten",
    "Enter The amount": "Geben Sie den Betrag ein",
    "You selected": "Sie haben ausgewählt",
    "Timezone": "Zeitzone",
    "Starting From": "Ab Beginn",
    "Recurring Cycle": "Wiederholender Zyklus",
    "Until I stop": "Bis ich aufhöre",
    "Schedule To": "Terminplanen",
    "Schedule at": "Termin festlegen",
    "Selected time must be at least 5 minutes from now.": "Die ausgewählte Zeit muss mindestens 5 Minuten ab jetzt betragen.",
    "Is this your Location?": "Ist dies Ihr Standort?",
    "Yes ": "Ja",
    "Select Remark Reason": "Wählen Sie den Kommentargrund",
    "Attach File": "Datei anhängen",
    "Invite via Phone Number": "Per Telefonnummer einladen",
    "Invite Friend": "Freund einladen",
    "Invite via Email Address": "Per E-Mail-Adresse einladen",
    "Invite via": "Einladen über",
    "Phone": "Telefon:",
    "Message": "Nachricht:",
    "Send": "Senden",
    "Invited Succesfully!": "Erfolgreich eingeladen!",
    "Email": "E-Mail",
    "Bank Transfer": "Banküberweisung",
    "Mobile Wallet": "Mobile Wallet",
    "Select The Bank": "Wählen Sie die Bank",
    "Select The Payer": "Wählen Sie den Zahler",
    "Min Amount:": "Mindestbetrag:",
    "Max Amount": "Höchstbetrag",
    "Other Beneficiaries:": "Andere Begünstigte:",
    "Back ": "Zurück",
    "Next": "Weiter",
    "Lookup": "Suchen",
    "Invite Them": "Sie einladen",
    "Delete Channel ": "Kanal löschen",
    "Set As Default": "Als Standard festlegen",
    "See More": "Mehr anzeigen",
    "Session Details": "Sitzungsdetails",
    "Platform": "Plattform",
    "Windows": "Windows",
    "Browser Name": "Browsername",
    "Google Chrome": "Google Chrome",
    "Browser Version": "Browserversion",
    "IP Address": "IP-Adresse",
    "Karachi": "Karachi",
    "State": "Bundesland",
    "Sindh": "Sindh",
    "Active": "Aktiv",
    "This currency wallet is currently blocked/disabled and funds cannot be added.": "Diese Währungsbrieftasche ist derzeit blockiert/deaktiviert und es können keine Gelder hinzugefügt werden.",
    "This currency wallet is currently blocked/disabled and funds cannot be converted.": "Diese Währungsbrieftasche ist derzeit blockiert/deaktiviert und es können keine Gelder umgewandelt werden.",
    "This currency wallet is currently blocked/disabled and you cannot withdraw balance.": "Diese Währungsbrieftasche ist derzeit blockiert/deaktiviert und Sie können das Guthaben nicht abheben.",
    " This currency wallet is currently blocked/disabled and you cannot download the statement.": "Diese Währungsbrieftasche ist derzeit blockiert/deaktiviert und Sie können den Kontoauszug nicht herunterladen.",
    "This currency wallet is currently set as your default and cannot be blocked. To proceed, please set a different currency wallet as your default first.": "Diese Währungsbrieftasche ist derzeit als Ihre Standard-Wallet eingestellt und kann nicht blockiert werden. Um fortzufahren, stellen Sie bitte zuerst eine andere Währungsbrieftasche als Standard ein.",
    "This currency wallet is currently set as your default and cannot be disabled. To proceed, please set a different currency wallet as your default first.": "Diese Währungsbrieftasche ist derzeit als Ihre Standard-Wallet eingestellt und kann nicht deaktiviert werden. Um fortzufahren, stellen Sie bitte zuerst eine andere Währungsbrieftasche als Standard ein.",
    "Are you sure, you want to delete the withdrawal details of this country?": "Sind Sie sicher, dass Sie die Abhebungsdetails dieses Landes löschen möchten?",
    "Set up your receiving accounts in up to three countries and instantly cash out funds from approved payment requests to your activated payout channels.": "Richten Sie Ihre Empfangskonten in bis zu drei Ländern ein und heben Sie Gelder sofort aus genehmigten Zahlungsanforderungen auf Ihre aktivierten Auszahlungskanäle ab.",
    "No Beneficiary Found From Pakistan Having Allied Bank Limited Details": "Kein Empfänger aus Pakistan mit den Angaben der Allied Bank Limited gefunden",
    "Invitation Message Has Been Successfully Sent To": "Einladungsnachricht wurde erfolgreich gesendet",
    "The Fee And The Received Amount May Vary Depending On The Chosen Payment Method.": "Die Gebühr und der erhaltene Betrag können je nach gewählter Zahlungsmethode variieren.",
    "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan": "Dow University of Health Sciences, Mission Road, Moin Steel Market, Ranchore Line, Karachi District, Karachi Division, Sindh, 72500, Pakistan",
    "Share Your QR Code Or Pay Link To Instantly Get Paid In Your Insta Wallet.": "Teilen Sie Ihren QR-Code oder Zahlungslink, um sofort in Ihrer Insta-Brieftasche bezahlt zu werden.",
    "Drag/Drop Or Upload A File (Photo/Video/PDF)": "Ziehen/Drop oder Laden Sie eine Datei hoch (Foto/Video/PDF)",
    "Login Again": "Melden Sie sich erneut an",
    "Unblock The Wallet": "Wallet entsperren",
    "Invalid Amount": "Ungültiger Betrag",
    "Add Payment Card": "Zahlungskarte hinzufügen",
    "Save Details To Use Next Time": "Details speichern, um sie beim nächsten Mal zu verwenden",
    "Paid": "Bezahlt",
    "Started On": "Begonnen am",
    "Num Of Cycles": "Anzahl der Zyklen",
    "Ends On": "Endet am",
    "Next Payment At": "Nächste Zahlung um",
    "Beneficiary": "Begünstigter",
    "Recieved Quotation": "Angebotsanfrage erhalten",
    "Sent Quotation": "Angebotsanfrage gesendet",
    "Decline Offer": "Angebot ablehnen",
    "Are You Sure That You Want To Decline The Offer?": "Sind Sie sicher, dass Sie das Angebot ablehnen möchten?",
    "Decline The Offer": "Angebot ablehnen",
    "Bargain Offer": "Feilschen Angebot",
    "Enter Counter Amount": "Geben Sie den Gegenbetrag ein",
    "Custom Amount": "Individueller Betrag",
    "Send The Offer": "Angebot senden",
    "The Offer Is Sent": "Angebot gesendet",
    "Countered Offer": "Gegenangebot",
    "Applied": "Eingereicht",
    "Accept Quotation": "Angebot annehmen",
    "Quotation Not Found Or Already Exist": "Angebot nicht gefunden oder bereits vorhanden",
    "Recieved Request": "Anfrage erhalten",
    "Sent Request": "Anfrage gesendet",
    "Search Request": "Anfrage suchen",
    "Search Quotation": "Angebot suchen",
    "QR Status": "QR-Status",
    "QR Code Description": "QR-Code-Beschreibung",
    "Download Your QR Code": "Laden Sie Ihren QR-Code herunter",
    "Your QR Code": "Ihr QR-Code",
    "Your QR Code Poster": "Ihr QR-Code-Plakat",
    "Download  ": "Herunterladen",
    "Select Relation": "Beziehung auswählen",
    "Nominee Permanent Address": "Dauerhafte Adresse des Nominierten",
    "Enter Your Message": "Geben Sie Ihre Nachricht ein",
    "Select": "Auswählen",
    "Select File Type": "Dateityp auswählen",
    "Send TOP-UP And E-Sim": "Guthaben und E-Sim senden",
    "Search Users": "Benutzer suchen",
    "Select Payment Request Type": "Zahlungstyp auswählen",
    "Wallet To Wallet Transfer": "Wallet-to-Wallet-Transfer",
    "Holiday List": "Feiertagsliste",
    "No Data To Show": "Keine Daten zum Anzeigen",
    "They Recieve": "Sie erhalten",
    "You Will Be Logged Out In 60 Seconds": "Sie werden in 60 Sekunden abgemeldet",
    "Move Your Mouse Or Press Any Key On Your Keyboard To Reset The Timer To 15 Minutes": "Bewegen Sie die Maus oder drücken Sie eine beliebige Taste auf Ihrer Tastatur, um den Timer auf 15 Minuten zurückzusetzen",
    "You've Been Logged Out": "Sie wurden abgemeldet",
    "For Your Security, You’ve Been Logged Out After 15 Minutes Of Inactivity. Please Log In Again If You Wish To Use InstaPay.": "Aus Sicherheitsgründen wurden Sie nach 15 Minuten Inaktivität abgemeldet. Bitte melden Sie sich erneut an, wenn Sie InstaPay verwenden möchten.",
    "Cash": "Bargeld",
    "Business": "Geschäft",
    "Gift": "Geschenk",
    "Salary": "Gehalt",
    "Lottery": "Lotterie",
    "Savings": "Ersparnisse",
    "Other": "Andere",
    "Select Source Of Fund": "Quelle der Mittel auswählen",
    "Select your source of funds": "Wählen Sie Ihre Finanzierungsquelle",
    "Request Times Out. Please Try Again!": "Anfrage ist abgelaufen. Bitte versuchen Sie es erneut!",
    "Resend Code In 100 Seconds": "Code in 100 Sekunden erneut senden",
    "Computer Service": "Computer-Service",
    "Family Support": "Familienunterstützung",
    "Education": "Bildung",
    "Gift And Other Donations": "Geschenke und andere Spenden",
    "Medical Treatment": "Medizinische Behandlung",
    "Maintenance Or Other Expenses": "Wartung oder andere Ausgaben",
    "Travel": "Reisen",
    "Small Value Remittance": "Kleine Wertüberweisung",
    "Liberalized Remittance": "Liberalisierte Überweisung",
    "Construction Expenses": "Baukosten",
    "Hotel Accommodation": "Hotelunterkunft",
    "Advertising And/Or Public Relations Related Expenses": "Werbung und/oder PR-bezogene Ausgaben",
    "Fees For Advisory Or Consulting Service": "Beratungs- oder Consulting-Servicegebühren",
    "Business Related Insurance Payment": "Geschäftsbezogene Versicherungszahlungen",
    "Insurance Claims Payment": "Versicherungsansprüche Zahlungen",
    "Delivery Fees": "Liefergebühren",
    "Payments For Exported Goods": "Zahlungen für exportierte Waren",
    "Payment For Services": "Zahlung für Dienstleistungen",
    "Payment Of Loans": "Kreditrückzahlungen",
    "Office Expenses": "Bürokosten",
    "Residential Property Purchase": "Wohnimmobilienkauf",
    "Property Rental Payment": "Immobilienmietzahlungen",
    "Royalty, Trademark, Patent And Copyright Fees": "Tantiemen, Marken, Patente und Urheberrechtsgebühren",
    "Investment In Shares": "Investitionen in Aktien",
    "Fund Investment": "Fondsinvestitionen",
    "Tax Payment": "Steuerzahlungen",
    "Transportation Fees": "Transportgebühren",
    "Utility Bills": "Versorgungsgebühren",
    "Personal Transfer": "Persönliche Überweisungen",
    "Payment Of Salary": "Gehaltzahlung",
    "Payment Of Rewards": "Belohnungszahlungen",
    "Payment Of Influencer": "Zahlung an Influencer",
    "Broker, Commitment, Guarantee And Other Fees": "Makler-, Verpflichtungs-, Garantie- und andere Gebühren",
    "Other Purposes": "Andere Zwecke",
    "Aunt": "Tante",
    "Brother": "Bruder",
    "Brother-In-Law": "Schwager",
    "Cousin": "Cousin",
    "Daughter": "Tochter",
    "Father": "Vater",
    "Father-In-Law": "Schwiegervater",
    "Friend": "Freund",
    "Grandfather": "Großvater",
    "Grandmother": "Großmutter",
    "Husband": "Ehemann",
    "Mother": "Mutter",
    "Mother-In-Law": "Schwiegermutter",
    "Nephew": "Neffe",
    "Niece": "Nichte",
    "Self (i.e. The Sender, Himself)": "Selbst (d.h. der Absender selbst)",
    "Sister": "Schwester",
    "Sister-In-Law": "Schwiegerschwester",
    "Son": "Sohn",
    "Uncle": "Onkel",
    "Wife": "Ehefrau",
    "Others Not Listed": "Andere, die nicht aufgeführt sind",
    "Passport": "Reisepass",
    "National Identification Card": "Nationaler Personalausweis",
    "Driving License": "Führerschein",
    "Social Security Card/Number": "Sozialversicherungskarte/Nummer",
    "Tax Payer Identification Card/Number": "Steuerzahler-Identifikationskarte/Nummer",
    "Senior Citizen Identification Card": "Senioren-Identifikationskarte",
    "Birth Certificate": "Geburtsurkunde",
    "Village Elder Identification Card": "Dorfältesten-Ausweis",
    "Permanent Residency Identification Card": "Ausweis für unbefristeten Aufenthalt",
    "Alien Registration Certificate/Card": "Ausländerregistrierungsbescheinigung/Karte",
    "PAN Card": "PAN-Karte",
    "Voter’s Identification Card": "Wählerausweis",
    "Health Insurance Card/Number": "Krankenversicherungskarte/-nummer",
    "Employer Identification Card": "Arbeitgeber-Ausweis",
    "Others Not Listed": "Andere nicht aufgeführt",
    "Bank Account": "Bankkonto",
    "Cash Pickup": "Bargeldabholung",
    "Card": "Karte",
    "Arts & Entertainment": "Kunst und Unterhaltung",
    "Banking & Finance": "Banken und Finanzen",
    "Education & Research": "Bildung und Forschung",
    "Engineering & Construction": "Ingenieurwesen und Bauwesen",
    "Healthcare & Medicine": "Gesundheitswesen und Medizin",
    "Information Technology & Services": "Informationstechnologie und Dienstleistungen",
    "Legal & Compliance": "Recht und Compliance",
    "Manufacturing & Production": "Herstellung und Produktion",
    "Marketing & Sales": "Marketing und Vertrieb",
    "Non-profit & Charity": "Gemeinnützige Organisationen und Wohltätigkeit",
    "Real Estate & Property": "Immobilien und Eigentum",
    "Retail & Wholesale": "Einzelhandel und Großhandel",
    "Science & Pharmaceuticals": "Wissenschaft und Pharmazeutika",
    "Transport & Logistics": "Transport und Logistik",
    "Travel & Tourism": "Reisen und Tourismus",
    "Unemployed": "Arbeitslos",
    "Other": "Andere",
    "Cash": "Bargeld",
    "Business": "Geschäft",
    "Gift": "Geschenk",
    "Salary": "Gehalt",
    "Lottery": "Lotterie",
    "Savings": "Ersparnisse",
    "Good Morning": "Guten Morgen",
    "Good Evening": "Guten Abend",
    "Completed": "Abgeschlossen",
    "Load/Deposit Money To The Wallet": "Weiter zur Zahlung",
    "Enter The Amount": "Betrag eingeben",
    "Choose The Top-Up Channel": "Wählen Sie den Auflade-Kanal",
    "Good Night!": "Gute Nacht!",
    "You Will Get": "Bestätigen Sie Ihre Zahlung",
    "Amount To Pay": "Sie erhalten",
    "Top-Up Wallet": "Zahlungsart",
    "Card Holder Name ": "Name des Karteninhabers",
    "Card Number": "Kartennummer",
    "CVC": "CVC",
    "MM/YY": "MM/JJ",
    "Save Details To Use Next Time": "Details speichern, um sie beim nächsten Mal zu verwenden",
    "Select Or Add Payment Card": "Zahlungskarte auswählen oder hinzufügen",
    "Your Details ": "Ihre Details",
    "You Selected": "Sie haben ausgewählt",
    "Detailed Confirmed": "Detailliert bestätigt",
    "Transaction Failed": "Transaktion fehlgeschlagen",
    "Something Went Wrong. Try Again.": "Etwas ist schief gelaufen. Versuchen Sie es erneut.",
    "PDF": "PDF",
    "CSV": "CSV",
    "Failed": "Fehlgeschlagen",
    "Initiated": "Initiiert",
    "Revise": "Überarbeiten",
    "Declined": "Abgelehnt",
    "Bargain-Accepted": "Verhandlung-Akzeptiert",
    "Accepted": "Akzeptiert",
    "Decline The Offer": "Das Angebot ablehnen",
    "Pending": "Ausstehend",
    "Cancelled": "Storniert",
    "Send A Detailed Price Quote To Your Client Or Customer. Present Your Service, Products, Or Offers Professionally With A Personalized Quotation.": "Senden Sie Ihrem Kunden oder Käufer ein detailliertes Preisangebot. Präsentieren Sie Ihre Dienstleistung, Produkte oder Angebote professionell mit einem personalisierten Angebot.",
    "Wallet to wallet": "Wallet zu Wallet",
    "Please follow these examples:": "Bitte folgen Sie diesen Beispielen:",
    "👤 InstaPay/Instagram Username: instauser": "👤 InstaPay/Instagram-Benutzername: instauser",
    "📧 Email: user@email.com": "📧 E-Mail: user@email.com",
    "Full Name": "Vollständiger Name",
    "Welcome to InstaPay! To make sure you can cash out instantly from the funds you receive, you'll need to set up your receiving accounts in the settings. This will allow you to seamlessly transfer money to your preferred bank accounts, mobile wallets, crypto wallets, or cash pick-up locations.": "Willkommen bei InstaPay! Um sicherzustellen, dass Sie die erhaltenen Gelder sofort auszahlen können, müssen Sie Ihre Empfangskonten in den Einstellungen einrichten. Dies ermöglicht es Ihnen, Geld nahtlos auf Ihre bevorzugten Bankkonten, mobilen Geldbörsen, Krypto-Geldbörsen oder Bargeld-Abholorte zu überweisen.",
    "You Have No Beneficiary": "Sie haben keinen Begünstigten",
    "Add Now": "Jetzt hinzufügen",
    "Wallet-Status": "Wallet-Status",
    "Select Payment Card": "Zahlungsmittelkarte auswählen",
    "Your funds have been credited to your wallet.": "Ihre Gelder wurden Ihrem Wallet gutgeschrieben.",
    "Thanks for using InstaPay": "Danke, dass Sie InstaPay verwenden",
    "Get Paid Instantly: Set Up Your Receiving Accounts Now!": "Sofort bezahlt werden: Richten Sie jetzt Ihre Empfangskonten ein!",
    "Follow these simple steps to set up your receiving accounts and ensure you're always ready to access your funds:": "Folgen Sie diesen einfachen Schritten, um Ihre Empfangskonten einzurichten und sicherzustellen, dass Sie jederzeit auf Ihr Geld zugreifen können:",
    "STEP 01": "SCHRITT 01",
    "Go to Settings": "Zu den Einstellungen gehen",
    "Open your InstaPay app and navigate to the \"Settings\" section.": "Öffnen Sie Ihre InstaPay-App und navigieren Sie zum Abschnitt \"Einstellungen\".",
    "STEP 02": "SCHRITT 02",
    "Select Receiving Accounts": "Empfangskonten auswählen",
    "Tap on \"Receiving Accounts\" to start adding your details.": "Tippen Sie auf \"Empfangskonten\", um Ihre Details hinzuzufügen.",
    "Start Adding Accounts": "Konten hinzufügen starten",
    "Phone Number: 44795396600 (With Country Code)": "Telefonnummer: 44795396600 (Mit Ländervorwahl)",
    "Select Country First": "Land zuerst auswählen",
    "First Name": "Vorname",
    "Last Name": "Nachname",
    "Address Line": "Adresszeile",
    "Email Address": "E-Mail-Adresse",
    "Select City": "Wählen Sie die Stadt aus",
    "Select Gender": "Geschlecht auswählen",
    "Select Occupation": "Beruf auswählen",
    "Male": "Männlich",
    "Female": "Weiblich",
    "Non-Binary": "Nicht-binär",
    "Prefer Not To Say'": "Möchte ich nicht sagen",
    "Bitcoin (BTC)": "Bitcoin (BTC)",
    "Tether (USDT)": "Tether (USDT)",
    "Ethereum (ETH)": "Ethereum (ETH)",
    "No User Found": "Kein Benutzer gefunden",
    "Email Address ": "E-Mail-Adresse",
    "Select Document": "Dokument auswählen",
    "Showcase Your Work, Amplify Your Reach. 'My Portfolio' Is Your Digital Canvas On Instapay. Upload And Display A Diverse Range Of Content - From Captivating Images And Videos To Informative PDFs. Whether You're An Artist, Freelancer, Or Entrepreneur, This Feature Lets You Present Your Work Or Projects In A Visually Engaging Way.": "Zeigen Sie Ihre Arbeit, erweitern Sie Ihre Reichweite. 'Mein Portfolio' ist Ihre digitale Leinwand auf Instapay. Laden Sie eine Vielzahl von Inhalten hoch und präsentieren Sie sie – von fesselnden Bildern und Videos bis hin zu informativen PDFs. Egal, ob Sie ein Künstler, Freelancer oder Unternehmer sind, diese Funktion ermöglicht es Ihnen, Ihre Arbeiten oder Projekte auf visuell ansprechende Weise zu präsentieren.",
    "Add New": "Neu hinzufügen",
    "Money Out": "Geld abheben",
    "Browsername": "Browsername",
    "Browserversion": "Browserversion",
    "Credit": "Kredit",
    "Debit": "Debit",
    "Top Up": "Aufladen",
    "Wallet To Wallet": "Wallet zu Wallet",
    "Accountant": "Buchhalter",
    "Architect": "Architekt",
    "Artist": "Künstler",
    "Banker": "Banker",
    "Business Owner": "Unternehmer",
    "Consultant": "Berater",
    "Doctor": "Arzt",
    "Engineer": "Ingenieur",
    "Farmer": "Bauer",
    "Government Employee": "Regierungsangestellter",
    "IT Professional": "IT-Fachmann",
    "Lawyer": "Anwalt",
    "Nurse": "Krankenschwester",
    "Retailer": "Einzelhändler",
    "Salesperson": "Verkäufer",
    "Student": "Student",
    "Teacher": "Lehrer",
    "Prefer Not to Say": "Bevorzuge keine Angabe",
    "Timezone": "Zeitzone",
    "Subscription Payment": "Abonnementzahlung",
    "Starting From": "Ab Beginn",
    "Recurring Cycle": "Wiederholender Zyklus",
    "Until I stop": "Bis ich aufhöre",
    "Schedule To": "Terminplanen",
    "Schedule at": "Termin festlegen",
    "Selected time must be at least 5 minutes from now.": "Die ausgewählte Zeit muss mindestens 5 Minuten ab jetzt betragen.",
    "They Recieve": "Sie erhalten",
    "Attach Files": "Dateien anhängen",
    "Wallet Selected": "Wallet ausgewählt",
    "You Have Selected PKR Currency Wallet For This Transaction": "Sie haben für diese Transaktion das PKR-Währungs-Wallet ausgewählt",
    "Instant": "Sofort",
    "Subscription": "Abonnement",
    "Schedule": "Zeitplan",
    "Sms/Email Verification Code": "SMS/Email-Verifizierungscode",
    "Confirm Your Password": "Bestätigen Sie Ihr Passwort",
    "This Security Feature Requires Password Confirmation!": "Diese Sicherheitsfunktion erfordert eine Passwortbestätigung!",
    "Use The Application To Scan The QR Code Or Use Your Secret Code To Activate Google Two-Factor Authentication": "Verwenden Sie die Anwendung, um den QR-Code zu scannen oder verwenden Sie Ihren geheimen Code, um die Google Zwei-Faktor-Authentifizierung zu aktivieren",
    "OR": "ODER",
    "Paste This Key In The Googe Authenticator App": "Fügen Sie diesen Schlüssel in die Google Authenticator-App ein",
    "Show Secret Key": "Geheimen Schlüssel anzeigen",
    "Enter The Six-Digit Code From The Application": "Geben Sie den sechsstelligen Code aus der Anwendung ein",
    "6 Digit Code": "6-stelliger Code",
    "Enable": "Aktivieren",
    "Disable": "Deaktivieren",
    "After Scanning The Barcode Image, The App Will Display A Six-Digit Code That You Can Enter Below": "Nach dem Scannen des Barcode-Bildes zeigt die App einen sechsstelligen Code an, den Sie unten eingeben können",
    "Google Two Factor Authentication Is Now Linked To Your Instapay Account": "Google Zwei-Faktor-Authentifizierung ist jetzt mit Ihrem Instapay-Konto verknüpft",
    "Min Amount:": "Mindestbetrag:",
    "Minimum Amount": "Mindestbetrag",
    "Maximum Amount": "Maximale Menge",
    "Identity Verification Required": "Identitätsüberprüfung erforderlich",
    "Select Your Wallet Currency": "Wählen Sie Ihre Wallet-Währung aus",
    "Setup Authenticator": "Authentifikator einrichten",
    "Note": "Hinweis",
    "This Is A Test Notice": "Dies ist eine Testbenachrichtigung",
    "Session Expired": "Sitzung abgelaufen",
    "You have been logged out for security reasons, please login again to continue.": "Aus Sicherheitsgründen wurden Sie abgemeldet, bitte melden Sie sich erneut an, um fortzufahren.",
    "Redirecting You To Login Page In 5 Seconds": "Weiterleitung zur Login-Seite in 5 Sekunden",
    "Searching": "Suche",
    "Recipient Mobile Number": "Empfängermobilnummer",
    "TOP-UP SUMMARY": "Aufladezusammenfassung",
    "Amount": "Betrag",
    "Something went wrong while getting countries.": "Etwas ist beim Abrufen der Länder schiefgelaufen.",
    "Select Destination Country": "Wählen Sie das Zielland aus.",
    "PKR is not supported by Paypal, USD will be used as the default currency.": "PKR wird von Paypal nicht unterstützt, USD wird als Standardwährung verwendet.",
    "Select A Date": "Wählen Sie ein Datum",
    "Select A Timezone": "Wählen Sie eine Zeitzone",
    "Proceed": "Fortfahren",
    "Proceeding": "Fortschreiten",
    "You Can Now Close This Webpage And Go Back To Instagram": "Sie können diese Webseite jetzt schließen und zu Instagram zurückkehren",
    "Select A Time": "Wählen Sie eine Uhrzeit",
    "Location Permission Denied": "Standortberechtigung verweigert",
    "Follow the below instructions to enable location services in your browser after denying permission previously:": "Befolgen Sie die unten stehenden Anweisungen, um Standortdienste in Ihrem Browser zu aktivieren, nachdem Sie zuvor die Berechtigung verweigert haben:",
    "1: Chrome Browser": "1: Chrome-Browser",
    "Open the Chrome app.": "Öffnen Sie die Chrome-App.",
    "Tap the three-dot menu (top-right corner) and select Settings.": "Tippen Sie auf das Drei-Punkte-Menü (oben rechts) und wählen Sie Einstellungen.",
    "Scroll down to Site Settings > Location.": "Scrollen Sie nach unten zu Website-Einstellungen > Standort.",
    "Locate the website in the list or search for it.": "Suchen Sie die Website in der Liste oder durchsuchen Sie sie.",
    "Tap the website and select Allow under Location Access.": "Tippen Sie auf die Website und wählen Sie Zulassen unter Standortzugriff.",
    "2: Firefox Browser": "2: Firefox-Browser",
    "Open the Firefox app.": "Öffnen Sie die Firefox-App.",
    "Tap the three-line menu (bottom-right corner) and go to Settings.": "Tippen Sie auf das Drei-Striche-Menü (unten rechts) und gehen Sie zu Einstellungen.",
    "Select Privacy & Security > Site Permissions > Location.": "Wählen Sie Datenschutz & Sicherheit > Website-Berechtigungen > Standort.",
    "Find the website in the list and set the permission to Allow.": "Suchen Sie die Website in der Liste und setzen Sie die Berechtigung auf Zulassen.",
    "3: Samsung Internet Browser": "3: Samsung Internet-Browser",
    "Open the Samsung Internet app.": "Öffnen Sie die Samsung Internet-App.",
    "Tap the three-line menu (bottom-right corner) and select Settings.": "Tippen Sie auf das Drei-Striche-Menü (unten rechts) und wählen Sie Einstellungen.",
    "Go to Sites and Downloads > Site Permissions > Location.": "Gehen Sie zu Websites und Downloads > Website-Berechtigungen > Standort.",
    "Find the website and change the permission to Allow.": "Suchen Sie die Website und ändern Sie die Berechtigung auf Zulassen.",
    "4: Safari Browser": "4: Safari-Browser",
    "Open the Settings app.": "Öffnen Sie die App „Einstellungen“.",
    "Scroll down and select Safari.": "Scrollen Sie nach unten und wählen Sie Safari aus.",
    "Tap Location and set it to Ask Next Time or Allow While Using the App.": "Tippen Sie auf Standort und stellen Sie ihn auf „Beim nächsten Mal fragen“ oder „Beim Verwenden der App erlauben“ ein.",
    "Revisit the website and when prompt, allow location access": "Besuchen Sie die Website erneut und erlauben Sie den Standortzugriff, wenn Sie dazu aufgefordert werden.",
    "Tip: If the website does not appear in the browser settings, clear your browser’s cache or history and try again.": "Tipp: Wenn die Website nicht in den Browsereinstellungen angezeigt wird, löschen Sie den Cache oder Verlauf Ihres Browsers und versuchen Sie es erneut.",
    "To send a payment request, please share your location—this helps us boost security and build trust for a smoother experience!": "Um eine Zahlungsanfrage zu senden, teilen Sie bitte Ihren Standort—dies hilft uns, die Sicherheit zu erhöhen und Vertrauen für ein reibungsloseres Erlebnis aufzubauen!",
    "Decline": "Ablehnen",
    "Share Location": "Standort teilen",
    "Already Exist": "Bereits Vorhanden",
    "Withdraw": "Abheben",
    "Seller Left A reply": "Verkäufer hat eine Antwort hinterlassen",
    "Check Now": "Jetzt überprüfen",
    "You Left A Review": "Sie haben eine Bewertung hinterlassen",
    "View Larger Map": "Größere Karte anzeigen",
    "English (UK)": "Englisch (UK)",
    "QR Code Title": "QR-Code-Titel",
    "Notice!": "Hinweis!",
    "By proceeding with this transaction, I hereby confirm that I am acting independently and of my own free will. I affirm that I have not been influenced, coached, coerced, or persuaded by any third party to carry out this transaction for investment purposes or any other reason.": "Durch das Fortsetzen dieser Transaktion bestätige ich hiermit, dass ich unabhängig und aus eigenem freien Willen handle. Ich bestätige, dass ich nicht von Dritten beeinflusst, angeleitet, gezwungen oder überredet wurde, diese Transaktion zu Investitionszwecken oder aus einem anderen Grund durchzuführen.",
    "I understand that this confirmation is required to ensure compliance and to safeguard my account and transactions.": "Ich verstehe, dass diese Bestätigung erforderlich ist, um die Einhaltung der Vorschriften sicherzustellen und mein Konto sowie meine Transaktionen zu schützen.",
    "Confirm and Proceed": "Bestätigen und fortfahren",
    "Blocked Wallet": "Gesperrte Brieftasche",
  "Nominee's Full Name": "Vollständiger Name des Nominierten",
  "Insufficient Balance": "Unzureichendes Guthaben",
  "Upload Photo": "Foto hochladen",
  "Security Alert": "Sicherheitswarnung",
  "Please remember to add your security questions for smooth experience. You will not be able to access the features of InstaPay unless You add the security questions.": "Bitte denken Sie daran, Ihre Sicherheitsfragen hinzuzufügen, um eine reibungslose Nutzung zu gewährleisten. Sie können die Funktionen von InstaPay nicht nutzen, wenn Sie die Sicherheitsfragen nicht hinzufügen.",
  "Setup Security Questions": "Sicherheitsfragen einrichten",
   "Geo Fencing": "Geofencing",
  "Manage Payment & Quotation Access": "Zahlungs- und Angebotszugriff verwalten",
  "Control countries from where you can receive Payment Requests and Quotations. Toggle on/off access for specific countries.": "Kontrollieren Sie die Länder, aus denen Sie Zahlungsanfragen und Angebote erhalten können. Aktivieren/deaktivieren Sie den Zugriff für bestimmte Länder.",
  "Search Countries ": "Länder suchen",
  "Filter": "Filter",
  "Payment Requests": "Zahlungsanfragen",
  "#No": "#Nr",
  "Country": "Land",
  "Account’s Country": "Konto-Land",
  "Current Location": "Aktueller Standort",
  "Save Changes": "Änderungen speichern",
  "Asia": "Asien",
  "Europe": "Europa",
  "Africa": "Afrika",
  "Oceania": "Ozeanien",
  "Americas": "Amerika",
  "Polar": "Polar",
  "Finger Biometric": "Fingerbiometrie",
  "Confirm": "Bestätigen",
  "Resend available in 77 seconds": "Erneutes Senden in 77 Sekunden verfügbar",
  "Invalid OTP!": "Ungültiges OTP!",
  "Finger Biometric Updated": "Fingerbiometrie aktualisiert",
  "Your finger biometric has been disabled successfully!": "Ihre Fingerbiometrie wurde erfolgreich deaktiviert!",
  "Your finger biometric has been enabled successfully!": "Ihre Fingerbiometrie wurde erfolgreich aktiviert!",
  "Afghanistan": "Afghanistan",
  "Armenia": "Armenien",
  "Azerbaijan": "Aserbaidschan",
  "Bahrain": "Bahrain",
  "Bangladesh": "Bangladesch",
  "Bhutan": "Bhutan",
  "Brunei": "Brunei",
  "Cambodia": "Kambodscha",
  "China": "China",
  "East Timor": "Osttimor",
  "Georgia": "Georgien",
  "Hong Kong S.A.R.": "Hongkong S.A.R.",
  "India": "Indien",
  "Indonesia": "Indonesien",
  "Iran": "Iran",
  "Iraq": "Irak",
  "Israel": "Israel",
  "Japan": "Japan",
  "Jordan": "Jordanien",
  "Kazakhstan": "Kasachstan",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kirgisistan",
  "Laos": "Laos",
  "Lebanon": "Libanon",
  "Macau S.A.R.": "Macau S.A.R.",
  "Malaysia": "Malaysia",
  "Maldives": "Malediven",
  "Mongolia": "Mongolei",
  "Myanmar": "Myanmar",
  "Nepal": "Nepal",
  "North Korea": "Nordkorea",
  "Oman": "Oman",
  "Palestinian Territory Occupied": "Besetztes Palästinensisches Gebiet",
  "Philippines": "Philippinen",
  "Qatar": "Katar",
  "Saudi Arabia": "Saudi-Arabien",
  "Singapore": "Singapur",
  "South Korea": "Südkorea",
  "Sri Lanka": "Sri Lanka",
  "Syria": "Syrien",
  "Taiwan": "Taiwan",
  "Tajikistan": "Tadschikistan",
  "Thailand": "Thailand",
  "Turkey": "Türkei",
  "Turkmenistan": "Turkmenistan",
  "United Arab Emirates": "Vereinigte Arabische Emirate",
  "Uzbekistan": "Usbekistan",
  "Vietnam": "Vietnam",
  "Yemen": "Jemen",
  "Åland Islands": "Åland-Inseln",
  "Albania": "Albanien",
  "Andorra": "Andorra",
  "Austria": "Österreich",
  "Belarus": "Weißrussland",
  "Belgium": "Belgien",
  "Bosnia and Herzegovina": "Bosnien und Herzegowina",
  "Bulgaria": "Bulgarien",
  "Croatia": "Kroatien",
  "Cyprus": "Zypern",
  "Czech Republic": "Tschechische Republik",
  "Denmark": "Dänemark",
  "Estonia": "Estland",
  "Faroe Islands": "Färöer-Inseln",
  "Finland": "Finnland",
  "France": "Frankreich",
  "Germany": "Deutschland",
  "Gibraltar": "Gibraltar",
  "Greece": "Griechenland",
  "Guernsey and Alderney": "Guernsey und Alderney",
  "Hungary": "Ungarn",
  "Iceland": "Island",
  "Ireland": "Irland",
  "Italy": "Italien",
  "Jersey": "Jersey",
  "Kosovo": "Kosovo",
  "Latvia": "Lettland",
  "Liechtenstein": "Liechtenstein",
  "Lithuania": "Litauen",
  "Luxembourg": "Luxemburg",
  "Malta": "Malta",
  "Isle of Man": "Isle of Man",
  "Moldova": "Moldawien",
  "Monaco": "Monaco",
  "Montenegro": "Montenegro",
  "Netherlands": "Niederlande",
  "North Macedonia": "Nordmazedonien",
  "Norway": "Norwegen",
  "Poland": "Polen",
  "Portugal": "Portugal",
  "Romania": "Rumänien",
  "Russia": "Russland",
  "San Marino": "San Marino",
  "Serbia": "Serbien",
  "Slovakia": "Slowakei",
  "Slovenia": "Slowenien",
  "Spain": "Spanien",
  "Svalbard and Jan Mayen Islands": "Spitzbergen und Jan Mayen",
  "Sweden": "Schweden",
  "Switzerland": "Schweiz",
  "Ukraine": "Ukraine",
  "United Kingdom": "Vereinigtes Königreich",
  "Vatican City": "Vatikanstadt",
  "Algeria": "Algerien",
  "Angola": "Angola",
  "Benin": "Benin",
  "Botswana": "Botsuana",
  "British Indian Ocean Territory": "Britisches Territorium im Indischen Ozean",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cameroon": "Kamerun",
  "Cape Verde": "Kap Verde",
  "Central African Republic": "Zentralafrikanische Republik",
  "Chad": "Tschad",
  "Comoros": "Komoren",
  "Congo": "Kongo",
  "Côte d'Ivoire (Ivory Coast)": "Elfenbeinküste",
  "Democratic Republic of the Congo": "Demokratische Republik Kongo",
  "Djibouti": "Dschibuti",
  "Egypt": "Ägypten",
  "Equatorial Guinea": "Äquatorialguinea",
  "Eritrea": "Eritrea",
  "Ethiopia": "Äthiopien",
  "French Southern Territories": "Französische Südgebiete",
  "Gabon": "Gabun",
  "Gambia": "Gambia",
  "Ghana": "Ghana",
  "Guinea": "Guinea",
  "Guinea-Bissau": "Guinea-Bissau",
  "Kenya": "Kenia",
  "Lesotho": "Lesotho",
  "Liberia": "Liberia",
  "Libya": "Libyen",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Mali": "Mali",
  "Mauritania": "Mauretanien",
  "Mauritius": "Mauritius",
  "Mayotte": "Mayotte",
  "Morocco": "Marokko",
  "Mozambique": "Mosambik",
  "Namibia": "Namibia",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Réunion": "Réunion",
  "Rwanda": "Ruanda",
  "Saint Helena": "Sankt Helena",
  "São Tomé and Príncipe": "São Tomé und Príncipe",
  "Senegal": "Senegal",
  "Seychelles": "Seychellen",
  "Sierra Leone": "Sierra Leone",
  "Somalia": "Somalia",
  "South Africa": "Südafrika",
  "South Sudan": "Südsudan",
  "Sudan": "Sudan",
  "Swaziland (Eswatini)": "Eswatini",
  "Tanzania": "Tansania",
  "Togo": "Togo",
  "Tunisia": "Tunesien",
  "Uganda": "Uganda",
  "Western Sahara": "Westsahara",
  "Zambia": "Sambia",
  "Zimbabwe": "Simbabwe",
  "American Samoa": "Amerikanisch-Samoa",
  "Australia": "Australien",
  "Christmas Island": "Weihnachtsinsel",
  "Cocos (Keeling) Islands": "Kokosinseln (Keeling)",
  "Cook Islands": "Cookinseln",
  "Fiji": "Fidschi",
  "French Polynesia": "Französisch-Polynesien",
  "Guam": "Guam",
  "Kiribati": "Kiribati",
  "Marshall Islands": "Marshallinseln",
  "Micronesia": "Mikronesien",
  "Nauru": "Nauru",
  "New Caledonia": "Neukaledonien",
  "New Zealand": "Neuseeland",
  "Niue": "Niue",
  "Norfolk Island": "Norfolkinsel",
  "Northern Mariana Islands": "Nördliche Marianen",
  "Palau": "Palau",
  "Papua New Guinea": "Papua-Neuguinea",
  "Pitcairn Islands": "Pitcairninseln",
  "Samoa": "Samoa",
  "Solomon Islands": "Salomonen",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Tuvalu": "Tuvalu",
  "Vanuatu": "Vanuatu",
  "Wallis and Futuna": "Wallis und Futuna",
  "North & South America": "Nord- und Südamerika",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua und Barbuda",
  "Argentina": "Argentinien",
  "Aruba": "Aruba",
  "Barbados": "Barbados",
  "Belize": "Belize",
  "Bermuda": "Bermuda",
  "Bolivia": "Bolivien",
  "Bonaire, Sint Eustatius, and Saba": "Bonaire, Sint Eustatius und Saba",
  "Brazil": "Brasilien",
  "Canada": "Kanada",
  "Cayman Islands": "Kaimaninseln",
  "Chile": "Chile",
  "Colombia": "Kolumbien",
  "Costa Rica": "Costa Rica",
  "Cuba": "Kuba",
  "Curaçao": "Curaçao",
  "Dominica": "Dominica",
  "Dominican Republic": "Dominikanische Republik",
  "Ecuador": "Ecuador",
  "El Salvador": "El Salvador",
  "Falkland Islands": "Falklandinseln",
  "French Guiana": "Französisch-Guayana",
  "Greenland": "Grönland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guatemala": "Guatemala",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Honduras": "Honduras",
  "Jamaica": "Jamaika",
  "Martinique": "Martinique",
  "Mexico": "Mexiko",
  "Montserrat": "Montserrat",
  "Nicaragua": "Nicaragua",
  "Panama": "Panama",
  "Paraguay": "Paraguay",
  "Peru": "Peru",
  "Puerto Rico": "Puerto Rico",
  "Saint Kitts and Nevis": "St. Kitts und Nevis",
  "Saint Lucia": "St. Lucia",
  "Saint Pierre and Miquelon": "St. Pierre und Miquelon",
  "Saint Vincent and the Grenadines": "St. Vincent und die Grenadinen",
  "Saint Barthélemy": "St. Barthélemy",
  "Saint Martin (French part)": "St. Martin (französischer Teil)",
  "Sint Maarten (Dutch part)": "Sint Maarten (niederländischer Teil)",
  "South Georgia and the South Sandwich Islands": "Südgeorgien und die Südlichen Sandwichinseln",
  "Suriname": "Suriname",
  "The Bahamas": "Bahamas",
  "Trinidad and Tobago": "Trinidad und Tobago",
  "Turks and Caicos Islands": "Turks- und Caicosinseln",
  "United States": "Vereinigte Staaten",
  "United States Minor Outlying Islands": "Kleinere abgelegene Inseln der Vereinigten Staaten",
  "Uruguay": "Uruguay",
  "Venezuela": "Venezuela",
  "British Virgin Islands": "Britische Jungferninseln",
  "U.S. Virgin Islands": "Amerikanische Jungferninseln",
  "Antarctica": "Antarktis",
  "Your Preferences Updated Successfully": "Ihre Einstellungen wurden erfolgreich aktualisiert"
}